import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class AgentAssist extends Component {


    constructor(props) {

        super(props);
        this.pass = this.pass.bind(this);
        this.state = {
            modelValue: {Model: ""},
            models: []
        }
        this.convRef = React.createRef();
        this.getSavedModels = this.getSavedModels.bind(this);
        this.changeModel = this.changeModel.bind(this);
    }
    changeModel(event, value) {

        if (value && value.Model) {

            localStorage.setItem("model", value.Model);
            this.setState({modelValue: value});
            this.setState({model: value.Model});
        }

    }
    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {
            let models = [];

            this.setState({models: data});
        }

    }
    componentDidMount() {
        this.getSavedModels();
        if(this.props.value){


            this.setState({model: this.props.value.model, modelValue: {Model: this.props.value.model}, account: this.props.account});

        }
        if (this.convRef && this.convRef.current) {

        }



    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            if(this.props.value.model){
                this.setState({model: this.props.value.model});
            }



        }

    }



    pass() {

        let workflow = {type: "puzzelai.nlu", "sendRestResponse": true};

        if(this.state.model){

            workflow.model = this.state.model;
        }

        if(this.state.account){

            workflow.account = this.state.account;
        }
        this.props.passData("puzzelai.nlu", workflow);


    }






    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                <Autocomplete
                    id="modelBox"
                    options={this.state.models}
                    onChange={this.changeModel}
                    value={this.state.modelValue}
                    defaultValue={this.state.models[0]}
                    getOptionLabel={option => option.Model}
                    style={{width: "100%", minWidth: "150px"}}
                    renderInput={params => <TextField {...params}
                                                      variant="outlined"
                                                      label="Voice Model"
                                                      style={{}}/>}/>
                    <Button
                        style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "20px"}}
                        variant="contained"
                        onClick={this.pass}>Add</Button>
            </div>

        )
    }

}

export default withStyles(styles)(AgentAssist);
