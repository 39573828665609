import React, {useEffect, useState} from "react";
import {fetchData} from "./ReusedComponents/fetchData"
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './styles/test-training-data.scss';
import Button from "@material-ui/core/Button/Button";
import IntentRanking from "./IntentRanking";
import {Alert} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import TrainingChat from "./TrainingChat";
import {getSettings} from "./ReusedComponents/getSettings";
import {getR4Token} from "./reused/reusedfunctions";
const TestTrainingData = (props) => {
    const [init, setInit] = useState(false)
    const [restDomain, setRestDomain] = useState("");
    const [allModels, setAllModels] = useState([]);
    const [modelValue, setModelValue] = useState(null);
    const r4 = useState("");
    
    const [intentRanking, setIntentRanking] = useState([]);
    const [textValue, setTextValue] = useState("");
    const [queryAlert, setQueryAlert] = useState('');
    const [alert, setAlert] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [selectedSite, setSelectedSite] = useState(null);
    const [opportunities, setOpportunities] = useState([]);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const [mappedCaseFlows, setMappedCaseFlows] = useState([]);
    const [, setMappedFallbackCategoryFlows] = useState([]);
    const [, setMappedCategoryFlows] = useState([]);
    const [, setFallbackFlow] = useState({"Id": ""});
    const [, setStartFlow] = useState({"Id": ""});
    const [, setInactiveFlow] = useState({"Id": ""});
    const [, setConfidenceThreshold] = useState(0);
    const [, setConfidence] = useState(' ');
    const [chosenCaseType, setChosenCaseType] = useState('');
    const [settings, setSettings] = useState(null);
    const [showIntroText, setShowIntroText] = useState(false);
    const [noMatchFoundMessage, setNoMatchFoundMessage] = useState('');

    

    useEffect(() => {
        const fetchDataAndSetDefaultModel = async () => {
            // Check if state is initialized, don't re-fetch after we get all the model data
            if (init) {
                return false
            }
            await getRestDomain();
            await getSavedModels();
            const savedModel = localStorage.getItem('model');
            if (savedModel) {
                const modelInfo = allModels.find(el => el.Model === savedModel);
                setModelValue(modelInfo);
            }
            const storedQueries = JSON.parse(localStorage.getItem('updatedQueries'));
            if (Array.isArray(storedQueries)) {
                const slicedQueries = storedQueries.slice(-8);
                setIntentRanking(slicedQueries);
            } else {
                setIntentRanking([]);
            }
            await new Promise(resolve => setTimeout(resolve, 500));
            const config = window.vngage?.get('appConfig');
            setInit(true);

            const result = await getSettings({
                setConfidenceThreshold,
                setConfidence,
                setMappedCaseFlows,
                setMappedFallbackCategoryFlows,
                setMappedCategoryFlows,
                setFallbackFlow,
                setStartFlow,
                setInactiveFlow
            })
            setSettings(result)
            setShowIntroText(false);
        };
        fetchDataAndSetDefaultModel();
    }, [allModels, props.account]);

    useEffect(() => {
        setShowButton(intentRanking.length > 0);
    }, [intentRanking]);


    const colors = ['red', 'green', 'blue', 'orange', 'purple', 'pink', 'grey', 'orange', 'yellow', 'turquoise', 'lime', 'black'];

    const getRestDomain = async () => {
        fetchData('/api/getRestDomain')
            .then((responseData) => setRestDomain(responseData))
            .catch((error) => console.error(error));
    }

    const clearResults = () => {
        localStorage.removeItem('updatedQueries');
        setIntentRanking([]);
        setAlert('');
        setChosenCaseType('');
    }

    const getSavedModels = async () => {
        try {
            const response = await fetchData('/api/getSavedModels');
            if (response && response.length) {
                const modelPromises = response.map(async (res) => {
                    return await fetchModelInfo(res.Model);
                });
                const allModelsData = await Promise.all(modelPromises);
                setAllModels(allModelsData);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const fetchModelInfo = async (Model) => {
        try {
            const responseData = await fetchData(`/api/getTrainingInfo?model=${encodeURIComponent(Model)}`);
            if (responseData && responseData.length > 0 && responseData[0].Traininginfo) {
                const trainingInfo = JSON.parse(responseData[0].Traininginfo);
                return {trainingInfo, Model};
            } else {
                // If no training info is available, mark the model as untrained
                return {trainingInfo: {trainingDate: null}, Model};
            }
        } catch (error) {
            console.error(error);
            // Handle error as needed
            return {trainingInfo: {trainingDate: null}, Model};
        }
    }

    const changeModel = async (event, value) => {
        localStorage.setItem('model', value.Model);
        const modelInfo = allModels.find(el => el.Model === value.Model);
        setModelValue(modelInfo);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await getResult(textValue);
        setTextValue("");
    }

    const handleText = (event) => {
        setTextValue(event.target.value);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const fetchQueries = async (queries) => {
        const fetchResponse = [];
        let url = "https://" + restDomain.restDomain + "/parse/" + modelValue.Model + "?q=";
        const  token = await getR4Token();
        let headerObject = {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'};
        
        let options = {
            headers: headerObject
        };
        try {
            for (const query of queries) {
                // Assign color based on the current index
                const colorItem = colors[currentColorIndex % colors.length];
                setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length); // Update the index
                const response = await fetch(url + query, options);
                const data = await response.json();
                fetchResponse.push({...data, colorItem});
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return fetchResponse;
    };

    const createQueries = (createQuery) => {
        const queryArray = [];
        if (createQuery) {
            queryArray.push({id: "queryString", qString: createQuery});
        }
        return queryArray;
    }

    const getResult = async (val) => {
        const queryArray = createQueries(val);
        const queries = queryArray.map((query) => {
            return query.qString;
        })
        const response = await fetchQueries(queries);
        // Allows maximum of 12 queries to be visible in the dom.
        const LIMIT = 12;
        if ((intentRanking.length + 1) >= LIMIT) {
            setQueryAlert("You've hit the maximum of visible queries. Adding a new one will replace the oldest.")
            setAlert(true);
        }
        setIntentRanking((prevMappedQueries) => {
            const updatedQueries = [...prevMappedQueries, ...response];
            // Once the list is full the last one on the list is replaced.
            if (updatedQueries.length > LIMIT) {
                updatedQueries.shift();
            }
            localStorage.setItem('updatedQueries', JSON.stringify(updatedQueries))
            return updatedQueries;
        });
    }

    const handleDelete = (intentObject) => {
        setIntentRanking((oldItems) => {
            const updatedItems = oldItems.filter((item) => item !== intentObject);
            localStorage.setItem('updatedQueries', JSON.stringify(updatedItems));
            return updatedItems;
        });
    };

    const updateTextValue = (newValue) => {
        setTextValue(newValue);
    }

    const intentRankingRow = () => {
        const mapQuery = [...intentRanking].reverse();
        return mapQuery.map((intentObject, i) => (
            <IntentRanking
                key={i}
                color={intentObject.colorItem || colors[i % colors.length]} // Use the assigned color
                entities={intentObject.entities}
                data={intentObject.intent_ranking}
                bestMatch={{
                    "query": intentObject.text,
                    "name": intentObject.intent?.name,
                    "confidence": intentObject.intent?.confidence,
                    "response": intentObject.response
                }}
                onDelete={() => handleDelete(intentObject)}
                updateTextValue={updateTextValue}
                model={modelValue}
                account={props.account}
            />
        ));
    };

    const handleCurrentSite = () => {
        const config = window.vngage?.get('appConfig');

        if (!config) {
            console.error('AppConfig is undefined.');
            return [];
        }
        const sites = config.sites;
        if (!sites) {
            console.error('Sites is undefined in AppConfig:', config);
            return [];
        }
        // Use a Set to keep track of unique ids
        const uniqueIds = new Set();

        return sites.filter(site => {
            if (!uniqueIds.has(site.id)) {
                uniqueIds.add(site.id);
                return true;
            }
            return false;
        });
    };

    const handleBotOpportunities = (selectedSite) => {
        const config = window.vngage.get('appConfig').currentSite;
        const botDescription = "BOT";
        const matchingOpportunities = Object.values(config.opportunities).filter(
            (opportunity) => {
                const isBotDescriptionMatch = opportunity.section.description === botDescription;
                const isBotSolutionsMatch = window.botSolutions && window.botSolutions.some(botSolution =>
                    opportunity.section.solutions.includes(botSolution),
                );
                const isSiteMatch = selectedSite
                    ? opportunity.siteId === selectedSite.id
                    : true; // Include opportunities without site information
                return (isBotDescriptionMatch || isBotSolutionsMatch) && isSiteMatch;
            }
        );

        const solutionsMap = {}; // Map to associate solution with opportunity ID

        matchingOpportunities.forEach(opportunity => {
            opportunity.section.solutions.forEach(solutionId => {
                solutionsMap[solutionId] = opportunity.id;
            });
        });

        const handleOpportunityClick = (selectedOption) => {
            const opportunityId = selectedOption.id;
            const solutionIds = Object.keys(solutionsMap).filter(
                solutionId => solutionsMap[solutionId] === opportunityId
            );
            setShowIntroText(true);
            const matchingSolutions = solutionIds.map(solutionId => ({
                caseTypeId: config.solutions[solutionId].section.caseTypeId
            }));

            if (settings && settings.CaseTypeMapping) {
                // Check if any caseTypeId in matchingSolutions matches with CaseTypeMapping
                const matchingFlows = settings.CaseTypeMapping.reduce((flows, item) => {
                    matchingSolutions.forEach(ms => {
                        if (item.caseId === ms.caseTypeId) {
                            flows.push(item.flowName);
                        }
                    });
                    return flows;
                }, []);

                if (matchingFlows.length > 0) {
                    setChosenCaseType(matchingFlows);
                    setNoMatchFoundMessage('')
                    // Do something with the matching flows
                } else {
                    setNoMatchFoundMessage('No intro Bot Flows match found');
                    setChosenCaseType('');
                    setShowIntroText(false);
                    console.log('No intro Bot Flows match found')
                    //add code for what to do when no casetypeIds matches
                }
            }
            window.vngage.removeAllBanners();
            window.vngage.triggerOpportunity(opportunityId);
        };

        const options = matchingOpportunities.map((opportunity) => ({
            id: opportunity.id,
            name: opportunity.name,
        }));
        return {options, handleOpportunityClick};
    };

    const handleSiteChange = (event, newValue) => {
        setSelectedSite(newValue);
        setSelectedOpportunity(null); // Reset the selected opportunity when changing the site
        setShowIntroText(false);
    };

    const cellClick = (cellData) => {
        props.openWorkflow(cellData[0]);
        props.history.push('/responseworkflow');
    };

    useEffect(() => {
        if (selectedSite !== null) {
            const {options, handleOpportunityClick} = handleBotOpportunities(selectedSite);
            setOpportunities(options);
            if (window.vngage && window.vngage.removeAllBanners) {
                window.vngage.removeAllBanners();
            }
        } else {
            setOpportunities([]);
            setSelectedOpportunity(null);
            if (window.vngage && window.vngage.removeAllBanners) {
                window.vngage.removeAllBanners();
            }
        }
    }, [selectedSite]);

    return (
        <div>
            <Tabs value={tabValue} onChange={handleTabChange} className="test-tabs-container">
                <Tab label="Training Data"
                     className="training-data-tabs"/>
                <Tab label="Test Bot"/>
                <Tab label="Visual Trainer"/>
            </Tabs>
            {tabValue === 0 &&
                <Grid container className="body-container" spacing={8}>
                    <Grid item xs={12}>
                        <div className="test-training-input">
                            <Paper id="test-training-data"
                                   className={`test-bot-modal ${alert ? 'alert-message-triggered' : ''}`}>
                                <p className="test-training-title">Test Training Data</p>
                                {allModels && allModels.length > 0 &&
                                    <FormControl className="training-data-model-container">
                                        <Autocomplete
                                            options={allModels}
                                            value={modelValue ? modelValue : null}
                                            onChange={changeModel}
                                            getOptionSelected={(option, value) => option && value && option.Model === value.Model}
                                            getOptionLabel={(option) => (
                                                option && option.Model ? `${option.Model.toString()} - last trained: ${option.trainingInfo ? option.trainingInfo.trainingDate || '(not trained)' : '(not trained)'}` : ''
                                            )}
                                            getOptionDisabled={(option) => option && option.trainingInfo && option.trainingInfo.trainingDate === null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Model"
                                                />
                                            )}
                                        />
                                    </FormControl>}
                                <form onSubmit={handleSubmit}>
                                    <FormControl>
                                        <TextField variant="outlined"
                                                   id="queryString"
                                                   className="insert-query-text-field"
                                                   margin="normal"
                                                   placeholder="Insert your query here"
                                                   value={textValue}
                                                   onChange={handleText}
                                        />
                                    </FormControl>
                                    {alert ?
                                        <Alert severity="info" className="alert-message">{queryAlert}</Alert> : <></>}
                                    <Button variant="contained" color="primary" id="test-training-btn"
                                            type="submit" disabled={!textValue}>Submit</Button>
                                </form>
                            </Paper>
                        </div>
                    </Grid>
                    <Paper className={`display-ranking-row ${alert ? 'alert-triggered' : ''}`}>
                        {showButton &&
                            <Tooltip title="Clear queries" placement="bottom"
                                     PopperProps={{style: {marginTop: -5, marginLeft: 20}}}>
                                <IconButton id="clear-queries" onClick={clearResults}>
                                    <ClearIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                        {intentRankingRow()}
                    </Paper>
                </Grid>
            }
            {tabValue === 1 &&
                <Grid item xs={12}>
                    <Paper id="test-training-data"
                           className={`test-bot-modal ${alert ? 'alert-message-triggered' : ''}`}>
                        <p className="test-training-title">Test Bot </p>
                        <FormControl className="training-data-model-container test-bot-site">
                            <Autocomplete
                                options={handleCurrentSite()}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Sites"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, newValue) => handleSiteChange(event, newValue)}
                            />
                        </FormControl>
                        <FormControl className="training-data-model-container">
                            <Autocomplete
                                options={opportunities}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Bot Flows" variant="outlined"/>
                                )}
                                onChange={(event, newValue) => {
                                    setSelectedOpportunity(newValue);
                                    if (newValue) {
                                        // Trigger the opportunity based on the selected option
                                        handleBotOpportunities().handleOpportunityClick(newValue);
                                    }
                                }}
                                value={selectedOpportunity}
                            />
                        </FormControl>
                        <div onClick={() => cellClick(chosenCaseType)}>
                            {showIntroText && <div className="botflow-clicked"> Intro Flow Selected:
                                <button className="chosen-casetype-btn">{chosenCaseType}</button>
                            </div>}
                        </div>
                        {noMatchFoundMessage && <div className="no-match-message">{noMatchFoundMessage}</div>}
                    </Paper>
                </Grid>}
            {tabValue === 2 && <TrainingChat {...props} model={props.model}
                                             updateModel={props.updateModel}
                                             account={props.account}/>}
        </div>
    );
}

export default TestTrainingData;