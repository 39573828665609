import React, { useCallback, useEffect, useRef, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ActionsAdmin from "./ActionsAdmin";
import IntegrationsAdmin from "./IntegrationsAdmin";
import ConfigurationAdmin from "./ConfigurationAdmin";
import WorkflowAdmin from "./WorkflowsAdmin";
import LogAdmin from "./LogAdmin";
const Botadmin = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [environment, setEnvironment] = useState("development");
  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const changeEnvironment = async (event) => {
    setEnvironment(event.target.value);
    let url = "/api/setEnvironment?environment=" + event.target.value;
    let options = {
      method: "get",
      headers: { "Content-Type": "application/json" },
    };

    let response = await fetch(url, options).catch((error) =>
      console.log(error)
    );
    let data = await response.json();

    if (data) {
      setEnvironment(data.environment);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            marginRight: "20px",
          }}
        >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Actions" />
          <Tab label="Integrations" />
          <Tab label="Configuration" />
          <Tab label="Starting Workflows" />
          <Tab label="Log" />
        </Tabs>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginRight: "20px",
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={environment}
            variant="outlined"
            onChange={changeEnvironment}
          >
            <MenuItem value={"development"}>Chatbot</MenuItem>
            <MenuItem value={"test"}>VoiceBot</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        {tabValue === 0 && <ActionsAdmin environment={environment}/>}
        {tabValue === 1 && <IntegrationsAdmin environment={environment} />}
        {tabValue === 2 && <ConfigurationAdmin environment={environment}/>}
        {tabValue === 3 && <WorkflowAdmin environment={environment}/>}
        {tabValue === 4 && <LogAdmin environment={environment}/>}
      </div>
    </div>
  );
};
export default Botadmin;
