export const calcFinishedJourneys = (journey) => {
  // console.log(journey);
  //Break out all checkpoint hits into array.
  const journeyHits = journey.map((group) => {
    // console.log(group);
    return group.checkpoints.map((checkpoint) => {
      return checkpoint.hits;
    });
  });
  // console.log(journeyHits);
  //Calc share of hits
  const sumStart = journeyHits[0].reduce((a, c) => {
    return a + c;
  }, 0);

  const sumEnded = journeyHits[journeyHits.length - 1].reduce((a, c) => {
    return a + c;
  }, 0);

  const totalsArray = journeyHits.map((e) => {
    return e.reduce((a, b) => {
      return a + b;
    });
  });

  const sumTotal = totalsArray.reduce((a, b) => {
    return a + b;
  });

  const share = (sumEnded / sumStart) * 100;

  // console.log(sumEnded);
  // console.log(sumStart);
  // console.log(sumTotal);

  // console.log(journeyHits, sumStart, sumEnded, share);
  return {
    startedJourneys: sumStart,
    finishedJourneys: sumEnded,
    finishedShare: share,
    totalHits: sumTotal,
  };
};

//////////////////////////////////////////////////////////////////////
//
//    CALCULATE STARTED, FINISHED AND SHARE OVER TIME
//

export const calcLineData = (data, journey) => {
  //Filter out journey
  const finishedData = [["Day", "Started", "Finished", "Share"]];
  const journeyData = [];
  data.forEach((row) => {
    // console.log(row);
    if (row.JourneyName === journey) journeyData.push(row);
  });

  const dates = [];

  journeyData.forEach((row) => {
    if (dates.includes(row.createdAt)) return;
    else dates.push(row.createdAt);
  });


  //Loop data rows and aggregate started, ended and score
  // console.log(journeyData);
  //1. Select only rows with same date.
  for (let i = 0; i < dates.length; i++) {
    // console.log(`Hämta data för ${dates[i]}`);
    let selectedRows = [];
    journeyData.forEach((row) => {
      if (row.createdAt === dates[i]) selectedRows.push(row);
    });
    //Sort on group Index
    selectedRows.sort((a, b) => {
      // console.log(a, b);
      return parseFloat(a.GroupIndex) - parseFloat(b.GroupIndex);
    });
    const endIndex = selectedRows[selectedRows.length - 1].GroupIndex;
     console.log(endIndex);

    let startedJourneys = 0;
    let finishedJourneys = 0;
    selectedRows.forEach((row) => {
      // console.log(row);
      // console.log(row.CheckpointCount, row.GroupIndex);
      if (row.GroupIndex === 1) {
        // console.log(`Add to started journeys. ${row.CheckpointCount}`);
        startedJourneys = startedJourneys + row.CheckpointCount;
      }
      if (row.GroupIndex === endIndex) {
        // console.log(`Add to ended journeys. ${row.CheckpointCount}`);
        finishedJourneys = finishedJourneys + row.CheckpointCount;
      }
    });
    let share;
    if(startedJourneys === 0){
      share = 0;
    }else{
      share = Math.round((finishedJourneys / startedJourneys) * 100);
    }



    finishedData.push([dates[i], startedJourneys, finishedJourneys, share]);
  }
  //2. loop through rows with same date and aggregate started and ended hits.
  // console.log(finishedData);

  //3 build object with date, started, ended, share

  return finishedData;
};

//////////////////////////////////////////////////////////////////////
//
//    CALCULATE STARTED, FINISHED AND SHARE FOR TABLE
//

export const calcTableData = (data) => {
  const journeyNames = [];
  const finishedData = [];
  data.forEach((row) => {
    if (journeyNames.includes(row.JourneyName)) return;
    else journeyNames.push(row.JourneyName);
  });

  // console.log("Table calculator");
  // console.log(journeyNames);

  for (let i = 0; i < journeyNames.length; i++) {
    let journeyData = [];
    data.forEach((row) => {
      if (row.JourneyName === journeyNames[i]) journeyData.push(row);
    });

    //Sort on group Index
    journeyData.sort((a, b) => {
      // console.log(a, b);
      return parseFloat(a.GroupIndex) - parseFloat(b.GroupIndex);
    });

    const endIndex = journeyData[journeyData.length - 1].GroupIndex;

    let startedJourneys = 0;
    let finishedJourneys = 0;
    console.log(journeyData)
    journeyData.forEach((row) => {
      // console.log(row);
      // console.log(row.CheckpointCount, row.GroupIndex);
      if (row.GroupIndex === 1) {
        // console.log(`Add to started journeys. ${row.CheckpointCount}`);
        startedJourneys = startedJourneys + row.CheckpointCount;
      }
      if (row.GroupIndex === endIndex) {
        // console.log(`Add to ended journeys. ${row.CheckpointCount}`);
        finishedJourneys = finishedJourneys + row.CheckpointCount;
      }
    });
    let share;
    if(startedJourneys === 0){
      share = 0;
    }else{
      share = Math.round((finishedJourneys / startedJourneys) * 100);
    }


    finishedData.push({
      journeyName: journeyNames[i],
      startedJourneys: startedJourneys,
      finishedJourneys: finishedJourneys,
      shareFinished: `${share} %`,
    });
  }
  //2. loop through rows with same date and aggregate started and ended hits.
  // console.log(finishedData);

  //3 build object with date, started, ended, share
console.log(finishedData)
  return finishedData;
};
