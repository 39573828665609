import axios from "axios";

export const fetchData = async (url, options = {}) => {
    try {
        const response = await axios(url, options);
        if (response.status !== 200) {
            throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.data;
    } catch (error) {
        throw new Error(`API error: ${error.message}`);
    }
};