import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import Button from "@material-ui/core/Button/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";



const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class MinMaxUi extends Component {


    constructor(props) {

        super(props);


        this.handleMaximizeSwitchChange = this.handleMaximizeSwitchChange.bind(this);
        this.handleMinimizeSwitchChange = this.handleMinimizeSwitchChange.bind(this);
        this.handleMobileOnlySwitchChange = this.handleMobileOnlySwitchChange.bind(this);
        this.pass = this.pass.bind(this);
        this.state = {
            workFlowRender: null,
            maximizeSwitchChecked: false,
            minimizeSwitchChecked: false,
            mobileOnlySwitchChecked: false
        };

    }
    handleMaximizeSwitchChange(event){
        this.setState({maximizeSwitchChecked: this.state.maximizeSwitchChecked ? false : true});

        if(event.target.checked){

            if(this.state.minimizeSwitchChecked){
                this.setState({minimizeSwitchChecked: false});
            }
        }

    }
    handleMinimizeSwitchChange(event){
        this.setState({minimizeSwitchChecked: this.state.minimizeSwitchChecked ? false : true});
        if(event.target.checked){

            if(this.state.maximizeSwitchChecked){
                this.setState({maximizeSwitchChecked: false});
            }
        }
    }

    handleMobileOnlySwitchChange(event){
        this.setState({mobileOnlySwitchChecked: this.state.mobileOnlySwitchChecked ? false : true});

    }


    componentDidMount() {
        if (this.props.value) {


            if(this.props.value.trigger && this.props.value.trigger === "maximize"){
                this.setState({maximizeSwitchChecked: true });

            }else if(this.props.value.trigger && this.props.value.trigger === "minimize"){
                this.setState({minimizeSwitchChecked: true });
            }


            if(this.props.value.mobileOnly){
                this.setState({mobileOnlySwitchChecked: true });
            }
        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {

        if ((prevProps && prevProps.value !== this.props.value)) {



            if(this.props.value.trigger && this.props.value.trigger === "maximize"){
                this.setState({maximizeSwitchChecked: true });

            }else if(this.props.value.trigger && this.props.value.trigger === "minimize"){
                this.setState({minimizeSwitchChecked: true });
            }


            if(this.props.value.mobileOnly){
                this.setState({mobileOnlySwitchChecked: true });
            }
        }

    }





    pass() {

        let workflow = {type: "case.minMaxUi"};
        if(this.state.minimizeSwitchChecked){

            workflow.trigger = "minimize";
        }

        if(this.state.maximizeSwitchChecked){
            workflow.trigger = "maximize";
        }

        if(this.state.mobileOnlySwitchChecked){
            workflow.mobileOnly = true;
        }

        this.props.passData("case.minMaxUi", workflow);

    }


    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <p style={{fontWeight: "bold", fontSize: "16px"}}>Minimize/Maximize Chat</p>
                    <FormGroup>

                        <FormControlLabel

                            control={<Switch color="primary"
                                             checked={this.state.minimizeSwitchChecked}
                                             onChange={this.handleMinimizeSwitchChange}
                                             name="active"/>}
                            label={"Minimize"}
                        />
                        <FormControlLabel

                            control={<Switch color="primary"
                                             checked={this.state.maximizeSwitchChecked}
                                             onChange={this.handleMaximizeSwitchChange}
                                             name="active"/>}
                            label={"Maximize"}
                        />
                        <FormControlLabel

                            control={<Switch color="primary"
                                             checked={this.state.mobileOnlySwitchChecked}
                                             onChange={this.handleMobileOnlySwitchChange}
                                             name="active"/>}
                            label={"Mobile Only"}
                        />
                    </FormGroup>
                    {this.props.value ?
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop:"15px"}}
                                variant="contained" onClick={this.pass}>Update</Button> :
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop:"15px"}}
                                variant="contained" onClick={this.pass}>Add</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(MinMaxUi);
