import React, {Component} from "react";
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import DraggableList from "./DraggableList";
import {ListItem, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import JourneyChart from "./chart/chart";
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(4),
        minWidth: 120
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'
    },
    listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },

});


class CustomerJourney extends Component {

    constructor() {
        super();
        this.state = {
            items: [],
            newJourney: false,
            newJourneyName: '',
            score: '',
            order: 1,
            checkpointName: '',
            confirmDeleteOpen: false,
            confirmDeleteJourneyOpen: false,
            nbrResult: 0,
            groupName: '',
            value: 0,
            confirmDeleteOpenJourney: false


        }
        this.getCustomerJourneys = this.getCustomerJourneys.bind(this);
        this.handleJourney = this.handleJourney.bind(this);
        this.newCustomerJourney = this.newCustomerJourney.bind(this);
        this.handleNewCustomerJourney = this.handleNewCustomerJourney.bind(this);
        this.newCheckpoint = this.newCheckpoint.bind(this);
        this.newJourney = this.newJourney.bind(this);
        this.saveNewCheckPoint = this.saveNewCheckPoint.bind(this);
        this.handleCheckpointNameChange = this.handleCheckpointNameChange.bind(this);
        this.handleScoreChange = this.handleScoreChange.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.updateCheckpoints = this.updateCheckpoints.bind(this);
        this.editItem = this.editItem.bind(this);
        this.updateCheckpoint = this.updateCheckpoint.bind(this);
        this.deleteCheckpoint = this.deleteCheckpoint.bind(this);
        this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this);
        this.handleConfirmDeleteCloseJourney = this.handleConfirmDeleteCloseJourney.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.updateFiltered = this.updateFiltered.bind(this);
        this.updateAllCustomerJourneys = this.updateAllCustomerJourneys.bind(this);
        this.updateCheckpointByArg = this.updateCheckpointByArg.bind(this);
        this.updateFilteredFromDrag = this.updateFilteredFromDrag.bind(this);
        this.handleGroupName = this.handleGroupName.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.deleteJourney = this.deleteJourney.bind(this);
        this.handleDeleteJourney = this.handleDeleteJourney.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.updateGroupByArg = this.updateGroupByArg.bind(this);
        this.addItem = this.addItem.bind(this);
        this.editCheckPoint = this.editCheckPoint.bind(this);
        this.handleDeleteCheckpoint = this.handleDeleteCheckpoint.bind(this);
        this.preSaveGroup = this.preSaveGroup.bind(this);
        this.countGroups = this.countGroups.bind(this);
        this.deleteUpdateCheckpointByArg = this.deleteUpdateCheckpointByArg.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);


    }

    handleTabChange = (event, value) => {

        this.setState({value: value});

    }

    preSaveGroup(event) {
        let found = false;

        this.state.filteredJourneys.map((journey) => {

            journey.map((j) => {

                if (j.group === this.state.groupName) {
                    found = true;
                }

            });

        });

        if (!found) {
            this.saveGroup();
        }
    }

    handleDeleteCheckpoint = (event, i) => {

        if (this.state.updateId) {

            this.countGroups();

        }

    }


    editCheckPoint = (item) => {

        if (this.state.addCheck) {

            this.setState({addCheck: false, checkpointName: '', score: ''});
        } else {

            this.setState({
                checkpointName: item.primary,
                score: item.secondary,
                groupName: item.group,
                addCheck: true,
                edit: true,
                checkpointExist: null,
                order: item.order,
                updateId: item.id
            });
        }

    }

    addItem = (i, item) => {

        if (this.state.addCheck) {

            this.setState({addCheck: false});

        } else {

            this.setState({checkpointExist: item[0].primary ? true : false});

            this.setState({
                addCheck: true,
                groupName: item[0].group,
                updateId: item[0].id,
                checkpointName: '',
                score: '',
                order: item[0].order
            });

        }

    }


    handleGroupName(event) {

        this.setState({groupName: event.target.value});

    }

    handleDelete(event) {

        this.setState({confirmDeleteOpen: true});

    }

    handleDeleteJourney(event) {

        this.setState({confirmDeleteOpenJourney: true});

    }

    async deleteJourney() {

        let url = '/api/deleteJourney?name=' + this.state.selectedJourney;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length === 0) {

            this.getCustomerJourneys();
        }
    }

    handleConfirmDeleteCloseJourney(event) {

        if (event.target.innerText === "YES") {
            this.deleteJourney();
        }

        this.setState({confirmDeleteOpenJourney: false});

    }

    handleConfirmDeleteClose(event) {

        if (event.target.innerText === "YES") {
            this.deleteGroup();
        }

        this.setState({confirmDeleteOpen: false});

    }

    editItem = (i, item) => {


        if (this.state.newCheckpoint) {
            this.setState({newCheckpoint: false, groupName: ""});

        } else {
            this.setState({
                groupName: item[0].group,
                updateGroup: true,
                oldGroupName: item[0].group,
                newCheckpoint: true

            });
        }


    }

    handleCheckpointNameChange(event) {

        this.setState({checkpointName: event.target.value});

    }

    handleScoreChange(event) {

        this.setState({score: event.target.value});
    }

    handleOrderChange(event) {

        this.setState({order: event.target.value});

    }

    async countGroups() {
        let url = '/api/countGroups?group=' + this.state.groupName + '&accountId=' + this.props.account;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0 && data[0].count && Number.parseInt(data[0].count) === 1) {

            this.deleteUpdateCheckpointByArg(this.state.selectedJourney, this.state.order, this.state.updateId, this.state.groupName);


        } else {

            this.deleteCheckpoint();
        }


    }

    async deleteCheckpoint() {

        let url = '/api/deleteCheckpoint?id=' + this.state.updateId;

        const response = await fetch(url);
        const data = await response.json();
        this.setState({updateId: null, newCheckpoint: false,});
        if (data && data.length === 0) {
            this.setState({addCheck: false, checkpointName: '', score: ''})
            this.getCustomerJourneys();
        }
    }


    async deleteGroup() {
        let url = '/api/deleteGroup?group=' + this.state.groupName + '&accountId=' + this.props.account + "&journeyName=" + this.state.selectedJourney;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length === 0) {
            this.setState({
                groupName: '',
                updateGroup: false,
                oldGroupName: null,
                newCheckpoint: false
            });
            this.getCustomerJourneys();
        }
    }

    async updateGroupByArg(order, id, group) {

        let url = '/api/updateGroupByArg?group=' + group + "&order=" + order + "&id=" + id;

        const response = await fetch(url);
        const data = await response;

        if (data && data.status === 200) {

            this.setState({nbrResult: this.state.nbrResult + 1}, () => {


                if (this.state.nbrResult === this.state.nbrUpdated) {
                    this.setState({nbrResult: 0});
                    this.getCustomerJourneys();

                }
            });

        }
    }


    async deleteUpdateCheckpointByArg(journeyName, order, id, group) {

        let url = '/api/deleteUpdateCheckpoint?group=' + group + '&accountId=' + this.props.account + "&journeyName=" + journeyName + "&order=" + order + "&id=" + id;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length === 0) {

            this.getCustomerJourneys();

        }
    }

    async updateCheckpointByArg(journeyName, checkpointName, score, order, id, group) {

        let url = '/api/updateCheckpoint?group=' + group + '&accountId=' + this.props.account + "&journeyName=" + journeyName + "&checkpointName=" + checkpointName + "&score=" + score + "&order=" + order + "&id=" + id;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length === 0) {

            this.setState({nbrResult: this.state.nbrResult + 1}, () => {


                if (this.state.nbrResult === this.state.nbrUpdated) {
                    this.setState({nbrResult: 0});
                    this.getCustomerJourneys();

                }
            });

        }
    }

    async updateGroup() {

        let url = '/api/updateGroup?oldGroup=' + this.state.oldGroupName + '&group=' + this.state.groupName + '&accountId=' + this.props.account + "&journeyName=" + this.state.selectedJourney;

        const response = await fetch(url);
        const data = await response.json();


        /*this.setState({
            updateId: null, newCheckpoint: false, checkpointName: null, score: null, order: null
        });*/
        if (data && data.length === 0) {

            this.setState({
                groupName: '',
                updateGroup: false,
                oldGroupName: null,
                newCheckpoint: false
            });


            this.getCustomerJourneys();
        }
    }


    updateFilteredFromDrag = (items) => {

        if (items) {
            this.setState({filteredJourneys: items}, () => {

                this.updateAllCustomerJourneys(this.state.selectedJourney);
            });
        }
    }

    updateAllCustomerJourneys(customerJourney) {
        //console.log("updateAllCustomerJourneys", this.state.filteredJourneys)
        let nbrUpdated = 0;
        this.state.filteredJourneys.map((item, i) => {

            if (Array.isArray(item)) {
                item.map((it) => {
                    if (it.name === customerJourney) {

                        this.updateGroupByArg(it.order, it.id, it.group);

                        nbrUpdated++;
                    }
                });

            } else {
                if (item.name == customerJourney) {
                    this.updateGroupByArg(item.order, item.id, item.group);
                    nbrUpdated++;
                }
            }

        });
        this.setState({nbrUpdated: nbrUpdated});


    }


    async saveGroup() {
        let newOrder = eval(this.state.filteredJourneys.length + 1);
        let url = '/api/newGroup?accountId=' + this.props.account + "&journeyName=" + this.state.selectedJourney + "&groupName=" + this.state.groupName + "&order=" + newOrder;

        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length === 0) {
            this.setState({
                checkpointName: '',
                score: '',
                order: '',
                newCheckpoint: false,
                groupName: ''

            });

            this.getCustomerJourneys();
        }
    }

    async saveNewCheckPoint() {

        let group;
        if(this.state.group){
            group = this.state.group;
        }else{
            group = this.state.groupName;
        }
        //console.log(this.state.group, this.state.groupName)
        let url = '/api/newCheckpoint?accountId=' + this.props.account + "&journeyName=" + this.state.selectedJourney + "&checkpointName=" + this.state.checkpointName + "&score=" + this.state.score + "&order=" + this.state.order + "&group=" + group;

        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length === 0) {
            this.setState({
                checkpointName: '',
                score: '',
                order: '',
                newCheckpoint: false,
                addCheck: false

            });

            this.getCustomerJourneys();
        }
    }

    async updateCheckpoint() {
        let group;
        if(this.state.group){
            group = this.state.group;
        }else{
            group = this.state.groupName;
        }
        //console.log(this.state.group, this.state.groupName)
        let url = '/api/updateCheckpoint?group=' + group + '&accountId=' + this.props.account + "&journeyName=" + this.state.selectedJourney + "&checkpointName=" + this.state.checkpointName + "&score=" + this.state.score + "&order=" + this.state.order + "&id=" + this.state.updateId;

        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            updateId: null, newCheckpoint: false, checkpointName: '', score: '', order: '', addCheck: false
        });
        if (data && data.length === 0) {

            this.getCustomerJourneys();
        }
    }

    async newJourney(event) {

        let exist = false;
        this.state.items.map((object) => {

            if (object.name === this.state.newJourneyName) {
                exist = true;
            }

        });

        if (!exist) {

            let url = '/api/newCustomerJourney?accountId=' + this.props.account + "&journeyName=" + this.state.newJourneyName;

            const response = await fetch(url);
            const data = await response.json();
            if (data && data.length === 0) {

                this.getCustomerJourneys();
                //reset new
                this.setState({newJourney: false, newJourneyName: ""});
            }
        } else {

            this.setState({journeyExist: true});
        }
    }

    newCheckpoint(event) {

        this.setState({newCheckpoint: this.state.newCheckpoint ? false : true});
    }

    handleNewCustomerJourney(event) {

        if (this.state.journeyExist) {
            this.setState({journeyExist: false});
        }
        this.setState({newJourneyName: event.target.value});

    }

    newCustomerJourney(event) {

        this.setState({newJourney: this.state.newJourney ? false : true});

    }

    componentDidMount() {
        this.getCustomerJourneys();
    }


    updateCheckpoints() {

        if (this.state.selectedJourney) {
            let filtered = this.state.items;
            filtered = filtered.filter(object => {
                return object.name && object.name === this.state.selectedJourney
            });

            filtered = this.updateFiltered(filtered);

            this.setState({filteredJourneys: filtered});
        }
    }

    updateFiltered(filtered) {

        let last = null;
        let groupArray = new Map();
        filtered.map((obj, i) => {

            let checkpoints = groupArray.get(obj.group) ? groupArray.get(obj.group) : [];

            if (obj.group) {

                checkpoints.push(obj);
                groupArray.set(obj.group, checkpoints);
            }

        });

        return Array.from(groupArray.values());

    }

    handleJourney = (event, index) => {

        let filtered = this.state.items;
        filtered = filtered.filter(object => {
            return object.name && object.name === event.target.innerHTML
        });

        filtered = this.updateFiltered(filtered);

        //filtered

        this.setState({filteredJourneys: filtered, selected: index, selectedJourney: event.target.innerHTML});

    }

    async getCustomerJourneys() {

        let url = '/api/getCustomerJourneys?accountId=' + this.props.account;

        const response = await fetch(url);
        const data = await response.json();

        let items = [];
        const groups = new Map();
        const journeys = new Map();
        if (data && data.length > 0) {

            data.map((journey) => {

                if (journey.Group) {
                    let obj = {};
                    obj.primary = journey.Checkpointname;
                    obj.id = journey.Id.toString();
                    obj.order = journey.Order;
                    obj.secondary = journey.Score;
                    obj.name = journey.Name;
                    obj.group = journey.Group;
                    items.push(obj);
                }

                if (journey.Group) {

                    let m = groups.get(journey.Group);
                    if (m) {
                        let obj = {};
                        obj.primary = journey.Checkpointname;
                        obj.id = journey.Id.toString();
                        obj.order = journey.Order;
                        obj.secondary = journey.Score;
                        obj.name = journey.Name;
                        obj.group = journey.Group;
                        m.push(obj);
                        groups.set(journey.Group, m);
                    } else {
                        groups.set(journey.Group, []);
                    }

                }

                journeys.set(journey.Name, 1);
            });

            this.setState({
                items: items,
                groups: groups,
                groupLength: Array.from(groups.keys()).length,
                journeys: Array.from(journeys.keys())
            }, () => {

                this.updateCheckpoints();
            });

        }
    }

    render() {
        const {classes} = this.props;
        const {value} = this.state;

        return (

        <div>
            <Tabs value={value} onChange={this.handleTabChange}>
                <Tab label="Create"/>
                <Tab label="Statistics"/>
            </Tabs>
            {value === 0 &&
        <div style={{display: "flex", flexDirection: "row"}}>

                <div style={{display: "flex", flexDirection: "column"}}>
                    {this.state.journeys && this.state.journeys.length > 0 && <p style={{fontSize: "18px"}}>Customer Journeys</p> }

                    <Paper style={{
                        padding: "10px",marginTop: this.state.journeys && this.state.journeys.length === 0 ? "20px": "0px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "10px"
                        }}>
                            <Tooltip title="New Customer Journey">
                                <IconButton style={{background: "#3E51B5", color: "white"}}
                                            onClick={this.newCustomerJourney} aria-label="New Customer Journey"
                                            component="span">
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                            {this.state.journeys && this.state.journeys.length > 0 &&
                            <Tooltip title="Delete Customer Journey">
                                <IconButton style={{marginLeft: "20px", background: "#3E51B5", color: "white"}}
                                            onClick={this.handleDeleteJourney} aria-label="Delete Customer Journey"
                                            component="span">
                                    <DeleteIcon/>
                                </IconButton>

                            </Tooltip>}


                        </div>

                        {this.state.newJourney &&
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: "10px"

                                }}>
                                    <TextField
                                        label="Name"
                                        style={{
                                            backgroundColor: "white"
                                        }}
                                        className={classes.textField}
                                        value={this.state.newCustomerJourney}
                                        onChange={this.handleNewCustomerJourney}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <Tooltip title="New Journey">
                                        <IconButton style={{color: "#3E51B5"}}
                                                    onClick={this.newJourney} aria-label="Save"
                                                    component="span">
                                            <SaveIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {this.state.journeyExist &&
                                    <p style={{color: "red", maxWidth: "280px", wordWrap: "break-word"}}>A journey with
                                        that
                                        name already exist, please choose another name</p>}
                            </div>
                        }
                    </Paper>
                    {this.state.journeys && this.state.journeys.length > 0 &&
                    <Paper style={{
                        padding: "10px",
                        marginTop: "20px"
                    }}>
                        <List style={{
                            maxHeight: "150px",
                            overflow: "auto",
                            border: "1px solid #ccc",
                            minHeight: "500px",
                            marginTop: "38px"
                        }}>
                            {this.state.journeys && this.state.journeys.map((variable, u) => {
                                return <ListItem style={{cursor: "pointer"}} selected={this.state.selected === u}
                                                 classes={{root: this.props.classes.listItemTextSelected}} key={u}>
                                    <ListItemText id={Math.random()} onClick={event => this.handleJourney(event, u)}
                                                  primary={variable}/>
                                </ListItem>
                            })}


                        </List>
                    </Paper>}

                    {( !this.state.journeys || (this.state.journeys && this.state.journeys.length === 0) )  &&
                        <Paper style={{
                            padding: "10px",
                            marginTop: "20px"
                        }}>
                            <List style={{
                                maxHeight: "150px",
                                overflow: "auto",
                                border: "1px solid #ccc",
                                minHeight: "500px",
                                marginTop: "38px"
                            }}>
                                <ListItem style={{cursor: "pointer"}} selected={this.state.selected === 0}
                                                     classes={{root: this.props.classes.listItemTextSelected}} key={0}>
                                        <ListItemText id={Math.random()}
                                                      primary={"No Journeys Defined"}/>
                                    </ListItem>



                            </List>
                        </Paper>}
                </div>

                {this.state.filteredJourneys &&
                    <div style={{display: "flex", flexDirection: "column", marginLeft: "40px"}}>
                        <p style={{fontSize: "18px"}}>Checkpoint Groups</p>
                        <Paper style={{
                            padding: "10px",
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}>
                                <Tooltip title="New Checkpoint Group">
                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                onClick={this.newCheckpoint} aria-label="New Checkpoint"
                                                component="span">
                                        <AddIcon/>
                                    </IconButton>
                                </Tooltip>


                            </div>
                            {this.state.addCheck && <div style={{display: "flex", flexDirection: "row"}}><TextField
                                label="Checkpoint Name"
                                style={{
                                    backgroundColor: "white",
                                    marginLeft: "10px",
                                    width: "100%"
                                }}
                                className={classes.textField}
                                value={this.state.checkpointName}
                                onChange={this.handleCheckpointNameChange}
                                margin="normal"
                                variant="outlined"
                            />
                                <TextField
                                    label="Score"
                                    style={{
                                        backgroundColor: "white",
                                        marginLeft: "10px",
                                        width: "150px"
                                    }}
                                    className={classes.textField}
                                    value={this.state.score}
                                    onChange={this.handleScoreChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                                {this.state.checkpointExist &&
                                    <Tooltip title="Update Checkpoint">
                                        <IconButton style={{color: "#3E51B5"}}
                                                    onClick={this.saveNewCheckPoint} aria-label="New Checkpoint"
                                                    component="span">
                                            <SaveIcon/>
                                        </IconButton>
                                    </Tooltip>}

                                {!this.state.checkpointExist && <Tooltip title="New Checkpoint">
                                    <IconButton style={{color: "#3E51B5"}}
                                                onClick={this.updateCheckpoint} aria-label="Save"
                                                component="span">
                                        <SaveIcon/>
                                    </IconButton>
                                </Tooltip>}


                                <IconButton style={{marginLeft: "10px", color: "#3E51B5"}}
                                            onClick={this.handleDeleteCheckpoint} color="primary"
                                            aria-label="Delete Checkpoint" component="span">
                                    <DeleteIcon/>
                                </IconButton>
                            </div>
                            }
                            {this.state.newCheckpoint &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: "10px"
                                }}>

                                    <TextField
                                        label="Checkpoint Group"
                                        style={{
                                            backgroundColor: "white",
                                            marginLeft: "10px",
                                            width: "100%"
                                        }}
                                        className={classes.textField}
                                        value={this.state.groupName}
                                        onChange={this.handleGroupName}
                                        margin="normal"
                                        variant="outlined"
                                    />


                                    {this.state.updateGroup &&
                                        <Tooltip title="Update Group">
                                            <IconButton style={{color: "#3E51B5"}}
                                                        onClick={this.updateGroup} aria-label="Update Group"
                                                        component="span">
                                                <SaveIcon/>
                                            </IconButton>
                                        </Tooltip>}

                                    {!this.state.updateGroup && <Tooltip title="New Group">
                                        <IconButton style={{color: "#3E51B5"}}
                                                    onClick={this.preSaveGroup} aria-label="Save"
                                                    component="span">
                                            <SaveIcon/>
                                        </IconButton>
                                    </Tooltip>}


                                    <IconButton style={{marginLeft: "10px", color: "#3E51B5"}}
                                                onClick={this.handleDelete} color="primary"
                                                aria-label="Delete Group" component="span">
                                        <DeleteIcon/>
                                    </IconButton>


                                </div>}
                        </Paper>
                        <Paper style={{
                            padding: "10px",
                            marginTop: "20px"
                        }}>
                        <span style={{display: "flex", flexDirection: "row", marginBottom: "10px", marginTop: "10px"}}>
                        <span style={{width: "80px", marginLeft: "15px"}}>Step</span>
                        <span style={{width: "380px"}}>Checkpoint Name</span>
                        </span>

                            <div style={{border: "1px solid #ccc", padding: "0px", minHeight: "500px"}}>
                                <DraggableList addItem={this.addItem} edit={this.editItem}
                                               updatedItems={this.updateFilteredFromDrag}
                                               editCheckPoint={this.editCheckPoint}
                                               items={this.state.filteredJourneys}/>
                            </div>
                        </Paper>
                    </div>}

                <Dialog
                    open={this.state.confirmDeleteOpen}
                    onClose={this.handleConfirmDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {this.state.groupName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmDeleteClose} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.handleConfirmDeleteClose} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.confirmDeleteOpenJourney}
                    onClose={this.handleConfirmDeleteCloseJourney}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {this.state.selectedJourney}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmDeleteCloseJourney} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.handleConfirmDeleteCloseJourney} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            }
            {value === 1 && <div style={{display: "flex", flexDirection: "column", height: "calc(100vh - 80px)"}}> <Paper style={{height: "100vh"}}> <JourneyChart account={this.props.account} /></Paper></div>}

        </div>
        );
    }
}

export default withStyles(styles)(CustomerJourney);
