import React from 'react';
import {toPng} from 'html-to-image';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
function downloadImage(dataUrl) {
    const a = document.createElement('a');

    a.setAttribute('download', 'workflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

function DownloadButton() {
    const onClick = () => {
        toPng(document.querySelector('.react-flow'), {
            filter: (node) => {
                // we don't want to add the minimap and the controls to the image
                if (
                    node?.classList?.contains('react-flow__minimap') ||
                    node?.classList?.contains('react-flow__controls')
                ) {
                    return false;
                }

                return true;
            },
            backgroundColor: "#FFFFFF"
        }).then(downloadImage);
    };

    return (

        <Tooltip title="Download the Workflow Rendering">
        <IconButton style={{color:"#000000"}} onClick={onClick} aria-label="delete" size="small" >
            <GetAppIcon />
        </IconButton>

        </Tooltip>
    );
}

export default DownloadButton;
