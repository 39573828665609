import React, {Component} from "react";
import {Draggable} from 'react-beautiful-dnd';
import Collapse from '@material-ui/core/Collapse';
import {
    Divider,
    ListItemIcon,
    Typography,
    ListItem,
    List,
    ListItemAvatar,
    Avatar,
    ListItemText,
    withStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {marginLeft: "20px"},
    inlineFixed: {marginLeft: "20px", width: "200px", marginRight: "20px"},
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
});

class DraggableListItem extends Component {
    constructor(props) {

        super(props);
        this.state = {
            open: false

        }
        this.handleClick = this.handleClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
    }
    handleAddClick(event){

        console.log(event)

    }
    handleEditClick(event){

        this.props.edit(this.props.item);
    }

    handleClick(event) {

        this.setState({open: this.state.open ? false : true});

    }

    render() {
        const {classes} = this.props;
        return (

            <Draggable draggableId={this.props.item[0].id} index={this.props.index}>
                {(provided, snapshot) => (

                    <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        divider={true}
                        selected={snapshot.isDragging ? true : false}
                        onClick={this.handleClick}
                    >
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: "#35424E"}}> {this.props.item.order}</Avatar>
                        </ListItemAvatar>

                        <ListItemText style={{width: "400px"}} primary={<React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inlineFixed}
                                color="textPrimary"
                            >
                                {this.props.item.primary}
                            </Typography>
                        </React.Fragment>}/>
                        <ListItemText style={{width: "100px"}} primary={this.props.item.secondary}/>

                        <IconButton size="small"
                                    onClick={this.handleEditClick}
                                    style={{
                                        color: "#35424E"
                                    }}
                                    color="primary"
                                    aria-label="Edit" component="span">
                            <EditIcon />
                        </IconButton>


                        <IconButton size="small"
                                    onClick={this.handleAddClick}
                                    style={{
                                        color: "#35424E"
                                    }}
                                    color="primary"
                                    aria-label="add" component="span">
                            <AddIcon/>
                        </IconButton>



                    </ListItem>

                )}
            </Draggable>
        );
    }
}

export default withStyles(styles)(DraggableListItem);