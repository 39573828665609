export async function getBotLess() {
  const url =
    "https://content.psplugin.com/images/8733B8BB-49D3-4187-B9D4-719BCC78C769/bot/THEME.less";
  const response = await fetch(url);
  const data = await response.text();
  return data.toString();
}

export async function createThemeConfig(domain, token, background, themeName) {
  //retrive the less theme from config
  const lessTheme = await getBotLess();

  let url = domain + "/api/v1/ConfigurationSection";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
    body: JSON.stringify({
      section: {
        less:
          "@panelHasBoxShadow: true;\n@hasGradient: false;\n@btnHasIcons: true;\n@panelBgColor: #FFFFFF;\n@headerBgColor: " +
          background +
          ";\n\n@themecolor:" +
          background +
          ";\n" +
          lessTheme,
        baseTheme: "themeLight",
      },
      name: themeName,
      type: "theme",
      siteId: "00000000-0000-0000-0000-000000000000",
    }),
  });
  return response.status === 200 ? true : false;
}

export async function getSites(domain, token) {
  let url = domain + "/api/v1/Site";
  const response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
  });

  if (response.statusCode !== 400) {
    const data = await response.json();
    let sites = [];
    data.map((object) => {
      if (object.data) {
        if (object.data.name.toLowerCase().indexOf("channel") === -1) {
          sites.push({ id: object.data.id, name: object.data.name });
        }
      }
    });

    return sites;
  }
  return null;
}

export async function getGroups(domain, siteId, vepToken) {
  let url = domain + "/api/v1/Group?siteId=";

  const response = await fetch(url + siteId, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
  });

  const data = await response.json();
  return data;
}

export async function getAccount(domain, token) {
  let url = domain + "/api/v1/Account/Current";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
  });
  const data = await response.json();
  if (data && data.data) {
    return {
      account: data.data.id,
      subDomainName: data.data.subDomainName,
      lowercaseAccount: data.data.id.toLowerCase(),
    };
  }
  return null;
}

export async function createGroup(groupObject, token, domain) {
  let url = domain + "/api/v1/Group";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
    body: JSON.stringify(groupObject),
  });
  const data = await response.json();

  return data ? data : null;
}

export async function createUser(reqData, token, domain) {
  let url = domain + "/api/v1/User";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
    body: JSON.stringify(reqData),
  });
  const data = await response.json();

  return data;
}

export async function createToken(userId, token, domain) {
  let url = domain + "/api/v1/user/" + userId + "/CreateToken";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + token,
    },
  });
  const data = await response.json();
  return data;
}

export async function createCase(domain, vepToken, caseObj) {
  let url = domain + "/api/v1/ConfigurationSection";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
    body: JSON.stringify(caseObj),
  });

  const data = await response.json();

  return data;
}

export async function getLastOpportunityPosition(domain, vepToken) {
  let url = domain + "/api/v1/ConfigurationSection";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
  });
  console.log(url);
  const data = await response.json();
  let position = 0;

  if (data && data.length > 0) {
    data.map((object) => {
      if (object.data.type === "opportunity") {
        if (object.data.section.position > position) {
          position = object.data.section.position;
        }
      }
    });
    console.log(position);
    return position;
  }
  return null;
}

export async function getAllBanners(domain, vepToken) {
  let url = domain + "/api/v1/ConfigurationSection";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
  });

  const data = await response.json();
  let position = 0;

  if (data && data.length > 0) {
    let banners = [];
    data.map((object) => {
      if (object.data.type === "banner") {
        banners.push(object);
      }

      if (object.data.section.position > position) {
        if (object.data.type === "opportunity") {
          position = object.data.section.position;
        }
      }
    });
    return banners;
  }
}

export async function createSolution(solObj, vepToken, domain) {
  let url = domain + "/api/v1/ConfigurationSection";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
    body: JSON.stringify(solObj),
  });

  const data = await response.json();

  return data;
}

export async function createOpportunity(oppObj, domain, vepToken) {
  let url = domain + "/api/v1/ConfigurationSection";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
    body: JSON.stringify(oppObj),
  });

  const data = await response.json();

  return data;
}

export async function getCustomJsScript(domain, vepToken, customJsAddon) {
  let url = domain + "/api/v1/Script";
  const response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
  });

  const data = await response.json();
  if (data[0]) {
    let revision = data[0].revision;

    let script = data[0].data.script + "\n" + customJsAddon;
    let key = data[0].key;
    let scriptObj = {
      comments: "Script",
      id: key,
      name: "Custom Script",
      script: script,
    };
    updateCustomJsScript(domain, scriptObj, key, revision, vepToken);
    return true;
  }
  return false;
}

export async function updateCustomJsScript(
  domain,
  scriptObject,
  key,
  revision,
  vepToken
) {
  let url = domain + "/api/v1/Script/" + key + "?revision=" + revision;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "veptoken " + vepToken,
    },
    body: JSON.stringify(scriptObject),
  });
  const data = await response.json();

  return data;
}

export async function createNewBot(
  context,
  desktop,
  systemworkflows,
  workflows,
  environmentconf,
  environment,
  subdomain,
  accountid
) {
  let url = "/api/createNewBot";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      context: context,
      desktop: desktop,
      systemWorkflows: systemworkflows,
      workflows: workflows,
      environmentConf: environmentconf,
      environment: environment,
      subdomain: subdomain,
      accountId: accountid,
    }),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data.length === 0) {
    return true;
  }

  return false;
}

export async function getAccountIntentTemplate() {
  let url = "/api/getModel?model=accounttemplate";

  const response = await fetch(url);

  const data = await response.json();

  return data;
}

async function getPuzzelToken(customerKey, userName, password) {
  let url = "https://auth.puzzel.com/api/Authenticate/login";
  const authObj = {
    customerKey: customerKey,
    userName: userName,
    password: password,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(authObj),
  });

  const data = await response.json();
  if (data && data.accessToken) {
    return data.accessToken;
  }
  return null;
}

export async function getPuzzelQueues(customerKey, userName, password, path) {
  const accessToken = await getPuzzelToken(customerKey, userName, password);

  const localPath = customerKey + "/queues/?";

  let authType = "Bearer";

  let url = path + localPath;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });

  const data = await response.json();
  return data ? data : null;

}
