import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";
import InputBase from '@material-ui/core/InputBase';
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(4),
        minWidth: 120,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    notchedOutline: {},
    select: {
        selectedTextColor: 'black',
    },
    menuItem: {
        color: "white",

    }

});

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            //marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: "black",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '12px 26px 12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            background: "white",
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


class IntentMatch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model,
            name: '',
            labelWidth: 0,
            intent: '',
            counter: '0'
        };
        this.listRow = this.listRow.bind(this);
        this.mapIntentsToScore = this.mapIntentsToScore.bind(this);
    }

    componentDidMount() {



        this.setState({intent: this.props.intent})
        this.setState({counter: this.props.counter});

    }

    mapIntentsToScore(){



    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        // only update chart if the data has changed

        if (prevProps.intent !== this.props.intent) {

            this.setState({intent: this.props.intent});

        }
        if (prevProps.counter !== this.props.counter) {

            this.setState({counter: this.props.counter});

        }


    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if(this.props.setIntent){
            this.props.setIntent(event, this.state.counter);
        }

    };

    listRow() {

        let arrayWithConfidence = [];
        if(this.props.data && this.props.intentRanking){


            this.props.data.map( object => {

                let found = false;
                this.props.intentRanking.intent_ranking.map(ranking => {

                    if(object === ranking.name){
                        arrayWithConfidence.push(object+" ["+ Math.round(eval(ranking.confidence*100))+"%]");
                        found = true;
                    }

                });

                if(!found){

                    arrayWithConfidence.push(object);
                }

            });



        }

        if (arrayWithConfidence) {

            return arrayWithConfidence.map((object, i) => {

                return  <MenuItem key={Math.random()} value={i}>{object}</MenuItem>

            });
        }

    }

    render() {
        const {classes} = this.props;
        return (
            <FormControl >
                <Select

                    value={this.state.intent}
                    onChange={this.handleChange}
                    input={<BootstrapInput name="age" id="age-customized-select" />}
                >
                    {this.listRow()}
                </Select>
            </FormControl>
        );
    }
}

IntentMatch.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(IntentMatch);
