import React, {useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";


const VoiceAskForContext = (props) => {


    const [commonWorkflows, setCommonWorkflows] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [contextVariable, setContextVariable] = useState(null);
    const [contextVariables, setContextVariables] = useState([]);
    useEffect(() => {

        getAllCommonWorkflows();
        getContext();

    }, []);


    useEffect(() => {
        if (props.value) {

            setSelectedWorkflow({"Id": props.value.nextflow});
            setContextVariable(props.value.context);

        }
    }, [props.value])

    const pass = (e) => {
        let workflow = {
            "type": "voice.askForContext",
            "context": contextVariable,
            "nextflow": selectedWorkflow.Id,
            "sendRestResponse": true
        };

        props.passData("voice.askForContext", workflow);

    }


    const getAllCommonWorkflows = async () => {

        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
            });
            setCommonWorkflows(workflowArray);
        }
    }
    const handleFlowChange = (event, value) => {

        setSelectedWorkflow(value);
    }


    const getContext = async () => {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {
                setContextVariables(Object.keys(data));
            }
        }
    }

    const handleContextChange = (event, value) => {

        setContextVariable(value);
    }
    return (

        <div style={{display: "flex", flexDirection: "column", marginTop: "20px", justifyContent: "space-around"}}>

            <Autocomplete
                options={contextVariables}
                getOptionSelected={(option, value) => option === value}
                onChange={handleContextChange}
                value={contextVariable || null}
                getOptionLabel={option => option}
                style={{width: "100%"}}
                renderInput={params => <TextField {...params} label="Save to Context" variant="outlined"/>}
            />

            <Autocomplete
                options={commonWorkflows}
                getOptionSelected={(option, value) => option.value === value.value}
                onChange={handleFlowChange}
                value={selectedWorkflow || null}
                getOptionLabel={option => option.Id}
                style={{width: "100%", marginTop: "20px"}}
                renderInput={params => <TextField {...params} label="Workflow to Execute" variant="outlined"/>}
            />

            <Button
                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "20px"}}
                variant="contained" color="primary"
                onClick={pass}>Update</Button>
        </div>
    );
};
export default VoiceAskForContext;