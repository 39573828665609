import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class CheckNumberInQueue extends Component {


    constructor(props) {

        super(props);


        this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.pass = this.pass.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.init = this.init.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.getSites = this.getSites.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.loadOpenWorkFlow = this.loadOpenWorkFlow.bind(this);
        this.changeSetFlow = this.changeSetFlow.bind(this);
        this.changeNotSetFlow = this.changeNotSetFlow.bind(this);
        this.handleAddGroup = this.handleAddGroup.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleThresholdChange = this.handleThresholdChange.bind(this);
        this.state = {
            workFlowRender: null,
            commonWorkflows:[{Id: ""}],
            groups: [{name: " "}],
            group: {name: ""},
            anotherGroup: {name: ""},
            onClosed: {Id: ""},
            setFlow: {Id: ""},
            notSetFlow: {Id: ""},
            addedGroups: [],
            threshold: ""



        };


    }

    handleThresholdChange(event){

        this.setState({threshold: event.target.value});
    }

    handleDelete = (e, i) => {

        this.state.addedGroups.splice(i, 1)
        this.setState({addedGroups: this.state.addedGroups});

    }

    renderRow() {

            return this.state.addedGroups.map((object, i) => {
                //console.log(object.entity)
                return <TableRow key={i}>
                    <TableCell component="th" scope="row">
                        {object.name}
                    </TableCell>
                    <TableCell align="right"> <InputAdornment onClick={event => this.handleDelete(event, i)}
                                                              position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></TableCell>
                </TableRow>
            });



    }

    handleAddGroup(event){

        let added = this.state.addedGroups;

        added.push(this.state.anotherGroup);

        this.setState({addedGroups: added})


    }


    changeSetFlow(event, value) {

        if(value && value.Id){
            this.setState({setFlow: {"Id": value.Id}});
        }

    }

    changeNotSetFlow(event, value) {

        if(value && value.Id){
            this.setState({notSetFlow: {"Id": value.Id}});
        }

    }
    loadOpenWorkFlow= (event, status) => {
        switch(status){
            case 'open':
                this.props.openWorkflow(this.state.onOpen.id);
                break;

            case 'busy':
                this.props.openWorkflow(this.state.onBusy.id);
                break;

            case 'closed':
                this.props.openWorkflow(this.state.onClosed.id);
                break;

        }

    }

    openWorkflow = (event, status) => {
        switch(status){
            case 'open':
                this.props.openWorkflow(this.state.onOpen.Id);
                break;

            case 'busy':
                this.props.openWorkflow(this.state.onBusy.Id);
                break;

            case 'closed':

                this.props.openWorkflow(this.state.onClosed.Id);
                break;

        }


    }

    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[2]) {
                    workflowArray.push({"Id": arraySplit[2]});
                }

            });
            //   console.log(this.props.workflowCreate);

            /*if (this.props.workflowCreate) {

                workflowArray.push({"Id": "[New Workflow]"});
            }*/
            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })
            this.setState({commonWorkflows: workflowArray});


        }


    }


    componentDidMount() {


        this.getTokenUser();
        this.getAllCommonWorkflows();




    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            if (this.state.groups) {
                let found = -1;
                this.state.groups.map((object, i) => {

                    if (object.id === this.props.value.groupId) {
                        found = i;

                    }

                });
                if(found !== -1){
                    this.setState({anotherGroup: this.state.groups[found], groupId: this.props.groupId});
                }

            }


        }

    }


    handleGroupIdChange(e) {

        this.setState({groupId: e.target.value})

    }

    handleGroupChange(event, value) {


        if (value && value.name) {
            this.setState({anotherGroup: value, groupId: value.id});

        }

        if (this.props.handleGroupChange) {

            this.props.handleGroupChange(this.props.optionAction, value);
        }

    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            data.map((site) => {

                this.getGroups(site);

            });

        }
    }

    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            this.setState({groups: groups}, this.init);

        }

    }


    init() {

        if (this.props.value) {
            if(this.props.value.threshold){
                this.setState({threshold: this.props.value.threshold})
            }

            if (this.state.groups) {
                let found = [];
                let addedGroups = this.props.value.groups;
                this.state.groups.map((object, i) => {
                    if(addedGroups){
                        addedGroups.map((g)=>{

                            if (object.id === g) {

                               found.push(object);
                            }

                        });
                    }
                });

                this.setState({addedGroups: found, groupId: this.props.value.groupId});
            }
            //console.log(this.props.value);
            if (this.props.value.onMatch) {

                this.setState({
                    setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse},
                    notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse},
                });
            }


        }

    }

    pass() {

        let onMatch = {};
        let onFailure = {};
        let groups = [];

        if(this.state.addedGroups && this.state.addedGroups.length > 0 && this.state.notSetFlow  && this.state.notSetFlow.Id !== "" && this.state.setFlow  && this.state.setFlow.Id !== "" && this.state.threshold !==  ""){

        this.state.addedGroups.map((group)=>{

           groups.push(group.id);

        });

        let workflow = {type: "case.checkNumberInQueue", "groups": groups, "threshold": this.state.threshold, "onMatch": {}, "onFailure": {} };


        if (this.state.setFlow.Id === "Workflow.GoNext") {

            onMatch = {"type": "workflow.goNext"};

        }else{

            onMatch =  {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.setFlow.Id,
                "replace": true,
                "account": this.props.account
            };
            if(this.state.setFlow.Id.indexOf("workflow.goNext")>-1){
                delete onMatch.replace;
            }

            workflow.onMatch = onMatch;
        }

        if (this.state.notSetFlow.Id === "Workflow.GoNext") {

            onFailure = {"type": "workflow.goNext"};

        }else {

            onFailure = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.notSetFlow.Id,
                "replace": true,
                "account": this.props.account
            };

            if(this.state.notSetFlow.Id.indexOf("workflow.goNext")>-1){
                delete onFailure.replace;
            }
            workflow.onFailure = onFailure;
        }


        this.props.passData("case.checkNumberInQueue", workflow);

        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "10px"}}>

                    <FormControl variant="outlined" style={{borderColor: "black", marginTop: "0px"}}
                                 className={classes.formControl}>

                        <Autocomplete
                            id="combo-box"
                            options={this.state.groups}
                            onChange={this.handleGroupChange}
                            value={this.state.anotherGroup}
                            getOptionLabel={option => option.name}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Group" variant="outlined"
                                                              style={{minHeight: "100px"}}/>}
                        />
                        <Button style={{marginTop: "-25px", marginBottom: "10px", width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                onClick={this.handleAddGroup}>Add Group</Button>
                        {this.state.addedGroups.length > 0 &&

                        <TableContainer style={{width: "100%", marginBottom: "50px", marginTop: "10px"}} component={Paper}>
                            <Table className={classes.table} aria-label=" ">
                            <TableHead>
                            <TableRow>
                            <TableCell>Group Name</TableCell>
                            <TableCell align="right"> </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                        {this.renderRow()}
                            </TableBody>
                            </Table>
                            </TableContainer>


                        }
                    </FormControl>

                    <TextField
                        value={this.state.threshold}
                        onChange={this.handleThresholdChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        label="Threshold"
                        style={{whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
                    />
                    <div style={{ marginBottom: "10px", marginTop: "-25px", display: "flex", flexDirection: "column", justifyContent:"space-around"}}>

                    <Autocomplete
                        id="combo-box5"
                        options={this.state.commonWorkflows}
                        onChange={this.changeSetFlow}
                        value={this.state.setFlow}
                        getOptionLabel={option => option.Id}
                        style={{ width: "100%", marginTop: "40px"}}
                        renderInput={params => <TextField {...params} variant="outlined" label="Set"  style={{minHeight: "100px"}} />}
                    />
                    {this.state.edit &&this.state.setFlow && this.state.setFlow.Id !== "Workflow.GoNext"?<Button style={{width: "100%", marginBottom: "10px", marginTop: "-10px"}} variant="contained"
                                                                                                                 color="primary" onClick={event => this.openWorkflow(event, this.state.setFlow)}>Open Workflow</Button>: ""}
                </div>
                    <div style={{ marginBottom: "0px", marginTop: "10px", display: "flex", flexDirection: "column", justifyContent:"space-around"}}>
                        <Autocomplete
                            id="combo-box4"
                            options={this.state.commonWorkflows}
                            onChange={this.changeNotSetFlow}
                            value={this.state.notSetFlow}
                            getOptionLabel={option => option.Id}
                            style={{ width: "100%", marginTop: "0px"}}
                            renderInput={params => <TextField {...params} variant="outlined" label="Not Set"  style={{minHeight: "100px"}} />}
                        />
                        {this.state.edit &&this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext"?<Button style={{width: "100%", marginTop: "-10px"}} variant="contained"
                                                                                                                           color="primary" onClick={event => this.openWorkflow(event, this.state.notSetFlow)}>Open Workflow</Button>: ""}
                    </div>

                    {!this.props.optionActionDisplay ? this.props.value && this.props.value.groupId ?
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                onClick={this.pass}>Update</Button> :
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                onClick={this.pass}>Add</Button> : <div></div>}

                </div>
            </div>

        )
    }

}

export default withStyles(styles)(CheckNumberInQueue);
