import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import MoodIcon from '@material-ui/icons/Mood';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

const charLimit = 950;
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        minWidth: "720px"
    };
}

class ConversationMessage extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.onEmojiClick = this.onEmojiClick.bind(this);
        this.showEmoji = this.showEmoji.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            anchorEl: null,
            showPicker: false,
            optionAction: false,
            open: false,
            openMenu: false


        }
        this.convRef = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.queryGPT = this.queryGPT.bind(this);
    }

    handleClick(event) {

        this.setState({anchorEl: event.currentTarget, openMenu: Boolean(event.currentTarget)})
    }

    handleClose(event) {

        if(!this.state.originalText){

            this.setState({originalText: this.state.textFieldValue});
        }

        if (event.target.innerText === "Original") {

            this.setState({textFieldValue: this.state.originalText});

        } else {

            const q = "Can you act as content writer and please take the text after the colon make sure to answer back in the same language and answer only with the updated text and make sure that the updated text maximum length is 450 characters and make it " + event.target.innerText + ": " + this.state.textFieldValue;

            this.queryGPT(q);

        }
        this.setState({anchorEl: null, openMenu: false})


    }

    componentDidMount() {

        if (this.props.value) {
            //console.log(this.props.value.message.length)
            this.setState({textFieldValue: this.props.value.message, charCounter: this.props.value.message.length});
            this.setState({originalText: this.props.value.message});
        }
        if (this.convRef && this.convRef.current) {
            // this.convRef.current.focus();
        }

        if (this.props.optionAction) {

            this.setState({optionAction: this.props.optionAction});
        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            //console.log(this.props.value);
            this.setState({textFieldValue: this.props.value.message, charCounter: this.props.value.message.length});


        }

    }

    showEmoji(event) {

        if (this.state.showPicker) {

            this.setState({showPicker: false});
        } else {
            this.setState({showPicker: true});
        }

    }

    pass() {

        let workflow = {type: "conversation.message", "message": this.state.textFieldValue};
        this.props.passData("conversation.message", workflow);


    }

    async queryGPT(query) {

        let url = '/api/queryChatGpt?query=' + query;
        const response = await fetch(url);

        const data = await response.json();


        this.setState({textFieldValue: data, charCounter: data.length});
    }


    handleChange(e) {
        let stringValue = e.target.value;
        if (stringValue.length > charLimit) {

            stringValue = stringValue.substring(0, charLimit - 1);

        }
        this.setState({textFieldValue: stringValue, charCounter: e.target.value.length});

        if (this.props.option) {

            let val = "";
            if (e.target.value === "") {
                val = "";

            } else {
                val = e.target.value;

            }
            let workflow = {type: "conversation.message", "message": val};
            this.setState({workflow: workflow});
            this.props.onChange(workflow);


        }
    }

    onEmojiClick = (event) => {

        let value = this.state.textFieldValue;

        value = value + event.native;

        this.setState({textFieldValue: value, showPicker: false});

        let workflow = {type: "conversation.message", "message": value};
        this.setState({workflow: workflow});
        if (this.props.onChange) {
            this.props.onChange(workflow);
        }


    };

    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>

                <div style={{display: "flex", flexDirection: "row", width: "100%"}}><HelpIcon onClick={() => {
                    this.setState({open: true})
                }}/>
                    {this.props.roles && (this.props.roles.indexOf("Admin") > -1 || this.props.roles.indexOf("chatgpt") > -1) && <Button style={{display: "flex", marginLeft: "59%"}} aria-controls="fade-menu" aria-haspopup="true"
                            onClick={this.handleClick}>
                    AI Assisted
                </Button>}
                    <Menu
                        id="fade-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={this.state.openMenu}
                        onClose={(event) => {
                            this.setState({anchorEl: null, openMenu: false});
                        }}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={this.handleClose}>Original</MenuItem>
                        <MenuItem onClick={this.handleClose}>More friendly</MenuItem>
                        <MenuItem onClick={this.handleClose}>More formal</MenuItem>
                        <MenuItem onClick={this.handleClose}>Longer</MenuItem>
                        <MenuItem onClick={this.handleClose}>Shorter</MenuItem>
                    </Menu>
                </div>
                <TextField
                    value={this.state.textFieldValue}
                    onChange={this.handleChange}
                    margin="normal"
                    multiline={true}
                    minRows={15}
                    ref={this.convRef}
                    variant="outlined"
                    label={this.props.label}
                    style={{whiteSpace: "pre-wrap", wordBreak: "keep-all"}}
                />
                <div style={{display: "flex", flexDirection: "row", width: "100%"}}><MoodIcon onClick={this.showEmoji}/><span
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>{this.state.charCounter} (950)</span></div>


                {this.state.showPicker ? <Picker style={{
                    position: this.state.optionAction === true ? "relative" : "absolute",
                    bottom: this.state.optionAction ? '550px' : '325px',
                    right: this.state.optionAction ? '0px' : '15px',
                    background: "white",
                    zIndex: "5"
                }} set='apple' onSelect={this.onEmojiClick}/> : <div></div>}


                {!this.props.option ?
                    <div style={{marginTop: "30px"}}>
                        {this.props.value && this.props.value.message ?
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Update</Button> :
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}


                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={() => {
                        this.setState({open: false})
                    }}
                >
                    <div style={getModalStyle()} className={classes.modal}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row", width: "100%"}}><span style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}><CloseIcon onClick={() => {
                                this.setState({open: false})
                            }}/></span></div>
                            <img style={{imageRendering: "pixelated"}}
                                 src="https://content.psplugin.com/images/bottrainer/formatting.png"/>

                        </div>
                    </div>
                </Modal>
            </div>


        )
    }

}

export default withStyles(styles)(ConversationMessage);
