import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {  saveNewIntegration, getBotConfig } from "./reused/reusedfunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
const getModalStyle = function () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    height: "90vh",
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 4, 3),
    marginTop: "20px",
    marginBottom: "20px",
  },

  modal: {
    position: "absolute",
    width: theme.spacing(160),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: "100vh",
    outline: "none",
  },
  listItemTextSelected: {
    color: "#000000",
    fontSize: "12px",
    "&.Mui-selected": {
      backgroundColor: "#3E51B5",
      color: "#FFFFFF",
    },
  },
  listItemText: {
    paddingLeft: "20px",
    fontSize: "15px",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
    overflow: "hidden",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },
}));


const newIntegrationTemplate = `(function (BOT, CONF) {
  /*Start Authentication Code Template Start Authentication Code*/
  function requestToken() {
    /*STARTAUTHOBJECT*/
    var CONF_AUTHENTICATION = {
      host: "",
      client_secret: "",
      client_id: "",
      method: "POST",
    };
    /*ENDAUTHOBJECT*/
    var options = {
      url: CONF_AUTHENTICATION.host,
      method: "POST",
      body:
        "grant_type=client_credentials&client_secret=" +
        CONF_AUTHENTICATION.client_secret +
        "&client_id=" +
        CONF_AUTHENTICATION.client_id,
    };
    BOT.request(options, function (error, response, body) {
      if (error) {
        BOT.LOGGER.log({
          entity: "Token Error",
          version: 0,
          type: "log",
          conversationId: null,
          caseId: null,
          description: "Token Error",
          json: {
            Error: error,
          },
        });
      } else {
        BOT.LOGGER.log({
          entity: "Token",
          version: 0,
          type: "log",
          conversationId: null,
          caseId: null,
          description: "Token",
          json: {
            body: body,
          },
        });
        BOT.template_token = JSON.parse(body);
      }
    });
  }

  if (!BOT.template_token) {
    requestToken();
    BOT.TIMER_3600secActions.template_token_renew = function () {
      requestToken();
    };
  }

  /*End Authentication Code Template End Authentication Code*/

  /*##AUTHENTICATIONCODE##*/

  var service = {
    /*StartIntegrationName GetIntegration StartIntegrationName*/
    GetIntegration: function (conversationId) {
      /*ACTION USING AUTH*/

      let url = "RESTURL";
      let token = "Bearer " + BOT.template_token.access_token;
      let headerObject = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: token,
      };

      let options = {
        headers: headerObject,
        url: url,
        method: "GET",
        body: JSON.stringify({}),
      };

      BOT.request(options, function (error, response, body) {
        if (error) {
          BOT.LOGGER.log({
            entity: "GetIntegration Error",
            version: 0,
            type: "log",
            conversationId: conversationId,
            caseId: null,
            description: "GetIntegration Error",
            json: {
              Error: error,
            },
          });
        } else {
          var resp;

          try {
            resp = JSON.parse(body);
          } catch (err) {
            BOT.LOGGER.log({
              entity: "GetIntegration Error",
              version: 0,
              type: "log",
              conversationId: conversationId,
              caseId: null,
              description: "GetIntegration Error",
              json: {
                Error: err,
              },
            });
          }

          if (resp) {
            BOT.LOGGER.log({
              entity: "GetIntegration Response",
              version: 0,
              type: "log",
              conversationId: conversationId,
              caseId: null,
              description: "GetIntegration Response",
              json: {
                options: options,
                response: resp,
              },
            });
            BOT.WORKFLOW.runNextAction(conversationId, {
              type: "GetIntegration",
              messageType: "response",
              response: resp,
            });
          }
        }
        return;
      });
    },
    /*EndIntegrationName GetIntegration EndIntegrationName*/
    /* ##ADDANOTHERINTEGRATIONPLACEHOLDER## */
  };
  return service;
})(BOT, CONF);`;

let manifestTemplate = {
  name: "Integration Name",
  description: "Integration Description",
  example: {}
};


const AddIntegration = (props) => {
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [integrationName, setIntegrationName] = useState("");
  const classes = useStyles();
  const [library, setLibrary] = useState("");
  const [code, setCode] = useState(newIntegrationTemplate);
  const [manifest, setManifest] = useState(
    JSON.stringify(manifestTemplate, null, "\t")
  );
  const [integrationSaved, setIntegrationSaved] = useState(false);
  const [libraries, setLibraries] = useState();
  const [libraryValue, setLibraryValue] = useState(null);
  const [integrationDescription, setIntegrationDescription] = useState("");
  useEffect(() => {
    if (props.openModal) {
      setOpenModal(props.openModal);
    }
  }, [props.openModal]);

  const getConfig = async () => {
    const data = await getBotConfig();
    setLibraries(data?.addOnLibraries);
  };

  useEffect(() => {
    getConfig();
  }, []);
  
  const handleOnClose = () => {
    setOpenModal(false);
    props.clearOpen();
  };

  const changeLibrary = (event, value) => {
    setLibraryValue(value);
  };

  const saveIntegration = async (event) => {
    
    if (integrationName && libraryValue && manifest && code) {

      manifestTemplate.description = integrationDescription;
      manifestTemplate.name = integrationName;

      const status = await saveNewIntegration(
        integrationName,
        libraryValue,
        JSON.stringify(manifestTemplate, null, "\t"),
        code
      );
      if (status) {
        setIntegrationSaved(integrationName);
        setOpenModal(false);
        props.notify(
          "New Integration: " + integrationName + " Successfully Saved"
        );
        
      }
    }
  };
  const changeManifest = (event) => {
    try {
      const parsedObject = JSON.parse(manifest);
      if (parsedObject) {
        setManifest(JSON.stringify(parsedObject, null, "\t"));
      }
    } catch (e) {
      setManifest(manifest);
    }
  };

  const handleManifestChange = (event) => {
    setManifest(event.target.value);
  };
  return (
    <Modal
      aria-labelledby="Edit-Workflow-Name"
      aria-describedby="Edit-Workflow-Name"
      open={openModal}
      onClose={handleOnClose}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginLeft: "20px",
            marginRight: "20px",
            height: "100%",
          }}
        >
          <TextField
            label="Integration Name"
            style={{
              width: "100%",
              height: "60px",
            }}
            value={integrationName}
            onChange={(event) => {
              setIntegrationName(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Integration Description"
            style={{
              width: "100%",
              height: "60px",
            }}
            value={integrationDescription}
            onChange={(event) => {
              setIntegrationDescription(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />
         

          <TextField
            label="Javascript Code"
            style={{
              width: "100%",
            }}
            multiline
            rows={28}
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />

          <Autocomplete
            options={libraries}
            value={libraryValue ? libraryValue : null}
            onChange={changeLibrary}
            getOptionSelected={(option, value) =>
              option && value && option.Model === value.Model
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Library" />
            )}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                width: "200px",
              }}
              onClick={() => {
                setOpenModal(false);
                props.clearOpen();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "300px",
              }}
              onClick={saveIntegration}
            >
              Save New Integration
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddIntegration;
