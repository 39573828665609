import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class SetCaseType extends Component {


    constructor(props) {

        super(props);
        this.pass = this.pass.bind(this);
        this.getTokenUser = this.getTokenUser.bind(this);
        this.getSites = this.getSites.bind(this);
        this.getCaseMappings = this.getCaseMappings.bind(this);
        this.renderCasesMenuItems = this.renderCasesMenuItems.bind(this);
        this.handleCaseChange = this.handleCaseChange.bind(this);

        this.state = {
            workFlowRender: null,
            caseValue: "",
            cases: []
        };

    }
    renderCasesMenuItems() {
        if (this.state.cases) {
            return this.state.cases.map((value) => {

                return <MenuItem key={Math.random()} value={value.id}>{value.name}</MenuItem>

            });
        }

    }

    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            data.map((site) => {

                this.getCaseMappings(site);


            });

        }
    }

    async getCaseMappings(site) {

        let url = '/api/getCases?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let cases = [...data, ...this.state.cases];


            this.setState({cases: cases});

        }
    }


    componentDidMount() {
        this.getTokenUser();
        if(this.props.value){

            this.setState({"caseValue": this.props.value.caseTypeId});

        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({"caseValue": this.props.value.caseTypeId});

        }

    }



    pass(){
        let workflow = {type: "case.setCaseType", "caseTypeId": this.state.caseValue};
        this.state.cases.map((c)=>{

            if(c.id === this.state.caseValue){

                workflow.caseName = c.name;

            }

        })


        this.props.passData("case.setCaseType", workflow);


    }

    handleCaseChange(e) {

        this.setState({caseValue: e.target.value});

    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column",  marginTop: "15px"}}>
                    <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                 className={classes.formControl}>
                        <TextField
                            variant="outlined"
                            label="Case Type"
                            select
                            value={this.state.caseValue}
                            classes={{root: classes.select}}
                            onChange={this.handleCaseChange}
                        >
                            {this.renderCasesMenuItems()}


                        </TextField>
                    </FormControl>
                    {this.props.value && this.props.value.caseTypeId ? <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "15px"}} variant="contained"  onClick={this.pass}>Update</Button>: <Button  style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "15px"}} variant="contained"    onClick={this.pass}>Add</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(SetCaseType);
