// App.js
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import clsx from 'clsx';
import {Link, Route, withRouter, Redirect} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {withStyles, MuiThemeProvider, createTheme, responsiveFontSizes} from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer/Drawer";
import MenuList from "@material-ui/core/es/MenuList/MenuList";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ShowIntent from './components/ShowIntent';
import Queries from './components/Queries';
import Settings from './components/Settings';
import Trainingdata from "./components/Trainingdata";
import ConvertData from "./components/ConvertData";
import TestTrainingData from "./components/TestTrainingData";
import classNames from 'classnames';
import TrainingChat from "./components/TrainingChat";
import ResponseWorkflow from "./components/ResponseWorkflow";
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WorkflowStats from './components/WorkflowStats';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import {ListItemText} from "@material-ui/core";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import SettingsIcon from '@material-ui/icons/Settings'
import Notifier from "./components/Notifier";
import Dialogs from "./components/Dialogs";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import SaveModelAs from "./components/SaveModelAs";

import CreateBot from "./components/BotCreate.js";
import CreateToken from "./components/CreateToken";
import Links from "./components/Links";
import Actions from "./components/Actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateIntegration from "./components/CreateIntegration";
import WorkflowLog from "./components/WorkflowLog";
import CustomerJourney from "./components/CustomerJourney";
import Entities from "./components/Entities";
import Context from "./components/Context";
import {getBaseUrl, getDomains} from "./components/reused/reusedfunctions.js";
import FlowOverview from "./components/FlowOverview";
import TestBot from "./components/TestBot";
import BotAdmin from './components/Botadmin.js';
let drawerWidth = 240;
let interval = null;
const windowWidth = window.innerWidth;

class App extends Component {
    componentWillUnmount() {
        this.unlisten();
    }
    componentDidUpdate(prevProps) {
        // Variables to track state
        let bannersRemoved = false;
        let closeButtonClicked = false;

        // Check if the route has changed
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // Added timeout to remove banners on route change
            setTimeout(() => {
                if (!bannersRemoved && window.vngage) {
                    window.vngage.removeAllBanners();
                    bannersRemoved = true;
                }
            }, 300);

            setTimeout(() => {
                const closeButton = document.querySelector('#vngage-chat-list > li.dialog-completedmessage > a.menubutton.vngage-btn.btn-leave.vngage-btn-cancel.vngage-focusable');
                if (closeButton) {
                    try {
                        closeButton.click();
                        // Set the variable to true when the button is clicked
                        closeButtonClicked = true;
                    } catch (error) {
                        console.error('Error clicking the button:', error);
                    }
                }
            }, 600);

            // A separate timeout to ensure that removeAllBanners is called after clicking the button
            setTimeout(() => {
                if (closeButtonClicked) {
                    window.vngage.removeAllBanners();
                }
            }, 800);
        }
    }
    componentDidMount() {

        this.unlisten = this.props.history.listen((location, action) => {
            if (window.vngage) {
                window.vngage.clearSession();
                window.vngage.evaluateOpportunities();
                window.vngage.leave();
            }
        });


        var first = ["responseworkflow", "trainingdata", "customerjourney", "links", "testtrainingdata", "trainingchat"];
        var second = ["conversations", "confidence", "intent", "workflowstats"];
        var found = false;
        this.getConf();

        first.map((obj) => {
            if (window.location.href.indexOf(obj) > -1) {
                found = true;


                this.setState({
                    selectedFirst: 1,
                    selected: -1,
                    openSubTrain: true,
                    openSubAdmin: false,
                    openSub: false
                });
                if (obj === "responseworkflow") {

                    this.setState({selected: 0})
                }
            }

        });
        second.map((obj) => {
            if (window.location.href.indexOf(obj) > -1) {
                found = true;
                this.setState({
                    selectedFirst: 2,
                    selected: -1,
                    openSubAdmin: false,
                    openSubTrain: false,
                    openSub: true
                });
            }

        });

        if (!found) {

            this.setState({selectedFirst: 1, selected: 0, openSubAdmin: false, openSubTrain: true, openSub: false});
        }

        this.getLoginStatus();
        this.getLoggedInUser("");
        this.getDomain();
    }


    constructor(props) {

        super(props);
        this.state = {
            keycloak: null,
            openModal: false,
            authenticated: false,
            models: [],
            model: "",
            loading: false,
            newModel: '',
            openSub: false,
            openSubAdmin: false,
            openSubTrain: true,
            saveModel: false,
            savedModels: [],
            open: true,
            pipeline: "",
            loggedInUser: '',
            anchorEl: null,
            notifierOpen: false,
            openIntent: '',
            selectedFirst: 1,
            selected: 0

        };
        this.passData = this.passData.bind(this);
        this.resetNewTrainingData = this.resetNewTrainingData.bind(this);
        this.getModels = this.getModels.bind(this);
        this.setModel = this.setModel.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.setPipeline = this.setPipeline.bind(this);
        this.newModelSaved = this.newModelSaved.bind(this);
        this.getLoggedInUser = this.getLoggedInUser.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.modelDeleted = this.modelDeleted.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
        this.handleExpandTraining = this.handleExpandTraining.bind(this);
        this.getLoginStatus = this.getLoginStatus.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.isModelReady = this.isModelReady.bind(this);
        this.changeState = this.changeState.bind(this);
        this.checkIfModelIsReady = this.checkIfModelIsReady.bind(this);
        this.listAccounts = this.listAccounts.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.updateApplication = this.updateApplication.bind(this);
        this.updateSelected = this.updateSelected.bind(this);
        this.openLink = this.openLink.bind(this);
        this.updateModel = this.updateModel.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getDomain = this.getDomain.bind(this);
        this.handleExpandAdmin = this.handleExpandAdmin.bind(this);
        this.checkIfR4ModelIsReady = this.checkIfR4ModelIsReady.bind(this);
        this.ensureModel = this.ensureModel.bind(this);
        this.trainStatus = this.trainStatus.bind(this);
        this.getTrainingInfoToDb = this.getTrainingInfoToDb.bind(this);
        this.writeTrainingInfoToDb = this.writeTrainingInfoToDb.bind(this);
        this.ensureModelFunction = this.ensureModelFunction.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.openOverview = this.openOverview.bind(this);
        this.openInLog = this.openInLog.bind(this);
        this.openInConversations = this.openInConversations.bind(this);
        this.getConf = this.getConf.bind(this);
        this.loadScript = this.loadScript.bind(this);
    }

    async getConf(){

        const domains = await getDomains();
        const baseUrl = await getBaseUrl();
        this.setState({domains: domains?domains: null, baseUrl: baseUrl?baseUrl:null});
    }
    openInConversations = (caseId) =>{

        this.setState({caseId: caseId},()=>{
            this.props.history.push('/conversations');
        })


    }

    openInLog = (convId) =>{
        this.setState({convId: convId},()=>{
            this.props.history.push('/actions');
        })


    }
    openOverview = (intentName, model) => {
        this.updateSelected(0);
        this.setState({selectedFirst: 1});
        this.setState({model: model, openOverview: intentName });
    }

    async writeTrainingInfoToDb(model) {

        let url = "/api/writeTrainingInfo?model=" + model;

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.state.trainingInfo),
            headers: {'Content-Type': 'application/json'}

        });

        const data = await response.json();

    }

    async getTrainingInfoToDb(model) {

        let url = "/api/getTrainingInfo?model=" + encodeURI(model);

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        if (data.length > 0) {

            if (data[0].Traininginfo) {
                let trainingInfo;
                try {
                    trainingInfo = JSON.parse(data[0].Traininginfo);

                    this.setState({trainingInfo: trainingInfo});

                } catch (e) {

                }
            }
        }
    }


    handleExpandAdmin(event) {

        if (this.state.openSubAdmin) {
            this.setState({openSubAdmin: false});
        } else {
            this.setState({openSubAdmin: true, open: true});
        }

    }

    async getDomain() {

        let url = "/api/getRestDomain";
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'

            }

        });

        const data = await response.json();
        if (data) {

            this.setState({restDomain: data.restDomain});
        }

    }

    refresh() {

        this.setState({refresh: "true"});
    }

    updateSelected(selectedIndex) {

        this.setState({selected: selectedIndex});

    }

    handleCloseModal(e) {

        this.setState({openModal: false});

    }

    updateApplication(accountId) {
        this.getLoggedInUser(accountId);

        //setTimeout(function(){window.location.reload()}, 1500);
    }

    handleAccountChange(e, value) {

        if (value && value.id) {

            this.setState({changeAccount: true, openModal: false, anchorEl: null});
            this.setState({accountObject: value, accountName: value.name.toLowerCase()});

            this.updateApplication(value.id);
            localStorage.removeItem("savedFilters");
            localStorage.removeItem("searchType");
            localStorage.removeItem("model");
            localStorage.removeItem("updatedQueries");
            this.setState({selected: 0, selectedFirst: 1});

        }
    }
    loadScript = () => {
        this.setState({loadScript: true});
    }

    listAccounts() {
        if (this.state.accountArray && this.state.accountNameArray) {
            return this.state.accountArray.map((object, i) => {
                return <ListItem key={i} classes={{root: this.props.classes.listItemTextSelected}}
                                 value={object}>{this.state.accountNameArray[i]}</ListItem>

            })

        }

    }

    changeState() {

        this.setState({notifierOpen: false});
    }

    updateModel = (value) => {
        if (value === "Tax") {

            value = "esci";
        }
        this.setState({model: value});

    }

    openWorkflow = (value) => {

        this.setState({workflowToOpen: value, selected: 0});

    }

    async handleLogout() {

        let url = '/logoff';
        window.location.href = "https://"+this.state.baseUrl+"logout";
    }

    checkIfModelIsReady = (model) => {

        this.setState({loading: true, fixedModelName: model});
        interval = setInterval(() => {
            this.isModelReady()
        }, 10000);

    };

    checkIfR4ModelIsReady = (model, token) => {
        this.setState({token: token});
        this.setState({loading: true, fixedModelName: model});

        interval = setInterval(() => {
            this.trainStatus(model);
        }, 10000);


    };

    ensureModelFunction = async (modelname, token) => {

        if (this.state.token) {
            this.ensureModel(modelname);
        } else {
            this.setState({token: token}, () => {
                this.ensureModel(modelname);
            });
        }
        const ti = await this.getTrainingInfoToDb(modelname);
        let trainingInfo = this.state.trainingInfo;

        if (trainingInfo) {
            trainingInfo.ensureRasaDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
            this.setState({trainingInfo: trainingInfo}, () => {
                this.writeTrainingInfoToDb(modelname);
            });
        }
    }

    async ensureModel(modelname) {

        let url = "https://"+this.state.domains.restDomain.default+"/EnsureRasaServer/" + modelname;
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.state.token)

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const response = await fetch(url, requestOptions);

        const data = await response.text();

        if (data) {

            console.log("Ensure started", data);
        }

    }

    async trainStatus(modelname) {

        let url = "https://"+this.state.domains.restDomain.default+"/TrainStatus/" + modelname;
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.state.token)

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(url, requestOptions);

        const data = await response.json();

        if (data && data.phase) {
            if (!this.state.r4StartTime) {

                if (data.startTime) {
                    this.setState({r4StartTime: data.startTime});
                }

            }

            if (data.phase === "Succeeded") {
                clearInterval(interval);
                let endTime;
                if (data.conditions) {

                    data.conditions.map((d) => {
                        if (d.type === "ContainersReady") {
                            endTime = d.lastTransitionTime;
                            this.setState({"r4FinishTime": d.lastTransitionTime});
                        }
                    });
                }
                if (endTime) {
                    let startTime = new Date(this.state.r4StartTime);
                    let end = new Date(endTime);
                    let d3 = end - startTime;
                    let durationMinutes = moment.duration(d3).minutes();
                    let durationseconds = moment.duration(d3).seconds();
                    durationseconds = durationseconds.toString().padStart(2, '0');
                    this.setState({trainingtimeElapsed: durationMinutes + ":" + durationseconds});
                }
                const ti = await this.getTrainingInfoToDb(modelname);

                let trainingInfo = this.state.trainingInfo;
                trainingInfo.ensureRasaDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
                this.setState({trainingInfo: trainingInfo}, () => {
                    this.writeTrainingInfoToDb(modelname);
                });

                this.ensureModel(modelname);

                this.setState({loading: false});
                this.setState({modelTrained: this.state.fixedModelName});
                this.setState({trainingComplete: this.state.fixedModelName + " trained successfully"});
                this.setState({notifierTrainOpen: true});

            }

        }


    }

    async isModelReady() {


        let url = "https://" + this.state.restDomain + "/IsModelReady/" + this.state.fixedModelName;

        const response = await fetch(url);

        const data = await response.json();

        if (data.ready) {
            clearInterval(interval);
            this.setState({loading: false});
            this.setState({modelTrained: this.state.fixedModelName});
            this.setState({trainingComplete: this.state.fixedModelName + " trained successfully"});
            this.setState({notifierTrainOpen: true})
        }

        return data.ready;

    }

    async getLoggedInUser(loggedInUser) {
        let url = '/api/userinfo';
        if (loggedInUser && loggedInUser !== "") {
            url = url + "?account=" + loggedInUser;
        }
        const response = await fetch(url);
        const data = await response.json();

        let account = data.account;
        if (loggedInUser && loggedInUser !== "") {
            this.getSavedModels();
        }
        if (!account) {
            account = "poc";
        }

        this.setState({accountNumber: account});
        this.setState({
            loggedInUser: data.username,
            email: data.email,
            account: account,
            name: data.name,
            accountArray: data.accountArray,
            roles: data.roles
        }, () => {
        });
        let mergedAccountArray = [];
        
        if(loggedInUser ==="") {

            if(data.accountArray){
                let accountName = data.accountArray[0].split("##")[1];
                data.accountArray.map( (accountString) =>{

                    const accountSplit = accountString.split("##");
                    if(accountSplit && accountSplit.length > 0 && account === accountSplit[0]){
                        accountName = accountSplit[1];
                    }

                })



                this.setState({
                    accountObject: {"name": accountName, "id": account},
                    accountName: accountName.toLowerCase()
                });
            }
        }
        if(data) {
            data.accountArray.forEach((d, i) => {

                let objectArray = d.split("##");
                const obj = {"name": objectArray[1], "id": objectArray[0]};
                mergedAccountArray.push(obj);

            });

            this.setState({mergedAccountArray: mergedAccountArray});
        }
    }

    setPipeline = (pipeline) => {

        //localStorage.setItem("pipeline", pipeline);
        this.setState({pipeline: pipeline});

    }


    getModels() {

        fetch('/api/getModels')
            .then(response => {

                response.json()

                    .then(data => {

                        if (data && data.length > 0 && data.name !== 'error') {

                            this.setState({models: data});

                            //this.setState( {model: this.state.newModel});
                        }
                    })
            })


    }

    openLink = (id, type, model) => {


        if (type === "Workflow") {

            this.setState({workflowToOpen: id});
        } else if (type === "IntentLog") {

            this.setState({openIntent: {"intent": id}, model: model});

        } else {

            this.setState({openIntentResponse: {"intent": id}, model: model});
        }

    }

    passData = (intent, query, model) => {


        this.setState({dataToUse: {"intent": intent, "query": query, "model": model}});

    };


    resetNewTrainingData() {

        this.setState({dataToUse: null});

    }

    async getLoginStatus() {

        let url = '/api/getSavedModels';
        const response = null;
        try {
            const response = await fetch(url);
        } catch (error) {

            window.location.reload();
            console.log(error);

        }


    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            this.setState({savedModels: data});

            if (data.length === 2) {
                this.setState({model: data[1].Model});

            } else {
                this.setState({model: data[0].Model});
            }


            let pipeLine = [];
            let reducedPipline = [];
            data.map((obj) => {

                if (!pipeLine.includes(obj.Pipeline)) {

                    pipeLine.push(obj.Pipeline);
                }

            });

            pipeLine.map((object) => {
                reducedPipline.push({"Pipeline": object});
            });

            this.setState({savedPipelines: reducedPipline});
            if (reducedPipline.length === 1) {

                this.setState({pipeline: reducedPipline[0].Pipeline});

            }
            if (this.state.changeAccount) {

                this.setState({changeAccount: false});
                this.props.history.push('/responseworkflow');
                // we need to reload the page so ps.js re-initialises
                setTimeout(() => {
                    if (window.vngage) {
                        window.vngage.clearSession();
                        window.vngage.evaluateOpportunities();
                        window.vngage.leave();
                    }
                    window.location.reload();

                }, 500);

            }
        }

    }

    setModel = (model) => {

        //localStorage.setItem("model", model);
        this.setState({model: model});

        //this.queryIntentResponse(model);

    }

    modelDeleted() {

        this.getSavedModels();

    };


    newModelSaved = (newModelName) => {
        this.getSavedModels();
        this.setState({model: newModelName});

    };


    handleDrawerClose = () => {
        if (this.state.open) {

            this.setState({open: false});

            if (this.state.openSub) {

                this.setState({openSub: false});
            }
            if (this.state.openSubAdmin) {

                this.setState({openSubAdmin: false});
            }
            if (this.state.openSubTrain) {

                this.setState({openSubTrain: false});
            }

        } else {
            this.setState({open: true});
        }


    };

    handleClose(event) {
        if (event.target.innerHTML.indexOf("Logout") > -1) {

            this.handleLogout();
        }
        this.setState({anchorEl: null});
    }

    handleClick(event) {

        this.setState({anchorEl: event.currentTarget})


    }

    handleExpand(event) {

        if (this.state.openSub) {
            this.setState({openSub: false});
        } else {
            this.setState({openSub: true, open: true});
        }
    }

    handleExpandTraining(event) {

        if (this.state.openSubTrain) {
            this.setState({openSubTrain: false});
        } else {
            this.setState({openSubTrain: true, open: true});
        }
    }


    render() {
        const {classes, theme} = this.props;
        const {open, openSub, openSubTrain, openSubAdmin} = this.state;


        function getModalStyle() {
            const top = 50;
            const left = 50;

            return {
                top: `${top}%`,
                left: `${left}%`,
                transform: `translate(-${top}%, -${left}%)`,
            };
        }

        return (

            <div className={classes.root}>
                <CssBaseline/>

                <Drawer
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    variant="permanent"
                    anchor="left"
                    open={open}
                >

                    <div className={classes.drawerHeader}>

                        {this.state.open && <IconButton onClick={this.handleDrawerClose}>
                            <div style={{display: "flex", alignItems: "center", height: "100%", fontWeight: "700"}}>

                                <img src="https://puzzel-app1.azurewebsites.net/img/puzzel_logo_colour.png"
                                     width="100px" style={{
                                    height: "auto",
                                    width: "100%",
                                    maxWidth: "150px",
                                    maxHeight: "60px",
                                    flex: "0"
                                }}/><ChevronLeftIcon
                                style={{color: "white", marginLeft: "30px", marginRight: "-20px"}}/></div>
                        </IconButton>}


                        {!this.state.open && <IconButton onClick={this.handleDrawerClose}>
                            <div style={{
                                display: "flex",
                                marginLeft: "18px",
                                alignItems: "center",
                                height: "100%",
                                fontWeight: "700"
                            }}>
                                <img src="https://admin.puzzel.com/admin/Content/Img/Logon/MenuPuzzelLogo.png" style={{
                                    height: "auto",
                                    width: "35px",
                                    minWidth: "30px"

                                }}/>
                                <ChevronRightIcon style={{color: "white", marginRight: "-25px"}}/></div>
                        </IconButton>}
                    </div>
                    <Divider/>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: "16px",
                        marginRight: "16px",
                        overflow: "hidden",
                        height: "100%",

                    }}>
                        <MenuList style={{display: "flex", flexDirection: "column"}}>

                            <ListItem selected={this.state.selectedFirst === 1} onClick={() => {

                                this.handleExpandTraining();
                            }} classes={{root: classes.listItem}} style={{color: 'white', marginBottom: "10px"}}
                                      button>
                                <ListItemIcon>
                                    <DirectionsRunIcon style={{color: 'white'}}/>
                                </ListItemIcon>
                                <ListItemText style={{color: 'white', fontWeight: "bold"}} primary="Training"/>
                                {this.state.openSubTrain ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>

                            <Collapse in={openSubTrain} timeout="auto" unmountOnExit>
                                <List component="div">

                                    <MenuItem onClick={() => {
                                        this.updateSelected(0);
                                        this.setState({selectedFirst: 1});
                                    }}
                                              selected={this.state.selected === 0} component={Link}
                                              to="/responseworkflow"
                                              classes={{root: classes.menuItem}}>
                                        Workflows
                                    </MenuItem>


                                    <MenuItem onClick={() => {
                                        this.updateSelected(1);
                                        this.setState({selectedFirst: 1});
                                    }}
                                              selected={this.state.selected === 1} component={Link} to="/trainingdata"
                                              classes={{root: classes.menuItem}}>
                                        Intents
                                    </MenuItem>



                                    <React.Fragment>
                                        <MenuItem onClick={() => {
                                            this.updateSelected(2);
                                            this.setState({selectedFirst: 1});
                                        }}
                                                  selected={this.state.selected === 2} component={Link} to="/entities"
                                                  classes={{root: classes.menuItem}}>
                                            Entities
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            this.updateSelected(3);
                                            this.setState({selectedFirst: 1});
                                        }}
                                                  selected={this.state.selected === 3} component={Link} to="/context"
                                                  classes={{root: classes.menuItem}}>
                                            Context
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            this.updateSelected(4);
                                            this.setState({selectedFirst: 1});
                                        }}
                                                  selected={this.state.selected === 4} component={Link}
                                                  to="/customerjourney"
                                                  classes={{root: classes.menuItem}}>
                                            Journeys
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            this.updateSelected(5);
                                            this.setState({selectedFirst: 1});
                                        }}
                                                  selected={this.state.selected === 5} component={Link} to="/links"
                                                  classes={{root: classes.menuItem}}>
                                            Links
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                            this.updateSelected(6);
                                            this.setState({selectedFirst: 1, loadScript: true});
                                        }}
                                                  selected={this.state.selected === 6} component={Link}
                                                  to="/testtrainingdata"
                                                  classes={{root: classes.menuItem}}>
                                            Test
                                        </MenuItem>

                                        {/* <MenuItem onClick={() => {
                                            this.updateSelected(7);
                                            this.setState({selectedFirst: 1});
                                        }}
                                                  selected={this.state.selected === 7} component={Link}
                                                  to="/trainingchat"
                                                  classes={{root: classes.menuItem}}>
                                            Visual Trainer
                                        </MenuItem>*/}



                                    </React.Fragment>



                                </List>
                            </Collapse>

                            <ListItem selected={this.state.selectedFirst === 2} onClick={() => {

                                this.handleExpand();
                            }} classes={{root: classes.listItem}} style={{color: 'white', marginBottom: "10px"}}
                                      button>
                                <ListItemIcon>
                                    <TrendingUpIcon style={{color: 'white'}}/>
                                </ListItemIcon>
                                <ListItemText style={{color: 'white', fontSize: '20px', fontWeight: "bold"}}
                                              primary="Statistics"/>
                                {this.state.openSub ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>

                            <Collapse in={openSub} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <MenuItem onClick={() => {
                                        this.updateSelected(8);
                                        this.setState({selectedFirst: 2});
                                    }}
                                              selected={this.state.selected === 8} component={Link} to="/conversations"
                                              classes={{root: classes.menuItem}}>
                                        Conversations
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        this.updateSelected(9);
                                        this.setState({selectedFirst: 2});
                                    }}
                                              selected={this.state.selected === 9} component={Link} to="/confidence"
                                              classes={{root: classes.menuItem}}>
                                        Query Log
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        this.updateSelected(10);
                                        this.setState({selectedFirst: 2});
                                    }}
                                              selected={this.state.selected === 10} component={Link} to="/intent"
                                              classes={{root: classes.menuItem}}>
                                        Intent Statistics
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        this.updateSelected(11);
                                        this.setState({selectedFirst: 2});
                                    }}
                                              selected={this.state.selected === 11} component={Link} to="/workflowstats"
                                              classes={{root: classes.menuItem}}>
                                        Workflow Statistics
                                    </MenuItem>
                                </List>
                            </Collapse>


                            <ListItem classes={{root: classes.listItem}}
                                      style={{color: 'white', marginBottom: "10px"}}
                                      button selected={this.state.selectedFirst === 3} onClick={() => {

                                this.handleExpandAdmin();
                            }}>
                                <ListItemIcon>
                                    <SettingsIcon style={{color: 'white'}}/>
                                </ListItemIcon>
                                <ListItemText style={{color: 'white', fontWeight: "bold"}} primary="Administration"/>
                                {this.state.openSubAdmin ? <ExpandLess style={{marginLeft: "5px"}}/> :
                                    <ExpandMore style={{marginLeft: "5px"}}/>}
                            </ListItem>
                            <Collapse in={openSubAdmin} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    <MenuItem onClick={() => this.updateSelected(12)}
                                              selected={this.state.selected === 12} component={Link}
                                              to="/settings"
                                              classes={{root: classes.menuItem}}>
                                        Settings
                                    </MenuItem>

                                    {this.state.roles && this.state.roles.indexOf("Developer") > -1 &&
                                        <React.Fragment>
                                            <MenuItem onClick={() => {
                                                this.updateSelected(13);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 13} component={Link}
                                                      to="/createIntegration"
                                                      classes={{root: classes.menuItem}}>
                                                Create Integration
                                            </MenuItem>
                                        </React.Fragment>
                                    }

                                    {this.state.roles && this.state.roles.indexOf("Admin") > -1 &&
                                        <React.Fragment>
                                            <MenuItem onClick={() => {
                                                this.updateSelected(14);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 14} component={Link}
                                                      to="/createIntegration"
                                                      classes={{root: classes.menuItem}}>
                                                Create Integration
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                this.updateSelected(15);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 15} component={Link}
                                                      to="/saveas"
                                                      classes={{root: classes.menuItem}}>
                                                Save As
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                this.updateSelected(16);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 16} component={Link}
                                                      to="/createbot"
                                                      classes={{root: classes.menuItem}}>
                                                Create Bot
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                this.updateSelected(17);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 17} component={Link}
                                                      to="/createtoken"
                                                      classes={{root: classes.menuItem}}>
                                                Create Token
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                this.updateSelected(18);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 18} component={Link}
                                                      to="/actions"
                                                      classes={{root: classes.menuItem}}>
                                                Actions
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                this.updateSelected(19);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 19} component={Link}
                                                      to="/botadmin"
                                                      classes={{root: classes.menuItem}}>
                                                Botadmin
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                this.updateSelected(20);
                                                this.setState({selectedFirst: 3});
                                            }}
                                                      selected={this.state.selected === 20} component={Link}
                                                      to="/workflowlog"
                                                      classes={{root: classes.menuItem}}>
                                                Logs
                                            </MenuItem>
                                        </React.Fragment>
                                    }
                                </List>
                            </Collapse>
                        </MenuList>

                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            height: "100%",
                            flexDirection: "column",
                            fontWeight: "700",
                            width: "100%",
                            marginBottom: "10px"
                        }}>
                            {this.state.loading && <div style={{
                                display: "flex",
                                alignItems: "baseline",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                marginLeft: "10px"
                            }}><span style={{
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>Training in progress</span><CircularProgress size={30} style={{
                                marginLeft: "10px",
                                marginBottom: "50px"
                            }}
                                                                            className={classes.progress}/></div>}

                            <div style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: !this.state.open?"-12px": "0px"
                            }}>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                    <Avatar style={{backgroundColor: "#D83563"}}><AccountCircleIcon/></Avatar>
                                    {this.state.open && <div style={{
                                        color: "white",
                                        marginLeft: "10px",
                                        fontSize: "12px"
                                    }}>{this.state.name}</div>}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    keepMounted
                                    anchorEl={this.state.anchorEl}
                                    classes={{paper: classes.menuPaper}}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                >

                                    {this.state.accountArray && this.state.accountArray.length > 1 ?
                                        <MenuItem classes={{root: classes.menuRootItem}} onClick={() => {
                                            this.setState({openModal: true})
                                        }}>
                                            Change Active Account


                                        </MenuItem> : ""}
                                    <MenuItem classes={{root: classes.menuRootItem}}
                                              onClick={this.handleClose}>Logout</MenuItem>


                                </Menu>
                            </div>
                        </div>
                    </div>
                </Drawer>
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: open,
                    })}
                    style={{overflow: "hidden", paddingBottom: "3px"}}
                >
                    <div className={classes.drawerHeader}/>



                    <Route exact path="/" render={() => (
                        <Redirect to="/responseworkflow"/>
                    )}/>

                    <Route
                        path='/entities'
                        render={(props) => <Entities account={this.state.account} />}
                    />

                  

                    <Route
                        path='/context'
                        render={(props) => <Context account={this.state.account} />}
                    />

                    <Route
                        path='/customerjourney'
                        render={(props) => <CustomerJourney account={this.state.account} />}
                    />
                    <Route
                        path='/links'
                        render={(props) => <Links {...props} account={this.state.account}
                                                  pipeline={this.state.pipeline}
                                                  passData={this.openLink} openInLog={this.openInLog}/>}
                    />

                    <Route
                        path='/confidence'
                        render={(props) => <Queries {...props} model={this.state.model} loggedInUserEmail={this.state.loggedInUser}  account={this.state.account}
                                                    pipeline={this.state.pipeline}
                                                    passData={this.passData}/>}
                    />

                    <Route
                        path='/intent'
                        render={(props) => <ShowIntent {...props} model={this.state.model}
                                                       pipeline={this.state.pipeline} passData={this.passData}/>}
                    />


                    {this.state.roles && this.state.roles.indexOf("Admin") > -1 &&
                        <React.Fragment>
                            <Route
                                path='/saveas'
                                render={(props) => <SaveModelAs {...props} model={this.state.model}
                                                                account={this.state.account}
                                                                pipeline={this.state.pipeline}
                                                                passData={this.passData}/>}
                            />

                            <Route
                                path='/createtoken'
                                render={(props) => <CreateToken {...props} model={this.state.model}
                                                                account={this.state.account}
                                                                pipeline={this.state.pipeline}
                                                                passData={this.passData}/>}
                            />

                            <Route
                                path='/convertdata'
                                render={(props) => <ConvertData {...props} model={this.state.model}
                                                                account={this.state.account}
                                                                pipeline={this.state.pipeline}
                                                                passData={this.passData}/>}
                            />


                            <Route
                                path='/createbot'
                                render={(props) => <CreateBot {...props} model={this.state.model}
                                                              account={this.state.account}
                                                              loggedInUserEmail={this.state.loggedInUser}
                                                              pipeline={this.state.pipeline} passData={this.passData}/>}
                            />

                            <Route
                                path='/actions'
                                render={(props) => <Actions {...props} openInConversations={this.openInConversations} convId={this.state.convId}/>}
                            />

<Route
                                path='/botadmin'
                                render={(props) => <BotAdmin {...props} openInConversations={this.openInConversations} convId={this.state.convId}/>}
                            />

                            <Route
                                path='/workflowlog'
                                render={(props) => <WorkflowLog {...props} passData={this.openLink}/>}
                            />


                        </React.Fragment>
                    }
                    {this.state.roles && (this.state.roles.indexOf("Developer") > -1 || this.state.roles.indexOf("Admin") > -1) &&
                        <React.Fragment>

                            <Route
                                path='/createIntegration'
                                render={(props) => <CreateIntegration {...props} model={this.state.model}
                                                                      account={this.state.accountNumber}
                                                                      pipeline={this.state.pipeline}
                                                                      accountName={this.state.accountName}
                                                                      passData={this.passData}
                                />}
                            />

                        </React.Fragment>

                    }

                    <Route
                        path='/trainingdata'
                        render={(props) => <Trainingdata {...props} model={this.state.model}
                                                         savedPipelines={this.state.savedPipelines}
                                                         pipeline={this.state.pipeline}
                                                         roles={this.state.roles}
                                                         newModelSaved={this.newModelSaved}
                                                         modelDeleted={this.modelDeleted}
                                                         loggedInUserEmail={this.state.loggedInUser}
                                                         loggedInUserName={this.state.name}
                                                         resetNewTrainingData={this.resetNewTrainingData}
                                                         dataToUse={this.state.dataToUse}
                                                         openIntentResponse={this.state.openIntentResponse}
                                                         resetOpenIntent={() => {
                                                             this.setState({openIntent: null, openIntentResponse: null})
                                                         }}
                                                         openIntent={this.state.openIntent}
                                                         refresh={this.state.refresh}
                                                         cancelAddFromQuery={() => {
                                                             this.setState({dataToUse: ""})
                                                         }}
                                                         openOverview={this.openOverview}
                                                         ensureModel={this.ensureModelFunction}
                                                         account={this.state.account}
                                                         openWorkflow={this.openWorkflow}
                                                         trainModel={this.checkIfModelIsReady}
                                                         checkIfR4ModelIsReady={this.checkIfR4ModelIsReady}
                                                         menuOpen={this.state.open}/>}
                    />

                    <Route
                        path='/testtrainingdata'
                        render={(props) => <TestTrainingData {...props} model={this.state.model} pipeline={this.state.pipeline}
                                                             account={this.state.account} openWorkflow={this.openWorkflow} loadScript={this.loadScript}/>}
                    />

                    <Route
                        path='/flowchart'
                        render={(props) => <FlowOverview {...props}  openWorkflow={this.openWorkflow} model={this.state.model}
                                                         account={this.state.account}/>}
                    />

                    <Route
                        path='/responseworkflow'
                        render={(props) => <ResponseWorkflow {...props} model={this.state.model}
                                                             pipeline={this.state.pipeline}
                                                             account={this.state.account}
                                                             drawerOpen={open}
                                                             roles={this.state.roles}
                                                             openOverview={this.state.openOverview}
                                                             accountName={this.state.accountName}
                                                             loggedInUserEmail={this.state.email}
                                                             loggedInUsername={this.state.loggedInUser}
                                                             resetOpenOverview={()=>{this.setState({openOverview: null})}}
                                                             workflowToOpen={this.state.workflowToOpen}/>}
                    />

                    <Route
                        path='/trainingchat'
                        render={(props) => <TrainingChat {...props} model={this.state.model}
                                                         updateModel={this.updateModel}
                                                         pipeline={this.state.pipeline} account={this.state.account}/>}
                    />

                    <Route
                        path='/workflowstats'
                        render={(props) => <WorkflowStats {...props} model={this.state.model}
                                                          pipeline={this.state.pipeline} account={this.state.account}/>}
                    />

                    <Route
                        path='/settings'
                        render={(props) => <Settings {...props} model={this.state.model}
                                                    roles={this.state.roles}
                                                     pipeline={this.state.pipeline} account={this.state.account}
                                                     accountName={this.state.accountName ? this.state.accountName: ""}
                                                     openWorkflow={this.openWorkflow}/>}
                    />


                    <Route
                        path='/conversations'
                        render={(props) => <Dialogs {...props} model={this.state.model} caseId={this.state.caseId}
                                                    pipeline={this.state.pipeline} account={this.state.account}
                                                    refresh={this.refresh}/>}
                    />
                    <Modal
                        aria-labelledby="Edit-Workflow-Name"
                        aria-describedby="Edit-Workflow-Name"
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                    >
                        <div style={getModalStyle()} className={classes.modal}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography variant="h6" id="modal-title">
                                    Change Active Account
                                </Typography>
                                <Typography variant="subtitle1" id="simple-modal-description">
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <FormControl style={{borderColor: "black"}} className={classes.formControl}>
                                            <Autocomplete
                                                id="combo-box"
                                                options={this.state.mergedAccountArray}
                                                onChange={this.handleAccountChange}
                                                value={this.state.accountObject}
                                                getOptionLabel={option => option.name}
                                                style={{minWidth: "200px", width: "100%"}}
                                                renderInput={params => <TextField {...params} variant="outlined"
                                                                                  label="Account "
                                                                                  style={{minHeight: "100px"}}/>}/>
                                        </FormControl>
                                        <Button style={{
                                            marginLeft: "0px",
                                            marginTop: "20px",
                                            padding: "10px"
                                        }}
                                                variant="contained" color="primary" onClick={() => {
                                            this.setState({openModal: false});
                                        }}>
                                            Cancel</Button>


                                    </div>

                                </Typography>
                            </div>

                        </div>
                    </Modal>
                </main>
                <Notifier changeState={this.changeState} open={this.state.notifierTrainOpen}
                          message={this.state.trainingComplete + " " + this.state.modelTrained + " Time elapsed: " + this.state.trainingtimeElapsed}/>
                {this.state.loadScript ?<TestBot account={this.state.account} />: <React.Fragment></React.Fragment>}

            </div>


        );
    }
}

const styles = theme => (
        {
            root:
                {
                    display: 'flex'
                },
            menuButton:
                {
                    marginLeft: 12,
                    marginRight: 20,
                },
            hide:
                {
                    display: 'none',
                },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                backgroundColor: "#35424E"
            },
            drawerOpen: {
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundColor: "#35424E"
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: theme.spacing(8) + 1,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9) + 1,
                },
                backgroundColor: "#35424E"
            },
            drawerPaper:
                {
                    width: drawerWidth,
                    margin: "0px",
                    backgroundColor: "#35424E"

                },
            drawerPaperMin:
                {
                    width: 60,
                    backgroundColor: "#35424E"

                },
            drawerHeader:
                {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 8px',
                    justifyContent: 'flex-end',
                },
            content:
                {
                    flexGrow: 1,
                    padding: theme.spacing(2),
                    transition: theme.transitions.create('margin',
                        {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    marginLeft:
                        10,
                },
            contentShift:
                {
                    transition: theme.transitions.create('margin',
                        {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    marginLeft: 10,
                },
            MuiToolbar:
                {
                    width: "100%",
                    backgroundColor: "#35424E"

                },
            menuItem:
                {
                    color: "white",
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "10px",
                    marginLeft: "20px",
                    marginRight: "10px",
                    '&:hover':
                        {
                            backgroundColor: '#515665',
                            borderRadius: "10px",
                            width: "auto"
                        },
                    "&.Mui-selected":
                        {
                            backgroundColor: "#EB1562",
                            borderRadius: "10px",
                            color: "white",
                            width: "auto"
                        },

                },
            listItem:
                {
                    justifyContent: "inherit",
                    color: "white",
                    minHeight: "40px",
                    padding: "10px",

                    '& .MuiListItemIcon-root': {

                        minWidth: "46px"
                    },
                    '&:hover':
                        {
                            backgroundColor: '#515665',
                            borderRadius: "10px",
                            width: "auto",
                            '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                                {
                                    color: theme.palette.common.white,

                                },
                        },
                    "&.Mui-selected":
                        {
                            backgroundColor: "#EB1562",
                            color: "white",
                            borderRadius: "10px",
                            width: "auto"

                        },
                    '& .MuiListItemText-primary': {

                        fontWeight: "600"
                    }
                },
            rootMenuItem:
                {
                    color: "white",
                    minHeight: "40px",
                    padding: "10px",
                    width: "200px"

                },
            listItemText:
                {
                    color: "white",
                    minHeight: "40px",
                },
            paperForm:
                {
                    display: 'flex',
                    flexDirection: 'row'


                },
            menuAccountPaper:
                {
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "306px"

                },
            menuPaper:
                {
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "260px",
                    width: "260px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                },
            menuRootItem:
                {

                    width: "260px",
                    height: "50px"

                },
            selectRoot:
                {
                    height: "200px",
                    overflow: "show"

                },
            modal:
                {
                    position: 'absolute',
                    width: theme.spacing(50),
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[5],
                    padding: theme.spacing(4),
                    outline: 'none',
                },
            listItemTextSelected:
                {

                    "&.Mui-selected":
                        {
                            backgroundColor: "#35424E",
                            color: "white"
                        },
                    outline: "none",
                    fontSize: "15px",
                    border: "none",
                }
        }
    )
;

App.propTypes =
    {
        classes: PropTypes.object.isRequired
    }
;


export default compose(
    withStyles(styles),
    withRouter
)(App);
