import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import moment from "moment/moment";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});

let counter, updateCounter = 0;

class ModifyTrainingData extends Component {


    constructor(props) {

        super(props);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.convertData = this.convertData.bind(this);
        this.updateTrainingData = this.updateTrainingData.bind(this);
        this.convertToRasaModel = this.convertToRasaModel.bind(this);
        this.convertAllToRasa = this.convertAllToRasa.bind(this);
        this.updateDataModelInDb = this.updateDataModelInDb.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);

        this.state = {
            value: null,
            keyValue: []

        }

    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            data.map((d, i) => {

                if (i === 0) {

                    first = d;
                }

            })

            this.setState({savedModels: data, model: first});


        }
        if (data) {

            this.setState({numberOfModels: data.length});

            data.map((object, i) => {

                this.queryIntentResponse(object.Model);

            });
        }
    }


    updateDataModel() {
        let updatedArray = [];
        if (this.state.updatedTrainingData) {
            this.state.updatedTrainingData.map((model) => {

                if (updatedArray.indexOf(model.model) === -1) {
                    let updatedModel = this.convertAllToRasa(model.model);
                    updatedArray.push(model.model);
                    this.updateDataModelInDb(model.model, updatedModel)

                }


            });
        }

    }

    async updateDataModelInDb(modelName, model) {

        let url = '/api/updateModel?id=' + modelName+"&account="+this.props.account;
        //console.log("updateDataModelInDb")
        //console.log(modelName, model)

        this.props.resetSaveUpdated();

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(model)
        });

        const data = await response.json();

        updateCounter++;

        if (data.length === 0) {

            this.props.notify();
            //this.props.resetSaveModel("update");
        }
        if (data && data.name && data.name.indexOf("error") > -1) {

        }
    }


    convertAllToRasa(model) {

        let convertedModel = {
            "rasa_nlu_data": {
                "common_examples": [],
                "entity_synonyms": [],
                "entity_values": [],
                "entity_object": {},
                "lookup_object": {}
            }
        };


        let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");

        convertedModel.rasa_nlu_data.lastModifiedBy = this.props.loggedInUserEmail;
        convertedModel.rasa_nlu_data.lastModifiedIntent = this.state.lastModifiedIntent;
        convertedModel.rasa_nlu_data.lastModified = createdTimeStamp;
        convertedModel.rasa_nlu_data.common_examples = this.convertToRasaModel(model);

        const entities = new Set();
        const entitiyValue = new Set();

        this.state.keyValue[model].map((object) => {

            object.trainingData.map((object) => {

                //object.text = object.text.trim();
                let arr = Array.from(object.text.matchAll(/\(([^\)[]*)\)/g), x => x[1]);
                let arr2 = Array.from(object.text.matchAll(/\(([^\)[]*)\)\{/g), x => x[1]);
                let arr3 = Array.from(object.text.matchAll(/\[([^\)[]*)\]/g), x => x[1]);

                if (arr.length > 0 && arr2.length === 0 && !entities.has(arr[0])) {

                    entities.add(arr[0]);
                }

                if (arr3.length > 0 && arr2.length === 0) {
                    entitiyValue.add(arr3[0])

                }
            });
        });

        if (this.state.firstQuery[model] && this.state.firstQuery[model].rasa_nlu_data && this.state.firstQuery[model].rasa_nlu_data.entity_object) {

            convertedModel.rasa_nlu_data.entity_object = this.state.firstQuery[model].rasa_nlu_data.entity_object;

        }

        if (this.state.firstQuery[model] && this.state.firstQuery[model].rasa_nlu_data && this.state.firstQuery[model].rasa_nlu_data.lookup_object) {

            convertedModel.rasa_nlu_data.lookup_object = this.state.firstQuery[model].rasa_nlu_data.lookup_object;

        }

        convertedModel.rasa_nlu_data.entity_synonyms = [...entities];

        if (entitiyValue.size > 0) {
            convertedModel.rasa_nlu_data.entity_values = [...entitiyValue];
        }

        return convertedModel;

    }



    convertToRasaModel(model) {


        //Recreate the object on each conversion
        let newObject = [];

        let keyValue = this.state.keyValue[model];

        keyValue.map((object) => {

            for (var i = 0; i < object.trainingData.length; i++) {

                let obj = {"intent": object.name, "text": object.trainingData[i].text};
                if (object.trainingData[i].entities) {

                    obj.entities = object.trainingData[i].entities;

                }
                newObject.push(obj);
            }
        });

        return newObject;

    }

    async convertData(dataArray, model) {


        const data = [];
        if (!this.state.firstQuery) {
            console.log("failed to init");
        } else {

            let allData = dataArray.rasa_nlu_data.common_examples;

            allData.map((object) => {

                let found = 0;

                for (let i = 0; i < data.length; i++) {


                    if (object.intent === data[i].name) {


                        if (object.entities && object.entities.length > 0) {
                            data.push({
                                name: object.intent,
                                trainingData: [{text: object.text, entities: object.entities}]
                            });
                        } else {
                            data[i].trainingData.push({text: object.text});
                        }

                        found = 1;
                        break;
                    }

                }

                if (found === 0) {
                    if (object.entities) {
                        data.push({
                            name: object.intent,
                            trainingData: [{text: object.text, entities: object.entities}]
                        });
                    } else {

                        data.push({name: object.intent, model: model, trainingData: [{text: object.text}]});
                    }


                }


            });

            this.setState({synonyms: this.state.firstQuery[model].rasa_nlu_data.entity_synonyms});

            //Sort the array in alphabetical order
            data.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            })


            if (this.state.keyValue) {
                let oldArray = this.state.keyValue;
                oldArray[model] = data;
                this.setState({keyValue: oldArray});


                if (counter === this.state.numberOfModels - 1) {

                    this.updateTrainingData();

                }
                counter++;


            }


        }
    }

    componentDidMount() {


        if (this.props.intentIndexArray) {
            counter = 0;

            //this.queryIntentResponse(this.props.model);
            this.getSavedModels();

        }


    }

    updateTrainingData() {

        let keyValue = this.state.keyValue;

        let keys = Object.keys(keyValue);

        let updatedTrainingData = this.state.updatedTrainingData ? this.state.updatedTrainingData : [];
        keys.map((key) => {

            keyValue[key].map((object) => {

                this.props.intentIndexArray.map((updatedObj) => {

                    if (object.name === updatedObj.newIntent) {
                        let trainingDataArray = object.trainingData;
                        trainingDataArray.push({"text": updatedObj.queryValue.toLowerCase()});


                        updatedTrainingData.push({"newIntent": updatedObj.newIntent, "model": object.model});
                    }


                    if (object.name === updatedObj.oldIntent) { //&& updatedObj.oldIntent !== updatedObj.newIntent){

                        let trainingDataFromOldIntent = object.trainingData;
                        let indexFound = -1;
                        trainingDataFromOldIntent.map((o, i) => {

                            if (o.text.toLowerCase() === updatedObj.queryValue.toLowerCase()) {
                                indexFound = i;
                            }

                        });

                        if (indexFound !== -1) {
                            trainingDataFromOldIntent.splice(indexFound, 1);
                        }

                        object.trainingData = trainingDataFromOldIntent;
                    }


                });


            });

        });


        this.setState({updatedModelAvailable: true, updatedTrainingData: updatedTrainingData, keyValue: keyValue}, ()=>{this.updateDataModel();});



        setTimeout(() => {

            this.props.resetSaveUpdated();
        }, 1000);


    }


    componentDidUpdate(prevProps, prevState, snapShot) {

        if (prevProps.intentIndexArray !== this.props.intentIndexArray) {


        }
        if (prevProps.saveUpdatedModel !== this.props.saveUpdatedModel) {

            if (this.state.updatedModelAvailable) {
                this.setState({updatedModelAvailable: false});

                this.updateDataModelInDb();


                this.props.resetSaveUpdated();

            }
        }


    }

    async queryIntentResponse(model) {

        let url = '/api/trainingDataJson?model=' + model;
        const response = await fetch(url);
        const data = await response.json();
        const firstQuery = this.state.firstQuery?this.state.firstQuery: [];
        firstQuery[model] = data;
        this.setState({firstQuery: firstQuery}, () => {

            this.convertData(data, model);
        });

    }


    render() {
        const {classes} = this.props;

        return (

            <div>
            </div>

        )
    }

}

export default withStyles(styles)(ModifyTrainingData);
