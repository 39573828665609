import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(4),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  notchedOutline: {},
  select: {
    selectedTextColor: "black",
  },
  menuItem: {
    color: "white",
  },
});

class FileRead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      importedFile: "",
    };
    this.handleFiles = this.handleFiles.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapShot) {
    // only update chart if the data has changed
  }

  handleFiles(e) {
    let files = e.target.files;

    if (window.FileReader) {
      const reader = new FileReader();
      reader.onload = (r) => this.props.processFile(r.target.result);
      reader.readAsText(files[0]);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button color="primary" variant="contained" component="label">
          Upload File
          <input
            type="file"
            id="csvFileInput"
            onChange={this.handleFiles}
            accept=".csv"
            hidden
          />
        </Button>
      </div>
    );
  }
}

FileRead.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FileRead);
