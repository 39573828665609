import React, {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import MUIDataTable from "mui-datatables";
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import moment from "moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ModelSelect from './ModelSelect'
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {
        height: "80px",
        root: {
            height: "80px",
            input: {

                height: "10px"
            }
        },
        input: {

            height: "10px"
        }

    },
    input: {

        height: "45px"
    },
    label: {fontSize: "40px"},


});

const theme = createTheme({
    overrides: {

        MuiInputBase: {

            root: {
                /*height: "35px",
                lineHeight: "0.9em",*/

            }
        }


    },
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class WorkflowLog extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Intent hits',
            keyValue: [],
            linkedClicked: [],
            linkedClickedRaw: [],
            confidence: '',
            chartData: [],
            chartData2: [],
            user: "",
            models: [],
            model: "",
            value: 0,
            confData: [],
            modelInfo: [],
            date: new Date(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm"))


        }
        this.handleSubmit = this.handleSubmit.bind(this);

        this.createChartData = this.createChartData.bind(this);
        this.createChartData2 = this.createChartData2.bind(this);
        this.cellClick = this.cellClick.bind(this);
        this.cellClickIntent = this.cellClickIntent.bind(this);
        this.getIntentResponseLog = this.getIntentResponseLog.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.getWorkflowLog = this.getWorkflowLog.bind(this);
        this.getWorkflowLogUser = this.getWorkflowLogUser.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.getIntentResponseLogAll = this.getIntentResponseLogAll.bind(this);
        this.getTrainingInfoToDb = this.getTrainingInfoToDb.bind(this);
    }

    cellClickIntent(cellData, cellMetaData) {
        let linkType = "IntentLog";

        this.props.passData(cellData, linkType, this.state.chartData2[cellMetaData.dataIndex][3]);

        this.props.history.push('/trainingdata');

    }


    async getTrainingInfoToDb(model) {

        if (this.state.model) {
            model = this.state.model;
        }
        let url = "/api/trainingDataJson?model=" + encodeURI(model);

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'

            }

        });

        const data = await response.json();

        if (data && data.rasa_nlu_data) {
            let meta = data.rasa_nlu_data;
            let array = [];
            let obj = {"model": model};
            if (meta.lastModified) {
                obj.lastModified = meta.lastModified;
            }
            if (meta.lastModifiedBy) {
                obj.lastModifiedBy = meta.lastModifiedBy;
            }

            if (meta.lastModifiedIntent) {
                obj.lastModifiedIntent = meta.lastModifiedIntent;
            }
            array.push(obj);

            this.setState({modelInfo: [...this.state.modelInfo, ...array]}, () => {
                this.createChartData3()
            });

        }


        //console.log(data)
    }


    changeUser(e) {
        this.setState({user: e.target.value});
    }

    async getWorkflowLogUser() {
        //console.log(.format("YYYY-MM-DD HH:mm"))
        let url = "/api/getWorkflowLog?fromDate=" + moment(this.state.date).format("YYYY-MM-DD") + "&user=" + this.state.user;


        const response = await fetch(url, {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        });

        const data = await response.json();
        //console.log(data)
        this.setState({logdata: data}, () => {
            this.createChartData()
        });

    }

    async getIntentResponseLog() {

        let url = "/api/getIntentResponseLog?fromDate=" + moment(this.state.date).format("YYYY-MM-DD") + "&user=" + this.state.user;


        const response = await fetch(url, {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        });

        const data = await response.json();

        this.setState({logdata: data}, () => {
            this.createChartData2()
        });

    }

    async getIntentResponseLogAll() {
        //console.log(.format("YYYY-MM-DD HH:mm"))
        let url = "/api/getIntentResponseLogAll?fromDate=" + moment(this.state.date).format("YYYY-MM-DD");


        const response = await fetch(url, {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        });

        const data = await response.json();

        this.setState({logdata: data}, () => {
            this.createChartData2()
        });

    }

    async getWorkflowLog() {
        //console.log(.format("YYYY-MM-DD HH:mm"))
        let url = "/api/getWorkflowLogAll?fromDate=" + moment(this.state.date).format("YYYY-MM-DD");


        const response = await fetch(url, {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        });

        const data = await response.json();

        this.setState({logdata: data}, () => {
            this.createChartData()
        });

    }

    async getSavedModels() {


        let url = '/api/getSavedModels';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            this.setState({savedModels: data});

            data.map((m) => {
                if (m.Model) {

                    this.getTrainingInfoToDb(m.Model)
                }


            })
        }


    }

    cellClick(celldata) {

        //selectedIntents.map( intent => console.log(intent));


        let linkType = "Workflow";
        this.props.passData(celldata, linkType);
        this.props.history.push('/responseworkflow');
    };

    onChangeDate = (date) => {

        this.setState({date: date})
    }

    onChangeToDate = (date) => {

        this.setState({toDate: date})
    }


    handleSubmit(e) {

        //e.preventDefault();


        if (e.currentTarget.innerHTML.indexOf("Intent") > -1 && this.state.user !== "") {
            this.getIntentResponseLog();
        } else if (e.currentTarget.innerHTML.indexOf("Intent") > -1) {
            this.getIntentResponseLogAll();
        } else if (this.state.user !== "") {

            this.getWorkflowLogUser();
        } else if (e.currentTarget.innerHTML.indexOf("Training Data") > -1) {
            this.setState({modelInfo: []});
            this.getSavedModels();

        } else {
            this.getWorkflowLog();
        }

        /*if(this.state.value === 0 || this.state.value === 1) {

        }else if(this.state.value === 2){

            this.queryLinkClicked();

        }else if( this.state.value === 3){

            this.queryLinkClickedRaw();
        }*/

    }

    componentDidMount() {

        this.getWorkflowLog();
        /*keycloak.init().success(function(authenticated) {
            alert(authenticated ? 'authenticated' : 'not authenticated');
        }).error(function() {
            alert('failed to initialize');
        });*/

        if (this.props.model) {

            //console.log(this.props.model)
            this.setState({model: this.props.model});


        }

        if (this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }

        //this.getSavedModels();


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.model !== this.props.model) {

//            console.log(this.props.model)
            //this.setState({model: this.props.model});


        }

        if (prevProps.pipeline !== this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }
    }

    createChartData3() {


        let rows = [];

        if (this.state.modelInfo !== []) {

            for (let row of this.state.modelInfo) {
                const {model, lastModified, lastModifiedBy, lastModifiedIntent} = row

                rows.push([model, lastModified, lastModifiedBy, lastModifiedIntent])

            }

            this.setState({chartData3: rows, chartData: [], chartData2: []});

        }

    }

    createChartData2() {


        let rows = [];

        if (this.state.logdata !== []) {

            for (let row of this.state.logdata) {
                const {Id, lastModified, lastModifiedBy, model} = row
                let idSplit = Id.split("_");
                rows.push([idSplit[idSplit.length - 1], lastModified, lastModifiedBy, model])

            }

            this.setState({chartData2: rows, chartData: [], chartData3: []});

        }

    }

    createChartData() {


        let rows = [];

        if (this.state.logdata !== []) {

            for (let row of this.state.logdata) {
                const {Id, created, createdBy, modified, modifiedBy} = row
                let idSplit = Id.split("_");
                rows.push([idSplit[idSplit.length - 1], created, createdBy, modified, modifiedBy])

            }

            this.setState({chartData: rows, chartData2: [], chartData3: []});

        }

    }


    setModel = (model) => {

        this.setState({model: model});

    }

    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        return (
            <MuiThemeProvider theme={theme}>
                <div style={{marginRight: "10px"}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Paper className={classes.paper}>

                                <div style={{display: "flex", flexDirection: "row", width: "100%", marginLeft: "20px"}}>
                                    {/* <ModelSelect model={this.state.model} setModel={this.setModel}
                                             data={this.state.savedModels}/>*/}
                                    <div style={{
                                        marginTop: "0px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>


                                        <TextField
                                            value={this.state.user}
                                            onChange={this.changeUser}
                                            margin="normal"
                                            multiline={false}
                                            variant="filled"
                                            label="Modified By"
                                            style={{width: "40%"}}
                                        />

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            < FormControl style={{borderColor: "black"}}
                                                          className={classes.formControl}
                                                          style={{
                                                              minWidth: "200px",
                                                              minHeight: "100px",
                                                              paddingLeft: "0px",
                                                              paddingRight: "20px",
                                                              marginTop: "0px",
                                                              marginBottom: "5px",
                                                              padding: "10px"
                                                          }}>
                                                <DatePicker
                                                    disableToolbar
                                                    autoOk
                                                    ampm={false}
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="From Date"
                                                    style={{}}
                                                    value={this.state.date}
                                                    onChange={this.onChangeDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </FormControl>

                                        </MuiPickersUtilsProvider>

                                        {/* <DateFormatInput id="fromDate" label="From date:" name='date-input' value={date}
                                                             onChange={this.onChangeDate} /><span
                                            style={{marginLeft: "20px", marginTop: "10px", height: "40px"}}>
                             </span><DateFormatInput id="toDate" label="To date:" name='date-input' value={toDate}
                                                     onChange={this.onChangeToDate}/>*/}
                                        <span
                                            style={{
                                                marginLeft: "20px",
                                                marginTop: "10px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}> <Button
                                            onClick={this.handleSubmit}
                                            variant="contained" color="primary" style={{width: "250px"}}
                                            type="submit">Workflow Log</Button>
                                            <Button onClick={this.handleSubmit}
                                                    variant="contained" color="primary"
                                                    style={{marginLeft: "20px", width: "250px"}}
                                                    type="submit">Intent Response Log</Button>
                                            <Button onClick={this.handleSubmit}
                                                    variant="contained" color="primary"
                                                    style={{marginLeft: "20px", width: "250px"}}
                                                    type="submit">Training Data Log</Button>
                                            </span>

                                    </div>
                                </div>
                            </Paper>

                        </Grid>
                        <Grid item xs={12}>
                            <Paper>

                                <Grid container spacing={6} style={{overflow: "auto"}}>
                                    <Grid item xs={12}>


                                        {this.state.chartData && this.state.chartData.length > 0 && <MUIDataTable

                                            title={"Workflow Log"}
                                            data={this.state.chartData}
                                            columns={[{
                                                name: "Id",
                                                options: {
                                                    filter: true,
                                                    setCellProps: (value) => {
                                                        return {
                                                            className: classnames(
                                                                {
                                                                    [classes.NameCell]: value
                                                                })
                                                        };
                                                    }
                                                }
                                            }, "Created", "CreatedBy", "Modified", "ModifiedBy"]}
                                            options={{
                                                filter: true,
                                                rowsPerPage: 150,
                                                filterType: "checkbox",
                                                responsive: "standard",
                                                selectableRows: "none",
                                                onCellClick: (colData, cellMeta) => {
                                                    this.cellClick(colData);

                                                }


                                            }}
                                        />
                                        }


                                        {this.state.chartData2 && this.state.chartData2.length > 0 && <MUIDataTable

                                            title={"Intent Response Log"}
                                            data={this.state.chartData2}
                                            columns={[{
                                                name: "Id",
                                                options: {
                                                    filter: true,
                                                    setCellProps: (value) => {
                                                        return {
                                                            className: classnames(
                                                                {
                                                                    [classes.NameCell]: value
                                                                })
                                                        };
                                                    }
                                                }
                                            }, "Modified", "ModifiedBy", "Model"]}
                                            options={{
                                                filter: true,
                                                rowsPerPage: 150,
                                                sortOrder: {
                                                    name: 'Modified',
                                                    direction: 'desc'
                                                },
                                                filterType: "checkbox",
                                                responsive: "standard",
                                                selectableRows: "none",
                                                onCellClick: (colData, cellMeta) => {
                                                    this.cellClickIntent(colData, cellMeta);

                                                }


                                            }}
                                        />}

                                        {this.state.chartData3 && this.state.chartData3.length > 0 && <MUIDataTable

                                            title={"Workflow Log"}
                                            data={this.state.chartData3}
                                            columns={["Model", "Modified", "ModifiedBy", "Last Modified Intent"]}
                                            options={{
                                                filter: true,
                                                rowsPerPage: 150,
                                                filterType: "checkbox",
                                                responsive: "standard",
                                                selectableRows: "none",
                                                onCellClick: (colData, cellMeta) => {
                                                    this.cellClick(colData);

                                                }


                                            }}
                                        />
                                        }


                                    </Grid>
                                </Grid>

                            </Paper>


                        </Grid>
                    </Grid>


                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(WorkflowLog);
