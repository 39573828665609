import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class CheckGroupStatusAndTransfer extends Component {


    constructor(props) {

        super(props);


        this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.pass = this.pass.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.init = this.init.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.handleBusyChange = this.handleBusyChange.bind(this);
        this.handleClosedChange = this.handleClosedChange.bind(this);
        this.getSites = this.getSites.bind(this);
        this.state = {
            workFlowRender: null,
            groups: [],
            group: {id: "", name: ""},
            closedFlow: {Id: ""},
            busyFlow: {Id: ""},

        };


    }

    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[2]) {
                    workflowArray.push({"Id": arraySplit[2]});
                }

            });


            if (this.props.workflowCreate) {

                workflowArray.push({"Id": "[New Workflow]"});
            }
            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })
            this.setState({commonWorkflows: workflowArray});


        }


    }


    componentDidMount() {


        this.getTokenUser();
        this.getAllCommonWorkflows();


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {



            if (this.state.groups) {
                let found = -1;
                this.state.groups.map((object, i) => {

                    if (object.id === this.props.value.groupId) {
                        found = i;

                    }

                });


                this.setState({group: this.state.groups[found], groupId: this.props.value.groupId});
            }


        }

    }


    handleGroupIdChange(e) {

        this.setState({groupId: e.target.value})

    }

    handleGroupChange(event, value) {


        if (value && value.name) {
            this.setState({group: value, groupId: value.id});

        }

        if (this.props.handleGroupChange) {

            this.props.handleGroupChange(this.props.optionAction, value);
        }

    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            data.map((site) => {

                this.getGroups(site);

            });

        }
    }

    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            this.setState({groups: groups}, this.init);

        }

    }


    init() {

        if (this.props.value) {

            if (this.state.groups) {
                let found = -1;
                this.state.groups.map((object, i) => {

                    if (object.id === this.props.value.groupId) {
                        found = i;

                    }

                });



                this.setState({group: this.state.groups[found], groupId: this.props.value.groupId});
            }

            if (this.props.value.transferFlows) {
                if (this.props.value.transferFlows.busyFlow) {
                    this.setState({
                        busyFlow: {"Id": this.props.value.transferFlows.busyFlow},
                        closedFlow: {"Id": this.props.value.transferFlows.closedFlow}
                    });
                } else if (this.props.value.transferFlows.busy) {
                    this.setState({
                        busyFlow: {"Id": this.props.value.transferFlows.busy},
                        closedFlow: {"Id": this.props.value.transferFlows.closed}
                    });

                }
            }


        }

    }

    pass() {


        let workflow = {type: "case.checkGroupStatusAndTransfer", "groupId": this.state.groupId};

        if (this.state.busyFlow && this.state.busyFlow.Id !== "") {

            workflow["transferFlows"] = {};
            workflow["transferFlows"].busy = this.state.busyFlow.Id;


        }
        if (this.state.closedFlow && this.state.closedFlow.Id !== "") {

            workflow["transferFlows"].closed = this.state.closedFlow.Id;

        }

        this.props.passData("case.checkGroupStatusAndTransfer", workflow);


    }


    handleBusyChange(event, value) {


        if (value && value.Id) {

            this.setState({busyFlow: {"Id": value.Id}});
        }
        if (this.props.handleBusyChange) {

            this.props.handleBusyChange(this.props.optionAction, value.Id);
        }


    }

    handleClosedChange(event, value) {


        if (value && value.Id) {

            this.setState({closedFlow: {"Id": value.Id}});
        }

        if (this.props.handleClosedChange) {

            this.props.handleClosedChange(this.props.optionAction, value.Id);
        }


    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "20px"}}>

                    <FormControl variant="outlined" style={{borderColor: "black", marginTop: "-10px"}}
                                 className={classes.formControl}>
                        <h3>Transfer to Group</h3>

                        <Autocomplete
                            id="combo-box3"
                            options={this.state.groups}
                            onChange={this.handleGroupChange}
                            value={this.state.group}
                            getOptionLabel={option => option.name}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Transfer To Group" variant="outlined"
                                                              style={{minHeight: "100px"}}/>}
                        />


                    </FormControl>
                    <FormControl variant="outlined" style={{borderColor: "black", marginBottom: "20px"}}
                                 className={classes.formControl}>
                        <h3>Workflow to Execute on Busy</h3>

                        <Autocomplete
                            id="busy"
                            options={this.state.commonWorkflows}
                            onChange={this.handleBusyChange}
                            value={this.state.busyFlow}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Workflows" variant="outlined"/>}
                        />


                    </FormControl>

                    <FormControl variant="outlined" style={{borderColor: "black", marginBottom: "50px"}}
                                 className={classes.formControl}>
                        <h3>Workflow to Execute on Closed</h3>

                        <Autocomplete
                            id="closed"
                            options={this.state.commonWorkflows}
                            onChange={this.handleClosedChange}
                            value={this.state.closedFlow}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Workflows" variant="outlined"/>}
                        />


                    </FormControl>


                    {!this.props.optionActionDisplay ? this.props.value && this.props.value.groupId ?
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                onClick={this.pass}>Update</Button> :
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                onClick={this.pass}>Add</Button> : <div></div>}

                </div>
            </div>

        )
    }

}

export default withStyles(styles)(CheckGroupStatusAndTransfer);
