import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import classnames from "classnames";
import TextField from "@material-ui/core/TextField";
import { ListItem, ListItemText } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TrainingdataBox from "./TrainingdataBox";
import Button from "@material-ui/core/Button/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Notifier from "./Notifier";
import Clear from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChipInput from "material-ui-chip-input";
import ArrowBack from "@material-ui/icons/ArrowBack";
import moment from "moment";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import PublicIcon from "@material-ui/icons/Public";
import TranslateIcon from "@material-ui/icons/Translate";
import CheckIcon from "@material-ui/icons/Check";

const style = { targetDiv: { height: "calc(100vh - 300px)" } };

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: 200,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  button: {
    padding: "0 10px",
  },
  list: {
    paddingLeft: "10px",
    color: "#000",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  listItemText: {
    paddingLeft: "20px",
    fontSize: "15px",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
    overflow: "hidden",
  },
  fab: {
    margin: theme.spacing(4),
  },

  paper: {
    padding: "10px",
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexGrow: 1,
    maxHeight: "100vh",
  },
  tabs: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  modal: {
    position: "absolute",
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  listItemTextSelected: {
    color: "#000000",
    fontSize: "12px",
    "&.Mui-selected": {
      backgroundColor: "#3E51B5",
      color: "#FFFFFF",
    },
  },
  NameCell: {
    fontWeight: 900,
    textDecoration: "underline",
    cursor: "pointer",
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class IntentList extends React.Component {
  constructor(props) {
    super(props);
    this.listRow = this.listRow.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.reactOnClick = this.reactOnClick.bind(this);
    this.updateTrainingData = this.updateTrainingData.bind(this);
    this.saveTrainingData = this.saveTrainingData.bind(this);
    this.deleteTrainingData = this.deleteTrainingData.bind(this);
    this.changeTraining = this.changeTraining.bind(this);
    this.updateDataModel = this.updateDataModel.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateTextField = this.updateTextField.bind(this);
    this.addNewIntent = this.addNewIntent.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.deleteIntent = this.deleteIntent.bind(this);
    this.saveUpdatedResponse = this.saveUpdatedResponse.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updateTextResponse = this.updateTextResponse.bind(this);
    this.getTrainingData = this.getTrainingData.bind(this);
    this.getIndexOfIntent = this.getIndexOfIntent.bind(this);
    this.resetNewTrainingData = this.resetNewTrainingData.bind(this);
    this.saveResponeModel = this.saveResponeModel.bind(this);
    this.updateResponseModel = this.updateResponseModel.bind(this);
    this.changeState = this.changeState.bind(this);
    this.updateResponseFromWorkflow =
      this.updateResponseFromWorkflow.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.createSelectedArray = this.createSelectedArray.bind(this);
    this.addEntity = this.addEntity.bind(this);
    this.getMuiTheme = this.getMuiTheme.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.saveUpdatedTraining = this.saveUpdatedTraining.bind(this);
    this.openWorkflow = this.openWorkflow.bind(this);
    this.handleCloseAddFromQueryLog =
      this.handleCloseAddFromQueryLog.bind(this);
    this.addNewTrainingFromLog = this.addNewTrainingFromLog.bind(this);
    this.changeIntentFromLog = this.changeIntentFromLog.bind(this);
    this.changeIntentName = this.changeIntentName.bind(this);
    this.editIntentName = this.editIntentName.bind(this);
    this.cancelAddFromQuery = this.cancelAddFromQuery.bind(this);
    this.deleteIntentByName = this.deleteIntentByName.bind(this);
    this.updateResponseFromMetadata =
      this.updateResponseFromMetadata.bind(this);
    this.updateResponseToDb = this.updateResponseToDb.bind(this);
    this.removeUnvantedChars = this.removeUnvantedChars.bind(this);
    this.resetSelectResponse = this.resetSelectResponse.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.cellClick = this.cellClick.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.getSavedModels = this.getSavedModels.bind(this);
    this.handlePanelChange = this.handlePanelChange.bind(this);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.filterArray = this.filterArray.bind(this);
    this.handleBackFromSearch = this.handleBackFromSearch.bind(this);
    this.setIntentStatus = this.setIntentStatus.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.trainingDataAndResponse = this.trainingDataAndResponse.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.showOverview = this.showOverview.bind(this);
    this.testIntents = this.testIntents.bind(this);
    this.ensureModel = this.ensureModel.bind(this);
    this.state = {
      gridValue: 2,
      filter: "",
      status: "",
      filterSyn: "",
      selectedIntent: "",
      searchClick: false,
      trainingData: "",
      trainingDataModel: "",
      selectResponse: false,
      model: "",
      metadata: "",
      savedModels: "",
      selected: null,
      response: null,
      showSelect: false,
      selectedArray: [],
      tabValue: 0,
      value: 0,
      addResponseValue: "",
      newTrainingData: "",
      selectIntent: "",
      synonyms: [],
      ensureUI: false,
      selectedSynonym: "",
      f: false,
      open: false,
      marginRight: "0px",
      openAddFromQueryLog: false,
      intentToLog: "",
      openEditModal: false,
      search: "",
      modelValue: { Model: "" },
      yourChips: [],
    };
  }

  componentDidMount() {
    if (this.props.dataToUse) {
      this.getSavedModels(this.props.dataToUse);
    } else {
      this.getSavedModels();
    }

    if (window.location.href.indexOf("ensure") > -1) {
      this.setState({ ensureUI: true });
    }

    if (this.props.selectIntent) {
      //this.setState({selectIntent: this.props.selectIntent.query})
    }

    if (this.props.openIntent) {
      if (this.props.model) {
        this.setState({ model: this.props.model });

        /*Wait for modelsarray to arrive*/
        setTimeout(() => {
          this.setState({
            modelValue: {
              Model: this.props.model,
              Pipeline: "en",
              Project: this.props.account,
            },
          });
        }, 1500);
        this.props.changeModel(this.props.model);
      }

      this.setState({ openIntent: this.props.openIntent }, () => {
        this.trainingDataAndResponse();
      });
    }

    if (this.props.openIntentResponse) {
      if (this.props.model) {
        this.setState({ model: this.props.model });

        setTimeout(() => {
          this.setState({
            modelValue: {
              Model: this.props.model,
              Pipeline: "en",
              Project: this.props.account,
            },
          });
          this.props.changeModel(this.props.model);
          this.setState({ model: this.props.model });
          this.setState({ openIntent: this.props.openIntentResponse }, () => {
            this.trainingDataAndResponse();
          });
        }, 1000);
      }
      this.setState({ selectResponse: true });
    }

    this.createSelectedArray();
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.gridValue !== this.props.gridValue && this.props.gridValue) {
      this.setState({ gridValue: this.props.gridValue });
    }

    if (
      prevProps.updateModels !== this.props.updateModels &&
      this.props.updateModels
    ) {
      if (this.props.updateModels) {
        this.getSavedModels();
      }
    }
    if (
      prevProps.clearSearch !== this.props.clearSearch &&
      this.props.clearSearch
    ) {
      //Clear search result if model updated
      this.setState({ search: "", searchResult: [] });
      this.props.resetClearSearch();
    }

    // only update chart if the data has changed
    if (prevProps.selected !== this.props.selected && this.props.selected) {
      this.setState({ selected: this.props.selected });
    }

    if (
      JSON.stringify(prevProps.allTrainingData) !==
        JSON.stringify(this.props.allTrainingData) &&
      JSON.stringify(this.props.allTrainingData)
    ) {
    }
    if (
      JSON.stringify(prevProps.openIntent) !==
        JSON.stringify(this.props.openIntent) &&
      JSON.stringify(this.props.openIntent)
    ) {
    }

    if (
      prevProps.firstIntent !== this.props.firstIntent &&
      this.props.firstIntent
    ) {
      if (
        !this.state.searchClick &&
        !this.props.openIntent &&
        !this.props.openIntentResponse
      ) {
        this.getTrainingDataAndResponse(this.props.firstIntent);
        this.setState({ selected: 0 });
      }
    }

    if (
      prevProps.selectIntent !== this.props.selectIntent &&
      this.props.selectIntent
    ) {
      this.setState({
        selectedIntent: this.props.selectIntent.intent,
        openAddFromQueryLog: true,
        addValue: this.props.selectIntent.query,
        intentToLog: { name: this.props.selectIntent.intent },
      });

      this.getTrainingDataAndResponse(this.props.selectIntent.intent);

      this.setState({
        selected: this.getIndexOfIntent(this.props.selectIntent.intent),
      });

      this.setState({ selectIntent: this.props.selectIntent.query });
      //this.setState({response: " "});
    }

    if (
      prevProps.selectIntent !== this.props.selectIntent &&
      this.props.selectIntent === "new"
    ) {
      this.setState({ selectedIntent: " " });
      this.setState({ trainingData: "" });
    }

    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  testIntents(event) {
    this.props.openTestIntents();
  }

  ensureModel(event) {
    this.props.ensureTrainingModel(this.state.model);
  }

  showOverview(event) {
    this.props.openOverview(this.state.selectedIntent, this.state.model);
  }

  onKeyPress(e) {
    if (
      e.key === "Enter" &&
      this.state.addValue &&
      this.state.addValue !== ""
    ) {
      this.addNewIntent();
    }
  }

  setIntentStatus = (status) => {
    let statusToSend = "active";
    if (!status) {
      statusToSend = "inactive";
    }
    this.props.updateDataModel(
      this.state.selectedIntent,
      this.state.trainingData,
      statusToSend
    );
  };

  handleBackFromSearch(event) {
    this.setState({ tabValue: 0, gridValue: 2 });
  }

  filterArray(array, filters) {
    const filterKeys = filters;

    if (filterKeys) {
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.every((key) => {
          return (
            item.name.toLowerCase().indexOf(key.toLowerCase()) >= 0 ||
            (item.Category
              ? item.Category.toLowerCase().indexOf(key.toLowerCase()) >= 0
              : "")
          );
        });
      });
    }
  }

  changeModel(event, value) {
    if (value && value.Model) {
      this.props.changeModel(value);
      localStorage.setItem("model", value.Model);
      this.setState({ modelValue: value });

      this.setState({ model: value.Model });
      //reset TrainingData
      this.setState({ trainingData: [] });
    }
  }

  handleAddChip = (chip) => {
    let chips = this.state.yourChips;
    chips.push(chip);

    this.setState({ yourChips: chips });
  };

  handleDeleteChip = (chip, index) => {
    let chips = this.state.yourChips;

    chips.splice(index, 1);

    this.setState({ yourChips: chips });
  };

  handleSearchButtonClick(event) {
    this.setState({ tabValue: 1 });
    this.setState({ gridValue: 12 });
  }

  handlePanelChange(event) {
    if (this.state.expandedPanelModel) {
      this.setState({ expandedPanelModel: false });
      this.setState({ expandedPanelIntent: true });
    } else {
      this.setState({ expandedPanelModel: true });
      this.setState({ expandedPanelIntent: false });
    }
  }

  async getSavedModels(dataToUse) {
    let url = "/api/getSavedModels";
    const response = await fetch(url);
    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      let first = false;
      let modelToCompare = false;
      if (dataToUse) {
        modelToCompare = dataToUse.model;
      }

      data.map((d, i) => {
        if (modelToCompare && modelToCompare === d.Model) {
          first = d;
        } else if (i === 0) {
          first = d;
        }
      });

      /*No initial load on loading from other page*/
      if (!this.props.openIntent) {
        // If localstorgage variabel set, then see if it is available among all model
        if (localStorage.getItem("model")) {
          const model = localStorage.getItem("model");
          let mod = model;
          if (data) {
            data.map((m) => {
              if (m.Model === model) {
                this.setState({ modelValue: m });
                this.props.initialLoad(model, data);
                this.setState({ selected: 0, model: model });
              }
            });

            this.setState({ savedModels: data });
          }
        } else {
          this.setState({ modelValue: first });
          this.setState({ savedModels: data, model: first.Model });
          this.props.initialLoad(first.Model, data);
        }
      } else if (this.props.openIntent) {
        this.props.setAllModels(data);
        this.setState({ selected: 0 });
        if (first && data) {
          this.setState({
            savedModels: data,
            modelValue: first,
            model: first.Model,
          });
        }
      }

      setTimeout(() => {
        if (this.props.dataToUse) {
          this.setState({
            selected: this.getIndexOfIntent(this.props.dataToUse.intent),
          });
          this.getTrainingDataAndResponse(this.props.dataToUse.intent);
        }
      }, 2000);
      let pipeLine = [];
      let reducedPipline = [];
      data.map((obj) => {
        if (!pipeLine.includes(obj.Pipeline)) {
          pipeLine.push(obj.Pipeline);
        }
      });
      pipeLine.map((object) => {
        reducedPipline.push({ Pipeline: object });
      });
      this.props.setPipeLine(pipeLine.length > 0 ? pipeLine[0] : "en");
      //reducedPipline.push({"Pipeline": "da"});
      this.setState({ savedPipelines: reducedPipline });
    }
  }

  cellClick = (coldata, cellmeta) => {
    let modelCompare = this.state.searchResult[cellmeta.dataIndex][2];
    this.state.savedModels.map((m) => {
      if (m.Model === modelCompare) {
        this.props.changeModel(m.Model);
        this.setState({ modelValue: m }, async () => {
          this.getTrainingDataAndResponse(
            this.state.searchResult[cellmeta.dataIndex][0],
            true
          );
          this.setState({ searchClick: true });
          this.setState({ tabValue: 0, gridValue: 2 });
          this.setState({
            selectedIntent: this.state.searchResult[cellmeta.dataIndex][0],
            metadata: [],
          });
        });
        if (m.Model) {
          this.setState({ model: m.Model });
        }
      }
    });
  };

  handleSearch(event) {
    if (event.keyCode === 13) {
      this.handleSearchButton();
    }
  }

  handleSearchButton() {
    let rows = [];

    this.props.allTrainingData
      .filter((object) => {
        return (
          object.text.toLowerCase().indexOf(this.state.search.toLowerCase()) >=
          0
        );
      })
      .map((inten) => {
        rows.push(inten);
      });

    rows = rows.filter(
      (tag, index, array) =>
        array.findIndex((t) => t.intent == tag.intent && t.text == tag.text) ==
        index
    );

    let rowFinal = [];
    rows.map((inten) => {
      const { intent, text, model } = inten;

      rowFinal.push([intent, text, model]);
    });

    this.setState({ searchResult: rowFinal });
  }

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            /*backgroundColor: "#FFF",
                    borderRightColor: "#CCC",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid"*/
            height: "11px",
            cursor: "pointer",
          },
        },
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: "62vh",
            minHeight: "62vh",
          },
        },

        MuiTableRow: {},
      },
    });

  resetSelectResponse() {
    this.setState({ selectResponse: null });
  }

  async editIntentName() {
    let newName = this.state.newIntentName.trimEnd();
    const resp = await this.getResponse(this.state.selectedIntent);
    let updatedResponse = {
      intent: newName,
      metaData: { workflow: this.state.response },
    };
    let url =
      "/api/updateIntentName?newName=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      newName +
      "&currentName=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;

    let intentName =
      this.props.account + "_" + this.state.model + "_" + newName;
    this.props.saveUpdatedResponseToKv(
      intentName,
      JSON.stringify(updatedResponse)
    );

    let response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedResponse),
    }).catch((error) => console.log(error));
    let data = await response.json();

    if (data && data.length === 0) {
      this.props.getIntentList(newName, this.state.selectedIntent);

      setTimeout(
        this.setState({
          selectedIntent: newName,
          openEditModal: false,
        }),
        1000
      );
    }
  }

  changeIntentName(e) {
    let intentName = this.removeUnvantedChars(e.target.value);
    this.setState({ newIntentName: intentName });
  }

  changeIntentFromLog(event, value) {
    if (value && value.name) {
      this.setState({ intentToLog: { name: value.name } });
      this.setState({ selectedIntent: value.name });

      this.props.data.map((object, i) => {
        if (object.name === value.name) {
          this.setState({ selected: i });
        }
      });
      const getTraining = this.getTrainingDataAndResponse(value.name);
    }
  }

  addNewTrainingFromLog(e) {
    this.setState({ addValue: this.state.addValue.trimEnd() });

    this.updateTrainingData(this.state.addValue.trimEnd());
    this.setState({ openAddFromQueryLog: false });
    setTimeout(this.props.saveUpdatedTraining, 600);
  }

  handleCloseAddFromQueryLog(e) {
    this.setState({ openAddFromQueryLog: false });
  }

  openWorkflow = (value) => {
    this.props.openWorkflow(value);
  };

  cancelAddFromQuery() {
    this.props.cancelAddFromQuery();
  }

  async trainingDataAndResponse() {
    this.setState({ selectedIntent: this.state.openIntent.intent });

    const getTraining = await this.getTrainingDataAndResponse(
      this.state.openIntent.intent
    );

    setTimeout(() => {
      this.setState({
        selected: this.getIndexOfIntent(this.state.openIntent.intent),
      });
      this.props.resetOpenIntent();
    }, 2000);
  }

  saveUpdatedTraining() {
    this.updateDataModel();
  }

  tabChange = (value) => {
    this.setState({ marginRight: "0px" });
  };

  reactOnClick = (e, index) => {
    this.setState({ selectedIntent: e.target.innerText, metadata: [] });

    this.setState({ selected: index });
    this.setState({ response: " " });

    const getTraining = this.getTrainingDataAndResponse(e.target.innerText);
    //pass which intent is selected to the parent component
    this.props.selectedIntent(e.target.innerText);
  };

  createSelectedArray() {
    let selectedArray = [];

    this.props.data.map((object) => {
      selectedArray.push(false);
    });
    this.setState({ selectedArray: selectedArray });
  }

  async getTrainingDataAndResponse(intent, search) {
    setTimeout(() => {
      this.getTrainingData(intent, search);
    }, 500);
    const response = await this.getResponse(intent);
  }

  getIndexOfIntent(intent) {
    let index = 0;

    this.props.data.map((object, i) => {
      if (object.name === intent) {
        index = i;
      }
    });
    //this.setState({openIntent: null});
    return index;
  }

  getTrainingData(intent, search) {
    this.props.data.map((object) => {
      if (object.name === intent) {
        this.setState({ selectedIntent: intent });

        if (object.trainingData) {
          object.trainingData.map((obj) => {
            function getHash(input) {
              var hash = 0,
                len = input.length;
              for (var i = 0; i < len; i++) {
                hash = (hash << 5) - hash + input.charCodeAt(i);
                hash |= 0; // to 32bit integer
              }
              return hash;
            }

            obj.id = getHash(obj.text);
          });
        }

        this.setState({
          trainingData: object.trainingData,
          status: object.status,
        });
      }
    });

    if (search) {
      this.setState({
        selected: this.getIndexOfIntent(intent),
        searchClick: false,
      });
    }
  }

  listRow() {
    if (this.props.data) {
      /*return this.props.data.filter(object => {
                //remove names that doesnt match current filter text
                return object.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
            })*/

      let filteredArray = this.filterArray(
        this.props.data,
        this.state.yourChips
      );

      if (!filteredArray) {
        filteredArray = this.props.data;
      }
      return filteredArray.map((object, i) => {
        /*  {this.state.showSelect ? <div style={{
                      display: "inline-block",
                      width: "10%",
                      top: "50%",
                      textAlign: "center",
                      verticalAlign: "bottom"
                  }}><Checkbox id={i.toString()} onChange={this.onSelect}/></div> : null}*/
        return (
          <div key={i} style={{ width: "100%", maxWidth: "320px" }}>
            <div
              style={{
                display: "inline-block",
                width: "90%",
                fontSize: "12px",
              }}
            >
              <ListItem
                autoFocus={this.state.selected === i}
                onClick={(event) => this.reactOnClick(event, i)}
                classes={{ root: this.props.classes.listItemTextSelected }}
                key={i}
                selected={this.state.selected === i}
                button
              >
                <ListItemText
                  id={i}
                  classes={{ primary: this.props.classes.listItemText }}
                  primary={object.name}
                />
              </ListItem>
            </div>
          </div>
        );
      });
    }
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  deleteIntent() {
    this.createSelectedArray();
    this.setState({ showSelect: false });
  }

  deleteIntentByName() {
    this.props.deleteIntentByName(this.state.selectedIntent);

    this.setState({ openIntent: this.props.openIntent }, () => {
      if (this.props.openIntent && this.props.openIntent.intent) {
        this.trainingDataAndResponse();
      }
    });
    let index = -1;

    if (this.props.data.length - 1 > this.state.selected) {
      index = this.state.selected + 1;
    } else if (this.state.selected) {
      index = this.state.selected - 1;
    }

    if (
      this.props.data &&
      this.props.data[index] &&
      this.props.data[index].name
    ) {
      this.setState(
        { openIntent: { intent: this.props.data[index].name } },
        () => {
          this.trainingDataAndResponse();
        }
      );
    }
  }

  deleteTrainingData = (dataToDelete) => {
    let trainingData = this.state.trainingData;

    /*
        let newIntent = {name: value, trainingData: [], status: "active"};

        newIntent.trainingData.push({"text": "##empty##"});*/
    for (var i = dataToDelete.length; i >= 0; i--) {
      if (dataToDelete[i]) {
        trainingData.splice(i, 1);
      }
    }

    this.setState({ trainingData: trainingData }, this.saveUpdatedTraining());
  };

  changeTraining = (index, value) => {
    let training = this.state.trainingData;

    this.setState({ oldTraining: training });

    function getHash(input) {
      var hash = 0,
        len = input.length;
      for (var i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
      }
      return hash;
    }

    training.map((obj, i) => {
      if (obj.id && obj.id.toString() === index) {
        training[i].text = value;
        training[i].id = getHash(value);
      }
    });

    this.setState({ trainingData: training });
  };

  addEntity = (index, value) => {
    let training = this.state.trainingData;
    if (!training[index].entities) {
      training[index].entities = [];
    }

    training[index].entities.push(value);

    this.setState({ trainingData: training });

    setTimeout(this.updateDataModel(), 500);
  };

  deleteEntity = (trainingIndex, indexToDelete) => {
    let training = this.state.trainingData;

    if (
      training &&
      training[trainingIndex] &&
      training[trainingIndex].entities
    ) {
      training[trainingIndex].entities.splice(indexToDelete, 1);

      this.setState({ trainingData: training });

      setTimeout(this.updateDataModel(), 500);
    }
  };

  updateDataModel() {
    this.props.updateDataModel(
      this.state.selectedIntent,
      this.state.trainingData
    );
    this.props.setModifiedIntent(this.state.selectedIntent);
  }

  updateTrainingData = (newData) => {
    let trainingData = this.state.trainingData;

    function getHash(input) {
      var hash = 0,
        len = input.length;
      for (var i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
      }
      return hash;
    }

    trainingData.push({ text: newData, id: getHash(newData).toString() });
    this.setState({ trainingData: trainingData }, () => {
      this.updateDataModel();
    });
  };

  async getResponse(intent) {
    let url =
      "/api/response?intent=" +
      intent +
      "&model=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      intent;

    const response = await fetch(url);

    const data = await response.json();

    if (data.length > 0) {
      const object = data[0];

      if (object.Response) {
        try {
          const resp = JSON.parse(object.Response);

          let response = "";
          if (resp) {
            if (!resp.trainingSentences) {
              resp.trainingSentences = [];
            }
            if (!resp.buttonText) {
              resp.buttonText = [];
            }
            this.setState({ metadata: resp }, () => {});
          }

          if (resp.workflow) {
            const workflow = resp.workflow;

            const workflowParsed = JSON.parse(workflow);

            this.setState({ response: workflow });
          } else if (resp.responseText) {
            let flow = [
              { type: "conversation.message", message: resp.responseText },
            ];

            this.setState({ response: JSON.stringify(flow) });
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  saveTrainingData() {
    this.props.data.map((object) => {
      if (object.name === this.state.selectedIntent) {
        object.trainingData = this.state.trainingData;
      }
    });
  }

  handleAdd() {
    //this.props.addToModel();
    this.setState({ open: true });
    this.setState({ addValue: "" });
    this.setState({ addResponseValue: "" });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  updateResponse = (update) => {
    this.setState({ response: update });
  };

  removeUnvantedChars(str) {
    // Define the list of characters to remove
    const charsToRemove = ["&", "/", "_", "#", ":", "+"];

    // Replace each character in the list with an empty string
    charsToRemove.forEach((char) => {
      str = str.split(char).join("");
    });

    return str;
  }

  updateTextField(e) {
    let str = e.target.value;

    str = this.removeUnvantedChars(str);

    this.setState({ addValue: str });
    //this.props.updateTrainingData(e.target.value);
  }

  addNewIntent() {
    this.setState({ addValue: this.state.addValue.trimEnd() });
    this.props.addToIntentDataModel(this.state.addValue.trimEnd());
    this.setState({ open: false });
    this.setState({ selectedIntent: this.state.addValue.trimEnd() });
    this.setState({ response: JSON.stringify([]) });
    this.props.updateDataModel(
      this.state.selectedIntent,
      this.state.trainingData
    );
    this.props.updateResponseToDataModel(
      this.state.addValue.trimEnd(),
      this.state.addResponseValue
    );
    this.saveUpdatedTraining();

    setTimeout(() => {
      const getTraining = this.getTrainingDataAndResponse(
        this.state.addValue.trimEnd()
      );
    }, 1500);
  }

  async saveUpdatedResponse() {
    let url =
      "/api/getResponse?id=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;
    const response = await fetch(url);

    const data = await response.json();

    if (data.length > 0) {
      this.updateResponseModel();
    } else {
      this.saveResponeModel();
    }
  }

  saveResponeModel() {
    let newModel = "";

    if (this.state.response && this.state.response.type) {
      newModel = {
        intent: this.state.selectedIntent,
        metaData: { workflow: this.state.response },
      };
    } else {
      newModel = {
        intent: this.state.selectedIntent,
        metaData: { responseText: this.state.response },
      };
    }
    // add model and project as separate parameters

    let url =
      "/api/saveResponse?id=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;

    fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newModel),
    }).then((response) => {
      response.json().then((data) => {
        
      });
    });
  }

  /*Update Intent Response*/
  updateResponseModel() {
    let newModel = "";
    let response = "";

    if (this.state.updatedResponse !== "") {
      response = JSON.parse(this.state.updatedResponse);
    } else {
      response = this.state.response;
    }

    let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");

    if (response && response[0] && response[0].type) {
      newModel = {
        intent: this.state.selectedIntent,
        metaData: { workflow: JSON.stringify(response) },
      };
    }

    //Metadata around saving

    if (this.state.metadata && this.state.metadata.buttonTexts) {
      newModel.metaData.buttonTexts = this.state.metadata.buttonTexts;
    }
    if (this.state.metadata.trainingSentences) {
      newModel.metaData.trainingSentences =
        this.state.metadata.trainingSentences;
    }

    newModel.metaData.lastModified = createdTimeStamp;
    newModel.metaData.model = this.state.model;
    newModel.metaData.lastModifiedBy = this.props.loggedInUserEmail;

    // add model and project as separate parameters

    //check if exists
    let urlCheck =
      "/api/getResponse?id=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;

    fetch(urlCheck, {
      method: "get",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      response.json().then((data) => {
        if (data.length === 0) {
          this.props.updateResponseToDataModel(
            this.state.selectedIntent,
            newModel
          );
        }
      });
    });

    this.updateResponseToDb(newModel);
  }

  updateResponseToDb(newObject) {
    let url =
      "/api/updateResponse?intent=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;
    let name =
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      this.state.selectedIntent;

    fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newObject),
    }).then((response) => {
      response.json().then((data) => {
        if (data.length === 0) {
          this.setState({ notifierOpen: true });
          this.setState({ updatedResponse: " " });
          this.getResponse(this.state.selectedIntent);
        }
      });
    });

    //Save updated response
    this.props.saveUpdatedResponseToKv(name, JSON.stringify(newObject));
  }

  scrollToBottom() {
    let thing = document.getElementById("thing");
    thing.scrollTop = thing.scrollHeight - thing.clientHeight;
  }

  updateTextResponse(e) {
    this.setState({ addResponseValue: e.target.value });
  }

  resetNewTrainingData() {
    this.props.resetNewTrainingData();
  }

  changeState() {
    this.setState({ notifierOpen: false });
  }

  updateResponseFromMetadata = (value) => {
    if (value && value.workflow) {
      value.workflow = this.state.response;
    }
    let updatedIntentObj = {
      intent: this.state.selectedIntent,
      metaData: value,
    };

    this.updateResponseToDb(updatedIntentObj, "metadata");
  };

  updateResponseFromWorkflow = (value) => {
    this.setState({ updatedResponse: value });
    setTimeout(this.updateResponseModel, 300);
  };

  clearSearch() {
    this.setState({ search: "" });
  }

  clearInput() {
    this.setState({ filter: "" });
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        id="intentListWrapper"
        style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}
      >
        {this.state.tabValue === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "350px",
              minWidth: "350px",
            }}
          >
            <Paper
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                paddingTop: "20px",
                paddingBottom: "10px",
                maxHeight: "300px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                  marginBottom: "00px",
                  marginRight: "20px",
                }}
              >
                {this.state.savedModels &&
                  this.state.savedModels.length > 1 && (
                    <React.Fragment>
                      {this.state.ensureUI && this.state.ensureUI === true ? (
                        <FormControl
                          className={classes.formControl}
                          style={{
                            width: "100%",
                            paddingLeft: "0px",
                            paddingRight: "20px",
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Autocomplete
                              id="modelBox"
                              options={this.state.savedModels}
                              onChange={this.changeModel}
                              value={this.state.modelValue}
                              getOptionLabel={(option) => option.Model}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Model"
                                  style={{}}
                                />
                              )}
                            />
                            <Tooltip title="Ensure Model">
                              <IconButton
                                size="medium"
                                style={{
                                  margin: "5px",
                                  background: "#3E51B5",
                                  color: "white",
                                }}
                                onClick={this.ensureModel}
                                color="primary"
                                aria-label="Ensure Model"
                                component="span"
                              >
                                <DirectionsWalkIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </FormControl>
                      ) : (
                        <FormControl
                          className={classes.formControl}
                          style={{
                            width: "100%",
                            paddingLeft: "0px",
                            paddingRight: "20px",
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <Autocomplete
                            id="modelBox"
                            options={this.state.savedModels}
                            onChange={this.changeModel}
                            value={this.state.modelValue}
                            getOptionLabel={(option) => option.Model}
                            style={{ width: "100%", minWidth: "150px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Model"
                                style={{}}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </React.Fragment>
                  )}

                <div
                  style={{
                    backgroundColor: "white",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "0px",
                    padding: "0px",
                    flexWrap: "wrap",
                    paddingBottom: "0px",
                  }}
                >
                  <Tooltip title="Train Model">
                    <IconButton
                      size="medium"
                      style={{
                        margin: "5px",
                        background: "#3E51B5",
                        color: "white",
                      }}
                      onClick={this.props.postTrainingDataToKvNew}
                      color="primary"
                      aria-label="Train Model"
                      component="span"
                    >
                      <DirectionsWalkIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Validate Training Data">
                    <IconButton
                      size="medium"
                      style={{
                        margin: "5px",
                        background: "#3E51B5",
                        color: "white",
                      }}
                      onClick={this.props.validateTrainingData}
                      color="primary"
                      aria-label="Validate Training Data"
                      component="span"
                    >
                      <SpellcheckIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Import Data Model">
                    <IconButton
                      size="medium"
                      style={{
                        margin: "5px",
                        background: "#3E51B5",
                        color: "white",
                      }}
                      onClick={this.props.handleShowImport}
                      color="primary"
                      aria-label="Import Data Model"
                      component="span"
                    >
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export Data Model As CSV">
                    <IconButton
                      size="medium"
                      style={{
                        margin: "5px",
                        background: "#3E51B5",
                        color: "white",
                      }}
                      onClick={this.props.handleExport}
                      color="primary"
                      aria-label="Export Data Model As CSV"
                      component="span"
                    >
                      <ImportExportIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Search">
                    <IconButton
                      size="medium"
                      style={{
                        marginTop: "10px",
                        background: "#3E51B5",
                        color: "white",
                        margin: "5px",
                      }}
                      onClick={this.handleSearchButtonClick}
                      color="primary"
                      aria-label="Search"
                      component="span"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add">
                    <IconButton
                      size="medium"
                      style={{
                        margin: "5px",
                        background: "#3E51B5",
                        color: "white",
                      }}
                      onClick={this.handleAdd}
                      color="primary"
                      aria-label="Add"
                      component="span"
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>

                  {this.state.selectedIntent && (
                    <Tooltip title="Delete">
                      <IconButton
                        size="medium"
                        style={{
                          margin: "5px",
                          background: "#3E51B5",
                          color: "white",
                        }}
                        onClick={this.deleteIntentByName}
                        color="primary"
                        aria-label="Delete"
                        component="span"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {this.state.selectedIntent && (
                    <Tooltip title="Edit Intent Name">
                      <IconButton
                        size="medium"
                        style={{
                          margin: "5px",
                          background: "#3E51B5",
                          color: "white",
                        }}
                        onClick={() => {
                          this.setState({
                            newIntentName: this.state.selectedIntent,
                            openEditModal: true,
                          });
                        }}
                        color="primary"
                        aria-label="Edit Intent Name"
                        component="span"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <div
                    style={{
                      marginLeft: "10px",
                      display: this.state.showField ? "block" : "none",
                    }}
                  >
                    <TextField
                      id="saveAsField"
                      label="Save As"
                      style={{
                        backgroundColor: "white",
                      }}
                      className={classes.textField}
                      value={this.state.saveAsName}
                      onChange={this.handleSaveAsChange}
                      margin="normal"
                      variant="filled"
                    />
                  </div>
                  <div style={{ marginLeft: "8px" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={this.saveModelWithNewName}
                      style={{
                        marginLeft: "10px",
                        display: this.state.showSave ? "block" : "none",
                      }}
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>

                  {this.state.loading && (
                    <div style={{ marginTop: "30px" }}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  )}

                  {this.state.showSelect ? (
                    <Fab
                      color="primary"
                      onClick={this.deleteIntent}
                      aria-label="Add"
                      className={classes.fab}
                      size="medium"
                    >
                      <DeleteIcon />
                    </Fab>
                  ) : null}

                  <Tooltip title="Flow Overview">
                    <IconButton
                      style={{
                        background: "#3E51B5",
                        color: "white",
                        marginLeft: "-5px",
                      }}
                      onClick={this.showOverview}
                      aria-label="Flow Overview"
                      component="span"
                    >
                      <PublicIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Test Intents">
                    <IconButton
                      style={{
                        background: "#3E51B5",
                        color: "white",
                        marginLeft: "10px",
                      }}
                      onClick={this.testIntents}
                      aria-label="Test Intents"
                      component="span"
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Paper>

            <Paper
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
                paddingTop: "0px",
                padding: "0px",
                height: "100%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    background: "#FFF",
                    width: "100%",
                    marginRight:
                      this.state.yourChips.length === 0 ? "-15px" : "0px",
                  }}
                >
                  {/*<TextField
                                            label="Filter"
                                            style={{width: "100%", height: "40px", margin: "0px"}}
                                            className={classes.textField}
                                            value={this.state.filter}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="filled"
                                            InputProps={{
                                                endAdornment: <InputAdornment onClick={this.clearInput}
                                                                              position="end">{this.state.filter !== "" ?
                                                    <IconButton><Clear style={{fontSize: 16}}/></IconButton> :
                                                    <div></div>}</InputAdornment>,
                                            }}


                                        />*/}

                  <ChipInput
                    value={this.state.yourChips}
                    variant="filled"
                    label="Filter"
                    style={{ width: "100%" }}
                    onAdd={(chip) => this.handleAddChip(chip)}
                    onDelete={(chip, index) =>
                      this.handleDeleteChip(chip, index)
                    }
                  />

                  <List
                    style={{
                      width: "100%",
                      maxWidth: "330px",
                      marginTop: "10px",
                      minHeight: this.state.selectedIntent
                        ? this.state.savedModels &&
                          this.state.savedModels.length > 1
                          ? "calc(100vh - 350px)"
                          : "calc(100vh - 304px)"
                        : this.state.savedModels &&
                          this.state.savedModels.length > 1
                        ? "calc(100vh - 140px)"
                        : "calc(100vh - 110px)",
                      maxHeight: this.state.selectedIntent
                        ? this.state.savedModels &&
                          this.state.savedModels.length > 1
                          ? "calc(100vh - 350px)"
                          : "calc(100vh - 304px)"
                        : this.state.savedModels &&
                          this.state.savedModels.length > 1
                        ? "calc(100vh - 140px)"
                        : "calc(100vh - 110px)",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    component="nav"
                    className={classes.root}
                    id="thing"
                  >
                    {this.listRow()}
                  </List>
                </div>
              </div>
            </Paper>
          </div>
        )}

        {this.state.tabValue === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 100px)",
              width: "100%",
              marginTop: "-15px",
            }}
          >
            <div
              style={{
                marginTop: "-15px",
                minHeight: "320px",
              }}
            >
              <Fab
                color="primary"
                onClick={this.handleBackFromSearch}
                aria-label="Back"
                size={"small"}
                style={{
                  marginBottom: "0px",
                  marginTop: "25px",
                  marginRight: "20px",
                }}
                className={classes.fab}
              >
                <ArrowBack />
              </Fab>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "calc(100% - 20px)",
                  borderBottom: "1px solid #888",
                  paddingBottom: "7px",
                  marginLeft: "15px",
                  paddingRight: "10px",
                }}
              >
                <TextField
                  label="Search"
                  style={{
                    width: "30%",
                    height: "40px",
                    marginLeft: "-5px",
                  }}
                  className={classes.textField}
                  value={this.state.search}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearch}
                  margin="normal"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={this.clearSearch} position="end">
                        {this.state.search !== "" ? (
                          <IconButton>
                            <Clear style={{ fontSize: 16 }} />
                          </IconButton>
                        ) : (
                          <div></div>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {this.state.tabValue === 1 && this.state.searchResult && (
                <div style={{ marginTop: "20px" }}>
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title={"Search Result"}
                      data={this.state.searchResult}
                      columns={[
                        {
                          name: "Intent",
                          options: {
                            filter: true,
                            setCellProps: (value) => {
                              return {
                                className: classnames({
                                  [classes.NameCell]: value,
                                }),
                              };
                            },
                          },
                        },

                        "Training Sentence",
                        "Model",
                      ]}
                      options={{
                        filter: false,
                        filterType: "checkbox",
                        print: false,
                        download: false,
                        searchable: false,
                        export: false,
                        viewColumns: false,
                        responsive: "scroll",
                        selectableRows: "none",
                        rowsPerPage: 10,
                        onCellClick: (colData, cellMeta) => {
                          this.cellClick(colData, cellMeta);
                        },
                      }}
                    />
                  </MuiThemeProvider>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.trainingData !== "" && this.state.tabValue === 0 && (
          <Paper
            className={classes.paper}
            style={{
              marginRight: this.state.marginRight,
              height: "calc(100% - 20px)",
            }}
          >
            <TrainingdataBox
              response={this.state.response}
              metadata={this.state.metadata}
              status={this.state.status}
              voicebot={this.props.voicebot}
              roles={this.props.roles}
              updateSentences={this.props.updateSentences}
              updateTestSentences={this.props.updateTestSentences}
              selectResponse={this.state.selectResponse}
              setIntentStatus={this.setIntentStatus}
              resetSelectResponse={this.resetSelectResponse}
              saveUpdatedResponse={this.saveUpdatedResponse}
              updateResponse={this.updateResponse}
              trainingData={this.state.trainingData}
              aiAssisted={this.props.aiAssisted}
              changeTraining={this.changeTraining}
              saveUpdatedTraining={this.saveUpdatedTraining}
              saveTrainingData={this.saveTrainingData}
              updateTrainingData={this.updateTrainingData}
              intent={this.state.selectedIntent}
              newTrainingData={this.state.selectIntent}
              deleteTrainingData={this.deleteTrainingData}
              resetNewTrainingData={this.resetNewTrainingData}
              updateResponseFromWorkflow={this.updateResponseFromWorkflow}
              updateResponseFromMetadata={this.updateResponseFromMetadata}
              model={this.state.model}
              pipeline={this.props.pipeline}
              addEntity={this.addEntity}
              deleteEntity={this.deleteEntity}
              account={this.props.account}
              classes={classes.TrainingdataBox}
              tabChange={this.tabChange}
              openWorkflow={this.openWorkflow}
            />
          </Paper>
        )}

        <Modal
          aria-labelledby="Add training sentence from Query Log"
          aria-describedby=" Add training sentence from Query Log"
          open={this.state.openAddFromQueryLog}
          onClose={this.handleCloseAddFromQueryLog}
        >
          <div style={getModalStyle()} className={classes.modal}>
            <div>
              <Typography variant="h6" id="modal-title">
                Add from Query Log
              </Typography>

              <Typography
                variant="subtitle2"
                id="simple-modal-description"
                style={{ marginTop: "40px" }}
              >
                <TextField
                  label="Training Sentence"
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={this.updateTextField}
                  value={this.state.addValue}
                />
              </Typography>

              <Typography
                variant="subtitle1"
                id="simple-modal-description"
                style={{ marginTop: "30px" }}
              >
                <FormControl
                  variant="outlined"
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box3"
                    options={this.props.data}
                    onChange={this.changeIntentFromLog}
                    value={this.state.intentToLog}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Intent"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />
                </FormControl>
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="subtitle2" id="simple-modal-description">
                  <Button
                    style={{ width: "100%", minWidth: "190px" }}
                    color="primary"
                    onClick={this.addNewTrainingFromLog}
                    variant="contained"
                  >
                    Add
                  </Button>
                </Typography>
                <Typography
                  variant="subtitle2"
                  id="simple-modal-description"
                  style={{ marginLeft: "20px" }}
                >
                  <Button
                    style={{ width: "100%", minWidth: "190px" }}
                    color="primary"
                    onClick={() => {
                      this.setState(
                        { openAddFromQueryLog: false },
                        this.cancelAddFromQuery
                      );
                    }}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Typography>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.modal}>
            {this.state.tabValue === 0 && (
              <div>
                <Typography variant="h6" id="modal-title">
                  Add Intent
                </Typography>
                <Typography variant="subtitle1" id="simple-modal-description">
                  <TextField
                    label="Intent Name"
                    style={{ width: "100%" }}
                    onChange={this.updateTextField}
                    value={this.state.addValue}
                    onKeyDown={this.onKeyPress}
                  />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      style={{ width: "100%" }}
                      color="primary"
                      onClick={() => {
                        this.setState({ open: false });
                      }}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                    {this.state.addValue &&
                      this.state.addValue.trim().length > 0 && (
                        <Button
                          style={{ width: "100%", marginLeft: "20px" }}
                          color="primary"
                          onClick={this.addNewIntent}
                          variant="contained"
                        >
                          Add
                        </Button>
                      )}
                  </div>
                </Typography>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          aria-labelledby="Edit-Intent-Name"
          aria-describedby="Edit-Intent-Name"
          open={this.state.openEditModal}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.modal}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" id="modal-title">
                Edit Intent Name
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormControl
                    variant="outlined"
                    style={{ borderColor: "black" }}
                    className={classes.formControl}
                  >
                    <TextField
                      label="Intent Name"
                      style={{
                        marginLeft: "-10px",
                        backgroundColor: "white",
                        width: "100%",
                      }}
                      className={classes.textField}
                      value={this.state.newIntentName}
                      onChange={this.changeIntentName}
                      margin="normal"
                      variant="outlined"
                    />
                  </FormControl>

                  <div style={{ marginTop: "20px" }}>
                    <Button
                      style={{
                        marginLeft: "0px",
                        marginTop: "20px",
                        padding: "10px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.editIntentName}
                    >
                      Update
                    </Button>
                    <Button
                      style={{
                        marginLeft: "20px",
                        marginTop: "20px",
                        padding: "10px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({ openEditModal: false });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Typography>
            </div>
          </div>
        </Modal>
        <Notifier
          changeState={this.changeState}
          open={this.state.notifierOpen}
          message="New response saved successfully"
        />
      </div>
    );
  }
}

IntentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntentList);
