import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {
    MiniMap,
    addEdge,
    ConnectionLineType,
    useNodesState,
    useEdgesState,
    Controls,
    Background
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import '../index.css';
import DownloadButton from './DownloadButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;
const minimapStyle = {
    height: 120,
};


const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    const isHorizontal = direction === 'LR';

    dagreGraph.setGraph({rankdir: direction});

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, {width: nodeWidth, height: nodeHeight});
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return {nodes, edges};
};

let initialNodes2 = [];
let initialEdges2 = [];

const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
    initialNodes2,
    initialEdges2
);


const LayoutFlow = (props) => {

    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

    useEffect(() => {
        const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
            props.nodes,
            props.edges,
            'LR'
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);

    }, [nodes, edges]);


    const nodeClick = (event, node) => {
        
        if(node.data.label.indexOf("Intent Response") === -1){
            props.openWorkflow(node.data.label);
        }

    };

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge({...params, type: ConnectionLineType.SmoothStep, animated: true}, eds)
            ),
        []
    );
    const onLayout = useCallback(
        (direction) => {

            const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
                nodes,
                edges,
                direction
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );

    const nodeInfo = (event) => {


    }

    return (


        <div className="layoutflow">

            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeClick={nodeInfo}
                onNodeDoubleClick={nodeClick}
                connectionLineType={ConnectionLineType.SmoothStep}
                fitView
            >
                <Background/>
                <MiniMap style={minimapStyle} zoomable pannable/>
                <Controls/>
            </ReactFlow>
            <div className="controls">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <DownloadButton/>
                    <Tooltip title="Vertical Rendering">
                        <IconButton  aria-label="delete" style={{marginLeft: "-10px", color:"#000000"}} size="small"
                                    onClick={() => onLayout('TB')}>
                            <ViewColumnIcon/>
                        </IconButton></Tooltip>
                    <Tooltip title="Horizontal Rendering">
                        <IconButton style={{marginLeft: "-10px", color:"#000000"}} onClick={() => onLayout('LR')}
                                    aria-label="Horizontal" size="small">
                            <HorizontalSplitIcon/>
                        </IconButton>
                    </Tooltip>

                </div>

            </div>
        </div>
    );
};

export default LayoutFlow;
