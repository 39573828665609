import React, {Fragment, useEffect, useState, useCallback} from "react";
import {Box, Tab, Tabs, makeStyles} from "@material-ui/core/";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button/Button";
import List from "@material-ui/core/List";
import {ListItem, ListItemText} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Notifier from "./Notifier";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DefaultContext from '../BOTconfig/DefaultContext.json';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(4),
        minWidth: 120
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'
    },
    listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },

}));

const getModalStyle = function () {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const Context = (props) => {

    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    const [addOpen, setAddOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [addValue, setAddValue] = useState("");
    const [notifierOpen, setNotifierOpen] = useState(false);
    const [notifierCopyOpen, setNotifierCopyOpen] = useState(false);
    const [contextObject, setContextObject] = useState(null);
    const [defaultValue, setDefaultValue] = useState("");
    const [question, setQuestion] = useState("");
    const [validation, setValidation] = useState("");
    const [validationErrorMessage, setValidationErrorMessage] = useState("");
    const [contextValues, setContextValues] = useState(null);
    const [selected, setSelected] = useState(0);
    const [contextName, setContextName] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [description, setDescription] = useState("");

    useEffect(() => {
        getContext();

    }, []);


    const copyToClipBoard = function(event, text){


        navigator.clipboard.writeText(text).then(function () {
            setNotifierCopyOpen(true);
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });

    }

    const puzzelUser = async function () {

        let url = '/api/getPuzzelUser';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

        }
    }

    const sortObject = function (data) {

        let sorted = Object.fromEntries(Object.entries(data).sort());
        return sorted;
    }

    const getContext = async function () {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();
            if (data && data.name !== 'error') {

                let sorted = sortObject(data);

                setContextObject(sorted);
                setContextValues(Object.keys(sorted));

                if (!contextName) {

                    setContextName(Object.keys(sorted)[0]);
                    setQuestion(sorted[Object.keys(sorted)[0]].question);
                    setValidation(sorted[Object.keys(sorted)[0]].validation);
                    setValidationErrorMessage(sorted[Object.keys(sorted)[0]].validationErrorMessage);
                    setDefaultValue(sorted[Object.keys(sorted)[0]].default);
                }
            }
        }
    }


    const reactOnClick = function (event, i) {
        loadContext(i);
    }

    const reactOnDefaultClick = function (event, i) {

        let keys = Object.keys(DefaultContext);

        setDescription(DefaultContext[keys[i]].description);
        setContextName(keys[i]);
        setSelected(i);
    }

    const loadContext = function (i) {

        if (contextObject && contextValues) {
            const co = contextObject[contextValues[i]];
            setSelected(i);
            setContextName(contextValues[i]);
            setQuestion(co.question);
            setValidation(co.validation);
            setValidationErrorMessage(co.validationErrorMessage);
            setDefaultValue(co.default);
        }
    }

    const listRow = function () {
        if (contextValues && contextValues.length > 0) {

            return contextValues.map((object, i) => {
                return <div key={i} style={{width: "100%", maxWidth: "328px", paddingRight: "0px", marginRight: "0px"}}>

                    <ListItem autoFocus={selected === i}
                              onClick={event => reactOnClick(event, i)}
                              classes={{root: classes.listItemTextSelected}}
                              key={i}
                              selected={selected === i}
                              button>
                        <ListItemText id={i}
                                      classes={{primary: classes.listItemText}}
                                      primary={object}/>
                    </ListItem>

                </div>;
                ;
            });

        }

    }
    const listDefaultRow = function () {
        if (DefaultContext) {

            return Object.keys(DefaultContext).map((object, i) => {
                return <div key={i} style={{width: "100%", maxWidth: "328px", paddingRight: "0px", marginRight: "0px"}}>

                    <ListItem autoFocus={selected === i}
                              onClick={event => reactOnDefaultClick(event, i)}
                              classes={{root: classes.listItemTextSelected}}
                              key={i}
                              selected={selected === i}
                              button>
                        <ListItemText id={i}
                                      classes={{primary: classes.listItemText}}
                                      primary={object}/>
                    </ListItem>

                </div>;
                ;
            });

        }

    }


    const onKeyPress = function (event) {

        if (event.key === 'Enter') {
            addNewContext();
            setAddOpen(false);
        }
    }

    const handleTabChange = function (event, value) {
        setTabValue(value);
        if (value === 1) {
            reactOnDefaultClick(event, 0);
        } else {
            reactOnClick(event, 0);
        }
    }
    const updateContextDb = async function (event) {

        let url = '/api/updateContext?accountid=' + props.account;

        let response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(contextObject)
        }).catch(error => console.log(error)
        );

        const data = await response.json();
        if (data && data.name !== 'error') {
            setNotifierOpen(true);
            getContext();
        }


    }
    const updateContext = function (event) {

        const co = contextObject[contextName];
        co.question = question;
        co.validationErrorMessage = validationErrorMessage;
        co.validation = validation;
        co.default = defaultValue;
        updateContextDb();

    }
    const handleConfirmDeleteClose = function (event) {

        if (event.target.innerText === "YES") {
            deleteContext();
        }

        setConfirmDeleteOpen(false);
    }
    const deleteContext = function (event) {

        delete contextObject[contextName];

        if (selected === contextValues.length - 1) {
            loadContext(contextValues.length - 2);
        } else {
            loadContext(selected + 1);
        }

        updateContextDb();

    }


    const addNewContext = function (event) {

        contextValues.push(addValue);
        contextValues.sort();


        contextValues.map((val, i) => {
            if (val === addValue) {
                setSelected(i);
            }
        });


        let newContext = {};
        newContext.question = "";
        newContext.validation = "^.+$";
        newContext.validationErrorMessage = "";
        newContext['default'] = "";
        contextObject[addValue] = newContext;

        sortObject(contextObject);

        setContextObject(contextObject);
        setContextName(addValue);
        setValidation("^.+$");
        setQuestion("");
        setValidationErrorMessage("");
        setDefaultValue("");

        setAddOpen(false);


    }

    return (

        <div style={{display: "flex", flexDirection: "row"}}>

            <Paper>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="User defined"/>
                    <Tab label="Default"/>
                </Tabs>

                {tabValue === 0 && <div style={{padding: "20px", display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{display: "flex", alignItems: "center"}}><IconButton
                            style={{background: "#3E51B5", color: "white"}}
                            onClick={(event) => {
                                setAddOpen(true);
                                setAddValue("");
                            }}><AddIcon
                            style={{fontSize: 24}}/></IconButton></div>

                        <Tooltip title="Delete Context">
                            <IconButton style={{background: "#3E51B5", color: "white", marginLeft: "20px"}}
                                        onClick={() => {
                                            setConfirmDeleteOpen(true)
                                        }} aria-label="Delete Context"
                                        component="span">
                                <DeleteIcon/>
                            </IconButton>

                        </Tooltip>
                    </div>
                    <List
                        style={{
                            width: "100%",
                            maxWidth: "330px",
                            marginTop: "10px",
                            minWidth: "150px",
                            minHeight: "calc(100vh - 180px)",
                            maxHeight: "calc(100vh - 180px)",
                            overflowY: "auto",
                            overflowX: "hidden",
                            border: "1px solid #ccc"
                        }}
                        component="nav"
                        id='thing'
                    >
                        {listRow()}
                    </List>
                </div>}
                {tabValue === 1 && <div style={{padding: "20px", display: "flex", flexDirection: "column"}}>
                    <List
                        style={{
                            width: "100%",
                            maxWidth: "330px",
                            marginTop: "10px",
                            minWidth: "150px",
                            minHeight: "calc(100vh - 180px)",
                            maxHeight: "calc(100vh - 180px)",
                            overflowY: "auto",
                            overflowX: "hidden",
                            border: "1px solid #ccc"
                        }}
                        component="nav"
                        id='thing'
                    >
                        {listDefaultRow()}
                    </List>
                </div>}
            </Paper>
            {tabValue === 0 && <Paper style={{padding: "20px", marginLeft: "20px", width: "100%"}}>
                <Box >
                    {contextName &&
                        <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <TextField
                                label="Context"
                                value={contextName}
                                margin="normal"
                                multiline={false}
                                variant="outlined"

                                style={{width: "100%"}}
                            />
                            <Tooltip title="Copy to name to clipboard">
                                <IconButton
                                    style={{marginTop: "5px"}}
                                    onClick={(event) => copyToClipBoard(event, contextName)} aria-label="Delete Customer Journey"
                                    component="span">
                                    <FileCopyIcon/>
                                </IconButton>

                            </Tooltip>
                            </div>
                            <TextField
                                label="Question"
                                value={question}
                                onChange={(event) => {
                                    setQuestion(event.target.value);
                                }}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{width: "100%"}}
                            />
                            <TextField
                                label="Default Value"
                                value={defaultValue}
                                onChange={(event) => {
                                    setDefaultValue(event.target.value);
                                }}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{width: "100%"}}
                            />
                            <TextField
                                label="RegEx Validation"
                                value={validation}
                                onChange={(event) => {
                                    setValidation(event.target.value);
                                }}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{width: "100%"}}
                            />
                            <TextField
                                label="Validation Error Message"
                                value={validationErrorMessage}
                                onChange={(event) => {
                                    setValidationErrorMessage(event.target.value);
                                }}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{width: "100%"}}
                            />
                            <Button style={{minWidth: "150pxm"}} color="primary" variant="contained"
                                    onClick={updateContext}>Save</Button>
                        </div>}
                </Box>
            </Paper>}
            {tabValue === 1 && <Paper style={{padding: "20px", marginLeft: "20px"}}>
                <Box >
                    {contextName &&
                        <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <TextField
                                    label="Context"
                                    value={contextName}
                                    margin="normal"
                                    multiline={false}
                                    variant="outlined"
                                    style={{width: "100%", minWidth: "530px"}}
                                />

                                <Tooltip title="Copy to name to clipboard">
                                    <IconButton
                                        style={{marginTop: "5px"}}
                                                onClick={(event) => copyToClipBoard(event, contextName)} aria-label="Delete Customer Journey"
                                                component="span">
                                        <FileCopyIcon/>
                                    </IconButton>

                                </Tooltip>

                            </div>

                            <TextField
                                label="Description"
                                value={description}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{width: "100%", minWidth: "530px"}}
                            />

                        </div>}
                </Box>
            </Paper>}
            <Notifier changeState={() => {
                setNotifierOpen(false)
            }} open={notifierOpen}
                      message={"Context: " + contextName + " updated successfully"}/>

            <Notifier changeState={() => {
                setNotifierCopyOpen(false)
            }} open={notifierCopyOpen}
                      message={contextName +" copied to clipboard"}/>

            <Modal
                open={addOpen}
                onClose={() => {
                    setAddOpen(false);
                }}
            >
                <div style={modalStyle} className={classes.modal}>
                    <div>
                        <Typography variant="h6" id="modal-title">
                            Add Context
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            <TextField label="New Context Name" style={{width: "100%"}} onChange={(event) => {
                                let name = event.target.value;
                                name = name.replaceAll(/\s/g, '');
                                setAddValue(name);
                            }}
                                       value={addValue} onKeyUp={onKeyPress}/>

                            <br/><br/><br/>
                            <br/>
                            <Button style={{width: "100%"}} color="primary" onClick={addNewContext}
                                    variant="contained">Add</Button>
                        </Typography></div>

                </div>
            </Modal>

            <Dialog
                open={confirmDeleteOpen}
                onClose={handleConfirmDeleteClose}
            >
                <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {contextName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDeleteClose} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleConfirmDeleteClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};
export default Context;
