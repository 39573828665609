import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class ImageInChat extends Component {


    constructor(props) {

        super(props);


        this.handleChangePath = this.handleChangePath.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeCaption = this.handleChangeCaption.bind(this);
        this.renderImage = this.renderImage.bind
        (this);
        this.pass = this.pass.bind(this);
        this.state = {
            workFlowRender: null,
            path: "",
            title: "",
            caption: "",
            renderImage: false
        };

    }

    componentDidMount() {
        if(this.props.value){

            this.setState({path: this.props.value.path});
            this.setState({title: this.props.value.title});
            this.setState({caption: this.props.value.caption});

        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {


            this.setState({path: this.props.value.path});
            this.setState({title: this.props.value.title});
            this.setState({caption: this.props.value.caption});

        }

    }



    handleChangePath(e) {

        this.setState({path: e.target.value})


    }

    handleChangeTitle(e) {

        this.setState({title: e.target.value})

    }
    handleChangeCaption(e) {

        this.setState({caption: e.target.value})

    }

    renderImage(e){

        this.setState({renderImage:true});
    }

    pass(){

        let workflow = {type: "website.imageInChat", "path": this.state.path, "title": this.state.title};
        this.props.passData("website.imageInChat", workflow);


    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <TextField
                        label="URL"
                        value={this.state.path}
                        onChange={this.handleChangePath}
                        onBlur={this.renderImage}
                        margin="normal"
                        variant="outlined"
                    />
                    {this.state.renderImage? <img src={this.state.path} style={{maxHeight: "100px", maxWidth:"30%"}}/>: <div></div>}


                    <TextField
                        label="Title"
                        value={this.state.title}
                        onChange={this.handleChangeTitle}
                        margin="normal"
                        variant="outlined"
                    />
                   {/* <TextField
                        label="Caption"
                        value={this.state.caption}
                        onChange={this.handleChangeCaption}
                        margin="normal"
                        variant="outlined"
                    />&& this.props.value.caption*/}
                    {this.props.value && this.props.value.path && this.props.value.title ? <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"  onClick={this.pass}>Update</Button>: <Button  style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"    onClick={this.pass}>Add</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(ImageInChat);
