import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getBotConfig,
  getActionsForThisAccount,
  updateActionManifest,
} from "./reused/reusedfunctions";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "./styles/botadmin.scss";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/show-hint.css"; // without this css hints won't show
import "codemirror/addon/search/match-highlighter";
import "codemirror/addon/search/matchesonscrollbar";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/fold/markdown-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldgutter.css";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddAction from "./AddAction";
import Notifier from "./Notifier";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { updateActionInDb } from "./reused/reusedfunctions";
const ActionsAdmin = (props) => {
  const [workflows, setWorkflows] = useState("{}");
  const [codeValue, setCodeValue] = useState(" ");
  const [manifest, setManifest] = useState(" ");
  const [allActions, setAllActions] = useState([]);
  const [actionArray, setActionArray] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const codeMirrorRef = useRef(null);
  const [notifierMessage, setNotifierMessage] = useState("");
  const [notifierOpen, setNotifierOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [libraries, setLibraries] = useState(null);
  useEffect(() => {
    getConfig();
  }, []);

  //Function get all the configuration for this bot account
  const getConfig = async () => {
    const data = await getBotConfig();
    if (data) {
      getAllBotActions();
    }
  };

  useEffect(() => {
    if (props.environment) {
      setActionArray([]);
      setTimeout(getConfig, 1000);
    }
  }, [props.environment]);

  const changeState = () => {
    setNotifierOpen(false);
  };

  const getAllBotActions = async () => {
    const data = await getActionsForThisAccount();
    setAllActions(data);
  };

  useEffect(() => {
    parseActions();
  }, [allActions]);

  const parseActions = () => {
    const actionMap = new Map();
    let libs = new Map();
    allActions.map((action) => {
      libs.set(action.library, "");
      let actionArray = actionMap.get(action.library);
      if (actionArray) {
        actionArray.push(action);
        actionMap.set(action.library, actionArray);
      } else {
        let newActionArray = [];
        newActionArray.push(action);
        actionMap.set(action.library, newActionArray);
      }
    });

    setLibraries([...libs.keys()]);
    const finalActionArray = Array.from(actionMap);

    setActionArray(finalActionArray);
  };

  const findObject = (selectedNode) => {
    if (selectedNode) {
      let returnObject;
      actionArray.map((array) => {
        if (array[0] === selectedNode.library) {
          if (array.length > 1) {
            array[1].map((action) => {
              if (action.name === selectedNode.name) {
                returnObject = action;
              }
            });
          }
        }
      });
      return returnObject;
    }
  };

  const handleTreeSelect = (event, nodeId) => {
    let selectedNode = JSON.parse(nodeId);
    let selectedObject = findObject(selectedNode);

    if (selectedObject) {
      setCodeValue(selectedObject.botscript);
      setManifest(
        JSON.stringify(JSON.parse(selectedObject.jsonconf), null, "\t")
      );
    }

    setSelectedNode(selectedNode);
    setSelectedObject(selectedObject);
  };

  const renderTree = useCallback(() => {
    if (actionArray) {
      
      return actionArray.map((object, i) => {
        if (object.length > 1) {
          const library = object[0];
          const actionArray = object[1];

          return (
            <TreeItem
              key={"Node" + (i + 1)}
              nodeId={JSON.stringify({ library: library })}
              label={library}
            >
              {actionArray.map((a, u) => {
                return (
                  <TreeItem
                    key={"Treeitem" + u * (i + 1)}
                    nodeId={JSON.stringify({ name: a.name, library: library })}
                    label={a.name}
                  ></TreeItem>
                );
              })}
            </TreeItem>
          );
        }
      });
    }
  }, [actionArray]);

  useEffect(() => {
    if (actionArray) {
      renderTree();
    }
  }, [setActionArray, actionArray, renderTree]);

  const updateActionCode = async (event) => {
    
    if (selectedObject) {
      let version = selectedObject.version + 1;
      const status = await updateActionInDb(selectedObject.id, codeValue, version);
      if (status) {
        setNotifierOpen(true);
        setNotifierMessage(
          "Action: " + selectedObject.name + " successfully updated"
        );
        setTimeout(getConfig, 1000);
        
      }
    }
  };

  const addAction = (event) => {
    setOpenModal(true);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const updateManifest = async (event) => {
    if (selectedObject) {
      const status = await updateActionManifest(selectedObject.id, manifest);
      if (status) {
        setNotifierOpen(true);
        setNotifierMessage(
          "Action: " + selectedObject.name + " successfully updated"
        );
        getConfig();
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ height: "calc(100vh - 74px)" }}>
        <Paper style={{ height: "100%", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-around",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginBottom: "0px",
              }}
            >
              <Tooltip title="Add to log" placement="bottom">
                <IconButton onClick={addAction} size="small">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <TreeView
              aria-label="Action Navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={handleTreeSelect}
              style={{
                height: 240,
                maxHeight: 500,
                flexGrow: 1,
                width: 300,
                maxWidth: 600,
                minHeight: "calc(100vh - 160px)",
                overflowY: "auto",
                marginTop: "10px",
              }}
            >
              {renderTree()}
            </TreeView>
          </div>
        </Paper>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <Paper>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Code" />
                <Tab label="Manifest" />
              </Tabs>
            </div>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "15px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                alignItems: "center",
              }}
            >
              {selectedNode && selectedNode.name}
            </span>
          </div>
          {tabValue === 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CodeMirror
                value={codeValue}
                ref={codeMirrorRef}
                options={{
                  mode: "application/json",
                  lineNumbers: true,
                  lint: true,
                  lineWrapping: true,
                  foldGutter: true,
                  gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                }}
                onBeforeChange={(editor, data, value) => {
                  setCodeValue(value);
                }}
                onChange={(editor, data, value) => {}}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "20px",
                    width: "300px",
                  }}
                  onClick={updateActionCode}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {tabValue === 1 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CodeMirror
                value={manifest}
                ref={codeMirrorRef}
                options={{
                  mode: "application/json",
                  lineNumbers: true,
                  lint: true,
                  lineWrapping: true,
                  foldGutter: true,
                  gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                }}
                onBeforeChange={(editor, data, value) => {
                  setManifest(value);
                }}
                onChange={(editor, data, value) => {}}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "20px",
                    width: "300px",
                  }}
                  onClick={updateManifest}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
      <AddAction
        notify={(notifierMessage) => {
          setNotifierMessage(notifierMessage);
          setNotifierOpen(true);
          getConfig();
        }}
        libraries={libraries}
        clearOpen={() => {
          setOpenModal(false);
        }}
        openModal={openModal}
      />
      <Notifier
        changeState={changeState}
        open={notifierOpen}
        message={notifierMessage}
      />
    </div>
  );
};
export default ActionsAdmin;
