import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        textField: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            width: 200,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
        button: {

            padding: '0 10px'
        },
        listItemText: {


            selected: {
                backgroundColor: '#000',
                color: '#fff'

            }
        },
        fab: {
            margin: theme.spacing(4),
        },

        paper: {
            padding: '10px',
            textAlign: 'left',
            color: theme.palette.text.secondary,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            marginTop: '20px'

        },
        modal: {
            position: 'absolute',
            width: theme.spacing(50),
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: 'none',
        },
        paperForm: {
            display: 'flex',
            flexDirection: 'row'


        },
        menuItemRoot:{

            fontSize: "15px"
        },
        select: {
            fontSize: "15px"
        }
    }


);


class IntentMatching extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.listRow = this.listRow.bind(this);
        this.createIntentArray = this.createIntentArray.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            value: {"name": "", "value": ""},
            queryValue: "",
            allIntentMatchingArray: []
        }
        this.convRef = React.createRef();
    }

    createIntentArray(){
        let allIntentMatchingArray = [];

        if(this.state.allIntents ){
            this.state.allIntents.map((o, i)=>{
                let found = false;

                this.state.items.map((object)=>{

                    if(o === object.name){
                        allIntentMatchingArray.push({"value": object.name, "name":object.name+" ["+Math.round(object.confidence*100,2)+"%]"});
                        found = true;


                        if(o === this.state.loadedValue && this.state.loadedValue !== ""){

                            let v = this.state.loadedValue;
                            this.setState({value: {"value": this.state.loadedValue, "name": object.name+" ["+Math.round(object.confidence*100,2)+"%]"}} );

                        }
                    }

                });


                if(!found){

                    allIntentMatchingArray.push({"value":o, "name": o});

                }

            });
            allIntentMatchingArray.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            })
            this.setState({allIntentMatchingArray:allIntentMatchingArray });
        }
    }


    listRow(){

        if(this.state.allIntentMatchingArray){

            return this.state.allIntentMatchingArray.map((o,i) =>{
                return <MenuItem classes={{root: this.props.classes.menuItemRoot}} key={i} value={o.value}>{o.name}</MenuItem>
            });

        }

    }
    handleChange(event, value) {

        let oldValue = this.state.value;

        if(value && value.name){
            this.setState({value: {"name":value.name}});
        }
        this.props.changeIntent(value,  oldValue, this.props.queryValue);

    }


    componentDidMount() {

        this.setState({completeId: Math.random().toString(36).substr(2, 9)});


        if (this.props.items) {

            this.setState({items: this.props.items, allIntents: this.props.allIntents}, ()=>{
                this.createIntentArray();
            });

        }

        if(this.props.value){

            this.setState({loadedValue: this.props.value, });
            this.setState({value: {"value": this.props.value, "name":this.props.value}});
        }

        if(this.props.queryValue){
            this.setState({queryValue: this.props.queryValue});
        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.props.resetOtherIntentMatchings){


        }


        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) && this.props.value !== "") {
            this.setState({value: {"value": this.props.value, "name":this.props.value}});


        }

    }


    pass() {

    }




    render() {
        const {classes} = this.props;


        return (



            <Autocomplete
                id={Math.random().toString()}
                options={this.state.allIntentMatchingArray}
                onChange={this.handleChange}
                value={this.state.value}
                getOptionLabel={option =>  option.name}
                style={{ minWidth: "200px", width: "100%", maxWidth: "390px"}}
                renderInput={params => <TextField {...params}  />}
            />



        )
    }

}

export default withStyles(styles)(IntentMatching);
