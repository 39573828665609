import moment from "moment";

export async function getSettings() {
  let url = "/api/getSettings";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (data) {
    return data;
  }
  return null;
}

export async function getDraftModels(account) {
  let url = "/api/getSavedModels";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (data) {
    if (account) {
      let returnData = [];
      data.map((model) => {
        if (
          model?.Project &&
          model?.Traininginfo &&
          JSON.parse(model?.Traininginfo) &&
          JSON.parse(model?.Traininginfo).draft
        ) {
          returnData.push(model);
        }
      });
      return returnData;
    }
  }
  return null;
}

export async function getSavedModels(account) {
  let url = "/api/getSavedModels";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (data) {
    if (account) {
      let returnData = [];

      data.map((model) => {
        if (model?.Project && model?.Model.indexOf("draft") === -1) {
          returnData.push(model);
        }
      });

      return returnData;
    }
    return data;
  }
  return null;
}

export async function getConfig() {
  let url = "/api/getConfig";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();

  return data;
}

export async function getDomains() {
  let url = "/api/getDomains";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();

  return data;
}

export async function getBaseUrl() {
  let url = "/api/getBaseUrl";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();

  return data?.baseUrl;
}

export async function getBotConfig() {
  let url = "/api/getBotAccountParameters";
  let options = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function getBotFlows() {
  let url = "/api/getBotFlows";
  let options = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data) {
    return data.workflows;
  }
  return null;
}

export async function getActionsForThisAccount() {
  let url = "/api/getActionsForThisAccount";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  }).catch((error) => console.log(error));
  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function getIntegrationsForThisAccount() {
  let url = "/api/getIntegrationsForThisAccount";
  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  }).catch((error) => console.log(error));
  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function saveNewAction(name, library, jsonConf, actionCode) {
  if (name && library && jsonConf && actionCode) {
    let url = "/api/addWorkflowAction";

    let config = {
      actionCode: actionCode,
      jsonConf: JSON.parse(jsonConf),
      name: name,
      library: library,
    };

    let options = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config),
    };

    let response = await fetch(url, options).catch((error) =>
      console.log(error)
    );
    let data = await response;

    if (data && data.status === 200) {
      return true;
    }
  }
  return false;
}

export async function saveNewIntegration(
  name,
  library,
  jsonConf,
  integrationCode
) {
  if (name && library && jsonConf && integrationCode) {
    let url = "/api/addIntegration";

    let config = {
      integrationCode: integrationCode,
      jsonConf: JSON.parse(jsonConf),
      name: name,
      library: library,
    };

    let options = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(config),
    };

    let response = await fetch(url, options).catch((error) =>
      console.log(error)
    );
    let data = await response;

    if (data && data.status === 200) {
      return true;
    }
  }
  return false;
}

export async function updateActionInDb(actionId, codeValue, actionVersion) {
  let url = "/api/updateAction";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      actionCode: codeValue,
      actionId: actionId,
      actionVersion: actionVersion,
    }),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data.length === 0) {
    return true;
  }

  return false;
}

export async function updateIntegrationInDb(
  integrationId,
  codeValue,
  integrationVersion
) {
  let url = "/api/updateIntegration";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      integrationCode: codeValue,
      integrationId: integrationId,
      integrationVersion: integrationVersion,
    }),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data.length === 0) {
    return true;
  }

  return false;
}

export async function updateActionManifest(actionId, manifest) {
  let url = "/api/updateActionManifest";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      actionManifest: manifest,
      actionId: actionId,
    }),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data.length === 0) {
    return true;
  }

  return false;
}

export async function updateIntegrationManifest(actionId, manifest) {
  let url = "/api/updateIntegrationManifest";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      integrationManifest: manifest,
      integrationId: actionId,
    }),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response.json();

  if (data.length === 0) {
    return true;
  }

  return false;
}

export async function updateConfiguration(config, environment) {
  let conf = {};
  conf[environment] = JSON.parse(config);

  let url = "/api/updateConfiguration";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(conf),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response;

  if (data.status === 200) {
    return true;
  }

  return false;
}

export async function updateWorkflows(flows) {
  let config = { workflows: JSON.parse(flows) };
  let url = "/api/updateAdminWorkflows";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));
  let data = await response;

  if (data.status === 200) {
    return true;
  }

  return false;
}

export async function getAdminLog(interval) {
  let config = { interval: interval };
  let url = "/api/getAdminLog";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function getAdminLogById(startId, endId, conversationId, caseId) {
  let config = {};

  if (startId && endId) {
    config = { startId: startId, endId: endId };
  }

  if (conversationId) {
    config["conversationid"] = conversationId;
  } else if (caseId) {
    config["caseid"] = caseId;
  }

  let url = "/api/getAdminLogById";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function getAdminLogByDate(
  startDate,
  endDate,
  conversationId,
  caseId
) {
  let config = { startDate: startDate, endDate: endDate };

  if (conversationId) {
    config["conversationid"] = conversationId;
  } else if (caseId) {
    config["caseid"] = caseId;
  }

  let url = "/api/getAdminLogByDate";
  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function getR4Token() {
  let url = "/api/getR4Token";

  const response = await fetch(url);

  const data = await response.json();

  if (data && data.access_token) {
    return data.access_token;
  }
  return null;
}

export async function postToKv(name, value) {
  const domains = await getDomains();
  const token = await getR4Token();

  let url = "https://" + domains.restDomain.default + "/kv/" + name;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(value),
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions);

  const data = await response.text();

  return data;
}

export async function startTraining(modelname) {
  const domains = await getDomains();
  const token = await getR4Token();

  let url = "https://" + domains.restDomain.default + "/Train/" + modelname;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions);
  const data = await response.text();
  return data;
}

export async function publishModel(draftModelName, modelName) {
  let url = "/api/publishModel";

  let config = { draftModelId: draftModelName, modelId: modelName };

  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function createPublishModel(modelName) {
  let url = "/api/createPublishModel";

  let config = { modelId: modelName };

  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function checkIfPublishModelExist(modelName) {
  let url = "/api/checkIfPublishModelExist";

  let config = { modelId: modelName };

  let options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(config),
  };

  let response = await fetch(url, options).catch((error) => console.log(error));

  let data = await response.json();

  if (data) {
    return data;
  }
  return null;
}

export async function ensureModel(modelName) {
  const domains = await getDomains();
  const token = await getR4Token();

  let url =
    "https://" + domains.restDomain.default + "/EnsureRasaServer/" + modelName;

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const response = await fetch(url, requestOptions);

  const data = await response.text();

  if (data) {
    console.log("Ensure started", data);
  }
}
//Function to check if the latest trained draft model is started and if not start it
export async function checkIfDraftModelsAreStarted(models) {
  models.map(async (model) => {
    const draftModelName = model.Model;
    const status = await trainingStatus(draftModelName);
    
    if (
      status &&
      status.containerStatuses &&
      status.containerStatuses.length > 0 &&
      status?.containerStatuses[0]?.state?.terminated?.finishedAt
    ) {
      const TrainingFinishedDate = new Date(
        status?.containerStatuses[0]?.state?.terminated?.finishedAt
      );
     
      const trainingData = await getTrainingData(draftModelName);
      if (trainingData[0]) {
        let trainInfo = trainingData[0].Traininginfo;
        let inf;
        try {
          inf = JSON.parse(trainInfo);
          let startModel = false;
          if (!inf.ensureRasaDate) {
            startModel = true;
          }
          const ensuredDate = new Date(inf.ensureRasaDate);
          
          if (TrainingFinishedDate > ensuredDate || startModel) {
            const writeInfo = await writeTrainingInfoToDb(
              draftModelName,
              moment(new Date()).format("YYYY-MM-DD HH:mm"),
              inf.username,
              inf.email,
              true,
              null
            );
            const ensure = await ensureModel(draftModelName);
            
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  });
}

export async function trainingStatus(modelname) {
  const domains = await getDomains();
  const token = await getR4Token();

  let url =
    "https://" + domains.restDomain.default + "/TrainStatus/" + modelname;
    
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions);

  let data;
  if(response.status === 200){
    try {
      
      data = await response.json();
      return data;
    } catch (e) {
      console.log("Error in trainingStatus", e);
    }
  }
  return null;
}

export async function saveIntentResponse(
  account,
  intent,
  model,
  loggedInUserEmail,
  responseWorkflow,
  createdDateString
) {
  if (responseWorkflow && responseWorkflow.intent) {
    let createdTimeStamp = createdDateString;
    responseWorkflow.metaData.created = createdTimeStamp;
    responseWorkflow.metaData.createdBy = loggedInUserEmail;
  }

  let responseName = account + "_" + model + "_" + intent;
  postToKv(responseName, JSON.stringify(responseWorkflow));
  var url = "/api/saveResponse?id=" + responseName;
  let response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(responseWorkflow),
  });

  let data = await response.json();
  return data;
}

export async function deleteIntentResponse(account, model, intent) {
  let responseName = account + "_" + model + "_" + intent;
  var url = "/api/deleteStandAloneResponse?responseId=" + responseName;
  let response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  let data = await response;
  return data;
}

// parse the Intent Map To Training Data Model JSON
export async function parseMapToJsonTrainingDataModel(map, trainingDataJson) {
  if (trainingDataJson) {
    trainingDataJson.rasa_nlu_data.common_examples = [];
    map.forEach((value, key) => {
      value.map((sentence) => {
        trainingDataJson.rasa_nlu_data.common_examples.push({
          text: sentence,
          intent: key,
        });
      });
    });
    return trainingDataJson;
  } else {
    return null;
  }
}

export async function updateTrainingDataModel(account, model, trainingdata) {
  const url = "/api/updateModel?account=" + account + "&id=" + model;

  const response = await fetch(url, {
    method: "post",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: trainingdata,
  });
  const data = await response;

  if (data && data.status === 200) {
    return true;
  }
  return false;
}

export async function saveTrainingDataModel(
  pipeline,
  account,
  model,
  trainingdata
) {
  const url =
    "/api/saveModel?project=" +
    account.trim() +
    "&pipeline=" +
    pipeline +
    "&model=" +
    model;

  const response = await fetch(url, {
    method: "post",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: trainingdata,
  });
  const data = await response;

  if (data && data.status === 200) {
    return true;
  }
  return false;
}

export async function getTrainingDataMap(model) {
  const data = await getTrainingData(model);
  let intentMap = new Map();
  if (data && data.length > 0) {
    JSON.parse(data[0].Value).rasa_nlu_data.common_examples.map((intent) => {
      if (intentMap.has(intent.intent)) {
        let sentences = intentMap.get(intent.intent);
        sentences.push(intent.text);
        intentMap.set(intent.intent, sentences);
      } else {
        intentMap.set(intent.intent, [intent.text]);
      }
    });
  }
  return intentMap;
}
export async function getTrainingData(model) {
  let url = "/api/trainingDataByModel?model=" + model;

  let response = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });

  let data = await response.json();

  return data;
}

export async function writeTrainingInfoToDb(
  model,
  date,
  loggedInUserName,
  loggedInUserEmail,
  draft,
  publishedDate
) {

  let url = "/api/writeTrainingInfo?model=" + model;

  let infoObj = {
    username: loggedInUserName,
    email: loggedInUserEmail,
    trainingDate: date,
    ensureRasaDate: date,
    draft: draft,
  };
  
  if (publishedDate) {
    infoObj['publishedDate'] = publishedDate;
  }
  
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(infoObj),
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  return data;
}

export async function getWorkflowResponseFromDb(intent, model, account) {
  let url =
    "/api/getWorkflowResponseFromDb?responseId=" +
    account +
    "_" +
    model +
    "_" +
    intent;

  const response = await fetch(url);
  const data = await response.json();

  return data;
}

//function to query intent model
export async function queryIntentModel(model, query) {
  const domains = await getDomains();
  const token = await getR4Token();
  let url =
    "https://" + domains.restDomain.default + "/parse/" + model + "?q=" + query;

  const options = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

// Update response workflow in the database
export async function updateResponse(account, model, intent, responseWorkflow) {
  let url =
    "/api/updateResponse?intent=" + account + "_" + model + "_" + intent;
  
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(responseWorkflow),
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();

  return data;
}
