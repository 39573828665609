import {useEffect} from "react";

const TestBot = (props) => {

    useEffect(() => {

        if (props.account && !window.psjsLoaded && !window.vngage) {
            window.psjsLoaded = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://account.psplugin.com/' + props.account + '/ps.js';
            script.async = true;
            document.head.appendChild(script);

           setTimeout(() => {
                if (window.vngage) {
                    window.vngage.removeAllBanners();
                }
            }, 1000);

            // Cleanup function
            return () => {
                // Introduce a small delay (e.g., 100ms) before removing the script
                setTimeout(() => {
                    // Remove the script from the DOM
                    document.head.removeChild(script);

                    // Reset the loaded flag to allow loading again when the account changes
                    window.psjsLoaded = false;
                }, 100);
            };
        }
    }, [props.account]);

    return null;
};

export default TestBot;
