import React, {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import MUIDataTable from "mui-datatables";
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import moment from "moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ModelSelect from './ModelSelect'
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {
        height: "80px",
        root: {
            height: "80px",
            input: {

                height: "10px"
            }
        },
        input: {

            height: "10px"
        }

    },
    input: {

        height: "45px"
    },
    label: {fontSize: "40px"},


});

const theme = createTheme({
    overrides: {

        MuiInputBase: {

            root: {
                /*height: "35px",
                lineHeight: "0.9em",*/

            }
        }


    },
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class WorkflowStats extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Intent hits',
            keyValue: [],
            linkedClicked: [],
            linkedClickedRaw: [],
            confidence: '',
            chartData: [],
            chartData2: [],
            chartData3: [],
            chartData4: [],
            models: [],
            model: "",
            value: 0,
            confData: [],
            date: new Date(moment().subtract(1, "days").format("YYYY-MM-DD HH:mm")),
            toDate: new Date(moment().add(1, "days").format("YYYY-MM-DD HH:mm")),

        }
        this.handleSubmit = this.handleSubmit.bind(this);

        this.createChartData = this.createChartData.bind(this);
        this.createChartData2 = this.createChartData2.bind(this);
        this.createChartData3 = this.createChartData3.bind(this);
        this.createChartData4 = this.createChartData4.bind(this);
        this.queryIntent = this.queryIntent.bind(this);
        this.updateConfTable = this.updateConfTable.bind(this);
        this.cellClick = this.cellClick.bind(this);
        this.cellClickCreateIntent = this.cellClickCreateIntent.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);

    }

    cellClickCreateIntent(cellData, cellMetaData) {
        if (cellMetaData.colIndex === 1) {
            this.props.passData(this.state.confData[cellMetaData.rowIndex][3], cellData);
            this.props.history.push('/trainingdata');
        }
    }

    async getSavedModels() {


        let url = '/api/getSavedModels';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            this.setState({savedModels: data});

        }


    }

    cellClick(celldata) {

        //selectedIntents.map( intent => console.log(intent));
        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");
        let url = '/api/trackingRaw?event=' + celldata + '&fromDate=' + dateString + '&toDate=' + toDateString + "&account=" + this.props.account;
        fetch(url)
            .then(response => {
                response.json()
                    .then(data => {


                        let rows = [];
                        for (let row of data) {

                            const {event, createdAt, conversationId} = row;

                            rows.push([event, moment(createdAt).format("YYYY-MM-DD HH:mm"), conversationId])

                        }

                        this.setState({chartData3: rows});


                        this.setState({value: 2});


                    })
            })


    };

    onChangeDate = (date) => {

        this.setState({date: date})
    }

    onChangeToDate = (date) => {

        this.setState({toDate: date})
    }

    updateConfTable(data) {


    }

    queryLinkClickedRaw() {

        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");

        fetch('/api/linkClickedRaw?date=' + dateString + '&toDate=' + toDateString + "&account=" + this.props.account)
            .then(response => {

                if (response.redirected) {
                    //console.log(response);
                    //window.location.href = window.location.href;

                }

                response.json()
                    .then(data => {

                        if (data && data.length > 0 && data.name !== 'error') {

                            this.setState({linkedClickedRaw: data});
                            this.createChartData4();
                        }
                    })
            })

    }

    queryLinkClicked() {

        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");

        fetch('/api/linkClickedAgg?date=' + dateString + '&toDate=' + toDateString + "&account=" + this.props.account)
            .then(response => {

                if (response.redirected) {
                    //console.log(response);
                    //window.location.href = window.location.href;

                }

                response.json()
                    .then(data => {

                        if (data && data.length > 0 && data.name !== 'error') {

                            this.setState({linkedClicked: data});
                            this.createChartData3();
                        }
                    })
            })
    }

    queryIntent() {
        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");

        fetch('/api/tracking?date=' + dateString + '&toDate=' + toDateString + "&account=" + this.props.account)
            .then(response => {


                if (response.redirected) {
                    //console.log(response);
                    //window.location.href = window.location.href;

                }


                response.json()
                    .then(data => {

                        if (data && data.length > 0 && data.name !== 'error') {

                            this.setState({keyValue: data});

                            this.createChartData();
                            this.createChartData2();
                        }
                    })
            })


    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({value: 0});
        this.queryIntent();
        /*if(this.state.value === 0 || this.state.value === 1) {

        }else if(this.state.value === 2){

            this.queryLinkClicked();

        }else if( this.state.value === 3){

            this.queryLinkClickedRaw();
        }*/

    }

    componentDidMount() {

        /*keycloak.init().success(function(authenticated) {
            alert(authenticated ? 'authenticated' : 'not authenticated');
        }).error(function() {
            alert('failed to initialize');
        });*/

        if (this.props.model) {

            //console.log(this.props.model)
            this.setState({model: this.props.model});


        }

        if (this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }

        //this.getSavedModels();
        this.queryIntent();


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.model !== this.props.model) {

//            console.log(this.props.model)
            //this.setState({model: this.props.model});


        }

        if (prevProps.pipeline !== this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }
    }


    createChartData() {


        let rows = [];

        if (this.state.keyValue !== []) {

            for (let row of this.state.keyValue) {
                const {count, event} = row;

                rows.push([count, event])

            }

            this.setState({chartData: rows});

        }

    }


    createChartData2() {
        const columns = ['Event', 'Count'];

        let rows = [];

        for (let row of this.state.keyValue) {
            const {event, count} = row

            rows.push([event, eval(count)])

        }

        var array = [columns, ...rows];


        this.setState({chartData2: array});

    }

    createChartData4() {
        let rows = [];

        if (this.state.linkedClickedRaw !== []) {

            for (let row of this.state.linkedClickedRaw) {

                const {linkName, url, createdAt, conversationId} = row;
                rows.push([linkName, url, moment(createdAt).format("YYYY-MM-DD HH:mm"), conversationId]);

            }
            this.setState({chartData4: rows});
        }
    }

    createChartData3() {


        let rows = [];

        if (this.state.confData !== []) {

            for (let row of this.state.confData) {
                const {event, createdAt, conversationId} = row;


                rows.push([event, createdAt, conversationId]);

            }

            this.setState({chartData3: rows});

        }

    }

    handleChange = (event, value) => {
        this.setState({value});

        if (value === 2) {

            this.queryLinkClicked();

        }
        if (value === 3) {

            this.queryLinkClickedRaw();

        }

    };
    setModel = (model) => {

        this.setState({model: model});
        this.queryIntent();
    }

    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        return (
            <MuiThemeProvider theme={theme}>
                <div style={{marginRight: "10px"}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Paper className={classes.paper}>

                                <div style={{display: "flex", flexDirection: "row", width: "100%", marginLeft: "20px"}}>
                                    {/* <ModelSelect model={this.state.model} setModel={this.setModel}
                                             data={this.state.savedModels}/>*/}
                                    <div style={{marginTop: "0px"}}>
                                        <form onSubmit={this.handleSubmit} className={classes.paperForm}>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                < FormControl style={{borderColor: "black"}}
                                                              className={classes.formControl}
                                                              style={{
                                                                  minWidth: "200px",
                                                                  minHeight: "100px",
                                                                  paddingLeft: "0px",
                                                                  paddingRight: "20px",
                                                                  marginTop: "0px",
                                                                  marginBottom: "5px",
                                                                  padding: "10px"
                                                              }}>
                                                    <DatePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        inputVariant="outlined"
                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="From Date"
                                                        style={{}}
                                                        value={this.state.date}
                                                        onChange={this.onChangeDate}

                                                    />
                                                </FormControl>
                                                < FormControl style={{borderColor: "black"}}
                                                              className={classes.formControl}
                                                              style={{
                                                                  minWidth: "200px",
                                                                  minHeight: "100px",
                                                                  paddingLeft: "0px",
                                                                  paddingRight: "20px",
                                                                  marginTop: "0px",
                                                                  marginBottom: "5px",
                                                                  padding: "10px"
                                                              }}>
                                                    <DatePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        id="date-picker-inline2"
                                                        label="To Date"
                                                        style={{marginLeft: "20px"}}
                                                        value={this.state.toDate}
                                                        onChange={this.onChangeToDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </FormControl>
                                            </MuiPickersUtilsProvider>

                                            {/* <DateFormatInput id="fromDate" label="From date:" name='date-input' value={date}
                                                             onChange={this.onChangeDate} /><span
                                            style={{marginLeft: "20px", marginTop: "10px", height: "40px"}}>
                             </span><DateFormatInput id="toDate" label="To date:" name='date-input' value={toDate}
                                                     onChange={this.onChangeToDate}/>*/}
                                            <span
                                                style={{marginLeft: "20px", marginTop: "10px"}}> <Button
                                                variant="contained" color="primary"
                                                type="submit">Show Workflow Hits</Button></span>
                                        </form>
                                    </div>
                                </div>
                            </Paper>

                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Tabs value={value} onChange={this.handleChange}>
                                    <Tab label="Table"/>
                                    <Tab label="Chart"/>
                                    <Tab label="Data"/>
                                    {/*  <Tab label="External Links Clicked"/>
                                    <Tab label="External Links Clicked RAW"/>*/}

                                </Tabs>


                                {value === 0 &&
                                <Grid container spacing={6} style={{overflow: "auto"}}>
                                    <Grid item xs={12} >


                                                <MUIDataTable

                                                    title={"Workflow Statistics"}
                                                    data={this.state.chartData}
                                                    columns={["Number of hits",
                                                        {
                                                            name: "Workflow",
                                                            options: {
                                                                filter: true,
                                                                setCellProps: (value) => {
                                                                    return {
                                                                        className: classnames(
                                                                            {
                                                                                [classes.NameCell]: value
                                                                            })
                                                                    };
                                                                }
                                                            }
                                                        }]}
                                                    options={{
                                                        filter: true,
                                                        filterType: "checkbox",
                                                        responsive: "standard",
                                                        selectableRows: "none",
                                                        onCellClick: (colData, cellMeta) => {
                                                            this.cellClick(colData);

                                                        }


                                                    }}
                                                />


                                    </Grid>
                                </Grid>
                                }
                                {value === 1 && <TabContainer>

                                    <Paper>

                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: "40px",
                                            minHeight: "580px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <Chart
                                                width={'1400px'}
                                                height={'550px'}
                                                chartType="Bar"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.chartData2}
                                                options={{
                                                    title: 'Tracking ',
                                                    chartArea: {left: 20, top: 0, width: '90%', height: '75%'},
                                                    hAxis: {
                                                        title: 'Number of executed workflows',
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        title: 'Workflow',
                                                    },
                                                }}


                                                rootProps={{'data-testid': '1'}}
                                            />
                                        </div>

                                    </Paper>
                                </TabContainer>
                                }

                                {value === 2 && <TabContainer>

                                    <Paper>

                                        <MUIDataTable
                                            title={"Data"}
                                            data={this.state.chartData3}
                                            columns={["Workflow Name", "Created At", "Conversation Id"]}
                                            options={{
                                                filter: true,
                                                filterType: "checkbox",
                                                responsive: "scroll",
                                                selectableRows: "none",
                                                rowsPerPage: 100,


                                            }}
                                        />

                                    </Paper>
                                </TabContainer>
                                }
                                {value === 3 && <TabContainer>

                                    <Paper>

                                        <MUIDataTable
                                            title={"Data"}
                                            data={this.state.chartData3}
                                            columns={["Link Name", "Url", "Created At", "Conversation Id"]}
                                            options={{
                                                filter: true,
                                                filterType: "checkbox",
                                                responsive: "scroll",
                                                selectableRows: "none",
                                                onCellClick: (colData, cellMeta) => {
                                                    this.cellClick(colData);

                                                }


                                            }}
                                        />

                                    </Paper>
                                </TabContainer>
                                }
                                {value === 4 && <TabContainer>

                                    <Paper>

                                        <MUIDataTable
                                            title={"External Links Clicked Raw Data"}
                                            data={this.state.chartData4}
                                            columns={["Link Name", "Url", "Created At", "Conversation Id"]}
                                            options={{
                                                filter: true,
                                                filterType: "checkbox",
                                                responsive: "scroll",
                                                selectableRows: "none",
                                                onCellClick: (colData, cellMeta) => {
                                                    this.cellClick(colData);

                                                }


                                            }}
                                        />

                                    </Paper>
                                </TabContainer>
                                }
                            </Paper>


                        </Grid>
                    </Grid>


                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(WorkflowStats);
