import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class ExecuteOnDate extends Component {


    constructor(props) {

        super(props);

        this.pass = this.pass.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.changeSetFlow = this.changeSetFlow.bind(this);
        this.changeNotSetFlow = this.changeNotSetFlow.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.addDate = this.addDate.bind(this);
        this.renderDates = this.renderDates.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            commonWorkflows: [],
            setFlow: {"Id": ""},
            notSetFlow: {"Id": ""},
            equalValue: "",
            operator: "",
            dates: [],
            days: "",
            date: moment(new Date()).format("YYYY-MM-DD HH:mm"),


        }
        //this.convRef = React.createRef();
    }

    handleDelete = (e, i) => {
        this.state.dates.splice(i, 1);
        this.setState({dates: this.state.dates}, () => {
            this.pass();
        });

    }

    renderDates() {


        return this.state.dates.map((date, i) => {


            return <TableRow key={i}>
                <TableCell style={{minWidth: "170px"}} scope="row">
                    {moment(date).format("MM/DD")}
                </TableCell>

                <TableCell align="right"> <div style={{textAlign:"right", width: "100%"}}><InputAdornment onClick={event => this.handleDelete(event, i)}
                                                          position="end"><IconButton><Delete
                    style={{fontSize: 24}}/></IconButton></InputAdornment></div></TableCell>
            </TableRow>


        })


    }

    addDate() {
        let dates = this.state.dates;

        let formattedDates = [];
        dates.map((date) => {
            formattedDates.push(moment(date).format("MM/DD"));
        })

        if (formattedDates.indexOf(moment(this.state.date).format("MM/DD")) === -1) {

            formattedDates.push(moment(this.state.date).format("MM/DD"));
        }

        this.setState({dates: formattedDates})
    }

    onChangeDate = (date) => {

        this.setState({date: date})
    }


    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            workflowArray.push({"Id": "Workflow.GoNext"});
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length - 1]) {


                    let found = false;
                    workflowArray.map((o) => {

                        if (o.Id === arraySplit[arraySplit.length - 1]) {

                            found = true;
                        }

                    });

                    if (!found) {

                        if (arraySplit.length > 3) {

                            workflowArray.push({
                                "Id": arraySplit[arraySplit.length - 1],
                                "Category": arraySplit[arraySplit.length - 2]
                            });
                        } else {
                            workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                        }
                    }

                }

            });

            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })

            this.setState({commonWorkflows: workflowArray});


        }


    }

    componentDidMount() {
        this.getAllCommonWorkflows();
        if (this.props.value) {
            this.setState({edit: true});
            if (this.props.value.dates && this.props.value.dates.length > 0) {

                let dates = this.props.value.dates;
                let convertedDates = [];
                dates.map((date, i) => {
                    if (date && date.split) {
                        let dateSplit = date.split("/");
                        if (dateSplit.length > 0) {
                            let year = moment(new Date()).format("YYYY")
                            let dateString = year + "-" + dateSplit[0] + "-" + dateSplit[1]+ " " + "00:01";
                            console.log(new Date(dateString));
                            convertedDates.push(new Date(dateString));
                        }
                    }

                });

                this.setState({dates: convertedDates});
            }


            if (this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent") {
                if (this.props.value.onMatch.type === "workflow.goNext") {
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({setFlow: {"Id": this.props.value.onMatch.type}});
                }

            } else {
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if (this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent") {

                if (this.props.value.onFailure.type === "workflow.goNext") {
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});
                }


            } else {
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }

        }


        if (this.props.optionAction) {

            this.setState({optionAction: this.props.optionAction});
        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({edit: true});






            if (this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent") {
                this.setState({setFlow: {"Id": this.props.value.onMatch.type}});

            } else {
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if (this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent") {
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});

            } else {
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }


        }

    }


    pass() {

        let dates = this.state.dates;


        let formattedDates = [];
        dates.map((date) => {
            formattedDates.push(moment(date).format("MM/DD"));
        })


        dates = formattedDates;

        let onMatch = {};
        let onFailure = {};


        if (this.state.setFlow.Id === "Workflow.GoNext") {

            onMatch = {"type": "workflow.goNext"};

        } else {

            onMatch = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.setFlow.Id,
                "replace": true,
                "account": this.props.account
            };
            if (this.state.setFlow.Id.indexOf("workflow.goNext") > -1) {
                delete onMatch.replace;
            }
        }

        if (this.state.notSetFlow.Id === "Workflow.GoNext") {

            onFailure = {"type": "workflow.goNext"};

        } else {

            onFailure = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.notSetFlow.Id,
                "replace": true,
                "account": this.props.account
            };

            if (this.state.notSetFlow.Id.indexOf("workflow.goNext") > -1) {
                delete onFailure.replace;
            }
        }


        let workflow = {
            type: "case.executeOnDate", "dates": dates, "onMatch":
            onMatch,
            "onFailure": onFailure
        };


        this.props.passData("case.executeOnDate", workflow);


    }

    openWorkflow = (event, flow) => {


        this.props.openWorkflow(flow.Id);

    }

    changeSetFlow(event, value) {

        if (value && value.Id) {
            this.setState({setFlow: {"Id": value.Id}});
        }

    }

    changeNotSetFlow(event, value) {
        if (value && value.Id) {
            this.setState({notSetFlow: {"Id": value.Id}});
        }

    }


    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    < FormControl style={{borderColor: "black"}}
                                  className={classes.formControl}
                                  style={{
                                      minWidth: "200px",
                                      minHeight: "100px",
                                      paddingLeft: "0px",
                                      paddingRight: "20px",
                                      marginTop: "0px",
                                      marginBottom: "5px",
                                      padding: "0px"
                                  }}>
                        <DatePicker
                            disableToolbar
                            autoOk
                            ampm={false}
                            inputVariant="outlined"
                            variant="inline"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date"
                            style={{}}
                            value={this.state.date}
                            onChange={this.onChangeDate}

                        />
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.addDate}>Add Date</Button>
                    </FormControl>
                </MuiPickersUtilsProvider>


                {this.state.dates.length > 0 ?

                    <TableContainer style={{width: "250px", overflow: "hidden", marginBottom: "50px", marginTop: "10px", marginRight: "0px"}} component={Paper}>
                        <Table className={classes.table} aria-label=" ">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date [Month/Day]</TableCell>
                                    <TableCell align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.renderDates()}
                            </TableBody>
                        </Table>
                    </TableContainer> : ""


                }


                <Autocomplete
                    id="combo-box5"
                    options={this.state.commonWorkflows}
                    onChange={this.changeSetFlow}
                    value={this.state.setFlow}
                    getOptionLabel={option => option.Id}
                    style={{width: "100%", marginTop: "40px"}}
                    renderInput={params => <TextField {...params} variant="outlined" label="True"
                                                      style={{minHeight: "100px"}}/>}
                />
                {this.state.edit && this.state.setFlow && this.state.setFlow.Id !== "Workflow.GoNext" ?
                    <Button style={{width: "100%", marginBottom: "10px", marginTop: "-20px"}} variant="contained"
                            color="primary" onClick={event => this.openWorkflow(event, this.state.setFlow)}>Open
                        Workflow</Button> : ""}


                <Autocomplete
                    id="combo-box4"
                    options={this.state.commonWorkflows}
                    onChange={this.changeNotSetFlow}
                    value={this.state.notSetFlow}
                    getOptionLabel={option => option.Id}
                    style={{width: "100%", marginTop: "10px"}}
                    renderInput={params => <TextField {...params} variant="outlined" label="False"
                                                      style={{minHeight: "100px"}}/>}
                />
                {this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ?
                    <Button style={{width: "100%", marginTop: "-20px"}} variant="contained"
                            color="primary" onClick={event => this.openWorkflow(event, this.state.notSetFlow)}>Open
                        Workflow</Button> : ""}


                {!this.props.option ?
                    <div style={{marginTop: "30px"}}>
                        {this.props.value && this.props.value.time ?
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Update</Button> :
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}
            </div>


        )
    }

}

export default withStyles(styles)(ExecuteOnDate);
