import React, {useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";


const VoiceUpdateCallDetails = (props) => {


    const [contextVariable, setContextVariable] = useState(null);
    const [contextVariables, setContextVariables] = useState([]);
    useEffect(() => {


        getContext();

    }, []);
    //


    useEffect(() => {
        if (props.value) {


            setContextVariable(props.value.contactReasonContext);

        }
    }, [props.value])

    const pass = (e) => {
        let workflow = {
            "type": "voice.updateCallDetails",
            "contactReasonContext": contextVariable
        };

        props.passData("voice.updateCallDetails", workflow);

    }

    const getContext = async () => {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {
                setContextVariables(Object.keys(data));
            }
        }
    }

    const handleContextChange = (event, value) => {

        setContextVariable(value);
    }
    return (

        <div style={{display: "flex", flexDirection: "column", marginTop: "20px", justifyContent: "space-around"}}>

            <Autocomplete
                options={contextVariables}
                getOptionSelected={(option, value) => option === value}
                onChange={handleContextChange}
                value={contextVariable || null}
                getOptionLabel={option => option}
                style={{width: "100%"}}
                renderInput={params => <TextField {...params} label="Context where information is saved" variant="outlined"/>}
            />



            <Button
                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "20px"}}
                variant="contained" color="primary"
                onClick={pass}>Update</Button>
        </div>
    );
};
export default VoiceUpdateCallDetails;