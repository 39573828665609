import React, {Component} from "react";
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import {ListItem, ListItemText} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(4),
        minWidth: 120
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'
    },
    listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },

});

class Entities extends Component {

    constructor() {
        super();
        this.state = {
            items: [],
            allTrainingData: [],
            entities: [],
            selected: 0,
            selectedItems: [],
            selectedEntity: "",
            entitySynonyms: new Map(),
            entityLookups: new Map(),
            pipeline: "en",
            tabValue: 0,
            lookupValue: "",
            synValue: "",
            modelValue: null,
            showSelect: false,
            selectedIndexes: [],
            savedModels: []
        }
        this.getSettings = this.getSettings.bind(this);

        this.getSavedModels = this.getSavedModels.bind(this);
        this.onChangeTraining = this.onChangeTraining.bind(this);
        this.loadTrainingData = this.loadTrainingData.bind(this);
        this.listRow = this.listRow.bind(this);
        this.reactOnClick = this.reactOnClick.bind(this);
        this.handleSynValue = this.handleSynValue.bind(this);
        this.handleLookupValue = this.handleLookupValue.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.keyPressLookup = this.keyPressLookup.bind(this);
        this.addEntity = this.addEntity.bind(this);
        this.listRowValues = this.listRowValues.bind(this);
        this.updateTrainingData = this.updateTrainingData.bind(this)
        this.updateDataModelInDb = this.updateDataModelInDb.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.listRowLookup = this.listRowLookup.bind(this);
        this.updateLookupTrainingData = this.updateLookupTrainingData.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.selectRows = this.selectRows.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.sortObject = this.sortObject.bind(this);

    }

    componentDidMount() {
        //this.getSettings();
        this.getSavedModels();
    }

    onChangeTraining = (event, index, type) => {

        if(type === "syn"){
            let entitySynonyms = this.state.entitySynonyms;
            entitySynonyms.get(this.state.selectedEntity)[index] = event.target.value
            this.setState({entitySynonyms: entitySynonyms});
        }else if(type === "lookup"){

            let entityLookups = this.state.entityLookups;
            entityLookups.get(this.state.selectedEntity)[index] = event.target.value
            this.setState({entityLookups: entityLookups}, );
        }


    }

    deleteRows = (event, type) => {

        let rasaData = this.state.allTrainingData;


        let indexesToRemove = this.state.selectedIndexes.sort(function (a, b) {
            return b - a;
        });

        if (type === "lookup") {
            let entityObject2 = rasaData.rasa_nlu_data.lookup_object[this.state.selectedEntity];
            indexesToRemove.map((ind) => {

                entityObject2.splice(ind, 1);
            });
            rasaData.rasa_nlu_data.lookup_object[this.state.selectedEntity] = entityObject2;

        } else if (type === "syn") {
            let entityObject = rasaData.rasa_nlu_data.entity_object[this.state.selectedEntity];
            indexesToRemove.map((index) => {
                entityObject.splice(index, 1);
            });

            rasaData.rasa_nlu_data.entity_object[this.state.selectedEntity] = entityObject;

        }


        this.setState({allTrainingData: rasaData});

        this.updateDataModelInDb(rasaData);
//        this.loadTrainingData(this.state.model);

        this.setState({showSelect: false});


    }

    selectRows() {
        this.setState({showSelect: this.state.showSelect ? false : true})

        if (!this.state.showSelect) {

            this.setState({selectedIndexes: [], selectedItems: [], indexesToRemove: []});
        }
    }

    onSelect = (e, i) => {

        let selected = this.state.selectedItems;
        if (e.target.checked) {
            selected[i] = true;
        } else {
            selected[i] = false;
        }
        let selectedIndexes = this.state.selectedIndexes;
        selectedIndexes.push(i);
        this.setState({selectedItems: selected, selectedIndexes: selectedIndexes});
    }
    handleTabChange = (event, value) => {


        this.setState({tabValue: value});

    }

    async updateDataModelInDb(data) {
        let model = this.state.model;


        let url = '/api/updateModel?id=' + model + "&account=" + this.props.account;
        let rasaData = this.state.allTrainingData;
        if (data) {
            rasaData = data;

        }

        if (rasaData && rasaData.rasa_nlu_data && rasaData.rasa_nlu_data.common_examples.length > 0) {

            if (rasaData && rasaData.rasa_nlu_data) {

                const response = await fetch(url, {
                    method: 'post',
                    mode: 'cors',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(this.state.allTrainingData)
                });

                const data = await response.json();

                if (data.length === 0) {
                    this.loadTrainingData(this.state.model);
                    this.setState({notifierOpen: true});

                }
            }
        }
    }


    listRowValues() {

        if (this.state.entitySynonyms && this.state.entitySynonyms.get(this.state.selectedEntity)) {
            return this.state.entitySynonyms.get(this.state.selectedEntity).map((object, i) => {
                return <div key={i} style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "5px"
                }}>
                    <div style={{
                        width: "100%",
                        marginTop: "10px",
                        minWidth: "800px",
                        display: "flex",
                        flexDirection: "row"
                    }}>

                        {this.state.showSelect ? <div style={{
                            display: "inline-block",
                            width: "6%",
                            top: "50%",
                            textAlign: "center",
                            verticalAlign: "bottom"
                        }}><Checkbox style={{marginTop: "-7px", marginRight: "10px"}}
                                     checked={this.state.selectedItems[i] ? this.state.selectedItems[i] : false}
                                     id={"td" + i.toString()}
                                     onChange={(event) => this.onSelect(event, i)}/>
                        </div> : <div></div>}
                        <div style={{marginTop: "6px", marginleft: "10px", marginRight: "10px"}}>
                            {i + 1}.
                        </div>
                        <TextField variant="standard"
                                   id={i.toString()}
                                   style={{width: "100%", height: '30px'}}
                                   InputProps={{
                                       classes: {
                                           input: this.props.classes.textField,
                                       },
                                   }} type="text" onBlur={this.updateTrainingData} onChange={(event) => this.onChangeTraining(event,i, "syn")} value={object}
                        />
                    </div>


                </div>
            });
        }
    }

    listRowLookup() {

        if (this.state.entityLookups && this.state.entityLookups.get(this.state.selectedEntity)) {
            return this.state.entityLookups.get(this.state.selectedEntity).map((object, i) => {
                return <div key={i} style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "5px"
                }}>
                    <div style={{
                        width: "100%",
                        marginTop: "10px",
                        minWidth: "800px",
                        display: "flex",
                        flexDirection: "row"
                    }}>

                        {this.state.showSelect ? <div style={{
                            display: "inline-block",
                            width: "6%",
                            top: "50%",
                            textAlign: "center",
                            verticalAlign: "bottom"
                        }}><Checkbox style={{marginTop: "-7px", marginRight: "10px"}}
                                     checked={this.state.selectedItems[i] ? this.state.selectedItems[i] : false}
                                     id={"td" + i.toString()}
                                     onChange={(event) => this.onSelect(event, i)}/>
                        </div> : <div></div>}
                        <div style={{marginTop: "6px", marginleft: "10px", marginRight: "10px"}}>
                            {i + 1}.
                        </div>
                        <TextField variant="standard"
                                   id={i.toString()}
                                   style={{width: "100%", height: '30px'}}
                                   InputProps={{
                                       classes: {
                                           input: this.props.classes.textField,
                                       },
                                   }} type="text" onBlur={this.updateLookupTrainingData} onChange={(event) => this.onChangeTraining(event,i, "lookup")} value={object}
                        />
                    </div>


                </div>
            });
        }
    }

    keyPress(e) {
        if (e.key === 'Enter') {
            this.addEntity();
        }

    }

    keyPressLookup(e) {
        if (e.key === 'Enter') {
            this.addLookup();
        }

    }

    addEntity() {

        let map = this.state.entitySynonyms;
        let currentMap = this.state.entitySynonyms.get(this.state.selectedEntity);
        console.log(currentMap)
        let synValue = this.state.synValue.trim();
        if (currentMap) {

            currentMap.push(synValue);
            map.set(this.state.selectedEntity, currentMap);

        } else if (this.state.selectedEntity && this.state.selectedEntity !== "") {

            map.set(this.state.selectedEntity, [synValue]);
        }

        this.setState({entitySynonyms: map, synValue: ""}, () => {
            this.updateTrainingData();

        });

    }

    addLookup() {

        let map = this.state.entityLookups;
        let currentMap = this.state.entityLookups.get(this.state.selectedEntity);

        let lookupValue = this.state.lookupValue.trim();
        if (currentMap) {

            currentMap.push(lookupValue);
            map.set(this.state.selectedEntity, currentMap);

        } else if (this.state.selectedEntity && this.state.selectedEntity !== "") {

            map.set(this.state.selectedEntity, [lookupValue]);
        }

        this.setState({entityLookups: map, lookupValue: ""}, () => {
            this.updateLookupTrainingData();


        });

    }

    handleSynValue(event) {

        this.setState({synValue: event.target.value});

    }


    handleLookupValue(event) {
        this.setState({lookupValue: event.target.value});
    }


    reactOnClick = (event, i) => {


        this.setState({selected: i, selectedEntity: event.target.innerText});

    }

    listRow() {

        if (this.state.entities && this.state.entities.length > 0) {

            return this.state.entities.map((object, i) => {
                return <div key={i} style={{width: "100%", maxWidth: "328px", paddingRight: "0px", marginRight: "0px"}}>

                    <ListItem autoFocus={this.state.selected === i}
                              onClick={event => this.reactOnClick(event, i)}
                              classes={{root: this.props.classes.listItemTextSelected}} key={i}
                              selected={this.state.selected === i}
                              button>
                        <ListItemText id={i}
                                      classes={{primary: this.props.classes.listItemText}}
                                      primary={object}/>
                    </ListItem>

                </div>;
                ;
            });

        } else if (this.state.entities && this.state.entities.length === 0) {

            return <ListItem autoFocus={this.state.selected === 0}
                             onClick={event => this.reactOnClick(event, 0)}
                             classes={{root: this.props.classes.listItemTextSelected}} key={0}
                             selected={this.state.selected === 0}
                             button>
                <ListItemText id={0}
                              classes={{primary: this.props.classes.listItemText}}
                              primary={"No Entities Defined"}/>
            </ListItem>;


        }

    }

    async loadTrainingData(model, keyValue) {

        let url = '/api/trainingDataJson?model=' + model;

        this.setState({model: model});
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );


        if (response.status === 302) {


        }
        let data = await response.json();

        this.setState({allTrainingData: data});


        let enMap = this.state.entitySynonyms;
        let lookupMap = this.state.entityLookups;
        if (data && data.rasa_nlu_data) {

            let arrayToSave = data.rasa_nlu_data.common_examples;
            let entitiesToSave = data.rasa_nlu_data.entity_synonyms;
            entitiesToSave.sort();
            let entityObj = data.rasa_nlu_data.entity_object;
            let lookupObj = data.rasa_nlu_data.lookup_object;

            if (entityObj) {
                let keys = Object.keys(entityObj);
                keys.map((key) => {

                    enMap.set(key, entityObj[key]);

                });


            }


            if (lookupObj) {
                let keys = Object.keys(lookupObj);
                keys.map((key) => {

                    lookupMap.set(key, lookupObj[key]);

                });

            }
            arrayToSave.map((arr) => {

                arr.model = model;

            });

            this.setState({
                entities: [...entitiesToSave],
                entitySynonyms: enMap,
                entityLookups: lookupMap
            }, () => {
                //here
                if (!this.state.firstLoad) {
                    this.setState({firstLoad: true});
                    this.setState({selected: 0, selectedEntity: entitiesToSave[0]});
                }
            });
        }
    }


    updateLookupTrainingData() {
        let lookupObj = {};
        for (const entityArray of this.state.entityLookups.keys()) {
            let array = this.state.entityLookups.get(entityArray);
            lookupObj[entityArray] = [];
            array.map((value) => {
                lookupObj[entityArray].push(value);
            });
        }
        let rasaData = this.state.allTrainingData;
        rasaData.rasa_nlu_data.lookup_object = lookupObj;

        this.setState({lookupObj: lookupObj}, () => {
            this.updateDataModelInDb()
        });

    }

    updateTrainingData() {
        let synObj = {};
        for (const entityArray of this.state.entitySynonyms.keys()) {
            let array = this.state.entitySynonyms.get(entityArray);
            synObj[entityArray] = [];
            array.map((value) => {
                synObj[entityArray].push(value);
            });
        }

        let rasaData = this.state.allTrainingData;


        rasaData.rasa_nlu_data.entity_object = synObj;

        this.setState({synObj: synObj}, () => {
            this.updateDataModelInDb()
        });

    }

    sortObject = function (data) {

        let sorted = Object.fromEntries(Object.keys(data).sort());
        return sorted;
    }

    async getSettings() {

        const url = '/api/getSettings';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            const sett = data[0].Value;
            const settings = JSON.parse(sett);

            if (settings.newTraining) {
                this.setState({newTraining: true});
            }

            if (settings.TrainingEpochs) {
                this.setState({epochs: settings.TrainingEpochs});
            }
        }
    }


    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        this.setState({savedModels: data});

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            let modelToCompare = false;


            if (this.props.model) {
                modelToCompare = this.props.model;
            }

            if (localStorage.getItem("model")) {
                modelToCompare = localStorage.getItem("model");
            }

            data.map((d, i) => {

                if (modelToCompare && modelToCompare === d.Model) {
                    first = d;
                } else if (i === 0) {

                    first = d;
                }

            });

            this.setState({model: first.Model, modelValue: {"Model": first.Model}}, () => {
                this.loadTrainingData(first.Model)
            });
        }
    }

    changeModel(event, value) {

        if (value && value.Model) {

            localStorage.setItem("model", value.Model);
            this.setState({modelValue: {"Model": value.Model}});
            this.setState({model: value.Model}, () => {
                this.loadTrainingData(value.Model)
            });
            //reset TrainingData
            this.setState({trainingData: []});

        }

    }


    render() {
        const {classes} = this.props;
        const {value} = this.state;

        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <Paper style={{padding: "20px"}}>
                    <div style={{display: "flex", flexDirection: "row", padding: "20px", paddingLeft: "0px"}}>
                        <div>
                            <Autocomplete
                                id="modelBox"
                                options={this.state.savedModels}
                                onChange={this.changeModel}
                                value={this.state.modelValue || null}
                                getOptionLabel={option => option.Model}
                                getOptionSelected={(option, value) => option.value === value.value}
                                style={{width: "200px", minWidth: "150px"}}
                                renderInput={params => <TextField {...params}
                                                                  variant="outlined"
                                                                  label="Model"
                                                                  style={{}}/>}/>
                        </div>

                        < Tabs value={this.state.tabValue} style={{marginTop: "0px", marginLeft: "40px"}}
                               onChange={this.handleTabChange}>
                            <Tab label="Synonyms"/>
                            <Tab label="Lookup Tables"/>
                        </Tabs>
                    </div>

                    {this.state.tabValue === 0 &&
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Paper>
                                <div style={{padding: "20px"}}>
                                    <List
                                        style={{

                                            width: "100%",
                                            maxWidth: "330px",
                                            marginTop: "10px",
                                            minWidth: "150px",
                                            minHeight: "calc(100vh - 220px)",
                                            maxHeight: "calc(100vh - 220px)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            border: "1px solid #ccc",
                                            paddingTop: "0px"
                                        }}
                                        component="nav"
                                        className={classes.root}
                                        id='thing'
                                    >   
                                        {this.listRow()}
                                    </List>
                                </div>
                            </Paper>
                            <Paper style={{marginLeft: "20px"}}>

                                <div style={{width: '100%', padding: "20px"}}>
                                    <div style={{marginBottom: "10px", display: "flex", flexDirection: "row"}}>

                                        <Fab color="secondary" onClick={this.selectRows} aria-label="Check"
                                             className={classes.fab} size="small"><CheckIcon/></Fab>

                                        {this.state.showSelect &&
                                            <div style={{marginLeft: "20px"}}><Fab color="secondary"
                                                                                   onClick={(event) => this.deleteRows(event, "syn")}
                                                                                   aria-label="Delete"
                                                                                   className={classes.fab} size="small"><DeleteIcon/></Fab>
                                            </div>}
                                    </div>


                                    <TextField inputProps={{autoFocus: true}} inputRef={this.myRef}
                                               onKeyDown={this.keyPress}
                                               style={{width: "100%", minWidth: "400px", marginBottom: "20px"}}
                                               onChange={this.handleSynValue}
                                               label="Add Synonym To Entity" value={this.state.synValue}/>
                                    <div id="synonymList"
                                         style={{
                                             marginTop: '00px',
                                             paddingLeft: "0px",
                                             overflowY: 'auto',
                                             maxHeight: "calc(100vh - 260px)",
                                             width: "100%"
                                         }}>
                                        {this.listRowValues()}
                                    </div>
                                </div>
                            </Paper>
                        </div>}


                    {this.state.tabValue === 1 &&
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Paper>
                                <div style={{padding: "20px"}}>
                                    <List
                                        style={{
                                            width: "100%",
                                            maxWidth: "330px",
                                            marginTop: "10px",
                                            minWidth: "150px",
                                            minHeight: "calc(100vh - 220px)",
                                            maxHeight: "calc(100vh - 220px)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            border: "1px solid #ccc"
                                        }}
                                        component="nav"
                                        className={classes.root}
                                        id='thing'
                                    >
                                        {this.listRow()}
                                    </List>
                                </div>
                            </Paper>
                            <Paper style={{marginLeft: "20px"}}>
                                <div style={{width: '100%', padding: "20px"}}>
                                    <div style={{marginBottom: "10px", display: "flex", flexDirection: "row"}}>

                                        <Fab color="secondary" onClick={this.selectRows} aria-label="Check"
                                             className={classes.fab} size="small"><CheckIcon/></Fab>

                                        {this.state.showSelect &&
                                            <div style={{marginLeft: "20px"}}><Fab color="secondary"
                                                                                   onClick={(event) => this.deleteRows(event, "lookup")}
                                                                                   aria-label="Delete"
                                                                                   className={classes.fab} size="small"><DeleteIcon/></Fab>
                                            </div>}
                                    </div>

                                    <TextField inputProps={{autoFocus: true}} inputRef={this.myRef}
                                               onKeyDown={this.keyPressLookup}
                                               style={{width: "100%", minWidth: "400px", marginBottom: "20px"}}
                                               onChange={this.handleLookupValue}
                                               label="Add Lookup To Entity" value={this.state.lookupValue}/>
                                    <div id="synonymList"
                                         style={{
                                             marginTop: '00px',
                                             paddingLeft: "0px",
                                             overflowY: 'auto',
                                             maxHeight: "calc(100vh - 260px)",
                                             width: "100%"
                                         }}>
                                        {this.listRowLookup()}
                                    </div>
                                </div>
                            </Paper>
                        </div>}
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(Entities);
