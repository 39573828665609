import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import FormControl from "@material-ui/core/FormControl";


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        fontSize: "14   px"
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class CustomAction extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: ""


        }

    }

    componentDidMount() {
        if (this.props.value) {



            let pretty = JSON.stringify(this.props.value, undefined, 4);

            this.setState({textFieldValue: pretty});
        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {
            let pretty = JSON.stringify(this.props.value, undefined, 4);
            //console.log(this.props.value);
            this.setState({textFieldValue: pretty});


        }

    }

    pass() {

        let workflow =  this.state.textFieldValue;

        this.props.passData("custom.action", JSON.parse(workflow));

    }


    handleChange(e) {

        this.setState({textFieldValue: e.target.value});

        if (this.props.option) {

            let val = "";
            if (e.target.value === "") {
                val = "";

            } else {
                val = e.target.value;

            }
            let workflow = {type: "custom.action", "message": val};
            this.props.onChange(workflow);


        }
    }

    render() {
        const {classes} = this.props;
        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                <TextField
                    value={this.state.textFieldValue}
                    onChange={this.handleChange}
                    margin="normal"
                    multiline={true}
                    minRows={15}
                    InputProps={{
                        classes: {
                            input: classes.textField,
                        },
                    }}
                    variant="outlined"
                    label={this.props.label}
                />
                {!this.props.option ?
                    <div style={{marginTop: "30px"}}>
                        {this.props.value && this.props.value.message ?
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                    onClick={this.pass}>Update</Button> :
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained" color="primary"
                                    onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}
            </div>


        )
    }

}

export default withStyles(styles)(CustomAction);
