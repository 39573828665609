import React, {Component} from "react";
import {ListItem, ListItemText, Select, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import Button from "@material-ui/core/Button/Button";
import ConversationMessage from "./ConversationMessage";
import ResponseOnIntent from "./ResponseOnIntent";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import arrayMove from 'array-move';
import {compose} from "redux";
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from "@material-ui/core/FormControl";
import CheckGroupStatusAndTransfer from "./CheckGroupStatusAndTransfer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import SetContextValue from "./SetContextValue";

const style = {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "20px"
}


const SortableItem = sortableElement(({value}) => <div style={{
    padding: "10px",
    marginTop: "0px",
    color: "#fff",
    backgroundColor: "#0091E9",
    fontSize: "14px",
    borderRadius: "5px",
    borderBottom: "1px solid #ccc",
    zIndex: 9999999,
    cursor: "pointer"
}} variant="contained" color="secondary">
    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><DragHandleIcon/>
        <div style={{marginLeft: "10px"}}>{value}</div>
    </div>
</div>);



const SortableContainer = sortableContainer(({children}) => {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#DDD",
        padding: "0px",
        marginTop: "20px",
        marginBottom: "20px",
        borderTop: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc"
    }}>{children}</div>;
});


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {
        fontSize: '15px',
        paddingLeft: '10px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


const data = [
    {
        "name": "Saved Workflow",
        "manifest": {
            "type": "vergicAi.responseOnIntent",
            "model": "MODEL",
            "pipeline": "PIPELINE",
            "initiateAiWithResponse": " ",
            "account": "account"
        }
    },
    {"name": "Set Context", "manifest":   {
            "type": "case.setContextValue",
            "key": " ",
            "value": " "
        }},
    {"name": "Go Next", "manifest": {"type": "workflow.goNext"}}


//{"name": "External Workflow", "manifest": {"type": "workflow.includeFlow", "flow": "FLOWID"}},
];


class OptionsAction extends Component {


    constructor(props) {

        super(props);
        this.listRow = this.listRow.bind(this);
        this.addOption = this.addOption.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.saveWorkflow = this.saveWorkflow.bind(this);
        this.listActions = this.listActions.bind(this);
        this.renderWorkflowForm = this.renderWorkflowForm.bind(this);
        this.removeOption = this.removeOption.bind(this);
        this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
        this.handleFlowIdChange = this.handleFlowIdChange.bind(this);
        this.handleResponseChange = this.handleResponseChange.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);
        this.handleIconHeightChange = this.handleIconHeightChange.bind(this);
        this.handleIconWidthChange = this.handleIconWidthChange.bind(this);
        this.handleRemoveOption = this.handleRemoveOption.bind(this);
        this.handleRenderPreview = this.handleRenderPreview.bind(this);
        this.handleClosureChange = this.handleClosureChange.bind(this);
        this.onSort = this.onSort.bind(this);
        this.listOptions = this.listOptions.bind(this);
        this.updateOptionsArray = this.updateOptionsArray.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleShowSort = this.handleShowSort.bind(this);
        this.scrollOption = this.scrollOption.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleShowType = this.handleShowType.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.toggleIcon = this.toggleIcon.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleBusyChange = this.handleBusyChange.bind(this);
        this.handleClosedChange = this.handleClosedChange.bind(this);
        this.handleChooseDefault = this.handleChooseDefault.bind(this);
        this.settingsChange = this.settingsChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.defaultTimeChange = this.defaultTimeChange.bind(this);
        this.handleDefaultOptionChange = this.handleDefaultOptionChange.bind(this);
this.handleSetContextChange = this.handleSetContextChange.bind(this);
        this.state = {
            filter: '',
            workFlowRender: [],
            textFieldValue: '',
            data: [{"text": "", "icon": "", "iwidth": "", "iheight": "", "accepts": [], "onMatch": []}],
            count: 0,
            website: "",
            model: "",
            pipeline: "",
            selected: [],
            newOption: false,
            renderPreview: true,
            closureMessage: "",
            items: [],
            showSort: false,
            defaultOption: "",
            typeSelect: "options",
            showType: false,
            showIcon: false,
            groups: [],
            group: {id: "", name: ""},
            checked: false,
            defaultTime: ""

        }

        this.listRef = React.createRef();
        this.actionsRef = React.createRef();

    }


    handleDefaultOptionChange(event){

        this.setState({defaultOption: event.target.value});
    }
    defaultTimeChange(event){

        this.setState({defaultTime: event.target.value});
    }
    renderOptions(){

        if(this.state.data[0] && !this.state.data[0].text){
            return <MenuItem  value={"No Options Defined"}>No Options Defined</MenuItem>

        }else{
            return this.state.data.map((option,i)=>{

                return <MenuItem key={i} value={option.text}>{option.text}</MenuItem>
            })

        }



    }

    handleChooseDefault(event){

        this.setState({chooseDefault: event.target.checked});

    }
    settingsChange = (key, flow) => {


        this.setState({flow: flow});
        let newData = this.state.data;

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            this.setState({data: newData}, this.setState({edit: true}));
            this.saveWorkflow();
        }


    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);

        if (response.status !== 500) {
            const data = await response.json();

            if (data && data.length > 0 && data.name !== 'error') {

                data.map((site) => {

                    this.getGroups(site);

                });

            }
        }
    }


    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            this.setState({groups: groups});

        }

    }

    openWorkflow = (value) => {

        if (this.props.workflowCreate) {

            this.props.passWorkflowToOpen(value);
        } else {
            this.props.openWorkflow(value);
        }


    }

    handleBusyChange = (key, event) => {


        this.setState({busyFlow: event});

        let newData = this.state.data;

        if (newData && newData[0].onMatch) {

            let currentOnMatch = newData[key].onMatch;

            if (!currentOnMatch.transferFlows) {

                currentOnMatch.transferFlows = {};
            }
            currentOnMatch.transferFlows.busy = event;


            this.setState({data: newData});


        }
    }

    handleClosedChange = (key, event) => {


        this.setState({closedFlow: event});

        let newData = this.state.data;

        if (newData && newData[0].onMatch) {

            let currentOnMatch = newData[key].onMatch;

            if (!currentOnMatch.transferFlows) {

                currentOnMatch.transferFlows = {};
            }
            currentOnMatch.transferFlows.closed = event;

            //console.log(newData)
            this.setState({data: newData});

        }

    }

    handleGroupChange = (key, event) => {

        let found = -1;
        this.state.groups.map((o, i) => {
            if (o.id === event.id) {
                found = i;
            }

        });

        if (found > -1) {

            this.setState({group: this.state.groups[found]})

            let groupId = this.state.groups[found].id;

            this.setState({groupId: groupId});

            let flowValue = {"type": "case.checkGroupStatusAndTransfer", "groupId": groupId};

            if (this.state.busyFlow) {

                flowValue.transferFlows = {};
                flowValue.transferFlows.busy = this.state.busyFlow;

            }

            if (this.state.closedFlow) {

                flowValue.transferFlows = {};
                flowValue.transferFlows.closed = this.state.closedFlow;

            }

            let newData = this.state.data;
            if (newData && newData[0].onMatch) {

                newData[key].onMatch = flowValue;


                this.setState({data: newData});

            }
        }

        /*if(value && value.name){
            this.setState({group: value, groupId: value.id});

        }*/

    }


    handleShowSort(e) {
        this.setState({showSort: true});
        this.listOptions();

    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }), this.updateOptionsArray);

    };

    updateOptionsArray() {
        let newData = [];
        let items = this.state.items;
        let data = this.state.data;

        items.map((object) => {

            data.map((obj) => {

                if (obj.text === object) {

                    newData.push(obj);
                }
            })
        });
        //   console.log(newData)
        this.setState({data: newData});
    }

    handleOpenWorkFlow = (value) => {

        this.props.passWorkflowToOpen(value);

    }
    handleRemoveOption = (event, i) => {

//        console.log(this.state.data[i])
        let arr = this.state.data;
        let removed = arr.splice(i, 1);
        this.setState({data: arr}, this.saveWorkflow);

        //  console.log( i);
        //this.props.passWorkflowToOpen(value);

    }

    componentDidMount() {

        if (this.props.data) {

            this.setState({edit: true});
            this.setState({data: this.props.data}, this.listOptions());

        }


        if (this.props.optionsType) {

            this.setState({typeSelect: this.props.optionsType});
        }

        if (this.props.chooseDefaultAfterSec) {


            this.setState({defaultTime: this.props.chooseDefaultAfterSec, chooseDefault: true});
        }

        if (this.props.defaultOption) {

            this.setState({defaultOption: this.props.defaultOption, chooseDefault: true});
        }

        if (this.props.disableInputField) {

            this.setState({checked: this.props.disableInputField})

        }

        if (this.props.model && this.props.pipeline) {

            this.setState({model: this.props.model});
            this.setState({pipeline: this.props.pipeline});

        }

        if (this.props.forwardParams) {

            this.setState({aiModel: this.props.forwardParams.model});
            this.setState({aiPipeline: this.props.forwardParams.pipeline});

        }
        this.getTokenUser();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (JSON.stringify(prevState.listOptions) !== JSON.stringify(this.state.listOptions)) {

            // console.log(this.state.listOptions);
        }

        if (prevProps.optionsType !== this.props.optionsType) {
            // console.log(this.props.optionsType)
            this.setState({typeSelect: this.props.optionsType});
        }

        if (prevProps.disableInputField !== this.props.disableInputField) {
            // console.log(this.props.optionsType)
            this.setState({checked: this.props.disableInputField});
        }

        if (JSON.stringify(prevState.items) !== JSON.stringify(this.state.items)) {

            //this.state.items);
        }


    }


    handleIconChange = (event, i) => {

        let newValue = this.state.data;

        newValue[i].icon = event.target.value;

        this.setState({data: newValue, renderPreview: false});


    }

    handleIconHeightChange = (event, i) => {
        let newValue = this.state.data;

        newValue[i].iheight = event.target.value;

        this.setState({data: newValue})


    }

    handleIconWidthChange = (event, i) => {


        let newValue = this.state.data;

        newValue[i].iwidth = event.target.value;

        this.setState({data: newValue})

    }

    handleChange = (event, i) => {

        let newValue = this.state.data;


        newValue[i].text = event.target.value;
        newValue[i].accepts[0] = event.target.value;


        this.setState({data: newValue});


    }

    handleRenderPreview(e) {

        this.setState({renderPreview: true});


    }

    handleMessageChange = (key, flow) => {
        this.setState({flow: flow});


        let newData = this.state.data;

        if (newData && newData[0].onMatch) {

            newData[key].onMatch = flow;


            this.setState({data: newData});

        }

    }


    handleWebsiteChange = (key, event) => {

        let url = event.target.value;

        if (url === "") {

            url = " ";
        }


        this.setState({website: url});

        let flowValue = {"type": "website.navigate", "url": url};

        let newData = this.state.data;
        if (newData && newData[0].onMatch) {

            newData[key].onMatch = flowValue;


            this.setState({data: newData});

        }

    }

    handleGroupIdChange = (key, event) => {

        let groupId = event.target.value;

        if (groupId === "") {

            groupId = " ";
        }


        this.setState({groupId: groupId});

        let flowValue = {"type": "case.checkGroupStatusAndTransfer", "groupId": groupId};

        let newData = this.state.data;
        if (newData && newData[0].onMatch) {

            newData[key].onMatch = flowValue;


            this.setState({data: newData});

        }

    }
    handleSetContextChange = (event, key) =>{



         var keyvalue = event.toString().split("##");
        let flowValue = {"type": "case.setContextValue", "key": keyvalue[0], "value": keyvalue[1]};


        let newData = this.state.data;
        if (newData && newData[0].onMatch) {

            newData[key].onMatch = flowValue;


            this.setState({data: newData});

        }



    }
    handleFlowIdChange = (key, event) => {


        let flowId = event.target.value;

        if (flowId === "") {

            flowId = " ";
        }


        this.setState({flow: flowId});

        let flowValue = {"type": "workflow.includeFlow", "flow": flowId};

        let newData = this.state.data;
        if (newData && newData[0].onMatch) {


            newData[key].onMatch = flowValue;


            this.setState({data: newData});

        }

    }


    handleClosureChange = (key, event) => {


        let message = event.target.value;

        if (message === "") {

            message = " ";
        }


        this.setState({closureMessage: message});

        let flowValue = {"type": "case.closeAsSuccess", "message": message};

        let newData = this.state.data;
        if (newData && newData[0].onMatch) {

            newData[key].onMatch = flowValue;


            this.setState({data: newData});

        }

    }

    handleResponseChange = (key, flow) => {


        if (flow.initiateAiWithResponse === "New Workflow") {
            this.setState({updatedPosition: key});
            this.props.newWorkflowResponseOnIntent();

        }

        this.setState({flow: flow});
        let newData = this.state.data;

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            this.setState({data: newData}, this.setState({edit: true}));

        }

        if (this.props.newNameResponseOnIntent) {

            this.saveWorkflow();

        }

    }


    listActions(key) {

        if (data) {
            return data.filter(object => {
                //remove names that doesnt match current filter text
                return object.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
            }).map((object, i) => {
                return <ListItem
                    classes={{root: this.props.classes.list}} key={i} selected={this.state.selected[key] === i}
                    button>

                    <ListItemText
                        onClick={event => this.reactOnListClick(event, i, key)} id={i}
                        classes={{primary: this.props.classes.listItemText}}
                        primary={object.name}/></ListItem>

            });
        }

    }

    reactOnListClick = (event, i, key) => {

        this.setState({selectedWorkflow: event.target.innerText});

        this.state.selected[key] = i;

        this.state.workFlowRender[key] = data[i].manifest;


        var d = this.state.data;
        d[key].onMatch = data[i].manifest;


        let curr = this.listRef.current;
        if (curr) {

            setTimeout(function () {

                curr.scrollTop = curr.scrollHeight;
            }, 300);
        }
        this.setState({showSort: false});
        //this.setState({workFlowRender: })

    }


    renderWorkflowForm = (key) => {

        if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "conversation.message") {

            return <div style={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                border: "0px solid #ccc",
                width: "100%"
            }}>
                <ConversationMessage option={true} onChange={this.handleMessageChange.bind(this, key)}
                                     passData={this.passData}
                                     id={key} label="Message"
                                     optionAction={true}/>
            </div>
        } else if (this.state.workFlowRender[key] && (this.state.workFlowRender[key].type === "case.closeAsSuccess" )) {


            return <div style={{padding: "0px", width: "100%"}}>
                <TextField
                    label="Message"
                    onChange={this.handleClosureChange.bind(this, key)}
                    margin="normal"
                    multiline={false}
                    variant="outlined"
                    style={{width: "100%"}}
                />
            </div>


        } else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "website.options") {

            return <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <OptionsAction saveWorkflow={this.saveWorkflow}/>
            </div>
        } else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "website.navigate") {

            return <div style={{padding: "0px", width: "100%"}}>
                <TextField
                    label="URL"
                    onChange={this.handleWebsiteChange.bind(this, key)}
                    margin="normal"
                    multiline={false}
                    variant="outlined"
                    style={{width: "100%"}}
                />
            </div>

        } else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "case.checkGroupStatusAndTransfer") {

            return <div style={{padding: "0px", width: "100%"}}>


                <CheckGroupStatusAndTransfer handleGroupChange={this.handleGroupChange}
                                             handleBusyChange={this.handleBusyChange}
                                             handleClosedChange={this.handleClosedChange}
                                             optionActionDisplay={true}
                                             value={this.state.data[key].onMatch} optionAction={key}/>


            </div>


        }else if(this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "case.setContextValue") {
            return <div style={{padding: "0px", width: "100%"}}>

                <SetContextValue
                    option={true}
                    value={this.state.data[key]}
                    onChange={(event) => this.handleSetContextChange(event, key)}
                    id={key}
                />
            </div>

        }else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "workflow.includeFlow") {

            return <div style={{padding: "0px", width: "100%"}}>
                <TextField
                    label="Workflow Id"
                    onChange={this.handleFlowIdChange.bind(this, key)}
                    margin="normal"
                    multiline={false}
                    variant="outlined"
                    id={key.toString()}
                    style={{width: "100%"}}
                />
            </div>

            //
        } else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "vergicAi.responseOnIntent" && key === this.state.updatedPosition) {
            return <div style={{padding: "0px", width: "100%", display: "flex", flexDirection: "column"}}>

                <ResponseOnIntent option={true} onChange={this.handleResponseChange.bind(this, key)}
                                  id={key.toString()} model={this.props.model} pipeline={this.props.pipeline}
                                  account={this.props.account}
                                  settingsChange={this.settingsChange}
                                  openWorkflow={this.openWorkflow}
                                  workflowCreate={this.props.workflowCreate}
                                  newNameResponseOnIntent={this.props.newNameResponseOnIntent}

                />


            </div>
        } else if (this.state.workFlowRender[key] && this.state.workFlowRender[key].type === "vergicAi.responseOnIntent") {

            return <div style={{padding: "0px", width: "100%", display: "flex", flexDirection: "column"}}>

                <ResponseOnIntent option={true} onChange={this.handleResponseChange.bind(this, key)}
                                  id={key.toString()} model={this.props.model} pipeline={this.props.pipeline}
                                  account={this.props.account}
                                  settingsChange={this.settingsChange}
                                  workflowCreate={this.props.workflowCreate}
                                  openWorkflow={this.openWorkflow}

                />


            </div>


        }


    };

    listOptions() {
        if (!this.state.showSort) {


            let optionList = [];
            this.state.data.map((object, i) => {


                optionList.push(object.text);

            });

            this.setState({items: optionList});
        } else {

            this.setState({showSort: false});
        }
    }

    toggleIcon(e) {

        // console.log(e.currentTarget.innerText)
        if (this.state.showIcon) {
            this.setState({showIcon: false});
        } else {
            this.setState({showIcon: true});
        }


    }

        listRow() {


        return this.state.data.map((object, i) => {

            return <div key={i} style={{display: "flex", flexDirection: "column", minWidth: "100%", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <div style={{width: "80%"}}><h3>Option: {i + 1}</h3></div>
                    <div style={{width: "20%"}}><InputAdornment onClick={event => this.handleRemoveOption(event, i)}
                                                                position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></div>
                </div>
                <TextField
                    label="Option Text"
                    style={{width: "100%"}}
                    value={object.text}
                    onChange={event => this.handleChange(event, i)}

                    margin="normal"
                    variant="outlined"
                />

                {!this.state.showIcon ? <div id="closed" style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        marginTop: "10px"
                    }} onClick={this.toggleIcon}><h4>Icon</h4> <ExpandMoreIcon/></div> :
                    <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}
                             id="open" onClick={this.toggleIcon}><h4>Icon</h4> <ExpandLessIcon/></div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <TextField
                                    label="Icon (optional)"
                                    style={{width: "100%"}}
                                    value={object.icon ? object.icon : ""}
                                    onChange={event => this.handleIconChange(event, i)}
                                    onBlur={this.handleRenderPreview}
                                    margin="normal"
                                    variant="outlined"
                                />

                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                {object.icon !== "" && this.state.renderPreview ?
                                    <div style={{marginRight: "20px"}}><img style={{maxWidth: "50px"}}
                                                                            src={object.icon}/>
                                    </div> : <div></div>}
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <TextField
                                        label="Icon W"
                                        style={{width: "100px"}}
                                        value={object.iwidth ? object.iwidth : ""}
                                        onChange={event => this.handleIconWidthChange(event, i)}

                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="Icon H"
                                        style={{width: "100px", marginLeft: "20px"}}
                                        value={object.iheight ? object.iheight : ""}
                                        onChange={event => this.handleIconHeightChange(event, i)}

                                        margin="normal"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                }

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && !this.state.edit ?

                    <div style={{
                        width: "100%",
                        wordBreak: "break-all",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px"
                    }}>
                        <h3>Response Action</h3>

                        <div style={{overflow: "auto", marginTop: "-10px"}}>
                            <List
                                style={{
                                    width: "100%",
                                    maxHeight: "200px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    overflow: "auto"
                                }}
                                component="nav">
                                {this.listActions(i)}
                            </List>

                            <div>
                                {this.renderWorkflowForm(i)}
                            </div>


                        </div>
                    </div> : <div></div>
                }


                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.type === "workflow.goNext") && this.state.edit && !this.state.newOption ?
                    <div>

                        <TextField
                            label="Action"
                            value="workflow.goNext"
                            margin="normal"
                            disabled={true}
                            multiline={false}
                            variant="outlined"
                            style={{width: "100%"}}
                        />

                    </div> : <div></div>}


                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.type === "case.closeAsSuccess") && this.state.edit && !this.state.newOption ?
                    <div>

                        <TextField
                            label="Closure Message"
                            value={this.state.closureMessage !== "" ? this.state.closureMessage : this.state.data[i].onMatch.message}
                            onChange={this.handleClosureChange.bind(this, i)}
                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            style={{width: "100%"}}
                        />

                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.message || this.state.data[i].onMatch.message === "") && this.state.data[i].onMatch.type !== "case.closeAsSuccess" && this.state.edit && !this.state.newOption ?
                    <div><ConversationMessage option={true} label="Message"
                                              onChange={this.handleMessageChange.bind(this, i)} id={i}
                                              value={this.state.data[i].onMatch}
                                              optionAction={true}/></div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.url || this.state.data[i].onMatch.url === "") && this.state.edit ?
                    <div>

                        <TextField
                            label="URL"
                            value={this.state.data[i].onMatch.url}
                            onChange={this.handleWebsiteChange.bind(this, i)}

                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            style={{width: "100%"}}
                        />

                    </div> : <div></div>
                }

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.groupId || this.state.data[i].onMatch.groupId === "") && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>

                        <CheckGroupStatusAndTransfer
                            handleGroupChange={this.handleGroupChange}
                            handleBusyChange={this.handleBusyChange}
                            handleClosedChange={this.handleClosedChange}
                            optionActionDisplay={true}
                            value={this.state.data[i].onMatch} optionAction={i}/>


                    </div> : <div></div>}


                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && this.state.data[i].onMatch.key && this.state.data[i].onMatch.value && this.state.edit ?
                    <div style={{padding: "0px", width: "100%", marginTop: "8px"}}>
<p>Set Context Value</p>
                        <SetContextValue
                            optionActionDisplay={true}
                            option={true}
                            onChange={(event) => this.handleSetContextChange(event, i)}
                            value={this.state.data[i].onMatch} optionAction={i}
                        />


                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.flow || this.state.data[i].onMatch.flow === "") && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>
                        <TextField
                            label="Workflow Id"
                            value={this.state.data[i].onMatch.flow}
                            onChange={this.handleFlowIdChange.bind(this, i)}

                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            style={{width: "100%"}}
                            id={i.toString()}
                        />
                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && (this.state.data[i].onMatch.initiateAiWithResponse || this.state.data[i].onMatch.initiateAiWithResponse === "") && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>

                        {this.props.newNameResponseOnIntent && this.state.updatedPosition === i ?
                            <ResponseOnIntent option={true} onChange={this.handleResponseChange.bind(this, i)}
                                              value={this.state.data[i].onMatch}
                                              settingsChange={this.settingsChange}
                                              workflowCreate={this.props.workflowCreate}
                                              id={i.toString()} model={this.props.model} pipeline={this.props.pipeline}
                                              openWorkflow={this.openWorkflow}
                                              handleOpenWorkFlow={this.handleOpenWorkFlow}
                                              account={this.props.account}
                                              replace={this.state.data[i].replace}
                                              newNameResponseOnIntent={this.props.newNameResponseOnIntent}/> :
                            <ResponseOnIntent option={true} onChange={this.handleResponseChange.bind(this, i)}
                                              value={this.state.data[i].onMatch}
                                              settingsChange={this.settingsChange}
                                              workflowCreate={this.props.workflowCreate}
                                              id={i.toString()} model={this.props.model} pipeline={this.props.pipeline}
                                              openWorkflow={this.openWorkflow}
                                              handleOpenWorkFlow={this.handleOpenWorkFlow}
                                              replace={this.state.data[i].replace}
                                              account={this.props.account}/>}
                    </div> : <div></div>}


                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && this.state.data[i].onMatch.model && this.state.data[i].onMatch.pipeline && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>

                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && this.state.data[i].onMatch.length === 0 && this.state.edit ?
                    <div style={{
                        width: "100%",
                        wordBreak: "break-all",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px"
                    }}>
                        <h3>Response Action</h3>

                        <div style={{overflow: "auto", marginTop: "-10px"}}>
                            <List
                                style={{
                                    width: "100%",
                                    maxHeight: "200px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    overflow: "auto"
                                }}
                                component="nav"
                                id='thing'
                            >
                                {this.listActions(i)}
                            </List>

                            <div>
                                {this.renderWorkflowForm(i)}
                            </div>


                        </div>
                    </div> : <div></div>
                }

            </div>
        });

    }

    addOption() {

        this.state.data.push({"text": "", "accepts": [], "onMatch": []});
        this.setState({render: true});
        this.setState({showSort: false});
        //this.listRef.current.scrollIntoView({block: "end", inline: "nearest"});


        setTimeout(this.scrollOption, 500);

    }

    scrollOption() {
        let curr = this.listRef.current;

        if (curr) {
            curr.scrollTop = (curr.scrollHeight - curr.clientHeight);
        }

    }


    saveWorkflow() {

        let workflow = this.state.data;



        workflow.map((object, key) => {

            if (object.onMatch && object.onMatch.length === 0) {
                delete object.onMatch;

            }
        });
        let settings = {};


        if(this.state.defaultOption && this.state.defaultTime && this.state.chooseDefault){
            settings["defaultOption"] = this.state.defaultOption;
            settings["chooseDefaultAfterSec"] = Number(this.state.defaultTime);


        }
        this.props.updateDisableInput(this.state.checked);
        this.props.saveWorkflow("website.options", workflow, settings);


    }


    removeOption() {

        let arr = this.state.data.splice(this.state.data.length - 1, 1);
        this.setState({data: this.state.data});
    }

    onSort(sortedList, dropEvent) {
        //console.log("sortedList", sortedList, dropEvent);


        let newList = [];
        let dataArray = this.state.data;
        sortedList.map((object) => {

            let sortedName = object.content.props.children;

            dataArray.map((originalObject, key) => {

                if (originalObject.text === sortedName) {
                    newList.push(originalObject);

                }

            })


        });

        this.setState({data: newList}, this.listOptions());


    }


    handleTypeChange(e) {


        this.setState({typeSelect: e.target.value}, this.props.updateOptionsType(e.target.value));


    }

    handleDisbleInputCheckbox(e) {

        this.setState({checked: e.target.checked}, this.props.updateDisableInput(e.target.checked));

    }

    handleShowType(e) {

        if (this.state.showType) {

            this.setState({showType: false});
        } else {

            this.setState({showType: true});
        }


    }

    render() {
        const {classes} = this.props;
        return (
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <div style={{
                    marginTop: '0px',
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginBottom: "20px"
                    }}>
                        <Tooltip title="Add Option">
                            <IconButton style={{background: "#3E51B5", color: "white"}}
                                        onClick={this.newWorkflow} aria-label="Add Option" onClick={this.addOption}
                                        component="span">
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Sort Options">
                            <IconButton style={{marginLeft: "15px", background: "#3E51B5", color: "white"}}
                                        aria-label="Sort Options" onClick={this.handleShowSort}
                                        component="span">
                                <ImportExportIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton style={{marginLeft: "15px", background: "#3E51B5", color: "white"}}
                                        aria-label="Sort Options" onClick={this.handleShowType}
                                        component="span">
                                <SettingsIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    {this.state.showType ?
                        <div style={{display: "flex", flexDirection: "column", marginBottom: "20px"}}>
                            <FormGroup row style={{marginTop: "0px"}}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.chooseDefault}
                                                       onChange={this.handleChooseDefault}
                                                       name="Automatically Choose Default"/>}
                                    label="Automatically Choose default"
                                />


                            </FormGroup>

                            {this.state.chooseDefault && <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <span   style={{width: "100px", marginTop: "10px"}}>Default Option</span>
                                <Select
                                    labelId="Type"
                                    id="type-select"
                                    variant="outlined"
                                    style={{marginTop: "20px", marginLeft: "10px"}}
                                    value={this.state.defaultOption}
                                    onChange={this.handleDefaultOptionChange}
                                >
                                    {this.renderOptions()}
                                </Select>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <span style={{width: "100px"}}z>Timeout[sec]</span>
                                    <TextField
                                        onChange={this.defaultTimeChange}
                                        margin="normal"
                                        value={this.state.defaultTime}
                                        multiline={false}
                                        variant="outlined"
                                        style={{width: "80px", marginLeft: "10px"}}
                                    />
                                </div>
                            </div>}
                          </div> : <div></div>}


                    {this.state.showSort && this.state.items.length > 0 && this.state.items[0] != "" ?
                        <SortableContainer onSortEnd={this.onSortEnd}>
                            {this.state.items.map((value, index) => (
                                <SortableItem helperClass='sortableHelper' key={`item-${value}`} index={index}
                                              value={value}/>

                            ))}
                        </SortableContainer> : <div></div>}
                    {this.state.showSort && (this.state.items.length === 0 || this.state.items[0] === "") ?
                        <div style={{marginTop: "10px"}}>No options to sort</div> : <div></div>}

                </div>
                {!this.state.showSort ?
                    <div style={{marginBottom: "0px"}}>
                        <div style={{
                            marginTop: '1   ' +
                                '0px',
                            width: "100%",
                            overflowY: "auto",
                            borderTop: "1px solid #ccc",
                            paddingBottom: "10px",
                            marginBottom: "0px",

                        }} ref={this.listRef}>

                            <List
                                style={{minHeight: "180px", maxHeight: "calc(66vh - 100px)", width: "100%"}}
                                component="nav"
                                className={classes.root}
                                id='thingList'
                            >
                                {this.listRow()}
                            </List>

                        </div>
                    </div> : <div></div>}
                <div style={{

                    marginTop: "30px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column"

                }}>

                    {this.state.edit ? <Button variant="contained"
                                               style={{backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                               onClick={this.saveWorkflow}>Update</Button> :
                        <Button variant="contained"
                                style={{backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                onClick={this.saveWorkflow}>Add</Button>}
                </div>
            </div>

        )
    }

}

export default compose(
    withStyles(styles)
)(OptionsAction);
