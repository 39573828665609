import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import {ListItem, ListItemText, ListItemIcon} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Notifier from "./Notifier";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChipInput from "material-ui-chip-input";
import { getDomains } from "./reused/reusedfunctions.js";

const styles = theme => ({
    root: {
        flexGrow: 1,
    }, textField: {
        marginLeft: theme.spacing(4), marginRight: theme.spacing(4), width: 200,
    }, paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    }, paperForm: {
        display: 'flex', flexDirection: 'row'


    }, listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5", color: "white"
        }, fontSize: "12px"

    }, listItemText: {
        fontSize: "14px"

    }, listItemTextSelected2: {

        "&.Mui-selected": {
            backgroundColor: "#cccccc", color: "black"
        }, fontSize: "12px"

    }, NameCell: {
        fontWeight: 900, textDecoration: "underline", cursor: "pointer"
    }, dateFormatInput: {
        height: "80px", root: {
            height: "80px", input: {

                height: "10px"
            }
        }, input: {

            height: "10px"
        }

    }, input: {
        height: "45px"
    }, label: {fontSize: "40px"},


});

let numberOfCommon = 0;
let numberOfResponse = 0;

function TabContainer(props) {
    return (<Typography component="div" style={{padding: 8 * 3}}>
        {props.children}
    </Typography>);
}

class SaveModelAs extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Settings',
            cw: [],
            modelExist:false,
            checkedNames: [],
            language: "english##en",
            lang: "english",
            translate: false,
            toPrefix: "",
            replacePrefix: " ",
            updateToAccountText: "",
            updateToModelText: "",
            nbrOfResponses: 0,
            account: "",
            pipeline: "en",
            model: "None",
            wa: [],
            workflowName: [],
            trainingData: [],
            selectValue: " ",
            toModel: "None",
            toAccount: "None",
            toAccountObject: "None",
            toAccountNew: {"subdomain": ""},
            numberOfSavedFlows: 0,
            checked: [],
            selectAll: false,
            selectAllIntents: false,
            checkedIntents: [],
            confirmDelete: false,
            tabValue: 0,
            toModelString: '',
            allTrainingdata: [],
            yourChips: [],
            yourChipsWorkflow: [],
            allAccounts: [],
            trainingDataModels: []
        }

        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.getAllIntents = this.getAllIntents.bind(this);
        this.getResponse = this.getResponse.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.saveWorkflow = this.saveWorkflow.bind(this);
        this.saveRasaModel = this.saveRasaModel.bind(this);
        this.listRow = this.listRow.bind(this);
        this.listRowAccounts = this.listRowAccounts.bind(this);
        this.getModels = this.getModels.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resaveAllIntents = this.resaveAllIntents.bind(this);
        this.saveResponseToDb2 = this.saveResponseToDb2.bind(this);
        this.updateAfterSaveModel = this.updateAfterSaveModel.bind(this);
        this.renderFlows = this.renderFlows.bind(this);
        this.renderIntents = this.renderIntents.bind(this);
        this.reactOnClick = this.reactOnClick.bind(this);
        this.saveSelectedWorkflow = this.saveSelectedWorkflow.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectAllIntents = this.selectAllIntents.bind(this);
        this.reactOnClickIntent = this.reactOnClickIntent.bind(this);
        this.saveSelectedIntents = this.saveSelectedIntents.bind(this);
        this.getSaveToModelIntents = this.getSaveToModelIntents.bind(this);
        this.processSaveIntent = this.processSaveIntent.bind(this);
        this.getAllAccounts = this.getAllAccounts.bind(this);
        this.getAllModels = this.getAllModels.bind(this);
        this.handleToModelChange = this.handleToModelChange.bind(this);
        this.listToModels = this.listToModels.bind(this);
        this.createNewModel = this.createNewModel.bind(this);
        this.saveNewModel = this.saveNewModel.bind(this);
        this.deleteModel = this.deleteModel.bind(this);
        this.confirmDeleteClose = this.confirmDeleteClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleChangePipeline = this.handleChangePipeline.bind(this);
        this.handlePrefixChange = this.handlePrefixChange.bind(this);
        this.handleAccountChangeNew = this.handleAccountChangeNew.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleAddChipWorkflow = this.handleAddChipWorkflow.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.filterArray = this.filterArray.bind(this);
        this.handleDeleteChipWorkflow = this.handleDeleteChipWorkflow.bind(this);
        this.handleReplacePrefixChange = this.handleReplacePrefixChange.bind(this);
        this.queryChatGpt = this.queryChatGpt.bind(this);
        this.translateContent = this.translateContent.bind(this);
        this.postToKv = this.postToKv.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleTranslateChange = this.handleTranslateChange.bind(this);
        this.getR4Token = this.getR4Token.bind(this);
        this.extractIntentsToSave = this.extractIntentsToSave.bind(this);
        this.getConf = this.getConf.bind(this);
    }

    async getConf(){

        const domains = await getDomains();
        this.setState({domains: domains?domains: null});
    }

    async getR4Token() {
        let url = '/api/getR4Token';

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.access_token) {

            this.setState({token: data.access_token});


        }
    }

    handleTranslateChange(event) {

        this.setState({translate: event.target.checked});
    }

    handleLanguageChange(event) {
        this.setState({language: event.target.value, lang: event.target.value.split("##")[0]});

    }

    async postToKv(name, postObject) {

        let url = "https://"+this.state.domains.restDomain.default+"/kv/" + name;
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.state.token);
        myHeaders.append("Content-Type", "application/json");

        let oToPost = this.state.updatedResponse;
        if (postObject) {

            oToPost = postObject;
        }

        let requestOptions = {
            method: 'POST', headers: myHeaders, body: oToPost, redirect: 'follow'
        };

        const response = await fetch(url, requestOptions);

        const data = await response.text();


    }

    translateContent(type) {

        if (type === "workflows") {
            if (this.state.wa) {
                const promises = [];
                this.state.wa.map((workflow) => {

                    workflow.array.map((action, i) => {
                        if (action.type === "conversation.message") {

                            promises.push(this.queryChatGpt("Please translate " + action.message + " to " + this.state.lang + " reply back with just the translated string", workflow.name, i, action.type, action.message));

                        } else if (action.type === "website.options") {

                            if (action.options) {

                                action.options.map((match) => {
                                    promises.push(this.queryChatGpt("Please translate " + match.text.trim() + " to " + this.state.lang + " reply back with just the translated string", workflow.name, i, action.type, match.text));
                                });
                            }
                        }
                    })

                });

                Promise.all(promises).then(() => {
                    const wa = this.state.wa;
                    this.state.gptResult.map((change) => {

                        wa.map((flow, i) => {

                            if (change.workflowName === flow.name) {

                                if (flow.array[change.position].type === "conversation.message") {
                                    let resp = change.objectResponse.replaceAll("\"", "");
                                    flow.array[change.position].message = resp;

                                } else if (flow.array[change.position].type === "website.options") {
                                    flow.array[change.position].options.map((opt) => {

                                        if (opt.text === change.originalText) {
                                            let resp = change.objectResponse.replaceAll("\"", "");
                                            resp = resp.replace(/\./g, '');
                                            opt.text = resp;
                                            opt.accepts = [resp];
                                        }
                                    });
                                }
                            }
                        })


                    });
                    this.saveWorkflow(null, wa, this.state.checkedNames);
                });
                this.setState({loading: false});

            }
        } else if (type === "intents") {

            if (this.state.cw) {
                const promises = [];
                let keys = Object.keys(this.state.cw);

                keys.map((intent) => {
                    let workflow = this.state.cw[intent];

                    workflow.map((action, i) => {
                        if (action.type === "conversation.message") {

                            promises.push(this.queryChatGpt("Please translate " + action.message + " to " + this.state.lang + " reply back with just the translated string", intent, i, action.type, action.message));

                        } else if (action.type === "website.options") {

                            if (action.options) {

                                action.options.map((match) => {
                                    promises.push(this.queryChatGpt("Please translate " + match.text.trim() + " to " + this.state.lang + " reply back with just the translated string", intent, i, action.type, match.text));
                                });
                            }
                        }
                    })

                });

                Promise.all(promises).then(() => {
                    let cw = this.state.cw;
                    let keys = Object.keys(this.state.cw);
                    this.state.gptResult.map((change) => {

                        keys.map((intent, i) => {
                            if (intent === change.workflowName) {
                                let flowArray = this.state.cw[intent];
                                let action = flowArray[change.position];
                                if (action.type === change.type) {

                                    if (action.type === "conversation.message") {
                                        let resp = change.objectResponse.replaceAll("\"", "");
                                        action.message = resp;


                                    } else if (action.type === "website.options") {

                                        action.options.map((opt) => {

                                            if (opt.text === change.originalText) {
                                                let resp = change.objectResponse.replaceAll("\"", "");
                                                resp = resp.replace(/\./g, '');
                                                opt.text = resp;
                                                opt.accepts = [resp];
                                            }
                                        });

                                    }
                                }
                            }

                        });

                    });
                });

                // Get all trainingdata and translate it
                this.setState({intentsToSave: this.state.checkedNames}, () => {
                    this.getSaveToModelIntents();
                });
            }
        }

    }

    async queryChatGpt(query, name, pos, type, originalText) {


        let headerObject = {
            "Authorization": "Bearer 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",
            "Content-Type": "application/json"
        };
        let url = "https://api.openai.com/v1/chat/completions";

        let options = {
            headers: headerObject, method: 'POST', body: JSON.stringify({
                "model": "gpt-4-0613", "messages": [{"role": "user", "content": query}]
            })
        };

        const response = await fetch(url, options);

        if (response.status === 429) {
            let failureResponse = this.state.failures?this.state.failures: [];
            failureResponse.push(name);
            this.setState({failures: failureResponse})

        } else if (response.status === 200) {
            const data = await response.json();
            let result = this.state.gptResult ? this.state.gptResult : [];
            if (data) {

                if (data && data.choices && data.choices[0].message && data.choices[0].message.content) {

                    let objectResponse = data.choices[0].message.content;
                    let parsedObj;
                    if (type === "object") {
                        try {
                            parsedObj = JSON.parse(objectResponse);
                            originalText = parsedObj[0].intent;

                        } catch (e) {
                            console.log(e);
                        }
                    }

                    result.push({
                        "workflowName": name,
                        "type": type,
                        "position": pos,
                        "objectResponse": objectResponse,
                        "originalText": originalText
                    });
                }
            }
            this.setState({gptResult: result});
        }


    }

    handleAddChipWorkflow = (chip) => {

        let chips = this.state.yourChipsWorkflow;
        chips.push(chip);

        this.setState({yourChipsWorkflow: chips})

        this.setState({filteredArray: this.filterArray(this.state.workflowName, chips)}, () => {

            this.findSelected(this.state.checkedNames);
        });
    }

    filterArray(array, filters) {

        const filterKeys = filters;
        function filterArrayByKeywords(arr, keywords) {
            return arr.filter(item => {
                for (let i = 0; i < keywords.length; i++) {
                    const keyword = keywords[i];
                    if (keyword.startsWith('!')) {
                        const negatedKeyword = keyword.slice(1);
                        if (item.toLowerCase().includes(negatedKeyword.toLowerCase())) {
                            return false;
                        }
                    } else if (!item.toLowerCase().includes(keyword.toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }

        return filterArrayByKeywords(array, filters);
        /*if (filterKeys) {

            return array.filter(item => {

                // validates all filter criteria
                return filterKeys.every(key => {

                    return item.toLowerCase().indexOf(key.toLowerCase()) >= 0;

                });
            });
        }*/
    }



    handleDeleteChipWorkflow = (chip, index) => {


        let chips = this.state.yourChipsWorkflow;

        chips.splice(index, 1);

        this.setState({yourChipsWorkflow: chips});
        this.setState({filteredArray: this.filterArray(this.state.workflowName, chips)}, () => {

            this.findSelected(this.state.checkedNames);
        });
    }
    handleDeleteChip = (chip, index) => {


        let chips = this.state.yourChips;

        chips.splice(index, 1);

        this.setState({yourChips: chips});
        this.setState({filteredArray: this.filterArray(this.state.modelIntents, chips)}, () => {

            this.findSelected(this.state.checkedNames);
        });
    }
    handleAddChip = (chip) => {

        let chips = this.state.yourChips;
        chips.push(chip);

        this.setState({yourChips: chips})

        this.setState({filteredArray: this.filterArray(this.state.modelIntents, chips)}, () => {

            this.findSelected(this.state.checkedNames);
        });
    }


    handleChangePipeline(event) {

        this.setState({pipeline: event.target.value});
    }

    handleTabChange = (event, value) => {

        this.setState({tabValue: value});
        if (value === 1) {
            this.getAllCommonWorkflows();
        }

    };

    confirmDeleteClose(event) {

        if (event.target.innerText === "YES") {

            this.deleteModel();


        }
        this.setState({confirmDelete: false});

    }

    async deleteModel() {

        let url = '/api/deleteDataModel?model=' + this.state.toModel

        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length === 0) {

            this.setState({notifierDeleteOpen: true, toModel: "None"});
            this.getAllModels();

        }

    }


    saveNewModel(e) {

        if (this.state.toAccount !== "None") {
            let toSave = {
                "rasa_nlu_data": {"common_examples": [], "entity_synonyms": []}
            };
            let exists = false;
            this.state.allTrainingdata.map((training) =>{

                if(training.Id === this.state.toModelString){
                    exists = true;
                }
            });
            if(!exists){
                this.setState({modelExist:false})
                this.saveRasaModel(JSON.stringify(toSave));
            }else{
                    this.setState({modelExist:true});
            }

        } else {


        }
    }

    createNewModel(e) {

        if (this.state.showCreate) {
            this.setState({showCreate: false});
        } else {
            this.setState({showCreate: true});
        }

    }

    async getAllModels() {

        let url = '/api/trainingData';

        const response = await fetch(url);

        const data = await response.json();


        this.setState({trainingDataModels: data});

        this.setState({allTrainingdata: data});


    }

    async getAllAccounts() {

        let url = '/api/allaccounts';

        const response = await fetch(url);

        const data = await response.json();
        // console.log(data)
        this.setState({allAccounts: data});

    }


    selectAllIntents(event, type) {
        this.state.selectAllIntents ? this.setState({selectAllIntents: false}) : this.setState({selectAllIntents: true});

        if (event.target.checked || type === "all") {
            let selected = this.state.selectedIntents ? this.state.selectedIntents : [];


            let modelIntents = this.state.modelIntents;
            if (this.state.yourChips.length > 0) {
                modelIntents = this.filterArray(this.state.modelIntents, this.state.yourChips);

            }


            modelIntents.map((select, i) => {
                selected.push(i);
            });

            this.setState({checkedIntents: selected, checkedNames: modelIntents});

        } else {

            this.setState({checkedIntents: [], checkedNames: []});
        }

    }

    saveSelectedIntents(event) {

        if (this.state.toModel !== "") {


            if (this.state.toAccount !== "None") {

                const promises = [];
                this.state.checkedNames.map((name) => {
                    promises.push(this.getResponse(name));
                })

                Promise.all(promises).then(() => {

                    this.setState({loading: true});

                    if (this.state.translate) {
                        this.setState({gptResult: []}, () => {
                            this.translateContent("intents");
                        });
                    } else {
                        this.setState({intentsToSave: this.state.checkedNames}, () => {
                            this.getSaveToModelIntents();
                        });
                    }


                }).catch((e) => {
                    console.log(e);
                });

            } else {
                this.setState({updateToAccount: true, updateToAccountText: "Please add a To Account"});
            }

        } else {

            if (this.state.toAccount === "") {
                this.setState({updateToAccount: true, updateToAccountText: "Please add a To Account"});
            }

            this.setState({updateToModel: true, updateToModelText: "Please add a To Model"});

        }


    }

    extractIntentsToSave(intentsToSave) {

        let trainingdatatoMove = [];
        let trainingMap = new Map();
        let indexToRemove = [];
        let intentNamesNotSaved = [];
        intentsToSave.map((intentToSave, i) => {

            if (this.state.saveToModelIntentNames.indexOf(intentToSave) === -1) {

                this.state.trainingData.map((intent, i) => {

                    if (intent.intent === intentToSave) {

                        if (trainingMap.get(intent.intent)) {
                            trainingdatatoMove = trainingMap.get(intent.intent)
                        } else {

                            trainingdatatoMove = [];
                        }
                        trainingdatatoMove.push(intent);
                        trainingMap.set(intent.intent, trainingdatatoMove);
                    }

                });
            } else {
                intentNamesNotSaved.push(intentToSave)
                indexToRemove.push(i);
            }
        });

        let keys = Array.from(trainingMap.keys());

        const promises = [];

        keys.map((key) => {

            if (trainingMap.get(key)) {
                // translate all training data
                promises.push(this.queryChatGpt("Can you translate the attributes in this object with attribute text to " + this.state.lang + " do not translate any content  within the attribute intent in the json object and make sure that you only answer back with the updated JSON object " + JSON.stringify(trainingMap.get(key)), key, 0, "object", key));

            }

        });

        Promise.all(promises).then(() => {

            this.state.gptResult.map((change) => {

                let intentArray;
                try {
                    intentArray = JSON.parse(change.objectResponse);
                } catch (e) {

                    console.log(e)
                }


                if (intentArray && Array.isArray(intentArray)) {
                    intentArray.map((intent) => {

                        if (intent.intent === change.originalText) {

                            intent.intent = change.workflowName;
                        }

                    });

                    if(this.state.failures){

                        this.state.failures.map((name) =>{

                            for (var i = intentArray.length - 1; i >= 0; i--) {
                                if (intentArray[i].intent === name) {
                                    intentArray.splice(i, 1);
                                }
                            }
                        });

                    }


                    trainingMap.set(change.workflowName, intentArray);
                }
            });


            let translatedTraining = [];
            let keys = Array.from(trainingMap.keys());
            keys.map((intent) => {

                translatedTraining = [...translatedTraining, ...trainingMap.get(intent)];

            });

            //Trainngdatatomove
            let updatedModel = [...this.state.saveToModel.rasa_nlu_data.common_examples, ...translatedTraining];

            let toSave = {
                "rasa_nlu_data": {"common_examples": updatedModel, "entity_synonyms": []}
            };


            this.saveRasaModel(JSON.stringify(toSave));

            this.setState({responseLength: intentsToSave.length});

            intentsToSave.map((intentName) => {

                this.updateResponse(intentName, this.state.cw[intentName]);

            });
            this.setState({loading: false, saveCompleted: true});

        });


    }

    processSaveIntent(intentsToSave) {

        let trainingdatatoMove = [];
        let indexToRemove = [];
        let intentNamesNotSaved = [];
        intentsToSave.map((intentToSave, i) => {

            if (this.state.saveToModelIntentNames.indexOf(intentToSave) === -1) {

                this.state.trainingData.map((intent, i) => {

                    if (intent.intent === intentToSave) {

                        trainingdatatoMove.push(intent);
                    }

                });
            } else {
                intentNamesNotSaved.push(intentToSave)
                indexToRemove.push(i);
            }
        });

        if (indexToRemove.length > 0) {

            let intentsAfterRemove = [];
            indexToRemove.sort(function (a, b) {
                return b - a;
            });

            indexToRemove.map((index) => {

                intentsToSave.splice(index, 1);

            });

            this.setState({intentsToSave: intentsToSave, intentNamesNotSaved: intentNamesNotSaved});

        }


        //Trainngdatatomove
        let updatedModel = [...this.state.saveToModel.rasa_nlu_data.common_examples, ...trainingdatatoMove];

        let toSave = {
            "rasa_nlu_data": {"common_examples": updatedModel, "entity_synonyms": []}
        };


        this.saveRasaModel(JSON.stringify(toSave));

        this.setState({responseLength: intentsToSave.length});

        intentsToSave.map((intentName) => {

            this.updateResponse(intentName, this.state.cw[intentName]);

        });

        this.setState({loading: false, saveCompleted: true});
    }

    renderIntents() {

        let filteredArray = this.filterArray(this.state.modelIntents, this.state.yourChips);

        if (!filteredArray) {

            filteredArray = this.state.modelIntents;

        }

        return filteredArray.map((intent, i) => {
            return <div key={i} style={{width: "100%", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "#FFFFFF"}}>
                <div style={{display: "inline-block", width: "90%", fontSize: "12px"}}>
                    <ListItem autoFocus={this.state.selected === i}
                              onClick={event => this.reactOnClickIntent(event, intent, i)}
                              classes={{root: this.props.classes.listItemTextSelected}} key={i}
                              selected={this.state.selectedIntent === i}
                              button>
                        <ListItemIcon style={{minWidth: "28px"}}>
                            <Checkbox
                                edge="start"
                                checked={this.state.checkedIntents.indexOf(i) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': i}}
                            />
                        </ListItemIcon>
                        <ListItemText id={i}
                                      classes={{primary: this.props.classes.listItemText}}
                                      primary={intent}/>
                    </ListItem>
                </div>
            </div>;
        });


    }

    selectAll(event, type) {
        this.state.selectAll ? this.setState({selectAll: false}) : this.setState({selectAll: true})

        if (event.target.checked || type === "all") {

            let workflowName = this.state.workflowName;
            if (this.state.yourChipsWorkflow.length > 0) {
                workflowName = this.filterArray(this.state.workflowName, this.state.yourChipsWorkflow);

            }

            let selected = this.state.selected ? this.state.selected : [];
            workflowName.map((select, i) => {
                selected.push(i);
            });

            this.setState({checked: selected, checkedNames: workflowName});

        } else {

            this.setState({checked: [], checkedNames: []});
        }

    }

    saveSelectedWorkflow(event) {
        this.getR4Token();
        this.setState({wa: []});
        let arrayOfFlows = this.state.wa;
        let newArray = [];
        let newNameArray = [];
        this.setState({loading: true});
        const promises = [];
        this.state.checkedNames.map((name) => {
            promises.push(this.getWorkflowResponseFromDb(name));
        })

        Promise.all(promises).then(() => {
            if (this.state.translate) {
                this.setState({gptResult: []}, () => {
                    this.translateContent("workflows");
                });
            } else {
                this.saveWorkflow(null, this.state.wa, this.state.checkedNames);
            }


            //
        }).catch((e) => {
            console.log(e);
        });


    }


    reactOnClick = (event, name, i) => {


        let checkedNames = this.state.checkedNames ? this.state.checkedNames : [];

        if (checkedNames && checkedNames.length > 0 && checkedNames.indexOf(name) > -1) {
            checkedNames.splice(checkedNames.indexOf(name), 1);
        } else {
            checkedNames.push(name);
        }


        this.setState({checkedNames: checkedNames});

        this.findSelected(checkedNames);
    }

    findSelected(checkedNames) {

        let checked = [];
        let filtered;
        if (checkedNames) {
            if (this.state.filteredArray) {

                filtered = this.state.filteredArray;

            } else {
                if (this.state.tabValue === 0) {
                    filtered = this.state.modelIntents;
                } else if (this.state.tabValue === 1) {

                    filtered = this.state.workflowName;
                }


            }
            filtered.map((intent, i) => {
                for (let j = 0; j < checkedNames.length; j++) {

                    if (checked.indexOf(i) === -1 && intent === checkedNames[j]) {
                        checked.push(i);
                    }
                }
            });

            if (this.state.tabValue === 0) {
                this.setState({checkedIntents: checked});
            } else if (this.state.tabValue === 1) {
                this.setState({checked: checked});
            }
        }
    }

    reactOnClickIntent = (event, name, i) => {


        let checkedNames = this.state.checkedNames ? this.state.checkedNames : [];


        if (checkedNames && checkedNames.length > 0 && checkedNames.indexOf(name) > -1) {
            checkedNames.splice(checkedNames.indexOf(name), 1);
        } else {
            checkedNames.push(name);
        }


        this.setState({checkedNames: checkedNames});

        this.findSelected(checkedNames);

    }


    renderFlows() {

        let filteredArray = this.filterArray(this.state.workflowName, this.state.yourChipsWorkflow);

        if (!filteredArray) {

            filteredArray = this.state.workflowName;

        }

        return filteredArray.map((flow, i) => {
            return <div key={i} style={{width: "100%", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "#FFFFFF",}}>
                <div style={{display: "inline-block", width: "90%", fontSize: "12px"}}>
                    <ListItem autoFocus={this.state.selected === i}
                              onClick={event => this.reactOnClick(event, flow, i)}
                              classes={{root: this.props.classes.listItemTextSelected}} key={i}
                              selected={this.state.selected === i}
                              button>

                        <ListItemIcon style={{minWidth: "28px"}}>
                            <Checkbox
                                edge="start"
                                checked={this.state.checked.indexOf(i) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': i}}
                            />
                        </ListItemIcon>
                        <ListItemText id={i}
                                      classes={{primary: this.props.classes.listItemText}}
                                      primary={flow}/>
                    </ListItem>
                </div>
            </div>;
        });

    }

    handlePrefixChange(event) {

        let val = event.target.value;
        this.setState({toPrefix: val});

    }

    handleReplacePrefixChange(event) {

        let val = event.target.value;
        this.setState({replacePrefix: val});

    }

    handleModelChangeNew = (event, value) => {
        this.setState({workflowName: []});
        if (value && value.Id && value.Project && value.Pipeline) {
            this.setState({model: value.Id});
            this.setState({account: value.Project});
            this.setState({pipeline: value.Pipeline});


            if (value.Project === this.state.toAccount) {
                this.setState({copyToSame: true});
            } else {
                this.setState({copyToSame: false});

            }

            this.setState({selectValue: value}, () => {
                this.setState({wa: [], cw: []});
                numberOfCommon = 0;
                numberOfResponse = 0;

                this.getAllIntents();
            });
        }
    }

    handleChange(event) {
        this.setState({workflowName: []});
        let val = event.target.value.split("##");

        this.setState({model: val[1]});
        this.setState({account: val[0]});
        this.setState({pipeline: val[2]});

        // console.log(val)

        if (val[0] === this.state.toAccount) {


            this.setState({copyToSame: true});
        }

        this.setState({selectValue: val[0] + "##" + val[1] + "##" + val[2]}, () => {
            this.setState({wa: [], cw: []});
            numberOfCommon = 0;
            numberOfResponse = 0;

            this.getAllIntents();
        });

    }

    getModels() {

        fetch('/api/trainingData')
            .then(response => {

                response.json().then(data => {

                    if (data && data.length > 0 && data.name !== 'error') {


                        //this.setState( {model: this.state.newModel});
                    }
                })
            })


    }

    resaveAllIntents() {

        let keys = Object.keys(this.state.cw);

        let modelSplit = this.state.selectValue.split("##");

        let trainingDataToSave;
        this.state.allTrainingdata.map((tData) => {

            if (tData.Id === modelSplit[1]) {

                this.setState({trainingDataToSave: tData.Value});
                trainingDataToSave = tData.Value;
            }


        });


        if (trainingDataToSave) {

            this.saveRasaModel(trainingDataToSave);
        }

        this.setState({responseLength: keys.length, intentsToSave: keys});

        keys.map((key) => {

            let workflow = this.state.cw[key];


            this.updateResponse(key, workflow);


        });

        /*let workflowArray = [];
                    if(workflow.metaData && workflow.metaData.workflow){
                        workflowArray = JSON.parse(workflow.metaData.workflow);

                    }*/
    }


    saveWorkflow(event, workArray, nameArray) {


        let workflowArray = [];

        if (workArray) {

            workflowArray = workArray;

        } else {

            workflowArray = this.state.wa;
        }

        workflowArray.map((workflow) => {

            workflow.array.map(action => {

                if (action.type === "LOGGER.track") {


                    if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {

                        action.event = action.event.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                        action.data.flow = action.data.flow.replaceAll(this.state.replacePrefix, this.state.toPrefix);

                    }else if(this.state.copyToSame && this.state.toPrefix && this.state.toPrefix !== "" ) {

                        action.event = this.state.toPrefix + action.event;
                        action.data.flow = this.state.toPrefix + action.data.flow;
                    }
                    action.data.created = "";
                    action.data.createdBy = "";
                    action.data.lastModified = "";
                    action.data.lastModifiedBy = "";
                }


                if (action.type === "case.contextSwitch") {

                    if (action.switches) {

                        if(action.default){
                            if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                                action.default.initiateAiWithResponse = action.default.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                            }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                                action.default.initiateAiWithResponse  = this.state.toPrefix +  action.default.initiateAiWithResponse;
                            }
                        }

                        action.switches.map((swi) => {

                            if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {

                                if (swi.onMatch.initiateAiWithResponse) {
                                    if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                                        swi.onMatch.initiateAiWithResponse = swi.onMatch.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                                    }else if(this.state.copyToSame &&  this.state.toPrefix && this.state.toPrefix !== "" ) {
                                        swi.onMatch.initiateAiWithResponse = this.state.toPrefix + swi.onMatch.initiateAiWithResponse;
                                    }
                                }
                                if (swi.onMatch.account) {
                                    swi.onMatch.account = this.state.toAccount;
                                }
                            }

                        })

                    }
                }
                if (action.type === "case.entitySwitch") {

                    if (action.switches) {

                        action.switches.map((swi) => {

                            if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {

                                if (swi.onMatch.initiateAiWithResponse) {
                                    if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                                        swi.onMatch.initiateAiWithResponse = swi.onMatch.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                                    }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                                        swi.onMatch.initiateAiWithResponse = this.state.toPrefix + swi.onMatch.initiateAiWithResponse;
                                    }
                                }
                                if (swi.onMatch.account) {
                                    swi.onMatch.account = this.state.toAccount;
                                }
                            }

                        })

                    }

                    if(action.default){
                        if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                            action.default.initiateAiWithResponse = action.default.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                        }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                            action.default.initiateAiWithResponse  = this.state.toPrefix +  action.default.initiateAiWithResponse;
                        }
                    }
                }

                if(action.type === "case.checkGroupStatusAndTransfer"){

                    if(action.transferFlows){

                        if(action.transferFlows.busy && action.transferFlows.closed){

                            if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                                action.transferFlows.busy = action.transferFlows.busy.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                                action.transferFlows.closed = action.transferFlows.closed.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                            }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                                action.transferFlows.busy  = this.state.toPrefix +  action.transferFlows.busy;
                                action.transferFlows.closed  = this.state.toPrefix +  action.transferFlows.closed;
                            }


                        }
                    }
                }
                if (action.type === "website.options") {

                    if (action.options) {

                        action.options.map((match) => {


                            if (match.onMatch && match.onMatch.type === "vergicAi.responseOnIntent") {
                                match.onMatch.account = this.state.toAccount;
                                match.onMatch.model = this.state.toModel;
                                if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                                    match.onMatch.initiateAiWithResponse = match.onMatch.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                                }else if(this.state.copyToSame && this.state.toPrefix && this.state.toPrefix !== "" ) {
                                    match.onMatch.initiateAiWithResponse = this.state.toPrefix + match.onMatch.initiateAiWithResponse;
                                }
                            }

                        })
                        if (action.forwardUnmatchedMessageTo) {

                            action.forwardUnmatchedMessageTo.model = this.state.toModel;
                            action.forwardUnmatchedMessageTo.pipeline = this.state.pipeline;
                            action.forwardUnmatchedMessageTo.account = this.state.toAccount;

                        }

                    }

                }

                if (action.type === "vergicAi.responseOnIntent") {

                    if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                        action.initiateAiWithResponse = action.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                    }else if(this.state.copyToSame && this.state.toPrefix && this.state.toPrefix !== "" ) {
                        action.initiateAiWithResponse  = this.state.toPrefix +  action.initiateAiWithResponse;
                    }

                    if (action.account) {
                        action.account = this.state.toAccount;
                        action.model = this.state.toModel;
                    }

                }

                if (action.type === "case.checkIfContextIsSet") {

                    if (action.onMatch) {
                        if (action.onMatch.type === "vergicAi.responseOnIntent") {
                            action.onMatch.account = this.state.toAccount;
                        }

                        if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                            action.onMatch.initiateAiWithResponse = action.onMatch.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                        }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                            action.onMatch.initiateAiWithResponse   = this.state.toPrefix +  action.onMatch.initiateAiWithResponse ;
                        }
                    }
                    if (action.onFailure) {
                        if (action.onFailure.type === "vergicAi.responseOnIntent") {
                            action.onFailure.account = this.state.toAccount;
                        }

                        if (this.state.copyToSame && this.state.replacePrefix && this.state.toPrefix && this.state.toPrefix !== "" && this.state.replacePrefix !== "") {
                            action.onFailure.initiateAiWithResponse = action.onFailure.initiateAiWithResponse.replaceAll(this.state.replacePrefix, this.state.toPrefix);
                        }else if(this.state.copyToSame  && this.state.toPrefix && this.state.toPrefix !== "" ) {
                            action.onFailure.initiateAiWithResponse   = this.state.toPrefix +  action.onFailure.initiateAiWithResponse ;
                        }
                    }
                    if (action.account) {
                        action.account = this.state.toAccount;
                    }

                }

                if (action.type === "case.executeOnTime") {

                    if (action.onMatch) {
                        if (action.onMatch.type === "vergicAi.responseOnIntent") {
                            action.onMatch.account = this.state.toAccount;
                        }
                    }
                    if (action.onFailure) {
                        if (action.onFailure.type === "vergicAi.responseOnIntent") {
                            action.onFailure.account = this.state.toAccount;
                        }
                    }
                    if (action.account) {
                        action.account = this.state.toAccount;
                    }

                }


                if (action.type === "case.executeOnDate") {

                    if (action.onMatch) {
                        if (action.onMatch.type === "vergicAi.responseOnIntent") {
                            action.onMatch.account = this.state.toAccount;
                        }
                    }
                    if (action.onFailure) {
                        if (action.onFailure.type === "vergicAi.responseOnIntent") {
                            action.onFailure.account = this.state.toAccount;
                        }
                    }
                    if (action.account) {
                        action.account = this.state.toAccount;
                    }

                }
            });

        });

        let workflowsSaved = [];
        const promises = [];
        //if (!workArray) {
            this.setState({numberOfResponse: this.state.workflowName.length});
            this.state.workflowName.map((workflowName, i) => {
                //console.log(workflowName,workflowArray[i]);
                workflowArray.map((item) => {

                    if (item.name === workflowName) {
                        workflowsSaved.push(workflowName);
                        promises.push(this.saveResponseToDb2(workflowName, item.array));


                        if(workflowName.indexOf("common") === -1){

                            workflowName = this.state.toAccount + '_common_' + workflowName;
                        }

                       promises.push( this.postToKv(workflowName, JSON.stringify(item.array)));
                    }
                });
            });
        Promise.all(promises).then(() => {        this.setState({loading: false, saveCompleteWorkflow: true, workflowsSaved: workflowsSaved});});

    }

    componentDidMount() {
        this.getR4Token();
        this.getAllModels();
        this.getConf();
        //  this.getModels();
        this.getAllAccounts();
        //setTimeout(()=>{}, 6000);
    }


    handleToModelChange(e) {
        let val = e.target.value;

        this.setState({toModel: val});


    }


    handleAccountChangeNew(event, value) {

        if (value && value.accountid) {
            this.setState({toAccount: value.accountid, toAccountNew: value});
        }

    }

    handleAccountChange(e) {
        let val = e.target.value.split("##");


        if (val.length > 1) {

            this.setState({toAccount: val[1], toAccountObject: e.target.value}, () => {

            });
        } else {
            this.setState({updateToAccount: false, updateToAccountText: ""});
        }


    }

    handleModelChange(e) {

        this.setState({toModelString: e.target.value.trim(), toModel: e.target.value.trim()});

        if (e.target.value.trim() !== "") {
            this.setState({updateToModel: false, updateToModelText: ""});
        }

    }

    async getResponse(intent) {

        let url = '/api/response?intent=' + intent + "&model=" + this.state.account + "_" + this.state.model + "_" + intent;

        const response = await fetch(url);

        const data = await response.json();

        if (data.length > 0) {
            const object = data[0];
            this.setState({nbrOfResponses: this.state.nbrOfResponses++});
            if (object.Response) {
                const resp = JSON.parse(object.Response);

                let response = "";
                if (resp.workflow) {
                    const workflow = resp.workflow;
                    const workflowParsed = JSON.parse(workflow);

                    workflowParsed.map((action) => {

                        if (action.type === "website.options") {

                            if (action.options) {

                                action.options.map((match) => {


                                    if (match.onMatch.type === "vergicAi.responseOnIntent") {
                                        match.onMatch.account = this.state.toAccount;
                                        match.onMatch.model = this.state.toModel;
                                        //console.log(, match.onMatch.pipeline)
                                    }
                                })

                            }

                            if (action.forwardUnmatchedMessageTo) {

                                action.forwardUnmatchedMessageTo.model = this.state.toModel;
                                action.forwardUnmatchedMessageTo.pipeline = this.state.pipeline;
                                action.forwardUnmatchedMessageTo.account = this.state.toAccount;

                            }

                        }

                        if (action.type === "vergicAi.responseOnIntent") {
                            if (action.account) {
                                action.account = this.state.toAccount;
                                action.model = this.state.toModel;
                            }

                        }


                    });
                    let commonWorkflows = this.state.cw;
                    commonWorkflows[intent] = workflowParsed;


                    this.setState({cw: commonWorkflows});


                } else if (resp.responseText) {

                    let flow = [{"type": "conversation.message", "message": resp.responseText}];
                    this.setState({response: JSON.stringify(flow)});

                }
            }
        }
    }

    async getWorkflowResponseFromDb(workflowName, category) {

        let url = '/api/getWorkflowResponseFromDb?responseId=' + this.state.account + '_common_' + workflowName;
        let wa = this.state.wa;
        let workflowN = this.state.workflowName;
        let response = await fetch(url, {
            method: 'get', mode: 'cors', headers: {'Content-Type': 'application/json'},
        }).catch(error => console.log(error));

        let data = await response.json();

        if (data[0] && data[0].Value) {


            let workflowArray = JSON.parse(data[0].Value);

            //workflowN.push(workflowName);
            wa.push({"array": workflowArray, "name": workflowName});
            numberOfCommon++;
            if (numberOfCommon === this.state.numberOfCommonResponse) {
                //console.log(wa);
            }

            this.setState({wa: wa});
            //this.setState({workflowName: workflowN});
        }
    }

    async getSaveToModelIntents(model) {

        let toModel = this.state.toModel;
        if (!toModel) {
            toModel = model;
        }

        let url = '/api/trainingDataJson?model=' + toModel
        const response = await fetch(url);

        const data = await response.json();


        let processData = data;

        if (data.length === 0) {

            processData = {
                "rasa_nlu_data": {"common_examples": [], "entity_synonyms": []}
            };


        } else {
            processData = data;

        }

        this.setState({saveToModel: processData});
        let intentNames = [];

        processData.rasa_nlu_data.common_examples.map((intent) => {
            if (intentNames.indexOf(intent.intent) === -1) {
                intentNames.push(intent.intent);
            }
        });

        this.setState({saveToModelIntentNames: intentNames}, () => {

            if (this.state.translate) {
                this.extractIntentsToSave(this.state.intentsToSave);
            } else {
                this.processSaveIntent(this.state.intentsToSave);
            }

        });


    }

    async saveModel(modelName) {


    }

    getAllIntents() {

        let model = this.state.model;

        fetch('/api/trainingDataJson?model=' + model)
            .then(response => {

                response.json()
                    .then(data => {

                        if (data.name && data.name === 'error') {
                            return;
                        } else {

                            this.setState({firstQuery: data});
                            let intents = [];
                            if (data && data.rasa_nlu_data) {
                                data.rasa_nlu_data.common_examples.map((intent) => {

                                    intents[intent.intent] = intent.intent;

                                });

                                this.setState({trainingData: data.rasa_nlu_data.common_examples});

                                let keys = Object.keys(intents);
                                this.setState({modelIntents: keys});

                                /* Get all Intent responses*/
                                /* keys.map((name) => {

                                     this.getResponse(name);
                                 });*/
                            }
                        }
                    })
            });
    }


    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflowsFromSpecific?account=' + this.state.account;
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            let workflowNames = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length - 1]) {
                    if (arraySplit.length > 3) {

                        workflowArray.push({
                            "Id": arraySplit[arraySplit.length - 1], "Category": arraySplit[arraySplit.length - 2]
                        });

                    } else {
                        workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                    }

                    workflowNames.push(arraySplit[arraySplit.length - 1]);

                }

            });

            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            });

            let wa = this.state.wa;


            this.setState({numberOfCommonResponse: workflowArray.length});


            this.setState({
                commonWorkflowArray: workflowArray, workflowName: workflowNames, nbrWorkflows: workflowNames.length
            });
//            console.log(workflowArray)
            /*get all workflow responses from db */
            /*  workflowArray.map((workflow) => {

                  let wf = this.getWorkflowResponseFromDb(workflow.Id, null);

              });*/


        }


    }

    updateResponse(intent, workflow) {

        let newModel = {"intent": intent, "metaData": {"workflow": JSON.stringify(workflow)}};

        let url = '/api/saveResponse?id=' + this.state.toAccount + '_' + this.state.toModel + '_' + intent;

        fetch(url, {
            method: 'post', mode: 'cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(newModel)
        }).then(response => {
            response.json()
                .then(data => {

                    if (data.length === 0) {
                        numberOfResponse++;
                        if (numberOfResponse === this.state.responseLength) {
                            this.setState({finished: true, saveCompleted: true});
                        }

                    }

                });
        });

        this.postToKv(this.state.toAccount + '_' + this.state.toModel + '_' + intent, JSON.stringify(newModel));

    }


    async saveRasaModel(model) {


        let url = '/api/saveModel?project=' + this.state.toAccount + '&pipeline=' + this.state.pipeline + '&model=' + this.state.toModel;

        let response = await fetch(url, {
            method: 'post', mode: 'cors', headers: {'Content-Type': 'application/json'}, body: model
        }).catch(error => console.log(error));


        if (response.status === 200) {

            this.setState({notifierOpen: true, showCreate: false, toModelString: ""});
            this.getAllModels();
        } else if (response.status === 400) {

            this.updateAfterSaveModel(model);


        }

    }

    async updateAfterSaveModel(model) {


        let url = '/api/updateAfterSaveModel?model=' + this.state.toModel;


        let response = await fetch(url, {
            method: 'post', mode: 'cors', headers: {'Content-Type': 'application/json'}, body: model
        }).catch(error => console.log(error));


        if (response.status === 200) {


        } else if (response.status === 400) {


        }

    }


    async saveResponseToDb2(id, workflow) {

        let con = true;

        if (this.state.wa) {

            if (this.state.toPrefix && this.state.toPrefix !== "") {

                if (this.state.replacePrefix) {

                    id = id.replaceAll(this.state.replacePrefix, this.state.toPrefix);

                } else if(this.state.replacePrefix === ""){

                    id = this.state.toPrefix + id;
                }


            } else {
                //con = false;
            }
        }

        if (con) {

            let url = '/api/saveStandAloneResponse?responseId=' + encodeURIComponent(this.state.toAccount + '_common_' + id);

            let response = await fetch(url, {
                method: 'post',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(workflow)
            }).catch(error => console.log(error));

            if (response.status === 200) {

                let data = await response.json();

                if (data && data.length === 0) {


                    this.setState({numberOfSavedFlows: this.state.numberOfSavedFlows + 1}, () => {

                        if (this.state.numberOfResponse === this.state.numberOfSavedFlows) {

                            this.setState({saveCompleteWorkflow: true});
                        }

                    });


                }
            } else {
                this.setState({numberOfSavedFlows: this.state.numberOfSavedFlows + 1}, () => {

                    if (this.state.numberOfResponse === this.state.numberOfSavedFlows) {

                        this.setState({saveCompleteWorkflow: true});
                    }

                });

            }
        }

    }

    listToModels() {

        if (this.state.toAccount && this.state.allTrainingdata) {
            let availableModels = [];
            this.state.allTrainingdata.map((object) => {

                if (object.Project === this.state.toAccount) {
                    availableModels.push(object.Id);

                }

            });


            return availableModels.map((object, i) => {
                return <MenuItem key={i}
                                 value={object}>{object}</MenuItem>
            });
        }

    }

    listRowAccounts() {

        if (this.state.allAccounts) {

            return this.state.allAccounts.map((object, i) => {
                //if(object.)
                return <MenuItem key={i}
                                 value={object.subdomain + "##" + object.accountid}>{object.subdomain}</MenuItem>
            });
        }

    }

    listRow() {

        if (this.state.trainingDataModels) {

            return this.state.trainingDataModels.map((object, i) => {
                return <MenuItem key={i}
                                 value={object.Project + "##" + object.Id + "##" + object.Pipeline}>{object.Id}</MenuItem>
            });
        }

    }


    render() {

        const {classes} = this.props;


        return (<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start",}}>
            <Card sx={{}} style={{
                marginTop: "0px", padding: "20px", minHeight: "calc(100vh - 40px)", maxHeight: "calc(100vh - 40px)"
            }}>
                <CardContent>
                    <div style={{
                        display: "flex", flexDirection: "column", justifyContent: "space-evenly", minWidth: "350px"
                    }}>


                        {/* <FormControl className={classes.formControl} style={{marginTop: "30px"}}>
                                <InputLabel htmlFor="Site-simple">To Account</InputLabel>
                                <Select
                                    value={this.state.toAccountObject}
                                    onChange={this.handleAccountChange}
                                    inputProps={{
                                        name: 'Site',
                                        id: 'Site-simple',
                                    }}
                                    variant="outlined"
                                    style={{marginTop: "20px"}}
                                >
                                    <MenuItem value="None">
                                        <em>None</em>
                                    </MenuItem>
                                    {this.listRowAccounts()}
                                </Select>
                            </FormControl>*/}

                        <FormControl variant="outlined" style={{borderColor: "black"}}
                                     className={classes.formControl}>

                            <Autocomplete
                                id="combo-box1"
                                options={this.state.allAccounts}
                                onChange={this.handleAccountChangeNew}
                                defaultValue={this.state.allAccounts[0]}
                                value={this.state.toAccountNew}
                                getOptionLabel={option => option.subdomain}
                                style={{width: "375px"}}
                                renderInput={params => <TextField {...params} variant="outlined"
                                                                  label="To Account"
                                                                  style={{minHeight: "100px"}}/>}
                            />
                        </FormControl>


                        <FormControl className={classes.formControl} style={{marginTop: "30px"}}>
                            <InputLabel htmlFor="Site-simple">To Model</InputLabel>
                            <Select
                                value={this.state.toModel}
                                onChange={this.handleToModelChange}
                                inputProps={{
                                    name: 'Site', id: 'Site-simple',
                                }}
                                variant="outlined"
                                style={{marginTop: "20px"}}
                            >
                                <MenuItem value="None">
                                    <em>None</em>
                                </MenuItem>
                                {this.listToModels()}
                            </Select>
                        </FormControl>
                        {this.state.toAccount !== 'None' && <FormControl className={classes.formControl}
                                                                         style={{marginTop: "30px"}}>
                            <Button variant="contained"
                                    style={{
                                        backgroundColor: "green", color: "white", fontWeight: "bold", width: "100%"
                                    }}
                                    onClick={this.createNewModel}>New Model</Button>
                        </FormControl>}
                        {this.state.toModel !== 'None' && this.state.toModelString === '' &&
                            <FormControl className={classes.formControl}
                                         style={{marginTop: "30px"}}>
                                <Button variant="contained"
                                        style={{
                                            backgroundColor: "green", color: "white", fontWeight: "bold", width: "100%"
                                        }}
                                        onClick={() => {
                                            this.setState({confirmDelete: true});
                                        }}>Delete Model</Button>
                            </FormControl>}

                        {this.state.showCreate && <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center"
                            }}>
                                <FormControl className={classes.formControl}
                                             style={{marginTop: "30px"}}>
                                    <TextField
                                        error={this.state.updateToModel}
                                        label="New model name"
                                        value={this.state.toModelString}
                                        onChange={this.handleModelChange}
                                        margin="normal"
                                        multiline={false}
                                        variant="outlined"
                                        style={{}}
                                        helperText={this.state.updateToModelText}
                                    />
                                    {this.state.modelExist &&
                                        <p style={{color:"red"}}>Model name must be unique, please choose antoher name</p>}

                                </FormControl>

                                <FormControl className={classes.formControl}
                                             style={{marginTop: "20px", marginLeft: "20px"}}>

                                    <Select
                                        value={this.state.pipeline}
                                        onChange={this.handleChangePipeline}
                                        inputProps={{
                                            name: 'Pipeline', id: 'Site-simple',
                                        }}
                                        variant="outlined"
                                        style={{marginTop: "20px"}}
                                    >
                                        <MenuItem value="en">
                                            <em>English En</em>
                                        </MenuItem>
                                        <MenuItem value="se">
                                            <em>Swedish Se</em>
                                        </MenuItem>
                                        <MenuItem value="fi">
                                            <em>Finish Fi</em>
                                        </MenuItem>
                                        <MenuItem value="nb">
                                            <em>Norwegian Bokmål Nb</em>
                                        </MenuItem>
                                        <MenuItem value="da">
                                            <em>Danish Dk</em>
                                        </MenuItem>
                                        <MenuItem value="fr">
                                            <em>French Fr</em>
                                        </MenuItem>
                                        <MenuItem value="de">
                                            <em>German De</em>
                                        </MenuItem>
                                        <MenuItem value="es">
                                            <em>Spanish Es</em>
                                        </MenuItem>
                                        <MenuItem value="nl">
                                            <em>Dutch nl</em>
                                        </MenuItem>
                                        <MenuItem value="bg">
                                            <em>Bulgaria Bg</em>
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                            <Button variant="contained"
                                    style={{
                                        backgroundColor: "green", color: "white", fontWeight: "bold", width: "100%"
                                    }}
                                    onClick={this.saveNewModel}>Save New Model</Button>


                        </div>}
                        {/*<FormControl className={classes.formControl} style={{marginTop: "30px"}}>
                                   <InputLabel htmlFor="Site-simple">Copy Model</InputLabel>
                                <Select
                                    value={this.state.selectValue}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'Site',
                                        id: 'Site-simple',
                                    }}
                                    variant="outlined"
                                    style={{marginTop: "20px"}}
                                >
                                    <MenuItem value="None">
                                        <em>None</em>
                                    </MenuItem>
                                    {this.listRow()}
                                </Select>
                            </FormControl>*/}
                        <FormControl variant="outlined"
                                     style={{borderColor: "black", marginTop: "20px"}}
                                     className={classes.formControl}>
                            {/*{object.Project + "##" + object.Id + "##" + object.Pipeline*/}
                            <Autocomplete
                                id="combo-box1"
                                options={this.state.trainingDataModels}
                                onChange={this.handleModelChangeNew}
                                defaultValue={this.state.trainingDataModels[0]}
                                value={this.state.selectValue}
                                getOptionLabel={option => option.Id}
                                style={{width: "375px"}}
                                renderInput={params => <TextField {...params} variant="outlined"
                                                                  label="Copy Model"
                                                                  style={{minHeight: "100px"}}/>}
                            />
                        </FormControl>


                        {this.state.copyToSame && <FormControl className={classes.formControl}
                                                               style={{marginTop: "30px"}}>

                            <p>
                                Since you are coping intents within the same account you need to
                                add a prefix
                            </p>
                            <TextField
                                label="New prefix"
                                value={this.state.toPrefix}
                                onChange={this.handlePrefixChange}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{}}
                            />

                            <TextField
                                label="Prefix to replace"
                                value={this.state.replacePrefix}
                                onChange={this.handleReplacePrefixChange}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                style={{}}
                            />

                        </FormControl>}
                        <Box component="div"><FormControlLabel
                            control={<Checkbox checked={this.state.translate}
                                               onChange={this.handleTranslateChange}
                                               name="Translate"/>}
                            label={<Box component="div">Translate</Box>}
                        /></Box>

                        {this.state.translate && <div style={{
                            display: "flex", flexDirection: "row", alignItems: "center", marginTop: "20px"
                        }}>
                            <span style={{width: "120px"}}>To Language: </span>
                            <Select
                                value={this.state.language}
                                onChange={this.handleLanguageChange}
                                inputProps={{
                                    name: 'Pipeline', id: 'Site-simple',
                                }}
                                variant="outlined"
                                label={"To language"}
                                style={{marginLeft: "0px"}}
                            >
                                <MenuItem value="english##en">
                                    <em>English En</em>
                                </MenuItem>
                                <MenuItem value="swedish##se">
                                    <em>Swedish Se</em>
                                </MenuItem>
                                <MenuItem value="finnish##fi">
                                    <em>Finnish Fi</em>
                                </MenuItem>
                                <MenuItem value="norwegian##nb">
                                    <em>Norwegian Bokmål Nb</em>
                                </MenuItem>
                                <MenuItem value="danish##dk">
                                    <em>Danish Dk</em>
                                </MenuItem>
                                <MenuItem value="french##fr">
                                    <em>French Fr</em>
                                </MenuItem>
                                <MenuItem value="german##de">
                                    <em>German De</em>
                                </MenuItem>
                                <MenuItem value="spanish##es">
                                    <em>Spanish Es</em>
                                </MenuItem>
                                <MenuItem value="dutch##nl">
                                    <em>Dutch nl</em>
                                </MenuItem>
                                <MenuItem value="bulgarian##bg">
                                    <em>Bulgaria Bg</em>
                                </MenuItem>

                            </Select>
                        </div>}

                        <br/>
                        <br/>


                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100px",
                            justifyContent: "space-between"
                        }}>


                        </div>
                        {/*{this.state.finished && <Typography>BOT Data Saved As</Typography>}*/}
                    </div>
                </CardContent>
            </Card>

            <Card
                style={{
                    marginLeft: "40px", marginTop: "10px", padding: "20px", minWidth: "600px"
                }}>
                <CardContent>
                    <Box
                        style={{
                            borderBottom: 1, borderColor: 'lightgrey', borderWidth: '1px', borderBottomStyle: "solid"
                        }}>
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange}>
                            <Tab label="Intents"/>
                            <Tab label="Workflows"/>
                        </Tabs>
                    </Box>

                    {this.state.commonWorkflowArray && this.state.tabValue === 1 && <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                            marginRight: "10px",
                            minHeight: "calc(100vh - 170px)",
                            marginTop: "10px",
                            width: "100%"

                        }}>
                        <ChipInput
                            value={this.state.yourChipsWorkflow}
                            variant="filled"
                            label="Filter"
                            style={{width: "100%"}}
                            onAdd={(chip) => this.handleAddChipWorkflow(chip)}
                            onDelete={(chip, index) => this.handleDeleteChipWorkflow(chip, index)}
                        />
                        <div style={{
                            border: "0px solid #ccc", marginBottom: "20px", marginTop: "10px", width: "100%"
                        }}>


                            <div style={{
                                borderBottom: "2px solid #ccc", marginLeft: "0px", marginRight: "0px", width: "100%",
                            }}>
                                <FormControl style={{marginLeft: "5px",width: "100%"}} component="fieldset"
                                             className={classes.formControl}>

                                    <FormGroup>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-evenly",
                                            width: "100%"
                                        }}>
                                            <Box component="div" fontSize={13} style={{}}><FormControlLabel
                                                control={<Checkbox
                                                    checked={this.state.selectAll}
                                                    onChange={this.selectAll}
                                                    name="Select All"/>}
                                                label={<Box component="div" fontSize={13}>Select
                                                    All</Box>}
                                            /></Box>
                                            <Box component="div" style={{}}
                                                 fontSize={13}>Selected: {this.state.checkedNames.length}</Box>
                                            <Box component="div" style={{}}
                                                 fontSize={13}>Total
                                                Number
                                                of flows: {this.state.nbrWorkflows}</Box>


                                        </div>
                                    </FormGroup>
                                </FormControl>
                            </div>

                            <List

                                style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    maxHeight: "calc(100vh - 420px)",
                                    minHeight: "calc(100vh - 420px)",

                                }}
                                component="nav"
                                className={classes.root}
                                id='thing'
                            >
                                {this.renderFlows()}
                            </List>
                        </div>
                        <Button variant="contained"
                                style={{
                                    backgroundColor: "green", color: "white", fontWeight: "bold", width: "100%"
                                }}
                                onClick={this.saveSelectedWorkflow}>Save the Selected
                            Workflows</Button>
                        {/*  <Button variant="contained"
                                style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    fontWeight: "bold",
                                    width: "100%",
                                    marginTop: "20px"
                                }}
                                onClick={event => {
                                    this.selectAll(event, "all");
                                    setTimeout(this.saveSelectedWorkflow, 500);
                                }}>SaveAs All
                            Workflows</Button>*/}

                    </div>}

                    {this.state.modelIntents && this.state.tabValue === 0 && <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "0px",
                            padding: "10px",
                            minHeight: "calc(100vh - 170px)",
                            width: "100%"
                        }}>
                        <ChipInput
                            value={this.state.yourChips}
                            variant="filled"
                            label="Filter"
                            style={{width: "100%"}}
                            onAdd={(chip) => this.handleAddChip(chip)}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                        />

                        <div style={{
                            border: "0px solid #ccc", marginBottom: "20px", marginTop: "10px", width: "100%"
                        }}>


                            <div style={{
                                borderBottom: "2px solid #ccc", marginLeft: "0px", marginRight: "0px", width: "100%"
                            }}>
                                <FormControl style={{marginLeft: "10px", width: "100%"}} component="fieldset"
                                             className={classes.formControl}>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-evenly"
                                    }}>

                                        <Box component="div" fontSize={13}><FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectAllIntents}
                                                onChange={this.selectAllIntents}
                                                name="Select All"/>}
                                            label={<Box component="div" fontSize={13}>Select
                                                All</Box>}
                                        /></Box>

                                        <Box component="div" style={{}}
                                             fontSize={13}>Selected: {this.state.checkedNames.length}</Box>
                                        <Box component="div" style={{}}
                                             fontSize={13}>Total
                                            Number
                                            of Intents: {this.state.modelIntents.length}</Box>

                                    </div>


                                </FormControl>
                            </div>
                            <List
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    marginTop: "10px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    maxHeight: "calc(100vh - 420px)",
                                    minHeight: "calc(100vh - 420px)"
                                }}
                                component="nav"
                                className={classes.root}
                                id='thing'
                            >
                                {this.renderIntents()}
                            </List>
                        </div>
                        <Button variant="contained"
                                style={{
                                    backgroundColor: "green", color: "white", fontWeight: "bold", width: "100%"
                                }}
                                onClick={this.saveSelectedIntents}>Save the Selected
                            Intents</Button>

                        {/*<Button variant="contained"
                                style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    fontWeight: "bold",
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "10px"
                                }}
                                onClick={event => {
                                    this.selectAllIntents(event, "all");
                                    this.saveSelectedIntents();
                                }}>SaveAs All Intents</Button>*/}
                    </div>

                    }


                </CardContent>
            </Card>

            {this.state.saveCompleteWorkflow && <Card sx={{maxWidth: 675}} style={{
                marginLeft: "40px", marginBottom: "50px", marginTop: "10px", padding: "20px"
            }}>
                <CardContent>
                    {this.state.failures && this.state.failures.length > 0 && <div>
                        <p>The following workflows failed to be translated, try again:</p>
                        {this.state.failures.map((name) =>{

                        return <p>{name}</p>})}
                    </div>}

                    <p style={{fontSize: "18px", color: "black"}}>Number of workflows
                        saved: {this.state.workflowsSaved.length}</p>
                    <p style={{fontSize: "18px", color: "black"}}>The following Workflows
                        successfully:</p>
                    {this.state.workflowsSaved.map((workflow, i) => {

                        return <p style={{fontSize: "14px", color: "black"}}
                                  key={"workflow" + i}>{workflow}</p>

                    })}


                </CardContent>
            </Card>}
            {this.state.loading && <Card sx={{maxWidth: 675}} style={{
                marginLeft: "40px", marginBottom: "50px", marginTop: "10px", padding: "20px"
            }}>
                <CardContent><CircularProgress/> </CardContent></Card>}
            {this.state.saveCompleted && <Card sx={{maxWidth: 675}} style={{
                marginLeft: "40px", marginBottom: "50px", marginTop: "10px", padding: "20px"
            }}>
                <CardContent>

                    <p style={{fontSize: "18px", color: "black"}}>The following Intents was saved
                        to {this.state.toModel} successfully:</p>
                    { this.state.intentsToSave && this.state.intentsToSave.map((intent, i) => {

                        return <p style={{fontSize: "14px", color: "black"}}
                                  key={"intent" + i}>{intent}</p>

                    })}

                    {this.state.intentNamesNotSaved &&
                        <p style={{fontSize: "18px", color: "black"}}>The following Intents already
                            existed:</p> && this.state.intentNamesNotSaved.map((intent, i) => {

                            return <p style={{fontSize: "14px", color: "black"}}
                                      key={"intent" + i}>{intent}</p>

                        })}

                </CardContent>
            </Card>}

            <Notifier changeState={() => {
                this.setState({notifierOpen: false});
            }} open={this.state.notifierOpen}
                      message={"Model " + this.state.toModel + " Saved Successfully"}/>
            <Notifier changeState={() => {
                this.setState({notifierDeleteOpen: false});
            }} open={this.state.notifierDeleteOpen}
                      message={"Model " + this.state.toModel + " Deleted Successfully"}/>


            <Dialog
                open={this.state.confirmDelete}
                onClose={this.confirmDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Model</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete model {this.state.toModel}?
                    </DialogContentText>
                </DialogContent>


                <DialogActions>
                    <Button onClick={this.confirmDeleteClose} color="primary">
                        Yes
                    </Button>
                    <Button onClick={this.confirmDeleteClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </div>);
    }
}

export default withStyles(styles)(SaveModelAs);
