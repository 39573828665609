import React from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core/";

const Selector = ({ value, onSelection, journeyNames }) => {
  return (

      <FormControl   style={{marginTop: "20px",  width: 200}}>
          <InputLabel style={{paddingLeft: "15px"}} htmlFor="Site-simple">Customer Journeys</InputLabel>



        <Select
            inputProps={{
                name: 'Journeys',
                id: 'Site-simple',
            }}
          value={value}
          label="Customer Journeys"
            onChange={onSelection}
          style={{minWidth: 160}}
          variant="outlined"
        >
          {journeyNames.map((journey) => {
            // console.log(journey);
            return (
              <MenuItem value={journey} key={journey}>
                {journey}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

  );
};

export default Selector;
