import React, {Component} from "react";
import {ListItem, ListItemText, withStyles} from "@material-ui/core";
import IntegrationInstructionsIcon from '@material-ui/icons/SettingsInputAntenna';
import List from "@material-ui/core/List";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from "@material-ui/core/Button/Button";
import InputIcon from '@material-ui/icons/Input';
import AddCircleOutlineIcon from '@material-ui/icons/Add';
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '  3px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    }, listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },


});


class Integrations extends Component {

    constructor(props) {

        super(props);

        this.state = {
            workFlowRender: null,
        };
        this.getAccountIntegrationActions = this.getAccountIntegrationActions.bind(this);
        this.getModels = this.getModels.bind(this);
        this.listRow = this.listRow.bind(this);
        this.reactOnClick = this.reactOnClick.bind(this);
        this.pass = this.pass.bind(this);
        this.addIntegration = this.addIntegration.bind(this);
    }

    pass() {

        let workflow = {"type": this.state.selectedType};

        this.props.passData("Integrations", workflow);

    }


    reactOnClick = (event, name, i) => {

        this.setState({selectedName: name});
        this.state.integrations.map((action) => {

            if (action.name === name) {
                let jsonConf;
                try{
                    jsonConf = JSON.parse(action.jsonconf);

                }catch(error){
                    console.log(error);
                }

                if(jsonConf){
                    this.setState({input: jsonConf.input});
                    this.setState({output: jsonConf.output});

                }
            }
        });

        this.setState({selected: i})
        //this.setState({jsonConf: this.state.data[name]});


    }

    addIntegration(){

        this.setState({selectedType: this.state.selectedName}, ()=>{this.pass()});
    }

    componentDidMount() {

        if (this.props.value) {

            this.setState({selectedName: this.props.value.type});




        }
        if (this.props.account) {

            this.getModels(this.props.account);

        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.account !== this.props.account) {

            this.setState({account: this.props.account}, () => {
                this.getModels(this.props.account);
            });

        }

    }

    getModels(account) {

        fetch('/api/trainingDataForAccount?account=' + account)
            .then(response => {

                response.json().then(data => {

                    if (data && data.length > 0 && data.name !== 'error') {
                        let models = [];
                        data.map((t) => {
                            models.push(t.Id);

                        });

                        this.setState({models: models});
                        this.setState({firstModel: this.props.accountName}, () => {
                            this.getAccountIntegrationActions();

                        });

                        //this.setState( {model: this.state.newModel});
                    }
                })
            })


    }

    async getAccountIntegrationActions() {
console.log(this.props.accountName)

        let url = '/api/getAccountIntegrationActions?model=' + this.props.accountName;
        const response = await fetch(url);

        const data = await response.json();


        this.setState({integrations: data});
        console.log(data)

        if(this.state.selectedName && this.state.selectedName !== ""){

            data.map((inte,i)=>{

                if(inte.name === this.state.selectedName){

                        this.setState({selected: i});
                        this.reactOnClick("event", inte.name, i);
                }
            })

        }


    }

    listRow() {
        if (this.state.integrations) {
            return this.state.integrations.map((object, i) => {
                return <div key={i} style={{width: "100%", maxWidth: "320px"}}>
                    <div style={{display: "inline-block", width: "100%", fontSize: "12px"}}>
                        <ListItem autoFocus={this.state.selected === i}
                                  onClick={event => this.reactOnClick(event, object.name, i)}
                                  classes={{root: this.props.classes.listItemTextSelected}} key={i}
                                  selected={this.state.selected === i}
                                  button>
                            <ListItemIcon>
                                <IntegrationInstructionsIcon/>
                            </ListItemIcon>

                            <ListItemText id={i}
                                          classes={{primary: this.props.classes.listItemText}}
                                          primary={object.name}/>
                        </ListItem>
                    </div>
                </div>;
                ;
            });
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "10px"}}>

                    <List

                        style={{
                            width: "100%",
                            maxWidth: "330px",
                            marginTop: "10px",
                            maxHeight: "510px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            borderBottom: "1px solid #ccc",
                            borderTop: "1px solid #ccc"
                        }}
                        component="nav"
                        className={classes.root}
                        id='thing'
                    >
                        {this.listRow()}
                    </List>
                    {this.state.input && <div>
                        <p style={{fontSize: "16px", fontWeight: "bold", marginLeft: "10px"}}>Input Context Variables</p>
                        <List style={{maxHeight: "150px", overflow: "auto"}}>
                            {this.state.input.map((variable, k)=>{
                               return  <ListItem classes={{root: this.props.classes.listItemTextSelected}} key={k}>
                                    <ListItemIcon>
                                        <InputIcon/>
                                    </ListItemIcon>

                                    <ListItemText id={Math.random()}
                                                  classes={{primary: this.props.classes.listItemText}}
                                                  primary={variable}/>
                                </ListItem>
                            })}
                        </List>

                    </div>
                        }
                    {this.state.output && <div>
                        <p style={{fontSize: "16px", fontWeight: "bold", marginLeft: "10px"}}>Output Context Variables</p>
                        <List style={{maxHeight: "150px", overflow: "auto"}}>
                            {this.state.output.map((variable, u)=>{

                                return <ListItem classes={{root: this.props.classes.listItemTextSelected}} key={u}>
                                    <ListItemIcon>
                                        <AddCircleOutlineIcon/>
                                    </ListItemIcon>

                                    <ListItemText id={Math.random()}
                                                  classes={{primary: this.props.classes.listItemText}}
                                                  primary={variable}/>
                                </ListItem>

                            })}
                        </List>

                    </div>
                    }

                    {this.state.selectedName &&      <Button
                        style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "10px"}}
                        variant="contained"
                        onClick={this.addIntegration}>Add</Button>}

                </div>
            </div>

        )
    }

}

export default withStyles(styles)(Integrations);
