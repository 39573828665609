import React, {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {
        height: "80px",
        root: {
            height: "80px",
            input: {

                height: "10px"
            }
        },
        input: {

            height: "10px"
        }

    },
    input: {

        height: "45px"
    },
    label: {fontSize: "40px"},


});

const theme = createTheme({
    overrides: {

        MuiInputBase: {

            root: {
                height: "35px",
                lineHeight: "0.9em",

            }
        }


    },
});


class CreateToken extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Create Token',
            confidenceThreshold: 0,
            sessionId: "",
            userId: "",
            domain: "https://subdomain.psplugin.com"


        }
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleDomainChange = this.handleDomainChange.bind(this);
    }

    handleButtonClick(event) {

        let codeString = "var xhr = new XMLHttpRequest();\n" +
            "\t\t\t\t\t\t\t        xhr.open('POST', \"" + this.state.domain + "/api/v1/user/" + this.state.userId + "/CreateToken\", true);\n" +
            "\t\t\t\t\t\t\t        xhr.setRequestHeader(\"Authorization\", \"" + this.state.sessionId + "\");\n" +
            "\t\t\t\t\t\t\t        xhr.send(null);\n" +
            "\n" +
            "\n" +
            "\t\t\t\t\t\t\txhr.onreadystatechange = function() {\n" +
            "\t\t\t\t\t\t\t            if (xhr.readyState == 4) {\n" +
            "\t\t\t\t\t\t\t                if (xhr.status == 200) {\n" +
            "\n" +
            "\t\t\t\t\t\t\t                    var jsonobj = this.responseText;\n" +
            "\t\t\t\t\t\t\t                    try {\n" +
            "\t\t\t\t\t\t\t                        var jsonresp = JSON.parse(jsonobj);\n" +
            "\t\t\t\t\t\t\t                        console.log(jsonresp);\n" +
            "\n" +
            "\t\t\t\t\t\t\t                        \n" +
            "\n" +
            "\t\t\t\t\t\t\t                    } catch (e) {\n" +
            "\n" +
            "\t\t\t\t\t\t\t                        console.log(\"Nerladdning misslyckades\"+e);\n" +
            "\n" +
            "\t\t\t\t\t\t\t                    }\n" +
            "\n" +
            "\t\t\t\t\t\t\t                }\n" +
            "\t\t\t\t\t\t\t            }\n" +
            "\t\t\t\t\t\t\t        };"


        navigator.clipboard.writeText(codeString).then(function () {

        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
        this.setState({copyNotification: "Token Create code copied to clipboard"});
    }

    componentDidMount() {

    }

    handleSessionChange(event) {

        this.setState({sessionId: event.target.value});
    }

    handleUserIdChange(event) {

        this.setState({userId: event.target.value});
    }

    handleDomainChange(event) {

        this.setState({domain: event.target.value});
    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        /*if (prevProps.model !== this.props.model) {

            this.setState({model: this.props.model});

        }*/

    }

    render() {

        const {classes} = this.props;


        return (

            <div style={{marginLeft: "10px", display: "flex", flexDirection: "column", width: "600px"}}>


                <FormControl>
                    <TextField onChange={this.handleSessionChange} label="Session Id"
                               value={this.state.sessionId}></TextField>
                </FormControl>
                <FormControl>
                    <TextField onChange={this.handleUserIdChange} label="User Id" value={this.state.userId}></TextField>
                </FormControl>
                <FormControl>
                    <TextField onChange={this.handleDomainChange} label="Domain" value={this.state.domain}></TextField>
                </FormControl>

                <FormControl style={{marginTop: "40px"}}>

                    <Button variant="contained"  color="primary" onClick={this.handleButtonClick}>Copy Token Code</Button>
                </FormControl>
                {this.state.copyNotification? <p>{this.state.copyNotification}</p>:""}
            </div>
        );
    }
}

export default withStyles(styles)(CreateToken);
