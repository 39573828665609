import React, {Component} from "react";
import {Draggable} from 'react-beautiful-dnd';
import Collapse from '@material-ui/core/Collapse';
import {
    Divider,
    ListItemIcon,
    Typography,
    ListItem,
    List,
    ListItemAvatar,
    Avatar,
    ListItemText,
    withStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {marginLeft: "20px"},
    inlineFixed: {marginLeft: "20px", width: "200px", marginRight: "20px"},
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },


});

class GroupedDraggableListItem extends Component {
    constructor(props) {

        super(props);

        this.state = {
            open: false

        }
        this.handleClick = this.handleClick.bind(this);

        this.renderGroupedItems = this.renderGroupedItems.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleEditGroupClick = this.handleEditGroupClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
    }



    handleEditClick= (event, i)=>{

        this.props.editCheckPoint(this.props.item[i])

    }

    handleAddClick = (event,i) =>{

        this.props.addItem(i,this.props.item);

    }

    handleEditGroupClick= (event, i)=>{


        this.props.edit(i, this.props.item)


    }

    componentDidMount() {


    }

    renderGroupedItems() {
        if (this.props.item) {


            return this.props.item.map((item, i) => {


                return <ListItem key={item.group+i} className={this.props.classes.nested} button><ListItemText
                    primary={<div style={{marginLeft: "45px", marginTop: "10px", minWidth: "400px", width: "380px"}}>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"

                        >
                            {item.primary}
                        </Typography>
                    </div>}/><ListItemText primary={<React.Fragment>
                    <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{marginLeft: "45px", marginTop: "10px",width: "28%"}}
                    >
                        {item.secondary}
                    </Typography>
                </React.Fragment>}/>
                    <IconButton size="small"
                                onClick={event => this.handleEditClick(event, i)}
                                style={{
                                    color: "#35424E",
                                    marginLeft: "10px"
                                }}
                                id="editButton"
                                color="primary"
                                aria-label="Edit"
                                component="span">
                        <EditIcon/>
                    </IconButton></ListItem>
            });
        }
    }



    handleClick= (event, message)=>{



            this.setState({open: this.state.open ? false : true});

    }


    render() {
        const {classes} = this.props;
        return (

            <Draggable draggableId={this.props.item[0].id.toString()} index={this.props.index} key={this.props.item[0].id.toString()}>
                {(provided, snapshot) => (

                    <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        divider={true}
                        selected={snapshot.isDragging ? true : false}

                    >
                        <div style={{display: "flex", flexDirection: "column", width: "100%",}}>
                            <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: "#35424E"}}> {this.props.index+1}</Avatar>
                                </ListItemAvatar>
                                <ListItemText style={{width: "400px"}} primary={<React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inlineFixed}
                                        color="textPrimary"
                                    >
                                        {this.props.item[0].group}
                                    </Typography>
                                </React.Fragment>}/>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>


                                    <IconButton size="medium"
                                            onClick={event => this.handleEditGroupClick(event, this.props.index)}
                                            style={{
                                                color: "#35424E",
                                                marginLeft: "10px"
                                            }}
                                            id="editButton"
                                            color="primary"
                                            aria-label="Edit"
                                            component="span">
                                    <EditIcon/>
                                </IconButton>
                                    <IconButton size="small"
                                                onClick={event => this.handleAddClick(event, this.props.index)}
                                                style={{
                                                    color: "#35424E",
                                                    marginLeft: "20px"
                                                }}
                                                color="primary"
                                                aria-label="add" component="span">
                                        <AddIcon/>
                                    </IconButton>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end", marginLeft: "10px"}}>


                                    <IconButton size="medium"
                                                onClick={event => {this.handleClick(event,"toggle")}}
                                                style={{
                                                    color: "#35424E",
                                                    marginLeft: "10px"
                                                }}
                                                id="editButton"
                                                color="primary"
                                                aria-label="Edit"
                                                component="span">
                                        {this.state.open ? <ExpandLess/> : <ExpandMore/>}
                                    </IconButton>


                                </div>


                            </div>
                            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {this.renderGroupedItems()}
                                </List>
                            </Collapse>
                        </div>
                    </ListItem>

                )}
            </Draggable>
        );
    }
}

export default withStyles(styles)(GroupedDraggableListItem);