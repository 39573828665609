import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class CustomerJourneyAction extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeGroup = this.handleChangeGroup.bind(this);
        this.handleChangeCheckpoint = this.handleChangeCheckpoint.bind(this);

        this.pass = this.pass.bind(this);
        this.getCustomerJourneys = this.getCustomerJourneys.bind(this);
        this.findCheckpoints = this.findCheckpoints.bind(this);
        this.findGroups = this.findGroups.bind(this);
        this.updateFiltered = this.updateFiltered.bind(this);

        this.state = {
            workFlowRender: null,
            selectedJourney: {"Id": ""},
            selectedGroup: {"Id": ""},
            selectedCheckpoint: {"Id": ""},
            journeys: [{"Id": ""}],
            filteredGroups: [{"Id": ""}],
            filteredCheckpoints: [{"Id": ""}],
        }
        this.convRef = React.createRef();
    }

    componentDidMount() {

        this.getCustomerJourneys();
        if (this.props.value) {

            this.setState({
                selectedJourney: {"Id": this.props.value.data.journeyName},
                selectedGroup: {"Id": this.props.value.data.groupName},
                selectedCheckpoint: {"Id": this.props.value.data.checkpointName}
            });

        }


    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if ((prevProps && prevProps.value !== this.props.value)) {


        }

    }

    pass() {
        console.log(this.state.selectedCheckpoint)
        let workflow = {
            type: "LOGGER.customerJourney", "event": "CustomerJourney", "data": {
                "journeyName": this.state.selectedJourney.Id,
                "groupName": this.state.selectedGroup.Id,
                "checkpointName": this.state.selectedCheckpoint.Id,
                "score": this.state.selectedCheckpoint.Score,
                "order": this.state.selectedCheckpoint.Order
            }
        };
            console.log(workflow)


        this.props.passData("LOGGER.customerJourney", workflow);

    }

    handleChange(event, value) {
        this.setState({selectedJourney: value});
        this.findGroups(value.Id);

    }

    handleChangeGroup(event, value) {
        this.setState({selectedGroup: value});
        this.findCheckpoints(value.Id, this.state.selectedJourney.Id);


    }

    handleChangeCheckpoint(event, value) {
        console.log(value);
        this.setState({selectedCheckpoint: value});
        this.setState({checkpoint: value.Id});

    }

    async getCustomerJourneys() {

        let url = '/api/getCustomerJourneys?accountId=' + this.props.account;

        const response = await fetch(url);
        const data = await response.json();

        let items = [];
        const groups = new Map();
        const journeys = new Map();
        if (data && data.length > 0) {

            data.map((journey) => {

                if (journey.Group) {

                    let obj = {};

                    obj.primary = journey.Checkpointname;
                    obj.id = journey.Id.toString();
                    obj.order = journey.Order;
                    obj.secondary = journey.Score;
                    obj.name = journey.Name;
                    obj.group = journey.Group;

                    items.push(obj);
                }

                journeys.set(journey.Name, 1);
            });

            let jour = Array.from(journeys.keys());
            let journeyArray = [];
            jour.map((j) => {

                journeyArray.push({"Id": j});

            })

            this.setState({
                items: items,
                journeys: journeyArray
            });

            if (this.state.selectedJourney && this.props.value && this.props.value.data && this.props.value.data.journeyName) {
                this.findGroups(this.props.value.data.journeyName);
            }

        }
    }


    findGroups(selectedJourney) {

        let filtered = this.state.items;

        if (filtered) {
            filtered = filtered.filter(object => {
                return object.name && object.name === selectedJourney;
            });

            let filteredGroups = [];
            filtered.map((item) => {
                let found = false;
                filteredGroups.map((fg) => {

                    if (fg.Id === item.group) {

                        found = true;
                    }
                });
                if (!found) {
                    filteredGroups.push({"Id": item.group});
                }
            })

            //  filtered = this.updateFiltered(filtered);

            this.setState({filteredJourneys: filtered, filteredGroups: filteredGroups});
            if (this.state.selectedGroup && this.props.value && this.props.value.data && this.props.value.data.groupName) {
                this.findCheckpoints(this.props.value.data.groupName, selectedJourney)
            }

        }
    }

    findCheckpoints(group, journeyName) {

        if (this.state.items) {

            let checkpoints = [];
            this.state.items.map((item) => {

                if (item.group && item.group === group && item.name && item.name === journeyName) {

                    checkpoints.push({"Id": item.primary, "Score": item.secondary, "Order": item.order });
                }

            });

            this.setState({filteredCheckpoints: checkpoints});

        }


    }

    updateFiltered(filtered) {

        let last = null;
        let groupArray = new Map();
        filtered.map((obj, i) => {

            let checkpoints = groupArray.get(obj.group) ? groupArray.get(obj.group) : [];

            if (obj.group) {

                checkpoints.push(obj);
                groupArray.set(obj.group, checkpoints);
            }

        });

        return Array.from(groupArray.values());

    }


    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "15px"}}>

                <FormControl variant="outlined" style={{borderColor: "black"}} className={classes.formControl}>

                    <Autocomplete
                        options={this.state.journeys}
                        onChange={this.handleChange}
                        value={this.state.selectedJourney?this.state.selectedJourney:{"Id": ""}}
                        getOptionLabel={option => option.Id}
                        style={{width: "100%"}}
                        renderInput={params => <TextField {...params} label="Journey" variant="outlined"/>}
                    />


                </FormControl>
                {this.state.filteredGroups &&
                    <FormControl variant="outlined" style={{borderColor: "black", marginTop: "15px"}}
                                 className={classes.formControl}>
                        <Autocomplete
                            options={this.state.filteredGroups}
                            onChange={this.handleChangeGroup}
                            value={this.state.selectedGroup?this.state.selectedGroup: {"Id": ""}}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Group" variant="outlined"/>}
                        />
                    </FormControl>
                }

                {this.state.filteredCheckpoints &&
                    <FormControl variant="outlined" style={{borderColor: "black", marginTop: "15px"}}
                                 className={classes.formControl}>
                        <Autocomplete
                            options={this.state.filteredCheckpoints}
                            onChange={this.handleChangeCheckpoint}
                            value={this.state.selectedCheckpoint?this.state.selectedCheckpoint: {"Id": ""}}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Checkpoint" variant="outlined"/>}
                        />
                    </FormControl>}
                <div style={{marginTop: "30px"}}>
                    {this.props.value && this.props.value.message ?
                        <Button
                            style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                            variant="contained"
                            onClick={this.pass}>Update</Button> :
                        <Button
                            style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                            variant="contained"
                            onClick={this.pass}>Add</Button>}
                </div>

            </div>


        )
    }

}

export default withStyles(styles)(CustomerJourneyAction);
