import React, { Component } from "react";
import Button from "@material-ui/core/Button/Button";
import {
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Notifier from "./Notifier";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { getDomains } from "./reused/reusedfunctions.js";
import { getSavedModels } from "./reused/reusedfunctions";
import { SignalCellularNullTwoTone } from "@material-ui/icons";
import { InputLabel, Select } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  NameCell: {
    fontWeight: 900,
    textDecoration: "underline",
    cursor: "pointer",
  },
  dateFormatInput: {
    height: "80px",
    root: {
      height: "80px",
      input: {
        height: "10px",
      },
    },
    input: {
      height: "10px",
    },
  },
  input: {
    height: "45px",
  },
  label: { fontSize: "40px" },
});

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        height: "35px",
        lineHeight: "0.9em",
      },
    },
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      title: "Settings",
      roles: [],
      models: [],
      notMappedGroups: [],
      confidenceThreshold: 0,
      fallbackFlow: SignalCellularNullTwoTone,
      inactiveFlow: null,
      didYouMeanTextModel: "",
      groups: [],
      model: null,
      settings: {
        confidenceThreshold: 0,
        fallbackFlow: "fallback",
      },
      commonWorkflows: [],
      selectedWorkflow: null,
      startFlow: null,
      mappingFlow: null,
      currentCase: null,
      mappedCaseFlows: [],
      cases: [],
      mappingFlow2: null,
      rangeValue: [25, 65],
      voicebot: false,
      didYouMeanText: "",
      didYouMeanTexts: [],
      checked: false,
      epochs: 300,
      template: null,
      templates: [],
      nbrOfMessagesSurvey: "",
      checkedSurvey: false,
      beforeMessage: "",
      afterMessage: "",
      multiSite: false,
      filterVisibleByDefault: false,
      newTraining: false,
      customRasaPipline: "",
      clearCustomPipline: false,
    };
    this.handleModelSelect = this.handleModelSelect.bind(this);
    this.getDomain = this.getDomain.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
    this.changeThreshold = this.changeThreshold.bind(this);
    this.changeFallback = this.changeFallback.bind(this);
    this.changeInactiveFlow = this.changeInactiveFlow.bind(this);
    this.changeState = this.changeState.bind(this);
    this.updateSettingsObject = this.updateSettingsObject.bind(this);
    this.changeStartFlow = this.changeStartFlow.bind(this);
    this.changeMappingFlow = this.changeMappingFlow.bind(this);
    this.addMapping = this.addMapping.bind(this);
    this.getCaseMappings = this.getCaseMappings.bind(this);
    this.changeCase = this.changeCase.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getTokenUser = this.getTokenUser.bind(this);
    this.getSites = this.getSites.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeCategory2 = this.changeCategory2.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderRowCategory = this.renderRowCategory.bind(this);
    this.changeCaseCategory = this.changeCaseCategory.bind(this);
    this.addMappingCategory = this.addMappingCategory.bind(this);
    this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
    this.updateResponseToDb = this.updateResponseToDb.bind(this);
    this.renderRowCategoryFallback = this.renderRowCategoryFallback.bind(this);
    this.changeMappingFlow2 = this.changeMappingFlow2.bind(this);
    this.addStrictCategoryFallback = this.addStrictCategoryFallback.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.valuetext = this.valuetext.bind(this);
    this.renderTextRow = this.renderTextRow.bind(this);
    this.handleDidYouMeanTextChange =
      this.handleDidYouMeanTextChange.bind(this);
    this.addDidYouMeanText = this.addDidYouMeanText.bind(this);
    this.handleDeleteDidYouMeanText =
      this.handleDeleteDidYouMeanText.bind(this);
    this.handleConfChange = this.handleConfChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.createSettings = this.createSettings.bind(this);
    this.handleTrainingCycleChange = this.handleTrainingCycleChange.bind(this);
    this.getBotConfig = this.getBotConfig.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.addAvailableFor = this.addAvailableFor.bind(this);
    this.renderRowAvailableGroup = this.renderRowAvailableGroup.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
    this.handleDeleteAvailableFor = this.handleDeleteAvailableFor.bind(this);
    this.updateBotConfig = this.updateBotConfig.bind(this);
    this.createNewBotConfig = this.createNewBotConfig.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.tableClick = this.tableClick.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.handleTemplatesChange = this.handleTemplatesChange.bind(this);
    this.handleNbrOfMessagesChange = this.handleNbrOfMessagesChange.bind(this);
    this.handleBeforeMessagesChange =
      this.handleBeforeMessagesChange.bind(this);
    this.handleAfterMessagesChange = this.handleAfterMessagesChange.bind(this);
    this.handleCheckboxSurvey = this.handleCheckboxSurvey.bind(this);
    this.saveSurveySettings = this.saveSurveySettings.bind(this);
    this.handleMultiSite = this.handleMultiSite.bind(this);
    this.handleNewTraining = this.handleNewTraining.bind(this);
    this.handelVisibleByDefault = this.handelVisibleByDefault.bind(this);
    this.postToKv = this.postToKv.bind(this);
    this.getR4Token = this.getR4Token.bind(this);
    this.handleVoiceBot = this.handleVoiceBot.bind(this);
    this.getConf = this.getConf.bind(this);
    this.handleCustomRasaPipline = this.handleCustomRasaPipline.bind(this);
    this.getModels = this.getModels.bind(this);
    this.changeModel = this.changeModel.bind(this);
  }

  changeModel = (event, value) => {
    this.setState({ model: value });
    if (value && value.Model) {
      let customRasaPipeline;

      if (
        this.state.settings &&
        this.state.settings.CustomRasaPipeline &&
        typeof this.state.settings.CustomRasaPipeline === "object" &&
        this.state.settings.CustomRasaPipeline[value.Model]
      ) {
        customRasaPipeline =
          this.state.settings.CustomRasaPipeline[value.Model];
      }
      if (customRasaPipeline) {
        this.setState({ customRasaPipline: customRasaPipeline });
      } else {
        this.setState({ customRasaPipline: "" });
      }
    }
  };

  handleCustomRasaPipline(event) {
    this.setState({ customRasaPipline: event.target.value });
  }
  async getConf() {
    const domains = await getDomains();
    this.setState({ domains: domains ? domains : null });
  }
  async getR4Token() {
    let url = "/api/getR4Token";

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.access_token) {
      this.setState({ token: data.access_token });
    }
  }

  async postToKv(name, postObject) {
    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + name;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);
    myHeaders.append("Content-Type", "application/json");

    let oToPost = this.state.updatedResponse;
    if (postObject) {
      oToPost = postObject;
    }

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: oToPost,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    const data = await response.text();
  }

  saveSurveySettings(event) {
    if (
      this.state.beforeMessage !== "" &&
      this.state.afterMessage !== "" &&
      this.state.nbrOfMessagesSurvey !== "" &&
      this.state.template.id !== ""
    ) {
      let settings = this.state.settings;
      if (settings && this.state.checkedSurvey) {
        settings.Survey = {};
        settings.Survey.surveyTemplate = this.state.template.id;
        settings.Survey.surveyMessage = this.state.beforeMessage;
        settings.Survey.surveyThankYou = this.state.afterMessage;
        settings.Survey.nbrOfVisitorMessages = this.state.nbrOfMessagesSurvey;
        this.setState({ settings: settings }, () => {
          this.updateSettingsObject();
        });
      }
    }
  }

  handleBeforeMessagesChange(event) {
    this.setState({ beforeMessage: event.target.value });
  }

  handleAfterMessagesChange(event) {
    this.setState({ afterMessage: event.target.value });
  }

  handleMultiSite(e) {
    this.setState({ multiSite: e.target.checked });
  }

  handleVoiceBot(e) {
    this.setState({ voicebot: e.target.checked });
  }

  handleNewTraining(e) {
    this.setState({ newTraining: e.target.checked });
  }
  handelVisibleByDefault(e) {
    this.setState({ filterVisibleByDefault: e.target.checked });
  }

  handleCheckboxSurvey(e) {
    this.getTemplates();
    this.setState({ checkedSurvey: e.target.checked });
  }

  handleNbrOfMessagesChange(event) {
    this.setState({ nbrOfMessagesSurvey: event.target.value });
  }

  handleTemplatesChange(event, value) {
    if (value && value.name) {
      this.setState({ template: value, templateId: value.id });
    }
  }

  async getTemplates() {
    let url = "/api/getTemplates";

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      let templates = [...data, ...this.state.templates];

      this.setState({ templates: templates });
      if (this.state.templateId) {
        templates.map((templ) => {
          if (templ.id === this.state.templateId) {
            this.setState({ template: templ });
          }
        });
      }
    }
  }

  copyUrl(event) {
    let url = this.state.bankdemoUrl;
    navigator.clipboard.writeText(url);
  }

  tableClick = (e, name) => {
    if (name && name.Id) {
      this.props.openWorkflow(name.Id);
    } else {
      this.props.openWorkflow(name);
    }

    this.props.history.push("/responseworkflow");
  };

  handleDeleteDidYouMeanText = (e, i) => {
    let newArray = this.state.didYouMeanTexts.splice(i, 1);

    this.setState({ didYouMeanTexts: this.state.didYouMeanTexts });
  };
  handleDeleteAvailableFor = (event, i) => {
    let toDelete = this.state.mappedGroups[i];

    let del = this.state.mappedGroups.splice(i, 1);
    let notMapped = this.state.notMappedGroups;
    notMapped.push(toDelete);

    this.setState({
      mappedGroups: this.state.mappedGroups,
      notMappedGroups: notMapped,
    });
  };

  changeGroup(event, value) {
    if (value && value.id) {
      this.setState({ selectedGroup: value });
    }
  }

  renderRowAvailableGroup() {
    if (this.state.mappedGroups) {
      return this.state.mappedGroups.map((object, i) => {
        //console.log(object.entity)
        return (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {object.name}
            </TableCell>
            <TableCell align="right">
              {" "}
              <InputAdornment
                onClick={(event) => this.handleDeleteAvailableFor(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  addAvailableFor(e) {
    var arr = this.state.mappedGroups;
    if (arr) {
      arr.push(this.state.selectedGroup);
      let index = -1;
      this.state.notMappedGroups.map((g, i) => {
        if (g.id === this.state.selectedGroup.id) {
          index = i;
        }
      });
      this.state.notMappedGroups.splice(index, 1);
      this.setState({
        notMappedGroups: this.state.notMappedGroups,
        selectedGroup: this.state.notMappedGroups[0],
      });

      this.setState({ mappedGroups: arr });
    }
  }

  async updateBotConfig() {
    let url = "/api/updateBotConfig";

    const response = await fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.newBotConfig),
    }).catch((error) => console.log(error));
  }

  handleTrainingCycleChange(e) {
    this.setState({ epochs: e.target.value });
  }

  handleCheckbox(e) {
    this.setState({ checked: e.target.checked });
  }

  handleConfChange = (event, newValue) => {
    this.setState({ confidenceThreshold: newValue });
  };

  addDidYouMeanText() {
    var arr = this.state.didYouMeanTexts;
    if (arr) {
      arr.push({
        text: this.state.didYouMeanText,
        model: this.state.didYouMeanTextModel,
      });

      this.setState({
        didYouMeanTexts: arr,
        didYouMeanText: "",
        didYouMeanTextModel: "",
      });
    }
  }

  handleDeleteDidYouMeanText = (e, i) => {
    this.state.didYouMeanTexts.splice(i, 1);
    this.setState({ didYouMeanTexts: this.state.didYouMeanTexts });
  };

  handleDidYouMeanTextChange(e) {
    this.setState({ didYouMeanText: e.target.value });
  }

  renderTextRow() {
    if (this.state.didYouMeanTexts) {
      return this.state.didYouMeanTexts.map((object, i) => {
        //console.log(object.entity)
        return (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {object.text}
            </TableCell>
            <TableCell component="th" scope="row">
              {object.model}
            </TableCell>

            <TableCell align="right">
              {" "}
              <InputAdornment
                onClick={(event) => this.handleDeleteDidYouMeanText(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  valuetext(value) {
    return `${value}%`;
  }

  handleRangeChange = (event, newValue) => {
    this.setState({ rangeValue: newValue });
  };

  renderRowCategoryFallback() {
    if (this.state.mappedFallbackCategoryFlows) {
      return this.state.mappedFallbackCategoryFlows.map((object, i) => {
        //console.log(object.entity)
        return (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {object.category}
            </TableCell>
            <TableCell>{object.fallbackFlow}</TableCell>
            <TableCell align="right">
              {" "}
              <InputAdornment
                onClick={(event) => this.handleDeleteFallbackCategory(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  handleDelete = (e, i) => {
    this.state.mappedCaseFlows.splice(i, 1);
    this.setState(
      { mappedCaseFlows: this.state.mappedCaseFlows },
      this.updateSettingsObject
    );
  };

  handleDeleteCategory = (e, i) => {
    this.state.mappedCategoryFlows.splice(i, 1);
    this.setState(
      { mappedCategoryFlows: this.state.mappedCategoryFlows },
      this.updateSettingsObject
    );
  };

  handleDeleteFallbackCategory = (e, i) => {
    this.state.mappedFallbackCategoryFlows.splice(i, 1);
    this.setState(
      { mappedFallbackCategoryFlows: this.state.mappedFallbackCategoryFlows },
      this.updateSettingsObject
    );
  };

  getAllCategories() {
    let categories = [];
    this.state.commonWorkflows.map((object) => {
      if (object.Category) {
        let found = false;
        categories.map((o) => {
          if (o.Id === object.Category) {
            found = true;
          }
        });

        if (!found) {
          categories.push({ Id: object.Category });
        }
      }
    });

    this.setState({ categories: categories });
  }

  async getAllCommonWorkflows() {
    let url = "/api/getAllCommonWorkflows";
    const response = await fetch(url);

    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      let workflowArray = [];
      data.map((object) => {
        let arraySplit = object.Id.split("_");
        if (arraySplit[arraySplit.length - 1]) {
          let found = false;
          workflowArray.map((o) => {
            if (o.Id === arraySplit[arraySplit.length - 1]) {
              found = true;
            }
          });

          if (!found) {
            if (arraySplit.length > 3) {
              workflowArray.push({
                Id: arraySplit[arraySplit.length - 1],
                Category: arraySplit[arraySplit.length - 2],
              });
            } else {
              workflowArray.push({ Id: arraySplit[arraySplit.length - 1] });
            }
          }
        }
      });

      //Sort the array in alphabetical order
      workflowArray.sort(function (a, b) {
        return a.Id.localeCompare(b.Id);
      });

      this.setState({ commonWorkflows: workflowArray });

      this.getAllCategories();
    }
  }

  addMappingCategory(e) {
    let newMapObj = {};
    newMapObj.caseId = this.state.currentCaseCategory.id;
    newMapObj.caseName = this.state.currentCaseCategory.name;
    newMapObj.category = this.state.category.Id;

    let caseFlows = this.state.mappedCategoryFlows;
    let found = -1;

    if (!caseFlows) {
      caseFlows = [];
    }

    caseFlows.map((obj, i) => {
      if (obj.caseId === newMapObj.caseId) {
        found = i;
      }
    });
    if (found > -1) {
      caseFlows[found] = newMapObj;
    } else {
      caseFlows.push(newMapObj);
    }

    this.setState(
      {
        mappedCategoryFlows: caseFlows,
        currentCaseCategory: { Id: "" },
        category: { Id: "" },
      },
      this.updateSettingsObject
    );
  }

  addStrictCategoryFallback(e) {
    let newMapObj = {};
    newMapObj.category = this.state.category2.Id;
    newMapObj.fallbackFlow = this.state.mappingFlow2.Id;
    let caseFlows = this.state.mappedFallbackCategoryFlows;
    let found = -1;

    if (!caseFlows) {
      caseFlows = [];
    }
    caseFlows.map((obj, i) => {
      if (obj.category === newMapObj.category) {
        found = i;
      }
    });

    if (found > -1) {
      caseFlows[found] = newMapObj;
    } else {
      caseFlows.push(newMapObj);
    }

    this.setState(
      { mappedFallbackCategoryFlows: caseFlows },
      this.updateSettingsObject
    );
  }

  addMapping(e) {
    let newMapObj = {};
    newMapObj.caseId = this.state.currentCase.id;
    newMapObj.caseName = this.state.currentCase.name;
    newMapObj.flowName = this.state.mappingFlow.Id;

    let caseFlows = this.state.mappedCaseFlows;
    let found = -1;

    if (!caseFlows) {
      caseFlows = [];
    }

    caseFlows.map((obj, i) => {
      if (obj.caseId === newMapObj.caseId) {
        found = i;
      }
    });
    if (found > -1) {
      caseFlows[found] = newMapObj;
    } else {
      caseFlows.push(newMapObj);
    }

    this.setState({ mappedCaseFlows: caseFlows }, this.updateSettingsObject);
  }

  changeFallback(event, value) {
    if (value && value.Id) {
      this.setState({ fallbackFlow: { Id: value.Id } });
    }
  }

  changeInactiveFlow(event, value) {
    if (value && value.Id) {
      this.setState({ inactiveFlow: { Id: value.Id } });
    }
  }

  async getSettings() {
    let url = "/api/getSettings";

    const response = await fetch(url);

    const data = await response.json();
    if (data.length > 0) {
      const sett = data[0].Value;

      const settings = JSON.parse(sett);

      if (settings.voicebot) {
        this.setState({ voicebot: settings.voicebot });
      }
      this.setState({
        confidenceThreshold: settings.ConfidenceThreshold * 100,
      });
      this.setState({ mappedCaseFlows: settings.CaseTypeMapping });
      this.setState({
        mappedFallbackCategoryFlows: settings.StrictCategoryMapping,
      });
      this.setState({ mappedCategoryFlows: settings.CategoryMapping });
      this.setState({ fallbackFlow: { Id: settings.FallbackFlow } });
      this.setState({ startFlow: { Id: settings.StartFlow } });
      this.setState({ inactiveFlow: { Id: settings.InactiveFlow } });
      this.setState({ multiSite: settings.MultiSite });
      this.setState({
        filterVisibleByDefault: settings.FilterVisibleByDefault,
      });
      if (
        settings.CustomRasaPipeline &&
        typeof settings.CustomRasaPipeline === "object"
      ) {
        const keys = Object.keys(settings.CustomRasaPipeline);

        if (keys && keys.length > 0) {
          this.setState({
            model: { Model: keys[0] },
            customRasaPipline: settings.CustomRasaPipeline[keys[0]],
          });
        }
      }
      this.setState({ settings: settings });
      this.setState({ newTraining: settings.newTraining });

      if (settings.Survey) {
        this.setState({ checkedSurvey: true });
        this.setState({
          beforeMessage: settings.Survey.surveyMessage,
          afterMessage: settings.Survey.surveyThankYou,
          nbrOfMessagesSurvey: settings.Survey.nbrOfVisitorMessages,
          templateId: settings.Survey.surveyTemplate,
        });

        this.getTemplates();
      }
      const models = await getSavedModels(this.props.account);
      if (settings.TrainingEpochs) {
        this.setState({ epochs: settings.TrainingEpochs });
      }
      if (settings.whatDidYoumean) {
        let newDidYouMeanTexts = [];
        settings.whatDidYoumean.didYouMeanTexts.map((obj) => {
          if (typeof obj === "object") {
            newDidYouMeanTexts.push(obj);
          } else {
            newDidYouMeanTexts.push({ text: obj, model: models[0].Model });
          }
        });

        this.setState({
          checked: settings.whatDidYoumean.activated,
          didYouMeanTexts: newDidYouMeanTexts,
          rangeValue: settings.whatDidYoumean.rangeValue,
        });
      }
    } else {
      this.createSettings();
    }
  }

  async getDomain() {
    let url = "/api/getRestDomain";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
  }

  async createSettings() {
    let url = "/api/saveSettings?account=" + this.props.account;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
  }

  async getBotConfig() {
    let url = "/api/getBotConfig";

    const response = await fetch(url);
    const data = await response.json();

    let conf = { development: JSON.parse(data.development) };
    let mappedGroups = [];
    let notMappedGroups = [];
    this.setState({ botConfig: conf });

    if (conf && conf.development) {
      this.state.groups.map((group) => {
        let found = false;

        conf.development.REST.availableFor.groups.map((id) => {
          if (group.id === id) {
            found = true;
            mappedGroups.push({ id: group.id, name: group.name });
          }
        });

        if (!found) {
          notMappedGroups.push(group);
        }
      });
      this.setState({ account: conf.development.account });
      this.setState(
        {
          bankdemoUrl:
            "https://bankdemo.vergic.com/?account=" +
            conf.development.REST.path +
            "/" +
            conf.development.account +
            "/ps.js" +
            "&query#bot",
        },
        () => {}
      );

      this.setState({
        notMappedGroups: notMappedGroups,
        mappedGroups: mappedGroups,
      });
    }
  }

  createNewBotConfig() {
    if (this.state.botConfig) {
      let config = this.state.botConfig;

      let mapped = [];
      this.state.mappedGroups.map((group) => {
        mapped.push(group.id);
      });

      this.state.botConfig.development.REST.availableFor.groups = mapped;

      this.setState({ newBotConfig: this.state.botConfig }, () => {
        this.updateBotConfig();
      });
    }
  }

  componentDidMount() {
    this.getConf();
    this.getR4Token();
    this.getDomain();

    this.getSettings();
    this.getAllCommonWorkflows();

    this.getTokenUser();
    let set = {
      ConfidenceThreshold: this.state.confidenceThreshold,
      FallbackFlow: this.state.fallbackFlow,
      Account: this.props.account,
    };
    if (this.props.account) {
      this.getModels(this.props.account);
    }
    if (this.props.accountName) {
      this.setState({ accountName: this.props.accountName });
    }

    this.setState({ settings: set });
  }

  async getModels(account) {
    const models = await getSavedModels(account);

    this.setState({ models: models });
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.roles !== this.props.roles) {
      this.setState({ roles: this.props.roles });
    }
  }

  updateSettingsObject(e) {
    this.createNewBotConfig();
    let settings = this.state.settings;

    if (this.state.clearCustomPipline) {
      delete settings.CustomRasaPipeline[this.state.model.Model];
      this.setState({ clearCustomPipline: false });
    }

    if (this.state.customRasaPipline !== "") {
      let rasaPipelines = {};
      rasaPipelines[this.state.model.Model] = this.state.customRasaPipline;
      settings.CustomRasaPipeline = rasaPipelines;
    }
    settings.whatDidYoumean = {
      activated: this.state.checked,
      didYouMeanTexts: this.state.didYouMeanTexts,
      rangeValue: this.state.rangeValue,
    };
    settings.ConfidenceThreshold = this.state.confidenceThreshold / 100;
    settings.FallbackFlow = this.state.fallbackFlow.Id;
    settings.StartFlow = this.state.startFlow.Id;
    settings.CaseTypeMapping = this.state.mappedCaseFlows;
    settings.CategoryMapping = this.state.mappedCategoryFlows;
    settings.InactiveFlow = this.state.inactiveFlow.Id;
    settings.StrictCategoryMapping = this.state.mappedFallbackCategoryFlows;
    settings.TrainingEpochs = this.state.epochs;
    settings.MultiSite = this.state.multiSite;
    settings.FilterVisibleByDefault = this.state.filterVisibleByDefault;
    if (this.state.voicebot) {
      settings.voicebot = this.state.voicebot;
    }
    settings.newTraining = this.state.newTraining;

    if (!this.state.checkedSurvey) {
      delete settings.Survey;
    }

    this.setState({ settings: settings }, this.updateSettings);
  }

  changeThreshold(e) {
    this.setState({ confidenceThreshold: e.currentTarget.value });
  }

  changeState() {
    this.setState({ notifierOpen: false });
  }

  changeCaseCategory(event, value) {
    if (value && value.name) {
      this.setState({ currentCaseCategory: value });
    }
  }

  changeCase(event, value) {
    if (value && value.name) {
      this.setState({ currentCase: value });
    }
  }

  changeStartFlow(event, value) {
    if (value && value.Id) {
      this.setState({ startFlow: { Id: value.Id } });
    }
  }

  changeMappingFlow(event, value) {
    if (value && value.Id) {
      this.setState({ mappingFlow: { Id: value.Id } });
    }
  }

  changeMappingFlow2(event, value) {
    if (value && value.Id) {
      this.setState({ mappingFlow2: { Id: value.Id } });
    }
  }

  changeCategory(event, value) {
    if (value && value.Id) {
      this.setState({ category: { Id: value.Id } });
    }
  }

  changeCategory2(event, value) {
    if (value && value.Id) {
      this.setState({ category2: { Id: value.Id } });
    }
  }

  async updateResponseToDb(id, value) {
    let url = "/api/updateStandAloneResponse?responseId=" + id;

    let response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: value,
    }).catch((error) => console.log(error));

    let data = await response.json();

    if (data && data.length === 0) {
    }
    this.postToKv(id, value);
  }

  async getSites() {
    let url = "/api/getSites";

    const response = await fetch(url);
    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      data.map((site) => {
        this.getCaseMappings(site);
        this.getGroups(site);
      });
    }
  }

  handleModelSelect = (event, value) => {
    this.setState({ didYouMeanTextModel: value });
  };

  async getGroups(site) {
    let url = "/api/getGroups?site=" + site;

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      let groups = [...data, ...this.state.groups];

      this.setState({ groups: groups }, () => {
        this.getBotConfig();
      });
    }
  }

  async getTokenUser() {
    let url = "/api/getTokenUser";

    const response = await fetch(url);
    const data = await response.json();

    if (data && data.length === 0 && data.name !== "error") {
      this.getSites();
    }
  }

  async getCaseMappings(site) {
    let url = "/api/getCases?site=" + site;

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.length > 0 && data.name !== "error") {
      let cases = [...data, ...this.state.cases];

      this.setState({ cases: cases });
    }
  }

  async updateSettings() {
    let url = "/api/updateSettings";

    const response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.settings),
    });

    const data = await response.json();

    if (data.length === 0) {
      this.setState({ notifierOpen: true });
    }
    if (data && data.name && data.name.indexOf("error") > -1) {
    }
    this.postToKv(
      this.props.account + "_account_settings",
      JSON.stringify(this.state.settings)
    );
  }

  setModel = (model) => {
    this.setState({ model: model });
    this.queryIntent();
  };

  renderRow() {
    if (this.state.mappedCaseFlows) {
      return this.state.mappedCaseFlows.map((object, i) => {
        //console.log(object.entity)
        return (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {object.caseName}
            </TableCell>
            <TableCell>
              <div
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
                onClick={(e) => this.tableClick(e, object.flowName)}
              >
                {object.flowName}
              </div>
            </TableCell>
            <TableCell align="right">
              {" "}
              <InputAdornment
                onClick={(event) => this.handleDelete(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  renderRowCategory() {
    if (this.state.mappedCategoryFlows) {
      return this.state.mappedCategoryFlows.map((object, i) => {
        //console.log(object.entity)
        return (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {object.caseName}
            </TableCell>
            <TableCell>{object.category}</TableCell>
            <TableCell align="right">
              {" "}
              <InputAdornment
                onClick={(event) => this.handleDeleteCategory(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginLeft: "0px" }}>
        <Paper
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            margin: "0px",
          }}
        >
          <h1>Settings</h1>
          <Card sx={{ maxWidth: 275 }} style={{ width: 620 }}>
            <CardContent>
              <h3>Account Information</h3>

              <div style={{ marginTop: "10px" }}>
                <b>Name:</b> {this.state.accountName}
              </div>
              <div style={{ marginTop: "10px" }}>
                <b>Id:</b> {this.state.account}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Link
                  underline="hover"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  href={this.state.bankdemoUrl}
                >
                  Bankdemo Test Page
                </Link>
                {/* <Button style={{marginLeft: "20px"}} color="primary" onClick={this.copyUrl}>Copy Url</Button>*/}
              </div>
            </CardContent>
          </Card>

          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px", marginTop: "50px" }}
          >
            <CardContent>
              <h3>Case -> Startflow Mapping</h3>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box"
                    options={this.state.cases}
                    onChange={this.changeCase}
                    value={
                      this.state.currentCase ? this.state.currentCase : null
                    }
                    getOptionSelected={(option, value) =>
                      option && value && option.value === value.value
                    }
                    getOptionLabel={(option) =>
                      option && option.name ? option.name : ""
                    }
                    style={{ minWidth: "200px", width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Case"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box4"
                    options={this.state.commonWorkflows}
                    onChange={this.changeMappingFlow}
                    value={
                      this.state.mappingFlow ? this.state.mappingFlow : null
                    }
                    getOptionSelected={(option, value) =>
                      option && value && option.value === value.value
                    }
                    getOptionLabel={(option) =>
                      option && option.Id ? option.Id : ""
                    }
                    style={{
                      minWidth: "200px",
                      width: "100%",
                      marginLeft: "20px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Dialog Start Flow"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />
                </FormControl>
              </div>

              {this.state.mappingFlow &&
              this.state.mappingFlow.Id !== "" &&
              this.state.currentCase &&
              this.state.currentCase.id !== "" ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "0px",
                    marginLeft: "0px",
                    marginBottom: "20px",
                    width: "300px",
                  }}
                  onClick={this.addMapping}
                >
                  Add Mapping
                </Button>
              ) : (
                <div></div>
              )}

              <TableContainer
                style={{
                  width: "520px",
                  marginBottom: "50px",
                  marginTop: "10px",
                }}
                component={Paper}
              >
                <Table className={classes.table} aria-label=" ">
                  <TableHead>
                    <TableRow>
                      <TableCell>Case Name</TableCell>
                      <TableCell>Start Flow</TableCell>
                      <TableCell align="right"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this.renderRow()}</TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <h3>Voicebot</h3>

              <FormGroup
                row
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        this.state.voicebot ? this.state.voicebot : false
                      }
                      onChange={this.handleVoiceBot}
                      name="Voicebot"
                    />
                  }
                  label="Only Voice Bot"
                />
              </FormGroup>
            </CardContent>
          </Card>
          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <h3>Workflow Filtering</h3>

              <FormGroup
                row
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        this.state.multiSite ? this.state.multiSite : false
                      }
                      onChange={this.handleMultiSite}
                      name="Multi Site"
                    />
                  }
                  label="Multi Site"
                />
              </FormGroup>
              <FormGroup
                row
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.filterVisibleByDefault}
                      onChange={this.handelVisibleByDefault}
                      name="Filter box visible by default"
                    />
                  }
                  label="Filter box visible by default"
                />
              </FormGroup>
            </CardContent>
          </Card>

          {window.location.href.indexOf("newTraining") > -1 && (
            <Card
              sx={{ maxWidth: 275 }}
              style={{ width: 620, marginBottom: "50px" }}
            >
              <CardContent>
                <h3>New Training</h3>

                <FormGroup
                  row
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.newTraining}
                        onChange={this.handleNewTraining}
                        name="New Training"
                      />
                    }
                    label="New Training"
                  />
                </FormGroup>
              </CardContent>
            </Card>
          )}

          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <h3>BOT Available for Groups</h3>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box"
                    options={this.state.notMappedGroups}
                    onChange={this.changeGroup}
                    value={
                      this.state.selectedGroup ? this.state.selectedGroup : null
                    }
                    getOptionSelected={(option, value) =>
                      option && value && option.value === value.value
                    }
                    getOptionLabel={(option) =>
                      option && option.name ? option.name : ""
                    }
                    style={{ minWidth: "200px", width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Group"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />
                </FormControl>
              </div>

              {this.state.selectedGroup && this.state.selectedGroup !== "" ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "0px",
                    marginLeft: "0px",
                    marginBottom: "20px",
                    width: "300px",
                  }}
                  onClick={this.addAvailableFor}
                >
                  Add Available For Group
                </Button>
              ) : (
                <div></div>
              )}

              <TableContainer
                style={{
                  width: "520px",
                  marginBottom: "50px",
                  marginTop: "10px",
                }}
                component={Paper}
              >
                <Table className={classes.table} aria-label=" ">
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell align="right"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this.renderRowAvailableGroup()}</TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <h3>Number of Training Cycles</h3>

              <TextField
                label="Training Cycles"
                style={{ width: "100%", height: "60px", maxWidth: "155px" }}
                value={this.state.epochs}
                onChange={this.handleTrainingCycleChange}
                margin="normal"
                variant="outlined"
              />
            </CardContent>
          </Card>

          {this.props.roles && this.props.roles.indexOf("Admin") > -1 && (
            <Card
              sx={{ maxWidth: 275, height: "100%" }}
              style={{ width: 620, marginBottom: "50px" }}
            >
              <CardContent>
                <h3>Custom Pipline</h3>
                <Autocomplete
                  options={this.state.models}
                  value={this.state.model ? this.state.model : null}
                  onChange={this.changeModel}
                  getOptionSelected={(option, value) =>
                    option && value && option.Model === value.Model
                  }
                  getOptionLabel={(option) => option.Model}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Models" />
                  )}
                />
                <TextField
                  label="Custom Config"
                  style={{ width: "100%", maxWidth: "600px" }}
                  value={this.state.customRasaPipline}
                  onChange={this.handleCustomRasaPipline}
                  margin="normal"
                  multiline={true}
                  minRows={15}
                  variant="outlined"
                />
                <Button
                  style={{
                    width: "100%",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    this.setState({
                      customRasaPipline: "",
                      clearCustomPipline: true,
                    })
                  }
                >
                  Clear
                </Button>
              </CardContent>
            </Card>
          )}

          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <h3>Survey</h3>

              <FormGroup
                row
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedSurvey}
                      onChange={this.handleCheckboxSurvey}
                      name="Enable Survey"
                    />
                  }
                  label="Enable Survey"
                />
              </FormGroup>
              {this.state.checkedSurvey && (
                <React.Fragment>
                  <FormControl
                    variant="outlined"
                    style={{ borderColor: "black" }}
                    className={classes.formControl}
                  >
                    <Typography
                      style={{ marginBottom: "20px" }}
                      variant="button"
                    >
                      Survey Template
                    </Typography>
                    <Autocomplete
                      id="combo-box3"
                      options={this.state.templates}
                      onChange={this.handleTemplatesChange}
                      getOptionSelected={(option, value) =>
                        option && value && option.value === value.value
                      }
                      value={this.state.template ? this.state.template : null}
                      getOptionLabel={(option) =>
                        option && option.name ? option.name : ""
                      }
                      style={{ width: "575px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Survey Template"
                          style={{ minHeight: "100px" }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ borderColor: "black", marginTop: "-20px" }}
                    className={classes.formControl}
                  >
                    <Typography variant="button">
                      Number of visitor messages before showing survey
                    </Typography>
                    <TextField
                      label="Number"
                      style={{
                        width: "100%",
                        height: "60px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                      value={this.state.nbrOfMessagesSurvey}
                      onChange={this.handleNbrOfMessagesChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ borderColor: "black" }}
                    className={classes.formControl}
                  >
                    <Typography variant="button">
                      Message before showing survey template
                    </Typography>
                    <TextField
                      label="Message before"
                      style={{
                        width: "100%",
                        height: "60px",
                        minWidth: "575px",
                      }}
                      value={this.state.beforeMessage}
                      onChange={this.handleBeforeMessagesChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ borderColor: "black" }}
                    className={classes.formControl}
                  >
                    <Typography variant="button">
                      Message after submitting Survey
                    </Typography>
                    <TextField
                      label="Message after"
                      style={{
                        width: "100%",
                        height: "60px",
                        minWidth: "575px",
                      }}
                      value={this.state.afterMessage}
                      onChange={this.handleAfterMessagesChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </FormControl>
                </React.Fragment>
              )}
              {this.state.checkedSurvey &&
                this.state.template &&
                this.state.template.id !== "" &&
                this.state.beforeMessage !== "" &&
                this.state.afterMessage !== "" &&
                this.state.nbrOfMessagesSurvey !== "" && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "10px",
                      marginLeft: "0px",
                      marginBottom: "20px",
                      width: "575px",
                    }}
                    onClick={this.saveSurveySettings}
                  >
                    Save Survey Settings
                  </Button>
                )}
            </CardContent>
          </Card>

          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <div
                style={{
                  marginLeft: "0px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "0px",
                }}
              >
                <h3 id="range-slider">Confidence Threshold</h3>
                <div
                  style={{
                    marginTop: "10px",
                    marginLeft: "0px",
                    fontWeight: 900,
                  }}
                >
                  {this.state.confidenceThreshold}%
                </div>
                <div
                  style={{
                    marginLeft: "15px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Slider
                    value={this.state.confidenceThreshold}
                    onChange={this.handleConfChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={this.valuetext}
                    style={{ maxWidth: "500px", marginTop: "20px" }}
                    marks={[
                      {
                        value: 0,
                        label: "0%",
                      },
                      {
                        value: 100,
                        label: "100%",
                      },
                    ]}
                  />
                </div>

                {/* <Input
                            className={classes.input}
                            value={this.state.confidenceThreshold}
                            margin="dense"
                            style={{width: "45px"}}
                            inputProps={{
                                step: 10,
                                min: 0,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />*/}
              </div>
            </CardContent>
          </Card>

          <Card
            sx={{ maxWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3>Fallbackflow</h3>
                <FormControl
                  variant="outlined"
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box3"
                    options={this.state.commonWorkflows}
                    onChange={this.changeFallback}
                    value={
                      this.state.fallbackFlow ? this.state.fallbackFlow : null
                    }
                    getOptionLabel={(option) =>
                      option && option.Id ? option.Id : ""
                    }
                    getOptionSelected={(option, value) =>
                      option && value && option.value === value.value
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Fallbackflow"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />
                  <Button
                    style={{
                      width: 300,
                      marginBottom: "40px",
                      marginTop: "-20px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.tableClick(e, this.state.fallbackFlow)}
                  >
                    Open Workflow
                  </Button>
                </FormControl>
              </div>
            </CardContent>
          </Card>
          {/*
                    <TextField
                        value={this.state.confidenceThreshold}
                        onChange={this.changeThreshold}
                        margin="normal"
                        variant="outlined"
                        multiline={false}
                        label="Confidence Threshold"
                        style={{marginTop: "0px", width: "300px"}}
                    />*/}
          <Card
            sx={{ minWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3>Did you mean?</h3>
                <FormControl
                  variant="outlined"
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <FormGroup row style={{ marginTop: "10px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checked}
                          onChange={this.handleCheckbox}
                          name="Enable Did You Mean?"
                        />
                      }
                      label="Enable Did You Mean?"
                    />
                  </FormGroup>
                  {this.state.checked && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <TextField
                          label="Conversation message before did you mean buttons"
                          style={{
                            width: "100%",
                            height: "60px",
                            maxWidth: "100%",
                          }}
                          value={this.state.didYouMeanText}
                          onChange={this.handleDidYouMeanTextChange}
                          margin="normal"
                          variant="outlined"
                        />
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="select-label">Model</InputLabel>
                          <Select
                            id="demo-simple-select"
                            label="Model"
                            variant="outlined"
                            labelId="select-label"
                            
                          >
                            {this.state.models.map((model, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={model.Model}
                                  onClick={(e) =>
                                    this.handleModelSelect(e, model.Model)
                                  }
                                >
                                  {model.Model}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            width: "100%",

                            marginTop: "10px",
                          }}
                          onClick={this.addDidYouMeanText}
                        >
                          Add
                        </Button>
                      </div>

                      <TableContainer
                        style={{
                          width: "100%",
                          marginBottom: "50px",
                          marginTop: "10px",
                        }}
                        component={Paper}
                      >
                        <Table className={classes.table} aria-label=" ">
                          <TableHead>
                            <TableRow>
                              <TableCell>Text</TableCell>
                              <TableCell>Model</TableCell>
                              <TableCell align="right"> </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{this.renderTextRow()}</TableBody>
                        </Table>
                      </TableContainer>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginTop: "10px",
                        }}
                      >
                        <Typography id="range-slider" gutterBottom>
                          Trigger confidence range
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ fontWeight: 900 }}>
                            {this.state.rangeValue[0]}%
                          </div>
                          <div
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            -
                          </div>
                          <div style={{ fontWeight: 900 }}>
                            {this.state.rangeValue[1]}%
                          </div>
                        </div>

                        <Slider
                          value={this.state.rangeValue}
                          onChange={this.handleRangeChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          getAriaValueText={this.valuetext}
                          style={{
                            maxWidth: "500px",
                            marginTop: "30px",
                            marginLeft: "10px",
                          }}
                          marks={[
                            {
                              value: 0,
                              label: "0%",
                            },
                            {
                              value: 100,
                              label: "100%",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </FormControl>
              </div>
            </CardContent>
          </Card>
          <Card
            sx={{ minWidth: 275 }}
            style={{ width: 620, marginBottom: "50px" }}
          >
            <CardContent>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3>Inactive Flow</h3>
                <FormControl
                  variant="outlined"
                  style={{ borderColor: "black" }}
                  className={classes.formControl}
                >
                  <Autocomplete
                    id="combo-box4"
                    options={this.state.commonWorkflows}
                    onChange={this.changeInactiveFlow}
                    value={
                      this.state.inactiveFlow ? this.state.inactiveFlow : null
                    }
                    getOptionLabel={(option) =>
                      option && option.Id ? option.Id : ""
                    }
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Inactive Flow"
                        style={{ minHeight: "100px" }}
                      />
                    )}
                  />

                  <Button
                    style={{
                      width: 300,
                      marginBottom: "40px",
                      marginTop: "-20px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.tableClick(e, this.state.inactiveFlow)}
                  >
                    Open Workflow
                  </Button>
                </FormControl>
              </div>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "10px",
              marginLeft: "0px",
              marginBottom: "10px",
              width: "620px",
              background: "green",
            }}
            onClick={this.updateSettingsObject}
          >
            Update Settings
          </Button>
        </Paper>

        <Notifier
          changeState={this.changeState}
          open={this.state.notifierOpen}
          message="Settings Updated Successfully"
        />
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
