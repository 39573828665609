import React, { Component } from "react";
import Button from "@material-ui/core/Button/Button";
import {
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { getDomains } from "./reused/reusedfunctions.js";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  NameCell: {
    fontWeight: 900,
    textDecoration: "underline",
    cursor: "pointer",
  },
  dateFormatInput: {
    height: "80px",
    root: {
      height: "80px",
      input: {
        height: "10px",
      },
    },
    input: {
      height: "10px",
    },
  },
  input: {
    height: "45px",
  },
  label: { fontSize: "40px" },
});

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        height: "35px",
        lineHeight: "0.9em",
      },
    },
  },
});

class ConvertData extends Component {
  constructor() {
    super();
    this.state = {
      title: "Create Token",
      confidenceThreshold: 0,
      sessionId: "",
      userId: "",
      domain: "https://subdomain.psplugin.com",
      trainingData: [],
      modelIntents: [],
    };

    this.handleSessionChange = this.handleSessionChange.bind(this);
    this.handleUserIdChange = this.handleUserIdChange.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.processFile = this.processFile.bind(this);
    this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
    this.postToKv = this.postToKv.bind(this);
    this.getR4Token = this.getR4Token.bind(this);
    this.getAllIntents = this.getAllIntents.bind(this);
    this.getSavedModels = this.getSavedModels.bind(this);
    this.processIntents = this.processIntents.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.saveIntents = this.saveIntents.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.processIntentWorkflow = this.processIntentWorkflow.bind(this);
    this.convertAllToCsv = this.convertAllToCsv.bind(this);
    this.copySettings = this.copySettings.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.getConf = this.getConf.bind(this);
  }

  async getConf() {
    const domains = await getDomains();
    this.setState({ domains: domains ? domains : null });
  }

  componentDidMount() {
    this.getConf();
    this.getR4Token();
    this.getSettings();
  }

  async getSettings() {
    let url = "/api/getSettings";

    const response = await fetch(url);

    const data = await response.json();
    let settings = JSON.parse(data[0].Value);
    this.setState({ settings: settings });
  }

  async copySettings(event) {
    const settingsName = this.props.account + "_account_settings";
    this.postToKv(settingsName, this.state.settings);
    this.postToExtraDb(settingsName, this.state.settings);
  }

  convertAllToCsv(event) {
    this.getAllCommonWorkflows(event, false);
  }

  async getSavedModels(event, convert) {
    if (convert) {
      this.setState({ convert: true });
    }
    let url = "/api/getSavedModels";
    const response = await fetch(url);
    const data = await response.json();
    let modelArray = [];
    if (data && data.length > 0 && data.name !== "error") {
      data.map((model) => {
        modelArray.push(model.Model);
      });
      this.setState({ modelArray: modelArray }, () => {
        this.processIntents();
      });
    }
  }

  async getResponse(intent, model) {
    let name = this.props.account + "_" + model + "_" + intent;
    let url =
      "/api/response?intent=" +
      intent +
      "&model=" +
      this.props.account +
      "_" +
      model +
      "_" +
      intent;

    const response = await fetch(url);

    const data = await response.json();
    let workflowArray = this.state.workflowArray
      ? this.state.workflowArray
      : [];
    if (data && data.length > 0) {
      const object = data[0];
      if (object.Response) {
        const resp = JSON.parse(object.Response);
        const workflow = resp.workflow;
        let responseObj;
        if (resp.buttonText) {
          responseObj = {
            intent: intent,
            metaData: { buttonText: resp.buttonText, workflow: workflow },
          };
        } else {
          responseObj = { intent: intent, metaData: { workflow: workflow } };
        }

        workflowArray.push(responseObj);

        if (this.state.convert) {
          this.postToKv(name, responseObj);
          // this.postToExtraDb(name, responseObj);
        }

        this.setState({ workflowArray: workflowArray });
      }
    }
  }

  processIntentWorkflow() {
    let csvString = this.state.csvString;
    this.state.workflowArray.map((obj) => {
      let name = obj.intent;
      let workflow = JSON.parse(obj.metaData.workflow);
      workflow.map((action) => {
        if (action.type === "conversation.message") {
          csvString +=
            action.type + ';"' + action.message + '";"";' + name + "\n";
        }

        if (action.type === "website.options") {
          const options = action.options;
          let optionMess = "";
          options.map((option, i) => {
            optionMess += i + 1 + ". " + option.text + "\n";
          });
          csvString += action.type + ';"' + optionMess + '";"";' + name + "\n";
        }
      });
    });
    this.handleExport(csvString);
  }

  processIntents() {
    const promises = [];
    this.state.modelArray.map((name) => {
      promises.push(this.getAllIntents(name));
    });

    Promise.all(promises).then(() => {
      const promises2 = [];
      this.state.modelIntents.map((intent) => {
        let intentArr = intent.split("##");
        promises2.push(this.getResponse(intentArr[0], intentArr[1]));
      });
      this.saveIntents(promises2);
    });
  }

  saveIntents(promises) {
    Promise.all(promises).then(() => {
      if (!this.state.convert) {
        this.processIntentWorkflow();
      }
      this.setState({ convert: false });
    });
  }

  async getAllIntents(model) {
    let url = "/api/trainingDataJson?model=" + model;
    const response = await fetch(url);

    const data = await response.json();

    if (data) {
      this.setState({ firstQuery: data });
      let intents = [];
      if (data && data.rasa_nlu_data) {
        data.rasa_nlu_data.common_examples.map((intent) => {
          intents[intent.intent + "##" + model] = intent.intent;
        });

        //this.setState({trainingData: [...this.state.trainingData, ...data.rasa_nlu_data.common_examples]});
        let keys = Object.keys(intents);

        this.setState({ modelIntents: [...this.state.modelIntents, ...keys] });
      }
    }
  }

  async getAllCommonWorkflows(event, convert) {
    let url = "api/getAllCommonWorkflowsAllInfo";
    const response = await fetch(url);
    const data = await response.json();
    let csvString = "Type;Text;Workflow Name;Intent Response\n";
    data.map((row) => {
      let val = JSON.parse(row.Value);
      let name = row.Id;

      if (convert) {
        this.postToKv(name, val);
        //this.postToExtraDb(name, val);
      } else {
        let nameArray = name.split("_");
        name = nameArray[nameArray.length - 1];
        val.map((action) => {
          if (action.type === "conversation.message") {
            csvString +=
              action.type + ';"' + action.message + '";' + name + "\n";
          }

          if (action.type === "website.options") {
            const options = action.options;
            let optionMess = "";
            options.map((option, i) => {
              optionMess += i + 1 + ". " + option.text + "\n";
            });
            csvString += action.type + ';"' + optionMess + '";' + name + "\n";
          }
        });
      }
    });
    if (!convert) {
      this.setState({ csvString: csvString }, () => {
        this.getSavedModels();
      });
    }
  }

  handleExport(csvString) {
    let csvObject = "\uFEFF";

    csvObject = csvObject + csvString;
    let dateString = new Date().toLocaleDateString();
    let timeString = new Date().toLocaleTimeString();
    let filename = dateString + "_" + timeString + "_workflowExport.csv";
    var FileSaver = require("file-saver");
    var blob = new Blob([csvObject], { type: "text/plain;charset=utf-8" });
    this.setState({ convert: false });
    FileSaver.saveAs(blob, filename);
  }

  async getR4Token() {
    let url = "/api/getR4Token";

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.access_token) {
      this.setState({ token: data.access_token });
    }
  }

  /* async postToExtraDb(name, value) {


        let url = '/api/saveResponseNewDb?id=' + name;
        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(value)
        });

        const data = await response;


    }*/

  async postToKv(name, value) {
    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + name;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(value),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    const data = await response.text();
  }

  processFile = (content) => {
    let imported = content.split("\n");
    imported.map((row) => {
      let rowSplit = row.split(",");
      if (rowSplit[1]) {
        try {
          //            let array = Array.from(rowSplit[1]);
        } catch (e) {}
      }
    });
  };

  handleSessionChange(event) {
    this.setState({ sessionId: event.target.value });
  }

  handleUserIdChange(event) {
    this.setState({ userId: event.target.value });
  }

  handleDomainChange(event) {
    this.setState({ domain: event.target.value });
  }


  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          flexDirection: "column",
          width: "600px",
        }}
      >
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          onClick={(event) => this.getAllCommonWorkflows(event, true)}
        >
          Copy Workflows to new db
        </Button>

        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          onClick={(event) => this.getSavedModels(event, true)}
        >
          Copy intent response to new db
        </Button>

        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          onClick={this.convertAllToCsv}
        >
          Export all text from Workflows to CSV
        </Button>

        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          onClick={this.copySettings}
        >
          Copy Settings
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ConvertData);
