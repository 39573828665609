import React, {Component} from "react";
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import {Button, ListItem, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import '../App.css';
import Paper from "@material-ui/core/Paper";
import ChipInput from 'material-ui-chip-input'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IntentMatching from "./IntentMatching";
import ModifyTrainingData from "./ModifyTrainingData";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Notifier from "./Notifier";
import Settings from "@material-ui/icons/Settings";
import PersonIcon from '@material-ui/icons/Person';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChatIcon from '@material-ui/icons/Chat';
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MUIDataTable from "mui-datatables";
import {Chart} from "react-google-charts";
import PollIcon from '@material-ui/icons/Poll';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';

let interval = null;
let optionsIds = [];

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },
    listItemTextSelected2: {

        "&.Mui-selected": {
            backgroundColor: "#cccccc",
            color: "black"
        },
        fontSize: "12px"

    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {
        height: "80px",
        root: {
            height: "80px",
            input: {

                height: "10px"
            }
        },
        input: {

            height: "10px"
        }

    },
    input: {
        height: "45px"
    },
    label: {fontSize: "40px"},


});

const theme = createTheme({
    overrides: {

        MuiInputBase: {

            root: {
                height: "35px",
                lineHeight: "0.9em",

            }
        }


    },
});


const pieOptions = {
    title: "",
    pieHole: 0.4,
    slices: [
        {
            color: "#21A850"
        },
        {
            color: "#77B948"
        },
        {
            color: "#ED6E13"
        },
        {
            color: "#E62F32"
        }
    ],
    legend: {
        position: "bottom",
        alignment: "center",
        textStyle: {
            color: "233238",
            fontSize: 10
        }
    },
    tooltip: {
        showColorCode: true
    },
    chartArea: {
        left: 0,
        top: 0,
        width: "100%",
        height: "80%"
    }

};


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class Dialogs extends Component {


    constructor() {
        super();
        this.state = {
            value: 0,
            includeSurvey: false,
            chartData: [],
            title: 'Dialogs',
            dialogs: [],
            selected: 0,
            expanded: 'panel1',
            deviceTypeArray: [],
            expandedFilter: false,
            expandedPeriod: true,
            expandedStats: true,
            expandedSurvey: false,
            userMessageWidth: "400px",
            selectedDialog: [],
            optionsIds: [],
            initialLoad: true,
            yourChips: [],
            closureObj: "",
            groups: [],
            checked: true,
            fromDate: new Date(moment().subtract(1, "days").set({"hour": 0, "minute": 0})),
            toDate: new Date(moment().add(1, "days").set({"hour": 23, "minute": 59})),
            relativeToDate: new Date(moment().add(0, "days").set({"hour": 23, "minute": 59})),
            notifierOpen: false,
            updatedModelAvailable: false,
            cases: [],
            caseFilterArray: [],
            checkedEmpty: true,
            solutions: [],
            opportunities: [],
            newlySaved: false
        }

        this.divRef = React.createRef();

        this.getAllDialogs = this.getAllDialogs.bind(this);
        this.getDialog = this.getDialog.bind(this);
        this.listRow = this.listRow.bind(this);
        this.listFilterRow = this.listFilterRow.bind(this);
        this.reactOnListClick = this.reactOnListClick.bind(this);
        this.createDialog = this.createDialog.bind(this);
        this.getAllDeviceInfo = this.getAllDeviceInfo.bind(this);
        this.getTokenUser = this.getTokenUser.bind(this);
        this.showActive = this.showActive.bind(this);
        this.getActiveDialogs = this.getActiveDialogs.bind(this);
        this.stopUpdate = this.stopUpdate.bind(this);
        this.scrollDiv = this.scrollDiv.bind(this);
        this.showClosed = this.showClosed.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.filterArray = this.filterArray.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
        this.getConversations = this.getConversations.bind(this);
        this.getIntentMatching = this.getIntentMatching.bind(this);
        this.getTrainingModel = this.getTrainingModel.bind(this);
        this.getAllIntents = this.getAllIntents.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.getSites = this.getSites.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.changeIntent = this.changeIntent.bind(this);
        this.changeState = this.changeState.bind(this);
        this.getCaseTypeName = this.getCaseTypeName.bind(this);
        this.getCaseMappings = this.getCaseMappings.bind(this);
        this.getAllDialogsWithConversation = this.getAllDialogsWithConversation.bind(this);
        this.getOnlyTransferConversations = this.getOnlyTransferConversations.bind(this);
        this.getParticipantsInfo = this.getParticipantsInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleCaseTypeChange = this.handleCaseTypeChange.bind(this);
        this.reactOnFilterClick = this.reactOnFilterClick.bind(this);
        this.handleCaseIdToOpen = this.handleCaseIdToOpen.bind(this);
        this.openCaseId = this.openCaseId.bind(this);
        this.handleEmptyDialogsCheckbox = this.handleEmptyDialogsCheckbox.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.handleStatsChange = this.handleStatsChange.bind(this);
        this.handleSurveyChange = this.handleSurveyChange.bind(this);

        this.handleTabChange = this.handleTabChange.bind(this);
        this.getConversationStats = this.getConversationStats.bind(this);
        this.getOpportunities = this.getOpportunities.bind(this);
        this.getSolutions = this.getSolutions.bind(this);
        this.getOppName = this.getOppName.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.showRelativeToDate = this.showRelativeToDate.bind(this);
        this.onChangeRelativeToDate = this.onChangeRelativeToDate.bind(this);
        this.getDialogOptions = this.getDialogOptions.bind(this);
        this.resetSaveUpdated = this.resetSaveUpdated.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.createSurveyData = this.createSurveyData.bind(this);
        this.showAllConversationsWithThatRating = this.showAllConversationsWithThatRating.bind(this);
        this.createSurveyStats = this.createSurveyStats.bind(this);

    }

    showAllConversationsWithThatRating(rating) {


        if (rating === "Thumbs Up") {
            rating = 90;

        } else if (rating === "Thumbs Down") {

            rating = 10;
        }

        let dialogArray = this.state.surveyStats[eval(rating / 100) + "Ids"];


        let dialogs = [];
        dialogArray.map((dialog) => {

            let dialogCreatedAt = moment(dialog.data.createdAt).format("MMM DD | HH:mm")
            let obj = {"id": dialog.data.id, "createdAt": dialogCreatedAt};
            dialogs.push(obj);

        });
        //console.log(dialogs[0].id);
        //Load the first dialog in the list
        this.getDialog(dialogs[0].id);
        this.setState({dialogs: dialogs})
    }

    createSurveyData(surveyData) {
        const columns = ['Rating', 'Count'];

        let rows = [];

        let surveyKeys = Object.keys(surveyData);
        surveyKeys.sort();
        surveyKeys.reverse();
        let thumbs = false;

        if ((surveyKeys.length === 4 || surveyKeys.length === 6) && surveyKeys.indexOf("0.1") > -1 && surveyKeys.indexOf("1") > -1) {

            thumbs = true;
        }

        surveyKeys.map((key) => {

            if (key.indexOf("Ids") === -1) {
                let name = eval(key) * 100;
                if (thumbs) {
                    if (name === 100) {
                        name = "Thumbs Up";
                    } else if (name === 90) {
                        name = "Thumbs Up";
                    } else {
                        name = "Thumbs Down";
                    }

                }


                rows.push([name.toString(), surveyData[key]]);
            }


        });

        if (thumbs) {
            let newThumbs = 0;
            let newRow = [];
            rows.map((o) => {
                if (o[0] === "Thumbs Up") {
                    newThumbs += o[1];
                } else {
                    newRow.push(['Thumbs Down', o[1]]);
                }
            });
            newRow.push(['Thumbs Up', newThumbs]);
            rows = newRow;
        }

        var array = [columns, ...rows];


        this.setState({surveyData: array});

    }

    resetSaveUpdated() {

        this.setState({saveUpdatedModel: false, updatedModelAvailable: false, intentIndexArray: []});

    }

    async getSettings() {


        let url = '/api/getSettings';

        const response = await fetch(url);

        const data = await response.json();
        if (data.length > 0) {

            const sett = data[0].Value;

            const settings = JSON.parse(sett);

            this.setState({confidenceThreshold: settings.ConfidenceThreshold * 100});
            this.setState({mappedCaseFlows: settings.CaseTypeMapping});
            this.setState({mappedFallbackCategoryFlows: settings.StrictCategoryMapping});
            this.setState({mappedCategoryFlows: settings.CategoryMapping});
            this.setState({fallbackFlow: {"Id": settings.FallbackFlow}});
            this.setState({startFlow: {"Id": settings.StartFlow}});
            this.setState({inactiveFlow: {"Id": settings.InactiveFlow}});

            if (settings.whatDidYoumean) {
                this.setState({
                    whatDidYoumeanActivated: settings.whatDidYoumean.activated,
                    didYouMeanTexts: settings.whatDidYoumean.didYouMeanTexts,
                    rangeValue: settings.whatDidYoumean.rangeValue
                });
            }

        }
    }

    showRelativeToDate(event) {


        this.setState({showRelativeToDate: this.state.showRelativeToDate ? false : true});

    }

    getConversationStats(event) {
        let fDate = new Date();
        if (this.props.model.indexOf("esci") > -1 || this.props.model.indexOf("ecsi") > -1) {
            fDate = moment(this.state.relativeToDate).subtract(1, "days");
            this.setState({buttonName: "One day"})
        } else {

            fDate = moment(this.state.relativeToDate).subtract(3, "days");
            this.setState({buttonName: "Three days"})
        }
        let fromDate = moment(fDate).format("YYYY-MM-DD HH:mm");

        let toDate = moment(this.state.relativeToDate).subtract(0, "days").format("YYYY-MM-DD HH:mm");


        let numberOfLoops = 10000 / 500;
        this.setState({numberOfLoops: numberOfLoops});
        for (let i = 0; i < numberOfLoops; i++) {
            if (i === 0) {
                this.setState({dialogs: [], botTransferredDialogs: []});
            }
            this.getAllDialogsWithConversation(i, 2, fromDate, toDate);
        }


    }


    handleTabChange = (event, value) => {

        this.setState({value});
    }

    handleEmptyDialogsCheckbox(e) {
        this.setState({checkedEmpty: e.target.checked});

    }

    openCaseId(event) {
        if (this.state.caseIdToOpen && this.state.caseIdToOpen !== "") {
            this.setState({loading: true, intentIndexArray: [], updatedModel: false});
            clearInterval(interval);

            this.setState({reload: false});


            this.setState({
                closure: null,
                deviceInfoBrowser: null,
                selectedCaseId: this.state.caseIdToOpen,
                claimObject: null,
                selected: null
            }, () => {
                this.getDialog(this.state.caseIdToOpen);
            });
        }

    }

    handleCaseIdToOpen(event) {

        this.setState({"caseIdToOpen": event.target.value});

    }

    handleCaseTypeChange(event) {

        if (event.target.value.indexOf("uncheckAll") > -1) {

            this.setState({caseFilterArray: []});
        } else {

            this.setState({caseFilterArray: event.target.value});
        }


    }


    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        if (response.status === 302) {

            window.location.reload();

        }

        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }


    handleChange(panelId) {
        this.setState({expanded: panelId});
    }


    handleFilterChange(event) {

        if (this.state.expandedFilter) {

            this.setState({expandedFilter: false});
        } else {

            this.setState({expandedFilter: true});
        }

    }

    handleSurveyChange(event) {

        if (this.state.expandedSurvey) {

            this.setState({expandedSurvey: false});
        } else {

            this.setState({expandedSurvey: true});
        }


    }

    handleStatsChange(event) {

        if (this.state.expandedStats) {

            this.setState({expandedStats: false});
        } else {

            this.setState({expandedStats: true});
        }


    }

    handlePeriodChange(event) {
        if (!event.target.innerText || event.target.innerText === "Period") {
            if (this.state.expandedPeriod) {

                this.setState({expandedPeriod: false});
            } else {

                this.setState({expandedPeriod: true});
            }
        }
    }


    changeState() {

        this.setState({notifierOpen: false});
    }

    changeIntent = (newIntent, oldIntent, queryValue, i) => {

        let intentIndexArray = this.state.intentIndexArray ? this.state.intentIndexArray : [];

        if (newIntent && newIntent.value && oldIntent && queryValue) {
            intentIndexArray[i] = {"newIntent": newIntent.value, "oldIntent": oldIntent, "queryValue": queryValue};
        }

        if (Object.keys(intentIndexArray).length > 1) {
            this.setState({updatedModel: true});
        }

        this.setState({intentIndexArray: intentIndexArray, updatedModelAvailable: true});


    }

    handleCheckbox(e) {

        this.setState({checked: e.target.checked});

    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            data.map((site) => {

                this.getGroups(site);
                this.getCaseMappings(site);
                this.getSolutions(site);
                this.getOpportunities(site);
            });
            let cases = this.state.cases;
            cases.push({id: '00000000-0000-0000-0000-000000000000', 'name': 'Empty'})
            this.setState({cases: cases, caseFilterArray: cases});


        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            let botgroups = this.state.botgroups ? this.state.botgroups : [];

            groups.map((o, i) => {

                if (o.name.toLowerCase().indexOf("facebook") > -1) {

                    this.setState({facebookGroup: o.id});
                } else if (o.name.toLowerCase().indexOf("bot") > -1) {
                    botgroups.push(o.id);
                }


            });
            this.setState({botgroups: botgroups});

            this.setState({groups: groups}, () => {
                if (!this.state.getInitialDialogsOnce) {

                    let numberOfLoops = 3000 / 500;
                    this.setState({numberOfLoops: numberOfLoops});
                    for (let i = 0; i < numberOfLoops; i++) {
                        this.getAllDialogs(i, "2", this.state.fromDateFormatted, this.state.toDateFormatted, false, true);
                    }


                }
            });


        }

    }


    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            data.map((d, i) => {

                if (i === 0) {

                    first = d;
                }

            })

            this.setState({savedModels: data, model: first});

            let pipeLine = [];
            let reducedPipline = [];
            data.map((obj) => {

                if (!pipeLine.includes(obj.Pipeline)) {

                    pipeLine.push(obj.Pipeline);
                }

            });

            pipeLine.map((object) => {
                reducedPipline.push({"Pipeline": object});
            });
            reducedPipline.push({"Pipeline": "da"});
            this.setState({savedPipelines: reducedPipline});
        }
        if (data) {
            data.map((object) => {

                this.getTrainingModel(object.Model)

            });
        }

    }


    async getTrainingModel(modelname) {

        if (modelname) {

            let url = '/api/trainingDataJson?model=' + modelname;
            const response = await fetch(url);
            const data = await response.json();

            if (data) {
                this.getAllIntents(data, modelname);
            }


        }

    }

    getAllIntents(data, modelname) {
        let intentArray = [];
        data.rasa_nlu_data.common_examples.map((object, i) => {

            // intentArray[modelname+" : "+object.intent] = object.intent;
            intentArray[object.intent] = object.intent;
        });
        let keys = Object.keys(intentArray);
        if (this.state.allIntents) {
            keys = [...this.state.allIntents, ...keys];
        }
        this.setState({allIntents: keys});


    }

    async getConversations() {
        let numberOfLoops = 15000 / 500;
        this.setState({numberOfLoops: numberOfLoops});
        for (let i = 0; i < numberOfLoops; i++) {

            if (this.state.breakLoop) {
                this.setState({loadingDialogs: false});
                this.setState({breakLoop: false});

                break;
            }
            if (i === 0) {
                this.setState({dialogs: [], botTransferredDialogs: [], dialogsWithEmpty: [], surveyStats: {}});
            }
            const dialogs = await this.getAllDialogs(i, "2", this.state.fromDateFormatted, this.state.toDateFormatted, false);
        }


        // let days = Math.round(moment.duration(moment(this.state.toDate).diff(moment(this.state.fromDate))).asDays());

        //console.log(days);
    }


    async getOnlyTransferConversations() {
        let numberOfLoops = 12000 / 500;
        this.setState({numberOfLoops: numberOfLoops});
        for (let i = 0; i < numberOfLoops; i++) {

            if (this.state.breakLoop) {
                this.setState({loadingDialogs: false});
                this.setState({breakLoop: false});

                this.setState({botTransferredDialogs: null});
                break;
            }

            if (i === 0) {
                this.setState({dialogs: [], botTransferredDialogs: []});
            }
            const transfers = await this.getAllDialogs(i, "2", this.state.fromDateFormatted, this.state.toDateFormatted, true);
        }
    }


    onChangeFromDate = (date) => {

        this.setState({fromDate: date, fromDateFormatted: moment(date).format("YYYY-MM-DD HH:mm")});
    }


    onChangeToDate = (date) => {

        this.setState({toDate: date, toDateFormatted: moment(date).format("YYYY-MM-DD HH:mm")});
    }

    onChangeRelativeToDate = (date) => {

        this.setState({relativeToDate: date, relativeToDateToDateFormatted: moment(date).format("YYYY-MM-DD HH:mm")});
    }


    filterArray(array, filters) {
        const filterKeys = filters;

        return array.filter(item => {
            // validates all filter criteria
            return filterKeys.every(key => {

                return item.createdAt.toLowerCase().indexOf(key.toLowerCase()) >= 0;


            });
        });
    }

    handleAddChip = (chip) => {

        let chips = this.state.yourChips;
        chips.push(chip);

        this.setState({yourChips: chips})

    }

    handleDeleteChip = (chip, index) => {


        let chips = this.state.yourChips;

        chips.splice(index, 1);

        this.setState({yourChips: chips});
    }

    showClosed(e) {
        clearInterval(interval);
        this.setState({reload: false})
        let fDate = new Date(moment().subtract(3, "days"));
        let fromDate = moment(fDate).format("YYYY-MM-DD HH:mm");


        let date = new Date();
        let toDate = moment(date).format("YYYY-MM-DD HH:mm");

        this.getAllDialogs(5, "2", fromDate, toDate);
    }

    showActive(e) {
        clearInterval(interval);
        this.setState({reload: false})
        this.setState({selectedDialog: []});
        this.getActiveDialogs();
    }

    stopUpdate(e) {

        clearInterval(interval);
        this.setState({reload: false})
    }

    reactOnFilterClick = (object, i) => {
        // console.log(object, i)

    }
    reactOnListClick = (object, i) => {
        this.setState({loading: true, intentIndexArray: [], updatedModel: false});
        clearInterval(interval);
        if (object.active) {

            this.setState({selectedDialog: []});
            this.setState({reload: true, dialogEnd: ""});
            this.getDialog(object.id);
            interval = setInterval(() => {
                this.getDialog(object.id);
            }, 10000);

        } else {
            this.setState({reload: false});
            this.getDialog(object.id);
        }


        this.setState({
            satisfactionScore: null,
            closure: null,
            selected: i,
            deviceInfoOs: null,
            deviceInfoBrowser: null,
            selectedCaseId: object.id,
            claimObject: null
        });


    }


    async getOpportunities(site) {


        let url = '/api/getOpportunities?siteId=' + site;

        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0 && data.name !== 'error') {


            let opportunities = [...data, ...this.state.opportunities];
            this.setState({opportunities: opportunities});

        }

    }


    getOppName(id) {

        this.state.opportunities.map((opp) => {

            if (opp.id === id) {

                return opp.name;
            }

        });

    }

    async getSolutions(site) {


        let url = '/api/getSolutions?siteId=' + site;

        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0 && data.name !== 'error') {


            let solutions = [...data, ...this.state.solutions];
            this.setState({solutions: solutions});
        }


    }

    componentDidMount() {
        this.getSettings();
        this.getSavedModels();
        if (this.props.model.indexOf("esci") > -1 || this.props.model.indexOf("ecsi") > -1) {

            this.setState({buttonName: "One day"})
        } else {


            this.setState({buttonName: "Three days"})
        }

        if (this.props.caseId) {

            this.setState({openExternalCaseId: true, caseIdToOpen: this.props.caseId}, () => {
                this.openCaseId(null);
            });


        }
        /*if (this.props.model) {

            this.setState({model: this.props.model}, () => {
            });

        }*/

        this.setState({fromDateFormatted: moment(this.state.fromDate).format("YYYY-MM-DD HH:mm")});
        this.setState({toDateFormatted: moment(this.state.toDate).format("YYYY-MM-DD HH:mm")});
        //this.getSites();
        this.getTokenUser();

        this.getTrainingModel();


    }

    getAllDeviceInfo() {

        if (this.state.selectedDialog && this.state.selectedDialog.conversations) {
            if (this.state.selectedDialog && this.state.selectedDialog.closure && this.state.selectedDialog.closure.name) {
                this.setState({closureObj: this.state.selectedDialog.closure.name});

            }
            let dialogStartSet = false;
            let dialogCloseSet = false;
            let dialog = this.state.selectedDialog;
            dialog.conversations.map((object) => {

                object.messages.map((mess) => {

                    if (mess.messageType === "metadataExchange" && mess.speaker.visitId) {
                        try {
                            let context = JSON.parse(mess.metadataBag);


                            if (context && context.deviceType && context.deviceType.os) {

                                this.setState({deviceInfoOs: context.deviceType.os});
                                this.setState({deviceInfoBrowser: context.deviceType.browser});
                            }

                        } catch (error) {
                            console.log(error)
                        }

                    }
                });
                let firstLeft = false;
                let nbrOfAgents = 0;
                let agentPickup, userLeft, botTransfer, groupId;
                let botClose = false;
                let systemClose = false;

                object.events.map((event) => {
                    if (event.type === "associatedWithGroup") {
                        groupId = event.groupId;

                    }
                    if (event.type === "participantJoined") {
                        if (event.participant && event.participant.userId && !dialogStartSet) {
                            this.setState({dialogStart: event.createdAt});
                            this.setState({userId: event.participant.userId})
                            dialogStartSet = true;
                            nbrOfAgents++;

                        } else if (event.participant && event.participant.userId && nbrOfAgents > 0) {

                            object.messages.push({
                                "message": "Agent picked up the conversation from queue",
                                "messageType": "chat",
                                "createdAt": event.createdAt,
                                "speaker": {"systemId": "systemInformation"}
                            });


                            agentPickup = event.createdAt;


                        }


                    } else if (event.type === "participantLeft" || event.type === "conversationClosed") {

                        if (this.state.botgroups && this.state.botgroups.indexOf(groupId) > -1) {

                            botClose = true;

                        }

                        if (event.type === "participantLeft") {

                            if (event.participant && event.participant.visitId) {


                                this.setState({visitorLeft: {"time": event.createdAt, "reason": event.reason}})

                                if (object.messages && object.messages.length > 0 && object.messages[object.messages.length - 1].message && object.messages[object.messages.length - 1].message.indexOf("Visitor left") > -1) {
                                    object.messages.pop();

                                }

                                if (object.messages && object.messages.length > 0 && object.messages[object.messages.length - 1].message && object.messages[object.messages.length - 1].message.indexOf("BOT Agent") === -1) {
                                    object.messages.push({
                                        "message": "Visitor left the conversation",
                                        "messageType": "chat",
                                        "createdAt": event.createdAt,
                                        "speaker": {"systemId": "systemInformation"}
                                    });
                                }
                            } else if (event.participant && event.participant.userId) {

                                if (dialogCloseSet && this.state.botgroups && this.state.botgroups.indexOf(groupId) === -1) {

                                    object.messages.push({
                                        "message": "Agent closed the conversation",
                                        "messageType": "chat",
                                        "createdAt": event.createdAt,
                                        "speaker": {"systemId": "systemInformation"}
                                    });

                                }

                                /*if (event.reason === "actively") {
                                    if (this.state.botgroups.indexOf(groupId) > -1) {

                                        botClose = true;
                                        console.log("here", systemClose, botClose)
                                    }
                                }*/

                                if (!firstLeft) {
                                    firstLeft = true;

                                    botTransfer = {
                                        "message": "BOT transferred the conversation to queue",
                                        "messageType": "chat",
                                        "createdAt": event.createdAt,
                                        "speaker": {"systemId": "systemInformation"}
                                    };
                                    userLeft = event.createdAt;
                                    this.setState({userLeft: {"time": event.createdAt, "reason": event.reason}})
                                }
                            }

                        }


                        if (!dialogCloseSet) {
                            this.setState({dialogEnd: event.createdAt});
                        }


                        if (event.type === "conversationClosed") {

                            dialogCloseSet = true;
                        }

                        if (event.type === "conversationClosed" && !systemClose && botClose) {

                            object.messages.pop();
                            object.messages.push({
                                "message": "BOT Agent closed the conversation",
                                "messageType": "chat",
                                "createdAt": event.createdAt,
                                "speaker": {"systemId": "systemInformation"}
                            });

                            botClose = false;
                            systemClose = true;
                        }

                    } else if (event.type === "participantConnectionStatus" && event.connectionState === "terminate") {
                        if (object.messages && object.messages.length > 0 && object.messages[object.messages.length - 1].message && object.messages[object.messages.length - 1].message.indexOf("Visitor left") > -1) {
                            object.messages.pop();

                        }
                        /* if (!botTransfer && !systemClose) {
                             object.messages.push({
                                 "message": "BOT Agent closed the conversation",
                                 "messageType": "chat",
                                 "createdAt": event.createdAt,
                                 "speaker": {"systemId": "systemInformation"}
                             });
                             console.log("here", event.createdAt)
                             systemClose = true;
                         }*/
                    }


                    if (event.messageType && event.messageType === "visitProfile" && (event.claims.length > 1)) {
                        let claims = event.claims;
                        let displayClaims = [];
                        claims.map((claim) => {
                            if (claim.type !== "ipaddress" && claim.values[0] !== " ") {
                                let claimObject = {};
                                claimObject.type = claim.type;
                                claimObject.value = claim.values[0];
                                displayClaims.push(claimObject);
                            }

                        });
                        //console.log(displayClaims)

                        this.setState({claimObject: displayClaims});

                    }
                });

                if (userLeft && agentPickup && object.participants.length > 2) {
                    let t1 = moment(userLeft);
                    let t2 = moment(agentPickup);
                    object.messages.push(botTransfer);
                    this.setState({timeInQueue: moment(t2.diff(t1)).format("mm:ss")})
                }
                object.participants.map((participant) => {

                        if (participant.info.role === "agent") {


                            this.setState({agentName: participant.info.name});
                        }

                    }
                )
            });

            this.setState({selectedDialogProcessed: dialog});
        }
    }

    getDialogOptions() {
        let optionsArray = [];
        if (this.state.selectedDialog && this.state.selectedDialog.conversations) {
            this.state.selectedDialog.conversations[0].messages.map((mess) => {

                if (mess.messageType === "metadataExchange" && mess.speaker.userId) {
                    if (mess.metadataBag.options) {
                        mess.metadataBag.options.map((option) => {

                            optionsArray.push(option.text);

                        });
                    } else if (mess && mess.metadataBag && mess.metadataBag.context) {
                        let context;

                        if (mess.metadataBag.context !== "message") {
                            try {

                                context = JSON.parse(mess.metadataBag.context);
                                let intentArray = context.intentMatchArray;
                                if (intentArray) {
                                    this.setState({intentMatchArray: intentArray});
                                }
                            } catch (error) {
                                //console.log(error)

                            }
                        }

                    }

                }
            });

        }
        this.setState({selectedDialogOptions: optionsArray});

    }


    createDialog() {

        if (this.state.selectedDialogProcessed && this.state.selectedDialogProcessed.conversations) {

            let firstUserId = null;
            let transferedDialog = false;
            let renderedPrev = false;
            let selectedDialog = this.state.selectedDialogProcessed;


            function getHash(input) {
                var hash = 0, len = input.length;
                for (var i = 0; i < len; i++) {
                    hash = ((hash << 5) - hash) + input.charCodeAt(i);
                    hash |= 0; // to 32bit integer
                }
                return hash;
            }
            let lastHash;
            return this.state.selectedDialogProcessed.conversations.map((object) => {
                return object.messages.sort(function (a, b) {
                    if (a.createdAt < b.createdAt) {
                        return -1;
                    }
                    if (a.createdAt > b.createdAt) {
                        return 1;
                    }
                    return 0;
                }).map((mess, i) => {


                    //Visitor
                    if (mess.messageType === "chat" && mess.speaker.visitId) {
                        let confidenceArray = null;
                        let confiValue = null;

                        if (this.state.intentMatchArray) {

                            this.state.intentMatchArray.map((intent) => {

                                if (intent && intent.query && typeof intent.query === 'string' && intent.query.toLowerCase() === mess.message.toLowerCase()) {

                                    confidenceArray = intent.confidence;


                                    if (this.state.intentIndexArray && this.state.intentIndexArray[i] && this.state.intentIndexArray[i].newIntent) {
                                        confiValue = this.state.intentIndexArray[i].newIntent;
                                    } else {
                                        if (intent.confidence.length > 0) {
                                            confiValue = intent.confidence[0].name;
                                        }
                                    }
                                }
                            });
                        }


                        return <div key={Math.random()} style={{
                            color: "white",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end"
                        }}>

                            <div className="boxUser sb1" style={{
                                width: "440px",
                                wordWrap: "wrap",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                {confidenceArray &&
                                    <IntentMatching key={Math.random()} allIntents={this.state.allIntents}
                                                    changeIntent={(newIntent, oldIntent, queryValue) => this.changeIntent(newIntent, oldIntent, queryValue, i)}
                                                    items={confidenceArray} value={confiValue}
                                                    queryValue={mess.message}/>
                                }
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: !confidenceArray ? "0px" : "20px"
                                }}>
                                    <div style={{"minWidth": "200px"}}>{mess.message}</div>
                                    {(!confidenceArray && !transferedDialog && this.state.selectedDialogOptions && this.state.selectedDialogOptions.indexOf(mess.message) > -1) &&
                                        <TouchAppIcon/>}
                                </div>
                            </div>


                            <div style={{
                                color: "black",
                                marginRight: "15px",
                                marginLeft: "20px",
                                fontSize: "15px"

                            }}>{moment(mess.createdAt).format("HH:mm:ss")}</div>

                        </div>

                    } else if (mess.messageType === "chat" && mess.speaker.userId) {
                        if (!firstUserId) {
                            firstUserId = mess.speaker.userId;
                        }

                        if (firstUserId !== mess.speaker.userId) {

                            transferedDialog = true;
                        }
                        return mess.message !== "" ? <div key={Math.random()} style={{
                            color: "black",
                            width: "100%",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <div key={Math.random()} className="box2 sb2" style={{
                                width: "400px"
                            }}>{mess.message}</div>
                            <div style={{
                                color: "black",
                                marginRight: "65px",
                                width: "330px",
                                display: "flex",
                                justifyContent: "flex-end"

                            }}>{moment(mess.createdAt).format("HH:mm:ss")}</div>
                        </div> : "";
                    } else if (mess.messageType === "chat" && mess.speaker.systemId) {
                        return <div key={Math.random()} style={{
                            color: "white",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>

                            <div key={Math.random()} className="" style={{
                                width: "100%", backgroundColor: "#999", padding: "10px"
                            }}>{mess.message}</div>
                            <div style={{
                                color: "black",
                                marginRight: "25px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end"

                            }}>{moment(mess.createdAt).format("HH:mm:ss")}</div>
                        </div>

                    } else if (mess.messageType === "metadataExchange" && mess.speaker.userId && mess.metadataBag.options && mess && (lastHash !== getHash(JSON.stringify(mess.metadataBag.options)))) {
                        lastHash = getHash(JSON.stringify(mess.metadataBag.options));

                        return mess.metadataBag.options.map((option, i) => {
                            return <div key={Math.random()}
                                        style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <div style={{
                                    border: "1px solid #2A769E",
                                    backgroundColor: "#2A769E",
                                    color: "white",
                                    fontWeight: "700",
                                    borderRadius: "20px",
                                    marginTop: "10px",
                                    marginLeft: "10px",
                                    width: "400px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    minHeight: "40px",
                                    paddingLeft: "20px"
                                }}>
                                    {option.text}</div>
                                {i === mess.metadataBag.options.length - 1 ? <div style={{
                                    color: "black",
                                    marginRight: "0px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    width: "400px"
                                }}>{moment(mess.createdAt).format("HH:mm:ss")}</div> : ""}</div>;
                        });
                    }
                });
            });
        }
    }

    getCaseTypeName(id) {
        let castTypeName = "";
        this.state.cases.map((object) => {

            if (object.id === id) {
                castTypeName = object.name;

            }

        });
        return castTypeName;
    }

    async getDialog(dialogId) {

        let url = '/api/getDialog?dialogId=' + dialogId;

        const response = await fetch(url);
        const data = await response.json();

        let curr = this.divRef.current;
        if (curr) {
            setTimeout(this.scrollDiv(curr), 4000);
        }

        this.setState({selectedDialog: data.data}, () => {
            this.setState({loading: false});
//            console.log(data.data)
            if (data.data && data.data.type) {
                let caseType = this.getCaseTypeName(data.data.type.id);
                let satisfactionScore = data.data.satisfactionScore;
                if (satisfactionScore > 0) {
                    this.setState({satisfactionScore: satisfactionScore});
                }
                this.getDialogOptions();
                this.getParticipantsInfo(data.data.conversations[0].participants);
                this.setState({conversationId: data.data.conversations[0].id});
                this.setState({caseType: caseType});
                //data.data.conversations[0].events.
                this.getAllDeviceInfo();

                this.getIntentMatching();
            }
        });

    }

    getParticipantsInfo(participantsObject) {

        let participants = [];
        participantsObject.map((p) => {

            participants.push(p.info.name);

        });
        this.setState({participants: participants});

    }

    async getCaseMappings(site) {

        let url = '/api/getCases?site=' + site;

        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let cases = [...data, ...this.state.cases];


            this.setState({cases: cases, caseFilterArray: cases});

        }
    }

    getIntentMatching() {

        if (this.state.selectedDialog.form && this.state.selectedDialog.form.context) {
            let context = JSON.parse(this.state.selectedDialog.form.context);
            let intentArray = context.intentMatchArray;
            if (intentArray) {
                this.setState({intentMatchArray: intentArray});
            } else {
                this.setState({intentMatchArray: null});
            }
        }


    }

    scrollDiv(current) {
        if (current) {
            current.scrollTop = (current.scrollHeight - current.clientHeight);
        }
    }


    async getActiveDialogs() {

        let url = '/api/getDialogs?take=40&caseState=1';

        const response = await fetch(url);
        const data = await response.json();
        let activeDialogs = [];
        if (data.length > 0) {
            data.map((object) => {

                if (object.data.state === 1 && object.data.groupId !== this.state.facebookGroup) {

                    let dialogCreatedAt = moment(object.data.createdAt).format("MMM DD | HH:mm")
                    let activeObj = {"id": object.data.id, "createdAt": dialogCreatedAt, "active": true};
                    if (this.state.checked && this.state.botgroups.indexOf(object.data.groupId) > -1) {
                        activeDialogs.push(activeObj);
                    } else if (!this.state.checked) {
                        activeDialogs.push(activeObj);
                    }


                }
            });
            if (activeDialogs.length === 0) {

                let activeObj = {"id": "0000", "createdAt": "No Active Dialogs", "active": true};
                activeDialogs.push(activeObj);
            }

            this.setState({dialogs: activeDialogs});
            this.setState({reloadActive: true});
        }

    }

    async getAllDialogsWithConversation(loopNumber, caseState, minDate, maxDate) {

        this.setState({loadingDialogs: true});
        let allStartUrls = new Map();
        let deviceMap = new Map();
        let allOpportunities = new Map();

        let allSolutions = new Map();
        if (minDate) {
            let splitMin = minDate.split(" ");
            minDate = splitMin[0] + "T" + splitMin[1] + ":00.000Z";

        }

        if (maxDate) {
            let splitMax = maxDate.split(" ");
            maxDate = splitMax[0] + "T" + splitMax[1] + ":00.000Z";

        }

        let url = '/api/getDialogs?getDialogs=true&includeConversations=true';


        url = url + "&take=500";

        url = url + "&skip=" + loopNumber * 500;


        if (caseState) {
            url = url + "&caseState=" + caseState;
        }
        if (minDate) {
            url = url + "&minDate=" + minDate;
        }

        if (maxDate) {
            url = url + "&maxDate=" + maxDate;
        }
        const response = await fetch(url);
        const data = await response.json();


        let deviceTypeArray = [];
        this.setState({numberOfConversations: data.length});
        if (data.length > 0) {
            let conversationArray = this.state.dialogs ? this.state.dialogs : [];
            data.map((object) => {

                if (object.data) {

                    if (object.data.form && object.data.form.context) {

                        let context = JSON.parse(object.data.form.context);
                        if (context.intentMatchArray) {

                            if (Number.parseFloat(context.intentMatchArray[0].confidence[0].confidence) < Number.parseFloat(this.state.confidenceThreshold) / 100) {
                                let dialogCreatedAt = moment(object.data.createdAt).format("MMM DD | HH:mm");
                                let convObj = {"id": object.data.id, "createdAt": dialogCreatedAt};

                                conversationArray.push(convObj);
                            }
                        }

                    }

                    /* let messages = object.data.conversations[0].messages;
                     let events = object.data.conversations[0].events;
                     let firstUrlSet = false;
 */
                    /* events.map((event) => {

                         if (event.type === "visitorRequestConversation") {

                             let oppId = event.opportunityId;

                             let solId = event.solutionId;
                             if (allOpportunities.has(oppId)) {
                                 let counter = allOpportunities.get(oppId)
                                 allOpportunities.set(oppId,  counter + 1);
                             } else {

                                 allOpportunities.set(oppId, 1);
                             }

                             if (allSolutions.has(solId)) {
                                 let counter = allSolutions.get(solId)
                                 allSolutions.set(solId,  counter + 1);
                             } else {

                                 allSolutions.set(solId, 1);
                             }


                         }


                     });
                     const iteratorOpp = allOpportunities[Symbol.iterator]();
                     let oppArray = [];

                     let resultOpp = iteratorOpp.next();


                     while( !resultOpp.done ) {
                         let name = "";

                         this.state.opportunities.map((opp)=>{

                             if(opp.id === resultOpp.value[0]){

                                 name = opp.name;
                             }

                         });



                         oppArray.push([name,resultOpp.value[1]] );
                         resultOpp = iteratorOpp.next();

                     }

                     const iteratorSol = allSolutions[Symbol.iterator]();
                     let solArray = [];

                     let resultSol = iteratorSol.next();


                     while( !resultSol.done ) {
                         let name = "";

                         this.state.solutions.map((sol)=>{

                             if(sol.id === resultSol.value[0]){

                                 name = sol.name;
                             }

                         });



                         solArray.push([name,resultSol.value[1]] );
                         resultSol = iteratorSol.next();

                     }


                     this.setState({allSolutions: solArray, allOpportunities: oppArray});*/


                    /*  let deviceFound = false;
                      for (let i = 0; i < messages.length; i++) {

                          if (messages[i].messageType === "navigation" && !firstUrlSet) {
                              firstUrlSet = true;
                              if (allStartUrls.has(messages[i].url)) {
                                  let counter = allStartUrls.get(messages[i].url)
                                  allStartUrls.set(messages[i].url, counter + 1);
                              } else {

                                  allStartUrls.set(messages[i].url, 1);
                              }

                          }


                          if (messages[i].messageType === "metadataExchange" && messages[i].speaker.visitId) {

                              try {
                                  let context = JSON.parse(messages[i].metadataBag);

                                  if (context.deviceType && context.deviceType.os) {
                                      deviceFound = true;
                                      if (context.deviceType && context.deviceType.os && context.deviceType.os.touch) {

                                          if (deviceMap.has("Mobile")) {
                                              let counter = deviceMap.get("Mobile")
                                              deviceMap.set("Mobile", counter + 1);
                                          } else {
                                              deviceMap.set("Mobile", 1);
                                          }

                                      } else if (context.deviceType && context.deviceType.os && !context.deviceType.os.touch) {
                                          if (deviceMap.has("Desktop")) {
                                              let counter = deviceMap.get("Desktop")
                                              deviceMap.set("Desktop", counter + 1);
                                          } else {
                                              deviceMap.set("Desktop", 1);
                                          }
                                      } else {
                                          if (deviceMap.has("N/A")) {
                                              let counter = deviceMap.get("N/A")
                                              deviceMap.set("N/A", counter + 1);
                                          } else {
                                              deviceMap.set("N/A", 1);
                                          }

                                      }
                                  }

                              } catch (error) {
                                  console.log(error)
                              }

                          }
                      }

                      if(!deviceFound){

                          if (deviceMap.has("N/A")) {
                              let counter = deviceMap.get("N/A")
                              deviceMap.set("N/A", counter + 1);
                          } else {
                              deviceMap.set("N/A", 1);
                          }
                      }else{
                          deviceFound = false;
                      }

  */
                }

            });


            const iterator1 = allStartUrls[Symbol.iterator]();
            let arr = [...iterator1];

            const iterator2 = deviceMap[Symbol.iterator]();
            let arr2 = [...iterator2];

            this.setState({dataArray: arr})
            this.setState({deviceTypeArray: arr2})


            this.setState({dialogs: conversationArray})
            this.setState({loadingDialogs: false});
        }


    }

    async getAllDialogs(loopNumber, caseState, minDate, maxDate, transfersOnly, initial) {


        if (minDate) {
            let splitMin = minDate.split(" ");
            minDate = splitMin[0] + "T" + splitMin[1] + ":00.000Z";
        }

        if (maxDate) {
            let splitMax = maxDate.split(" ");
            maxDate = splitMax[0] + "T" + splitMax[1] + ":00.000Z";
        }
        let url = '/api/getDialogs?getDialogs=true';

        url = url + "&take=500";

        url = url + "&skip=" + loopNumber * 500

        if (caseState) {
            url = url + "&caseState=" + caseState;
        }
        if (minDate) {
            url = url + "&minDate=" + minDate;
        }

        if (maxDate) {
            url = url + "&maxDate=" + maxDate;
        }

        if (initial) {

            this.setState({getInitialDialogsOnce: true});

        }
        this.setState({loadingDialogs: true});
        const response = await fetch(url);
        const data = await response.json();

        if (loopNumber === this.state.numberOfLoops - 1 || this.state.initialLoad) {
            this.setState({loadingDialogs: false});
        }
        let botTransferredDialogs = this.state.botTransferredDialogs ? this.state.botTransferredDialogs : [];

        let conversationArray = this.state.dialogs ? this.state.dialogs : [];
        let conversationArrayWithEmpty = this.state.dialogsWithEmpty ? this.state.dialogsWithEmpty : [];
        if (transfersOnly) {
            this.setState({botTransferredArrayLength: botTransferredDialogs.length});
        } else {
            this.setState({conversationArrayLength: conversationArray.length});
        }

        let surveyStats = this.state.surveyStats ? this.state.surveyStats : {};
        if (data.length > 0) {


            data.map((object) => {

                if (object.data && object.data.createdAt) {

                    let dialogCreatedAt = moment(object.data.createdAt).format("MMM DD | HH:mm")
                    let convObj = {"id": object.data.id, "createdAt": dialogCreatedAt};

                    let noUserInput = false;
                    const caseIdArray = this.state.caseFilterArray.filter(c => c.id === object.data.type.id);

                    if (caseIdArray.length > 0) {
                        if (object.data.satisfactionScore && object.data.satisfactionScore > 0) {

                            if (surveyStats[object.data.satisfactionScore.toString()] && !isNaN(surveyStats[object.data.satisfactionScore.toString()]) && surveyStats[object.data.satisfactionScore.toString() + "Ids"]) {

                                surveyStats[object.data.satisfactionScore.toString()] = surveyStats[object.data.satisfactionScore.toString()] + 1;
                                let idArray = surveyStats[object.data.satisfactionScore.toString() + "Ids"];

                                if (idArray && idArray.length > 0) {
                                    idArray.push(object);
                                    surveyStats[object.data.satisfactionScore.toString() + "Ids"] = idArray;
                                }

                            } else {

                                surveyStats[object.data.satisfactionScore.toString() + "Ids"] = [object];
                                surveyStats[object.data.satisfactionScore.toString()] = 1;

                            }

                        }
                    }
                    if (object.data.groupId !== this.state.facebookGroup && !noUserInput) {
                        const caseIdArray = this.state.caseFilterArray.filter(c => c.id === object.data.type.id);

                        if (caseIdArray.length > 0) {
                            if (this.state.checkedEmpty && object.data.closure && object.data.closure.name && object.data.closure.name.toLowerCase() === "bot no user input") {
                                noUserInput = true;
                                conversationArrayWithEmpty.push(convObj);
                            }


                            if (this.state.checked && this.state.botgroups.indexOf(object.data.createdBy.groupId.id) > -1 && !noUserInput) {
                                conversationArray.push(convObj);
                            } else if (!this.state.checked && !noUserInput) {
                                conversationArray.push(convObj);
                            }

                            if (this.state.botgroups.indexOf(object.data.createdBy.groupId.id) > -1 && object.data.groupId !== object.data.createdBy.groupId.id) {

                                botTransferredDialogs.push(convObj);
                            }
                        }

                    }

                }

            });


            if (this.state.initialLoad && conversationArray && conversationArray.length > 0 && !this.state.openExternalCaseId) {

                let idToGet = conversationArray[0].id;
                this.createSurveyStats(surveyStats);
                this.setState({initialLoad: false, selectedCaseId: idToGet});
                this.getDialog(idToGet);

            } else {

                this.setState({openExternalCaseId: false})
            }

        } else {
            if (transfersOnly && this.state.botTransferredArrayLength && botTransferredDialogs.length === this.state.botTransferredArrayLength) {
                this.setState({
                    dialogs: botTransferredDialogs,
                    botTransferredDialogs: null,
                    nbrConversations: botTransferredDialogs.length
                });
                this.setState({breakLoop: true});
            } else if (this.state.conversationArrayLength && conversationArray.length === this.state.conversationArrayLength) {

                this.setState({breakLoop: true});
            }
        }
        if (transfersOnly) {

            if (loopNumber === this.state.numberOfLoops - 1) {

                this.setState({
                    dialogs: botTransferredDialogs,
                    botTransferredDialogs: null,
                    nbrConversations: botTransferredDialogs.length
                });
            } else {
                this.setState({

                    botTransferredDialogs: botTransferredDialogs,
                    nbrConversations: botTransferredDialogs.length
                });
            }


        } else {

            let nominator = conversationArray.length + conversationArrayWithEmpty.length;

            this.setState({
                dialogs: conversationArray,
                dialogsWithEmpty: conversationArrayWithEmpty,
                dialogsWithEmptyLength: conversationArrayWithEmpty.length,
                dialogsPercentageEmpty: Math.round((conversationArrayWithEmpty.length / nominator) * 100, 0),
                nbrConversations: conversationArray.length,
                botTransferredDialogs: botTransferredDialogs
            });
        }


        if (Object.keys(surveyStats).length > 0) {

            this.setState({includeSurvey: true});
//            console.log(loopNumber, this.state.numberOfLoops)

            if (loopNumber === this.state.numberOfLoops - 1 || this.state.breakLoop) {
                this.createSurveyStats(surveyStats);
            } else {
                this.setState({surveyStats: surveyStats});
            }
        }

    }

    createSurveyStats(surveyStats) {

        //


        this.createSurveyData(surveyStats);

        let keys = Object.keys(surveyStats);

        keys = keys.sort();


        let totalNumberOfValues = 0;
        let sumSatisfactionScore = 0;
        keys.map((key) => {

            if (key.indexOf("Ids") === -1) {

                let k = eval(key);
                let value = surveyStats[key];
                totalNumberOfValues += value;
                let indexValue = k * value;
                sumSatisfactionScore += indexValue;

            }

        });


        this.setState({
            sumSatisfactionScore: sumSatisfactionScore,
            totalNumberOfValues: totalNumberOfValues,
            averageIndexedSurvey: Math.round(((sumSatisfactionScore / totalNumberOfValues) * 100))
        })

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.pipeline !== this.props.pipeline) {

            this.setState({pipeline: this.props.pipeline});

        }
    }

    listFilterRow() {

        if (this.state.cases) {

            return this.state.cases.map((c, i) => {

                return <MenuItem
                    key={c.name} classes={{root: this.props.classes.listItemTextSelected2}} value={c}
                    button><Checkbox checked={this.state.caseFilterArray.includes(c)}/><ListItemText
                    id={i}
                    primary={c.name}/></MenuItem>
            });


        }


    }

    listRow() {
        let filteredArray = this.filterArray(this.state.dialogs, this.state.yourChips);


        return filteredArray.map((object, i) => {
            return <ListItem
                key={i} selected={this.state.selected === i} classes={{root: this.props.classes.listItemTextSelected}}
                button><ListItemText
                onClick={event => this.reactOnListClick(object, i)} id={i}
                primary={object.createdAt}/></ListItem>

        });


    }

    render() {

        const {classes} = this.props;


        return (
            <div style={{display: "flex", flexDirection: "column", marginTop: "-5px"}}>
                {/*<Tabs value={this.state.value} onChange={this.handleTabChange}>
                    <Tab label="Conversation"/>
                    <Tab label="Statistics"/>


                </Tabs>*/}
                {this.state.value === 0 &&
                    <div style={{display: "flex", flexDirection: "row", marginTop: "20px"}}>

                        <Paper style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "15px",
                            padding: "10px",
                            maxWidth: "300px"
                        }}>
                            <div style={{display: "flex", flexDirection: "column"}}>


                                <Accordion expanded={this.state.expandedPeriod} style={{marginTop: "10px"}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={this.handlePeriodChange}
                                    >
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <QueryBuilderIcon/>

                                            <div style={{marginLeft: "10px"}}>Time Period</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{dislay: "flex", flexDirection: "column"}}>
                                            <div style={{marginTop: "0px"}}>

                                                {/*<Button style={{width: "100%", marginTop: "20px"}} variant="contained" color="primary"
                                                onClick={this.showClosed}>Last Three Days</Button> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDateTimePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        variant="inline"
                                                        format="yyyyMMdd HH:mm"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="From Date"
                                                        style={{width: 238}}
                                                        value={this.state.fromDate}
                                                        onChange={this.onChangeFromDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />


                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div style={{width: "100%", marginTop: "5px", boxSizing: "content-box"}}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDateTimePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        variant="inline"
                                                        format="yyyyMMdd HH:mm"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="To Date"
                                                        style={{width: 238}}
                                                        value={this.state.toDate}
                                                        onChange={this.onChangeToDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                                                    color="primary"
                                                    onClick={this.getConversations}>Get Conversations</Button>

                                            <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                                                    color="primary"
                                                    onClick={this.getOnlyTransferConversations}>Get BOT
                                                Transfers</Button>
                                            <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                                                    color="primary"
                                                    onClick={this.showActive}>Active Dialogs</Button>

                                            <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                                                    color="primary"
                                                    onClick={this.showRelativeToDate}>Low confidence</Button>
                                            {this.state.showRelativeToDate &&
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <div style={{
                                                        width: "100%",
                                                        marginTop: "5px",
                                                        boxSizing: "content-box"
                                                    }}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDateTimePicker
                                                                disableToolbar
                                                                autoOk
                                                                ampm={false}
                                                                variant="inline"
                                                                format="yyyyMMdd HH:mm"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Relative To Date"
                                                                style={{width: 238}}
                                                                value={this.state.relativeToDate}
                                                                onChange={this.onChangeRelativeToDate}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <Button style={{width: "100%", marginTop: "20px"}}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.getConversationStats}>Get {this.state.buttonName} back</Button>
                                                </div>}


                                            {this.state.loadingDialogs && <div style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: "20px"
                                            }}><CircularProgress style={{}} className={classes.progress}/></div>}
                                        </div>

                                    </AccordionDetails>
                                </Accordion>


                                <Accordion expanded={this.state.expandedFilter} style={{marginTop: "10px"}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={event => this.handleFilterChange('panel1')}
                                    >
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <FilterListIcon/>
                                            <div style={{marginLeft: "10px"}}>Filter</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <div style={{dislay: "flex", flexDirection: "column", width: "100%"}}>
                                            <FormGroup row style={{marginTop: "10px"}}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={this.state.checked}
                                                                       onChange={this.handleCheckbox}
                                                                       name="BOTDialogs"/>}
                                                    label="Bot Dialogs Only"
                                                />
                                            </FormGroup>
                                            <FormGroup row style={{}}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={this.state.checkedEmpty}
                                                                       onChange={this.handleEmptyDialogsCheckbox}
                                                                       name="EmptyDialogs"/>}
                                                    label="Exclude Empty BOT Dialogs"
                                                />
                                            </FormGroup>
                                            <FormGroup variant="outlined" style={{width: "100%"}}
                                                       className={classes.formControl}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginTop: "20px",
                                                }}>
                                                    <InputLabel style={{fontSize: "12px", marginBottom: "-8px"}}
                                                                id="demo-mutiple-checkbox-label">Case Id </InputLabel>
                                                    <div style={{
                                                        width: 242,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }} component="form" className={classes.root}>

                                                        <InputBase
                                                            className={classes.input}
                                                            inputProps={{'aria-label': 'Case Id'}}

                                                            onChange={this.handleCaseIdToOpen}
                                                        />
                                                        <IconButton onClick={this.openCaseId}
                                                                    className={classes.iconButton}
                                                                    aria-label="Go">
                                                            <SendIcon/>
                                                        </IconButton>
                                                    </div>
                                                    <span style={{
                                                        borderBottom: "1px solid #949494",
                                                        marginTop: "-6px",
                                                        width: 238,
                                                    }}></span>
                                                </div>
                                            </FormGroup>

                                            <FormGroup className={classes.formControl} style={{marginTop: "25px"}}>
                                                <InputLabel style={{fontSize: "12px"}} id="demo-mutiple-checkbox-label">Case
                                                    Type </InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.caseFilterArray}
                                                    onChange={this.handleCaseTypeChange}
                                                    input={<Input style={{maxWidth: 238}}/>}
                                                    renderValue={(selected) => selected.map(item => item.name).join(', ')}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 48 * 4.5 + 8,
                                                                width: 200,
                                                            },
                                                        },
                                                    }}
                                                >

                                                    <MenuItem
                                                        key={"uncheckAll"}
                                                        classes={{root: this.props.classes.listItemTextSelected2}}
                                                        value={"uncheckAll"}
                                                        button><Checkbox checked={false}/><ListItemText
                                                        id={"uncheckAll"}
                                                        primary={"Uncheck All"}/></MenuItem>
                                                    {this.listFilterRow()}

                                                </Select>
                                            </FormGroup>


                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                {this.state.includeSurvey &&
                                    <Accordion expanded={this.state.expandedSurvey} style={{marginTop: "10px"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            onClick={this.handleSurveyChange}
                                        >
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row"
                                            }}>
                                                <PollOutlinedIcon/>

                                                <div style={{marginLeft: "10px"}}>Survey Statistics</div>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "flex-start"
                                            }}>
                                                <Chart
                                                    width={'230px'}
                                                    height={'230px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={this.state.surveyData}
                                                    options={pieOptions}
                                                    rootProps={{'data-testid': '1'}}
                                                    chartEvents={[
                                                        {
                                                            eventName: "select",
                                                            callback: ({chartWrapper, google}) => {
                                                                const chart = chartWrapper.getChart();
                                                                const selected = chart.getSelection();
                                                                if (selected) {
                                                                    const dataTable = chartWrapper.getDataTable();

                                                                    const [selectedItem] = selected;
                                                                    if (selectedItem) {
                                                                        const {row, column} = selectedItem;


                                                                        this.showAllConversationsWithThatRating(dataTable.getValue(row, 0));

                                                                    }
                                                                }
                                                            }
                                                        }]}


                                                />
                                                <div style={{marginTop: "20px", fontSize: "14px"}}>
                                                    <div style={{display: "flex", flexDirection: "row"}}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                marginRight: "20px",
                                                                width: "165px"
                                                            }}>Satisfaction Score:
                                                        </div>
                                                        {this.state.averageIndexedSurvey}</div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div
                                                        style={{
                                                            fontWeight: "700",
                                                            marginRight: "20px",
                                                            width: "165px"
                                                        }}>Number of Answers:
                                                    </div>
                                                    <div>{this.state.totalNumberOfValues}</div>
                                                </div>
                                            </div>


                                        </AccordionDetails>
                                    </Accordion>}
                                <Accordion expanded={this.state.expandedStats} style={{marginTop: "10px"}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={this.handleStatsChange}
                                    >

                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <ShowChartIcon/>

                                            <div style={{marginLeft: "10px"}}>Statistics</div>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{
                                            width: "100%"
                                        }}>
                                            {this.state.nbrConversations ?
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div
                                                        style={{
                                                            fontWeight: "700",
                                                            marginRight: "20px",
                                                            width: "165px"
                                                        }}>Conversations:
                                                    </div>
                                                    {this.state.nbrConversations}</div> : ""}
                                            {this.state.dialogsWithEmpty ?
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        marginRight: "20px",
                                                        width: "165px"
                                                    }}>Number of Empty:
                                                    </div>
                                                    {this.state.dialogsWithEmptyLength}
                                                </div> : ""
                                            }
                                            {this.state.dialogsPercentageEmpty ?
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        marginRight: "20px",
                                                        width: "165px"
                                                    }}>Empty Percentage:
                                                    </div>
                                                    {this.state.dialogsPercentageEmpty}%
                                                </div> : ""
                                            }

                                            {this.state.botTransferredDialogs ?
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        marginRight: "20px",
                                                        width: "165px"
                                                    }}>Transfers
                                                        BOT -> Agent:
                                                    </div>
                                                    {this.state.botTransferredDialogs.length}</div> : ""}
                                            {this.state.botTransferredDialogs ?
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        marginRight: "20px",
                                                        width: "165px"
                                                    }}>Transfer
                                                        Percentage:
                                                    </div>
                                                    {Math.round((this.state.botTransferredDialogs.length / this.state.nbrConversations) * 100, 0)}%
                                                </div> : ""}


                                        </div>
                                    </AccordionDetails>
                                </Accordion>


                            </div>
                        </Paper>
                        <Paper style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "15px",
                            padding: "0px",
                            marginLeft: "20px",
                            height: "calc(100vh - 40px)",
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%"

                            }}>
                                <ChipInput
                                    value={this.state.yourChips}
                                    variant="filled"
                                    label="Filter"
                                    style={{width: "100%"}}
                                    onAdd={(chip) => this.handleAddChip(chip)}
                                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                />
                                <List
                                    component="nav"
                                    className={classes.root}
                                    id='thing'
                                    style={{overflow: 'auto', width: "100%", minWidth: "140px", maxHeight: "95%"}}
                                >
                                    {this.listRow()}
                                </List>
                            </div>

                        </Paper>
                        <Paper style={{
                            display: "flex",
                            marginBottom: "0px",
                            minWidth: "600px",
                            marginLeft: "40px",
                            padding: "10px",
                            maxHeight: "calc(100vh - 40px)",
                            minHeight: "calc(100vh - 40px)"

                        }}>
                            {this.state.loading ? <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <CircularProgress style={{}} className={classes.progress}/></div> :
                                <div ref={this.divRef} style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    overflowY: "auto",
                                    borderRadius: "5px",
                                    padding: "20px"
                                }}>

                                    {this.createDialog()}

                                </div>}
                            {this.state.intentIndexArray && this.state.intentIndexArray.length > 0 && this.state.saveUpdatedModel ?
                                <ModifyTrainingData
                                    resetSaveUpdated={this.resetSaveUpdated}
                                    notify={() => {
                                        this.setState({notifierOpen: true});
                                        localStorage.setItem("refresh", true);
                                    }} model={this.state.model}
                                    account={this.props.account}
                                    setUpdateModel={() => {
                                        this.setState({updateModel: false})
                                    }} getUpdatedModel={this.state.updatedModel}
                                    intentIndexArray={this.state.intentIndexArray}
                                    saveUpdatedModel={this.state.saveUpdatedModel}
                                /> : ""}

                        </Paper>

                        <Paper style={{
                            display: "flex",
                            marginBottom: "15px",
                            marginLeft: "40px",
                            padding: "10px",
                            height: "calc(100vh - 40px)"
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                padding: "10px",
                                paddingBottom: "25px",

                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddding: "10px",
                                    height: "100%"
                                }}>

                                    <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                                        this.handleChange('panel1')
                                    }} style={{marginTop: "10px"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row"
                                            }}>
                                                <ChatIcon/>
                                                <div style={{marginLeft: "10px"}}>Dialog Information</div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div style={{dislay: "flex", flexDirection: "column"}}>
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Case Id</div>
                                                {this.state.selectedCaseId ?
                                                    <div>{this.state.selectedCaseId}</div> : ""}
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Conversation Id
                                                </div>
                                                {this.state.conversationId && <div>{this.state.conversationId}</div>}
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Case Type</div>
                                                {this.state.caseType ?
                                                    <div>{this.state.caseType}</div> : ""}

                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Closure</div>
                                                {this.state.closureObj &&
                                                    <div>{this.state.closureObj}</div>}
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Participants</div>
                                                {this.state.participants ? <div key={Math.random()}>Visitor</div> : ""}
                                                {this.state.participants ? this.state.participants.map((p, index) => {
                                                    return <div key={index}>{p}</div>
                                                }) : ""}

                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Dialog Started
                                                </div>
                                                {this.state.dialogStart ?
                                                    <div>{moment(this.state.dialogStart).format("YY-MM-DD | HH:mm:ss")}</div> : ""}

                                                {this.state.timeInQueue ? <div>
                                                    <div style={{fontWeight: "700", marginRight: "20px"}}>Time In Queue
                                                    </div>
                                                    <div>{this.state.timeInQueue}</div>
                                                </div> : ""}
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Dialog Ended</div>
                                                {this.state.dialogEnd ?
                                                    <div>{moment(this.state.dialogEnd).format("YY-MM-DD | HH:mm:ss")}</div> : ""}


                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Device</div>
                                                {this.state.deviceInfoOs && this.state.deviceInfoOs.osx ?
                                                    <div>Desktop Mac</div> : ""}
                                                {this.state.deviceInfoOs && this.state.deviceInfoOs.win ?
                                                    <div>Desktop Windows</div> : ""}
                                                {this.state.deviceInfoOs && this.state.deviceInfoOs.phone ?
                                                    <div>Mobile</div> : ""}
                                                {this.state.deviceInfoOs && this.state.deviceInfoOs.tablet ?
                                                    <div>Tablet</div> : ""}
                                                <div style={{fontWeight: "700", marginRight: "20px"}}>Browser</div>
                                                {this.state.deviceInfoBrowser && this.state.deviceInfoBrowser.safari ?
                                                    <div>Safari
                                                        Version: {this.state.deviceInfoBrowser.version}</div> : ""}
                                                {this.state.deviceInfoBrowser && this.state.deviceInfoBrowser.chrome ?
                                                    <div>Chrome
                                                        Version: {this.state.deviceInfoBrowser.version}</div> : ""}
                                                {this.state.deviceInfoBrowser && this.state.deviceInfoBrowser.firefox ?
                                                    <div>Firefox
                                                        Version: {this.state.deviceInfoBrowser.version}</div> : ""}
                                                {this.state.satisfactionScore && <div>
                                                    <div style={{fontWeight: "700", marginRight: "20px"}}>Visitor
                                                        Satisfaction Score
                                                    </div>
                                                    <div>{this.state.satisfactionScore * 100}%</div>
                                                </div>}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    {this.state.claimObject &&
                                        <Accordion expanded={this.state.expanded === 'panel2'}
                                                   onClick={event => this.handleChange('panel2')}
                                                   style={{marginTop: "10px"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "row"
                                                }}>
                                                    <PersonIcon/>
                                                    <div style={{marginLeft: "10px"}}>Visitor Information</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "350px"
                                                }}>
                                                    {this.state.claimObject.map((claim, i) => {

                                                        return <div key={i}>
                                                            <div><strong>{claim.type}</strong></div>
                                                            <div>{claim.value}</div>
                                                        </div>
                                                    })
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>}


                                    {this.state.reload ?
                                        <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                                                color="primary"
                                                onClick={this.stopUpdate}>Stop Updating</Button> : ""}

                                    {this.state.updatedModelAvailable ?
                                        <Button style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}
                                                variant="contained" color="primary"
                                                onClick={() => {
                                                    this.setState({
                                                        saveUpdatedModel: true

                                                    })
                                                }}>Save Updated Model</Button> : ""}


                                </div>
                            </div>
                        </Paper>

                    </div>
                }


                {this.state.value === 1 && <div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <span>Number Of Conversations:</span>
                        <span> {this.state.numberOfConversations}</span>

                    </div>
                    <Button style={{width: "100%", marginTop: "20px"}} variant="contained"
                            color="primary"
                            onClick={this.getConversationStats}>Get Conversation Statistics</Button>


                    <MUIDataTable
                        title={"Dialog Start Urls"}
                        data={this.state.dataArray}
                        columns={["Url", {
                            name: "Counter",
                            label: "Counter",
                            options: {
                                filter: true,
                                sort: true,
                                sortDirection: 'desc'
                            }
                        }]}
                        options={{
                            filter: true,
                            filterType: "checkbox",
                            responsive: "scroll",
                            selectableRows: "none",
                            rowsPerPage: 100,
                            rowsPerPageOptions: [50, 100, 500],
                            sort: true,
                        }


                        }
                    />

                    <MUIDataTable
                        title={"Device Type"}
                        data={this.state.deviceTypeArray}
                        columns={["Device Type", {
                            name: "Counter",
                            label: "Counter",
                            options: {
                                filter: true,
                                sort: true,
                                sortDirection: 'desc'
                            }
                        }]}
                        options={{
                            filter: true,
                            filterType: "checkbox",
                            responsive: "scroll",
                            selectableRows: "none",
                            rowsPerPage: 100,
                            rowsPerPageOptions: [50, 100, 500],
                            sort: true,
                        }


                        }
                    />

                    {/*  <MUIDataTable
                        title={"All Solutions"}
                        data={this.state.allSolutions}
                        columns={["Solution Name", {
                            name: "Counter",
                            label: "Counter",
                            options: {
                                filter: true,
                                sort: true,
                                sortDirection: 'desc'
                            }
                        }]}
                        options={{
                            filter: true,
                            filterType: "checkbox",
                            responsive: "scroll",
                            selectableRows: false,
                            rowsPerPage: 100,
                            rowsPerPageOptions: [50, 100, 500],
                            sort: true,
                        }


                        }
                    />
                    <MUIDataTable
                        title={"All Opportunities"}
                        data={this.state.allOpportunities}
                        columns={["Opportunity Name", {
                            name: "Counter",
                            label: "Counter",
                            options: {
                                filter: true,
                                sort: true,
                                sortDirection: 'desc'
                            }
                        }]}
                        options={{
                            filter: true,
                            filterType: "checkbox",
                            responsive: "scroll",
                            selectableRows: false,
                            rowsPerPage: 100,
                            rowsPerPageOptions: [50, 100, 500],
                            sort: true,
                        }


                        }
                    />*/}
                </div>}
                <Notifier changeState={this.changeState} open={this.state.notifierOpen}
                          message="Updated Model Saved Successfully"/>
            </div>
        );
    }
}

export default withStyles(styles)(Dialogs);
