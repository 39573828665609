import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button/Button";
import moment from "moment";


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        minWidth: "520px"
    };
}

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    list: {

        paddingLeft: '10px',
        color: '#000',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    listItemText: {

        paddingLeft: '20px',
        fontSize: '15px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        },
        overflow: 'hidden'
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flexGrow: 1,
        maxHeight: "100vh"

    },
    tabs: {

        paddingLeft: "5px",
        paddingRight: "5px"

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    listItemTextSelected: {
        color: "#000000",
        fontSize: "12px",
        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "#FFFFFF"
        },


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
});

class AddFromQueryLog extends Component {

    constructor(props) {

        super(props);

        this.state = {
            addValue: "",
            intentToLog: {"name": ""},
            modelValue: {"name": ""},
            savedModels: [],
            keyValue: [],
            open: false,
            firstQuery: null

        }
        this.convRef = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.removeUnvantedChars = this.removeUnvantedChars.bind(this);
        this.updateTextField = this.updateTextField.bind(this);
        this.changeIntentFromLog = this.changeIntentFromLog.bind(this);
        this.addNewTrainingFromLog = this.addNewTrainingFromLog.bind(this);
        this.queryIntentModel = this.queryIntentModel.bind(this);
        this.convertData = this.convertData.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.convertAllToRasa = this.convertAllToRasa.bind(this);
        this.updateDataModelInDb = this.updateDataModelInDb.bind(this);
        this.convertToRasaModel = this.convertToRasaModel.bind(this);
    }
    convertToRasaModel() {


        //Recreate the object on each conversion
        let newObject = [];


        this.state.keyValue.map((object) => {

            if(object.trainingData) {
                for (var i = 0; i < object.trainingData.length; i++) {

                    let obj = {
                        "intent": object.name,
                        "text": object.trainingData[i].text,
                        "status": object.status ? object.status : "active"
                    };


                    if (object.trainingData[i].entities) {

                        obj.entities = object.trainingData[i].entities;

                    }


                    newObject.push(obj);
                }
            }
            //console.log(entities, newObject)
        });


        return newObject;

    }




    convertAllToRasa() {

        let convertedModel = {
            "rasa_nlu_data": {
                "common_examples": [],
                "entity_synonyms": [],
                "entity_values": [],
                "entity_object": {},
                "lookup_object": {}
            }
        };


        let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");

        convertedModel.rasa_nlu_data.lastModifiedBy = this.props.loggedInUserEmail;
        convertedModel.rasa_nlu_data.lastModifiedIntent = this.state.lastModifiedIntent;
        convertedModel.rasa_nlu_data.lastModified = createdTimeStamp;
        convertedModel.rasa_nlu_data.common_examples = this.convertToRasaModel();

        const entities = new Set();
        const entitiyValue = new Set();

        this.state.keyValue.map((object) => {

            object.trainingData.map((object) => {

                //object.text = object.text.trim();
                let arr = Array.from(object.text.matchAll(/\(([^\)[]*)\)/g), x => x[1]);
                let arr2 = Array.from(object.text.matchAll(/\(([^\)[]*)\)\{/g), x => x[1]);
                let arr3 = Array.from(object.text.matchAll(/\[([^\)[]*)\]/g), x => x[1]);

                if (arr.length > 0 && arr2.length === 0 && !entities.has(arr[0])) {

                    entities.add(arr[0]);
                }

                if (arr3.length > 0 && arr2.length === 0) {
                    entitiyValue.add(arr3[0])

                }
            });
        });

        if (this.state.firstQuery && this.state.firstQuery.rasa_nlu_data && this.state.firstQuery.rasa_nlu_data.entity_object) {

            convertedModel.rasa_nlu_data.entity_object = this.state.firstQuery.rasa_nlu_data.entity_object;

        }

        if (this.state.firstQuery && this.state.firstQuery.rasa_nlu_data && this.state.firstQuery.rasa_nlu_data.lookup_object) {

            convertedModel.rasa_nlu_data.lookup_object = this.state.firstQuery.rasa_nlu_data.lookup_object;

        }

        convertedModel.rasa_nlu_data.entity_synonyms = [...entities];

        if (entitiyValue.size > 0) {
            convertedModel.rasa_nlu_data.entity_values = [...entitiyValue];
        }

        return convertedModel;

    }
    async updateDataModelInDb() {
        let model = this.state.model;

        let url = '/api/updateModel?id=' + model + "&account=" + this.props.account;


        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body:JSON.stringify(this.convertAllToRasa())
        });

        const data = await response.json();

        if (data.length === 0) {

            this.props.closeAdd();
            this.setState({open: false});

        }

    }

    changeModel(event, value) {


        if (value && value.Model) {

            this.setState({modelValue: value});
            this.setState({model: value.Model}, ()=>{this.queryIntentModel();});

        }
    }
    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();


        if(data && data.length >  0){

            data.map((d)=>{
                if(d.Model === this.props.model){
                    this.setState({modelValue: d});
                }
            });
            this.setState({savedModels: data});
        }
    }
    componentDidMount() {

        if (this.props.open) {

            this.setState({open: this.props.open === "true" ? true : false});
        }
        if (this.props.model) {

            this.setState({model: this.props.model});

        }

        if (this.props.trainingSentence) {
            this.setState({addValue: this.props.trainingSentence});

        }
        this.getSavedModels();
        this.queryIntentModel();
    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

        }

        if ((prevProps && prevProps.open !== this.props.open)) {
            this.setState({open: this.props.open === "true" ? true : false});
        }

    }

    convertData() {

        const data = [];
        if(this.state.firstQuery) {
            if (this.state.firstQuery && this.state.firstQuery.rasa_nlu_data && this.state.firstQuery.rasa_nlu_data.common_examples) {

                this.state.firstQuery.rasa_nlu_data.common_examples.map((object) => {

                    let found = 0;

                    for (let i = 0; i < data.length; i++) {


                        if (object.intent === data[i].name) {


                            if (object.entities && object.entities.length > 0) {
                                data.push({
                                    name: object.intent,
                                    trainingData: [{text: object.text, entities: object.entities}],
                                    status: object.status ? object.status : "active"
                                });
                            } else {
                                if (object.text !== "##empty##") {
                                    data[i].trainingData.push({text: object.text});
                                }
                            }

                            found = 1;
                            break;
                        }

                    }

                    if (found === 0) {
                        if (object.entities) {
                            data.push({
                                name: object.intent,
                                trainingData: [{text: object.text, entities: object.entities}],
                                status: object.status ? object.status : "active"
                            });
                        } else {

                            let dataObj = {
                                name: object.intent,
                                trainingData: [],
                                status: object.status ? object.status : "active"
                            };
                            if (object.text !== "##empty##") {
                                dataObj.trainingData.push({text: object.text});
                            }
                            data.push(dataObj);
                        }
                    }
                });

            }

            //Sort the array in alphabetical order
            data.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });

            this.setState({keyValue: data, firstIntent: data && data.length > 0 ? data[0].name : null});
        }
    }

    handleClose(event) {

        this.props.closeModal();
        this.setState({open: false});

    }

    updateTextField(e) {

        let str = e.target.value;
        str = this.removeUnvantedChars(str);
        this.setState({addValue: str});
        //this.props.updateTrainingData(e.target.value);

    }

    changeIntentFromLog(event, value) {

        if (value && value.name) {

            this.setState({intentToLog: {"name": value.name}});
            this.setState({selectedIntent: value.name});

        }
    }

    async queryIntentModel() {
        let model = this.props.model;
        if(this.state.model){
            model = this.state.model;

        }
        let url = '/api/trainingDataJson?model=' + model;
        const response = await fetch(url);

        const data = await response.json();
        this.setState({firstQuery: data}, ()=>{
            this.convertData();
        });


    }


    removeUnvantedChars(str) {
        if (/([\?\&\/\_\#\:\\+])/.test(str)) {
            str = str.replace(/([\?\&\/\_\\+ ])/g, '');
        }
        return str;
    }

    addNewTrainingFromLog(e) {

        this.setState({addValue: this.state.addValue.trim()});

        let keyValue = this.state.keyValue;
        keyValue.map( (obj)=>{

            if(this.state.intentToLog.name === obj.name){
                obj.trainingData.push({"text":decodeURIComponent(this.state.addValue.trim())});
            }
        });

        this.updateDataModelInDb();

        this.setState({openAddFromQueryLog: false});

    }

    render() {
        const {classes} = this.props;
        return (
            <Modal
                aria-labelledby="Add training sentence from Query Log"
                aria-describedby=" Add training sentence from Query Log"
                open={this.state.open}
                onClose={this.handleClose}
            >
                <div style={getModalStyle()} className={classes.modal}>
                    <div>
                        <Typography variant="h6" id="modal-title">
                            Add from Query Log
                        </Typography>

                        <Typography variant="subtitle2" id="simple-modal-description" style={{marginTop: "40px"}}>
                            <TextField label="Training Sentence" variant="outlined" style={{width: "400px"}}
                                       onChange={this.updateTextField}
                                       value={this.state.addValue}/>

                        </Typography>

                        <Typography variant="subtitle2" id="simple-modal-description" style={{marginTop: "40px"}}>
                        <Autocomplete
                            id="modelBox"
                            options={this.state.savedModels}
                            onChange={this.changeModel}
                            value={this.state.modelValue}
                            getOptionLabel={option => option.Model}
                            style={{width: 400}}
                            renderInput={params => <TextField {...params}
                                                              variant="outlined"
                                                              label="Model"
                                                              style={{}}/>}/>
                        </Typography>

                        <Typography variant="subtitle1" id="simple-modal-description" style={{marginTop: "30px"}}>
                            <FormControl variant="outlined" style={{borderColor: "black"}}
                                         className={classes.formControl}>
                                <Autocomplete
                                    id="combo-box3"
                                    options={this.state.keyValue}
                                    onChange={this.changeIntentFromLog}
                                    value={this.state.intentToLog || null}
                                    getOptionLabel={option => option.name}
                                    style={{width: 400}}
                                    renderInput={params => <TextField {...params} variant="outlined" label="Intent"
                                                                      style={{minHeight: "100px"}}/>}
                                />
                            </FormControl>
                        </Typography>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Typography variant="subtitle2" id="simple-modal-description">
                                <Button style={{width: "100%", minWidth: "190px"}} color="primary"
                                        onClick={this.addNewTrainingFromLog} variant="contained">Add</Button>
                            </Typography>
                            <Typography variant="subtitle2" id="simple-modal-description"
                                        style={{marginLeft: "20px"}}>
                                <Button style={{width: "100%", minWidth: "190px"}} color="primary"
                                        onClick={this.handleClose} variant="contained">Cancel</Button>
                            </Typography>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withStyles(styles)(AddFromQueryLog);
