import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ReactDOM from "react-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    menuItem: {

        fontSize: "10px"

    }


});


class ResponseOnIntent extends Component {


    constructor(props) {

        super(props);

        //this.listRow = this.listRow.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.pass = this.pass.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.loadOpenWorkFlow = this.loadOpenWorkFlow.bind(this);
        this.toggleIcon = this.toggleIcon.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.getCommon = this.getCommon.bind(this);
        this.state = {
            workFlowRender: null,
            commonWorkflows: [{"Id": ""}],
            selectedWorkflow: {"Id": ""},
            labelWidth: 0,
            checked: false,
            showIcon: false,
            dontShowSettings: false,
            contextSwitch: false
        };


    }

    handleCheckbox(e) {

        this.setState({checked: e.target.checked});

        let initiateAiWithResponse = this.props.value.initiateAiWithResponse
        let workflow = {
            type: "vergicAi.responseOnIntent",
            "initiateAiWithResponse": initiateAiWithResponse,
            "account": this.props.account,

        };
        if (e.target.checked) {
            workflow.replace = true;
        }

        this.props.settingsChange(this.props.id, workflow);

    }

    toggleIcon(e) {

        // console.log(e.currentTarget.innerText)
        if (this.state.showIcon) {
            this.setState({showIcon: false});
        } else {
            this.setState({showIcon: true});
        }


    }

    componentDidMount() {



        if (this.props.value) {

            if (this.props.value.category) {
                this.setState({selectedWorkflow: {"Id": this.props.value.initiateAiWithResponse + ": " + this.props.value.category}})
            } else {
                this.setState({selectedWorkflow: {"Id": this.props.value.initiateAiWithResponse}})
            }

            if (this.props.value.replace) {
                this.setState({checked: true});
            }
        }

        if (this.props.dontShowSettings) {

            this.setState({dontShowSettings: true})

        }

        if (this.props.replace) {

            this.setState({checked: this.props.replace});
        }

        if(this.props.contextSwitch){

            this.setState({contextSwitch: true});
        }


        setTimeout(()=>{this.getAllCommonWorkflows()    }, 1000);

    }



    componentDidUpdate(prevProps, prevState, snapShot) {

        if ((prevProps && prevProps.value !== this.props.value)) {

            if (this.props.value && this.props.value.initiateAiWithResponse) {

                if (this.props.value.category) {

                    this.setState({selectedWorkflow: {"Id": this.props.value.initiateAiWithResponse + ": " + this.props.value.category}});
                } else {

                    this.setState({selectedWorkflow: {"Id": this.props.value.initiateAiWithResponse}});
                }

            }
        }
        if ((prevProps && prevProps.replace !== this.props.replace)) {


            this.setState({checked: this.props.replace});
        }

        if ((prevProps && prevProps.newNameResponseOnIntent !== this.props.newNameResponseOnIntent)) {

            this.setState({selectedWorkflow: {"Id": this.props.newNameResponseOnIntent}});
            let workflow = {
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.props.newNameResponseOnIntent,
                "account": this.props.account,

            };

            if (this.state.checked) {
                workflow.replace = true;
            }

            if (!this.props.option) {
                this.props.passData("vergicAi.responseOnIntent", workflow);
            } else {
                this.props.onChange(workflow);
            }
        }

    }


    pass() {

        let workflow = {
            type: "vergicAi.responseOnIntent",
            "initiateAiWithResponse": this.state.selectedWorkflow.Id,
            "category": this.state.selectedWorkflow.Category,
            "account": this.props.account

        };

        if (this.state.checked) {
            workflow.replace = true;
        }

        this.props.passData("vergicAi.responseOnIntent", workflow);

    }

    loadOpenWorkFlow() {
        this.props.openWorkflow(this.state.selectedWorkflow.Id);

    }

    openWorkflow() {

        this.props.openWorkflow(this.state.selectedWorkflow.Id);

    }
    getCommon(){

        this.getAllCommonWorkflows();
    }

    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");

                if (arraySplit.length > 3) {

                    workflowArray.push({
                        "Id": arraySplit[arraySplit.length - 1] + ": " + arraySplit[arraySplit.length - 2],
                        "Category": arraySplit[arraySplit.length - 2]
                    });

                } else {
                    let found = false;
                    workflowArray.map((o) => {

                        if (o.Id === arraySplit[arraySplit.length - 1]) {

                            found = true;
                        }

                    });
                    if (!found) {
                        workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                    }
                }

            });
            //   console.log(this.props.workflowCreate);

            if (this.props.workflowCreate) {

                workflowArray.push({"Id": "[New Workflow]"});
            }
            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })

            this.setState({commonWorkflows: workflowArray});


        }


    }

    handleChange(event, value) {


        if (this.props.option && value && value.Id === "[New Workflow]") {

            this.props.onChange({
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": "New Workflow",
                "account": this.props.account
            });
        } else if (!this.props.option && value && value.Id === "[New Workflow]") {

            let workflow = {
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": "New Workflow",
                "account": this.props.account
            };
            this.props.passData("vergicAi.responseOnIntent", workflow);

        } else if (this.props.option && value && value.Id) {

            let initiateAiWithResponse = value.Id;
            /* let categorySplit =   value.Id.split(":");
             if(categorySplit.length === 2){
                 initiateAiWithResponse = categorySplit[0];
             }*/


            let workflow = {
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": initiateAiWithResponse,
                "account": this.props.account
            };

            if (this.state.checked) {

                workflow.replace = true;
            }

            if (value.Category && value.Category !== "") {
                workflow.category = value.Category;
            }

            this.props.onChange(workflow);
        }


        if (value && value.Id) {


            this.setState({selectedWorkflow: value});
        }
    }


/*    listRow() {

        if (this.state.commonWorkflows) {
            return this.state.commonWorkflows.map((object, i) => {
                return <MenuItem classes={{root: this.props.classes.menuItem}} key={i}
                                 value={object.Id}>{object.Id}</MenuItem>

            });
        }

    }*/

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "20px"}}>

                    <FormControl variant="outlined" style={{borderColor: "black"}} className={classes.formControl}>

                        <Autocomplete
                            options={this.state.commonWorkflows}
                            getOptionSelected={(option, value) => option.value === value.value}
                            onChange={this.handleChange}
                            defaultValue={this.state.commonWorkflows[0]}
                            value={this.state.selectedWorkflow}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%"}}
                            renderInput={params => <TextField {...params} label="Workflows" variant="outlined"/>}
                        />


                    </FormControl>
                    {!this.state.dontShowSettings && <div>
                        {!this.state.showIcon ? <div id="closed" style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                            marginTop: "10px",
                            marginLeft: "10px"
                        }} onClick={this.toggleIcon}><h4>Settings</h4> <ExpandMoreIcon/></div> :
                        <div style={{display: "flex", flexDirection: "column", marginTop: "10px", marginLeft: "10px"}}>
                            <div
                                style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}
                                id="open" onClick={this.toggleIcon}><h4>Settings</h4> <ExpandLessIcon/></div>
                            <div style={{display: "flex", flexDirection: "column"}}>

                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.checked}
                                                           onChange={this.handleCheckbox}
                                                           name="Replace Current Workflow"/>}
                                        label="Replace Current Workflow"
                                    />
                                </FormGroup></div>
                        </div>} </div>}

                    {this.props.workflowCreate ?
                        <Button style={{width: "100%", marginBottom: "10px", marginTop: "20px"}} variant="contained"
                                color="primary" onClick={this.openWorkflow}>Open Workflow</Button> :
                        <Button style={{width: "100%", marginBottom: "10px", marginTop: "10px"}} variant="contained"
                                color="primary" onClick={this.loadOpenWorkFlow}>Open Workflow</Button>}

                    {!this.props.option &&
                        <div style={{marginTop: "30px"}}>

                            {this.props.value && this.props.value.initiateAiWithResponse ?
                                <Button style={{
                                    width: "100%",
                                    backgroundColor: "green",
                                    color: "white",
                                    fontWeight: "bold"
                                }} variant="contained"
                                        onClick={this.pass}>Update</Button> :
                                <Button style={{
                                    width: "100%",
                                    backgroundColor: "green",
                                    color: "white",
                                    fontWeight: "bold"
                                }} variant="contained"
                                        onClick={this.pass}>Add</Button>}

                        </div>
                    }
                    <div style={{marginBottom: "30px"}}>&nbsp;</div>


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(ResponseOnIntent);
