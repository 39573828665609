import React, {useEffect, useCallback, useState} from 'react';
import Button from "@material-ui/core/Button/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import {getDomains} from "./reused/reusedfunctions.js";
function getModalStyle() {
    const top = 50;
    const left = 50;
    //Comment
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    modal: {
        position: 'absolute',
        width: theme.spacing(160),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        height: "100vh",
        outline: 'none'
    },
    listItemTextSelected: {
        color: "#000000",
        fontSize: "12px",
        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "#FFFFFF"
        }
    },
    listItemText: {

        paddingLeft: '20px',
        fontSize: '15px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        },
        overflow: 'hidden'
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
        marginLeft: -theme.spacing(2)
    },
}));

const TestIntents = (props) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(true);
    const [token, setToken] = useState(null);
    const [trainingData, setTrainingData] = useState([]);
    const [result, setResult] = useState([]);
    const [trainingSentences, setTrainingSentences] = useState(new Map());
    const [finished, setFinished] = useState(false);
    const [nbrSentences, setNbrSentences] = useState(0);
    const [nbrIntents, setNbrIntents] = useState(0);
    const [noSentences, setNoSentences] = useState(false);
    const [editSentence, setEditSentence] = useState("");
    const [editIntent, setEditIntent] = useState("");
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [confidenceThreshold, setConfidenceThreshold] = useState(null);
    const [nbrPassed, setNbrPassed] = useState(0);
    const [domains, setDomains] = useState(null);

    const getConf = async() =>{

        const dom = await getDomains();

        setDomains(dom);

    }

    useEffect( () => {
        getConf();


    }, []);
    const queryModel = useCallback(async (query, intent) => {
        let modelName = props.model;


        let url = "https://" + domains.restDomain.default + "/parse/" + modelName + "?q=";

        let options = {
            headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}
        };

        const response = await fetch(url + query, options);
        const data = await response.json();

        if (data && data.intent) {
            const confidence = Math.round(data.intent.confidence * 100, 2);
            const name = data.intent.name;
            let matched = "true";
            if (data.intent.name === intent) {

                matched = "true";
                setNbrPassed(count=>count+1);
                if ((confidenceThreshold * 100) >= confidence) {
                    matched = "false";
                }
            } else if (data.intent.name !== intent) {
                matched = "false";

            }


            setResult(prevArray => [...prevArray, {intent, query, name, confidence, matched}])

        }

    }, [confidenceThreshold, props.model, result, token, domains]);


    useEffect( () => {
        if(domains){
        setResult([]);
        const getR4Token = async () => {
            let url = '/api/getR4Token';

            const response = await fetch(url);
            const data = await response.json();

            if (data && data.access_token) {

                setToken(data.access_token);

            }
        }

        const getSettings = async () => {

            let url = '/api/getSettings';

            const response = await fetch(url);


            const data = await response.json();
            if (data.length > 0) {

                const settings = JSON.parse(data[0].Value);
                setConfidenceThreshold(settings.ConfidenceThreshold);

            }
        }
        getR4Token();
        getSettings();
        }

    }, [domains, setDomains]);

    useEffect(() => {

        setTrainingData(props.trainingData);

    }, [props.trainingData]);

    const processTrainingSentences = useCallback(async () => {
        const keys = Array.from(trainingSentences.keys());

        const queryPromise = [];
        let counter = 0;
        let counterIntents = 0;

        keys.forEach((key) => {
            let sentences = trainingSentences.get(key);
            if (sentences && sentences.length > 0) {
                counterIntents = counterIntents + 1;
            }
            sentences.forEach((sentence) => {
                counter = counter + 1;
                queryPromise.push(queryModel(sentence, key));
            });
        });

        await Promise.all(queryPromise);

        if (trainingSentences.size === 0) {
            setNoSentences(true);
        }

        setNbrSentences(counter);
        setNbrIntents(counterIntents);
        setFinished(true);
        setLoading(false);
    }, [queryModel, trainingSentences]);

    useEffect(() => {

        const getTrainingSentences = async (intent) => {

            let url = '/api/response?intent=' + intent + "&model=" + props.account + "_" + props.model + "_" + intent;

            const response = await fetch(url);

            const data = await response.json();

            try {
                if (data.length > 0) {
                    const meta = JSON.parse(data[0].Response);

                    if (meta && meta.trainingSentences) {
                        if (meta.trainingSentences.length > 0) {
                            setTrainingSentences(new Map(trainingSentences.set(intent, meta.trainingSentences)));
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }

        };


        if (trainingData && domains) {

            if (trainingData.length > 0) {
                const promises = trainingData.map(intent => getTrainingSentences(intent.name));
                Promise.all(promises).then(() => processTrainingSentences());
            }

        }

    }, [trainingData, domains, setDomains]);

    useEffect(() => {

        if (props.updatedFromtestIntents) {

            setEdit(false);
            props.reset();
        }

    }, [props, props.updatedFromtestIntents]);


    const saveEditedSentence = () => {

        props.addTrainingSentence(editIntent, editSentence);

    }

    const handleEditSentence = (tableMeta) => {

        const intent = tableMeta.rowData[1];
        const sentence = tableMeta.rowData[0];
        setEditIntent(intent);
        setEditSentence(sentence);
        setEdit(true)
    }

    const handleModalClose = (event) => {
        setResult([]);
        setModalOpen(false);
        setFinished(false);
        setNoSentences(false);
        setEdit(false);
        props.modalClose();

    }
    return (

        <div style={{display: "flex", flexDirection: "column", marginTop: "0px", padding: "0px"}}>

            <Modal
                aria-labelledby="Chat GPT"
                aria-describedby="Chat GPT"
                open={modalOpen}
                onClose={handleModalClose}
            >
                <div style={getModalStyle()} className={classes.modal}>
                    <React.Fragment>{loading && <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center"
                    }}><CircularProgress/></div>}</React.Fragment>
                    {!edit && finished === true && result.length > 0 &&
                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <Paper style={{padding: "10px", marginBottom: "10px", marginTop: "10px"}}>

                                <Typography variant="h4" id="modal-title">
                                    Intent Test Result
                                </Typography>
                                <br/>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    backgroundColor: "#E8E8E8",
                                    marginBottom: "20px"
                                }}>

                                    <span style={{display: "flex", flexDirection: "row", fontSize: "16px"}}><span
                                        style={{width: "250px", fontWeight: "bold"}}>Model:</span><span
                                        style={{marginLeft: "20px"}}> {props.model}</span></span>


                                    <span style={{display: "flex", flexDirection: "row", fontSize: "16px"}}><span
                                        style={{
                                            width: "250px",
                                            fontWeight: "bold"
                                        }}>Confidence Threshold:</span><span
                                        style={{marginLeft: "20px"}}>{confidenceThreshold * 100}%</span></span>

                                    <span style={{display: "flex", flexDirection: "row", fontSize: "16px"}}><span
                                        style={{
                                            width: "250px",
                                            fontWeight: "bold"
                                        }}>Number of Tested Intents:</span><span
                                        style={{marginLeft: "20px"}}>{nbrIntents}</span></span>
                                    <span style={{display: "flex", flexDirection: "row", fontSize: "16px"}}><span
                                        style={{
                                            width: "250px",
                                            fontWeight: "bold"
                                        }}>Number of Tested Sentences:</span><span
                                        style={{marginLeft: "20px"}}>{nbrSentences}</span></span>
                                    <span style={{display: "flex", flexDirection: "row", fontSize: "16px"}}><span
                                        style={{
                                            width: "250px",
                                            fontWeight: "bold"
                                        }}>Summary:</span><span
                                        style={{marginLeft: "20px"}}>{"The tested model passed "+Math.round((nbrPassed / nbrSentences) * 100)}% of all tests ({nbrPassed}/{nbrSentences})     </span></span>

                                </div>
                            </Paper>
                            <div style={{maxHeight: "350px"}}>
                            <MUIDataTable

                                data={result?result: []}
                                columns={[
                                    {
                                        name: "query", label: "Sentence", options: {
                                            customBodyRender: (value, tableMeta, updateValue) => {
                                                return (
                                                    <Typography component={'span'} variant="body2" noWrap={true}>
                                                        {value}
                                                    </Typography>
                                                )
                                            }
                                        }
                                    }, {
                                        name: "intent", label: "Intent", options: {
                                            filter: true,
                                            sort: true


                                        }
                                    },
                                    {
                                        name: "name", label: "Matched Intent",
                                        options: {
                                            customHeadLabelRender: (columnMeta) => (
                                                <span style={{whiteSpace: "nowrap"}}>{columnMeta.label}</span>)

                                        }
                                    },
                                    {name: "confidence", label: "Confidence"}, {
                                        name: "matched", label: "Passed", options: {
                                            customBodyRender: (value, tableMeta, updateValue) => {
                                                return (
                                                    value === "true" ?
                                                        <CheckIcon style={{color: "green", marginLeft: "10px"}}/> :
                                                        <CloseIcon style={{color: "red", marginLeft: "10px"}}/>

                                                )
                                            }
                                        }
                                    },{
                                        name: "edit", label: "Edit", options: {
                                            customBodyRender: (value, tableMeta, updateValue) => {
                                                return (
                                                    <IconButton
                                                        style={{
                                                            color: "#3E51B5",
                                                            marginTop: "0px",
                                                            marginLeft: "-12px"
                                                        }}
                                                        onClick={(event) => handleEditSentence(tableMeta)}
                                                        aria-label="Save"
                                                        component="span">
                                                        <EditIcon/>
                                                    </IconButton>

                                                )
                                            }
                                        }
                                    }
                                ]}
                                options={{
                                    filter: false,
                                    viewColumns: false,
                                    pagination: false,
                                    responsive: "simple",
                                    tableBodyMaxHeight: "387px",
                                    selectableRows: "none",
                                    print: false,
                                    expandableRows: false,
                                    tableBodyHeight: 'auto',
                                    sortOrder: {
                                        name: 'intent',
                                        direction: 'asc'
                                    }

                                }}

                            />
                            </div>
                        </div>


                    }
                    {noSentences === true &&
                        <div style={{fontSize: "18px", fontStyle: "italic"}}>No test sentences defined; please define
                            them within the metadata tab for each intent.</div>}


                    {edit === true && <Paper style={{height: "100%"}}>
                        <div style={{
                            marginBottom: "10px",
                            fontStyle: "italic",
                            padding: "20px",
                            fontSize: "16px"
                        }}>Intent: {editIntent}</div>
                        <div style={{display: "flex", padding: "20px", flexDirection: "row", alignItems: "center"}}>

                            <TextField
                                value={editSentence}
                                onChange={(event) => {
                                    setEditSentence(event.currentTarget.value)
                                }}
                                margin="normal"
                                multiline={false}
                                variant="outlined"
                                label="Sentence"
                                style={{
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "keep-all",
                                    width: "100%",
                                    marginTop: "10px"
                                }}
                            />
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <Button variant="outlined" type="submit" color="primary"
                                        onClick={saveEditedSentence}
                                        style={{
                                            height: "55px",
                                            marginLeft: "10px",
                                            backgroundColor: "#3E51B5",
                                            color: "#FFF"
                                        }}>Save</Button>
                                <Button variant="outlined" type="submit" color="primary"
                                        onClick={() => {
                                            setEdit(false);
                                        }} style={{
                                    height: "55px",
                                    marginLeft: "10px",
                                    backgroundColor: "#3E51B5",
                                    color: "#FFF"
                                }}>Cancel</Button>
                            </div>
                        </div>

                    </Paper>}


                    {edit === false && <div style={{marginTop: "-40px", display: "flex", justifyContent: "flex-end"}}>
                        <Button variant="outlined" type="submit" color="primary"
                                onClick={handleModalClose}
                                style={{
                                    height: "50%",
                                    maxWidth: "50%",
                                    backgroundColor: "#3E51B5",
                                    color: "#FFF"
                                }}>Close</Button>
                    </div>}
                </div>

            </Modal>

        </div>
    );
};
export default TestIntents;
