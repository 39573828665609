import React, {useCallback, useEffect, useState} from 'react';
import 'reactflow/dist/style.css';
import '../index.css';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button/Button";
import LayoutFlow from "./LayoutFlow";
import {MarkerType} from 'reactflow';
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";
import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";

const FlowOverview = (props) => {

    const [workflows, setWorkflows] = useState([]);
    const [account, setAccount] = useState("");
    const [model, setModel] = useState("");
    const [intent, setIntent] = useState("");
    const [renderedFlows, setRenderedFlows] = useState(new Map());
    const [selectedWorkflow, setSelectedWorkflow] = useState("");
    const [selectedExecuted, setSelectedExecuted] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [numberLinked, setNumberLinked] = useState(0);
    const [renderWork, setRenderWork] = useState(false);
    const [renderFirstWork, setRenderFirstWork] = useState(false);
    const [intentOverview, setIntentOverview] = useState(false);

    useEffect(() => {

        if (props.account) {
            setAccount(props.account)
        }

        if (props.model) {
            setModel(props.model)
        }

        if (props.intentOverview) {
            nodes.length = 0;
            edges.length = 0;
            setIntentOverview(true);
            getResponse(props.intentOverview);

            setTimeout(()=>{renderFlow(); }, 3000);

        }

        if (props.flow) {

            setIntent("");
            setNodes([]);
            setEdges([]);

            setSelectedWorkflow(props.flow);
            setRenderFirstWork(true);
            handleWorkflowChange(null, props.flow);
            setTimeout(renderFlow, 3000);
        }
        getAllCommonWorkflows();

    }, [account, model]);

    const traverseWorkflow = (workflow, workflowName) => {

        workflow.map((action) => {

            if (action.type === "case.entitySwitch") {

                if (action.default && action.default.type === "vergicAi.responseOnIntent") {

                    if (action.default.initiateAiWithResponse) {
                        if (action.default.initiateAiWithResponse !== " ") {

                            nodes.push({
                                id: action.default.initiateAiWithResponse,
                                data: {label: action.default.initiateAiWithResponse},
                                style: {
                                    backgroundColor: '#D83563',
                                    color: '#FFF',
                                    border: '1px solid #36424E',
                                    padding: 10
                                },
                                position: {x: 0, y: 0},
                            });
                            edges.push({
                                id: 'e' + action.default.initiateAiWithResponse + Math.random(),
                                source: intent,
                                target: action.default.initiateAiWithResponse,
                                type: 'smoothstep',
                                animated: false,
                                label: "Default",
                                markerEnd: {
                                    type: MarkerType.Arrow,
                                },
                            });

                            getWorkflowResponseFromDb(action.default.initiateAiWithResponse);
                            renderedFlows.set(action.default.initiateAiWithResponse, 1);

                        }
                    }
                }

                if (action.switches) {
                    action.switches.map((swi) => {
                        if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {
                            if (swi.onMatch.initiateAiWithResponse) {
                                if (swi.onMatch.initiateAiWithResponse !== " ") {
                                    nodes.push({
                                        id: swi.onMatch.initiateAiWithResponse,
                                        data: {label: swi.onMatch.initiateAiWithResponse},
                                        style: {
                                            backgroundColor: '#D83563',
                                            color: '#FFF',
                                            border: '1px solid #36424E',
                                            padding: 10
                                        },
                                        position: {x: 0, y: 0},
                                    });

                                    edges.push({
                                        id: 'e' + swi.onMatch.initiateAiWithResponse + Math.random(),
                                        source: workflowName,
                                        target: swi.onMatch.initiateAiWithResponse,
                                        type: 'smoothstep',
                                        label: "Entity: "+ swi.accepts.toString(),
                                        animated: false,
                                        markerEnd: {
                                            type: MarkerType.Arrow,
                                        },
                                    });
                                    getWorkflowResponseFromDb(swi.onMatch.initiateAiWithResponse);
                                    renderedFlows.set(swi.onMatch.initiateAiWithResponse, 1);
                                }
                            }
                        }
                    });
                }
            }
            if (action.type === "case.contextSwitch") {


               if (action.default && action.default.type === "vergicAi.responseOnIntent") {

                    if (action.default.initiateAiWithResponse) {
                        if (action.default.initiateAiWithResponse !== " ") {

                            nodes.push({
                                id: action.default.initiateAiWithResponse,
                                data: {label: action.default.initiateAiWithResponse},
                                style: {
                                    backgroundColor: '#D83563',
                                    color: '#FFF',
                                    border: '1px solid #36424E',
                                    padding: 10
                                },
                                position: {x: 0, y: 0},
                            });
                            edges.push({
                                id: 'e' + action.default.initiateAiWithResponse + Math.random(),
                                source: workflowName,
                                target: action.default.initiateAiWithResponse,
                                type: 'smoothstep',
                                label: "Default",
                                animated: false,
                                markerEnd: {
                                    type: MarkerType.Arrow,
                                },
                            });

                            getWorkflowResponseFromDb(action.default.initiateAiWithResponse);
                            renderedFlows.set(action.default.initiateAiWithResponse, 1);

                        }
                    }
                }

                if (action.switches) {
                    action.switches.map((swi) => {
                        if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {
                            if (swi.onMatch.initiateAiWithResponse) {
                                if (swi.onMatch.initiateAiWithResponse !== " ") {

                                    if (intent !== "") {
                                        edges.push({
                                            id: 'e' + swi.onMatch.initiateAiWithResponse + Math.random(),
                                            source: intent,
                                            target: workflowName,
                                            type: 'smoothstep',
                                            animated: false,
                                            markerEnd: {
                                                type: MarkerType.Arrow,
                                            },
                                        });
                                        setIntent("");
                                    }


                                    nodes.push({
                                        id: swi.onMatch.initiateAiWithResponse,
                                        data: {label: swi.onMatch.initiateAiWithResponse},
                                        style: {
                                            backgroundColor: '#D83563',
                                            color: '#FFF',
                                            border: '1px solid #36424E',
                                            padding: 10
                                        },
                                        position: {x: 0, y: 0},
                                    });

                                    edges.push({
                                        id: 'e' + swi.onMatch.initiateAiWithResponse + Math.random(),
                                        source: workflowName,
                                        target: swi.onMatch.initiateAiWithResponse,
                                        type: 'smoothstep',
                                        animated: false,
                                        label: "Context value: "+ swi.accepts.toString(),
                                        markerEnd: {
                                            type: MarkerType.Arrow,
                                        },
                                    });
                                    getWorkflowResponseFromDb(swi.onMatch.initiateAiWithResponse);
                                    renderedFlows.set(swi.onMatch.initiateAiWithResponse, 1);
                                }
                            }
                        }
                    });
                }
            }
            if (action.type === "website.options") {
                if (action.options) {
                    action.options.map((match) => {
                        if (match.onMatch && match.onMatch.type === "vergicAi.responseOnIntent") {

                            if (match.onMatch.initiateAiWithResponse !== " ") {
                                nodes.push({
                                    id: match.onMatch.initiateAiWithResponse,
                                    data: {label: match.onMatch.initiateAiWithResponse},
                                    style: {
                                        backgroundColor: '#D83563',
                                        color: '#FFF',
                                        border: '1px solid #36424E',
                                        padding: 10
                                    },
                                    position: {x: 0, y: 0},
                                });

                                edges.push({
                                    id: 'e' + match.onMatch.initiateAiWithResponse + Math.random(),
                                    source: workflowName,
                                    target: match.onMatch.initiateAiWithResponse,
                                    type: 'smoothstep',
                                    animated: false,
                                    label: "Option:"+ match.text,
                                    labelBgStyle: { fill: '#F2F2F2', color: '#515665', fillOpacity: 1    },
                                    markerEnd: {
                                        type: MarkerType.Arrow,
                                    },
                                });
                                getWorkflowResponseFromDb(match.onMatch.initiateAiWithResponse);
                                renderedFlows.set(match.onMatch.initiateAiWithResponse, 1);
                            }
                        }
                    });
                    setNodes(nodes);
                    setEdges(edges);
                }
            }
            if (action.type === "vergicAi.responseOnIntent") {

                let prefix = "";

                if (renderedFlows && renderedFlows.get(action.initiateAiWithResponse)) {

                    prefix = renderedFlows.get(action.initiateAiWithResponse);

                }

                if (action.initiateAiWithResponse !== " ") {
                    nodes.push({
                        id: prefix + action.initiateAiWithResponse,
                        data: {label: action.initiateAiWithResponse},
                        style: {
                            backgroundColor: '#D83563',
                            color: '#FFF',
                            border: '1px solid #36424E',
                            padding: 10
                        },
                        position: {x: 0, y: 0},
                    });


                    edges.push({
                        id: 'e' + prefix + action.initiateAiWithResponse + Math.random(),
                        source: workflowName,
                        target: prefix + action.initiateAiWithResponse,
                        type: 'smoothstep',
                        animated: false,
                        markerEnd: {
                            type: MarkerType.Arrow,
                        },
                    });

                    getWorkflowResponseFromDb(action.initiateAiWithResponse);

                    renderedFlows.set(action.initiateAiWithResponse, 1);
                }

            }
            if (action.type === "case.checkIfContextIsSet") {
                if (action.onMatch) {

                    if (action.onMatch.type === "vergicAi.responseOnIntent") {
                        if (action.onMatch.initiateAiWithResponse !== " ") {
                            nodes.push({
                                id: action.onMatch.initiateAiWithResponse,
                                data: {label: action.onMatch.initiateAiWithResponse},
                                style: {
                                    backgroundColor: '#D83563',
                                    color: '#FFF',
                                    border: '1px solid #36424E',
                                    padding: 10
                                },
                                position: {x: 0, y: 0},
                            });
                            edges.push({
                                id: 'e' + action.onMatch.initiateAiWithResponse + Math.random(),
                                source: workflowName,
                                target: action.onMatch.initiateAiWithResponse,
                                type: 'smoothstep',
                                markerEnd: {
                                    type: MarkerType.Arrow,
                                },
                                animated: false
                            });
                            getWorkflowResponseFromDb(action.onMatch.initiateAiWithResponse);
                            renderedFlows.set(action.onMatch.initiateAiWithResponse, 1);
                        }
                    }
                }
                if (action.onFailure) {
                    if (action.onFailure.type === "vergicAi.responseOnIntent") {
                        if (action.onFailure.initiateAiWithResponse !== " ") {
                            nodes.push({
                                id: action.onFailure.initiateAiWithResponse,
                                data: {label: action.onFailure.initiateAiWithResponse},
                                style: {
                                    backgroundColor: '#D83563',
                                    color: '#FFF',
                                    border: '1px solid #36424E',
                                    padding: 10
                                },
                                position: {x: 0, y: 0},
                            });

                            edges.push({
                                id: 'e' + action.onFailure.initiateAiWithResponse + Math.random(),
                                source: workflowName,
                                target: action.onFailure.initiateAiWithResponse,
                                type: 'smoothstep',
                                animated: false,
                                markerEnd: {
                                    type: MarkerType.Arrow,
                                },
                            });
                            getWorkflowResponseFromDb(action.onFailure.initiateAiWithResponse);
                            renderedFlows.set(action.onFailure.initiateAiWithResponse, 1);
                        }

                    }
                }
            }

        });


    }

    const getWorkflowResponseFromDb = async (workflowName) => {


        setNumberLinked(numberLinked + 1);


        if (renderedFlows.get(workflowName) || (workflowName === selectedWorkflow && selectedExecuted) || numberLinked > 100) {
            return;
        }

        if (workflowName === selectedWorkflow && !selectedExecuted) {
            setSelectedExecuted(true);
        }

        let url = '/api/getWorkflowResponseFromDb?responseId=' + props.account + '_common_' + workflowName;


        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        if (data && data.length > 0 && data[0].Value) {

            try {

                const workflow = JSON.parse(data[0].Value);

                traverseWorkflow(workflow, workflowName);
            } catch (error) {

                console.log(error)
            }

        }

    }

    const setStartFlow = (name) => {

        setRenderedFlows(new Map());
        setRenderWork(false);

        nodes.length = 0;
        edges.length = 0;
        setNumberLinked(0);
        nodes.push({
            id: name,
            type: 'input',
            data: {label: "Intent Response: " + name},
            style: {backgroundColor: '#D83563', color: '#FFF', border: '1px solid #36424E', padding: 10},
            position: {x: 0, y: 0},
        });

        getWorkflowResponseFromDb(name);

    }

    const handleWorkflowChange = (event, value) => {

        setRenderedFlows(new Map());
        setSelectedWorkflow(value);
        setRenderWork(false);
        nodes.length = 0;
        edges.length = 0;

        setNumberLinked(0);
        nodes.push({
            id: value,
            type: 'input',
            data: {label: value},
            style: {backgroundColor: '#D83563', color: '#FFF', border: '1px solid #36424E', padding: 10},
            position: {x: 0, y: 0},
        });

        getWorkflowResponseFromDb(value)

    }

    const renderFlow = (event) => {

        setRenderWork(true);

    }
    const getAllCommonWorkflows = async () => {

        let url = '/api/getAllCommonWorkflows';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );
        const data = await response.json();
        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];

            data.map((object) => {

                let arraySplit = object.Id.split("_");
                let name = arraySplit[arraySplit.length - 1];
                workflowArray.push(name);

            });
            console.log(workflowArray)
            setWorkflows(workflowArray);

        }
    }


    const getResponse = async (intent) => {

        let url = '/api/response?intent=' + intent + "&model=" + account + "_" + model + "_" + intent;

        const response = await fetch(url);

        const data = await response.json();

        if (data.length > 0) {
            const object = data[0];

            if (object.Response) {
                const resp = JSON.parse(object.Response);

                if (resp.workflow) {
                    const workflow = resp.workflow;
                    const workflowParsed = JSON.parse(workflow);

                    workflowParsed.map((action) => {
                        if (action.type === "vergicAi.responseOnIntent") {

                            //setSelectedWorkflow(action.initiateAiWithResponse);
                            setRenderFirstWork(true);
                            setIntent(intent);

                            setStartFlow(intent);

                            if (action && action.type === "vergicAi.responseOnIntent") {

                                if (action.initiateAiWithResponse) {
                                    if (action.initiateAiWithResponse !== " ") {

                                        nodes.push({
                                            id: action.initiateAiWithResponse,
                                            data: {label: action.initiateAiWithResponse},
                                            style: {
                                                backgroundColor: '#D83563',
                                                color: '#FFF',
                                                border: '1px solid #36424E',
                                                padding: 10
                                            },
                                            position: {x: 0, y: 0},
                                        });
                                        edges.push({
                                            id: 'e' + action.initiateAiWithResponse + Math.random(),
                                            source: intent,
                                            target: action.initiateAiWithResponse,
                                            type: 'smoothstep',
                                            animated: false,
                                            markerEnd: {
                                                type: MarkerType.Arrow,
                                            },
                                        });

                                        getWorkflowResponseFromDb(action.initiateAiWithResponse);
                                        renderedFlows.set(action.initiateAiWithResponse, 1);

                                    }
                                }
                            }

                            //setTimeout(renderFlow, 3000);

                        } else if (action.type === "case.contextSwitch") {

                            setStartFlow(intent);

                            if (action.default && action.default.type === "vergicAi.responseOnIntent") {

                                if (action.default.initiateAiWithResponse) {
                                    if (action.default.initiateAiWithResponse !== " ") {

                                        nodes.push({
                                            id: action.default.initiateAiWithResponse,
                                            data: {label: action.default.initiateAiWithResponse},
                                            style: {
                                                backgroundColor: '#D83563',
                                                color: '#FFF',
                                                border: '1px solid #36424E',
                                                padding: 10
                                            },
                                            position: {x: 0, y: 0},
                                        });
                                        edges.push({
                                            id: 'e' + action.default.initiateAiWithResponse + Math.random(),
                                            source: intent,
                                            target: action.default.initiateAiWithResponse,
                                            type: 'smoothstep',
                                            animated: false,
                                            label: "Default",
                                            markerEnd: {
                                                type: MarkerType.Arrow,
                                            },
                                        });

                                        getWorkflowResponseFromDb(action.default.initiateAiWithResponse);
                                        renderedFlows.set(action.default.initiateAiWithResponse, 1);

                                    }
                                }
                            }

                            if (action.switches) {
                                action.switches.map((swi) => {

                                    if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {
                                        if (swi.onMatch.initiateAiWithResponse) {
                                            if (swi.onMatch.initiateAiWithResponse !== " ") {

                                                nodes.push({
                                                    id: swi.onMatch.initiateAiWithResponse,
                                                    data: {label: swi.onMatch.initiateAiWithResponse},
                                                    style: {
                                                        backgroundColor: '#D83563',
                                                        color: '#FFF',
                                                        border: '1px solid #36424E',
                                                        padding: 10
                                                    },
                                                    position: {x: 0, y: 0},
                                                });
                                                edges.push({
                                                    id: 'e' + swi.onMatch.initiateAiWithResponse + Math.random(),
                                                    source: intent,
                                                    target: swi.onMatch.initiateAiWithResponse,
                                                    label: "Context value: "+ swi.accepts.toString(),
                                                    type: 'smoothstep',
                                                    animated: false,
                                                    markerEnd: {
                                                        type: MarkerType.Arrow,
                                                    },
                                                });

                                                getWorkflowResponseFromDb(swi.onMatch.initiateAiWithResponse);
                                                renderedFlows.set(swi.onMatch.initiateAiWithResponse, 1);

                                            }
                                        }
                                    }
                                });
                            }

                        } else if (action.type === "case.entitySwitch") {
                            setStartFlow(intent);

                            if (action.default && action.default.type === "vergicAi.responseOnIntent") {

                                if (action.default.initiateAiWithResponse) {
                                    if (action.default.initiateAiWithResponse !== " ") {

                                        nodes.push({
                                            id: action.default.initiateAiWithResponse,
                                            data: {label: action.default.initiateAiWithResponse},
                                            style: {
                                                backgroundColor: '#D83563',
                                                color: '#FFF',
                                                border: '1px solid #36424E',
                                                padding: 10
                                            },
                                            position: {x: 0, y: 0},
                                        });
                                        edges.push({
                                            id: 'e' + action.default.initiateAiWithResponse + Math.random(),
                                            source: intent,
                                            target: action.default.initiateAiWithResponse,
                                            type: 'smoothstep',
                                            animated: false,
                                            label: "Default",
                                            markerEnd: {
                                                type: MarkerType.Arrow,
                                            },
                                        });

                                        getWorkflowResponseFromDb(action.default.initiateAiWithResponse);
                                        renderedFlows.set(action.default.initiateAiWithResponse, 1);

                                    }
                                }
                            }

                            if (action.switches) {
                                action.switches.map((swi) => {
                                    if (swi.onMatch && swi.onMatch.type === "vergicAi.responseOnIntent") {
                                        if (swi.onMatch.initiateAiWithResponse) {
                                            if (swi.onMatch.initiateAiWithResponse !== " ") {
                                                nodes.push({
                                                    id: swi.onMatch.initiateAiWithResponse,
                                                    data: {label: swi.onMatch.initiateAiWithResponse},
                                                    style: {
                                                        backgroundColor: '#D83563',
                                                        color: '#FFF',
                                                        border: '1px solid #36424E',
                                                        padding: 10
                                                    },
                                                    position: {x: 0, y: 0},
                                                });

                                                edges.push({
                                                    id: 'e' + swi.onMatch.initiateAiWithResponse + Math.random(),
                                                    source: intent,
                                                    target: swi.onMatch.initiateAiWithResponse,
                                                    type: 'smoothstep',
                                                    animated: false,
                                                    label: "Entity: "+ swi.accepts.toString(),
                                                    markerEnd: {
                                                        type: MarkerType.Arrow,
                                                    },
                                                });
                                                getWorkflowResponseFromDb(swi.onMatch.initiateAiWithResponse);
                                                renderedFlows.set(swi.onMatch.initiateAiWithResponse, 1);
                                            }
                                        }
                                    }
                                });
                            }

                        }
                    });

                }
            }
        }
    }

    const openWorkflow = (name) => {
        nodes.length = 0;
        edges.length = 0;
        setRenderedFlows(new Map());
        setIntent("");
        setRenderWork(false);
        props.openWorkflow(name);
        props.closeOverview();

    }
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}
                 className="flowoverview">
                {!intentOverview &&
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "center", width: "100%"}}>
                <Autocomplete
                    options={workflows}
                    onChange={handleWorkflowChange}
                    defaultValue={workflows[0]}
                    value={selectedWorkflow ? selectedWorkflow : ""}
                    getOptionLabel={option => option}
                    style={{width: "400px", marginTop: "20px", marginBottom: "14px"}}
                    renderInput={params => <TextField  {...params} label="Workflow" variant="outlined"/>}
                />
                <Button style={{
                    marginLeft: "5px",
                    padding: "16px",
                    marginTop: "5px"
                }}
                        variant="contained" color="primary" onClick={renderFlow}>
                    Update</Button>
            </div>}
                <div style={{width: "100%"}}>
                    <IconButton variant={"contained"} color="primary" style={{display: "flex", float: "right", marginBottom: "0px", marginRight: "5px"}}
                                onClick={() => {
                                    setIntent("");
                                    props.closeOverview();


                                }} aria-label="Close" size="medium">
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                </div>


            </div>
            {renderWork &&

                <LayoutFlow openWorkflow={openWorkflow} nodes={nodes} edges={edges}/>
            }
        </div>

    );
};

export default FlowOverview;
