import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button/Button";
import Modal from "@material-ui/core/Modal";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { set } from "date-fns";
import { Check } from "@material-ui/icons";
import CheckBox from "@material-ui/icons/CheckBox";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { getWorkflowResponseFromDb } from "./reused/reusedfunctions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getModalStyle() {
  const top = 50;
  const left = 50;
  //  Comment
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modal: {
    position: "absolute",
    width: theme.spacing(50),
    minWidth: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  modalResult: {
    position: "absolute",
    width: theme.spacing(150),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },
}));
const ChatGpt = (props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(true);
  const [modelName, setModelName] = useState("");
  const [firstModelName, setFirstModelName] = useState("");
  const [pipeline, setPipeline] = useState("swedish##se");
  const [loading, setLoading] = useState(false);
  const [intents, setIntents] = useState([]);
  const [intent, setIntent] = useState(null);
  const [baseline, setBaseline] = useState([]);
  const [nbrsentence, setNbrsentence] = useState(5);
  const [currentTrainingSentences, setCurrentTrainingSentences] = useState([]);
  const [trainingSentences, setTrainingSentences] = useState([]);
  const [chatGptData, setChatGptData] = useState("");
  const [selected, setSelected] = useState([]);
  const [dialog, setDialog] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [enableGenerate, setEnableGenerate] = useState(false);
  const [enableTestGenerate, setEnableTestGenerate] = useState(false);
  const [generatedEntitiesChecked, setGeneratedEntitiesChecked] =
    useState(true);

  useEffect(() => {
    if (props.selectedIntent) {
      setIntent(props.selectedIntent);
    }
  }, [props.selectedIntent]);

  //If currentTrainingSentences are set in props use that.
  useEffect(() => {
    if (props.currentTrainingSentences) {
      setCurrentTrainingSentences(props.currentTrainingSentences);      
      setBaseline(props.currentTrainingSentences.length > 1 && props.currentTrainingSentences[0]=== '' ? props.currentTrainingSentences[1] : props.currentTrainingSentences[0]  );
      setEnableGenerate(true);
    }
  }, [props.currentTrainingSentences]);

  useEffect(() => {
    if (props.openModal) {
      setModalOpen(props.openModal);
    }

    if (props.trainingData) {
      let trainMap = new Map();

      props.trainingData.rasa_nlu_data.common_examples.map((obj) => {
        if (trainMap.get(obj.intent)) {
          let intentArray = trainMap.get(obj.intent);
          intentArray.push(obj.text);
          trainMap.set(obj.intent, intentArray);
        } else {
          let intentArray = [];
          intentArray.push(obj.text);
          trainMap.set(obj.intent, intentArray);
        }
      });
      const keys = Array.from(trainMap.keys());

      setCurrentTrainingSentences(trainMap.get(props.selectedIntent));

      setIntents(keys);
    }

    if (props.model) {
      setFirstModelName(props.model);
      setModelName(props.model + "se");
    }

    if (props.assistType) {
      if (props.assistType === "test") {
        setEnableTestGenerate(true);
      }
    }
  }, [props.selectedIntent]);

  useEffect(() => {
    if (chatGptData && !dialog) {
      const arr = chatGptData.split("\n");

      arr.map((obj, i) => {
        selected[i] = false;
      });

      setSelected(selected);
    
      setSelectAll(true);
      setTrainingSentences(arr);
      let selArray = [];

      if (arr.length > 0) {
        arr.map((item, i) => {
          selArray["td" + i.toString()] = true;
        });

        setSelected({ ...selArray });
      }
    }
  }, [chatGptData]);

  useEffect(() => {
    if (chatGptData && dialog) {
      //console.log(chatGptData)
    }
  }, [chatGptData]);

  useEffect(() => {
    let dialog = [];

    if (
      props.dialogData &&
      props.dialogData.length > 0 &&
      props.dialogData[0].messages
    ) {
      props.dialogData[0].messages.forEach((mess) => {
        if (mess.messageType === "chat" && mess.speaker.visitId) {
          dialog.push({ speaker: "customer", text: mess.message });
        } else if (mess.messageType === "chat" && mess.speaker.userId) {
          dialog.push({ speaker: "bot", text: mess.message });
        }
      });
      setDialog(dialog);
    }
  }, [props.dialogData]);

  useEffect(() => {
    if (dialog) {
      analyzeDialog();
    }
  }, [dialog]);

  const handleChange = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  };

  const onSelectAll = function (e) {
    if (e.target.checked) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    let selArray = [];

    if (trainingSentences) {
      trainingSentences.map((item, i) => {
        if (e.target.checked) {
          selArray["td" + i.toString()] = true;
        } else {
          selArray["td" + i.toString()] = false;
        }
      });

      setSelected({ ...selArray });
    }
  };

  const renderSentences = function () {
    return trainingSentences.map((sentence, i) => {
      return (
        <div
          key={Math.random()}
          style={{
            width: "100%",
            backgroundColor: i % 2 === 0 ? "#E8E8E8" : "#FFFFFF",
            marginTop: "10px",
            border: "1px solid #ccc",
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "6%",
              top: "50%",
              textAlign: "center",
              verticalAlign: "bottom",
            }}
          >
            <Checkbox
              checked={selected["td" + i]}
              onChange={handleChange}
              name={"td" + i}
            />
          </div>

          {sentence}
        </div>
      );
    });
  };
  const queryGPT = async function (query) {
    let url = "/api/queryChatGpt?query=" + query;
    setLoading(true);
    const response = await fetch(url);

    const data = await response.json();
    console.log(data);
    setLoading(false);
    setChatGptData(data);
  };

  const handleModalClose = (event) => {
    props.closeChatGpt();
    setModalOpen(false);
  };

  const generateTrainingData = async (event) => {
    let query =
      "I want " +
      nbrsentence +
      " training sentences for a RASA NLU model. The sentences should be consistent to the intention of what " +
      "I'm asking for and try to keep any entity definitions using the format [entity](entity name). My RASA training sentence is: " +
      baseline +
      ". The result should not contain any quotaion marks. The response language should be the same as the language of the training data.";

    const queryNoEntities =
      "I want " +
      nbrsentence +
      " training sentences for a RASA NLU model. The sentences should be consistent to the intention of what " +
      "I'm asking for, do not include entity definitions. My RASA training sentence is: " +
      baseline +
      ". The result should not contain any quotaion marks. The response language should be the same as the language of the training data.";
    if (!generatedEntitiesChecked) {
      query = queryNoEntities;
    }
    const d = await queryGPT(query);
  };

  const generateTestSentences = async (event) => {
    const query =
      "I want " +
      nbrsentence +
      " test sentences for a RASA NLU model. The sentences should test how well my intent " +
      intent +
      " performs. The following training sentences" +
      "are used " +
      currentTrainingSentences.toString() +
      " .The result should not contain any quotaion marks.";

    const d = await queryGPT(query);
  };

  const analyzeDialog = async (event) => {
    const query =
      "Summerize this dialog: " +
      JSON.stringify(dialog) +
      " Take out the sentiment of the customer and what is intent of the customer";
    const d = await queryGPT(query);
  };

  const addSelected = async (event) => {
    let filteredArray = trainingSentences.filter(
      (_, index) => selected["td" + index]
    );

    filteredArray = filteredArray.map((str) => str.replace(/^[0-9.\s]+/, ""));
    filteredArray = filteredArray.map((str) => str.replace(/"/g, ""));

    if (props.assistType === "test") {
      
     props.updateTestSentences([...props.currentSentences, ...filteredArray]);
    } else {
      props.updateSentences(filteredArray);
    }
    props.closeChatGpt();
    setModalOpen(false);
  };

  const baselineChange = (event) => {
    setBaseline(event.target.value);

    if (event.target.value.length > 0) {
      setEnableGenerate(true);
    } else {
      setEnableGenerate(false);
    }
  };

  const handleSentenceChange = (event) => {
    setNbrsentence(event.target.value);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
    >
      <Modal
        aria-labelledby="Chat GPT"
        aria-describedby="Chat GPT"
        open={modalOpen}
        onClose={handleModalClose}
      >
        <div style={getModalStyle()} className={classes.modal}>
          {!props.dialogData && (
            <React.Fragment>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!enableTestGenerate && (
                  <Typography variant="h6" id="modal-title">
                    Generate Training Data
                  </Typography>
                )}

                {props.assistType === "test" && (
                  <Typography variant="h6" id="modal-title">
                    Generate Test Sentences
                  </Typography>
                )}

                {!enableTestGenerate && (
                  <span>
                    Use a first training sentence or paragraph to generate
                    training data.
                  </span>
                )}
                {props.assistType === "test" && (
                  <div>
                    Intent: {intent} will be used to generate test sentences
                  </div>
                )}

                {!enableTestGenerate && (
                  <TextField
                    label="Data"
                    value={baseline}
                    onChange={baselineChange}
                    margin="normal"
                    multiline={false}
                    variant="outlined"
                    style={{}}
                  />
                )}

                <FormControl
                  style={{
                    marginTop: "20px",
                    marginLeft: "15px",
                    width: "35%",
                  }}
                >
                  <InputLabel id="name-label">Number of Sentences</InputLabel>
                  <Select
                    labelId="name-label"
                    id="demo-multiple-name"
                    value={nbrsentence}
                    onChange={handleSentenceChange}
                    input={<OutlinedInput label="Number Of Sentences" />}
                    MenuProps={MenuProps}
                    className={classes.selectEmpty}
                  >
                    <MenuItem key={"5"} value={"5"}>
                      {"5"}
                    </MenuItem>
                    <MenuItem key={"10"} value={"10"}>
                      {"10"}
                    </MenuItem>
                    <MenuItem key={"15"} value={"15"}>
                      {"15"}
                    </MenuItem>
                    <MenuItem key={"20"} value={"20"}>
                      {"20"}
                    </MenuItem>
                  </Select>
                </FormControl>

                {!props.assistType && (
                  <FormGroup
                    row
                    style={{
                      marginTop: "18px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={generatedEntitiesChecked}
                          onChange={(event) =>
                            setGeneratedEntitiesChecked(event.target.checked)
                          }
                          name="Generate Entities"
                        />
                      }
                      label="Generate Entities"
                    />
                  </FormGroup>
                )}
              </div>
            </React.Fragment>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              style={{
                height: "50%",
                maxWidth: "50%",
              }}
              onClick={handleModalClose}
            >
              close
            </Button>
            {enableGenerate && (
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                style={{
                  height: "50%",
                  maxWidth: "50%",
                  marginLeft: "20px",
                }}
                onClick={generateTrainingData}
              >
                Generate
              </Button>
            )}
            {props.assistType === "test" && (
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                style={{
                  height: "50%",
                  maxWidth: "50%",
                  marginLeft: "20px",
                }}
                onClick={generateTestSentences}
              >
                Generate
              </Button>
            )}
          </div>

          {loading && <CircularProgress />}
          {trainingSentences && trainingSentences.length > 0 && (
            <div>
              <Typography variant="h6" id="modal-title">
                Result:
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #CCC",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "11px",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        style={{}}
                        checked={selectAll}
                        id={"selectAll"}
                        onChange={(event) => onSelectAll(event)}
                      />
                      <span style={{ marginLeft: "0px" }}>Select all</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxHeight: "380px",
                        overflowY: "auto",
                      }}
                    >
                      {renderSentences()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {trainingSentences && trainingSentences.length > 0 && (
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              style={{
                height: "50%",
                maxWidth: "50%",
                marginTop: "20px",
              }}
              onClick={addSelected}
            >
              Add Selected
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default ChatGpt;
