import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import IntentMatch from "./IntentMatch";
import SendIcon from '@material-ui/icons/Send';
import Notifier from "./Notifier";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import '../App.scss';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import './styles/training-chat.scss';

class TrainingChat extends Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
        this.state = {
            title: '',
            intentRanking: '',
            chatdialog: [],
            query: '',
            labelWidth: 0,
            commonWorkflows: [],
            matchArray: [],
            visitorDialog: [],
            allIntents: [],
            bestMatch: [],
            matchIndex: [],
            loading: false,
            modelValue: {"Model": ""},
        }
        this.queryVergicAI = this.queryVergicAI.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.createChat = this.createChat.bind(this);
        this.updateModel = this.updateModel.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.setIntent = this.setIntent.bind(this);
        this.getTrainingModel = this.getTrainingModel.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.updateDataModelInDb = this.updateDataModelInDb.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.getAllIntents = this.getAllIntents.bind(this);
        this.changeState = this.changeState.bind(this);
        this.trainDataModel = this.trainDataModel.bind(this);
        this.reactOnClick = this.reactOnClick.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this);
        this.handleIntroFlowChange = this.handleIntroFlowChange.bind(this);
        this.getDomain = this.getDomain.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.getR4Token = this.getR4Token.bind(this);
    }

    async getR4Token() {
        let url = '/api/getR4Token';
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.access_token) {
            this.setState({token: data.access_token});
        }
    }

    changeModel(event, value) {
        if (value && value.Model) {
            localStorage.setItem("model", value.Model);
            this.setState({modelValue: value});
            this.setState({model: value.Model}, () => {
                this.getTrainingModel();
            });
        }
    }

    async getSavedModels() {
        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            const model = localStorage.getItem("model");
            data.map((d, i) => {
                if (model && d.Model === model) {
                    first = d;
                } else if (i === 0) {
                    first = d;
                }
            })
            this.setState({savedModels: data, modelValue: first, model: first.Model});
            this.getTrainingModel();
        }
    }

    async getDomain() {
        let url = "/api/getRestDomain";
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        if (data) {
            this.setState({restDomain: data.restDomain});
            if (data.restDomain.indexOf("r4") > -1) {
                this.setState({r4: true});
            }
        }
    }

    async getSettings() {
        let url = '/api/getSettings';
        const response = await fetch(url);
        const data = await response.json();
        if (data.length > 0) {
            const sett = data[0].Value;
            const settings = JSON.parse(sett);
            this.setState({confidenceThreshold: settings.ConfidenceThreshold});
            this.setState({mappedCaseFlows: settings.CaseTypeMapping});
            let introFlows = [];
            settings.CaseTypeMapping.map((o) => {
                introFlows.push(o.flowName);
            })
            this.setState({introFlows: introFlows});
            this.setState({mappedFallbackCategoryFlows: settings.StrictCategoryMapping});
            let strictCategories = [];
            if (settings.StrictCategoryMapping) {
                settings.StrictCategoryMapping.map((object) => {
                    strictCategories.push(object.category);
                });
                this.setState({strictCategories: strictCategories});
            }
            this.setState({mappedCategoryFlows: settings.CategoryMapping});
            this.setState({fallbackFlow: settings.FallbackFlow});
            this.setState({startFlow: {"Id": settings.StartFlow}});
            this.setState({inactiveFlow: {"Id": settings.InactiveFlow}});
        }
    }

    getAllCategories() {
        let categories = [];
        this.state.commonWorkflows.map((object) => {
            if (object.Category) {
                let found = false;
                categories.map((o) => {
                    if (o.Id === object.Category) {
                        found = true;
                    }
                });
                if (!found) {
                    categories.push({"Id": object.Category});
                }
            }
        });
        this.setState({categories: categories});
    }

    handleIntroFlowChange = (e, index, object) => {
        this.setState({selectedIndex: index});
        this.setState({chatdialog: []})
        this.queryIntentResponse(object, "common", null);
    }

    renderIntroFlowRow() {
        if (this.state.introFlows) {
            return this.state.introFlows.map((object, i) => {
                return <ListItem key={i}
                                 id="list-item-text-selected"
                                 onClick={event => this.handleIntroFlowChange(event, i, object)}
                                 selected={this.state.selectedIndex === i}
                                 autoFocus={this.state.selectedIndex === i}
                                 button
                >
                    <ListItemText id="list-item-text" primary={object}/>
                </ListItem>
            });
        }
    }

    async getAllCommonWorkflows() {
        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0 && data.name !== 'error') {
            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length - 1]) {
                    let found = false;
                    workflowArray.map((o) => {
                        if (o.Id === arraySplit[arraySplit.length - 1]) {
                            found = true;
                        }
                    });
                    if (!found) {
                        if (arraySplit.length > 3) {
                            workflowArray.push({
                                "Id": arraySplit[arraySplit.length - 1],
                                "Category": arraySplit[arraySplit.length - 2]
                            });
                        } else {
                            workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                        }
                    }
                }
            });
            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })
            this.setState({commonWorkflows: workflowArray});
            this.getAllCategories();
        }
    }

    componentDidMount() {
        this.getSavedModels();
        this.getDomain();
        this.getAllCommonWorkflows();
        this.getSettings();
        this.getR4Token();
    }

    reactOnClick = (e, index, workflowToRun) => {
        if (workflowToRun !== "" && workflowToRun.indexOf("URL") === -1) {
            this.queryIntentResponse(workflowToRun, "common", null);
        } else if (workflowToRun.indexOf("URL") > -1) {
            let urlSplit = workflowToRun.split("##");
            window.open(urlSplit[1], "_blank")
        }
    }

    async queryVergicAI(query) {
        let modelName = this.state.model; //.replaceAll("_", "");
        let url = "https://" + this.state.restDomain + "/parse/" + modelName + "?q=";
        let headerObject = {'Content-Type': 'application/json'};
        if (this.state.r4) {
            headerObject = {'Authorization': 'Bearer ' + this.state.token, 'Content-Type': 'application/json'};
        }
        let options = {
            headers: headerObject
        };
        const response = await fetch(url + query, options);
        const data = await response.json();
        await this.setState({intentRanking: [...this.state.intentRanking, data]});
        await this.setState({bestMatch: data.intent.name});
        let keys = this.state.allIntents;
        if (parseFloat(data.intent.confidence) < parseFloat(this.state.confidenceThreshold)) {
            keys.push(this.state.fallbackFlow + " [" + Math.round(data.intent.confidence * 100, 0) + "%]");
            this.queryIntentResponse(this.state.fallbackFlow, "common", null);
            this.state.matchIndex.push(keys.length - 1);
        } else {
            let indexMatch = 0;
            for (let k = 0; k < keys.length; k++) {
                if (keys[k] === this.state.bestMatch) {
                    indexMatch = k;
                    break;
                }
            }
            this.state.matchIndex.push(indexMatch);
            this.queryIntentResponse(data.intent.name, this.state.model, null);
        }
        this.setState({allIntents: keys})
    }

    async queryIntentResponse(intent, model, category) {
        let url = "https://rasa.vergic.ai/kv/" + this.props.account + "/" + model + "/" + intent;
        let headerObject = {'Content-Type': 'application/json'};
        if (this.state.r4) {
            headerObject = {'Authorization': 'Bearer ' + this.state.token, 'Content-Type': 'application/json'};
            url = "https://" + this.state.restDomain + "/kv/" + this.props.account + "_" + model + "_" + intent;
        }
        let options = {
            headers: headerObject
        };
        const response = await fetch(url, options);
        const data = await response.json();
        let workflow = [];
        if (data && data.metaData) {
            this.setState({response: JSON.parse(data.metaData.workflow)});
            workflow = JSON.parse(data.metaData.workflow);
        } else {
            workflow = data;
            this.setState({response: data});
        }
        if (typeof data === 'object' && data !== null) {
            let chatDialog = this.state.chatdialog;
            workflow.map((flow) => {
                if (flow.type === "conversation.message") {
                    chatDialog.push({user: flow.message});
                } else if (flow.type === "website.navigate") {
                    chatDialog.push({user: "Navigate: " + flow.url});
                } else if (flow.type === "website.imageInChat") {
                    chatDialog.push({user: "IMAGE##" + flow.path});
                } else if (flow.type === "website.options") {
                    chatDialog.push({user: "OPTIONS##" + JSON.stringify(flow.options)})
                } else if (flow.type === "vergicAi.responseOnIntent") {
                    if (flow && flow.onMatch && flow.onMatch.category) {
                        this.setState({category: flow.onMatch.category});
                    }
                    this.queryIntentResponse(flow.initiateAiWithResponse, "common", null);
                } else if (flow.type === "vergicAi.setCategory") {
                    this.props.updateModel(flow.category);
                } else {
                    chatDialog.push({user: "ACTION: " + flow.type});
                }
            });
            this.setState({chatdialog: chatDialog}, this.scrollToBottom);
        } else {
            if (this.state.category) {
                this.queryIntentResponse(intent, "common", this.state.category);
            } else if (this.state.strictCategories) {
                this.state.strictCategories.map((category) => {
                    this.queryIntentResponse(intent, "common", category);
                });
            }
        }
    }

    handleQueryChange(e) {
        this.setState({query: e.target.value});
    }

    handleSubmit() {
        let chatdialog = this.state.chatdialog;
        let visitorDialog = this.state.visitorDialog;
        chatdialog.push({visitor: this.state.query});
        this.setState({chatdialog: chatdialog});
        visitorDialog.push(this.state.query);
        this.setState({visitorDialog: visitorDialog});
        this.queryVergicAI(this.state.query);
        this.setState({query: ""});
        setTimeout(this.scrollToBottom, 500);
    }

    updateModel(e) {
        this.updateDataModelInDb()
        this.setState({modelUpdated: false})
    }

    async getTrainingModel() {
        if (this.state.model) {
            let url = '/api/trainingDataJson?model=' + this.state.model;
            const response = await fetch(url);
            const data = await response.json();
            this.setState({trainingData: data});
            this.getAllIntents(data);
        }

    }

    getAllIntents(data) {
        let intentArray = [];
        data.rasa_nlu_data.common_examples.map((object, i) => {
            intentArray[object.intent] = object.intent;
        });
        let keys = Object.keys(intentArray);
        this.setState({allIntents: keys});
    }

    scrollToBottom() {
        if (this.divRef) {
            this.divRef.scrollTop = this.divRef.scrollHeight - this.divRef.clientHeight;
        }
    }

    setIntent = (i, counter, e) => {
        this.state.matchIndex[counter] = e.target.value;
        let text = this.state.visitorDialog[counter];
        let newData = {intent: this.state.allIntents[e.target.value], text: text};
        let trainingData = this.state.trainingData;
        trainingData.rasa_nlu_data.common_examples.map((object, k) => {
            if (object.text.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                trainingData.rasa_nlu_data.common_examples.splice(k, 1);
            }
        });
        trainingData.rasa_nlu_data.common_examples.push(newData);
        this.setState({modelUpdated: true});
        this.setState({updatedTrainingData: trainingData});
    }

    async updateDataModelInDb() {
        let url = '/api/updateModel?id=' + this.state.model + "&account=" + this.props.account;
        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.updatedTrainingData)
        });
        const data = await response.json();
        if (data.length === 0) {
            this.setState({notifierOpen: true});
        }
    }

    createChat(e) {
        let counter = -1;
        return this.state.chatdialog.map((object, i) => {
            if (object.visitor) {
                counter++;
                return <div key={Math.random()} className="chat-dialog-container">
                    <div className="chat-dialog-visitor box sb2">{object.visitor}</div>
                    <div className="chat-dialog-intent">
                        <IntentMatch key={i} counter={counter.toString()} setIntent={(e, c) => this.setIntent(i, c, e)}
                                     data={this.state.allIntents}
                                     intentRanking={this.state.intentRanking[counter.toString()]}
                                     intent={this.state.matchIndex[counter] ? this.state.matchIndex[counter] : 0} />
                    </div>
                </div>
            } else if (object.user) {
                let message = object.user;
                let isOptions = false;
                if (message.indexOf("IMAGE") > -1) {
                    let image = (object.user).split("##");
                    message = <img width="100%" height="100%" src={image[1]} alt="img"/>;
                } else if (message.indexOf("OPTIONS") > -1) {
                    message = [];
                    isOptions = true;
                    let optionsString = (object.user).split("##");
                    let options = JSON.parse(optionsString[1]);
                    options.map((o, i) => {
                        let workflowToRun = "";
                        if (o.onMatch && o.onMatch.type === "vergicAi.responseOnIntent") {
                            workflowToRun = o.onMatch.initiateAiWithResponse;
                        } else if (o.onMatch && o.onMatch.type === "website.navigate") {
                            workflowToRun = "URL##" + o.onMatch.url;
                        }
                        message[i] = (<Button key={i} id="button-text"
                                              onClick={event => this.reactOnClick(event, i, workflowToRun)}
                                              color="primary" variant="contained">{o.text}</Button>);
                    });
                }
                return <div key={Math.random()} className="message-container">
                    <div className="options-chat-dialog-visitor boxUser sb1">{isOptions ?
                        <div className="message-wrapper">{message}</div> : message}</div>
                </div>
            }
        });
    }

    keyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    }

    trainDataModel() {
        let url = '/api/trainModel?model=' + this.state.model + "&pipeline=" + this.props.pipeline;
        this.setState({loading: true});
        fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.updatedTrainingData)
        })
            .then(response => {
                response.json()
                    .then(data => {
                        this.setState({loading: false});
                        this.setState({modelTrained: data.model});
                        this.setState({trainingComplete: data.info});
                        this.setState({notifierTrainOpen: true});

                    })
            })
    }

    changeState() {
        this.setState({notifierOpen: false});
        this.setState({notifierCreateOpen: false});
        this.setState({notifierTrainOpen: false});
    }

    render() {
        return (
            <Paper className="rendered-model-container">
                <div className="rendered-model-wrapper">
                    <div className="rendered-model-content">
                        {this.state.savedModels && this.state.savedModels.length > 1 &&
                            < FormControl className="model-input-container">
                                <Autocomplete
                                    id="modelBox"
                                    options={this.state.savedModels}
                                    onChange={this.changeModel}
                                    value={this.state.modelValue}
                                    getOptionLabel={option => option.Model}
                                    className="model-input-wrapper"
                                    renderInput={params => <TextField {...params}
                                                                      variant="outlined"
                                                                      label="Model"
                                    />}/>
                            </FormControl>}
                        <div className="chose-intro-container">
                            <h3 className="chose-intro-label">Choose Intro Flow</h3>
                            <List
                                component="nav"
                                className="intro-flow-container"
                                id='thing'
                            >
                                {this.renderIntroFlowRow()}
                            </List>
                        </div>
                    </div>

                    <div className="create-chat-container">
                        <div ref={(ref) => {
                            this.divRef = ref;
                        }} className="create-chat-wrapper">
                            {this.createChat()}
                        </div>
                        <div className="query-container">
                            <TextField
                                id="outlined-name"
                                label="Query"
                                className="query-text-field"
                                value={this.state.query}
                                onChange={this.handleQueryChange}
                                margin="normal"
                                variant="outlined"
                                onKeyDown={this.keyPress}
                            />
                            <Button variant="contained" onClick={this.handleSubmit}
                                    color="primary" id="query-btn" type="submit"><SendIcon
                                id="query-btn-icon"/> </Button>
                        </div>
                        {this.state.modelUpdated ?
                            <div id="save-updated-model"><Button
                                variant="contained" color="primary"
                                onClick={this.updateModel}>Save
                                Updated Model</Button>
                                {this.state.loading ?
                                    <div className="circular-progress"><CircularProgress/>
                                    </div> : <div></div>}
                            </div> : <div></div>}
                        <Notifier changeState={this.changeState} open={this.state.notifierOpen}
                                  message="Updated Model Saved Successfully"/>
                        <Notifier changeState={this.changeState} open={this.state.notifierTrainOpen}
                                  message={this.state.trainingComplete + " " + this.state.modelTrained}/>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default TrainingChat;
