import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import MoodIcon from '@material-ui/icons/Mood';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import Modal from '@material-ui/core/Modal';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";

const charLimit = 950;
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        minWidth: "720px"
    };
}

class ArrayMessage extends Component {


    constructor(props) {

        super(props);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.onEmojiClick = this.onEmojiClick.bind(this);
        this.showEmoji = this.showEmoji.bind(this);
        this.renderEntityMenuItems = this.renderEntityMenuItems.bind(this);
        this.handleChangeContext = this.handleChangeContext.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.getContext = this.getContext.bind(this);
        this.handleExpandChange = this.handleExpandChange.bind(this);
        this.handlePredefinedChange = this.handlePredefinedChange.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            textFieldValueContext: "",
            model: "",
            showPicker: false,
            optionAction: false,
            open: false,
            expanded: "panel1",
            entityContext: "",
            contextValues: [],
            predefinedContext: ""


        }
        this.convRef = React.createRef();
    }

    handlePredefinedChange(event){

        this.setState({predefinedContext: event.target.value});
    }

    componentDidMount() {

        this.getSavedModels();
        this.getContext();
        if (this.props.value) {
            //console.log(this.props.value.message.length)
            this.setState({textFieldValue: this.props.value.message, charCounter: this.props.value.message.length});
        }
        if (this.convRef && this.convRef.current) {
            // this.convRef.current.focus();
        }

        if (this.props.optionAction) {

            this.setState({optionAction: this.props.optionAction});
        }


        if (this.props.value) {
            console.log(this.props.value)
            this.setState({textFieldValue: this.props.value.message});


            if (this.props.value.contextType) {
console.log(this.props.value.contextType)
                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.contextArray});
                    this.setState({contextType: "predefinedContext"});
                    this.setState({expanded: 'panel1'})


                } else if (this.props.value.contextType === "entityContext") {
                    this.setState({entityContext: this.props.value.contextArray});
                    this.setState({contextType: "entityContext"});
                    this.setState({expanded: 'panel3'})
                } else {
                    this.setState({textFieldValueContext: this.props.value.contextArray});
                    this.setState({contextType: "manual"});
                    this.setState({expanded: 'panel2'})
                    console.log("HEREE")
                }
            }

        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            //console.log(this.props.value);
            this.setState({textFieldValue: this.props.value.message, charCounter: this.props.value.message.length});
            this.setState({edit: true});



            if (this.props.value.contextType) {

                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.contextArray});
                    this.setState({contextType: "predefinedContext"});
                    this.setState({expanded: 'panel1'})
                } else if (this.props.value.contextType === "entityContext") {
                    this.setState({entityContext: this.props.value.contextArray});
                    this.setState({contextType: "entityContext"});
                    this.setState({expanded: 'panel3'})
                } else {
                    this.setState({textFieldValueContext: this.props.value.contextArray});
                    this.setState({contextType: "manual"});
                    this.setState({expanded: 'panel2'})
                }
            }
        }
    }

    handleExpandChange(panelId) {
        this.setState({expanded: panelId});
    }

    renderContextMenuItems() {
        if (this.state.contextValues) {
            return this.state.contextValues.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }

    async getContext() {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {

                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (this.props.value && this.props.value.key && object === this.props.value.key) {

                    //this.setState({expanded: 'panel1'}, () => {
                        this.setState({predefinedContext: object});
                   // });

                }

            })
        }


    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            });
        }
    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;

        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );

        let data = await response.json();
        let entities;
        if (i === 0) {
            entities = [];
        } else {
            entities = this.state.entities ? this.state.entities : [];
        }


        this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]})
    }

    handleEntityChange(e) {

        this.setState({entityContext: e.target.value});
    }

    handleChangeContext(event) {

        this.setState({textFieldValueContext: event.target.value});

    }

    renderEntityMenuItems() {
        if (this.state.entities) {
            return this.state.entities.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }

    }

    showEmoji(event) {

        if (this.state.showPicker) {

            this.setState({showPicker: false});
        } else {
            this.setState({showPicker: true});
        }

    }

    pass() {
        let contextVariabel;
        let contextType = "manual";

        if (this.state.expanded === "panel1") {
            contextVariabel = this.state.predefinedContext;
            contextType = "predefinedContext";
        } else if (this.state.expanded === "panel2") {
            contextVariabel = this.state.textFieldValueContext;
        } else {
            contextType = "entityContext";
            contextVariabel = this.state.entityContext;
        }
        let workflow = {
            type: "conversation.arrayMessage",
            contextArray: contextVariabel,
            message: this.state.textFieldValue,
            contextType: contextType
        };
        this.props.passData("conversation.arrayMessage", workflow);


    }


    handleChange(e) {
        let stringValue = e.target.value;
        if (stringValue.length > charLimit) {

            stringValue = stringValue.substring(0, charLimit - 1);

        }
        this.setState({textFieldValue: stringValue, charCounter: e.target.value.length});

        if (this.props.option) {

            let val = "";
            if (e.target.value === "") {
                val = "";

            } else {
                val = e.target.value;

            }
            let workflow = {type: "conversation.message", "message": val};
            this.setState({workflow: workflow});
            this.props.onChange(workflow);


        }
    }

    onEmojiClick = (event) => {

        let value = this.state.textFieldValue;

        value = value + event.native;

        this.setState({textFieldValue: value, showPicker: false});

        let workflow = {type: "conversation.message", "message": value};
        this.setState({workflow: workflow});
        if (this.props.onChange) {
            this.props.onChange(workflow);
        }


    };

    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>

                <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                    this.handleExpandChange('panel1')
                }} style={{marginTop: "5px", marginBottom: "0px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Predefined Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                     className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                label="Predefined Context"
                                select
                                value={this.state.predefinedContext}
                                classes={{root: classes.select}}
                                onChange={this.handlePredefinedChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>


                                {this.renderContextMenuItems()}

                            </TextField>
                        </FormControl>

                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={this.state.expanded === 'panel2'} onClick={event => {
                    this.handleExpandChange('panel2')
                }} style={{marginTop: "5px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Manual Input Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <TextField
                            value={this.state.textFieldValueContext}
                            onChange={this.handleChangeContext}
                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            label="Context Variable"
                            style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", width: "100%", marginTop: "-10px"}}
                        />

                    </AccordionDetails>
                </Accordion>


                {this.state.entities &&
                    <Accordion expanded={this.state.expanded === 'panel3'} onClick={event => {
                        this.handleExpandChange('panel3')
                    }} style={{marginTop: "5px"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row"
                            }}>

                                <div style={{marginLeft: "0px", fontWeight: "bold"}}>Entity Context Variable</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                         className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    label="Entity Context Variable"
                                    select
                                    value={this.state.entityContext}
                                    classes={{root: classes.select}}
                                    onChange={this.handleEntityChange}
                                >
                                    {this.renderEntityMenuItems()}

                                </TextField>
                            </FormControl>

                        </AccordionDetails>
                    </Accordion>}

                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop:"10px"}}><HelpIcon onClick={() => {
                    this.setState({open: true})
                }}/></div>
                <TextField
                    value={this.state.textFieldValue}
                    onChange={this.handleChange}
                    margin="normal"
                    multiline={true}
                    minRows={15}
                    ref={this.convRef}
                    variant="outlined"
                    label={this.props.label}
                    style={{whiteSpace: "pre-wrap", wordBreak: "keep-all"}}
                />
                <div style={{display: "flex", flexDirection: "row", width: "100%"}}><MoodIcon onClick={this.showEmoji}/><span
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>{this.state.charCounter} (950)</span></div>


                {this.state.showPicker ? <Picker style={{
                    position: this.state.optionAction === true ? "relative" : "absolute",
                    bottom: this.state.optionAction ? '550px' : '325px',
                    right: this.state.optionAction ? '0px' : '15px',
                    background: "white",
                    zIndex: "5"
                }} set='apple' onSelect={this.onEmojiClick}/> : <div></div>}


                {!this.props.option ?
                    <div style={{marginTop: "30px"}}>
                        {this.props.value && this.props.value.message ?
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Update</Button> :
                            <Button
                                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                variant="contained"
                                onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}


                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={() => {
                        this.setState({open: false})
                    }}
                >
                    <div style={getModalStyle()} className={classes.modal}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row", width: "100%"}}><span style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}><CloseIcon onClick={() => {
                                this.setState({open: false})
                            }}/></span></div>
                            <img style={{imageRendering: "pixelated"}}
                                 src="https://content.psplugin.com/images/bottrainer/formatting.png"/>

                        </div>
                    </div>
                </Modal>
            </div>


        )
    }

}

export default withStyles(styles)(ArrayMessage);
