import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import IntentList from "./IntentList";
import Notifier from "./Notifier";
import Button from "@material-ui/core/Button/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FileReader from "./FileRead";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import validateTrainingData from "./validation/trainingDataValidator";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ChatGpt from "./ChatGpt";
import TestIntents from "./TestIntents";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Fab from "@material-ui/core/Fab";
import { getDomains } from "./reused/reusedfunctions.js";

function getModalStyle() {
  const top = 50;
  const left = 50;
  //Comment
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  NameCell: {
    fontWeight: 900,
    textDecoration: "underline",
    cursor: "pointer",
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  modalResult: {
    position: "absolute",
    width: theme.spacing(150),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
});

const dataSelect = [{ pipline: "se" }, { pipline: "en" }, { pipline: "da" }];
let interval,
  refreshInterval = null,
  r4Interval;

class Trainingdata extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Intent hits",
      updateModels: false,
      keyValue: [],
      updatedFromtestIntents: false,
      allTrainingData: [],
      openValidator: false,
      clearSearch: false,
      firstQuery: [],
      modelTrained: "",
      dataDeleted: [],
      newModel: "",
      modelValue: { Model: "" },
      notifierTrainOpen: false,
      open: false,
      openProcessFile: false,
      gridValue: 3,
      pipeline: "se",
      loading: false,
      saveAsName: "",
      showField: false,
      showSave: false,
      oldModelName: "",
      showImport: false,
      voicebot: false,
      synonyms: [],
      confirmDeleteOpen: false,
      confirmDeleteOpenIntent: false,
      confirmStartTraining: false,
      confirmStartTrainingNew: false,
      trainingR4Complete: false,
      editSentence: "",
    };
    this.updateTestSentences = this.updateTestSentences.bind(this);
    this.queryIntentResponse = this.queryIntentResponse.bind(this);
    this.convertData2 = this.convertData2.bind(this);
    this.convertToRasaModel = this.convertToRasaModel.bind(this);
    this.convertToRasaV3Model = this.convertToRasaV3Model.bind(this);
    this.deleteIntent = this.deleteIntent.bind(this);
    this.resetNewTrainingData = this.resetNewTrainingData.bind(this);
    this.addToIntentDataModel = this.addToIntentDataModel.bind(this);
    this.deleteSynonym = this.deleteSynonym.bind(this);
    this.saveDataModel = this.saveDataModel.bind(this);
    this.updateResponseToDataModel = this.updateResponseToDataModel.bind(this);
    this.updateDataModelInDb = this.updateDataModelInDb.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleConfirmStartTrainingClose =
      this.handleConfirmStartTrainingClose.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.saveModel = this.saveModel.bind(this);
    this.updateDataModel = this.updateDataModel.bind(this);
    this.convertAllToRasa = this.convertAllToRasa.bind(this);
    this.listRow = this.listRow.bind(this);
    this.handlePipeChange = this.handlePipeChange.bind(this);
    this.saveModelAs = this.saveModelAs.bind(this);
    this.handleSaveAsChange = this.handleSaveAsChange.bind(this);
    this.saveModelWithNewName = this.saveModelWithNewName.bind(this);
    this.queryResponses = this.queryResponses.bind(this);
    this.insertResponses = this.insertResponses.bind(this);
    this.processFile = this.processFile.bind(this);
    this.convertImported = this.convertImported.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.handleShowImport = this.handleShowImport.bind(this);
    this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this);
    this.deleteModelFromDb = this.deleteModelFromDb.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.postTrainingDataToKv = this.postTrainingDataToKv.bind(this);
    this.trainR3Model = this.trainR3Model.bind(this);
    this.isModelReady = this.isModelReady.bind(this);
    this.checkIfModelIsReady = this.checkIfModelIsReady.bind(this);
    this.openWorkflow = this.openWorkflow.bind(this);
    this.updateKeyValue = this.updateKeyValue.bind(this);
    this.deleteIntentByName = this.deleteIntentByName.bind(this);
    this.handleConfirmDeleteCloseIntent =
      this.handleConfirmDeleteCloseIntent.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.convertToRasaV33Model = this.convertToRasaV33Model.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.loadTrainingData = this.loadTrainingData.bind(this);
    this.loadAllTrainingData = this.loadAllTrainingData.bind(this);
    this.writeTrainingInfoToDb = this.writeTrainingInfoToDb.bind(this);
    this.getTrainingInfoToDb = this.getTrainingInfoToDb.bind(this);
    this.handleConfirmStartTrainingCloseNew =
      this.handleConfirmStartTrainingCloseNew.bind(this);
    this.getR4Token = this.getR4Token.bind(this);
    this.postToKv = this.postToKv.bind(this);
    this.getFromKv = this.getFromKv.bind(this);
    this.startR4Training = this.startR4Training.bind(this);
    this.postToKvResponse = this.postToKvResponse.bind(this);
    this.validateTraining = this.validateTraining.bind(this);
    this.renderValidation = this.renderValidation.bind(this);
    this.openOverview = this.openOverview.bind(this);
    this.editSentence = this.editSentence.bind(this);
    this.handleEditSentence = this.handleEditSentence.bind(this);
    this.saveEditedSentence = this.saveEditedSentence.bind(this);
    this.chatGpt = this.chatGpt.bind(this);
    this.updateSentences = this.updateSentences.bind(this);
    this.addTrainingSentence = this.addTrainingSentence.bind(this);
    this.getConf = this.getConf.bind(this);
    this.ensureTrainingModel = this.ensureTrainingModel.bind(this);
    this.showTrainModel = this.showTrainModel.bind(this);
  }

  showTrainModel = () => {
    this.setState({ showTrainModel: true });
  };
  ensureTrainingModel = async (model) => {
    const token = await this.getR4Token();
    
    setTimeout(() => {
      this.props.ensureModel(model, this.state.token);
    }, 2000);
  };

  async getConf() {
    const domains = await getDomains();

    this.setState({ domains: domains ? domains : null });
  }
  addTrainingSentence = (intent, sentence) => {
    let editied = false;
    let keyValue = this.state.keyValue;
    keyValue.map((inte) => {
      if (inte.name === intent) {
        inte.trainingData.push({ text: sentence });
        editied = true;
      }
    });

    if (editied) {
      this.setState(
        { updatedFromtestIntents: true, keyValue: keyValue },
        () => {
          this.updateDataModelInDb();
        }
      );
    }
  };
  updateTestSentences(sentences) {
    this.setState({
      testSentences: sentences,
      openModal: false,
      chatGpt: false,
    });
  }
  updateSentences(sentences) {
    this.setState({ sentences: sentences, openModal: false, chatGpt: false });
  }
  chatGpt() {
    const chatgpt = this.state.chatGpt ? false : true;

    this.setState({ chatGpt: chatgpt });
  }
  async saveEditedSentence(event) {
    let edited = false;
    let keyValue = this.state.keyValue;
    keyValue.map((intent) => {
      if (intent.name === this.state.editObject.intent) {
        intent.trainingData.map((o, i) => {
          if (o.text === this.state.editObject.sentence) {
            edited = true;
            o.text = this.state.editSentence;
          }
        });
      }
    });

    if (edited) {
      this.setState(
        { edit: false, keyValue: keyValue, updateValidation: true },
        () => {
          this.updateDataModelInDb();
        }
      );
    }
  }
  handleEditSentence(event) {
    this.setState({ editSentence: event.target.value });
  }
  componentDidMount() {
    this.getConf();
    this.getSettings();

    if (this.props.menuOpen) {
      this.setState({ gridValue: 3 });
    } else {
      this.setState({ gridValue: 3 });
    }

    if (localStorage.getItem("model")) {
      this.setState({ model: localStorage.getItem("model") });
      this.queryIntentResponse(localStorage.getItem("model"));
    }

    if (this.props.dataToUse) {
      this.setState({ dataToUse: this.props.dataToUse });
      this.setState({ model: this.props.dataToUse.model });
      this.queryIntentResponse(this.props.dataToUse.model);
    }

    if (this.props.pipeline) {
      this.setState({ pipeline: this.props.pipeline });
    }

    this.getR4Token();
  }
  componentDidUpdate(prevProps, prevState, snapShot) {
    //State that keeps track of tabs visibility
    if (prevState.visible !== this.state.visible && this.state.visible) {
      if (this.state.visible === "visible") {
        this.queryIntentResponse(this.props.model);
      }
    }

    if (prevProps.model !== this.props.model) {
      this.setState({ model: this.props.model }, () => {
        this.getTrainingInfoToDb();
      });
      // this.queryIntentResponse(this.props.model);
    }

    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({ pipeline: this.props.pipeline });
    }
  }
  editSentence = (event, index) => {
    let sentence = "";
    if (
      this.state.validationResult &&
      this.state.validationResult.invalidStrings &&
      this.state.validationResult.invalidStrings.length > 0 &&
      this.state.validationResult.invalidStrings[index].sentence
    ) {
      sentence = this.state.validationResult.invalidStrings[index].sentence;
    }

    this.setState({
      editSentence: sentence,
      edit: true,
      editObject: this.state.validationResult.invalidStrings[index],
    });
  };
  openOverview = (intentName, model) => {
    this.props.openOverview(intentName, model);

    //Fix to open files from intentlist and not break reordering
    setTimeout(()=>{this.props.history.push("/responseworkflow")}, 500);
  };
  validateTraining() {
    if (this.state.firstQuery) {
      this.setState(
        { validationResult: validateTrainingData(this.state.firstQuery) },
        () => {
          this.setState({ openValidator: true });
        }
      );
    }
  }
  renderValidation() {
    if (
      this.state.validationResult &&
      this.state.validationResult.invalidStrings
    ) {
      return this.state.validationResult.invalidStrings.map((result, i) => {
        return (
          <div
            key={Math.random()}
            style={{
              display: "flex",
              flexDirection: "column",
              borderTop: i === 0 ? "1px solid #ccc" : "none",
              borderBottom: "1px solid #ccc",
              borderRight: "1px solid #ccc",
              borderLeft: "1px solid #ccc",
              backgroundColor: i % 2 === 0 ? "#E8E8E8" : "#FFFFFF",
              padding: "10px",
              marginTop: "0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {i + 1 + ". "}
              <div
                style={{
                  width: "150px",
                  marginLeft: "20px",
                  color:
                    result.type === "Error"
                      ? "red"
                      : result.type === "Warning"
                      ? "orange"
                      : "green",
                }}
              >
                {result.type}
              </div>
              <div
                style={{ width: "150px", overflow: "auto", marginLeft: "5px" }}
              >
                {result.intent}
              </div>
              <div style={{ width: "100%", marginLeft: "20px" }}>
                {result.sentence}
              </div>
              {result.sentence && (
                <Tooltip title="Edit">
                  <IconButton
                    style={{ color: "#3E51B5", marginTop: "15px" }}
                    onClick={(event) => this.editSentence(event, i)}
                    aria-label="Save"
                    component="span"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!result.sentence && <div style={{ height: "63px" }}>&nbsp;</div>}
            </div>
            <div style={{ marginLeft: "20px", fontStyle: "italic" }}>
              {result.message}
            </div>
          </div>
        );
      });
    }
  }
  async startR4Training(modelname) {
    let url =
      "https://" +
      this.state.domains.restDomain.default +
      "/Train/" +
      modelname;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(url, requestOptions);

    const data = await response.text();

    this.props.checkIfR4ModelIsReady(modelname, this.state.token);
  }
  async postToKvResponse(name, resp) {
    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + name;

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: resp,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    const data = await response.text();
  }
  async postToKv(modelname) {
    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + modelname;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify(this.convertToRasaV33Model());
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    const data = await response.text();
    this.writeTrainingInfoToDb();
    this.startR4Training(modelname);
  }
  async getFromKv(modelname) {
    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + modelname;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(url, requestOptions);

    const data = await response.json();
  }
  async getTrainingInfoToDb(model) {
    if (this.state.model) {
      model = this.state.model;
    }
    let url = "/api/getTrainingInfo?model=" + encodeURI(model);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (data.length > 0) {
      if (data[0].Traininginfo) {
        let trainingInfo;
        try {
          trainingInfo = JSON.parse(data[0].Traininginfo);

          this.setState({ trainingInfoName: trainingInfo.username });
          this.setState({ trainingInfoEmail: trainingInfo.email });
          this.setState({ trainingInfoTimestamp: trainingInfo.trainingDate });
          if (trainingInfo.ensureRasaDate) {
            if (trainingInfo.ensureRasaDate !== "NA") {
              let d1 = new Date(trainingInfo.ensureRasaDate);
              let d2 = new Date(trainingInfo.trainingDate);

              let minutes = moment
                .duration(moment(d1).diff(moment(d2)))
                .asMinutes();

              if (d2 > d1) {
                this.props.ensureModel(model, this.state.token);
              }
            } else {
              const tok = await this.getR4Token();
              this.props.ensureModel(model, this.state.token);
            }
            this.setState({ ensureRasaDate: trainingInfo.ensureRasaDate });
          }
        } catch (e) {
          let trainingArray = data[0].Traininginfo.split("_");
          this.setState({ trainingInfoName: trainingArray[0] });
          this.setState({ trainingInfoEmail: trainingArray[1] });
          this.setState({ trainingInfoTimestamp: trainingArray[2] });
        }
      }
    }
  }
  async writeTrainingInfoToDb() {
    let date = moment(new Date()).format("YYYY-MM-DD HH:mm");
    let trainingInfo = encodeURI(
      this.props.loggedInUserName +
        "_" +
        this.props.loggedInUserEmail +
        "_" +
        date
    );
    
    let url = "/api/writeTrainingInfo?model=" + this.state.model;
    
    let infoObj;

    infoObj = {
      username: this.props.loggedInUserName,
      email: this.props.loggedInUserEmail,
      trainingDate: date,
      ensureRasaDate: "NA",
    };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(infoObj),
      headers: { "Content-Type": "application/json" },
    });

    const data = await response.json();
  }
  async loadAllTrainingData(modelArr) {
    this.setState({ allTrainingData: [] });
    let modelArray;
    if (modelArr) {
      modelArray = modelArr;
    } else {
      modelArray = this.state.allModels;
    }

    modelArray.map((m) => {
      this.loadTrainingData(m.Model, false);
    });
  }
  changeModel(value) {
    this.setState({ model: value.Model });
    this.setState({ pipeline: value.Pipeline });

    localStorage.setItem("model", value);
    this.queryIntentResponse(value.Model);
  }
  handleExport(event) {
    let csvObject = "\uFEFF";
    this.state.keyValue.map((object) => {
      object.trainingData.map((sentence) => {
        csvObject += object.name + ";" + sentence.text + "\n";
      });
    });

    let filename = this.state.model + ".csv";
    var FileSaver = require("file-saver");
    var blob = new Blob([csvObject], { type: "text/plain;charset=utf-8" });

    FileSaver.saveAs(blob, filename);
  }
  openWorkflow = (value) => {
    this.props.openWorkflow(value);
    setTimeout(()=>{this.props.history.push("/responseworkflow")}, 500);
    
  };
  async getResponse(intent) {
    let url =
      "/api/response?intent=" +
      intent +
      "&model=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      intent;

    const response = await fetch(url);

    const data = await response.json();

    if (data.length === 0) {
      this.updateResponseToDataModel(intent, "empty");
    }
  }
  resetNewTrainingData() {
    this.setState({ dataToUse: null });
  }
  async saveModel() {
    this.props.cancelAddFromQuery();
    const saveData = await this.updateDataModelInDb();
  }
  handleValidatorClose = () => {
    this.setState({ openValidator: false, edit: false });
  };
  handleProcessFileClose = () => {
    this.setState({ openProcessFile: false });
  };
  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            height: "65px",
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });
  async loadTrainingData(model, keyValue) {
    let url = "/api/trainingDataJson?model=" + model;

    let response = await fetch(url, {
      method: "get",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).catch((error) => window.location.reload());

    if (response.status === 302) {
    }
    let data = await response.json();

    if (data) {
      let arrayToSave = data.rasa_nlu_data.common_examples;
      arrayToSave.map((arr) => {
        arr.model = model;
      });

      this.setState({
        allTrainingData: [...this.state.allTrainingData, ...arrayToSave],
      });
    }
  }
  async queryIntentResponse(model) {
    if (!model || model === "no convert") {
      model = this.state.model;
    } else {
      this.setState({ model: model });
    }

    let url = "/api/trainingDataJson?model=" + model;

    let response = await fetch(url, {
      method: "get",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).catch((error) => window.location.reload());

    if (response.status === 302) {
    }
    let data = await response.json();

    this.setState({ firstQuery: data }, () => {
      if (this.state.updateValidation) {
        this.setState({ updateValidation: false });
        this.validateTraining();
      }
    });

    if (model !== "no convert") {
      this.convertData2();
    }
  }
  async saveDataModel() {
    let model = this.state.model;

    if (this.state.model && this.state.model.Model) {
      model = this.state.model.Model;
    }

    let url =
      "/api/saveModel?model=" +
      model +
      "&pipeline=" +
      this.state.pipeline +
      "&project=" +
      this.props.account;
    let data = null;
    let response = null;

    response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.convertAllToRasa()),
    }).catch((error) => console.log(error));

    if (response.status === 500 || response.status === 400) {
      this.updateDataModelInDb();
      return;
    }

    data = await response.json();

    this.props.newModelSaved(this.state.saveAsName);

    if (data && data.length === 0) {
      this.setState({ notifierOpen: true });
      //this.props.resetSaveModel("new");

      return new Promise((resolve) => {
        resolve("resolved");
      });
    }

    if (this.state.saveAsName !== "") {
    }
  }
  changeState() {
    this.setState({ notifierOpen: false });
    this.setState({ notifierCreateOpen: false });
    this.setState({ notifierTrainOpen: false });
  }
  async updateDataModelInDb() {
    let model = this.state.model;
    if (this.state.model && this.state.model.Model) {
      model = this.state.model.Model;
    }

    let url = "/api/updateModel?id=" + model + "&account=" + this.props.account;

    const response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.convertAllToRasa()),
    });

    const data = await response.json();

    if (data.length === 0) {
      this.setState({ notifierOpen: true });
      //Removed to load all training data all the time maybe need to load all trainingdata on change of model
      //this.loadAllTrainingData();

      // Load the current model again.
      this.queryIntentResponse(this.state.model);
    }
    if (data && data.name && data.name.indexOf("error") > -1) {
    }
  }
  async convertImported(importedData) {
    let imported = importedData.split("\n");

    let kv = this.state.keyValue;
    imported.map((row) => {
      let rowSplit = row.split(";");
      let text = rowSplit[1].trim();
      let textMatch = null; // text.match(/(.*)\?/);

      if (textMatch && textMatch.length > 1) {
        text = textMatch[1].charAt(0).toLowerCase() + textMatch[1].slice(1);
      } else {
        text = text[0].charAt(0).toLowerCase() + text.slice(1);
      }

      if (rowSplit.length === 2) {
        let found = false;

        kv.map((o) => {
          if (o.name === rowSplit[0]) {
            found = true;

            o.trainingData.push({ text: text });
          }
        });

        if (!found) {
          let trainingData = [];

          trainingData.push({ text: text });

          kv.push({ name: rowSplit[0].trim(), trainingData: trainingData });
        }
      }
    });

    kv.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    this.setState({ keyValue: kv });

    this.saveDataModel();
    this.setState({ openProcessFile: false, importedFile: null });
  }
  async convertData2() {
    const data = [];
    let synonyms,
      lookups = [];
    if (!this.state.firstQuery) {
      console.log("failed to init");
    } else {
      if (
        this.state.firstQuery &&
        this.state.firstQuery.rasa_nlu_data &&
        this.state.firstQuery.rasa_nlu_data.common_examples
      ) {
        this.state.firstQuery.rasa_nlu_data.common_examples.map((object) => {
          let found = 0;

          for (let i = 0; i < data.length; i++) {
            if (object.intent === data[i].name) {
              if (object.entities && object.entities.length > 0) {
                data.push({
                  name: object.intent,
                  trainingData: [
                    { text: object.text, entities: object.entities },
                  ],
                  status: object.status ? object.status : "active",
                });
              } else {
                //Removed empty check on creating data
                // if (object.text !== "##empty##") {
                data[i].trainingData.push({ text: object.text });
                //}
              }

              found = 1;
              break;
            }
          }

          if (found === 0) {
            if (object.entities) {
              data.push({
                name: object.intent,
                trainingData: [
                  { text: object.text, entities: object.entities },
                ],
                status: object.status ? object.status : "active",
              });
            } else {
              let dataObj = {
                name: object.intent,
                trainingData: [],
                status: object.status ? object.status : "active",
              };
              //Removed delete of empty rows
              //if (object.text !== "##empty##") {
              dataObj.trainingData.push({ text: object.text });
              //}

              data.push(dataObj);
            }
          }
        });
      }

      if (
        this.state.firstQuery &&
        this.state.firstQuery.rasa_nlu_data &&
        this.state.firstQuery.rasa_nlu_data.entity_object
      ) {
        synonyms = this.state.firstQuery.rasa_nlu_data.entity_object;
        this.setState({ entitySynonyms: synonyms });
      }

      if (
        this.state.firstQuery &&
        this.state.firstQuery.rasa_nlu_data &&
        this.state.firstQuery.rasa_nlu_data.lookup_object
      ) {
        lookups = this.state.firstQuery.rasa_nlu_data.lookup_object;
        this.setState({ entityLookups: lookups });
      }
      //this.setState({synonyms: this.state.firstQuery.rasa_nlu_data.entity_synonyms});

      //Sort the array in alphabetical order
      data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      this.setState({
        keyValue: data,
        firstIntent: data && data.length > 0 ? data[0].name : null,
      });

      // this.checkThatAllIntentsHaveResponses(data);
      this.setState({ dataToUse: this.props.dataToUse });

      this.setState({ openIntentResponse: this.props.openIntentResponse });

      
    }
  }
  updateDataModel = async (selectedIntent, trainingData, status) => {
    if (trainingData.length > 1) {
      for (let i = 0; i < trainingData.length; i++) {
        if (trainingData[i].text === "##empty##") {
          trainingData.splice(i, 1);
          break;
        }
      }
    }
    if (trainingData.length === 0) {
      trainingData.push({ text: "##empty##" });
    }

    //const latest = await this.getLatestModel();

    this.state.keyValue.map((object) => {
      if (object.name === selectedIntent) {
        if (status) {
          object.status = status;
        } else {
          object.trainingData = trainingData;
        }
      }
    });

    this.saveModel();
  };

  updateResponseToDataModel(selectedIntent, response) {
    let flow = [{ type: "conversation.message", message: selectedIntent }];
    let responseObj = {
      intent: selectedIntent,
      metaData: { workflow: JSON.stringify(flow) },
    };
    if (response && response.intent) {
      let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");
      //response.metaData.workflow = JSON.stringify(response.metaData.workflow);
      response.metaData.created = createdTimeStamp;
      response.metaData.createdBy = this.props.loggedInUserEmail;
      responseObj = response;
    }

    //this.getLatestModel()
    let responseName =
      this.props.account + "_" + this.state.model + "_" + selectedIntent;
    this.postToKvResponse(responseName, JSON.stringify(responseObj));
    var url =
      "/api/saveResponse?id=" +
      this.props.account +
      "_" +
      this.state.model +
      "_" +
      selectedIntent;
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(responseObj),
    }).then((response) => {
      response.json().then((data) => {
        //load the new response
      });
    });
  }
  addToIntentDataModel = (value) => {
    let newIntent = { name: value, trainingData: [], status: "active" };

    newIntent.trainingData.push({ text: "##empty##" });

    let keyValue = this.state.keyValue;
    keyValue.push(newIntent);
    keyValue.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    keyValue.map((object, i) => {
      if (object.name === value) {
        this.setState({ selected: i });
      }
    });

    this.setState({ keyValue: keyValue });
  };
  convertAllToRasa() {
    let convertedModel = {
      rasa_nlu_data: {
        common_examples: [],
        entity_synonyms: [],
        entity_values: [],
        entity_object: {},
        lookup_object: {},
      },
    };

    let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");

    convertedModel.rasa_nlu_data.lastModifiedBy = this.props.loggedInUserEmail;
    convertedModel.rasa_nlu_data.lastModifiedIntent =
      this.state.lastModifiedIntent;
    convertedModel.rasa_nlu_data.lastModified = createdTimeStamp;
    convertedModel.rasa_nlu_data.common_examples = this.convertToRasaModel();

    const entities = new Set();
    const entitiyValue = new Set();

    this.state.keyValue.map((object) => {
      object.trainingData.map((object) => {
        //object.text = object.text.trim();
        let arr = Array.from(
          object.text.matchAll(/\(([^\)[]*)\)/g),
          (x) => x[1]
        );
        let arr2 = Array.from(
          object.text.matchAll(/\(([^\)[]*)\)\{/g),
          (x) => x[1]
        );
        let arr3 = Array.from(
          object.text.matchAll(/\[([^\)[]*)\]/g),
          (x) => x[1]
        );

        if (arr.length > 0 && arr2.length === 0 && !entities.has(arr[0])) {
          entities.add(arr[0]);
        }

        if (arr3.length > 0 && arr2.length === 0) {
          entitiyValue.add(arr3[0]);
        }
      });
    });

    if (
      this.state.firstQuery &&
      this.state.firstQuery.rasa_nlu_data &&
      this.state.firstQuery.rasa_nlu_data.entity_object
    ) {
      convertedModel.rasa_nlu_data.entity_object =
        this.state.firstQuery.rasa_nlu_data.entity_object;
    }

    if (
      this.state.firstQuery &&
      this.state.firstQuery.rasa_nlu_data &&
      this.state.firstQuery.rasa_nlu_data.lookup_object
    ) {
      convertedModel.rasa_nlu_data.lookup_object =
        this.state.firstQuery.rasa_nlu_data.lookup_object;
    }

    convertedModel.rasa_nlu_data.entity_synonyms = [...entities];

    if (entitiyValue.size > 0) {
      convertedModel.rasa_nlu_data.entity_values = [...entitiyValue];
    }

    return convertedModel;
  }
  updateKeyValue = (newIntentName, oldIntentName) => {
    let kv = this.state.keyValue;

    kv.map((object) => {
      if (object.name === oldIntentName) {
        object.name = newIntentName;
      }
    });

    this.setState({ keyValue: kv }, this.updateDataModelInDb);
  };
  convertToRasaModel() {
    //Recreate the object on each conversion
    let newObject = [];

    this.state.keyValue.map((object) => {
      for (var i = 0; i < object.trainingData.length; i++) {
        let obj = {
          intent: object.name,
          text: object.trainingData[i].text,
          status: object.status ? object.status : "active",
        };

        if (object.trainingData[i].entities) {
          obj.entities = object.trainingData[i].entities;
        }
        newObject.push(obj);
      }
    });

    return newObject;
  }
  convertToRasaV33Model() {
    //Recreate the object on each conversion
    let newObject = [];
    let yamlString = 'version: "2.0"\n' + "\n" + "nlu:\n";
    let domainString =
      "session_config:\n  session_expiration_time: 60\n  carry_over_slots_to_new_session: true";
    //let configString = "# Configuration for Rasa NLU.\n# https://rasa.com/docs/rasa/nlu/components/\nlanguage: "+this.state.pipeline+"\n\npipeline:\n  - name: WhitespaceTokenizer\n  intent_tokenization_flag: True  \n      intent_split_symbol: \"-\"\n - name: RegexFeaturizer\n  - name: LexicalSyntacticFeaturizer\n  - name: CountVectorsFeaturizer\n  - name: CountVectorsFeaturizer\n    analyzer: \"char_wb\"\n    min_ngram: 1\n    max_ngram: 4\n  - name: DIETClassifier\n    epochs: 100\n  - name: EntitySynonymMapper\n  - name: ResponseSelector\n    epochs: 1\n\n# Configuration for Rasa Core.\n# https://rasa.com/docs/rasa/core/policies/\npolicies:\n- name: KerasPolicy\n- name: MappingPolicy";

    let configString =
      "# Configuration for Rasa NLU.\n# https://rasa.com/docs/rasa/nlu/components/\nlanguage: " +
      this.state.pipeline +
      "\n\npipeline:\n" +
      "- name: WhitespaceTokenizer\n" +
      "  intent_tokenization_flag: True\n" +
      '  intent_split_symbol: "+"\n' +
      "- name: RegexFeaturizer\n" +
      "- name: LexicalSyntacticFeaturizer\n" +
      "- name: RegexEntityExtractor\n" +
      "  case_sensitive: True\n" +
      "  use_lookup_tables: True\n" +
      "  use_regexes: True\n" +
      "- name: CountVectorsFeaturizer\n" +
      '  analyzer: "char_wb"\n' +
      "  min_ngram: 1\n" +
      "  max_ngram: 4\n" +
      "- name: DIETClassifier\n" +
      "  epochs: " +
      this.state.epochs +
      "\n" +
      "  constrain_similarities: true\n" +
      "- name: EntitySynonymMapper\n" +
      "- name: ResponseSelector\n" +
      "  epochs: 1";

    if (
      !this.state.entityLookups ||
      JSON.stringify(this.state.entityLookups) === "{}"
    ) {
      configString =
        "# Configuration for Rasa NLU.\n# https://rasa.com/docs/rasa/nlu/components/\nlanguage: " +
        this.state.pipeline +
        "\n\npipeline:\n" +
        "- name: WhitespaceTokenizer\n" +
        "  intent_tokenization_flag: True\n" +
        '  intent_split_symbol: "+"\n' +
        "- name: RegexFeaturizer\n" +
        "  case_sensitive: True\n" +
        "- name: LexicalSyntacticFeaturizer\n" +
        "- name: CountVectorsFeaturizer\n" +
        '  analyzer: "char_wb"\n' +
        "  min_ngram: 1\n" +
        "  max_ngram: 4\n" +
        "- name: DIETClassifier\n" +
        "  epochs: " +
        this.state.epochs +
        "\n" +
        "  constrain_similarities: true\n" +
        "- name: EntitySynonymMapper\n" +
        "- name: ResponseSelector\n" +
        "  epochs: 1";
    }
    
    if(this.state.settings && this.state.settings.CustomRasaPipeline){

      const customPipelines = this.state.settings.CustomRasaPipeline;

      const confString = customPipelines[this.state.model];
      if(confString && confString !== ""){

        configString = confString;
      }
      

    }



    this.state.keyValue.map((object, i) => {
      if (!object.status || object.status !== "inactive") {
        if (i === 0) {
          yamlString += "- intent: " + object.name + "\n" + "  examples: |\n";
        } else {
          yamlString += "\n- intent: " + object.name + "\n" + "  examples: |\n";
        }

        //domainString += "\n- "+object.name+""
        for (var i = 0; i < object.trainingData.length; i++) {
          let obj = { intent: object.name, text: object.trainingData[i].text };

          yamlString += "    - " + object.trainingData[i].text + "\n";

          if (object.trainingData[i].entities) {
            obj.entities = object.trainingData[i].entities;
          }

          newObject.push(obj);
        }
      }
    });

    let synString = "";
    if (this.state.entitySynonyms) {
      let keys = Object.keys(this.state.entitySynonyms);
      keys.map((key, i) => {
        this.state.entitySynonyms[key].map((syn, k) => {
          if (k === 0) {
            synString += "\n- synonym: " + key + "\n" + "  examples: |\n";
          }
          synString += "    - " + syn + "\n";
        });
      });
      yamlString += "\n" + synString;
    }

    let lookupString = "";
    if (this.state.entityLookups) {
      let keys = Object.keys(this.state.entityLookups);

      keys.map((key, i) => {
        this.state.entityLookups[key].map((syn, j) => {
          if (j === 0) {
            lookupString += "\n- lookup: " + key + "\n" + "  examples: |\n";
          }
          lookupString += "    - " + syn + "\n";
        });
      });

      yamlString += "\n" + lookupString;
    }

    let trainingObject = {
      domain: domainString,
      config: configString,
      nlu_yml: yamlString,
      stories: "",
      endpoint: "",
      credentials: "",
      initpy: "",
      actionspy: "",
    };

    return trainingObject;
  }
  async getSettings() {
    let url = "/api/getSettings";

    const response = await fetch(url);
    const data = await response.json();
    if (data.length > 0) {
      const sett = data[0].Value;

      const settings = JSON.parse(sett);
      this.setState({settings: settings});
      if (settings.voicebot) {
        this.setState({ voicebot: true });
      }

      if (settings.TrainingEpochs) {
        this.setState({ epochs: settings.TrainingEpochs });
      }
    }
  }
  convertToRasaV3Model() {
    let epochs = 300;

    if (this.state.epochs) {
      epochs = this.state.epochs;
    }
    //Recreate the object on each conversion
    let newObject = [];
    let yamlString = "";
    let domainString =
      "session_config:\n  session_expiration_time: 60\n  carry_over_slots_to_new_session: true";
    let configString =
      "# Configuration for Rasa NLU.\n# https://rasa.com/docs/rasa/nlu/components/\nlanguage: " +
      this.state.pipeline +
      '\n\npipeline:\n  - name: WhitespaceTokenizer\n  - name: RegexFeaturizer\n  - name: LexicalSyntacticFeaturizer\n  - name: CountVectorsFeaturizer\n  - name: CountVectorsFeaturizer\n    analyzer: "char_wb"\n    min_ngram: 1\n    max_ngram: 4\n  - name: DIETClassifier\n    epochs: ' +
      epochs +
      "\n  - name: EntitySynonymMapper\n  - name: ResponseSelector\n    epochs: 1\n\n# Configuration for Rasa Core.\n# https://rasa.com/docs/rasa/core/policies/\npolicies:\n- name: KerasPolicy\n- name: MappingPolicy";

    this.state.keyValue.map((object, i) => {
      let status = "active";
      if (object.status) {
        status = object.status;
      }

      if (object.trainingData.length > 0 && status === "active") {
        if (i === 0) {
          yamlString += "## intent:" + object.name + "\n";
        } else {
          yamlString += "\n## intent:" + object.name + "\n";
        }

        //domainString += "\n- "+object.name+""
        for (var i = 0; i < object.trainingData.length; i++) {
          if (object.trainingData[i].text !== "##empty##") {
            let obj = {
              intent: object.name,
              text: object.trainingData[i].text,
            };

            yamlString += "- " + object.trainingData[i].text + "\n";

            if (object.trainingData[i].entities) {
              obj.entities = object.trainingData[i].entities;
            }

            newObject.push(obj);
          }
        }
      }
    });

    this.state.synonyms.map((syn) => {
      if (syn.value) {
        yamlString += "\n## synonym:" + syn.value + "\n";

        let synonyms = syn.synonyms;
        synonyms.map((value) => {
          yamlString += "- " + value + "\n";
        });
      }
    });

    let trainingObject = {
      domain: domainString,
      config: configString,
      nlu: yamlString,
      stories: "",
      endpoint: "",
      credentials: "",
      initpy: "",
      actionspy: "",
    };

    return trainingObject;

    //return newObject;
  }
  async trainR3Model() {
    let url =
      "https://" +
      this.state.domains.restDomain.default +
      "/EnsureRasaServer/" +
      this.state.fixedModelName;

    const response = await fetch(url);

    const data = await response;

    this.props.trainModel(this.state.fixedModelName);
  }
  checkIfModelIsReady() {
    interval = setInterval(() => {
      this.isModelReady();
    }, 10000);
  }
  async isModelReady() {
    let url =
      "https://" +
      this.state.domains.restDomain.default +
      "/IsModelReady/" +
      this.state.fixedModelName;

    const response = await fetch(url);

    const data = await response.json();

    if (data.ready) {
      clearInterval(interval);
      this.setState({ loading: false });
      this.setState({ modelTrained: this.state.fixedModelName });
      this.setState({
        trainingComplete: this.state.fixedModelName + " trained successfully",
      });
      this.setState({ notifierTrainOpen: true });
    }

    return data.ready;
  }
  async postTrainingDataToKv() {
    let modelName = this.state.model.toLowerCase(); //.replaceAll("_", "");
    const sett = await this.getSettings();
    this.setState({ fixedModelName: modelName });
    

    let url =
      "https://" + this.state.domains.restDomain.default + "/kv/" + modelName;
    

    const response2 = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.convertToRasaV3Model()),
    });
    const d = await response2;

    if (d.status === 200) {
      this.trainR3Model();
    }
  }

  deleteIntentByName = (intentName) => {
    this.setState({
      intentNameToDelete: intentName,
      confirmDeleteOpenIntent: true,
    });
  };
  deleteIntent = (dataToDelete) => {
    let intentArray = this.state.keyValue;

    for (var i = dataToDelete.length; i >= 0; i--) {
      if (dataToDelete[i]) {
        intentArray.splice(i, 1);
      }
    }

    this.setState({ keyValue: intentArray });
    this.saveDataModel();
  };
  deleteSynonym = (dataToDelete) => {
    for (var i = 0; i < dataToDelete.length; i++) {
      this.setState({
        dataDeleted: this.state.synonyms.splice(dataToDelete[i], 1),
      });
    }
  };
  resetNewTrainingData() {
    this.props.resetNewTrainingData();
  }
  saveUpdatedSynonyms = (selectedSynonym, synonyms) => {
    this.state.synonyms.map((object) => {
      if (object.value.trim() === selectedSynonym.trim()) {
        let synonymArray = synonyms.split(",");

        object.synonyms = synonymArray;
      }
    });
  };
  handleCreate() {
    //ADD new model to state
    let emptyModel = {
      rasa_nlu_data: { common_examples: [], entity_synonyms: [] },
    };
    this.setState({ firstQuery: emptyModel });

    // Open Create Modal
    this.setState({ open: true });
  }
  handleDelete() {
    this.setState({ confirmDeleteOpen: true });
  }
  listRow() {
    if (dataSelect) {
      return dataSelect.map((object, i) => {
        return (
          <MenuItem key={i} value={object.pipline}>
            {object.pipline}
          </MenuItem>
        );
      });
    }
  }
  handlePipeChange(e) {
    this.setState({ pipeline: e.target.value });
  }
  saveModelAs(e) {
    if (this.state.showField) {
      this.setState({ showField: false });
    } else {
      this.setState({ showField: true });
    }
  }
  handleSaveAsChange(e) {
    this.setState({ saveAsName: e.target.value });
    if (e.target.value !== "") {
      this.setState({ showSave: true });
    }
  }
  async queryResponses(model) {
    let insertArray = [];

    let url = "/api/keyvalue?model=" + model;

    const response = await fetch(url);

    const data = await response.json();

    this.setState({ allKeyValues: data });
  }
  async insertResponses() {
    let insertUrl = "/api/saveAsResponses?model=" + this.state.saveAsName;

    const response2 = await fetch(insertUrl, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.allKeyValues),
    });
    const d = await response2.json();
  }
  async saveModelWithNewName(e) {
    this.setState({ oldModelName: this.props.model });

    const queryResponse = await this.queryResponses(this.props.model);

    const ir = await this.insertResponses();

    const ss = await this.setState({ model: this.state.saveAsName });

    await this.saveDataModel();
    this.setState({ showField: false });
    this.setState({ showSave: false });
  }
  processFile = (content) => {
    this.setState({ importedFile: content });
  };
  handleShowImport(e) {
    if (this.state.showImport) {
      this.setState({ showImport: false });
    } else {
      this.setState({ showImport: true });
    }
  }
  async deleteModelFromDb() {
    let url = "/api/deleteModelFromDb?model=" + this.state.model;

    const response = await fetch(url);

    const data = await response.json();

    if (data && data.length === 0) {
      this.setState({ notifierDeleteOpen: true });
      this.props.modelDeleted();
      this.setState({ keyValue: [] });
    }
  }
  handleConfirmDeleteClose(event) {
    if (event.target.innerText === "YES") {
      this.deleteModelFromDb();
    } else {
    }
    this.setState({ confirmDeleteOpen: false });
  }
  handleConfirmStartTrainingClose(event) {
    if (event.target.innerText === "YES") {
      this.writeTrainingInfoToDb();
      this.postTrainingDataToKv();
    }
    this.setState({ confirmStartTraining: false });
  }
  async handleConfirmStartTrainingCloseNew(event) {
    if (event.target.innerText === "YES") {
      const tok = await this.getR4Token();

      this.postToKv(this.state.model);
    }
    this.setState({ confirmStartTrainingNew: false });
  }
  async getR4Token() {
    let url = "/api/getR4Token";

    const response = await fetch(url);
    const data = await response.json();

    if (data && data.access_token) {
      this.setState({ token: data.access_token });
    }
  }
  handleConfirmDeleteCloseIntent(event) {
    if (event.target.innerText === "YES") {
      let newArray = [];
      this.state.keyValue.map((intent) => {
        if (intent.name !== this.state.intentNameToDelete) {
          newArray.push(intent);
        }
      });

      this.setState({ keyValue: newArray, clearSearch: true }, () => {
        this.saveDataModel();
      });
    }
    this.setState({ confirmDeleteOpenIntent: false });
  }

  render() {
    const { classes } = this.props;
    const { date, toDate, value } = this.state;
    const renderTable = this.state.confData;
    const { data: stateData, cursor } = this.state;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <IntentList
          resetNewTrainingData={this.resetNewTrainingData}
          selectIntent={this.state.dataToUse}
          roles={this.props.roles}
          setPipeLine={(pipeline) => {
            this.setState({ pipeline: pipeline });
          }}
          ensureTrainingModel={this.ensureTrainingModel}
          voicebot={this.state.voicebot}
          selectedIntent={(intent) => {
            this.setState({ selectedIntent: intent });
          }}
          saveUpdatedResponseToKv={(name, newObject) => {
            this.postToKvResponse(name, newObject);
          }}
          showTrainModel={this.showTrainModel}
          aiAssisted={(type) => {
            this.setState({
              chatGpt: this.state.chatGpt ? false : true,
              assistType: type === "test" ? type : null,
            });
          }}
          openTestIntents={() => {
            this.setState({
              testIntents: this.state.testIntents ? false : true,
            });
          }}
          updateSentences={this.state.sentences}
          updateTestSentences={this.state.testSentences}
          updateModels={this.state.updateModels}
          chatGpt={this.chatGpt}
          openOverview={this.openOverview}
          clearSearch={this.state.clearSearch}
          resetOpenIntent={() => {
            this.props.resetOpenIntent();
          }}
          setModifiedIntent={(intent) => {
            this.setState({ lastModifiedIntent: intent });
          }}
          resetClearSearch={() => {
            this.setState({ clearSearch: false });
          }}
          loggedInUserEmail={this.props.loggedInUserEmail}
          loggedInUserName={this.props.loggedInUserName}
          initialLoad={(model, modelArray) => {
            this.queryIntentResponse(model);
            this.setState({ allModels: modelArray });
            this.loadAllTrainingData(modelArray);
            this.getTrainingInfoToDb(model);
          }}
          setAllModels={(modelArray) => {
            this.setState({ allModels: modelArray });
          }}
          allTrainingData={this.state.allTrainingData}
          postTrainingDataToKv={() => {
            this.getTrainingInfoToDb();
            this.setState({ confirmStartTraining: true });
          }}
          postTrainingDataToKvNew={() => {
            this.setState({ confirmStartTrainingNew: true });
          }}
          firstIntent={this.state.firstIntent}
          openIntentResponse={this.props.openIntentResponse}
          openIntent={this.props.openIntent}
          cancelAddFromQuery={() => {
            this.props.cancelAddFromQuery();
          }}
          changeModel={this.changeModel}
          handleExport={this.handleExport}
          handleShowImport={() => {
            this.setState({ openProcessFile: true });
          }}
          dataToUse={this.props.dataToUse}
          deleteIntent={this.deleteIntent}
          gridValue={this.state.gridValue}
          deleteIntentByName={this.deleteIntentByName}
          updateDataModel={this.updateDataModel}
          updateResponseToDataModel={this.updateResponseToDataModel}
          data={this.state.keyValue}
          synonyms={this.state.synonyms}
          saveUpdatedTraining={this.saveModel}
          addToIntentDataModel={this.addToIntentDataModel}
          saveUpdatedSynonyms={this.saveUpdatedSynonyms}
          selected={this.state.selected}
          deleteSynonym={this.deleteSynonym}
          model={this.props.model}
          pipeline={this.state.pipeline}
          account={this.props.account}
          openWorkflow={this.openWorkflow}
          getIntentList={this.updateKeyValue}
          firstQuery={this.state.firstQuery}
          menuOpen={this.props.menuOpen}
          validateTrainingData={this.validateTraining}
        />

        {this.state.chatGpt && (
          <ChatGpt
            assistType={this.state.assistType}
            trainingData={this.state.firstQuery}
            selectedIntent={
              this.state.selectedIntent
                ? this.state.selectedIntent
                : this.state.keyValue[0].name
            }
            closeChatGpt={() => {
              this.setState({
                openModal: false,
                chatGpt: false,
                assistType: null,
              });
            }}
            openModal={this.state.openModal}
            model={this.state.model}
            updateSentences={this.updateSentences}
            updateTestSenxtences={this.updateTestSentences}
            account={this.props.account}
          />
        )}
        {this.state.testIntents && (
          <TestIntents
            reset={() => {
              this.setState({ updatedFromtestIntents: false });
            }}
            updatedFromtestIntents={this.state.updatedFromtestIntents}
            addTrainingSentence={(intent, sentence) => {
              this.addTrainingSentence(intent, sentence);
            }}
            modalClose={() => {
              this.setState({ testIntents: false });
            }}
            model={this.state.model}
            account={this.props.account}
            trainingData={this.state.keyValue}
          />
        )}

        <Modal
          aria-labelledby="import training data"
          aria-describedby="simple-modal-description"
          open={this.state.openProcessFile}
          onClose={this.handleProcessFileClose}
        >
          <div style={getModalStyle()} className={classes.modal}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" id="modal-title">
                Import training data
              </Typography>
              <Typography variant="subtitle1" id="simple-modal-description">
                <div style={{ marginTop: "30px", marginRight: "-60px" }}>
                  <FileReader processFile={this.processFile} />
                </div>
              </Typography>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                color="white"
                onClick={() => {
                  this.setState({ openProcessFile: false, importedFile: null });
                }}
                style={{ height: "50%", maxWidth: "50%" }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                onClick={() => {
                  this.convertImported(this.state.importedFile);
                }}
                style={{ marginLeft: "20px", height: "50%", maxWidth: "50%" }}
              >
                Import
              </Button>
            </div>
          </div>
        </Modal>

        <Dialog
          open={this.state.confirmDeleteOpen}
          onClose={this.handleConfirmDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {this.state.model}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirmDeleteClose} color="primary">
              Yes
            </Button>
            <Button
              onClick={this.handleConfirmDeleteClose}
              color="primary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.confirmDeleteOpenIntent}
          onClose={this.handleConfirmDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {this.state.intentNameToDelete}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleConfirmDeleteCloseIntent}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={this.handleConfirmDeleteCloseIntent}
              color="primary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.confirmStartTrainingNew}
          onClose={this.handleConfirmStartTrainingCloseNew}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Train"}</DialogTitle>
          {this.state.trainingInfoTimestamp && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description2">
                The last training was started at:{" "}
                {this.state.trainingInfoTimestamp}
                <br></br>
                By: {this.state.trainingInfoName}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to start a new training session?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleConfirmStartTrainingCloseNew}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={this.handleConfirmStartTrainingCloseNew}
              color="primary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.confirmStartTraining}
          onClose={this.handleConfirmStartTrainingClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Train"}</DialogTitle>
          {this.state.trainingInfoTimestamp && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description2">
                The last training was started at:{" "}
                {this.state.trainingInfoTimestamp}
                <br></br>
                By: {this.state.trainingInfoName}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to start a new training session?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleConfirmStartTrainingClose}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={this.handleConfirmStartTrainingClose}
              color="primary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          aria-labelledby="Validation Result"
          aria-describedby="simple-modal-description"
          open={this.state.openValidator}
          onClose={this.handleValidatorClose}
        >
          <div style={getModalStyle()} className={classes.modalResult}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Paper style={{ padding: "10px" }}>
                <Typography variant="h4" id="modal-title">
                  Validation Result
                </Typography>
                <br />
                {this.state.validationResult &&
                  this.state.validationResult.intentsInTotal && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #ccc",
                          padding: "10px",
                          backgroundColor: "#E8E8E8",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                          }}
                        >
                          <span style={{ width: "250px", fontWeight: "bold" }}>
                            Intents:
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            {" "}
                            {this.state.validationResult.intentsInTotal}
                          </span>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                          }}
                        >
                          <span
                            style={{
                              width: "250px",
                              fontWeight: "bold",
                            }}
                          >
                            Number of active intents:
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            {this.state.validationResult.numberOfActiveIntents}
                          </span>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                          }}
                        >
                          <span
                            style={{
                              width: "250px",
                              fontWeight: "bold",
                            }}
                          >
                            Number of disabled intents:
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            {
                              this.state.validationResult
                                .numberOfDisabledIntents
                            }
                          </span>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                          }}
                        >
                          <span
                            style={{
                              width: "250px",
                              fontWeight: "bold",
                            }}
                          >
                            Number of issues found:
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            {this.state.validationResult.numberOfIssues}
                          </span>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "16px",
                          }}
                        >
                          <span style={{ width: "250px", fontWeight: "bold" }}>
                            Active sentences:
                          </span>
                          <span style={{ marginLeft: "20px" }}>
                            {
                              this.state.validationResult
                                .numberOfActiveSentences
                            }
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
              </Paper>
              <br />
              <Paper style={{ padding: "10px" }}>
                {!this.state.edit && <h3>Issues</h3>}
                <div
                  style={{
                    height: "350px",
                    maxHeight: "350px",
                    overflow: "auto",
                  }}
                >
                  {!this.state.edit && this.renderValidation()}

                  {this.state.edit && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h3>Edit</h3>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontStyle: "italic",
                          fontSize: "16px",
                        }}
                      >
                        {this.state.editObject.message}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          value={this.state.editSentence}
                          onChange={this.handleEditSentence}
                          margin="normal"
                          multiline={false}
                          variant="outlined"
                          label="Sentence"
                          style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "keep-all",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            variant="outlined"
                            type="submit"
                            color="primary"
                            onClick={this.saveEditedSentence}
                            style={{
                              height: "55px",
                              marginLeft: "10px",
                              backgroundColor: "#3E51B5",
                              color: "#FFF",
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            type="submit"
                            color="primary"
                            onClick={() => {
                              this.setState({ edit: false });
                            }}
                            style={{
                              height: "55px",
                              marginLeft: "10px",
                              backgroundColor: "#3E51B5",
                              color: "#FFF",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Paper>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                onClick={() => {
                  this.setState({ openValidator: false, edit: false });
                }}
                style={{
                  height: "50%",
                  maxWidth: "50%",
                  backgroundColor: "#3E51B5",
                  color: "#FFF",
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>

        <Notifier
          changeState={this.changeState}
          open={this.state.notifierOpen}
          message="Model saved successfully"
        />

        <Notifier
          changeState={this.changeState}
          open={this.state.notifierCreateOpen}
          message="New model created successfully"
        />

        <Notifier
          changeState={this.changeState}
          open={this.state.notifierTrainOpen}
          message={this.state.trainingComplete + " " + this.state.modelTrained}
        />

        <Notifier
          changeState={this.changeState}
          open={this.state.notifierDeleteOpen}
          message={"Model " + this.state.model + " Deleted Successfully"}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Trainingdata);
