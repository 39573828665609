import React, {Component} from "react";
import {ListItem, ListItemText, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import Button from "@material-ui/core/Button/Button";
import OptionsAction from "./OptionsAction";
import {Flowpoint, Flowspace} from 'flowpoints';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConversationMessage from "./ConversationMessage";
import ImageInChat from "./ImageInChat";
import PresentUrl from "./PresentUrl";
import Message from '@material-ui/icons/Message';
import Menu from '@material-ui/icons/Menu';
import Image from '@material-ui/icons/Image';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DeveloperMode from '@material-ui/icons/DeveloperMode';
import Link from '@material-ui/icons/Link';
import Highlight from '@material-ui/icons/Highlight';
import Save from '@material-ui/icons/Save';
import Receipt from '@material-ui/icons/Receipt';
import Bookmark from '@material-ui/icons/Bookmark';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import NavigateNext from '@material-ui/icons/NavigateNext';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckGroupStatusAndTransfer from './CheckGroupStatusAndTransfer';
import AskForContext from './AskForContext';
import WebsiteNavigate from './WebsiteNavigate';
import VergicTemplate from "./VergicTemplate";
import HighlightElement from "./HighlightElement";
import Fab from '@material-ui/core/Fab';
import ResponseOnIntent from "./ResponseOnIntent"
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from "@material-ui/core/InputLabel";
import ReactDOM from "react-dom";
import CustomAction from "./CustomAction";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Settings from "@material-ui/icons/Settings";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        background: "#DDDDDD",
        marginTop: "0px"

    },
    flowPoint: {
        backgroundColor: "#000"

    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {
        fontSize: "15px",
        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',

        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    drawerPaper: {}

});


const data = [
    {"name": "Conversation Message", "manifest": {"type": "conversation.message", "message": "this is a message"}},
    {
        "name": "Options", "manifest": {
            "type": "website.options",
            "options": [
                {
                    "text": "Show me something larger",
                    "accepts": [
                        "Show me something larger"
                    ],
                    "onMatch": {
                        "type": "workflow.includeFlow",
                        "flow": "A_WORKFLOW_PRESENTING_SOMETHING_LARGER"
                    }
                }
            ],
            "chooseDefaultAfterSec": 120,
            "defaultOption": "Show me something larger",
            "forwardUnmatchedMessageTo": {
                "type": "conversation.message",
                "message": "Chat received but not handled by any action available now. Expected is that the ai service will receive and analyse the message"
            }
        }
    },
    {"name": "Website Navigate", "manifest": {"type": "website.navigate", "url": "http://www.google.se"}},
    {
        "name": "Image In Chat",
        "manifest": {
            "type": "website.imageInChat",
            "path": "http://www.google.se",
            "title": "http://www.google.se",
            "caption": "http://www.google.se"
        }
    },
    {
        "name": "Link",
        "manifest": {"type": "website.presentUrl", "text": "MY LINK TITLE", "url": "https://www.MY_DOMAIN.com/PATH"}
    },

    {
        "name": "Check group status and transfer",
        "manifest": {"type": "case.checkGroupStatusAndTransfer", "groupId": "GROUP_ID"}
    },
    {"name": "Ask for context in conversation", "manifest": {"type": "conversation.askForContext", "context": "email"}},
    {
        "name": "Vergic Template",
        "manifest": {
            "type": "temp.vergicTemplate",
            "text": "MY TITLE",
            "linkId": "GUID",
            "url": "https://www.MY_DOMAIN.com/PATH"
        }
    },
    {
        "name": "Highlight Element",
        "manifest": {"type": "website.highLightElementLocatedByTargetPath", "targetPath": "PATH"}
    },
    {
        "name": "Saved Workflow",
        "manifest": {
            "type": "vergicAi.responseOnIntent",
            "model": "MODEL",
            "pipeline": "PIPELINE",
            "initiateAiWithResponse": "Savedworkflow",
            "account": "accountx"
        }
    },
    {
        "name": "Custom Action",
        "manifest": {"type": "custom.action"}
    }

];

//{"name": "Transfer To Agent", "manifest": {"type": "case.transferToAgent"}},
class WorkflowOverview extends Component {


    constructor(props) {

        super(props);

        this.state = {
            filter: '',
            workFlowRender: null,
            textFieldValue: '',
            position: 0,
            flowpoints: [],
            right: true,
            response: [],
            message: "",
            website: "",
            showUpdatedResponseSave: false,
            groupId: "",
            context: "",
            createWorkflow: false,
            value: 0,
            errorMessage: "",
            viewPortHeight: "70vh",
            optionsType: "options"

        }




        this.reactOnListClick = this.reactOnListClick.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.renderFlowPoints = this.renderFlowPoints.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);

        this.onFlowClick = this.onFlowClick.bind(this);

        this.workflowNavigate = this.workflowNavigate.bind(this);
        this.passForwardParams = this.passForwardParams.bind(this);

        this.clearInput = this.clearInput.bind(this);


    }

    componentDidMount() {


        this.setState({startPositionX: 150});
        this.setState({value: 1});

        if (this.props.workflowCreate) {
            // console.log(this.props.workflowCreate)
            this.setState({viewPortHeight: "82vh"});
            this.setState({workflowCreate: true});
            this.setState({right: true});
            this.setState({startPositionX: 250});

        }

        this.state = {
            startPositionX: 235
        }


    }

    clearInput() {

        this.setState({filter: ""});

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && JSON.stringify(prevProps.flowpoints) !== JSON.stringify(this.props.flowpoints))) {

            this.setState({flowpoints: this.props.flowpoints});


        }


        if (prevProps && prevProps.editOrAdd !== this.props.editOrAdd) {

            if (this.props.editOrAdd) {
                //     console.log("editoradd")
                this.setState({value: 1});
            }
        }

        if ((prevState && prevProps.updatedWorkflow !== this.props.updatedWorkflow)) {

            this.setState({updatedWorkflow: this.props.updatedWorkflow});

        }

        if (prevProps && prevProps.activeWorkflow !== this.props.activeWorkflow) {
            this.setState({prevWorkflow: prevProps.activeWorkflow})
            this.setState({activeWorkflow: this.props.activeWorkflow})
        }


        if ((prevState && JSON.stringify(prevState.response) !== JSON.stringify(this.state.response))) {

            this.setState({flowpoints: []});
            this.convertWorkflowToFlowPoints();

            if (JSON.stringify(prevState.response) !== "[]" && this.state.prevWorkflow === this.state.activeWorkflow) {


                this.saveWorkflow();


            }
            //this.setState({showUpdatedResponseSave: true});

        }

        if ((prevProps && JSON.stringify(prevProps.response) !== JSON.stringify(this.props.response))) {

            this.setState({response: this.props.response});

            this.setState({renderType: ""});
            this.convertWorkflowToFlowPoints();

        }
        if ((prevProps && prevProps.newNameResponseOnIntent !== this.props.newNameResponseOnIntent)) {

            this.setState({value: 1});
            this.setState({createWorkflow: false});

        }



        if ((prevProps && prevProps.newWorkflow !== this.props.newWorkflow)) {

            if (this.props.newWorkflow === "true") {


                this.setState({flowpoints: []});
                this.setState({response: []});

                this.setState({value: 1});
            }

        }
        if ((prevProps && prevProps.backToAdd !== this.props.backToAdd)) {
            if(this.props.backToAdd){
                this.setState({value: 1});
                this.setState({createWorkflow: false});
                this.props.resetAdd();
            }
        }



    }

    passForwardParams = (params) => {

        this.setState({passForwardParams: params});

    }

    reactOnListClick = (event, i) => {

        this.setState({selectedWorkflow: event.target.innerText});
        this.setState({selected: i});
        this.setState({workFlowRender: data[i].manifest})
        this.setState({createWorkflow: true});

    }

    handleChange(e) {

        this.setState({textFieldValue: e.target.value});

    }

    workflowNavigate() {



        if (this.state.workflowNavigate) {

            this.setState({createWorkflow: false});
        } else if(this.state.value === 1 && !this.state.workflowNavigate){

            this.setState({createWorkflow: false});
        }else {

            this.setState({createWorkflow: true});
        }


    }

    toggleDrawer = (side) => () => {
        // console.log(this.state.right);
        if (this.state.right) {

            this.setState({
                [side]: false,
            });

        } else {
            this.setState({value: 1})
            this.setState({
                [side]: true,
            });

        }

    }





    onFlowClick = (event, key) => {


         this.props.passWorkflowToOpen(key);




    };

    renderFlowPoints() {

        return this.props.flowpoints.map((point, key) => {

            // {point.content instanceof Object ? console.log("") : console.log(point.content)}

            return <Flowpoint
                onClick={(e) => this.onFlowClick(e, point.key)}
                startPosition= {{
                    x: point.startPoint.x,
                    y: point.startPoint.y
                }}
                outputs={point.outputs}
                key={point.key}
                style={{
                    padding: "10px",
                    minHeight: "100px",
                    height:  "100px"
                }}
                selected={this.state.selected_point === key}
                id={key}
                width={200}
                dragX={true}
                dragY={true}
            >

                <div style={{
                    wordBreak: "break-all",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    flexGrow: "1",
                    marginTop: "20px"
                }}>

                    {point.type === "vergicAi.responseOnIntent" ? <Save style={{marginRight: "10px"}}/> : <div></div>}

                    {point.name}

                </div>

                {point.type === 'website.imageInChat' ? <img style={{maxHeight: "55px"}} src={point.content}/> : ""}

                {point.content instanceof Object && Array.isArray(point.content) ? point.content.map((object) => {
                    return <li key={Math.random()} style={{marginLeft: "10px"}}>{object}</li>
                }) : " "}
                {point.content instanceof Object || point.type === 'website.imageInChat' ? " " :
                    <Tooltip title={point.content} aria-label={point.content}>
                        <div
                            style={{wordBreak: "break-all"}}>{point.content && point.content.length > 60 ? point.content.substring(0, 60) + "..." : point.content}</div>
                    </Tooltip>}

            </Flowpoint>

        })


    }



    render() {
        const {classes} = this.props;
        return (

            <div style={{margin: "40px",height: "80vh", overflowY: "scroll", overflowX: "hidden"}}>
                <div style={{height: "100vh", overflowY: "scroll", overflowX: "hidden"}}>

                        <Flowspace
                            background="white"
                            variant="filled"
                            theme="light-blue"
                            connectionSize="6"
                            arrowStart={false}
                            arrowEnd={false}
                            style={{marginTop: "20px", marginRight: "-400px", height: "100%", width: "100%"}}
                            selected={this.state.selected_point}
                            onClick={(e) => {
                                this.setState({renderType: ""})
                                this.setState({selected_point: null});
                                this.setState({value: 1});
                            }}
                        >
                            {this.renderFlowPoints()}
                        </Flowspace>
                </div>
            </div>

        )
    }

}

export default withStyles(styles)(WorkflowOverview);
