import "./chart.css";
import React from "react";
const TimeLine = () => {
  return (
    <div className="journey-timeline-container">
      <div className="journey-timeline-container-contain">
        <div className="journey-timeline-container-upper"></div>
        <div className="journey-timeline-container-point"></div>
        <div className="journey-timeline-container-upper"></div>
      </div>
    </div>
  );
};

export default TimeLine;
