import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import Button from "@material-ui/core/Button/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";



const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class ChatInput extends Component {


    constructor(props) {

        super(props);

        this.handleSwitchChange = this.handleSwitchChange.bind(this);

        this.pass = this.pass.bind(this);
        this.state = {
            workFlowRender: null,
            switchChecked: true,
            switchLabel: "Enabled"
        };

    }
    handleSwitchChange(event) {


        this.setState({
            switchChecked: this.state.switchChecked ? false : true,
            switchLabel: this.state.switchLabel === "Enabled" ? "Disabled" : "Enabled"
        });
        //this.setIntentStatus(event.currentTarget.checked)
    }

    componentDidMount() {
        if (this.props.value) {


             if(this.props.value.trigger && this.props.value.trigger === "disable"){
                 this.setState({switchChecked: false, switchLabel: "Disabled" });

             }else{
                 this.setState({switchChecked: true, switchLabel: "Enabled" });
             }
        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {

        if ((prevProps && prevProps.value !== this.props.value)) {



            if(this.props.value.trigger && this.props.value.trigger === "disable"){
                this.setState({switchChecked: false, switchLabel: "Disabled" });

            }else{
                this.setState({switchChecked: true, switchLabel: "Enabled" });
            }
        }

    }





    pass() {

        let workflow = {type: "case.chatInput", "trigger": this.state.switchChecked?"enable":"disable"};

        this.props.passData("case.chatInput", workflow);

    }


    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <p style={{fontWeight: "bold", fontSize: "16px"}}>Enable/disable Chat Input Field</p>
                    <FormGroup>
                        <FormControlLabel

                            control={<Switch color="primary"
                                             checked={this.state.switchChecked}
                                             onChange={this.handleSwitchChange}
                                             name="active"/>}
                            label={this.state.switchLabel}
                        />
                    </FormGroup>
                    {this.props.value ?
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop:"15px"}}
                                variant="contained" onClick={this.pass}>Update</Button> :
                        <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop:"15px"}}
                                variant="contained" onClick={this.pass}>Add</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(ChatInput);
