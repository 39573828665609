import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import ChipInput from "material-ui-chip-input";
import ChatIcon from "@material-ui/icons/Chat";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    chipRoot: { margin: theme.spacing(1), height: '100%', display: 'flex', flexDirection: 'column' }, chipLabel: { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' }

});


class CheckIfContextIsSet extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.changeSetFlow = this.changeSetFlow.bind(this);
        this.changeNotSetFlow = this.changeNotSetFlow.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.handleChangeEqual = this.handleChangeEqual.bind(this);
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.getContext = this.getContext.bind(this);
        this.handleExpandChange = this.handleExpandChange.bind(this);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.handlePredefinedChange = this.handlePredefinedChange.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.handleCaseChange = this.handleCaseChange.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.renderEntityMenuItems = this.renderEntityMenuItems.bind(this);
        this.renderCasesMenuItems = this.renderCasesMenuItems.bind(this);
        this.getCaseMappings = this.getCaseMappings.bind(this);
        this.getSites = this.getSites.bind(this);
        this.getTokenUser = this.getTokenUser.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.onChangePattern = this.onChangePattern.bind(this);
        this.validateRegEx = this.validateRegEx.bind(this);
        this.testRegularExpression = this.testRegularExpression.bind(this);

        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            commonWorkflows: [],
            setFlow: {"Id": ""},
            notSetFlow: {"Id": ""},
            equalValue: "",
            operator: "",
            type: "Operator",
            yourChips: [],
            expanded: "panel1",
            predefinedContext: "",
            entityContext: "",
            caseValue: "",
            entities: [],
            cases: [],
            pattern: "",
            testSentence: ""


        }
        //this.convRef = React.createRef();
    }
    testRegularExpression(event){

       let validateAgainst = new RegExp(this.state.pattern, "i");

        if(this.state.testSentence && this.state.testSentence !== ""){
            const validated = validateAgainst.test(this.state.testSentence);
            let validatedString = "Not Set will be executed";
            if(validated){

                validatedString = "Set will be executed";

            }
            this.setState({validated: validated, validatedString: validatedString, showTest: true});
        }


    }

    validateRegEx(event){
        if(this.state.pattern && this.state.pattern !==""){
        let regEx;
        try{

            regEx = new RegExp(this.state.pattern);

        }catch(e){

            this.setState({regExError: e.toString});
        }

        }
    }
    onChangePattern(event){

        this.setState({pattern: event.target.value});
    }

    handleTypeChange(event){

        this.setState({type: event.target.value});

    }
    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            data.map((site) => {

                this.getCaseMappings(site);


            });

        }
    }

    async getCaseMappings(site) {

        let url = '/api/getCases?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let cases = [...data, ...this.state.cases];


            this.setState({cases: cases});

        }
    }


    handleEntityChange(e) {

        this.setState({entityContext: e.target.value});
    }

    handleCaseChange(e) {

        this.setState({caseValue: e.target.value});

    }

    renderCasesMenuItems() {
        if (this.state.cases) {
            return this.state.cases.map((value) => {

                return <MenuItem key={Math.random()} value={value.id}>{value.name}</MenuItem>

            });
        }

    }

    renderEntityMenuItems() {
        if (this.state.entities) {
            return this.state.entities.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }

    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            })


        }


    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;


        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );


        let data = await response.json();
        let entities;
        if (i === 0) {
            entities = [];
        } else {
            entities = this.state.entities ? this.state.entities : [];
        }

        if (data.rasa_nlu_data.entity_synonyms && data.rasa_nlu_data.entity_synonyms.length > 0 && typeof data.rasa_nlu_data.entity_synonyms[0] === "string") {
            this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]});
        }


    }

    handlePredefinedChange(e) {

        this.setState({predefinedContext: e.target.value});

    }

    renderContextMenuItems() {
        if (this.state.contextValues) {
            return this.state.contextValues.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }

    handleExpandChange(panelId) {
        this.setState({expanded: panelId});
    }

    async getContext() {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {

                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (this.props.value && this.props.value.key && object === this.props.value.key) {

                    this.setState({expanded: 'panel1'}, () => {
                        this.setState({predefinedContext: object});
                    });

                }

            })
        }


    }


    handleAddChip = (chip) => {

        let chips = this.state.yourChips;
        chips.push(chip);

        this.setState({yourChips: chips})

    }


    handleDeleteChip = (chip, index) => {


        let chips = this.state.yourChips;

        chips.splice(index, 1);

        this.setState({yourChips: chips});
    }


    handleOperatorChange(e) {

        this.setState({operator: e.target.value});

    }


    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];

            workflowArray.push({"Id": "[New Workflow]"});


            workflowArray.push({"Id": "Workflow.GoNext"});
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length - 1]) {


                    let found = false;
                    workflowArray.map((o) => {

                        if (o.Id === arraySplit[arraySplit.length - 1]) {

                            found = true;
                        }

                    });

                    if (!found) {

                        if (arraySplit.length > 3) {

                            workflowArray.push({
                                "Id": arraySplit[arraySplit.length - 1],
                                "Category": arraySplit[arraySplit.length - 2]
                            });
                        } else {
                            workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                        }
                    }

                }

            });

            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })

            this.setState({commonWorkflows: workflowArray});


        }


    }

    componentDidMount() {
        this.getTokenUser();
        this.getSavedModels();
        this.getAllCommonWorkflows();
        this.getContext();


        if (this.props.value) {
            this.setState({edit: true});


            this.setState({textFieldValue: this.props.value.key});
            this.setState({equalValue: this.props.value.value});
            if (this.props.value.values) {

                this.setState({yourChips: this.props.value.values});

            }else{


                if (this.props.value.value) {
                    this.setState({yourChips: this.props.value.value.split(",")});
                }
            }

            if (this.props.value.pattern) {

                this.setState({type:"Pattern", pattern: this.props.value.pattern});
            }

            if (this.props.value.contextType) {

                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.key});
                    this.setState({contextType: "predefinedContext", expanded: "panel1"});

                } else if (this.props.value.contextType === "caseTypeId") {

                    this.setState({caseValue: this.props.value.value});

                    this.setState({contextType: "caseTypeId", expanded: "panel4"});


                } else if (this.props.value.contextType === "entityContext") {
                    this.setState({entities: []});
                    this.setState({entityContext: this.props.value.key});
                    this.setState({contextType: "entityContext", expanded: "panel3"});


                } else {
                    this.setState({textFieldValue: this.props.value.key});
                    this.setState({contextType: "manual", expanded: "panel2"});
                }
            } else {
                this.setState({contextType: "manual", expanded: "panel2"});

            }

            if (this.props.value.method) {
                this.setState({operator: this.props.value.method});
            }
            if (this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent") {
                if (this.props.value.onMatch.type === "workflow.goNext") {
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({setFlow: {"Id": this.props.value.onMatch.type}});
                }

            } else {
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if (this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent") {

                if (this.props.value.onFailure.type === "workflow.goNext") {
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});
                }


            } else {
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }

        }


        if (this.props.optionAction) {

            this.setState({optionAction: this.props.optionAction});
        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({edit: true});
            //this.getSavedModels();

            this.setState({textFieldValue: this.props.value.key});

            this.setState({equalValue: this.props.value.value});
            if (this.props.value.pattern) {

                this.setState({type:"Pattern", pattern: this.props.value.pattern});
            }
            if (this.props.value.values) {

                this.setState({yourChips: this.props.value.values});

            }else{
                if(this.props.value.value) {
                    this.setState({yourChips: this.props.value.value.split(",")});
                }
            }

            if (this.props.value.contextType) {

                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.key});
                    this.setState({contextType: "predefinedContext", expanded: "panel1"});

                } else if (this.props.value.contextType === "entityContext") {
                    // this.setState({entities: []});
                    this.setState({entityContext: this.props.value.key});
                    this.setState({contextType: "entityContext", expanded: "panel3"});


                } else if (this.props.value.contextType === "caseTypeId") {

                    this.setState({caseValue: this.props.value.value});
                    this.setState({contextType: "caseTypeId", expanded: "panel4"});


                }else {
                    this.setState({textFieldValue: this.props.value.key});
                    this.setState({contextType: "manual", expanded: "panel2"});
                }
            } else {

                this.setState({contextType: "manual", expanded: "panel2"});
            }



            if (this.props.value.method) {
                this.setState({operator: this.props.value.method});
            }
            if (this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent") {
                if (this.props.value.onMatch.type === "workflow.goNext") {
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({setFlow: {"Id": this.props.value.onMatch.type}});
                }

            } else {
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if (this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent") {
                if (this.props.value.onFailure.type === "workflow.goNext") {
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                } else {
                    this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});
                }

            } else {
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }


        }

        if ((prevProps && prevProps.newFromCheckIfContextIsSetType !== this.props.newFromCheckIfContextIsSetType)) {


            if (this.props.newFromCheckIfContextIsSetType) {
                if (this.props.newNameCheckIfContextIsSet) {
                    this.pass();
                    //this.setState({setFlow: {"Id": }})
                }
            }

        }

        if ((prevProps && prevProps.newNameCheckIfContextIsSet !== this.props.newNameCheckIfContextIsSet)) {

            if (this.props.newFromCheckIfContextIsSetType) {
                if (this.props.newNameCheckIfContextIsSet) {
                    this.pass();
                }
            }
        }
    }

    showEmoji(event) {

        if (this.state.showPicker) {

            this.setState({showPicker: false});
        } else {
            this.setState({showPicker: true});
        }

    }

    pass() {


        let type, newFlow;
        if (this.props.newFromCheckIfContextIsSetType) {

            type = this.props.newFromCheckIfContextIsSetType;
        }


        if (this.props.newNameCheckIfContextIsSet) {

            newFlow = this.props.newNameCheckIfContextIsSet;
        }

        let onMatch = {};
        let onFailure = {};


        if (this.state.setFlow.Id === "Workflow.GoNext") {

            onMatch = {"type": "workflow.goNext"};

        } else {

            onMatch = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.setFlow.Id,
                "replace": true,
                "account": this.props.account
            };
            if (this.state.setFlow.Id.indexOf("workflow.goNext") > -1) {
                delete onMatch.replace;
            }

            if (type && type === "setFlow") {
                onMatch.initiateAiWithResponse = newFlow;
            }
        }

        if (this.state.notSetFlow.Id === "Workflow.GoNext") {

            onFailure = {"type": "workflow.goNext"};

        } else {

            onFailure = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.notSetFlow.Id,
                "replace": true,
                "account": this.props.account
            };

            if (this.state.notSetFlow.Id.indexOf("workflow.goNext") > -1) {
                delete onFailure.replace;
            }

            if (type && type === "notSetFlow") {
                onFailure.initiateAiWithResponse = newFlow;
            }
        }

        if (onFailure.initiateAiWithResponse === '' && onMatch.initiateAiWithResponse && onMatch.initiateAiWithResponse !== '') {

            onFailure = {"type": "workflow.goNext"};
        }
        let textFieldValue = this.state.textFieldValue;

        let contextType = "manual";

        if (this.state.expanded === "panel1" && this.state.predefinedContext) {

            textFieldValue = this.state.predefinedContext;
            contextType = "predefinedContext";
        }

        if (this.state.expanded === "panel3" && this.state.entityContext) {

            textFieldValue = this.state.entityContext;
            contextType = "entityContext";
        }


        let workflow = {
            type: "case.checkIfContextIsSet", "onMatch": onMatch,
            "onFailure": onFailure
        };

        if (this.state.expanded === "panel4" && this.state.caseValue) {

            workflow.value = this.state.caseValue;

            this.state.cases.map((c)=>{

                if(c.id === this.state.caseValue){

                    workflow.caseName = c.name;
                }

            });

            workflow.key = "caseTypeId";
            workflow.contextType = "caseTypeId";

        }else{

            workflow.key = textFieldValue;
            workflow.contextType = contextType;

        }




        if (this.state.equalValue && this.state.equalValue !== "" && this.state.equalValue.split(",").length > 1) {
            workflow.value = this.state.equalValue;
            let valuesSplit = this.state.equalValue.split(",");
            let values = [];
            valuesSplit.map((o) => {
                values.push(o);

            });
            workflow.values = values;


        } else if (this.state.yourChips.length === 1 && this.state.expanded !== "panel4") {

            workflow.value = this.state.yourChips[0];
        } else if (this.state.yourChips.length > 0) {

            workflow.values = this.state.yourChips;

        }

        if(this.state.type === "Pattern"){

            workflow.pattern = this.state.pattern;
        }


        if (this.state.operator && this.state.operator !== " ") {

            workflow.method = this.state.operator;

        }

        this.props.passData("case.checkIfContextIsSet", workflow);


    }

    openWorkflow = (event, flow) => {


        this.props.openWorkflow(flow.Id);

    }

    changeSetFlow(event, value) {

        if (value && value.Id) {

            this.setState({setFlow: {"Id": value.Id}});

            if (value.Id === "[New Workflow]") {
                this.props.newWorkflowCheckIfContextIsSet("setFlow");

            }
        }

    }

    changeNotSetFlow(event, value) {
        if (value && value.Id) {
            this.setState({notSetFlow: {"Id": value.Id}});
        }

        if (value.Id === "[New Workflow]") {
            this.props.newWorkflowCheckIfContextIsSet("notSetFlow");

        }

    }

    handleChange(e) {

        this.setState({textFieldValue: e.target.value});

    }

    handleChangeEqual(e) {

        this.setState({equalValue: e.target.value});
    }


    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>

                <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                    this.handleExpandChange('panel1')
                }} style={{marginTop: "5px", marginBottom: "0px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Predefined Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                     className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                label="Predefined Context"
                                select
                                value={this.state.predefinedContext}
                                classes={{root: classes.select}}
                                onChange={this.handlePredefinedChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>


                                {this.renderContextMenuItems()}

                            </TextField>
                        </FormControl>

                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={this.state.expanded === 'panel2'} onClick={event => {
                    this.handleExpandChange('panel2')
                }} style={{marginTop: "5px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Manual Input Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <TextField
                            value={this.state.textFieldValue}
                            onChange={this.handleChange}
                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            label="Context Variable"
                            style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", width: "100%", marginTop: "-10px"}}
                        />

                    </AccordionDetails>
                </Accordion>


                {this.state.entities && this.state.entities.length > 0 &&
                    <Accordion expanded={this.state.expanded === 'panel3'} onClick={event => {
                        this.handleExpandChange('panel3')
                    }} style={{marginTop: "5px"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row"
                            }}>

                                <div style={{marginLeft: "0px", fontWeight: "bold"}}>Entity Context Variable</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                         className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    label="Entity Context Variable"
                                    select
                                    value={this.state.entityContext}
                                    classes={{root: classes.select}}
                                    onChange={this.handleEntityChange}
                                >
                                    {this.renderEntityMenuItems()}


                                </TextField>
                            </FormControl>

                        </AccordionDetails>
                    </Accordion>}


                {this.state.cases && this.state.cases.length > 0 &&
                    <Accordion expanded={this.state.expanded === 'panel4'} onClick={event => {
                        this.handleExpandChange('panel4')
                    }} style={{marginTop: "5px"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row"
                            }}>

                                <div style={{marginLeft: "0px", fontWeight: "bold"}}>Case Type</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                         className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    label="Case Type"
                                    select
                                    value={this.state.caseValue}
                                    classes={{root: classes.select}}
                                    onChange={this.handleCaseChange}
                                >
                                    {this.renderCasesMenuItems()}


                                </TextField>
                            </FormControl>

                        </AccordionDetails>
                    </Accordion>}

                <Paper style={{
                    padding: "10px",
                    marginTop: "30px",
                    overflow: "auto"
                }}>
                    <p style={{fontWeight: "bold", marginBottom: "-10px"}}>Options:</p>
                    {this.state.expanded !== 'panel4' && <div>

                        <FormControl variant="outlined"
                                     style={{
                                         borderColor: "black",
                                         marginTop: "15px",
                                         marginBottom: "20px",
                                         width: "100%"
                                     }}
                                     className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                label="Type"
                                select
                                value={this.state.type}
                                classes={{root: classes.select}}
                                onChange={this.handleTypeChange}
                            >
                                <MenuItem value={"Operator"}>{"Operator"}</MenuItem>
                                <MenuItem value={"Pattern"}>{"Pattern"}</MenuItem>
                            </TextField>
                        </FormControl>


                        {this.state.type === "Operator" && <React.Fragment>
                        <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                     className={classes.formControl}>
                            <ChipInput
                                value={this.state.yourChips}
                                variant="outlined"
                                classes={{ root: classes.chipRoot, label: classes.chipLabel, }}
                                label="Equal To (optional)"
                                style={{width: "100%", marginTop: "15px", marginLeft: "0px", marginBottom: "10px", wordWrap: "wrap"}}
                                onAdd={(chip) => this.handleAddChip(chip)}
                                onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                            />
                        </FormControl>

                        <FormControl variant="outlined"
                                     style={{
                                         borderColor: "black",
                                         marginTop: "15px",
                                         marginBottom: "20px",
                                         width: "100%"
                                     }}
                                     className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                label="Operator (optional)"
                                select
                                value={this.state.operator}
                                classes={{root: classes.select}}
                                onChange={this.handleOperatorChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"="}>{"="}</MenuItem>
                                <MenuItem value={">"}>{">"}</MenuItem>
                                <MenuItem value={"<"}>{"<"}</MenuItem>
                            </TextField>
                        </FormControl>
                        </React.Fragment>}

                        {this.state.type !== "Operator" && <div style={{display: "flex", flexDirection: "column"}}>
                            <TextField variant="standard" style={{width: "100%", height: '30px', marginBottom: "10px"}} label="RegEx Pattern" variant={"outlined"} type="text" onChange={this.onChangePattern} onBlur={this.validateRegEx} value={this.state.pattern} />
                            {this.state.regExError && <span>{this.state.regExError}</span>}
                            <Button style={{width: "100%", marginBottom: "10px", marginTop: "20px"}} variant="contained"
                                    color="primary" onClick={()=>{if(this.state.testRegEx){this.setState({testRegEx: false});}else{this.setState({testRegEx: true});}}}>Test RegEx</Button>
                            {this.state.testRegEx && <div><div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "baseline", marginBottom: "10px"}}>
                                <TextField variant="standard" style={{width: "250px", height: '30px'}} label="Test Sentence" variant={"outlined"} type="text" onChange={(event)=>{this.setState({testSentence:event.target.value})}} value={this.state.testSentence} />
                                <Button style={{padding: "15px", marginLeft: "2px", paddingBottom: "13px"}} variant="contained" size={"medium"}
                                        color="primary" onClick={this.testRegularExpression}>Test</Button>

                            </div>
                            {this.state.showTest && <div style={{display: "flex", flexDirection:"row", alignItems: "center", marginBottom: "10px"}}>
                                <Typography style={{fontSize: "14px", color:"black", marginTop: "10px", marginLeft: "0px"}}>Result: </Typography>
                                <Typography style={{fontSize: "14px", marginTop: "10px", color:this.state.validated?"green":"red",marginLeft: "5px"}}>{this.state.validatedString}</Typography></div> }
                            </div>    }

                        </div>}
                    </div>
                    }

                    <div style={{
                        marginBottom: "10px",
                        marginTop: this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ? "-5px" : this.state.type !== "Operator"? "0px": "-30px",
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        <Autocomplete
                            id="combo-box5"
                            options={this.state.commonWorkflows}
                            onChange={this.changeSetFlow}
                            value={this.state.setFlow}
                            getOptionLabel={option => option.Id}
                            style={{
                                width: "100%",
                                marginTop: this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ? "10px" : "40px"
                            }}
                            renderInput={params => <TextField {...params} variant="outlined" label="Set"
                                                              style={{minHeight: "100px"}}/>}
                        />
                        {this.state.edit && this.state.setFlow && this.state.setFlow.Id !== "Workflow.GoNext" ?
                            <Button style={{width: "100%", marginBottom: "0px", marginTop: "-30px"}} variant="contained"
                                    color="primary" onClick={event => this.openWorkflow(event, this.state.setFlow)}>Open
                                Workflow</Button> : ""}
                    </div>
                    <div style={{
                        marginBottom: "0px",
                        marginTop: this.state.edit && this.state.setFlow && this.state.setFlow.Id !== "Workflow.GoNext" ? "20px" : "-25px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around"
                    }}>
                        <Autocomplete
                            id="combo-box4"
                            options={this.state.commonWorkflows}
                            onChange={this.changeNotSetFlow}
                            value={this.state.notSetFlow}
                            getOptionLabel={option => option.Id}
                            style={{width: "100%", marginTop: "0px"}}
                            renderInput={params => <TextField {...params} variant="outlined" label="Not Set"
                                                              style={{minHeight: "100px"}}/>}
                        />
                        {this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ?
                            <Button style={{width: "100%", marginTop: "-30px"}} variant="contained"
                                    color="primary" onClick={event => this.openWorkflow(event, this.state.notSetFlow)}>Open
                                Workflow</Button> : ""}
                    </div>

                    {!this.props.option ?
                        <div
                            style={{marginTop: this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ? "10px" : "0px"}}>
                            {this.props.value && this.props.value.message ?
                                <Button
                                    style={{
                                        width: "100%",
                                        backgroundColor: "green",
                                        color: "white",
                                        fontWeight: "bold"
                                    }}
                                    variant="contained"
                                    onClick={this.pass}>Update</Button> :
                                <Button
                                    style={{
                                        width: "100%",
                                        backgroundColor: "green",
                                        color: "white",
                                        fontWeight: "bold"
                                    }}
                                    variant="contained"
                                    onClick={this.pass}>Add</Button>}

                        </div> : <div></div>}
                </Paper>
            </div>


        )
    }

}

export default withStyles(styles)(CheckIfContextIsSet);
