import React, {Component} from "react";
import {ListItem, ListItemText, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from  "@material-ui/icons/Edit";
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '15px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(),
    },
    list: {
        maxHeight: 200,
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',

    },
    listItem: {
      paddingLeft: "5px"

    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },   listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        }
    },


});


class AskForContext extends Component {


    constructor(props) {

        super(props);

        this.handleContextChange = this.handleContextChange.bind(this);
        this.handleValidationChange =  this.handleValidationChange.bind(this);
        this.reactOnContextClick = this.reactOnContextClick.bind(this);
        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleContextChange = this.handleContextChange.bind(this);
        this.handleValidationErrorChange = this.handleValidationErrorChange.bind(this);
        this.updateContextDb = this.updateContextDb.bind(this);
        this.removeContext = this.removeContext.bind(this);
        this.handleAddNew = this.handleAddNew.bind(this);
        this.handleAddNewClick = this.handleAddNewClick.bind(this);
        this.addNewContex = this.addNewContex.bind(this);
        this.getContext = this.getContext.bind(this);
        this.pass = this.pass.bind(this);
        this.editContext = this.editContext.bind(this);
        this.state = {
            workFlowRender: null,
            context: "",
            contextValues: [],
            validationError: "",
            question: "",
            validation: "",
            showAddNew: false,
            selectedIndex: -1,
            editContext: false,
            addNew: ""


        };
        this.listRef = React.createRef();
    }

    componentDidMount() {

        this.getContext();

        if(this.props.value){

            this.setState({context: this.props.value.context})


        }

    }

    async getContext() {

        let url = '/api/context';
        const response = await fetch(url);
        if(response) {

            const data = await response.json();

            if (data && data.name !== 'error') {
                this.setState({contextObject: data}, ()=>{

                    if(this.state.contextObject && this.state.context && data[this.state.context]) {
                        this.setState({question: data[this.state.context].question});
                        this.setState({validation: data[this.state.context].validation});
                        this.setState({validationError: data[this.state.context].validationErrorMessage});
                    }

                });
                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (object === this.state.context) {
                    this.setState({selectedIndex: i});

                }

            })
        }


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {


            this.setState({context: this.props.value.context})

        }

    }


    handleContextChange(e) {

        this.setState({context: e.target.value})

    }


    pass(){

        let workflow = {type: "conversation.askForContext", "context": this.state.context};
        this.props.passData("conversation.askForContext", workflow);


    }

    reactOnContextClick(event, objectName, i ) {
        this.setState({selectedIndex: i});
        this.setState({context: objectName});

         this.setState({question: this.state.contextObject[objectName].question});
        this.setState({validation: this.state.contextObject[objectName].validation});
        this.setState({validationError: this.state.contextObject[objectName].validationErrorMessage});

    }

    listRow(){


        return this.state.contextValues.map((object, i ) => {

            return <ListItem  onClick={event => this.reactOnContextClick(event, object, i)}
                              autoFocus={this.state.selectedIndex === i} selected={this.state.selectedIndex === i}
                              classes={{root: this.props.classes.listItemTextSelected}} key={i}
                             button><ListItemText id={i} primary={object}/></ListItem>


        });


    }

    handleQuestionChange(event){
        this.setState({question: event.target.value});
        let contextObject = this.state.contextObject;
        contextObject[this.state.context].question = event.target.value;
        this.setState({contextObject: contextObject});

    }

    handleContextChange(event){
        this.setState({context: event.target.value});




    }
    handleValidationErrorChange(event){
        this.setState({validationError: event.target.value});

        let contextObject = this.state.contextObject;
        contextObject[this.state.context].validationErrorMessage = event.target.value;
        this.setState({contextObject: contextObject});

    }
    handleValidationChange(event){

        this.setState({validation: event.target.value});

        let contextObject = this.state.contextObject;
        if(contextObject && contextObject[this.state.context]){
            contextObject[this.state.context].validation = event.target.value;
            this.setState({contextObject: contextObject});
        }


    }
    addNewContex(event){

        if(this.state.showAddNew){
            this.setState({showAddNew: false});
        }else {

            this.setState({showAddNew: true});
        }

        this.setState({validationError: "", validation: "", context: "", question: ""});


    }

    removeContext(event){


        let context = this.state.contextObject;

        delete context[this.state.context];


        this.setState({contextObject: context},this.updateContextDb);

        let curr = this.listRef.current;
        if(curr) {

            setTimeout(function () {

                curr.scrollTop = 900;
            }, 300);
        }

    }

    async updateContextDb(event){

        let url = '/api/updateContext?accountid='+this.props.account;

        let response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.contextObject)
        }).catch(error => console.log(error)
        );

        const data = await response.json();
        if (data && data.name !== 'error') {
            this.getContext();
        }


    }
    handleAddNew(event){
        this.setState({addNew: event.target.value});

    }

    editContext(e){
        this.setState(prevState => ({
            editContext: !prevState.editContext
        }));



    }
    handleAddNewClick(event){

        let context = this.state.contextObject;
        context[this.state.addNew] = {default: "", question: "", validation: "^.+$", validationErrorMessage: ""};
        this.setState({contextObject: context},this.updateContextDb);
        this.setState({editContext: true, showAddNew: false, context: this.state.addNew});
        this.setState({question: this.state.contextObject[this.state.addNew].question});
        this.setState({validation: this.state.contextObject[this.state.addNew].validation});
        this.setState({validationError: this.state.contextObject[this.state.addNew].validationErrorMessage});
        let curr = this.listRef.current;
        if(curr) {

            setTimeout(function () {

                curr.scrollTop = 900;
            }, 300);
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>

                    {this.state.contextValues.length > 0 ?
                    <div style={{border: "1px solid #ccc", width: "100%"}}>
                    <List
                        style={{ width: "99%"}}
                        component="nav"
                        className={classes.list}
                        id='thing'
                        ref={this.listRef}
                        value={this.state.context}
                    >
                        {this.listRow()}
                    </List>
                    </div> : <div></div>}
                    {this.state.contextValues.length > 0 ?
                    <div style={{display: "flex", flexDirection: "row"}}>
                    <Fab color="primary" onClick={this.editContext} aria-label="Edit"
                         className={classes.fab}>
                        <EditIcon/>
                    </Fab>
                    <Fab color="primary" onClick={this.addNewContex} aria-label="Add"
                         className={classes.fab}>
                        <AddIcon/>
                    </Fab>
                        <Fab color="primary" onClick={this.removeContext} aria-label="Remove"
                             className={classes.fab}>
                            <RemoveIcon/>
                        </Fab>
                    </div>
                        : <div></div>}

                    {this.state.contextValues.length === 0 ?
                    <TextField
                        label="Context"
                        value={this.state.context}
                        onChange={this.handleContextChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        style={{width: "100%"}}
                    />:<div></div>}

                    {this.state.showAddNew?<div style={{display:"flex", flexDirection: "row"}} > <TextField
                            label="Add New"
                            value={this.state.addNew}
                            onChange={this.handleAddNew}
                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            style={{width: "100%"}}/><Button onClick={this.handleAddNewClick}>Add</Button></div>:<div></div>}

                    {this.state.editContext && !this.state.showAddNew?  <div>
                    <TextField
                        label="Context"
                        value={this.state.context}
                        onChange={this.handleContextChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        disabled={true}
                        style={{width: "100%"}}
                    />
                    <TextField
                        label="Question"
                        value={this.state.question}
                        onChange={this.handleQuestionChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        style={{width: "100%"}}
                    />
                    <TextField
                        label="Validation"
                        value={this.state.validation}
                        onChange={this.handleValidationChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        style={{width: "100%"}}
                    />
                    <TextField
                        label="Validation Error Message"
                        value={this.state.validationError}
                        onChange={this.handleValidationErrorChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        style={{width: "100%"}}
                    />


                    <Button style={{ width: "100%"}} variant="contained"  color="primary"  onClick={this.updateContextDb}>Update Context Definition</Button>
                        </div>:<div></div>}
<br/>
                    {this.props.value && this.props.value.context? <Button  variant="contained"  style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} onClick={this.pass}>Update</Button>: <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"  color="primary"  onClick={this.pass}>Add to Workflow</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(AskForContext);
