import React, {Component} from "react"; 
import { getDomains } from "./reused/reusedfunctions.js";
import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import moment from "moment";
import Link from '@material-ui/core/Link';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    overrides: {
        MUIDataTableSelectCell: {
            checkboxRoot: {
                display: 'none'
            }
        }

    },
    TextField:{

        minWidth: "350px"
    }


});


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class Links extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Intent hits',
            keyValue: [],
            confidence: ' ',
            intentData: [],
            limit: "100",
            value: 0,
            links: [],
            confData: [],
            date: new Date('2019-01-17'),
            toDate: new Date(),
            model: "",
            pipeline: "",
            workflowLinks: [],
            failures: [],
            valueError: 0,
            selectedWorkflow: {Id: ""},
            commonWorkflows: []

        }

        this.getKey = this.getKey.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitConf = this.handleSubmitConf.bind(this);
        this.createChartData = this.createChartData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeConfidence = this.onChangeConfidence.bind(this);
        this.cellClickCreateIntent = this.cellClickCreateIntent.bind(this);
        this.setModel = this.setModel.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.getMuiTheme = this.getMuiTheme.bind(this);
        this.cellClick = this.cellClick.bind(this);
        this.selectedRowClick = this.selectedRowClick.bind(this);
        this.saveUpdatedLink = this.saveUpdatedLink.bind(this);
        this.getWorkflow = this.getWorkflow.bind(this);
        this.getLinksFromIntent = this.getLinksFromIntent.bind(this);
        this.getLinksFromOptionsIntent = this.getLinksFromOptionsIntent.bind(this);
        this.getLinksFromOptionsWorkflows = this.getLinksFromOptionsWorkflows.bind(this);
        this.getLinksFromWorkflows = this.getLinksFromWorkflows.bind(this);
        this.updateWorkflowValue = this.updateWorkflowValue.bind(this);
        this.updateURL = this.updateURL.bind(this);
        this.updateResponse = this.updateResponse.bind(this);
        this.updateWorkflow = this.updateWorkflow.bind(this);
        this.getAllLinks = this.getAllLinks.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTabChangeErrors = this.handleTabChangeErrors.bind(this);
        this.getWorkflowsFromOptionsIntentLinked = this.getWorkflowsFromOptionsIntentLinked.bind(this);
        this.getWorkflowsFromOptionsWorkflow = this.getWorkflowsFromOptionsWorkflow.bind(this);
        this.getWorkflowsFromIntents = this.getWorkflowsFromIntents.bind(this);
        this.getWorkflowsFromWorkflow = this.getWorkflowsFromWorkflow.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.handleWorkflowChange = this.handleWorkflowChange.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.getAllFailures = this.getAllFailures.bind(this);
        this.testAllLinks = this.testAllLinks.bind(this);
        this.formatLinkStatus = this.formatLinkStatus.bind(this);
        this.deleteFromErrorLog = this.deleteFromErrorLog.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.editRow = this.editRow.bind(this);
        this.getDomain = this.getDomain.bind(this);
        this.postToKv = this.postToKv.bind(this);
        this.getR4Token = this.getR4Token.bind(this);
        this.getConf = this.getConf.bind(this);
    }

    async getConf(){

        const domains = await getDomains();
        this.setState({domains: domains?domains: null});
    }
    async getR4Token() {
        let url = '/api/getR4Token';

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.access_token) {

            this.setState({token: data.access_token});

        }
    }
    async getDomain() {

        let url = "/api/getRestDomain";
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'

            }

        });

        const data = await response.json();
        if (data) {

            this.setState({restDomain: data.restDomain});
            if(data.restDomain.indexOf("r4") > -1){

                this.setState({r4:true});
            }
        }

    }
    editRow = (event, value, tableMeta) => {

        let editMode = this.state.editMode;

        if (editMode[tableMeta.rowIndex]) {
            editMode[tableMeta.rowIndex] = false;

            this.saveUpdatedLink(tableMeta, tableMeta.rowData[2])

        } else {
            editMode[tableMeta.rowIndex] = true;

        }

        this.setState({editMode: editMode})

    }

    onDeleteClick(rowsDeleted) {

       this.deleteFromErrorLog(this.state.name, this.state.currentAction, this.state.type, this.state.conversationId);

    }

    selectedRowClick = (currentRowsSelected, allRowsSelected, rowsSelected) => {

        if (this.state.failures[currentRowsSelected[0].dataIndex][1] === "Workflow error") {

            const flowId = this.state.failures[currentRowsSelected[0].dataIndex][0];
            const currentAction = this.state.failures[currentRowsSelected[0].dataIndex][2];
            this.setState({name: flowId, currentAction: currentAction, type: "WorkflowError"})
            this.setState({conversationId: this.state.failures[currentRowsSelected[0].dataIndex][3]})

            if (!currentAction) {
                this.setState({currentAction: 1})

            }


        } else if (this.state.failures[currentRowsSelected[0].dataIndex][1] === "No Intent Response") {

            const intentName = this.state.failures[currentRowsSelected[0].dataIndex][0];
            this.setState({name: intentName, currentAction: 1, type: "NoIntentResponse"})

        }

    };

    async deleteFromErrorLog(name, currentAction, type, conversationId) {

        if(!name){

            name = "";
        }

        const response = await fetch('/api/deleteFromErrorLog?name=' + name + "&currentAction=" + encodeURIComponent(currentAction) + "&type=" + type +"&conversationid="+conversationId);

        const data = await response.json();

        if (data.length === 0) {

            this.getAllFailures();
        }


    }

    handleTabChangeErrors = (event, value) => {

        if (value === 1) {

        }
        this.setState({valueError: value});

    };

    formatLinkStatus() {

        if (this.state.linkStatus) {
            let rows = [];
            this.state.linkStatus.map((stat, i) => {

                if (stat.code !== 200) {
                    let row = [stat.name, stat.type, stat.link, stat.code];
                    rows.push(row);
                }
            });
            this.setState({linkStatusFormatted: rows});

        }

    }


    async testAllLinks(e) {

        let linksFormatted = [];
        this.state.links.map((link) => {

            linksFormatted.push({"name": link[0], "link": link[2], "type": link[1]});

        });

        let postLinks = {"links": linksFormatted};

        let url = 'api/testUrls';
        let options = {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postLinks)
        };

        const response = await fetch(url, options);
        const data = await response.json();

        if (data && data.statusCodes && data.statusCodes.length > 0) {
            this.setState({linkStatus: data.statusCodes}, () => {
                this.formatLinkStatus()
            });
        }

    }

    changeModel(event, value) {

        if (value && value.Model) {
            localStorage.setItem("model", value.Model);
            this.setState({modelValue: value});
            this.setState({model: value.Model});
        }
    }

    async getSavedModels() {


        let url = '/api/getSavedModels';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            const model = localStorage.getItem("model");
            data.map((d, i) => {


                if(model && d.Model === model) {

                    first = d;

                }else if (i === 0) {

                    first = d;
                }


            })

            this.setState({savedModels: data, modelValue: first, model: first.Model});

        }
    }

    handleWorkflowChange(event, value) {


        if (value && value.Id) {
            this.setState({workflowLinks: []});
            this.getWorkflowsFromWorkflow(value.Id)

            this.setState({selectedWorkflow: value});
        }
    }

    async getWorkflowsFromWorkflow(workflowName) {

        let url = '/api/getWorkflowsFromWorkflow?model=' + this.state.model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        this.setState({getWorkflowsFromWorkflow: data});
        if (data && data.length > 0 && data[0].Id) {
            console.log(data)
            let rows = [];
            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id} = row


                rows.push([idSplit[idSplit.length - 1], "Link in Workflow"])
            }
            this.setState({workflowLinks: rows}, () => {

                    this.getWorkflowsFromIntents(workflowName, this.state.modelValue);

            });


        } else {
            
                this.getWorkflowsFromIntents(workflowName, this.state.modelValue)
            
        }

    }

    async getWorkflowsFromIntents(workflowName, model) {

        let url = '/api/getWorkflowsFromIntents?model=' + model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        //console.log("getWorkflowsFromIntents");


        if (data && data.length > 0 && data[0].Id) {

            let rows = this.state.workflowLinks ? this.state.workflowLinks : [];

            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id} = row


                rows.push([idSplit[idSplit.length - 1], "Link in Intent Response", model])

            }

            this.setState({workflowLinks: rows}, () => {
                this.getWorkflowsFromOptionsWorkflow(workflowName)
            });


        } else {

            this.getWorkflowsFromOptionsWorkflow(workflowName)
        }


        this.setState({workflowsFromIntent: data});

        if (data && data.length === 0) {

        }

    }

    async getWorkflowsFromOptionsWorkflow(workflowName) {

        let url = '/api/getWorkflowsFromOptionsWorkflow?model=' + this.state.model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

         
        this.setState({workflowsFromOptionsIntent: data});

        if (data && data.length > 0 && data[0].Id) {
            let rows = this.state.workflowLinks ? this.state.workflowLinks : [];
            
            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id} = row


                rows.push([idSplit[idSplit.length - 1], "Option in a Workflow"])
            }
            this.setState({workflowLinks: rows}, () => {
                
                this.getWorkflowsFromOptionsIntentLinked(workflowName)
            });

        } else {

            this.getWorkflowsFromOptionsIntentLinked(workflowName);
        }


    }


    async getWorkflowsFromOptionsIntentLinked(workflowName) {
        let url = '/api/getWorkflowsFromOptionsIntent?model=' + this.state.model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        //console.log("getWorkflowsFromOptionsIntent");

        this.setState({workflowsFromOptionsIntent: data});

        if (data && data.length > 0 && data[0].Id) {

            let rows = this.state.workflowLinks ? this.state.workflowLinks : [];
            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id} = row


                rows.push([idSplit[idSplit.length - 1], "Option in an Intent response"])
            }
            this.setState({workflowLinks: rows});

        } else {

            if (!this.state.workflowLinks) {

                this.setState({workflowLinks: []});

            }

        }


    }


    handleTabChange = (event, value) => {
        this.setState({value});

    };


    async saveUpdatedLink(rowInfo, updatedValue) {


        this.setState({type: rowInfo.rowData[3]});
        let workflow, id;
        if (rowInfo.rowData[1] === "Intent Response") {

            id = this.props.account + "_" + this.state.model + "_" + rowInfo.rowData[0];

        } else {

            id = this.props.account + "_common_" + rowInfo.rowData[0];

        }

        this.setState({updatedId: id, updatedUrl: updatedValue}, () => {
            this.updateURL()
        });

    }

    updateWorkflow(workflow) {

        let type = this.state.type;
        workflow.map((action) => {

            if (action.type === type) {

                if (type === "website.options") {
                    if (action.options) {
                        action.options.map((option) => {
                            if (option.onMatch.type === "website.navigate") {
                                option.onMatch.url = this.state.updatedUrl;
                            }

                        });
                    }
                } else if (type === "website.navigate" || type === "website.presentUrl") {

                    action.url = this.state.updatedUrl;
                }
            }

        });


        if (!this.state.intentResp) {

            this.updateWorkflowValue(JSON.stringify(workflow), this.state.updatedId);

        } else if (this.state.updatedId) {

            let intentResponse = this.state.intentResp;

            intentResponse.metaData.workflow = JSON.stringify(workflow);

            this.updateResponse(JSON.stringify(intentResponse), this.state.updatedId)

        }


    }

    async updateURL() {
        let workflow = await this.getWorkflow(this.state.updatedId);

        this.updateWorkflow(workflow, this.state.updatedId);


    }

    async updateResponse(newValue, workflowId) {


        let url = '/api/updateResponse?intent=' + workflowId;

        //Save to new database also.
        this.postToKv(newValue, this.props.account+"_"+this.state.model+"_"+workflowId);

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: newValue
        });

        const data = await response.json();

        this.getAllLinks();
        this.setState({updatedId: null, updatedResponse: null});

    }

    async updateWorkflowValue(newValue, workflowId) {


        let url = '/api/updateWorkflowValue?workflowId=' + workflowId;


        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': ' application/json'},
            body: newValue
        });
        this.postToKv(newValue, this.props.account+"_common_"+workflowId)
        const data = await response.json();
        this.getAllLinks();

    }

    async postToKv(toPost, name) {

        let url = "https://"+this.state.domains.restDomain.default+"/kv/" + name;
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.state.token);
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: toPost,
            redirect: 'follow'
        };

        const response = await fetch(url, requestOptions);
        const data = await response.text();

    }


   //Get workflow from database directly not through restservice.
    async getWorkflow(id) {

        let idSplit = id.split("_");
        let url = "https://"+this.state.restDomain+"/kv/"+idSplit[0]+"_"+idSplit[1]+"_"+idSplit[2];
        let headerObject = { 'Authorization': 'Bearer ' + this.state.token, 'Content-Type': 'application/json' };


        let options = {
            headers: headerObject
        };

        const response = await fetch(url, options);

        const data = await response.json();
        if (data) {

            if (data.metaData && data.metaData.workflow) {
                let workflow = JSON.parse(data.metaData.workflow);
                this.setState({intentResp: data});

                return workflow;


            } else {
                this.setState({intentResp: false});
                return data;

            }

        }
    }

    cellClick(cellData, cellMetaData, tableMeta) {
        //console.log(cellData, cellMetaData, tableMeta)

        if(cellMetaData.colIndex === 3){

            this.props.openInLog(cellData);
        }else{

        let type = this.state.links;
        let linkType = "Workflow";


        if (tableMeta && tableMeta === "WorkflowLinks") {
            type = this.state.workflowLinks;

        } else if (tableMeta && tableMeta === "ErrorLinks") {
            type = this.state.linkStatusFormatted
            //cellMetaData.dataIndex

        } else if (tableMeta && tableMeta === "Workflow Errors") {

            type = this.state.failures;
        } else {
            type = this.state.links;

        }

        if (cellMetaData.colIndex === 0) {

            type = type[cellMetaData.dataIndex][1];


            if (type.indexOf("Option in a Workflow") > -1 || type.indexOf("Link in Workflow") > -1 || type === "Workflow" || type === "Workflow error") {
                linkType = "Workflow";
                this.props.passData(cellData, linkType);
                this.props.history.push('/responseworkflow');
            } else if (type.indexOf("Link in Intent Response") > -1 || type.indexOf("Option in an Intent response") > -1 || type === "Intent Response" || type === "No Intent Response") {
                linkType = "Intent";
                this.props.passData(cellData, linkType, this.state.model);

                this.props.history.push('/trainingdata');
            }

        }
        }

    }

    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    /*backgroundColor: "#FFF",
                    borderRightColor: "#CCC",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid"*/

                }
            },
            MUIDataTable: {
                responsiveScroll: {
                    maxHeight: "calc(100vh - 420px)",
                    minHeight: "calc(100vh - 420px)",
                }
            }

        }
    })

    async getSettings() {


        let url = '/api/getSettings';

        const response = await fetch(url);

        const data = await response.json();
        if (data.length > 0) {

            const sett = data[0].Value;

            const settings = JSON.parse(sett);

            this.setState({
                confidenceThreshold: settings.ConfidenceThreshold,
                confidence: settings.ConfidenceThreshold * 100
            });

            this.setState({mappedCaseFlows: settings.CaseTypeMapping});
            this.setState({mappedFallbackCategoryFlows: settings.StrictCategoryMapping});
            this.setState({mappedCategoryFlows: settings.CategoryMapping});
            this.setState({fallbackFlow: {"Id": settings.FallbackFlow}});
            this.setState({startFlow: {"Id": settings.StartFlow}});
            this.setState({inactiveFlow: {"Id": settings.InactiveFlow}});


        }
    }


    setModel = (model) => {

        this.setState({model: model});
        this.queryIntent();
    }


    async getAllFailures() {

        let url = '/api/getAllFailures?account=' + this.props.account;


        const response = await fetch(url);

        const data = await response.json();
        let rows = [];


        if (data && data.length > 0 && data.name !== 'error') {

            for (let row of data) {


                let name, failureType;
                const {failureWorkflow, currentAction, type, intent, conversationId, time} = row;
                if (type === "No Intent Response") {
                    name = intent;
                    failureType = type;
                } else {
                    name = failureWorkflow;
                    failureType = "Workflow error";
                }


                rows.push([name, failureType, currentAction, conversationId.toUpperCase(),time])
            }

            this.setState({failures: rows});
        } else {
            this.setState({failures: []});
        }

    }

    async getLinksFromWorkflows() {

        let url = '/api/getLinksFromWorkflows?account=' + this.props.account;
        const response = await fetch(url);

        if (response.redirected) {
            window.location.href = response.url;
        }

        const data = await response.json();


        let rows = this.state.links.length > 0 ? this.state.links : [];


        if (data && data.length > 0 && data.name !== 'error') {

            for (let row of data) {
                let idSplit = row.Id.split("_");

                const {Id, url, type} = row

                rows.push([idSplit[idSplit.length - 1], "Workflow", url, type, Id])
            }
            this.setState({links: rows});
        }
    }

    async getLinksFromOptionsWorkflows() {

        let url = '/api/getLinksFromOptionsWorkflows?account=' + this.props.account;
        const response = await fetch(url);

        if (response.redirected) {
            window.location.href = response.url;
        }

        const data = await response.json();


        let rows = this.state.links.length > 0 ? this.state.links : [];


        if (data && data.length > 0 && data.name !== 'error') {

            for (let row of data) {
                let idSplit = row.Id.split("_");

                const {Id, url, type} = row

                rows.push([idSplit[idSplit.length - 1], "Workflow", url, type, Id])
            }
            this.setState({links: rows});
        }
    }


    async getLinksFromOptionsIntent() {

        let url = '/api/getLinksFromOptionsIntent?account=' + this.props.account;
        const response = await fetch(url);

        if (response.redirected) {
            window.location.href = response.url;
        }

        const data = await response.json();

        let rows = this.state.links.length > 0 ? this.state.links : [];
        if (data && data.length > 0 && data.name !== 'error') {


            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id, url, type} = row

                rows.push([idSplit[idSplit.length - 1], "Intent Response", url, type, Id])

            }
            this.setState({links: rows});
        }
    }

    async getLinksFromIntent() {

        let url = '/api/getLinksFromIntent?account=' + this.props.account;
        const response = await fetch(url);

        if (response.redirected) {
            window.location.href = response.url;
        }

        const data = await response.json();

        let rows = this.state.links.length > 0 ? this.state.links : [];
        if (data && data.length > 0 && data.name !== 'error') {


            for (let row of data) {

                let idSplit = row.Id.split("_");

                const {Id, url, type} = row

                rows.push([idSplit[idSplit.length - 1], "Intent Response", url, type, Id])

            }
            this.setState({links: rows});
        }
    }

    cellClickCreateIntent(cellData, cellMetaData) {
        if (cellMetaData.colIndex === 1) {
            this.props.passData(this.state.chartData[cellMetaData.rowIndex][2], cellData);
            //var href = this.props.history.createHref('myRoute', '/trainingdata');

//            const url = '';
            //setTimeout(window.open(url), 500);

            this.props.history.push('/trainingdata');
        }

    }


    getKey() {
        return this.keyCount++;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.queryIntent();
    }

    handleSubmitConf(e) {
        e.preventDefault();
        this.queryConfidence();
    }

    getAllLinks() {
        this.setState({links: []}, async () => {
            const var1 = await this.getLinksFromWorkflows();
            const var2 = await this.getLinksFromIntent();
            const var3 = await this.getLinksFromOptionsWorkflows();
            const var4 = await this.getLinksFromOptionsIntent();
            let editMode = [];
            this.state.links.map((link, i) => {
                editMode[i] = false;
            })
            this.setState({startLinkReport: true, editMode: editMode});
        });

    }

    componentDidMount() {
        this.getR4Token();
        this.getConf();
        this.getDomain();
        this.getSavedModels();
        this.getAllCommonWorkflows();
        this.getAllLinks();
        this.getAllFailures();


        if (this.state.model) {

            this.setState({model: this.state.model});

        }

        if (this.props.pipeline) {

            this.setState({pipeline: this.props.pipeline});

        }


    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        /* if (prevProps.model !== this.props.model) {
             this.setState({model: this.props.model});
         }*/

        if (prevProps.pipeline !== this.props.pipeline) {
            this.setState({pipeline: this.props.pipeline});
        }

        if (prevState.startLinkReport !== this.state.startLinkReport) {

            if (this.state.startLinkReport) {

                this.testAllLinks();
            }
        }
    }


    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            data.map((object) => {
                let arraySplit = object.Id.split("_");

                if (arraySplit.length > 3) {

                    workflowArray.push({
                        "Id": arraySplit[arraySplit.length - 1] + ": " + arraySplit[arraySplit.length - 2],
                        "Category": arraySplit[arraySplit.length - 2]
                    });


                } else {
                    let found = false;
                    workflowArray.map((o) => {

                        if (o.Id === arraySplit[arraySplit.length - 1]) {

                            found = true;
                        }

                    });
                    if (!found) {
                        workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                    }

                }


            });
            //   console.log(this.props.workflowCreate);

            if (this.props.workflowCreate) {

                workflowArray.push({"Id": "[New Workflow]"});
            }
            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })
            this.setState({commonWorkflows: workflowArray});


        }


    }


    createChartData() {


        let rows = [];
        let intentRow = [];
        if (this.state.keyValue.length > 0) {

            for (let row of this.state.keyValue) {
                const {CreatedAt, Query, Name, Confidence, IntentRanking} = row
                intentRow.push(IntentRanking);
                rows.push([moment(CreatedAt).format("YYYY-MM-DD HH:mm"), Query, Name, Math.round(Confidence)])

            }
            this.setState({intentRow: intentRow});
            this.setState({chartData: rows});

        }

    }

    onChangeConfidence(e) {

        this.setState({confidence: e.target.value});

    }


    handleChange(e) {

        this.setState({limit: e.target.value});
    }

    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        return (

            <div style={{}}>
                <Paper style={{height: "100%", minHeight: "calc(100vh - 20px)"}}>
                    <Box style={{
                        borderBottom: 1,
                        borderColor: 'lightgrey',
                        borderWidth: '1px',
                        borderBottomStyle: "solid"
                    }}>
                        < Tabs value={this.state.value} style={{marginTop: "10px", marginLeft: "10px"}}
                               onChange={this.handleTabChange}>

                            <Tab label="Workflow"/>
                            <Tab label="Navigation"/>
                            <Tab label="Errors"/>
                        </Tabs>
                    </Box>
                    <div style={{marginTop: "20px"}}>
                        {this.state.value === 1 &&
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <Button style={{marginLeft: "10px", height: "40px", marginTop: "26px"}} color="primary"
                                variant="contained" type="submit">Get All Links</Button>
                    </Paper>

                </Grid>*/}
                                <Grid item xs={12} style={{marginTop: "20px", padding: "20px"}}>


                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable
                                            title={"All Links"}
                                            data={this.state.links}
                                            columns={[
                                                {
                                                    name: "Name",
                                                    options: {
                                                        filter: true,
                                                        setCellProps: (value) => {
                                                            return {
                                                                className: classnames(
                                                                    {
                                                                        [classes.NameCell]: value
                                                                    })
                                                            };
                                                        }
                                                    }
                                                }
                                                ,
                                                "Type",
                                                {
                                                    name: "Url",
                                                    options: {
                                                        responsive: "vertical",
                                                        tableBodyHeight: "600px",
                                                        filter: true,
                                                        customBodyRender: (value, tableMeta, updateValue) => (
                                                            <FormControlLabel
                                                                value={value}
                                                                style={{width: "100%", minWidth: "350px"}}
                                                                control={<div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    minWidth: "350px"
                                                                }}>

                                                                    <TextField variant={"outlined"}
                                                                               className={classes.textField}
                                                                               inputProps={{style: {minWidth: "500px"
                                                                               }}}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   display: this.state.editMode && this.state.editMode[tableMeta.rowIndex] ? "block" : "none"
                                                                               }}
                                                                               value={value}/><Link underline="hover"
                                                                                                    target="_blank"
                                                                                                    style={{display: this.state.editMode && this.state.editMode[tableMeta.rowIndex] ? "none" : "block"}}
                                                                                                    rel="noreferrer"
                                                                                                    variant="button"
                                                                                                    href={value}>{value}
                                                                </Link> <IconButton
                                                                    onClick={(event) => this.editRow(event, value, tableMeta)}>
                                                                    <EditIcon fontSize={"small"}/>
                                                                </IconButton></div>}
                                                                onChange={(event) => {
                                                                    updateValue(event.target.value);

                                                                }}
                                                                onBlur={
                                                                    event => {
                                                                        //this.saveUpdatedLink(tableMeta, event.target.value)
                                                                    }
                                                                }
                                                            />
                                                        )
                                                    }
                                                }, "Action"]}
                                            options={{
                                                filter: false,
                                                filterType: "checkbox",
                                                print: false,
                                                viewColumns: false,
                                                responsive: "standard",
                                                selectableRows: "none",
                                                rowsPerPage: 150,
                                                onCellClick: (colData, cellMeta, tableMeta) => {
                                                    this.cellClick(colData, cellMeta);
                                                }
                                            }}
                                        />
                                    </MuiThemeProvider>

                                </Grid>

                            </Grid>
                        }

                        {this.state.value === 0 && <div>
                            <div style={{display: "flex", flexDirection: "row", padding: "20px"}}>
                                {this.state.savedModels && this.state.savedModels.length > 1 &&


                                    <Autocomplete
                                        id="modelBox"
                                        options={this.state.savedModels}
                                        onChange={this.changeModel}
                                        value={this.state.modelValue}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        getOptionLabel={option => option.Model}
                                        style={{maxWidth: "300px", width: "100%", minWidth: "150px"}}
                                        renderInput={params => <TextField {...params}
                                                                          variant="outlined"
                                                                          label="Model"
                                                                          style={{}}/>}/>
                                }


                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.commonWorkflows}
                                    onChange={this.handleWorkflowChange}
                                    value={this.state.selectedWorkflow}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    getOptionLabel={option => option.Id}
                                    style={{
                                        width: "300px",
                                        paddingBottom: "20px",
                                        marginLeft: this.state.savedModels && this.state.savedModels.length > 1 ? "20px" : "0px"
                                    }}
                                    renderInput={params => <TextField {...params} label="Choose Workflow"
                                                                      variant="outlined"/>}
                                />
                            </div>
                            {this.state.workflowLinks &&
                                <MuiThemeProvider theme={this.getMuiTheme()}>
                                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                                        <MUIDataTable
                                            title={"Workflow Links"}
                                            data={this.state.workflowLinks}
                                            columns={[
                                                {
                                                    name: "Name",
                                                    options: {
                                                        filter: true,
                                                        setCellProps: (value) => {
                                                            return {
                                                                className: classnames(
                                                                    {
                                                                        [classes.NameCell]: value
                                                                    })
                                                            };
                                                        }
                                                    }
                                                }

                                                , "Type", "Model"]}
                                            options={{
                                                filter: false,
                                                filterType: "checkbox",
                                                print: false,
                                                viewColumns: false,
                                                responsive: "standard",
                                                selectableRows: "none",
                                                rowsPerPage: 15,
                                                onCellClick: (colData, cellMeta, tableMeta) => {
                                                    this.cellClick(colData, cellMeta, "WorkflowLinks");
                                                }
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>}
                        </div>}


                        {this.state.value === 2 &&
                            <Grid container spacing={2}>

                                <Grid item xs={12} style={{marginTop: "20px", padding: "20px"}}>
                                    < Tabs value={this.state.valueError} style={{marginTop: "10px", marginLeft: "10px"}}
                                           onChange={this.handleTabChangeErrors}>

                                        <Tab label="Workflow Errors"/>
                                        <Tab label="Url Errors"/>
                                    </Tabs>

                                    {this.state.valueError === 1 && !this.state.linkStatusFormatted &&
                                        <div style={{marginLeft: "50px", marginTop: "50px"}}>
                                            <CircularProgress
                                                className={classes.progress}/></div>
                                    }
                                    {this.state.linkStatusFormatted && this.state.valueError === 1 &&
                                        <MuiThemeProvider theme={this.getMuiTheme()}>
                                            <MUIDataTable
                                                title={"Test Result Urls"}
                                                data={this.state.linkStatusFormatted}
                                                columns={[
                                                    {
                                                        name: "Name",
                                                        options: {
                                                            filter: true,
                                                            setCellProps: (value) => {
                                                                return {
                                                                    className: classnames(
                                                                        {
                                                                            [classes.NameCell]: value
                                                                        })
                                                                };
                                                            }
                                                        }
                                                    },
                                                    "Type",
                                                    {
                                                        name: "Url",
                                                        options: {
                                                            responsive: "vertical",
                                                            tableBodyHeight: "600px",
                                                            filter: true,
                                                            customBodyRender: (value, tableMeta, updateValue) => (
                                                                <FormControlLabel
                                                                    value={value}
                                                                    style={{width: "100%", minWidth: "350px"}}
                                                                    control={<Link underline="hover" target="_blank"
                                                                                   rel="noreferrer" variant="button"
                                                                                   href={value}>{value}
                                                                    </Link>}
                                                                    onChange={(event) => {
                                                                        updateValue(event.target.value);

                                                                    }}
                                                                    onBlur={
                                                                        event => {
                                                                            this.saveUpdatedLink(tableMeta, event.target.value)
                                                                        }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    },
                                                    "Status Code"]}
                                                options={{
                                                    filter: false,
                                                    filterType: "checkbox",
                                                    print: false,
                                                    viewColumns: false,
                                                    responsive: "standard",
                                                    selectableRows: "none",
                                                    rowsPerPage: 150,
                                                    onCellClick: (colData, cellMeta) => {
                                                        this.cellClick(colData, cellMeta, "ErrorLinks");
                                                    }
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    }

                                    {this.state.valueError === 0 &&
                                        <MuiThemeProvider theme={this.getMuiTheme()}>
                                            <MUIDataTable
                                                title={"Errors"}
                                                data={this.state.failures}
                                                columns={[
                                                    {
                                                        name: "Name",
                                                        options: {
                                                            filter: true,
                                                            setCellProps: (value) => {
                                                                return {
                                                                    className: classnames(
                                                                        {
                                                                            [classes.NameCell]: value
                                                                        })
                                                                };
                                                            }
                                                        }
                                                    }, "Type",
                                                    "Current Action",
                                                    {
                                                        name: "ConversationId",
                                                        options: {
                                                            filter: true,
                                                            setCellProps: (value) => {
                                                                return {
                                                                    className: classnames(
                                                                        {
                                                                            [classes.NameCell]: value
                                                                        })
                                                                };
                                                            }
                                                        }
                                                    }, "Time"
                                                ]}
                                                options={{
                                                    filter: false,
                                                    filterType: "checkbox",
                                                    print: false,
                                                    sort: true,
                                                    viewColumns: false,
                                                    responsive: "standard",
                                                    selectableRows: "single",
                                                    rowsPerPage: 100,
                                                    rowHover: true,
                                                    onCellClick: (colData, cellMeta, tableMeta) => {
                                                        this.cellClick(colData, cellMeta, "Workflow Errors");
                                                    },
                                                    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {

                                                        this.selectedRowClick(currentRowsSelected, allRowsSelected, rowsSelected);

                                                    },
                                                    onRowsDelete: ((rowsDeleted) => {
                                                        this.onDeleteClick(rowsDeleted);
                                                    })
                                                }}
                                            />
                                        </MuiThemeProvider>}

                                </Grid>

                            </Grid>
                        }
                    </div>
                </Paper>
            </div>


        );
    }
}

export default withStyles(styles)(Links);
