import React, {Component} from "react";
import {ListItem, ListItemText, Select, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import Button from "@material-ui/core/Button/Button";
import ConversationMessage from "./ConversationMessage";
import Divider from '@material-ui/core/Divider';
import ResponseOnIntent from "./ResponseOnIntent";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import arrayMove from 'array-move';
import {compose} from "redux";
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckGroupStatusAndTransfer from "./CheckGroupStatusAndTransfer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import ChipInput from "material-ui-chip-input";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const style = {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "20px"
}

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {
        fontSize: '15px',
        paddingLeft: '10px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    chipRoot: {margin: theme.spacing(1), height: '100%', display: 'flex', flexDirection: 'column'},
    chipLabel: {overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip'}

});


class ContextSwitch extends Component {


    constructor(props) {

        super(props);
        this.listRow = this.listRow.bind(this);
        this.addOption = this.addOption.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveWorkflow = this.saveWorkflow.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.removeOption = this.removeOption.bind(this);
        this.handleResponseChange = this.handleResponseChange.bind(this);
        this.handleRemoveOption = this.handleRemoveOption.bind(this);
        this.handleRenderPreview = this.handleRenderPreview.bind(this);
        this.scrollOption = this.scrollOption.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.handleDisbleInputCheckbox = this.handleDisbleInputCheckbox.bind(this);
        this.settingsChange = this.settingsChange.bind(this);
        this.handlePredefinedChange = this.handlePredefinedChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.renderEntityMenuItems = this.renderEntityMenuItems.bind(this);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.handleExpandChange = this.handleExpandChange.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.handleDefaultResponseChange = this.handleDefaultResponseChange.bind(this);
        this.getContext = this.getContext.bind(this);
        this.state = {
            filter: '',
            expanded: "panel1",
            workFlowRender: [],
            data: [{"accepts": [], "onMatch": []}],
            count: 0,
            website: "",
            model: "",
            default: "",
            pipeline: "",
            selected: [],
            newOption: false,
            renderPreview: true,
            closureMessage: "",
            items: [],
            showSort: false,
            entityContext: '',
            predefinedContext: "",
            textFieldValue: "",
            typeSelect: "options",
            showType: false,
            showIcon: false,
            groups: [],
            group: {id: "", name: ""},
            checked: false,
            entities: [],

        }

        this.listRef = React.createRef();

    }

    handlePredefinedChange(e) {

        this.setState({predefinedContext: e.target.value});

    }
    async getContext() {
        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {

                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (this.props.value && this.props.value.key && object === this.props.value.key) {

                    this.setState({expanded: 'panel1'}, () => {
                        this.setState({predefinedContext: object});
                    });

                }

            })
        }


    }


    handleDefaultResponseChange(event) {

        this.setState({default: event, error: false});

        if (event.initiateAiWithResponse === "New Workflow") {
            this.props.newWorkflowContextSwitch("default");
        }


    }

    handleDeleteChip = (chip, index, i) => {

        let chips = this.state.data;

        chips[i].accepts.splice(index, 1);

        this.setState({data: chips});

    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            });

        }

    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;

        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );

        let data = await response.json();
        let entities;
        if (i === 0) {
            entities = [];
        } else {
            entities = this.state.entities ? this.state.entities : [];
        }

        if (data.rasa_nlu_data.entity_synonyms && data.rasa_nlu_data.entity_synonyms.length > 0 && typeof data.rasa_nlu_data.entity_synonyms[0] === "string") {

            let entities = [...new Set(entities)];
            this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]});
        }


    }

    handleExpandChange(panelId) {
        this.setState({expanded: panelId});
    }


    renderContextMenuItems() {
        if (this.state.contextValues) {
            return this.state.contextValues.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }

    handleEntityChange(e) {

        this.setState({entityContext: e.target.value});
    }

    renderEntityMenuItems() {
        if (this.state.entities) {
            return this.state.entities.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }

    handleAddChip = (chip, i) => {

        let data = this.state.data;
        let chips = data[i].accepts;

        chips.push(chip);

        this.setState({data: data});

    }


    settingsChange = (key, flow) => {


        this.setState({flow: flow});
        let newData = this.state.data;

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            this.setState({data: newData}, this.setState({edit: true}));
            this.saveWorkflow();
        }


    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);

        if (response.status !== 500) {
            const data = await response.json();

            if (data && data.length > 0 && data.name !== 'error') {

                data.map((site) => {

                    this.getGroups(site);

                });

            }
        }
    }


    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            this.setState({groups: groups});

        }

    }

    openWorkflow = (value) => {

        if (this.props.workflowCreate) {

            this.props.passWorkflowToOpen(value);
        } else {
            this.props.openWorkflow(value);
        }


    }


    handleOpenWorkFlow = (value) => {

        this.props.passWorkflowToOpen(value);

    }

    handleRemoveOption = (event, i) => {

        let arr = this.state.data;
        let removed = arr.splice(i, 1);
        this.setState({data: arr}, this.saveWorkflow);

    }

    componentDidMount() {
        this.getContext();
        this.getSavedModels();
        if (this.props.data) {

            this.setState({edit: true});
            this.setState({items: this.props.data});

        }


        if (this.props.optionsType) {

            this.setState({typeSelect: this.props.optionsType});
        }

        if (this.props.disableInputField) {

            this.setState({checked: this.props.disableInputField})

        }

        if (this.props.model && this.props.pipeline) {

            this.setState({model: this.props.model});
            this.setState({pipeline: this.props.pipeline});

        }

        if (this.props.forwardParams) {

            this.setState({aiModel: this.props.forwardParams.model});
            this.setState({aiPipeline: this.props.forwardParams.pipeline});

        }


        if (this.props.value) {


            this.setState({data: this.props.value.switches});
            if (this.props.value.default) {

                this.setState({default: this.props.value.default, edit: true});
            }

            if (this.props.value.contextType === "predefinedContext") {

                this.setState({predefinedContext: this.props.value.contextKey});
                this.setState({contextType: "predefinedContext", expanded: "panel1"});

            } else if (this.props.value.contextType === "entityContext") {
                this.setState({entities: []});
                this.setState({entityContext: this.props.value.contextKey});
                this.setState({contextType: "entityContext", expanded: "panel3"});


            } else {
                this.setState({textFieldValue: this.props.value.contextKey});
                this.setState({contextType: "manual", expanded: "panel2"});
            }
        } else {
            this.setState({contextType: "manual", expanded: "panel2"});

        }

        this.getTokenUser();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (JSON.stringify(prevState.listOptions) !== JSON.stringify(this.state.listOptions)) {

        }

        if (prevProps.optionsType !== this.props.optionsType) {

            this.setState({typeSelect: this.props.optionsType});
        }

        if (prevProps.disableInputField !== this.props.disableInputField) {

            this.setState({checked: this.props.disableInputField});
        }

        if (JSON.stringify(prevState.items) !== JSON.stringify(this.state.items)) {

        }

        if ((prevProps && prevProps.newNameContextSwitch !== this.props.newNameContextSwitch)) {

            this.setState({selectedWorkflow: {"Id": this.props.newNameContextSwitch}});

            let workflow = {
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.props.newNameContextSwitch,
                "account": this.props.account,

            };

            if(this.props.default){

                this.handleDefaultResponseChange(workflow);

            }else{

                if(workflow.initiateAiWithResponse === "New Workflow"){

                    workflow.initiateAiWithResponse = " ";
                }
                    this.handleResponseChange(this.state.updatedPosition, workflow);

            }
            if(this.state.textFieldValue === ""){
                this.setState({textFieldValue: "default"}, ()=>{this.saveWorkflow();});
            }else{
                this.saveWorkflow();
            }


        }
    }


    handleChange(event) {


        this.setState({textFieldValue: event.target.value});

    }

    handleRenderPreview(e) {

        this.setState({renderPreview: true});

    }

    handleResponseChange = (key, flow) => {

        if (flow.initiateAiWithResponse === "New Workflow") {
            this.setState({updatedPosition: key});
            this.props.newWorkflowContextSwitch("switch");
        } //else {

        this.setState({flow: flow});
        let newData = this.state.data;

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            this.setState({data: newData, edit:true},()=>{ if (this.props.newNameContextSwitch) {

                this.saveWorkflow();

            }});

        }

    }


    listRow() {


        return this.state.data.map((object, i) => {

            return <div key={i} style={{display: "flex", flexDirection: "column", minWidth: "100%", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <div style={{width: "80%"}}><h3>Check: {i + 1}</h3></div>
                    <div style={{width: "20%"}}><InputAdornment onClick={event => this.handleRemoveOption(event, i)}
                                                                position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></div>
                </div>


                <ChipInput
                    value={object.accepts}
                    variant="outlined"
                    classes={{root: this.props.classes.chipRoot, label: this.props.classes.chipLabel,}}
                    label="Equal To"
                    style={{
                        width: "100%",
                        marginTop: "15px",
                        marginLeft: "0px",
                        marginBottom: "10px",
                        wordWrap: "wrap"
                    }}
                    onAdd={(chip) => this.handleAddChip(chip, i)}
                    onDelete={(chip, index) => this.handleDeleteChip(chip, index, i)}
                />
                {this.state.data && this.state.data[i] && this.state.data[i].onMatch &&
                (this.state.data[i].onMatch.initiateAiWithResponse ||
                    this.state.data[i].onMatch.initiateAiWithResponse === "")
                && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>


                        <ResponseOnIntent option={true}
                                          onChange={this.handleResponseChange.bind(this, i)}
                                          value={this.state.data[i].onMatch}
                                          settingsChange={this.settingsChange}
                                          workflowCreate={true}
                                          dontShowSettings={true}
                                          id={i.toString()}
                                          workflowCreate={true}
                                          model={this.props.model}
                                          pipeline={this.props.pipeline}
                                          openWorkflow={this.openWorkflow}
                                          handleOpenWorkFlow={this.handleOpenWorkFlow}
                                          replace={this.state.data[i].replace}
                                          account={this.props.account}/>
                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && !this.state.data[i].onMatch.initiateAiWithResponse &&

                    <div style={{
                        width: "100%",
                        wordBreak: "break-all",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px"
                    }}>
                        <p style={{fontSize: "16px", fontWeight: "bold"}}>On Match</p>

                        <div style={{overflow: "auto", marginTop: "-10px"}}>
                            <div>
                                <ResponseOnIntent option={true}
                                                  onChange={this.handleResponseChange.bind(this, i)}
                                                  model={this.props.model}
                                                  pipeline={this.props.pipeline}
                                                  account={this.props.account}
                                                  dontShowSettings={true}
                                                  contextSwitch={true}
                                                  workflowCreate={true}
                                                  openWorkflow={this.openWorkflow}
                                />
                            </div>
                        </div>
                    </div>
                }


            </div>
        });

    }

    addOption() {

        this.state.data.push({"accepts": [], "onMatch": []});
        this.setState({render: true});
        this.setState({showSort: false});
        //this.listRef.current.scrollIntoView({block: "end", inline: "nearest"});


        setTimeout(this.scrollOption, 500);

    }

    scrollOption() {
        let curr = this.listRef.current;

        if (curr) {
            curr.scrollTop = (curr.scrollHeight - (curr.clientHeight + 20));
        }

    }


    saveWorkflow() {

        let workflow = this.state.data;

        if (this.state.default === "") {

            this.setState({error: true, errorInfo: "Please choose a default workflow before adding this action"})


        } else {

            let action = {
                "type": "case.contextSwitch",
                "contextKey": "",
                "switches": workflow,
                "default": this.state.default
            };

            if (this.state.entityContext !== "") {
                action.contextKey = this.state.entityContext;

            }
            let textFieldValue = this.state.textFieldValue;
            let contextType = "manual";

            if (this.state.expanded === "panel1" && this.state.predefinedContext) {

                textFieldValue = this.state.predefinedContext;
                contextType = "predefinedContext";
            }


            if (this.state.expanded === "panel3" && this.state.entityContext) {

                textFieldValue = this.state.entityContext;
                contextType = "entityContext";
            }


            if (textFieldValue === "") {
                this.setState({error: true, errorInfo: "Please define a context variable before adding this action"});
            } else {
                this.setState({error: false});
                action.contextKey = textFieldValue;
                action.contextType = contextType;
                //workflow

                this.props.saveWorkflow("case.contextSwitch", action);
            }
        }

    }


    removeOption() {

        let arr = this.state.data.splice(this.state.data.length - 1, 1);
        this.setState({data: this.state.data});
    }


    handleTypeChange(e) {


        this.setState({typeSelect: e.target.value}, this.props.updateOptionsType(e.target.value));


    }

    handleDisbleInputCheckbox(e) {

        this.setState({checked: e.target.checked}, this.props.updateDisableInput(e.target.checked));

    }


    render() {
        const {classes} = this.props;
        return (
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>

                <Paper style={{
                    padding: "10px",
                    marginTop: "30px",
                    overflow: "auto"
                }}>
                    <div style={{
                        marginTop: '0px',
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                            this.handleExpandChange('panel1')
                        }} style={{marginTop: "5px", marginBottom: "0px"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>

                                    <div style={{marginLeft: "0px", fontWeight: "bold"}}>Predefined Context Variable
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                             className={classes.formControl}>
                                    <TextField
                                        variant="outlined"
                                        label="Predefined Context"
                                        select
                                        value={this.state.predefinedContext}
                                        classes={{root: classes.select}}
                                        onChange={this.handlePredefinedChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>


                                        {this.renderContextMenuItems()}

                                    </TextField>
                                </FormControl>

                            </AccordionDetails>
                        </Accordion>


                        <Accordion expanded={this.state.expanded === 'panel2'} onClick={event => {
                            this.handleExpandChange('panel2')
                        }} style={{marginTop: "5px"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}>

                                    <div style={{marginLeft: "0px", fontWeight: "bold"}}>Manual Input Context Variable
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>

                                <TextField
                                    value={this.state.textFieldValue}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    multiline={false}
                                    variant="outlined"
                                    label="Context Variable"
                                    style={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "keep-all",
                                        width: "100%",
                                        marginTop: "-10px"
                                    }}
                                />

                            </AccordionDetails>
                        </Accordion>


                        {this.state.entities && this.state.entities.length > 0 &&
                            <Accordion expanded={this.state.expanded === 'panel3'} onClick={event => {
                                this.handleExpandChange('panel3')
                            }} style={{marginTop: "5px"}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row"
                                    }}>

                                        <div style={{marginLeft: "0px", fontWeight: "bold"}}>Entity Context Variable
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                                 className={classes.formControl}>
                                        <TextField
                                            variant="outlined"
                                            label="Entity Context Variable"
                                            select
                                            value={this.state.entityContext}
                                            classes={{root: classes.select}}
                                            onChange={this.handleEntityChange}
                                        >
                                            {this.renderEntityMenuItems()}

                                        </TextField>
                                    </FormControl>

                                </AccordionDetails>
                            </Accordion>}


                    </div>

                    <div style={{marginBottom: "0px"}}>


                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Divider/>
                            <div style={{width: "80%"}}><h3>Default</h3></div>
                            <div style={{marginTop: "-15px"}}>
                                <ResponseOnIntent passData={this.makeWorkFlow} model={this.props.model}
                                                  option={true}
                                                  value={this.state.default}
                                                  onChange={this.handleDefaultResponseChange}
                                                  dontShowSettings={true}
                                                  pipeline={this.props.pipeline}
                                                  account={this.props.account}
                                                  workflowCreate={true}
                                                  openWorkflow={this.openWorkflow}/>
                            </div>

                            <div style={{marginTop: "-40px"}}>
                                <Tooltip title="Add Switch">
                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                onClick={this.newWorkflow} aria-label="Add Switch"
                                                onClick={this.addOption}
                                                component="span">
                                        <AddIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{
                            marginTop: '1   ' +
                                '0px',
                            width: "100%",
                            overflowY: "auto",
                            paddingBottom: "10px",
                            marginBottom: "0px",
                            maxHeight: "83%"
                        }} ref={this.listRef}>

                            <List
                                style={{minHeight: "180px", maxHeight: "310px", width: "100%"}}
                                component="nav"
                                className={classes.root}
                                id='thingList'

                            >
                                {this.listRow()}
                            </List>

                        </div>

                    </div>
                    <div style={{

                        marginTop: "-30px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"

                    }}>
                        {this.state.error &&
                            <p style={{marginTop: "-20px", width: "280px", color: "red", fontWeight: "bold"}}>
                                {this.state.errorInfo}
                            </p>}

                        {this.state.edit ? <Button variant="contained"
                                                   style={{
                                                       backgroundColor: "green",
                                                       color: "white",
                                                       fontWeight: "bold"
                                                   }}
                                                   onClick={this.saveWorkflow}>Update</Button> :
                            <Button variant="contained"
                                    style={{backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                    onClick={this.saveWorkflow}>Add</Button>}
                    </div>
                </Paper>
            </div>

        )
    }

}

export default compose(
    withStyles(styles)
)(ContextSwitch);
