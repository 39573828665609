import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import {Controlled as CodeMirror} from 'react-codemirror2';
import prettier from "prettier";
import * as parserBabel from "prettier/parser-babel.js";
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {Buffer} from 'buffer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import {ListItem, ListItemText} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from "@material-ui/core/List";
import Notifier from "./Notifier";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    modal: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {
        height: "80px",
        root: {
            height: "80px",
            input: {

                height: "10px"
            }
        },
        input: {

            height: "10px"
        }

    },
    input: {

        height: "45px"
    },
    label: {fontSize: "40px"},
    listItemTextSelected: {
        color: "#000000",
        fontSize: "12px",
        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "#FFFFFF"
        },


    },
});

function getModalStyle

() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

class CreateIntegration extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Create Integrations',
            addParamsToQuery: true,
            confirmOverwriteIntegration: false,
            notifierChangeOpen: false,
            actionUpdatedOpen: false,
            integrationUpdatedOpen: false,
            actions: [],
            paramTestValues: [],
            tabValue: 0,
            tabValueMode: 0,
            tabValueCode: 0,
            type: "GET",
            tokenResponsePath: "",
            restUrl: "",
            authorization: "No Auth",
            grantType: "Password Credentials",
            paramValue: "",
            params: [],
            authMethod: "GET",
            integrationName: "",
            contextFromResponseChecked: false,
            password: "",
            postType: "JSON",
            auth: false,
            username: "",
            clientID: "",
            cliendSecret: "",
            headerKey: "",
            headerValue: "",
            headerKeyAuth: "",
            headerValueAuth: "",
            contextResponse: [],
            headers: {Accept: '*/*', 'Content-Type': 'application/json'},
            headersAuth: {Accept: '*/*', 'Content-Type': 'application/json'},
            responseSearchPath: "",
            contextKey: "",
            contextSearchPath: "",
            contextSearchPaths: {},
            responseType: "Basic",
            confirmDeleteOpen: false,
            openAdd: false,
            addValue: ""

        }

        this.getAllActions = this.getAllActions.bind(this);

        this.getAllIntegrations = this.getAllIntegrations.bind(this);
        this.handleRestChange = this.handleRestChange.bind(this);
        this.handleInputParams = this.handleInputParams.bind(this);
        this.handleAddParameter = this.handleAddParameter.bind(this);
        this.handleIntegrationName = this.handleIntegrationName.bind(this);
        this.createAction = this.createAction.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCreateIntegration = this.handleCreateIntegration.bind(this);
        this.getModels = this.getModels.bind(this);
        this.createIntegration = this.createIntegration.bind(this);
        this.handleContextResponse = this.handleContextResponse.bind(this);
        this.createIntegrationInDb = this.createIntegrationInDb.bind(this);
        this.createActionInDb = this.createActionInDb.bind(this);
        this.getIntegrationLibrary = this.getIntegrationLibrary.bind(this);
        this.updateIntegrationLibraryInDb = this.updateIntegrationLibraryInDb.bind(this);
        this.handleAuthorization = this.handleAuthorization.bind(this);
        this.handleAccessTokenURL = this.handleAccessTokenURL.bind(this);
        this.handleClientIdChange = this.handleClientIdChange.bind(this);
        this.handleClientSecret = this.handleClientSecret.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleScope = this.handleScope.bind(this);
        this.handleGetToken = this.handleGetToken.bind(this);
        this.getToken = this.getToken.bind(this);
        this.handleTestIntegration = this.handleTestIntegration.bind(this);
        this.handleTestParamChange = this.handleTestParamChange.bind(this);
        this.currentIntegrationMethods = this.currentIntegrationMethods.bind(this);
        this.handleInputHeaderKey = this.handleInputHeaderKey.bind(this);
        this.handleInputHeaderKeyAuth = this.handleInputHeaderKeyAuth.bind(this);
        this.handleInputHeaderValue = this.handleInputHeaderValue.bind(this);
        this.handleInputHeaderValueAuth = this.handleInputHeaderValueAuth.bind(this);
        this.handleAddHeaders = this.handleAddHeaders.bind(this);
        this.handleAddHeadersAuth = this.handleAddHeadersAuth.bind(this);
        this.renderHeaders = this.renderHeaders.bind(this);
        this.renderHeadersAuth = this.renderHeadersAuth.bind(this);
        this.handleContextSearchPath = this.handleContextSearchPath.bind(this);
        this.handleContextKey = this.handleContextKey.bind(this);
        this.handleResponseSearchPath = this.handleResponseSearchPath.bind(this);
        this.renderContextSearchPaths = this.renderContextSearchPaths.bind(this);
        this.reactOnClick = this.reactOnClick.bind(this);
        this.getAccountIntegrationActions = this.getAccountIntegrationActions.bind(this);
        this.parseResponseFromAction = this.parseResponseFromAction.bind(this);
        this.changeState = this.changeState.bind(this);
        this.handleConfirmOverwriteIntegration = this.handleConfirmOverwriteIntegration.bind(this);
        this.deleteIntegrationAndActionFromDb = this.deleteIntegrationAndActionFromDb.bind(this);
        this.overWriteIntegration = this.overWriteIntegration.bind(this);
        this.createAuthenticationCode = this.createAuthenticationCode.bind(this);
        this.handlePostParam = this.handlePostParam.bind(this);
        this.handlePostParamAuth = this.handlePostParamAuth.bind(this);
        this.handlePostTypeChange = this.handlePostTypeChange.bind(this);
        this.handleResponseTypeChange = this.handleResponseTypeChange.bind(this);
        this.createNewIntegration = this.createNewIntegration.bind(this);
        this.handleTokenResponsePath = this.handleTokenResponsePath.bind(this);
        this.handleDeleteIntegration = this.handleDeleteIntegration.bind(this);
        this.updateActionInDb = this.updateActionInDb.bind(this);
        this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this);
        this.addNewIntegration = this.addNewIntegration.bind(this);
        this.updateTextField = this.updateTextField.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.getBotConf = this.getBotConf.bind(this);
        this.updateBotConfig = this.updateBotConfig.bind(this);
        this.removeNewLines = this.removeNewLines.bind(this);
        this.getActionIds = this.getActionIds.bind(this);
        this.saveUpdatedAction = this.saveUpdatedAction.bind(this);
        this.renderCurrentAuthentications = this.renderCurrentAuthentications.bind(this);
        this.extractAuthentication = this.extractAuthentication.bind(this);
        this.setAuthenticationObject = this.setAuthenticationObject.bind(this);
    }

    renderCurrentAuthentications() {
        const map = this.state.authMap ? this.state.authMap : new Map();
        const keysArray = [...map.keys()];
        return keysArray.map((key) => {

            return <MenuItem key={Math.random()}
                             value={"ExistingAuth##" + key}>{key + (map.get(key).authentication ? " (" + map.get(key).authentication + ")" : "")}</MenuItem>

        });

    }

    saveUpdatedAction() {

        const actionName = this.props.accountName + "." + this.state.integrationName;
        let actionId = -1;
        if (this.state.integrationActions) {

            this.state.integrationActions.map((action) => {
                if (action.name === actionName) {

                    actionId = action.id;
                }
            });

            if (actionId !== -1) {

                this.updateActionInDb(this.state.actionScript, actionId);
            }
        }


    }

    /****
     * Update the CONF Object of the BOT
     * @returns {Promise<void>}
     */

    async updateBotConfig(conf) {

        let url = '/api/updateBotConfig';
        let config = {"development": conf};

        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(config)
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response;

        if (data && data.status === 200) {
            this.setState({notifierOpenConf: true});
        }
    }

    /***
     * Get the BOT development Conf
     *
     * @returns {Promise<void>}
     */
    async getBotConf() {
        let url = '/api/getBotAccountParameters';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );
        let data = await response.json();

        this.setState({libraries: data.addOnLibraries});

        if (data.addOnLibraries.indexOf(this.props.accountName) === -1) {
            data.addOnLibraries.push(this.props.accountName);

        }
        this.updateBotConfig(data);

    }

    updateTextField(e) {

        let str = e.target.value;

        //str = this.removeUnvantedChars(str);
        this.setState({addValue: str});


    }

    addNewIntegration(event) {

        this.setState({
            type: "GET",
            restUrl: "",
            authorization: "No Auth",
            integrationName: this.state.addValue,
            headers: {},
            params: [],
            contextSearchPaths: [],
            advancedResponse: "",
            openAdd: false,
            selected: this.state.integrationArray ? this.state.integrationArray.length : 0

        });
        let integrationArray = this.state.integrationArray ? this.state.integrationArray : [];
        integrationArray.push(this.state.addValue);
        this.setState((integrationArray: integrationArray));
    }

    handleConfirmDeleteClose(event) {

        if (event.target.innerText === "YES") {
            this.handleDeleteIntegration();
            this.setState({deleteIntegrationProcess: true});
        } else {
        }
        this.setState({confirmDeleteOpen: false});

    }

    handleDeleteIntegration() {


        let integrationServiceCode = this.state.integrationCode;
        let regInfo = "(?<=\\/\\*StartIntegrationName " + this.state.integrationName + " StartIntegrationName\\*\\/)(.*)(?=\\/\\*EndIntegrationName " + this.state.integrationName + " EndIntegrationName\\*\\/)";
        let reg = new RegExp(regInfo, 'gms');
        let newIntegrationCode = integrationServiceCode.replace(reg, "");
        newIntegrationCode = newIntegrationCode.replace("/*StartIntegrationName " + this.state.integrationName + " StartIntegrationName*//*EndIntegrationName " + this.state.integrationName + " EndIntegrationName*/", "")


        let id = false;
        this.state.actions.map((action) => {

            if (action.name === this.props.accountName + "." + this.state.integrationName) {

                id = action.id;

            }

        });

        if (id) {
            this.deleteIntegrationAndActionFromDb(id);

            let jsonIntegration = {
                "name": this.props.accountName,
                "description": "Integrate with customer API",
                "example": {}
            }
            this.updateIntegrationLibraryInDb(newIntegrationCode, jsonIntegration);
            this.setState({notifierDeleteOpen: true});
        }

    }

    handleTokenResponsePath(event) {

        this.setState({tokenResponsePath: event.target.value});
    }

    createNewIntegration(event) {


    }

    handleResponseTypeChange(event) {
        let val = event.target.value;
        this.setState({responseType: val});
    }

    handlePostTypeChange(event) {
        let val = event.target.value;
        this.setState({postType: val});
    }

    handlePostParam(event) {

        let str = event.target.value;
        str = str.replace(/\s+$/g, '');
        this.setState({postBody: str});

    }

    handlePostParamAuth(event) {

        this.setState({postBodyAuth: event.target.value});

    }

    handleConfirmOverwriteIntegration(event) {


        if (event.target.innerText === "YES") {
            this.overWriteIntegration();

        }
        this.setState({confirmOverwriteIntegration: false});

    }


    changeState() {

        this.setState({notifierOpen: false, notifierDeleteOpen: false});

    }

    async getAccountIntegrationActions() {

        let url = '/api/getAccountIntegrationActions?model=' + this.props.accountName;
        const response = await fetch(url);

        const data = await response.json();

        this.setState({actions: data});


        if (data && data.length > 0) {
            setTimeout(() => {
                if (this.state.integrationArray.length === 0) {

                    data.map((a) => {
                        this.deleteIntegrationAndActionFromDb(a.id);
                    });

                }
            }, 3000);
        }
    }

    parseResponseFromAction(actionCode) {

        let regInfo = "(?<=\\/\\*Start response as context\\*\\/)(.*)(?=\\/\\*End response as context\\*\\/)";

        let reg = new RegExp(regInfo, 'gms');
        let arr = Array.from(actionCode.matchAll(reg), x => x[1]);
        let allResponses = arr[0];

        this.setState({advancedResponse: allResponses});

        if (arr.length > 0) {
            //get the if paramaters
            regInfo = "(?<=BOT\\.CONVERSATIONS\\[conversationId\\]\\.context\\[\\'(.*)(?=\\'\\]))";

            reg = new RegExp(regInfo, 'g');

            arr = Array.from(arr[0].matchAll(reg), x => x[1]);


            let keys = arr;

            regInfo = "(?<=\\= response\\.(.*)(?=\\;))";

            reg = new RegExp(regInfo, 'g');
            arr = Array.from(allResponses.matchAll(reg), x => x[1]);

            let keyResponse = [];
            keys.map((key, i) => {
                keyResponse[key] = arr[i];

            });

            this.setState({contextSearchPaths: keyResponse});
        }
    }

    removeNewLines(str) {
        return str.replace(/\n/g, '');
    }

    setAuthenticationObject(authObject, reUseAuth) {


        let regInfoAuth2 = "(?<=\\=)(.*)(?=;)";
        let regAuth2 = new RegExp(regInfoAuth2, 'gms');
        let arrAuth2 = Array.from(authObject.matchAll(regAuth2), x => x[1]);

        let authObject2 = arrAuth2[0];

        if (authObject2 && authObject2.toLowerCase().indexOf("username") > -1) {
            this.setState({authorization: "OAuth 2.0", grantType: "Password Credentials"})
            let oldAuthO;
            try {
                oldAuthO = JSON.parse(authObject2);
            } catch (error) {
                console.log(error);
            }

            if (oldAuthO) {

                if (oldAuthO.method) {
                    this.setState({authMethod: oldAuthO.method})
                }
                if (oldAuthO.host) {
                    this.setState({accessTokenURL: oldAuthO.host})
                }
                if (oldAuthO.client_id) {
                    this.setState({clientID: oldAuthO.client_id});
                }
                if (oldAuthO.username) {
                    this.setState({username: oldAuthO.username})
                }
                if (oldAuthO.password) {
                    this.setState({password: oldAuthO.password})
                }
                if (oldAuthO.scope) {
                    this.setState({scope: oldAuthO.scope});
                }
                if (oldAuthO.client_secret) {
                    this.setState({client_secret: oldAuthO.client_secret})
                }
            }
        } else if (authObject2 && authObject2.toLowerCase().indexOf("client_secret") > -1) {

            this.setState({authorization: "OAuth 2.0", grantType: "Client Credentials"})
            let oldAuthO;

            try {
                oldAuthO = JSON.parse(authObject2);
            } catch (error) {
                console.log(error);
            }

            if (oldAuthO) {
                if (oldAuthO.authentication) {
                    this.setState({authentication: oldAuthO.authentication})
                }

                if (oldAuthO.method) {
                    this.setState({authMethod: oldAuthO.method})
                }
                if (oldAuthO.host) {
                    this.setState({accessTokenURL: oldAuthO.host})
                }
                if (oldAuthO.client_id) {
                    this.setState({clientID: oldAuthO.client_id});
                }
                if (oldAuthO.client_secret) {
                    this.setState({clientSecret: oldAuthO.client_secret})
                }

                if (oldAuthO.scope) {
                    this.setState({scope: oldAuthO.scope});
                }

            }


        } else if (authObject2) {
            let oldAuthO;
            try {
                oldAuthO = JSON.parse(authObject2);
            } catch (error) {
                console.log(error);
            }
            if (oldAuthO.host) {
                this.setState({accessTokenURL: oldAuthO.host})
            }

            if (oldAuthO.method) {
                this.setState({authMethod: oldAuthO.method})
            }
        }
    }


    extractAuthentication(integrationServiceCode, name) {
        let regInfoAuth = "(?<=\\/\\*Start Authentication Code " + name.trim() + " Start Authentication Code\\*\\/)(.*)(?=\\/\\*End Authentication Code " + name.trim() + " End Authentication Code\\*\\/)";

        let regAuth = new RegExp(regInfoAuth, 'gms');


        let arrAuth = Array.from(integrationServiceCode.matchAll(regAuth), x => x[1]);

        let authObject = arrAuth[0];

        if (authObject) {

            let regInfoAuth2 = "(?<=\\/\\*STARTAUTHOBJECT\\*\\/)(.*)(?=\\/\\*ENDAUTHOBJECT\\*\\/)";
            let regAuth2 = new RegExp(regInfoAuth2, 'gms');
            let arrAuth2 = Array.from(authObject.matchAll(regAuth2), x => x[1]);
            authObject = arrAuth2[0];


        }

        /*Extract all Auth code */
        const startDelimiter = '/*Start Authentication Code';
        const endDelimiter = 'ENDAUTHOBJECT';
        const regex = new RegExp(`${startDelimiter}(.*?${endDelimiter})`, 'gms');

        const matches = [];
        let match;
        const authMap = new Map();
        while ((match = regex.exec(integrationServiceCode)) !== null) {
            const code = match[1].trim();

            const names = code.split("Start Authentication Code");

            const input = names[1];

            const objString = names[1].split("CONF_AUTHENTICATION =");
            const objString2 = objString[1].split(";");

            authMap.set(names[0].trimEnd(), JSON.parse(objString2[0]));

            this.setState({authMap: authMap});

        }

        return authObject;
    }

    reactOnClick = (event, name, i) => {

        this.setState({
            selected: i,
            testIntegrationResponse: null,
            integrationName: name.trim(),
            authorization: "No Auth",
            headers: {},
            params: [],
            restUrl: "",
            existingAuthorization: null,
            addParamsToQuery: true
        });
        this.setState({})
        //change
        let integrationServiceCode = this.state.integrationCode;

        this.state.actions.map((action) => {

                if (action.name === this.props.accountName + "." + name.trim()) {

                    this.setState({actionScript: action.botscript});
                    this.parseResponseFromAction(action.botscript);
                }
            }
        );
        if (integrationServiceCode) {

            let authObject = this.extractAuthentication(integrationServiceCode, name);

            if (authObject) {
                this.setAuthenticationObject(authObject);
            }

        }

        //let regInfo = "(?<= " + name.trim() + " StartIntegrationName\\*\\/)(.*)(?=\\/\\*EndIntegrationName " + name.trim() + " EndIntegrationName\\*\\/)";

        let regInfo = "(?<=\\/\\*StartIntegrationName " + name.trim() + " StartIntegrationName\\*\\/)(.*)(?=\\/\\*EndIntegrationName " + name.trim() + " EndIntegrationName\\*\\/)";

        let reg = new RegExp(regInfo, 'gms');
        let arr, fullFunctionCode;
        if (integrationServiceCode) {
            arr = Array.from(integrationServiceCode.matchAll(reg), x => x[1]);

            fullFunctionCode = arr[0];

            const regex = /\/\*(.*?)\*\//gs;
            const matches = fullFunctionCode.match(regex);


            if(matches && matches.length > 0){

                let nameArray = matches[0].split("##");

                if(nameArray && nameArray.length > 1){
                    nameArray = nameArray[1].split("*");

                    let aObject = this.extractAuthentication(integrationServiceCode, nameArray[0]);
                    this.setAuthenticationObject(aObject);

                    try{
                        aObject = JSON.parse(aObject);

                    }catch(e){console.log(e)}

                    if(aObject && aObject.authentication){

                        this.setState({authentication: aObject.authentication});
                        //console.log(aObject.authentication)
                    }
                    this.setState({authorization: "ExistingAuth##"+nameArray[0]});



                }

            }
            if (fullFunctionCode && fullFunctionCode.indexOf("/*ACTION USING AUTH") === -1) {

                this.setState({authorization: "No Auth"});
            }

        }
        regInfo = "(?<=function\\()(.*)(?=conversationId\\))";
        reg = new RegExp(regInfo, 'g');
        if (arr && arr.length > 0) {
            arr = Array.from(arr[0].matchAll(reg), x => x[1]);
            if (arr && arr[0]) {
                let allParameters = arr[0].split(",")
            }

        }
        //get the if paramaters
        regInfo = "(?<=if\\((.*)(?=\\)\\{))";

        reg = new RegExp(regInfo, 'g');
        let object;
        if (fullFunctionCode) {

            arr = Array.from(fullFunctionCode.matchAll(reg), x => x[1]);

            if (arr.length > 0) {
                let paramaters = arr[0].split("&&");
                let trimmedParmeters = [];
                paramaters.map((p) => {
                    if (p.trim() !== "") {
                        trimmedParmeters.push(p.trim());
                    }

                });

                this.setState({params: trimmedParmeters});

            }


            const regex = /url\s*=\s*'([^']+)'/;
            const match = regex.exec(fullFunctionCode);
            let url;
            if (match) {
                url = match[1];
                url = url.replace(/'/g, '').trim();

                this.setState({restUrl: url});

            } else {
                console.log("URL not found.");
            }


            if (url) {
                // Create a URL object from the URL string
                try {
                    const urlen = new URL(url);
                    this.setState({restUrl: urlen.origin + urlen.pathname})
// Get the search parameters from the URL
                    const searchParams = urlen.searchParams;

// Create an object to store the extracted query parameters
                    const queryParams = {};

// Iterate over each query parameter
                    for (const [param, value] of searchParams.entries()) {
                        queryParams[param] = value;
                    }

                    this.setState({params: Object.keys(queryParams)})
                } catch (error) {

                    console.log(error)
                }
            }


            const regexHeader = /let headerObject\s*=\s*({[^}]+})/;
            const matchHeader = regexHeader.exec(fullFunctionCode);
            if (matchHeader) {
                let headerObjectString = matchHeader[1];

                try {
                    object = JSON.parse(headerObjectString);

                } catch (error) {
                    if (headerObjectString.indexOf("Authorization") > -1) {
                        headerObjectString = headerObjectString.replace(/,"Authorization(.*)/, "}");

                        headerObjectString = headerObjectString.replace(/'/g, '"');

                        try {

                            object = JSON.parse(headerObjectString);

                        } catch (e) {
                            //console.log(e)
                        }

                    }
                    console.log("faulty header object", headerObjectString)
                }


            } else {
                console.log("headerObject not found.");
            }
        }


        if (object && object.hasOwnProperty('Authorization') && object['Authorization'].indexOf("token") > -1) {

            delete object.Authorization;
        }

        //parse header
        if (object) {

            this.setState({headers: object});

        } else {

            this.setState({headers: {}})
        }

        regInfo = "(?<=method: \')(.*)(?=\')";
        //regInfo = "(?<=let options =)(.*)(?=;)";
        reg = new RegExp(regInfo, 'g');
        arr = Array.from(fullFunctionCode ? fullFunctionCode.matchAll(reg) : [], x => x[1]);
        if (arr.length > 0) {
            this.setState({type: arr[0]});
        }


        if (arr[0] === "POST") {


            const regex = /JSON\.stringify\(([\s\S]*?)\)/g;
            const matches = [];
            let match;

            while ((match = regex.exec(fullFunctionCode)) !== null) {
                matches.push(match[1]);
            }


            /* regInfo = "(?<=JSON\\.stringify\\()(.*)(?=\\)\\})";

             reg = new RegExp(regInfo, 'gms');
             arr = Array.from(fullFunctionCode ? fullFunctionCode.matchAll(reg) : [], x => x[1]);*/
            this.setState({postBody: matches});

            // arr = Array.from( ? fullFunctionCode.matchAll(reg) : [], x => x[1]);

        }

    };


    renderContextSearchPaths() {
        let keys = Object.keys(this.state.contextSearchPaths);

        if (keys.length > 0) {
            return keys.map((param, i) => {
                return <tr key={i}>

                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                    }}>{param}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                    }}>{this.state.contextSearchPaths[param]}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        width: "20px",

                    }}>
                        <div style={{display: "flex", alignItems: "center"}}><IconButton
                            onClick={(event) => this.handleDeleteContextSearchPaths(event, param, i)}><Delete
                            style={{fontSize: 24}}/></IconButton></div>
                    </td>

                </tr>;
            })
        }

    }

    handleResponseSearchPath(event) {

        this.setState({responseSearchPath: event.target.value})
    }

    handleContextKey(event) {
        this.setState({contextKey: event.target.value})

    }

    handleContextSearchPath(event) {


        let contextSearchPaths = this.state.contextSearchPaths;
        if (contextSearchPaths) {
            contextSearchPaths[this.state.contextKey] = this.state.responseSearchPath;
            this.setState({contextSearchPaths: contextSearchPaths});
        }
    }

    handleTestParamChange = (event, param, i) => {

        let paramTestValues = this.state.paramTestValues;
        if (paramTestValues && event && event.currentTarget) {
            paramTestValues[param] = event.currentTarget.value;

            this.setState({paramTestValues: paramTestValues});
        }


    }

    async handleTestIntegration() {
        let url = this.state.restUrl;
        let urlObject = new URL(this.state.restUrl);

        let numberOfParams = Array.from(urlObject.searchParams).length;

        let keys = Object.keys(this.state.paramTestValues);

        keys.map((key, i) => {

            if (i === 0 && numberOfParams === 0) {

                url += "?" + key + "=" + this.state.paramTestValues[key];

            } else {

                url += "&" + key + "=" + this.state.paramTestValues[key];

            }


        })

        let headerObject;

        if (this.state.authorization === "OAuth 2.0") {
            headerObject = {
                'Authorization': 'Bearer ' + this.state.access_token
            };


        } else {

            headerObject = this.state.headers;
        }
        let options = {
            method: this.state.type,
            headers: headerObject

        };

        if (this.state.postBody && this.state.postBody !== "") {

            let postBody;

            if (this.state.postType === "JSON") {
                try {
                    postBody = JSON.parse(this.state.postBody.trim());

                } catch (e) {

                    console.log(e)

                }
                options.body = JSON.stringify(postBody);
            } else {

                options.body = this.state.postBody.trim();
            }

        }

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();

        this.setState({testIntegrationResponse: JSON.stringify(data, null, "\t")});
        this.setState({tabValue: 1});

    }


    handleGetToken(event) {
        //console.log(this.createAuthenticationCode());
        this.getToken();
    }

    async getToken() {
        let url = this.state.accessTokenURL;
        let response = "";
        if (this.state.authorization === "Manual") {
            let options;
            if (this.state.authMethod === "POST") {
                let postBody = JSON.parse(this.state.postBodyAuth.trim());
                options = {
                    method: this.state.authMethod,
                    headers: this.state.headersAuth,
                    body: JSON.stringify(postBody)
                };
            } else {
                options = {
                    method: this.state.authMethod,
                    headers: this.state.headersAuth
                };

            }

            response = await fetch(url, options).catch(error => console.log(error)
            );

            this.setState({errorGetToken: null});
            if (response && response.status === 200) {


                let data = await response.json();

                if (this.state.tokenResponsePath && this.state.tokenResponsePath !== "") {


                    this.setState({access_token: data[this.state.tokenResponsePath]});
                    this.setState({tokenSuccess: true});
                }
                if (data.access_token) {

                    this.setState({access_token: data.access_token});
                    this.setState({tokenSuccess: true});
                } else if (data.accessToken) {

                    this.setState({access_token: data.accessToken});
                    this.setState({tokenSuccess: true});
                }

            } else {

                this.setState({errorGetToken: "Unable to get Access Token"})
            }

        } else {


            let postBody = '';
            if (this.state.grantType === 'Password Credentials') {
                postBody += 'grant_type=password'
            }

            if (this.state.grantType === 'Client Credentials') {
                postBody += 'grant_type=client_credentials'
            }

            if (this.state.username) {

                postBody += '&username=' + this.state.username;
            }

            if (this.state.password) {

                postBody += '&password=' + this.state.password;
            }

            if (this.state.clientID) {

                postBody += '&client_id=' + this.state.clientID;
            }

            if (this.state.clientSecret) {

                postBody += '&client_secret=' + this.state.clientSecret;
            }

            if (this.state.scope) {

                postBody += '&scope=' + "\"" + this.state.scope + "\"";
            }

            //&username=' + CONF_Incontact.username + '&password=' + CONF_Incontact.password + '&client_id=' + CONF_Incontact.client_id
            let options;

            options = {
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: postBody
            };
            /*if(this.state.authMethod === "POST") {

            }else{
                options = {
                    method: this.state.authMethod,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                };

            }*/

            response = await fetch(url, options).catch(error => console.log(error));
            this.setState({errorGetToken: null});
            if (response && response.status === 200) {


                let data = await response.json();


                if (data.access_token) {

                    this.setState({access_token: data.access_token});
                    this.setState({tokenSuccess: true});
                } else if (data.accessToken) {

                    this.setState({access_token: data.accessToken});
                    this.setState({tokenSuccess: true});
                }

            } else {

                this.setState({errorGetToken: "Unable to get Access Token"})
            }
        }


    }

    handleInputHeaderValue(event) {

        this.setState({headerValue: event.target.value})
    }

    handleInputHeaderValueAuth(event) {

        this.setState({headerValueAuth: event.target.value})
    }

    handleInputHeaderKey(event) {

        this.setState({headerKey: event.target.value})
    }

    handleInputHeaderKeyAuth(event) {

        this.setState({headerKeyAuth: event.target.value})
    }

    handleScope(event) {

        this.setState({scope: event.target.value})
    }

    handlePassword(event) {

        this.setState({password: event.target.value})
    }

    handleUsername(event) {

        this.setState({username: event.target.value})

    }

    handleClientSecret(event) {

        this.setState({clientSecret: event.target.value})
    }

    handleClientIdChange(event) {

        this.setState({clientID: event.target.value})
    }

    handleAccessTokenURL(event) {

        this.setState({accessTokenURL: event.target.value})

    }

    handleAuthorization(event) {

        if (event.target.value.indexOf("ExistingAuth") > -1) {
            const reuse = event.target.value.split("##");
            this.setState({existingAuthorization: reuse[1].trimEnd()});
            let obj = this.state.authMap.get(reuse[1]);

            try {
                if (obj.authentication) {
                    this.setState({authentication: obj.authentication});
                }
                this.setState({authMethod: obj.method})
                this.setState({accessTokenURL: obj.host})
                this.setState({clientID: obj.client_id});
                this.setState({clientSecret: obj.client_secret})
                if (obj.scope) {
                    this.setState({scope: obj.scope});
                }

            } catch (e) {

            }


        } else {

            this.setState({authentication: null})
        }

        this.setState({authorization: event.target.value})

    }


    async updateIntegrationLibraryInDb(integrationServiceCode, jsonIntegration) {

        let url = '/api/updateIntegrationLibrary?name=' + this.props.accountName + "&library=" + this.props.accountName;

        let postObject = {"integrationCode": integrationServiceCode, "jsonconf": JSON.stringify(jsonIntegration)};

        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postObject)
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );

        let data = await response.json();
        if (data && data.length === 0) {

            this.setState({integrationUpdatedOpen: true});

        }
        this.getAllIntegrations();


    }

    handleContextResponse(event) {

        this.setState({contextFromResponseChecked: event.target.checked});

    }

    getModels(account) {

        fetch('/api/trainingDataForAccount?account=' + account)
            .then(response => {

                response.json().then(data => {

                    if (data && data.length > 0 && data.name !== 'error') {

                        let models = [];
                        data.map((t) => {
                            models.push(t.Id);

                        });

                        this.setState({models: models});

                        this.setState({firstModel: this.props.accountName}, () => {

                            this.getIntegrationLibrary();
                            //this.getAccountIntegrationActions();

                        });

                        //this.setState( {model: this.state.newModel});
                    }
                })
            })


    }


    async overWriteIntegration() {
//change
        let integrationServiceCode = this.state.integrationCode;
        this.setState({IntegrationExist: true, overwrite: true});

        let integrationArray = [];
        let regInfo = "(?<=\\/\\*StartIntegrationName " + this.state.integrationName + " StartIntegrationName\\*\\/)(.*)(?=\\/\\*EndIntegrationName " + this.state.integrationName + " EndIntegrationName\\*\\/)";

        let reg = new RegExp(regInfo, 'gms');
        let arr = Array.from(integrationServiceCode.matchAll(reg), x => x[1]);


        let newIntegartionCode = integrationServiceCode.replace(reg, "");

        let regInfo2 = "\\/\\*StartIntegrationName " + this.state.integrationName + " StartIntegrationName\\*\\/";
        let reg2 = new RegExp(regInfo2, 'g');
        let regInfo3 = "\\/\\*EndIntegrationName " + this.state.integrationName + " EndIntegrationName\\*\\/";
        let reg3 = new RegExp(regInfo3, 'g');
        newIntegartionCode = newIntegartionCode.replace(reg2, "");
        newIntegartionCode = newIntegartionCode.replace(reg3, "");

        let regInfoAuth = "(?<=\\/\\*Start Authentication Code " + this.state.integrationName + " Start Authentication Code\\*\\/)(.*)(?=\\/\\*End Authentication Code " + this.state.integrationName + " End Authentication Code\\*\\/)";

        let regAuth = new RegExp(regInfoAuth, 'gms');
        let arrAuth = Array.from(newIntegartionCode.matchAll(regAuth), x => x[1]);

        if (arrAuth.length > 0) {
            newIntegartionCode = newIntegartionCode.replace(regAuth, "");
            let regAuth2 = "\\/\\*Start Authentication Code " + this.state.integrationName + " Start Authentication Code\\*\\/";
            let reg2 = new RegExp(regAuth2, 'g');
            let regAuth3 = "\\/\\*End Authentication Code " + this.state.integrationName + " End Authentication Code\\*\\/";
            let reg3 = new RegExp(regAuth3, 'g');
            newIntegartionCode = newIntegartionCode.replace(reg2, "");
            newIntegartionCode = newIntegartionCode.replace(reg3, "");
        }


        //Clear Integration code and then clear Authcode

        let jsonIntegration = {
            "name": this.props.accountName,
            "description": "Integrate with customer API",
            "example": {}
        }

        let id = false;
        this.state.actions.map((action) => {

            if (action.name === this.props.accountName + "." + this.state.integrationName) {

                id = action.id;

            }

        });


        if (id) {
            this.setState({updateId: id});


            //const actionUpdated = await this.deleteIntegrationAndActionFromDb(id);
            //const integrationUpdated = await this.updateIntegrationLibraryInDb(newIntegartionCode, jsonIntegration);

        }
        this.createIntegration(newIntegartionCode);

    }

    async handleCreateIntegration(event) {
//change
        let integrationServiceCode = this.state.integrationCode;

        if (integrationServiceCode) {

            const matches = integrationServiceCode.matchAll(/StartIntegrationName\ (.*?) StartIntegrationName/g);
            let currentNameArray = Array.from(matches, x => x[1]);
            if (currentNameArray.indexOf(this.state.integrationName) > -1) {
                this.setState({confirmOverwriteIntegration: true});
            } else {
                this.createIntegration();
            }
        } else {
            this.createIntegration();
        }


    }

    async deleteIntegrationAndActionFromDb(id) {

        let url = "/api/deleteAction?id=" + id;

        const response = await fetch(url);

        const data = await response.json();

    }

    handleDelete = (event, param, index) => {

        this.state.params.splice(index, 1);
        this.setState({param: this.state.params});

    }

    handleDeleteContextSearchPaths = (event, contextPath, index) => {

        let contextSearchPaths = this.state.contextSearchPaths;
        delete contextSearchPaths[contextPath];

        this.setState({contextSearchPaths: contextSearchPaths})
    }
    handleDeleteHeaderAuth = (event, header, index) => {


        let headers = this.state.headersAuth;
        delete headers[header];

        this.setState({headersAuth: headers})

        /*this.state.params.splice(index, 1);
        this.setState({param: this.state.params});*/

    }
    handleDeleteHeader = (event, header, index) => {


        let headers = this.state.headers;
        delete headers[header];

        this.setState({headers: headers})

        /*this.state.params.splice(index, 1);
        this.setState({param: this.state.params});*/

    }

    handleIntegrationName(event) {
        let name = event.target.value;
        name = name.replaceAll(/\s/g, '');

        this.setState({integrationName: name, changeName: false});

    }

    handleAddParameter(event) {

        let params = this.state.params;
        if (this.state.paramValue !== "") {

            params.push(this.state.paramValue);
            this.setState({paramValue: ""});
        }

        this.setState({params: params});

    }

    handleAddHeadersAuth(event) {
        let headers = this.state.headersAuth;
        if (this.state.headerValueAuth !== "") {

            headers[this.state.headerKeyAuth] = this.state.headerValueAuth;
            this.setState({headerValueAuth: "", headerKeyAuth: ""});
            this.setState({headersAuth: headers});

        }


    }

    handleAddHeaders(event) {
        let headers = this.state.headers;
        if (this.state.headerValue !== "") {

            headers[this.state.headerKey] = this.state.headerValue;
            this.setState({headerValue: "", headerKey: ""});
            this.setState({headers: headers});

        }


    }

    handleInputParams(event) {

        this.setState({paramValue: event.target.value});
    }

    handleRestChange(event) {
        this.setState({restUrl: event.target.value});
    }

    async getIntegrationLibrary() {


        let url = '/api/getIntegrationLibrary?name=' + this.props.accountName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        if (data.length > 0) {
            let buf = Buffer.from(data[0].javascript);
            //change
            this.setState({IntegrationExist: true, integrationCode: buf.toString()}, () => {

            });

        }


    }

    createAuthenticationCode() {
        let tokenString;
        if (this.state.grantType === "Client Credentials" && this.state.authorization !== "Manual") {
            let scope = "";
            if (this.state.scope && this.state.scope !== "") {

                scope = "            \"scope\":\"" + this.state.scope + "\",\n";
            }

            tokenString = "/*Start Authentication Code " + this.state.integrationName + " Start Authentication Code*/ \n" +
                "    function requestToken" + this.state.integrationName + "() { \n" +
                "    /*STARTAUTHOBJECT*/\n" +
                "        var CONF_AUTHENTICATION = {\n" +
                "            \"host\":\"" + this.state.accessTokenURL.trim() + "\" ,\n" +
                "            \"client_secret\":\"" + this.state.clientSecret + "\",\n" +
                "            \"client_id\":\"" + this.state.clientID + "\",\n" +
                scope +
                "            \"method\":\"" + this.state.authMethod + "\"\n" +
                "            \"authentication\":\"" + this.state.authentication + "\"\n" +
                "        };\n" +
                "    /*ENDAUTHOBJECT*/\n" +
                "        var options = {\n" +
                "          url: CONF_AUTHENTICATION.host,\n" +
                "          method: '" + this.state.authMethod + "',\n" +
                "          body: 'grant_type=client_credentials&client_secret=' + CONF_AUTHENTICATION.client_secret + '&client_id=' + CONF_AUTHENTICATION.client_id\n   " +
                "        };\n" +
                "        BOT.request(options, function(error, response, body) {\n" +
                "            if (error) {\n" +
                "                  BOT.LOGGER.log({ \n" +
                "                         entity: '" + this.state.integrationName + " Token Error',\n" +
                "                         version: 0,\n" +
                "                         type: 'log',\n" +
                "                         conversationId: null,\n" +
                "                         caseId: null,\n" +
                "                         description: '" + this.state.integrationName + " Token Error',\n" +
                "                         json: {\n" +
                "                                 Error: error\n" +
                "                                }\n" +
                "                         });\n" +
                "            } else {\n" +
                "                   BOT.LOGGER.log({ \n" +
                "                       entity: '" + this.state.integrationName + " Token',\n" +
                "                       version: 0,\n" +
                "                       type: 'log',\n" +
                "                       conversationId: null,\n" +
                "                       caseId: null,\n" +
                "                       description: '" + this.state.integrationName + " Token',\n" +
                "                       json: {\n" +
                "                           body: body\n" +
                "                       }\n" +
                "                   });\n" +
                "                   BOT." + this.props.accountName + "_" + this.state.integrationName + "_token = JSON.parse(body);\n" +
                "           }\n" +
                "        });\n" +
                "    }\n" +
                "       if (!BOT." + this.props.accountName + "_" + this.state.integrationName + "_token) {\n" +
                "           requestToken" + this.state.integrationName + "();\n" +
                "           BOT.TIMER_3600secActions." + this.props.accountName + "_" + this.state.integrationName + "_token_renew = function() {\n" +
                "           requestToken" + this.state.integrationName + "();\n" +
                "       }\n" +
                "    }\n" +
                "    /*End Authentication Code " + this.state.integrationName + " End Authentication Code*/\n" +
                "    /*##AUTHENTICATIONCODE##*/\n";


        } else if (this.state.grantType === "Password Credentials" && this.state.authorization !== "Manual") {
            tokenString = "/*Start Authentication Code " + this.state.integrationName + " Start Authentication Code*/ \n function requestToken" + this.state.integrationName + "() { \n" +
                "    /*STARTAUTHOBJECT*/\n" +
                "    var CONF_AUTHENTICATION = {\n" +
                "        \"host\":\"" + this.state.accessTokenURL.trim() + "\" ,\n" +
                "        \"username\":\"" + this.state.username + "\",\n" +
                "        \"password\":\"" + this.state.password + "\",\n" +
                "        \"client_id\":\"" + this.state.clientID + "\",\n" +
                "        \"method\":\"" + this.state.authMethod + "\"\n" +
                "            \"authentication\":\"" + this.state.authentication + "\"\n" +
                "    };\n" +
                "    /*ENDAUTHOBJECT*/\n" +
                "    var options = {\n" +
                "        url: CONF_AUTHENTICATION.host,\n" +
                "        method: '" + this.state.authMethod + "',\n" +
                "        body: 'grant_type=password&username=' + CONF_AUTHENTICATION.username + '&password=' + CONF_AUTHENTICATION.password + '&client_id=' + CONF_AUTHENTICATION.client_id\n   " +
                "    };\n" +
                "    BOT.request(options, function(error, response, body) {\n" +
                "        if (error) {\n" +
                "                console.log(error);\n" +
                "        } else {\n" +
                "                BOT." + this.props.accountName + "_" + this.state.integrationName + "_token = JSON.parse(body);\n" +
                "        }\n" +
                "    });\n" +
                "}\n" +
                "if (!BOT." + this.props.accountName + "_" + this.state.integrationName + "_token) {\n" +
                "        requestToken" + this.state.integrationName + "();\n" +
                "        BOT.TIMER_3600secActions." + this.props.accountName + "_" + this.state.integrationName + "_token_renew = function() {\n" +
                "            requestToken" + this.state.integrationName + "();\n" +
                "        }\n" +
                "    }/*End Authentication Code " + this.state.integrationName + " End Authentication Code*/\n" +
                "    /*##AUTHENTICATIONCODE##*/\n";
        } else if (this.state.authorization === "Manual") {
            let postBody = JSON.parse(this.state.postBodyAuth.trim());
            let body = JSON.stringify(postBody);
            tokenString = "/*Start Authentication Code " + this.state.integrationName + " Start Authentication Code*/ \n function requestToken" + this.state.integrationName + "() { \n" +
                "    /*STARTAUTHOBJECT*/\n" +
                "    var CONF_AUTHENTICATION = {\n" +
                "        \"host\":\"" + this.state.accessTokenURL.trim() + "\" ,\n" +
                "        \"method\":\"" + this.state.authMethod + "\" ,\n" +
                "            \"authentication\":\"" + this.state.authentication + "\"\n" +
                "    };\n" +
                "    /*ENDAUTHOBJECT*/\n" +
                "    var options = {\n" +
                "        url: CONF_AUTHENTICATION.host,\n" +
                "        method: '" + this.state.authMethod + "',\n" +
                (this.state.authMethod === "POST" ? "        body: JSON.stringify(" + body + ") " :
                    "        ") +
                " \n" +
                "    };\n" +
                "    BOT.request(options, function(error, response, body) {\n" +
                "        if (error) {\n" +
                "                console.log(error);\n" +
                "        } else {\n" +
                "                BOT." + this.props.accountName + "_token = JSON.parse(body);\n" +
                "        }\n" +
                "    });\n" +
                "}\n" +
                "if (!BOT." + this.props.accountName + "_" + this.state.integrationName + "_token) {\n" +
                "        requestToken" + this.state.integrationName + "();\n" +
                "        BOT.TIMER_3600secActions." + this.props.accountName + "_" + this.state.integrationName + "_token_renew = function() {\n" +
                "            requestToken" + this.state.integrationName + "();\n" +
                "        }\n" +
                "    }/*End Authentication Code " + this.state.integrationName + " End Authentication Code*/\n" +
                "    /*##AUTHENTICATIONCODE##*/\n";

        }

        return tokenString;

    }


    createIntegration(newIntegrationCode) {
        let integrationServiceCode;

        if (!this.state.IntegrationExist && newIntegrationCode === "newLibrary") {
            integrationServiceCode = "(function(BOT, CONF) { \n" +
                "\n" +
                "/*##AUTHENTICATIONCODE##*/ \n" +
                "var service = {\n" +
                "/*##SERVICECODE##*/ \n" +
                "};\n" +
                "return service;\n" +
                "}(BOT, CONF));";

            let jsonIntegration = {
                "name": this.props.accountName,
                "description": "Integrate with customer API",
                "example": {}

            }

            this.createIntegrationInDb(integrationServiceCode, jsonIntegration);
            return;

        } else if (!this.state.IntegrationExist) {

            integrationServiceCode = "(function(BOT, CONF) { \n" +
                "\n" +
                "/*##AUTHENTICATIONCODE##*/ \n" +
                "var service = {\n" +
                "/*##SERVICECODE##*/ \n" +
                "};\n" +
                "return service;\n" +
                "}(BOT, CONF));";

        }


        if (this.state.IntegrationExist) {
            if (newIntegrationCode) {
                integrationServiceCode = newIntegrationCode;
            } else {
                //change
                integrationServiceCode = this.state.integrationCode;
            }
        }


        if (this.state.authorization === "OAuth 2.0" || this.state.authorization === "Manual") {
            let authCode = this.createAuthenticationCode();

            if (authCode) {

                let regInfoAuth = "(?<=\\/\\*Start Authentication Code " + this.state.integrationName + " Start Authentication Code\\*\\/)(.*)(?=\\/\\*End Authentication Code " + this.state.integrationName + " End Authentication Code\\*\\/)";

                let regAuth = new RegExp(regInfoAuth, 'gms');
                let arrAuth = Array.from(integrationServiceCode.matchAll(regAuth), x => x[1]);


                let newIntegartionCode = integrationServiceCode.replace(regAuth, "");

                if (arrAuth.length > 0) {


                    let regInfo2 = "\\/\\*Start Authentication Code " + this.state.integrationName + " Start Authentication Code\\*\\/";
                    let reg2 = new RegExp(regInfo2, 'g');
                    let regInfo3 = "\\/\\*End Authentication Code " + this.state.integrationName + " End Authentication Code\\*\\/";
                    let reg3 = new RegExp(regInfo3, 'g');
                    newIntegartionCode = newIntegartionCode.replace(reg2, "/*##AUTHENTICATIONCODE##*/");
                    newIntegartionCode = newIntegartionCode.replace(reg3, "");

                    integrationServiceCode = newIntegartionCode.replaceAll("/*##AUTHENTICATIONCODE##*/", authCode);
                } else {

                    integrationServiceCode = newIntegartionCode.replaceAll("/*##AUTHENTICATIONCODE##*/", authCode);
                }
            }


        }

        this.createAction();

        let integrationCodeString = "/*##INTEGRATIONNAMECOMMENT##*/\n" +
            " ##INTEGRATIONNAME##: function(##PARAMETERS## conversationId) {\n" +
            "\n" +
            "            ##USINGAUTH##\n" +
            "            ##PARAMETERSCHECKSTRING##\n" +
            "                \n" +
            "                    let url = '##INTEGRATIONURL####URLPARAMETERSVALUES##\n" +
            "                    ##TOKENOBJECT##;\n" +
            "                    let headerObject = ##HEADEROBJECT##;\n" +
            "\n" +
            "                    let options = {headers: headerObject,url: url,method: ##METHOD##, ##BODY##};\n" +
            "\n" +
            "                    BOT.request(options, function(error, response, body) {\n" +
            "                        if (error) {\n" +
            "                            \n" +
            "                                BOT.LOGGER.log({ \n" +
            "                                   entity: '##INTEGRATIONNAME## Error',\n" +
            "                                   version: 0,\n" +
            "                                   type: 'log',\n" +
            "                                   conversationId: conversationId,\n" +
            "                                   caseId: null,\n" +
            "                                   description: '##INTEGRATIONNAME## Error',\n" +
            "                                   json: {\n" +
            "                                        Error: error\n" +
            "                                   }\n" +
            "                               });\n" +
            "                        } else {\n" +
            "                            var resp;\n" +
            "\n" +
            "                            try {\n" +
            "                                resp = JSON.parse(body);\n" +
            "\n" +
            "                            } catch (err) {\n" +
            "\n" +
            "                                BOT.LOGGER.log({ \n" +
            "                                   entity: '##INTEGRATIONNAME## Error',\n" +
            "                                   version: 0,\n" +
            "                                   type: 'log',\n" +
            "                                   conversationId: conversationId,\n" +
            "                                   caseId: null,\n" +
            "                                   description: '##INTEGRATIONNAME## Error',\n" +
            "                                   json: {\n" +
            "                                        Error: err\n" +
            "                                   }\n" +
            "                               });\n" +
            "                            }\n" +
            "                        \n" +
            "                            if (resp) {\n" +
            "\n" +
            "                               BOT.LOGGER.log({ \n" +
            "                                   entity: '##INTEGRATIONNAME## Response',\n" +
            "                                   version: 0,\n" +
            "                                   type: 'log',\n" +
            "                                   conversationId: conversationId,\n" +
            "                                   caseId: null,\n" +
            "                                   description: '##INTEGRATIONNAME## Response',\n" +
            "                                   json: {\n" +
            "                                        options: options,\n" +
            "                                        response: resp,\n" +
            "                                   }\n" +
            "                               });\n" +

            "                                BOT.WORKFLOW.runNextAction(conversationId, {\n" +
            "                                    type: '##INTEGRATIONNAME##',\n" +
            "                                    messageType: 'response',\n" +
            "                                    response: resp\n" +
            "                                });\n" +
            "                            }\n" +
            "\n" +
            "                        }\n" +
            "                        return;\n" +
            "                    });                \n" +
            "    ##PARAMETERSCHECKSTRINGEND##\n" +
            "        },\n" +
            "/*EndIntegrationName " + this.state.integrationName + " EndIntegrationName*/\n" +
            "/* ##ADDANOTHERINTEGRATIONPLACEHOLDER## */";

        if (this.state.authorization !== "No Auth") {

            if (this.state.existingAuthorization) {
                integrationCodeString = integrationCodeString.replaceAll("##USINGAUTH##", "/*ACTION USING AUTH##" + this.state.existingAuthorization + "*/");
            } else {
                integrationCodeString = integrationCodeString.replaceAll("##USINGAUTH##", "/*ACTION USING AUTH*/");

            }


        } else {
            integrationCodeString = integrationCodeString.replaceAll("##USINGAUTH##", "");
        }

        integrationCodeString = integrationCodeString.replaceAll("##INTEGRATIONNAMECOMMENT##", "StartIntegrationName " + this.state.integrationName + " StartIntegrationName");
        integrationCodeString = integrationCodeString.replaceAll("##INTEGRATIONNAME##", this.state.integrationName);
        if (this.state.params.length > 0) {
            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERS##", this.state.params.toString() + ",");
        } else {
            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERS##", "");
        }


        integrationCodeString = integrationCodeString.replaceAll("##METHOD##", "'" + this.state.type + "'");

        if (this.state.postBody && this.state.postBody !== "") {

            //  let postBodyString = JSON.parse(this.state.postBody);
            integrationCodeString = integrationCodeString.replaceAll("##BODY##", "body: JSON.stringify(" + this.state.postBody + ")");

        } else {
            integrationCodeString = integrationCodeString.replaceAll("##BODY##", "");
        }


        integrationCodeString = integrationCodeString.replaceAll("##INTEGRATIONURL##", this.state.restUrl);
        let queryParameters = "';";
        if (this.state.params && this.state.params.length > 0) {
            queryParameters = "";
        }


        let parameterCheckString = "";


        if (this.state.params && this.state.restUrl) {

            let url = new URL(this.state.restUrl);

            let numberOfParams = Array.from(url.searchParams).length;

            let firstParam = false;
            this.state.params.map((param, i) => {

                param = param.trim();

                if ((numberOfParams === 0) && !firstParam) {

                    queryParameters += "?" + param + "='+" + "encodeURIComponent(" + param + ")";
                    parameterCheckString += param;
                    firstParam = true;

                } else {

                    if (i === 0) {
                        queryParameters += "&" + param + "='+" + "encodeURIComponent(" + param + ")";

                    } else {
                        queryParameters += "+'&" + param + "='+" + "encodeURIComponent(" + param + ")";
                    }
                    if (i === 0 && numberOfParams > 0) {
                        parameterCheckString += param;
                    } else {
                        parameterCheckString += " && " + param;
                    }
                }
            });


        }

        if (parameterCheckString !== "") {
            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERSCHECKSTRING##", "if(" + parameterCheckString + "){");
            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERSCHECKSTRINGEND##", "}");
        } else {

            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERSCHECKSTRINGEND##", "");
            integrationCodeString = integrationCodeString.replaceAll("##PARAMETERSCHECKSTRING##", "");

        }

        if (!this.state.addParamsToQuery) {

            queryParameters = "';";
        }

        integrationCodeString = integrationCodeString.replaceAll("##URLPARAMETERSVALUES##", queryParameters);

        if (this.state.authorization === "OAuth 2.0" || this.state.authentication === "OAuth 2.0") {

            let authHeader = this.state.headers;


            authHeader.Authorization = "##TOKEN##";
            authHeader = JSON.stringify(authHeader);

            integrationCodeString = integrationCodeString.replaceAll("##HEADEROBJECT##", authHeader);

            delete authHeader.Authorization;
            let tokenString;
            if (this.state.authorization === "OAuth 2.0") {
                tokenString = "let token = 'Bearer ' + BOT." + this.props.accountName + "_" + this.state.integrationName + "_token.access_token";
            } else if (this.state.authentication === "OAuth 2.0") {

                tokenString = "let token = 'Bearer ' + BOT." + this.props.accountName + "_" + this.state.existingAuthorization.trim() + "_token.access_token";

            }

            integrationCodeString = integrationCodeString.replaceAll("##TOKENOBJECT##", tokenString);
            let integrationCodeStringArray = integrationCodeString.split('"##TOKEN##"');

            integrationCodeString = integrationCodeStringArray[0] + "token" + integrationCodeStringArray[1];

        } else {
            integrationCodeString = integrationCodeString.replaceAll("##TOKENOBJECT##", "");
            if (this.state.headers) {

                integrationCodeString = integrationCodeString.replaceAll("##HEADEROBJECT##", JSON.stringify(this.state.headers));
            }

        }


        if (!this.state.IntegrationExist || integrationServiceCode.indexOf("/*##SERVICECODE##*/") > -1) {
            integrationServiceCode = integrationServiceCode.replaceAll("/*##SERVICECODE##*/", integrationCodeString);
        } else {
            integrationServiceCode = integrationServiceCode.replaceAll("/* ##ADDANOTHERINTEGRATIONPLACEHOLDER## */", integrationCodeString);
        }


        let jsonIntegration = {
            "name": this.props.accountName,
            "description": "Integrate with customer API",
            "example": {}

        }


        let integrationFound = false;
        this.state.allIntegrationNames.map((integration) => {

            if (integration === this.props.accountName) {
                integrationFound = true;
            }

        });

        if (!integrationFound) {


            this.createIntegrationInDb(integrationServiceCode, jsonIntegration);

        } else {

            this.updateIntegrationLibraryInDb(integrationServiceCode, jsonIntegration);

        }

    }

    async getActionIds() {

        let url = '/api/getActionsFromLibrary?library=' + this.props.accountName;
        const response = await fetch(url);

        const data = await response.json();
        this.setState({integrationActions: data});

    }

    async updateActionInDb(actionCode, id) {

        let url = '/api/updateActionCode?id=' + id;
        let postObject = {"actionCode": actionCode};


        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postObject)
        };


        let response = await fetch(url, options).catch(error => console.log(error)
        );

        let data = await response.json();

        if (data.length === 0) {

            this.setState({actionCreated: true, actionUpdatedOpen: true});

        }

    }

    async createActionInDb(actionCode, jsonAction) {

        let url = '/api/addAction?name=' + this.props.accountName + "." + this.state.integrationName + "&library=" + this.props.accountName;
        let postObject = {"actionCode": actionCode, "jsonconf": JSON.stringify(jsonAction)};


        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postObject)
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );

        let data = await response.json();

        if (data.length === 0) {

            this.setState({actionCreated: true, notifierOpen: true});

        }

    }


    async createIntegrationInDb(integrationServiceCode, jsonIntegration) {

        let url = '/api/addIntegrationLibrary?name=' + this.props.accountName + "&library=" + this.props.accountName;
        let postObject = {"integrationCode": integrationServiceCode, "jsonconf": JSON.stringify(jsonIntegration)};

        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postObject)
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );

        let data = await response.json();


        this.setState({integrationCreated: true});
    }

    createAction() {

        let actionString = "(function(BOT, CONF) {\n" +
            "    return function(flow, ACTION, conversationId, message, runFlowStepAction) {\n" +
            "        switch (ACTION.state) {\n" +
            "            case 'activated':\n" +
            "              ##PARAMETERSCHECK##\n" +
            " \n" +
            "                BOT.INTEGRATIONS['##MODELNAME##'].##INTEGRATIONNAME##(##PARAMETERS## conversationId);\n" +
            "                ACTION.waitingForAnsver = true;\n" +
            "                ACTION.state = 'executed';\n" +
            " \n" +
            "                ##PARAMETERSCHECKEND## \n" +
            "            break;\n" +
            "            case 'executed':\n" +
            "                if (message && message.messageType === 'response' && message.type === '##MESSAGETYPE##') {\n" +
            "                    var response = message.response;\n" +
            "                    BOT.LOGGER.log({ \n" +
            "                        entity: '##INTEGRATIONNAME##',\n" +
            "                        version: 0,\n" +
            "                        type: 'log',\n" +
            "                        conversationId: conversationId,\n" +
            "                        caseId: null,\n" +
            "                        description: '##INTEGRATIONNAME##',\n" +
            "                        json: {\n" +
            "                             message: message,\n" +
            "                        }\n" +
            "                    });\n" +
            "                    /*Start response as context*/\n" +
            "                    ##CONTEXTSEARCHPATHS##            \n" +
            "                    /*End response as context*/\n" +
            "                    /*Save response as context*/\n" +
            " \n" +
            "                    ACTION.state = 'completed';\n" +
            " \n" +
            "                 }\n" +
            " \n " +
            "                 if (ACTION.waitingForAnsver) {\n" +
            "                    if (message.type && message.type === 'timer') {\n" +
            "                        ACTION = BOT.ACTION.resetTimer(ACTION);\n" +
            "                    }\n" +
            "                 }\n" +
            "                 break;\n" +
            "        }\n" +
            "        return ACTION;\n" +
            "};\n" +
            "}(BOT, CONF));\n";

        if (this.state.responseType === "Advanced") {

            actionString = actionString.replace("##CONTEXTSEARCHPATHS##", this.state.advancedResponse);


        } else if (Object.keys(this.state.contextSearchPaths).length > 0) {
            let searchPath = "try{ \n";
            let keys = Object.keys(this.state.contextSearchPaths);
            keys.map((key) => {

                searchPath += "                    BOT.CONVERSATIONS[conversationId].context['" + key + "'] = response." + this.state.contextSearchPaths[key] + ";\n";

            });

            searchPath += "                    }catch(error){ console.log(error);}";
            actionString = actionString.replace("##CONTEXTSEARCHPATHS##", searchPath);
            // console.log(searchPath);
        } else {

            actionString = actionString.replace("##CONTEXTSEARCHPATHS##", "");
        }


        actionString = actionString.replaceAll("##INTEGRATIONNAME##", this.state.integrationName);
        actionString = actionString.replace("##MODELNAME##", this.props.accountName);

        let actionJSON = {
            "type": this.props.accountName + "." + this.state.integrationName,
            "output": [...Object.keys(this.state.contextSearchPaths)],
            "input": [...this.state.params]

        };


        let saveResponseAsContext = "/*Save response as context*/ \n " +
            "                    var objectArray = Object.entries(response);\n" +
            "                    if (objectArray && objectArray.length > 0) {\n" +
            "                        for (var i = 0; i < objectArray.length; i++) {\n" +
            "                            if (objectArray[i]) { \n" +
            "                                var key = objectArray[i][0];\n" +
            "                                BOT.CONVERSATIONS[conversationId].context[key] = objectArray[i][1];\n" +
            "                            }\n" +
            "\n" +
            "                        }\n" +
            "                    }\n";

        if (this.state.contextFromResponseChecked) {

            actionString = actionString.replace("/*Save response as context*/", saveResponseAsContext);
        } else {

            actionString = actionString.replace("/*Save response as context*/", " \n");
        }

        let paramCheckString = "";
        let paramsContextArray = [];
        this.state.params.map((param, i) => {

            let paramCheck = "BOT.CONVERSATIONS[conversationId].context." + param.trim();
            if (i === this.state.params.length - 1) {

                paramCheckString += paramCheck;
            } else {

                paramCheckString += paramCheck + " && ";
            }
            paramsContextArray.push(paramCheck);


        });

        this.setState({paramsContextArray: paramsContextArray});
        this.setState({paramCheckString: paramCheckString});
        if (paramsContextArray.length > 0) {
            actionString = actionString.replace("##PARAMETERS##", paramsContextArray.toString() + ",");
        } else {
            actionString = actionString.replace("##PARAMETERS##", "");
        }

        if (paramCheckString !== "") {
            actionString = actionString.replace("##PARAMETERSCHECK##", "if(" + paramCheckString + "){");
            actionString = actionString.replace("##PARAMETERSCHECKEND##", "}else{\n" +
                " \n" +
                "                ACTION.state = 'completed';\n" +
                "                }         \n");
        } else {
            actionString = actionString.replace("##PARAMETERSCHECK##", "");
            actionString = actionString.replace("##PARAMETERSCHECKEND##", "");
        }


        actionString = actionString.replace("##MESSAGETYPE##", this.state.integrationName);


        if (this.state.IntegrationExist) {
            let id = -1;
            this.state.actions.map((a) => {

                if (a.name === this.props.accountName + "." + this.state.integrationName) {
                    id = a.id;
                }
            });
            if (id > -1) {
                this.updateActionInDb(actionString, id);
            } else {

                this.createActionInDb(actionString, actionJSON);
            }
        } else {
            this.createActionInDb(actionString, actionJSON);
        }


        //setTimeout(this.createIntegration, 1000);
        //console.log(actionString);

    }

    /* Main method for getting all Integrations*/
    async getAllIntegrations() {

        let url = '/api/getAllIntegrations';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        let allIntegrationNames = [];
        let integrationCode = "";
        data.map((o) => {

            allIntegrationNames.push(o.name);
            if (o.name === this.props.accountName) {
                integrationCode = o.botscript;
            }

        });


        //integrationCode = integrationCode.replace(, "\n");

        let regex = /^([ \r]*\n){3,}$/gm;
        integrationCode = integrationCode.replaceAll(regex, "\n");

        let formattedCode = prettier.format(integrationCode, {
            parser: "babel",
            plugins: [parserBabel],
            semi: true,
            singleQuote: false,
            trailingComma: "es5",
            tabWidth: 3
        });

        this.setState({
            integrations: data,
            integrationCode: integrationCode,
            allIntegrationNames: allIntegrationNames
        }, () => {
            //this.getActionsFromLibrary();
            this.currentIntegrationMethods();
        });


    }

    currentIntegrationMethods() {
        let integrationCode = "";


        this.state.integrations.map((integration) => {

            if (integration.name === this.props.accountName) {
                integrationCode = integration.botscript;
            }

        });


        if (integrationCode !== "") {

            let integrationArray = [];

            var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') === -1;
            if (!is_safari) {
                let arr = Array.from(integrationCode.matchAll(/(?<=StartIntegrationName)(.*)(?=StartIntegrationName)/g), x => x[1])

                arr.map((entry) => {
                    integrationArray.push(entry);
                });
            }


            this.setState({integrationArray: integrationArray}, () => {

                if (this.state.deleteIntegrationProcess) {

                    this.reactOnClick(new Event("click"), this.state.integrationArray[0], 0);
                    this.setState({deleteIntegrationProcess: false});
                }
                this.getAccountIntegrationActions();
            });


        } else {
            //No integrations present create placeholder
            this.createIntegration("newLibrary");


            //Check that no actions are present, delete them if there is


        }

    }

    listRow() {

        if (this.state.integrationArray) {

            return this.state.integrationArray.map((object, i) => {

                return <div key={i} style={{width: "100%", maxWidth: "320px"}}>
                    <div style={{display: "inline-block", width: "90%", fontSize: "12px"}}>
                        <ListItem autoFocus={this.state.selected === i}
                                  onClick={event => this.reactOnClick(event, object, i)}
                                  classes={{root: this.props.classes.listItemTextSelected}} key={i}
                                  selected={this.state.selected === i}
                                  button>
                            <ListItemText id={i}
                                          classes={{primary: this.props.classes.listItemText}}
                                          primary={object}/>
                        </ListItem>
                    </div>
                </div>;
                ;
            });
        }
    }

    async getAllActions() {

        let url = '/api/getAllActions';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        this.setState({actions: data}, () => {
            this.formatActionArray("actions")
        });


    }


    componentDidMount() {
        this.getActionIds();
        this.getAllIntegrations();
        this.getBotConf();
        if (this.props.account) {
            this.getModels(this.props.account);
        }

        if (this.props.model) {
            this.setState({model: this.props.model});
        }

    }

    renderParams() {

        return this.state.params.map((param, i) => {
            return <li key={i}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px",
                    width: "560px"
                }}>
                    <span style={{width: "200px"}}>{param}</span><IconButton
                    onClick={(event) => this.handleDelete(event, param, i)}><Delete
                    style={{fontSize: 24}}/></IconButton>
                    <TextField style={{width: "100%"}} variant="outlined"
                               onChange={(event) => this.handleTestParamChange(event, param, i)}
                               label="Test Value"
                               value={this.state.paramTestValues[param] ? this.state.paramTestValues[param] : ""}></TextField>
                </div>
            </li>;
        })

    }

    renderHeadersAuth() {
        let keys = Object.keys(this.state.headersAuth);

        if (keys.length > 0) {
            return keys.map((param, i) => {
                return <tr key={i}>

                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                    }}>{param}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        maxWidth: "500px",
                        overflowX: "auto"
                    }}>{this.state.headersAuth[param]}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        width: "20px"
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}><IconButton
                            onClick={(event) => this.handleDeleteHeaderAuth(event, param, i)}><Delete
                            style={{fontSize: 24}}/></IconButton></div>
                    </td>

                </tr>;
            })
        }


    }

    renderHeaders() {
        let keys = Object.keys(this.state.headers);

        if (keys.length > 0) {
            return keys.map((param, i) => {
                return <tr key={i}>

                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                    }}>{param}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        maxWidth: "500px",
                        overflowX: "auto"
                    }}>{this.state.headers[param]}</td>
                    <td style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                        borderTop: "1px solid #ccc",
                        width: "20px"
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}><IconButton
                            onClick={(event) => this.handleDeleteHeader(event, param, i)}><Delete
                            style={{fontSize: 24}}/></IconButton></div>
                    </td>

                </tr>;
            })
        }


    }


    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.accountName !== this.props.accountName) {

            this.getModels(this.props.account);
        }
        if (prevProps.account !== this.props.account) {
            this.setState({account: this.props.account}, () => {
                this.getModels(this.props.account);
            });

        }

    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.addNewIntegration();
        }

    }

    render() {

        const {classes} = this.props;


        return (
            <React.Fragment>
                <Tabs value={this.state.tabValueMode} onChange={(event, value) => {
                    this.setState({tabValueMode: value});
                }}>
                    <Tab label="Form"/>
                    <Tab label="Code"/>
                </Tabs>
                <div style={{marginTop: "0px", display: "flex", flexDirection: "row", overflow: "auto"}}>

                    {this.state.tabValueMode === 0 && <React.Fragment>
                        <div style={{
                            marginTop: "10px",
                            marginLeft: "20px",
                            marginRight: "20px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <Card sx={{}}
                                  style={{marginBottom: "50px", marginTop: "0px", padding: "20px", height: "100%"}}>
                                <CardContent>
                                    <p style={{fontWeight: "bold", fontSize: "18px"}}>Integrations</p>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: "10px"
                                    }}>
                                        <Tooltip title="New Integration">
                                            <IconButton style={{background: "#3E51B5", color: "white"}}
                                                        onClick={() => {
                                                            this.setState({openAdd: true, addValue: ""})
                                                        }} aria-label="New Integration"
                                                        component="span">
                                                <AddIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete Integration">
                                            <IconButton
                                                style={{marginLeft: "20px", background: "#3E51B5", color: "white"}}
                                                onClick={() => {
                                                    this.setState({confirmDeleteOpen: true})
                                                }} aria-label="Delete Integration"
                                                component="span">
                                                <DeleteIcon/>
                                            </IconButton>

                                        </Tooltip>
                                    </div>


                                    <List
                                        style={{
                                            width: "100%",
                                            maxWidth: "330px",
                                            marginTop: "10px",
                                            minHeight: "calc(100vh - 150px)",
                                            maxHeight: "calc(100vh - 150px)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            border: "1px solid #ccc"

                                        }}
                                        component="nav"
                                        className={classes.root}
                                        id='thing'
                                    >
                                        {this.listRow()}
                                    </List></CardContent>

                            </Card>
                        </div>


                        <Card sx={{}}
                              style={{marginBottom: "50px", marginTop: "10px", padding: "20px", minWidth: "780px"}}>
                            <CardContent>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <p style={{fontWeight: "bold", fontSize: "18px"}}>General Integration Details</p>
                                    <FormControl style={{width: "600px"}}>
                                        <TextField variant="outlined" onChange={this.handleIntegrationName}
                                                   label="Integration Name"
                                                   value={this.state.integrationName}></TextField>
                                        {this.state.changeName &&
                                            <p style={{color: "red"}}>Please choose another integration name, that name
                                                already
                                                exist.</p>}

                                    </FormControl>


                                    <FormControl style={{width: "600px", marginTop: "20px"}}>
                                        <TextField variant="outlined" onChange={this.handleRestChange} label="REST URL"
                                                   value={this.state.restUrl}></TextField>
                                    </FormControl>

                                    <FormControl style={{width: "600px", marginTop: "20px"}}>
                                        <Select
                                            labelId="Authorization"
                                            id="Authorization"
                                            variant="outlined"
                                            value={this.state.authorization}
                                            label="Authorization"
                                            onChange={this.handleAuthorization}
                                        >
                                            <MenuItem value={"No Auth"}>No Auth</MenuItem>
                                            <MenuItem value={"OAuth 2.0"}>OAuth 2.0</MenuItem>
                                            <MenuItem value={"Manual"}>Manual</MenuItem>
                                            {this.renderCurrentAuthentications()}
                                        </Select>
                                    </FormControl>
                                    {this.state.authorization === "OAuth 2.0" &&

                                        <FormControl style={{width: "400px", marginTop: "20px"}}>
                                            <Select
                                                labelId="GrantType"
                                                id="type"
                                                variant="outlined"
                                                value={this.state.grantType}
                                                label="GrantType"
                                                onChange={(event) => {
                                                    this.setState({grantType: event.target.value})
                                                }}
                                            >
                                                <MenuItem value={"Password Credentials"}>Password Credentials</MenuItem>
                                                <MenuItem value={"Client Credentials"}>Client Credentials</MenuItem>
                                            </Select>
                                        </FormControl>


                                    }

                                    <FormControl style={{width: "600px", marginTop: "20px"}}>
                                        <Select
                                            labelId="Type"
                                            id="type"
                                            variant="outlined"
                                            value={this.state.type}
                                            label="Type"
                                            onChange={(event) => {
                                                this.setState({type: event.target.value})
                                            }}
                                        >
                                            <MenuItem value={"GET"}>GET</MenuItem>
                                            <MenuItem value={"POST"}>POST</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl style={{width: "600px", marginTop: "20px"}}>


                                        <p style={{fontWeight: "bold", fontSize: "18px"}}>Headers</p>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <TextField style={{width: "400px"}} variant="outlined"
                                                       onChange={this.handleInputHeaderKey}
                                                       label="Header Key"
                                                       value={this.state.headerKey}></TextField>
                                            <TextField style={{width: "340px", marginLeft: "20px"}} variant="outlined"
                                                       onChange={this.handleInputHeaderValue}
                                                       label="Header Value"
                                                       value={this.state.headerValue}></TextField>
                                            <Button style={{marginLeft: "10px"}} variant="contained" color="primary"
                                                    onClick={this.handleAddHeaders}>Add</Button>
                                        </div>
                                    </FormControl>

                                    {Object.keys(this.state.headers).length > 0 &&
                                        <div style={{marginTop: "20px"}}>
                                            <table style={{border: "0px solid #000", overflowX: "auto"}} talespacing="0"
                                                   border="0">
                                                <thead>
                                                <tr>
                                                    <th style={{
                                                        textAlign: "left",
                                                        borderLeft: "1px solid #ccc",
                                                        borderTop: "1px solid #ccc",
                                                    }}>Header Key
                                                    </th>
                                                    <th colSpan="2" style={{
                                                        textAlign: "left",
                                                        borderLeft: "1px solid #ccc",
                                                        borderRight: "1px solid #ccc",
                                                        borderTop: "1px solid #ccc",
                                                        marginLeft: "0px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        wordWrap: "break-word"
                                                    }}>Header Value
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderHeaders()}
                                                </tbody>

                                            </table>
                                        </div>
                                    }

                                    {this.state.type === "POST" &&

                                        <FormControl style={{width: "600px", marginTop: "20px"}}>
                                            <p style={{fontWeight: "bold", fontSize: "18px"}}>POST Body</p>

                                            <RadioGroup aria-label="gender" name="postType" row
                                                        value={this.state.postType}
                                                        onChange={this.handlePostTypeChange}>
                                                <FormControlLabel value="JSON" control={<Radio/>} label="JSON"/>
                                                <FormControlLabel value="Text" control={<Radio/>} label="Text"/>
                                            </RadioGroup>

                                            <TextField style={{width: "540px"}} variant="outlined"
                                                       onChange={this.handlePostParam}
                                                       label="POST BODY"
                                                       multiline={true}
                                                       minRows={10}
                                                       value={this.state.postBody}/>
                                        </FormControl>
                                    }


                                    <FormControl style={{width: "600px", marginTop: "20px"}}>
                                        <p style={{fontWeight: "bold", fontSize: "18px"}}>Input Paramaters</p>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <TextField style={{width: "540px"}} variant="outlined"
                                                       onChange={this.handleInputParams}
                                                       label="Input Paramaters"
                                                       value={this.state.paramValue}></TextField>
                                            <Button variant="contained" color="primary"
                                                    onClick={this.handleAddParameter}>Add</Button>
                                        </div>
                                    </FormControl>
                                    {this.state.params.length > 0 ?
                                        <ol>
                                            {this.renderParams()}
                                        </ol> : ""
                                    }

                                    <FormGroup row style={{marginLeft: "0px", marginTop: "10px", marginBottom: "10px"}}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.addParamsToQuery}
                                                               onChange={(event) => {
                                                                   this.setState({addParamsToQuery: event.target.checked})
                                                               }}
                                                               name="Create Context Variables from Response"/>}
                                            label="Add parameters as query parameters to url"
                                        />
                                    </FormGroup>

                                    <FormControl style={{width: "600px", marginTop: "20px"}}>

                                        <p style={{fontWeight: "bold", fontSize: "18px"}}>Response</p>
                                        <RadioGroup aria-label="responseType" name="responseType" row
                                                    value={this.state.responseType}
                                                    onChange={this.handleResponseTypeChange}>
                                            <FormControlLabel value="Basic" control={<Radio/>} label="Basic"/>
                                            <FormControlLabel value="Advanced" control={<Radio/>} label="Advanced"/>
                                        </RadioGroup>
                                        {this.state.responseType === "Basic" && <React.Fragment>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <TextField style={{width: "300px"}} variant="outlined"
                                                           onChange={this.handleContextKey}
                                                           label="Context Key Name"
                                                           value={this.state.contextKey}></TextField>
                                                <TextField style={{width: "240px", marginLeft: "20px"}}
                                                           variant="outlined"
                                                           onChange={this.handleResponseSearchPath}
                                                           label="Response Search Path"
                                                           value={this.state.responseSearchPath}></TextField>
                                                <Button style={{marginLeft: "10px"}} variant="contained" color="primary"
                                                        onClick={this.handleContextSearchPath}>Add</Button>
                                            </div>
                                        </React.Fragment>}
                                    </FormControl>
                                    {this.state.responseType === "Basic" && <React.Fragment>
                                        <FormGroup row style={{
                                            marginLeft: "20px",
                                            marginTop: "10px",
                                            marginBottom: "10px"
                                        }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.contextFromResponseChecked}
                                                                   onChange={this.handleContextResponse}
                                                                   name="Create Context Variables from Response"/>}
                                                label="Create Context Variables from Response"
                                            />
                                        </FormGroup>

                                        {Object.keys(this.state.contextSearchPaths).length > 0 &&
                                            <div style={{width: "600px", marginTop: "20px"}}>
                                                <table style={{border: "0px solid #000", width: "100%"}} talespacing="0"
                                                       border="0">
                                                    <tbody>
                                                    <tr>
                                                        <th style={{
                                                            textAlign: "left",
                                                            borderLeft: "1px solid #ccc",
                                                            borderTop: "1px solid #ccc",
                                                        }}>Context Name
                                                        </th>
                                                        <th colSpan="2" style={{
                                                            textAlign: "left",
                                                            borderLeft: "1px solid #ccc",
                                                            borderRight: "1px solid #ccc",
                                                            borderTop: "1px solid #ccc",
                                                            marginLeft: "0px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px"
                                                        }}>Response Search Path
                                                        </th>
                                                    </tr>
                                                    {this.renderContextSearchPaths()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </React.Fragment>}
                                    {this.state.responseType === "Advanced" && <React.Fragment>

                                        <Box style={{overflow: "auto", maxHeight: "300px"}}>


                                            <CodeMirror
                                                value={this.state.advancedResponse}
                                                height="200px"
                                                ref="editor"
                                                options={{
                                                    mode: 'javascript',
                                                    lineNumbers: true,
                                                    lint: true,
                                                    lineWrapping: true,
                                                    extraKeys: {
                                                        "Ctrl-Q": function (cm) {
                                                            cm.foldCode(cm.getCursor());
                                                        }
                                                    },
                                                    foldGutter: true,
                                                    theme: 'default height400 readonly',
                                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                                }}

                                                onBeforeChange={(editor, data, value) => {

                                                    this.setState({advancedResponse: value});
                                                }}
                                                onChange={(editor, data, value) => {
                                                }}
                                            />
                                        </Box>

                                    </React.Fragment>}

                                    <span style={{marginTop: "20px", width: "100%"}}>
                    <Button style={{width: "600px"}} variant="contained" color="primary"
                            onClick={this.handleTestIntegration}>Test Integration</Button>
                </span>
                                    <span style={{marginTop: "20px", width: "100%"}}>
                    <Button style={{width: "600px"}} variant="contained" color="primary"
                            onClick={this.handleCreateIntegration}>Create Integration</Button>
                </span>

                                </div>
                            </CardContent>
                        </Card>

                        {/*Third panel*/}
                        <Card sx={{}} style={{
                            marginLeft: "50px",
                            marginTop: "10px",
                            padding: "20px",
                            minWidth: "550px",
                            minHeight: "calc(100vh - 100px)",
                            maxHeight: "100%",
                            marginBottom: "50px"
                        }}>
                            <CardContent>
                                <Tabs value={this.state.tabValue} onChange={(event, value) => {
                                    this.setState({tabValue: value});
                                }}>
                                    <Tab label="Authentication"/>
                                    <Tab label="Test Result"/>
                                </Tabs>

                                {this.state.authorization === "Manual" &&
                                    <Card sx={{}}
                                          style={{
                                              marginLeft: "20px",
                                              marginBottom: "50px",
                                              marginTop: "10px",
                                              padding: "20px"
                                          }}>
                                        <CardContent>
                                            <p style={{fontWeight: "bold", fontSize: "18px"}}>Authentication: Manual</p>
                                            <div style={{display: "flex", flexDirection: "row", marginLeft: "40px"}}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleAccessTokenURL}
                                                                   label="Access Token URL"
                                                                   value={this.state.accessTokenURL}></TextField>
                                                    </FormControl>


                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <Select
                                                            labelId="Auth Request Type"
                                                            id="type"
                                                            variant="outlined"
                                                            value={this.state.authMethod}
                                                            label="Age"
                                                            onChange={(event) => {
                                                                this.setState({authMethod: event.target.value})
                                                            }}
                                                        >
                                                            <MenuItem value={"GET"}>GET</MenuItem>
                                                            <MenuItem value={"POST"}>POST</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {this.state.authMethod && this.state.authMethod === "POST" &&
                                                        <FormControl style={{width: "600px", marginTop: "20px"}}>
                                                            <TextField style={{width: "540px"}} variant="outlined"
                                                                       onChange={this.handlePostParamAuth}
                                                                       label="POST BODY"
                                                                       multiline={true}
                                                                       minRows={10}
                                                                       value={this.state.postBodyAuth}/>
                                                        </FormControl>}
                                                    <FormControl style={{width: "600px", marginTop: "20px"}}>
                                                        <p style={{fontWeight: "bold", fontSize: "18px"}}>Headers</p>
                                                        <div style={{display: "flex", flexDirection: "row"}}>
                                                            <TextField style={{width: "400px"}} variant="outlined"
                                                                       onChange={this.handleInputHeaderKeyAuth}
                                                                       label="Header Key"
                                                                       value={this.state.headerKeyAuth}></TextField>
                                                            <TextField style={{width: "340px", marginLeft: "20px"}}
                                                                       variant="outlined"
                                                                       onChange={this.handleInputHeaderValueAuth}
                                                                       label="Header Value"
                                                                       value={this.state.headerValueAuth}></TextField>
                                                            <Button style={{marginLeft: "10px"}} variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleAddHeadersAuth}>Add</Button>
                                                        </div>
                                                    </FormControl>

                                                    {Object.keys(this.state.headersAuth).length > 0 &&
                                                        <div style={{marginTop: "20px"}}>
                                                            <table style={{border: "0px solid #000", overflowX: "auto"}}
                                                                   talespacing="0"
                                                                   border="0">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{
                                                                        textAlign: "left",
                                                                        borderLeft: "1px solid #ccc",
                                                                        borderTop: "1px solid #ccc",
                                                                    }}>Header Key
                                                                    </th>
                                                                    <th colSpan="2" style={{
                                                                        textAlign: "left",
                                                                        borderLeft: "1px solid #ccc",
                                                                        borderRight: "1px solid #ccc",
                                                                        borderTop: "1px solid #ccc",
                                                                        marginLeft: "0px",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        wordWrap: "break-word"
                                                                    }}>Header Value
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.renderHeadersAuth()}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    }
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleTokenResponsePath}
                                                                   label="Token Response Path"
                                                                   value={this.state.tokenResponsePath}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>

                                                        <Button style={{width: "400px"}} variant="contained"
                                                                color="primary"
                                                                onClick={this.handleGetToken}>Get
                                                            New Access
                                                            Token</Button>
                                                    </FormControl>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",

                                                    }}>

                                                        {this.state.errorGetToken && <div style={{
                                                            width: "100%",
                                                            marginTop: "20px",
                                                            fontStyle: "bold",
                                                            color: "red"
                                                        }}>{this.state.errorGetToken}</div>}

                                                        {this.state.tokenSuccess &&
                                                            <div style={{
                                                                width: "100%",
                                                                marginTop: "20px",
                                                                marginRight: "20px",
                                                                fontStyle: "bold",
                                                                paddingRight: "20px",
                                                                wordBreak: "break-all"
                                                            }}>Token Generated Succesfully <div style={{
                                                                marginRight: "20px",
                                                                width: "100%",
                                                                marginTop: "20px",
                                                                fontStyle: "bold",
                                                                wordWrap: "wrap"
                                                            }}>
                                                                {this.state.access_token}</div></div>}

                                                    </div>
                                                </div>

                                            </div>
                                        </CardContent>
                                    </Card>
                                }


                                {(this.state.authorization === "OAuth 2.0" || this.state.authentication === "OAuth 2.0") && this.state.grantType === "Client Credentials" && this.state.tabValue === 0 &&
                                    <Card sx={{}}
                                          style={{
                                              marginLeft: "20px",
                                              marginBottom: "50px",
                                              marginTop: "10px",
                                              padding: "20px",
                                              maxWidth: "600px",
                                              minWidth: "500px"
                                          }}>
                                        <CardContent>
                                            <p style={{fontWeight: "bold", fontSize: "18px"}}>Authentication: OAuth 2.0;
                                                Client
                                                Credentials</p>
                                            <div style={{display: "flex", flexDirection: "row", marginLeft: "20px"}}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleAccessTokenURL}
                                                                   label="Access Token URL"
                                                                   value={this.state.accessTokenURL}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleClientIdChange}
                                                                   label="Client ID"
                                                                   value={this.state.clientID}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleClientSecret}
                                                                   label="Client Secret"
                                                                   value={this.state.clientSecret}></TextField>
                                                    </FormControl>

                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleScope}
                                                                   label="Scope"
                                                                   value={this.state.scope}></TextField>
                                                    </FormControl>


                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <Select
                                                            labelId="Auth Request Type"
                                                            id="type"
                                                            variant="outlined"
                                                            value={this.state.authMethod}
                                                            label="Age"
                                                            onChange={(event) => {
                                                                this.setState({authMethod: event.target.value})
                                                            }}
                                                        >
                                                            <MenuItem value={"GET"}>GET</MenuItem>
                                                            <MenuItem value={"POST"}>POST</MenuItem>
                                                        </Select>
                                                    </FormControl>


                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>

                                                        <Button style={{width: "400px"}} variant="contained"
                                                                color="primary"
                                                                onClick={this.handleGetToken}>Get
                                                            New Access
                                                            Token</Button>
                                                    </FormControl>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        maxWidth: "400px"
                                                    }}>
                                                        {this.state.errorGetToken && <div style={{
                                                            width: "100%",
                                                            marginTop: "20px",
                                                            fontStyle: "bold",
                                                            color: "red"
                                                        }}>{this.state.errorGetToken}</div>}

                                                        {this.state.tokenSuccess &&
                                                            <div style={{
                                                                width: "100%",
                                                                marginTop: "20px",
                                                                marginRight: "20px",
                                                                fontStyle: "bold",
                                                                paddingRight: "20px",
                                                                wordBreak: "break-all"
                                                            }}>Token Generated Succesfully <div style={{
                                                                marginRight: "20px",
                                                                width: "100%",
                                                                marginTop: "20px",
                                                                fontStyle: "bold",
                                                                wordWrap: "wrap"
                                                            }}>
                                                                {this.state.access_token}</div></div>}
                                                    </div>
                                                </div>

                                            </div>
                                        </CardContent>
                                    </Card>
                                }
                                {this.state.authorization === "OAuth 2.0" && this.state.grantType === "Password Credentials" &&

                                    <Card sx={{}}
                                          style={{
                                              marginLeft: "20px",
                                              marginBottom: "50px",
                                              marginTop: "10px",
                                              padding: "20px"
                                          }}>
                                        <CardContent>
                                            <p style={{fontWeight: "bold", fontSize: "18px"}}>Authentication: OAuth 2.0;
                                                Password
                                                Credentials</p>
                                            <div style={{display: "flex", flexDirection: "row", marginLeft: "40px"}}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleAccessTokenURL}
                                                                   label="Access Token URL"
                                                                   value={this.state.accessTokenURL}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleClientIdChange}
                                                                   label="Client ID"
                                                                   value={this.state.clientID}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleClientSecret}
                                                                   label="Client Secret"
                                                                   value={this.state.clientSecret}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleUsername}
                                                                   label="Username"
                                                                   value={this.state.username}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handlePassword}
                                                                   label="Password"
                                                                   type="password"
                                                                   value={this.state.password}></TextField>
                                                    </FormControl>
                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <TextField variant="outlined"
                                                                   onChange={this.handleScope}
                                                                   label="Scope"
                                                                   value={this.state.scope}></TextField>
                                                    </FormControl>

                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>
                                                        <Select
                                                            labelId="Auth Request Type"
                                                            id="type"
                                                            variant="outlined"
                                                            value={this.state.authMethod}
                                                            label="Age"
                                                            onChange={(event) => {
                                                                this.setState({authMethod: event.target.value})
                                                            }}
                                                        >
                                                            <MenuItem value={"GET"}>GET</MenuItem>
                                                            <MenuItem value={"POST"}>POST</MenuItem>
                                                        </Select>
                                                    </FormControl>


                                                    <FormControl style={{width: "400px", marginTop: "20px"}}>

                                                        <Button style={{width: "400px"}} variant="contained"
                                                                color="primary"
                                                                onClick={this.handleGetToken}>Get
                                                            New Access
                                                            Token</Button>
                                                    </FormControl>


                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",

                                                    }}>

                                                        {this.state.errorGetToken && <div style={{
                                                            width: "100%",
                                                            marginTop: "20px",
                                                            fontStyle: "bold",
                                                            color: "red"
                                                        }}>{this.state.errorGetToken}</div>}


                                                        {this.state.tokenSuccess &&
                                                            <div style={{
                                                                width: "100%",
                                                                marginTop: "20px",
                                                                marginRight: "20px",
                                                                fontStyle: "bold",
                                                                paddingRight: "20px",
                                                                wordBreak: "break-all"
                                                            }}>Token Generated Succesfully
                                                                <div style={{
                                                                    marginRight: "20px",
                                                                    width: "100%",
                                                                    marginTop: "20px",
                                                                    fontStyle: "bold",
                                                                    wordWrap: "wrap"
                                                                }}>
                                                                    {this.state.access_token}</div></div>}

                                                    </div>
                                                </div>

                                            </div>
                                        </CardContent>
                                    </Card>
                                }


                                {
                                    this.state.tabValue === 1 &&

                                    <Box sx={{}} style={{

                                        marginTop: "10px",
                                        padding: "20px",
                                        height: "100%",
                                        width: "100%",
                                        marginLeft: "0px"
                                    }}>

                                        <p style={{fontWeight: "bold", fontSize: "18px"}}>Test Result</p>
                                        <Box style={{overflow: "auto", width: "100%", minWidth: "600px"}}>
                                            <div style={{width: "max-content", minWidth: "600px"}}>
                                                <CodeMirror
                                                    value={this.state.testIntegrationResponse}
                                                    options={{
                                                        mode: 'javascript',
                                                        lineNumbers: true,
                                                        lint: true,
                                                        lineWrapping: true,
                                                        extraKeys: {
                                                            "Ctrl-Q": function (cm) {
                                                                cm.foldCode(cm.getCursor());
                                                            }
                                                        },
                                                        foldGutter: true,
                                                        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                                    }}

                                                    onBeforeChange={(editor, data, value) => {

                                                        this.setState({testIntegrationResponse: value});
                                                    }}
                                                    onChange={(editor, data, value) => {
                                                    }}
                                                />
                                            </div>
                                        </Box>

                                    </Box>
                                }
                            </CardContent>
                        </Card>
                    </React.Fragment>}


                    {this.state.tabValueMode === 1 && <div>

                        <Tabs value={this.state.tabValueCode} onChange={(event, value) => {
                            this.setState({tabValueCode: value});
                        }}>
                            <Tab label="Integration Code"/>
                            <Tab label="Action Code"/>
                        </Tabs>
                        {this.state.tabValueCode === 0 && <React.Fragment>

                            <CodeMirror
                                value={this.state.integrationCode}
                                height="800px"
                                ref="editor"
                                options={{
                                    mode: 'javascript',
                                    lineNumbers: true,
                                    lint: true,
                                    lineWrapping: true,
                                    extraKeys: {
                                        "Ctrl-Q": function (cm) {
                                            cm.foldCode(cm.getCursor());
                                        }
                                    },
                                    foldGutter: true,
                                    theme: 'default height400 readonly',
                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                }}

                                onBeforeChange={(editor, data, value) => {

                                    this.setState({integrationCode: value});
                                }}
                                onChange={(editor, data, value) => {
                                }}
                            />
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: "0px",
                                paddingTop: "10px",
                                borderTop: "1px solid #ddd",
                                paddingRight: "20px"

                            }}>
                                <Button style={{minWidth: "150pxm", width: "200px"}} color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            this.updateIntegrationLibraryInDb(this.state.integrationCode, {
                                                "name": this.props.accountName,
                                                "description": "Integrate with customer API",
                                                "example": {}
                                            })
                                        }
                                        }>Save</Button>
                            </div>
                        </React.Fragment>}

                        {this.state.tabValueCode === 1 && <React.Fragment>
                            <CodeMirror
                                value={this.state.actionScript}
                                height="800px"
                                ref="editor"
                                options={{
                                    mode: 'javascript',
                                    lineNumbers: true,
                                    lint: true,
                                    lineWrapping: true,
                                    extraKeys: {
                                        "Ctrl-Q": function (cm) {
                                            cm.foldCode(cm.getCursor());
                                        }
                                    },
                                    foldGutter: true,
                                    theme: 'default height400 readonly',
                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                }}

                                onBeforeChange={(editor, data, value) => {

                                    this.setState({actionScript: value});
                                }}
                                onChange={(editor, data, value) => {
                                }}
                            />
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: "0px",
                                paddingTop: "10px",
                                borderTop: "1px solid #ddd",
                                paddingRight: "20px"

                            }}>
                                <Button style={{minWidth: "150pxm", width: "200px"}} color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            this.saveUpdatedAction();

                                        }
                                        }>Save</Button>
                            </div>
                        </React.Fragment>}


                    </div>}
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.openAdd}
                        onClose={() => {
                            this.setState({openAdd: false});
                        }}
                    >
                        <div style={getModalStyle()} className={classes.modal}>
                            <div>
                                <Typography variant="h6" id="modal-title">
                                    Add Integration
                                </Typography>
                                <Typography variant="subtitle1" id="simple-modal-description">
                                    <TextField label="Integration Name" style={{width: "100%"}}
                                               onChange={this.updateTextField}
                                               value={this.state.addValue} onKeyDown={this.onKeyPress}/><br/><br/><br/>
                                    <br/>
                                    <Button style={{width: "100%"}} color="primary" onClick={this.addNewIntegration}
                                            variant="contained">Add</Button>
                                </Typography></div>

                        </div>
                    </Modal>
                    <Notifier changeState={() => {
                        this.setState({integrationUpdatedOpen: false});
                    }
                    } open={this.state.integrationUpdatedOpen}
                              message="Integration Library Updated Successfully"/>
                    <Notifier changeState={this.changeState} open={this.state.notifierOpen}
                              message="Integration Created Successfully"/>
                    <Notifier changeState={() => {
                        this.setState({actionUpdatedOpen: false});
                    }
                    } open={this.state.actionUpdatedOpen}
                              message="Action Updated Successfully"/>
                    <Notifier changeState={this.changeState} open={this.state.notifierChangeOpen}
                              message="Integration Updated Successfully"/>

                    <Notifier changeState={this.changeState} open={this.state.notifierDeleteOpen}
                              message={"Integration " + this.state.integrationName + " deleted"}/>

                    <Dialog
                        open={this.state.confirmDeleteOpen}
                        onClose={this.handleConfirmDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete {this.state.integrationName}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleConfirmDeleteClose} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleConfirmDeleteClose} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.confirmOverwriteIntegration}
                        onClose={this.handleConfirmOverwriteIntegration}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Overwrite"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to overwrite {this.state.integrationName}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleConfirmOverwriteIntegration} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleConfirmOverwriteIntegration} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CreateIntegration);
