import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { saveNewAction, getBotConfig } from "./reused/reusedfunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
const getModalStyle = function () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    height: "90vh",
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 4, 3),
    marginTop: "20px",
    marginBottom: "20px",
  },

  modal: {
    position: "absolute",
    width: theme.spacing(160),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: "100vh",
    outline: "none",
  },
  listItemTextSelected: {
    color: "#000000",
    fontSize: "12px",
    "&.Mui-selected": {
      backgroundColor: "#3E51B5",
      color: "#FFFFFF",
    },
  },
  listItemText: {
    paddingLeft: "20px",
    fontSize: "15px",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
    overflow: "hidden",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },
}));

const manifestTemplate = {
  name: "action.name",
  title: "Action Name",
  description: "Action Description",
  example: {
    type: "action.name",
  },
};

const myFunctionString = `(function(BOT, CONF) {
    return function(flow, ACTION, conversationId, message, runFlowStepAction) {
        switch (ACTION.state) {
            case 'activated':
                let context = BOT.CONVERSATIONS[conversationId].context;
                if (context['CONTEXTKEY']) {
                    BOT.INTEGRATIONS['INTEGRATIONNAME'].FUNCTIONNAME(context['CONTEXTKEY'], conversationId);
                    ACTION.waitingForAnsver = true;
                    ACTION.state = 'executed';
                } else {
                    ACTION.state = 'completed';
                }
                break;
            case 'executed':
                if (message && message.messageType === 'response' && message.type === 'INTEGRATIONRESPONSEMESSAGE') {
                    ACTION.waitingForAnsver = false;
                    var response = message.response;
                    BOT.LOGGER.log({
                        entity: 'ActionTemplate',
                        version: 0,
                        type: 'log',
                        conversationId: conversationId,
                        caseId: null,
                        description: 'ActionTemplate',
                        json: {
                            message: message,
                        }
                    });
                    ACTION.state = 'completed';
                }
                // Reset timer until the answer comes back from integration
                if (ACTION.waitingForAnsver) {
                    if (message.type && message.type === 'timer') {
                        ACTION = BOT.ACTION.resetTimer(ACTION);
                    }
                }
                break;
        }
        return ACTION;
    };
}(BOT, CONF));
`;

const AddAction = (props) => {
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [actionName, setActionName] = useState("");
  const classes = useStyles();
  const [library, setLibrary] = useState("");
  const [code, setCode] = useState(myFunctionString);
  const [manifest, setManifest] = useState(
    JSON.stringify(manifestTemplate, null, "\t")
  );
  const [actionSaved, setActionSaved] = useState(false);
  const [libraries, setLibraries] = useState();
  const [libraryValue, setLibraryValue] = useState(null);
  useEffect(() => {
    if (props.openModal) {
      setOpenModal(props.openModal);
    }
  }, [props.openModal]);

  const getConfig = async () => {
    const data = await getBotConfig();
    setLibraries(data?.addOnLibraries);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const handleOnClose = () => {
    setOpenModal(false);
    props.clearOpen();
  };

  const changeLibrary = (event, value) => {
    setLibraryValue(value);
  };

  const saveAction = async (event) => {
    if (actionName && libraryValue && manifest && code) {
      const status = await saveNewAction(
        actionName,
        libraryValue,
        manifest,
        code
      );
      if (status) {
        setActionSaved(actionName);
        setOpenModal(false);
        props.notify("New action: " + actionName + " successfully saved");
      }
    }
  };
  const changeManifest = (event) => {
    try {
      const parsedObject = JSON.parse(manifest);
      if (parsedObject) {
        setManifest(JSON.stringify(parsedObject, null, "\t"));
      }
    } catch (e) {
      setManifest(manifest);
    }
  };

  const handleManifestChange = (event) => {
    setManifest(event.target.value);
  };
  return (
    <Modal
      aria-labelledby="Edit-Workflow-Name"
      aria-describedby="Edit-Workflow-Name"
      open={openModal}
      onClose={handleOnClose}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginLeft: "20px",
            marginRight: "20px",
            height: "100%",
          }}
        >
          <TextField
            label="Action Name"
            style={{
              width: "100%",
              height: "60px",
            }}
            value={actionName}
            onChange={(event) => {
              setActionName(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />

          <Autocomplete
            options={libraries}
            value={libraryValue ? libraryValue : null}
            onChange={changeLibrary}
            getOptionSelected={(option, value) =>
              option && value && option.Model === value.Model
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Library" />
            )}
          />

          <TextField
            label="Manifest"
            style={{
              width: "100%",
              marginRight: "20px",
            }}
            multiline
            minRows={8}
            value={manifest}
            onChange={handleManifestChange}
            onBlur={changeManifest}
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Javascript Code"
            style={{
              width: "100%",
            }}
            multiline
            minRows={15}
            maxRows={15}
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                width: "200px",
              }}
              onClick={() => {
                setOpenModal(false);
                props.clearOpen();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "300px",
              }}
              onClick={saveAction}
            >
              Save New Action
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAction;
