import "./chart.css";
import React from "react";
const KpiCard = ({ value, value2, label }) => {
  return (
    <div className="journeys-chart-kpis__container">
      <div className="journeys-chart-kpis__label">{label}</div>
      <div className="journeys-chart-kpis__value-container">
        <div className="journeys-chart-kpis__value">{value}</div>
      </div>
      <div className="journeys-chart-kpis__secondvalue-container">
        <div className="journeys-chart-kpis__value">{value2}</div>
      </div>
    </div>
  );
};

export default KpiCard;
