import React, { Component } from "react";
import { ListItem, ListItemText, Select, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button/Button";
import ResponseOnIntent from "./ResponseOnIntent";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import { compose } from "redux";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import ChipInput from "material-ui-chip-input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
const style = {
  display: "flex",
  justifyContent: "space-around",
  paddingTop: "20px",
};

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: 200,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  button: {
    padding: "0 10px",
  },
  listItemText: {
    fontSize: "15px",
    paddingLeft: "10px",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  fab: {
    margin: theme.spacing(4),
  },

  paper: {
    padding: "10px",
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: "20px",
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  chipRoot: {
    margin: theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textOverflow: "clip",
  },
});

class VoiceOptions extends Component {
  constructor(props) {
    super(props);
    this.listRow = this.listRow.bind(this);
    this.addOption = this.addOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveWorkflow = this.saveWorkflow.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.handleResponseChange = this.handleResponseChange.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    this.handleRenderPreview = this.handleRenderPreview.bind(this);
    this.scrollOption = this.scrollOption.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleDisbleInputCheckbox = this.handleDisbleInputCheckbox.bind(this);
    this.settingsChange = this.settingsChange.bind(this);
    this.handlePredefinedChange = this.handlePredefinedChange.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.renderEntityMenuItems = this.renderEntityMenuItems.bind(this);
    this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.queryIntentResponse = this.queryIntentResponse.bind(this);
    this.getSavedModels = this.getSavedModels.bind(this);
    this.handleDefaultResponseChange =
      this.handleDefaultResponseChange.bind(this);
    this.getContext = this.getContext.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.openWorkflow = this.openWorkflow.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.state = {
      filter: "",
      expanded: "panel1",
      workFlowRender: [],
      data: [{ contains: [], onMatch: [] }],
      count: 0,
      website: "",
      model: "",
      default: "",
      pipeline: "",
      selected: [],
      newOption: false,
      renderPreview: true,
      closureMessage: "",
      fallbackCheck: false,
      items: [],
      models: [],
      showSort: false,
      entityContext: "",
      predefinedContext: "",
      textFieldValue: "",
      typeSelect: "options",
      showType: false,
      showIcon: false,
      groups: [],
      group: { id: "", name: "" },
      checked: false,
      entities: [],
      message: "",
      modelValue: null,
    };

    this.listRef = React.createRef();
  }

  handleCheckbox(e) {
    this.setState({ fallbackCheck: e.target.checked });
  }

  changeModel(event, value) {
    if (value && value.Model) {
      localStorage.setItem("model", value.Model);
      this.setState({ modelValue: value });
      this.setState({ model: value.Model });
    }
  }

  changeFallbackModel(event, value) {
    if (value && value.Model) {
      localStorage.setItem("model", value.Model);
      this.setState({ modelValue: value });
      this.setState({ model: value.Model });
    }
  }

  handleMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  handlePredefinedChange(e) {
    this.setState({ predefinedContext: e.target.value });
  }

  openWorkflow = (value) => {
    if (this.props.workflowCreate) {
      this.props.passWorkflowToOpen(value);
    } else {
      this.props.openWorkflow(value);
    }
  };

  async getContext() {
    let url = "/api/context";
    const response = await fetch(url);
    if (response) {
      const data = await response.json();

      if (data && data.name !== "error") {
        this.setState({ contextValues: Object.keys(data) });
      }

      Object.keys(data).map((object, i) => {
        if (
          this.props.value &&
          this.props.value.key &&
          object === this.props.value.key
        ) {
          this.setState({ expanded: "panel1" }, () => {
            this.setState({ predefinedContext: object });
          });
        }
      });
    }
  }

  handleDefaultResponseChange(event) {
    this.setState({ default: event, error: false });
  }

  handleDeleteChip = (chip, index, i) => {
    let chips = this.state.data;

    chips[i].contains.splice(index, 1);

    this.setState({ data: chips });
  };

  async getSavedModels() {
    let url = "/api/getSavedModels";
    const response = await fetch(url);
    const data = await response.json();

    if (data && data.length > 0) {
      let models = [];

      this.setState({ models: data });
    }
  }

  async queryIntentResponse(model, i) {
    let url = "/api/trainingDataJson?model=" + model;

    let response = await fetch(url, {
      method: "get",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).catch((error) => window.location.reload());

    let data = await response.json();
    let entities;
    if (i === 0) {
      entities = [];
    } else {
      entities = this.state.entities ? this.state.entities : [];
    }

    if (
      data.rasa_nlu_data.entity_synonyms &&
      data.rasa_nlu_data.entity_synonyms.length > 0 &&
      typeof data.rasa_nlu_data.entity_synonyms[0] === "string"
    ) {
      let entities = [...new Set(entities)];
      this.setState({
        entities: [...entities, ...data.rasa_nlu_data.entity_synonyms],
      });
    }
  }

  handleExpandChange(panelId) {
    this.setState({ expanded: panelId });
  }

  renderContextMenuItems() {
    if (this.state.contextValues) {
      return this.state.contextValues.map((value) => {
        return (
          <MenuItem key={Math.random()} value={value}>
            {value}
          </MenuItem>
        );
      });
    }
  }

  handleEntityChange(e) {
    this.setState({ entityContext: e.target.value });
  }

  renderEntityMenuItems() {
    if (this.state.entities) {
      return this.state.entities.map((value) => {
        return (
          <MenuItem key={Math.random()} value={value}>
            {value}
          </MenuItem>
        );
      });
    }
  }

  handleAddChip = (chip, i) => {
    let data = this.state.data;
    let chips = data[i].contains;

    chips.push(chip);

    this.setState({ data: data });
  };

  settingsChange = (key, flow) => {
    this.setState({ flow: flow });
    let newData = this.state.data;

    if (newData && newData[key].onMatch) {
      newData[key].onMatch = flow;

      this.setState({ data: newData }, this.setState({ edit: true }));
      this.saveWorkflow();
    }
  };

  async getSites() {
    let url = "/api/getSites";

    const response = await fetch(url);

    if (response.status !== 500) {
      const data = await response.json();

      if (data && data.length > 0 && data.name !== "error") {
        data.map((site) => {
          this.getGroups(site);
        });
      }
    }
  }

  handleRemoveOption = (event, i) => {
    let arr = this.state.data;
    let removed = arr.splice(i, 1);
    this.setState({ data: arr }, this.saveWorkflow);
  };

  componentDidMount() {
    this.getContext();
    this.getSavedModels();
    if (this.props.data) {
      console.log(this.props.data);
      this.setState({ edit: true });
      this.setState({ items: this.props.data });
    }

    if (this.props.optionsType) {
      this.setState({ typeSelect: this.props.optionsType });
    }

    if (this.props.disableInputField) {
      this.setState({ checked: this.props.disableInputField });
    }

    if (this.props.model && this.props.pipeline) {
      this.setState({ model: this.props.model });
      this.setState({ pipeline: this.props.pipeline });
    }

    if (this.props.forwardParams) {
      this.setState({ aiModel: this.props.forwardParams.model });
      this.setState({ aiPipeline: this.props.forwardParams.pipeline });
    }

    if (this.props.value) {
      //
      this.setState({
        data: this.props.value.options,
        edit: true,
        message: this.props.value.message,
        modelValue: {
          Model: this.props.value.forwardUnmatchedMessageTo.model
            ? this.props.value.forwardUnmatchedMessageTo.model
            : this.props.value.model,
        },
        model: this.props.value.forwardUnmatchedMessageTo.model
          ? this.props.value.forwardUnmatchedMessageTo.model
          : this.props.value.model,
      });

      if (
        this.props.value.forwardUnmatchedMessageTo &&
        this.props.value.forwardUnmatchedMessageTo.type &&
        this.props.value.forwardUnmatchedMessageTo.type !== "puzzelai.nlu"
      ) {
        this.setState({
          fallbackCheck: true,
          default: this.props.value.forwardUnmatchedMessageTo,
        });
      }
    } else {
      this.setState({ contextType: "manual", expanded: "panel2" });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (
      JSON.stringify(prevState.listOptions) !==
      JSON.stringify(this.state.listOptions)
    ) {
    }

    if (
      prevProps &&
      prevProps.newNameContextSwitch !== this.props.newNameContextSwitch
    ) {
      this.setState({
        selectedWorkflow: { Id: this.props.newNameContextSwitch },
      });

      let workflow = {
        type: "vergicAi.responseOnIntent",
        initiateAiWithResponse: this.props.newNameContextSwitch,
        account: this.props.account,
      };

      if (this.props.default) {
        this.handleDefaultResponseChange(workflow);
      } else {
        if (workflow.initiateAiWithResponse === "New Workflow") {
          workflow.initiateAiWithResponse = " ";
        }
        this.handleResponseChange(this.state.updatedPosition, workflow);
      }
      if (this.state.textFieldValue === "") {
        this.setState({ textFieldValue: "default" }, () => {
          this.saveWorkflow();
        });
      } else {
        this.saveWorkflow();
      }
    }
  }

  handleChange(event) {
    this.setState({ textFieldValue: event.target.value });
  }

  handleRenderPreview(e) {
    this.setState({ renderPreview: true });
  }

  handleResponseChange = (key, flow) => {
    if (flow.initiateAiWithResponse === "New Workflow") {
      this.setState({ updatedPosition: key });
      this.props.newWorkflowContextSwitch("switch");
    } //else {

    this.setState({ flow: flow });
    let newData = this.state.data;

    if (newData && newData[key].onMatch) {
      newData[key].onMatch = flow;

      this.setState({ data: newData, edit: true }, () => {
        if (this.props.newNameContextSwitch) {
          this.saveWorkflow();
        }
      });
    }
  };

  listRow() {
    return this.state.data.map((object, i) => {
      return (
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "100%",
            width: "100%",
            borderBottom: "1px solid #ccc",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "80%" }}>
              <h3>Check: {i + 1}</h3>
            </div>
            <div style={{ width: "20%" }}>
              <InputAdornment
                onClick={(event) => this.handleRemoveOption(event, i)}
                position="end"
              >
                <IconButton>
                  <Delete style={{ fontSize: 24 }} />
                </IconButton>
              </InputAdornment>
            </div>
          </div>

          <ChipInput
            value={object.contains}
            variant="outlined"
            classes={{
              root: this.props.classes.chipRoot,
              label: this.props.classes.chipLabel,
            }}
            label="Equal To"
            style={{
              width: "100%",
              marginTop: "15px",
              marginLeft: "0px",
              marginBottom: "10px",
              wordWrap: "wrap",
            }}
            onAdd={(chip) => this.handleAddChip(chip, i)}
            onDelete={(chip, index) => this.handleDeleteChip(chip, index, i)}
          />

          {this.state.data &&
          this.state.data[i] &&
          this.state.data[i].onMatch &&
          (this.state.data[i].onMatch.initiateAiWithResponse ||
            this.state.data[i].onMatch.initiateAiWithResponse === "") &&
          this.state.edit ? (
            <div style={{ padding: "0px", width: "100%" }}>
              <ResponseOnIntent
                option={true}
                onChange={this.handleResponseChange.bind(this, i)}
                value={this.state.data[i].onMatch}
                settingsChange={this.settingsChange}
                dontShowSettings={true}
                id={i.toString()}
                workflowCreate={false}
                model={this.props.model}
                pipeline={this.props.pipeline}
                openWorkflow={this.openWorkflow}
                replace={this.state.data[i].replace}
                account={this.props.account}
              />
            </div>
          ) : (
            <div></div>
          )}

          {this.state.data &&
            this.state.data[i] &&
            this.state.data[i].onMatch &&
            !this.state.data[i].onMatch.initiateAiWithResponse && (
              <div
                style={{
                  width: "100%",
                  wordBreak: "break-all",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px",
                }}
              >
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>On Match</p>

                <div style={{ overflow: "auto", marginTop: "-10px" }}>
                  <div>
                    <ResponseOnIntent
                      option={true}
                      onChange={this.handleResponseChange.bind(this, i)}
                      model={this.props.model}
                      pipeline={this.props.pipeline}
                      account={this.props.account}
                      dontShowSettings={true}
                      contextSwitch={true}
                      workflowCreate={false}
                      openWorkflow={this.openWorkflow}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    });
  }

  addOption() {
    this.state.data.push({ contains: [], onMatch: [] });
    this.setState({ render: true });
    this.setState({ showSort: false });
    setTimeout(this.scrollOption, 500);
  }

  scrollOption() {
    let curr = this.listRef.current;

    if (curr) {
      curr.scrollTop = curr.scrollHeight - (curr.clientHeight + 20);
    }
  }

  saveWorkflow() {
    let workflow = this.state.data;
    let model;
    if (this.state.model && this.state.model !== "") {
      model = this.state.model;
    } else {
      model = this.state.modelValue.value;
    }
    let fallback = {
      type: "puzzelai.nlu",
      model: model,
      sendRestResponse: true,
    };

    if (this.state.fallbackCheck && this.state.default) {
      fallback = this.state.default;
    }

    let action = {
      type: "voice.recognize",
      message: this.state.message,
      options: workflow,
      model: this.state.model,
      sendRestResponse: true,
      suppressOptions: true,
      forwardUnmatchedMessageTo: fallback,
    };

    this.props.saveWorkflow("voice.recognize", action);
  }

  removeOption() {
    let arr = this.state.data.splice(this.state.data.length - 1, 1);
    this.setState({ data: this.state.data });
  }

  handleTypeChange(e) {
    this.setState(
      { typeSelect: e.target.value },
      this.props.updateOptionsType(e.target.value)
    );
  }

  handleDisbleInputCheckbox(e) {
    this.setState(
      { checked: e.target.checked },
      this.props.updateDisableInput(e.target.checked)
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Paper
          style={{
            padding: "10px",
            paddingBottom: "5px",
            marginBottom: "10px"
          }}
        >
          <TextField
            label="(Optional) Leading Message"
            style={{ width: "100%", marginBottom: "10px", marginTop: "15px" }}
            onChange={this.handleMessageChange}
            value={this.state.message}
            variant="outlined"
          />

          <FormControl
            className={classes.formControl}
            style={{
              width: "100%",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <Autocomplete
              id="modelBox"
              options={this.state.models}
              onChange={this.changeModel}
              value={this.state.modelValue || null}
              getOptionLabel={(option) => option.Model}
              style={{ width: "100%", minWidth: "150px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Voice Model"
                  style={{}}
                />
              )}
            />
          </FormControl>

          <div
            style={{
              width: "100%",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginBottom: !this.state.fallbackCheck ? "20px" : "-30px",
            }}
          >
            <h3 style={{marginTop: "-5px", marginBottom: "-10px" }}>Fallback</h3>
            <FormGroup row style={{ marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.fallbackCheck}
                    onChange={this.handleCheckbox}
                    name="fallback"
                  />
                }
                label="Use Fallback Workflow"
              />
            </FormGroup>
            {this.state.fallbackCheck && (
              <ResponseOnIntent
                option={true}
                onChange={this.handleDefaultResponseChange.bind(this)}
                model={this.props.model}
                pipeline={this.props.pipeline}
                account={this.props.account}
                dontShowSettings={true}
                contextSwitch={true}
                value={this.state.default}
                workflowCreate={false}
                openWorkflow={this.openWorkflow}
              />
            )}
          </div>
        </Paper>
        <Paper
          style={{
            padding: "10px",                     
            height: "420px"
            

          }}
        >
          <div style={{ marginBottom: "0px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginTop: "0px" }}>
                <Tooltip title="Add Option">
                  <IconButton
                    style={{ background: "#3E51B5", color: "white" }}
                    aria-label="Add Switch"
                    onClick={this.addOption}
                    component="span"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                overflow: "auto",
                paddingBottom: "10px",
                marginBottom: "0px",   
                maxHeight: "400px"             
              }}
              ref={this.listRef}
            >
              <List
                style={{
                  minHeight: "180px",
                  height: "330px",
                  width: "100%",
                }}
                component="nav"
                className={classes.root}
                id="thingList"
              >
                {this.listRow()}
              </List>
            </div>
          </div>
          <div
            style={{
              marginTop: "-30px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {this.state.error && (
              <p
                style={{
                  marginTop: "-20px",
                  width: "280px",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {this.state.errorInfo}
              </p>
            )}
          </div>
        </Paper>

        {this.state.edit ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: "green",
              color: "white",
              fontWeight: "bold",
              marginBottom: "5 px",
              marginTop: "15px",
            }}
            onClick={this.saveWorkflow}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              backgroundColor: "green",
              color: "white",
              fontWeight: "bold",
              marginTop: "15px",
              marginBottom: "5px",
            }}
            onClick={this.saveWorkflow}
          >
            Add
          </Button>
        )}
      </div>
    );
  }
}

export default compose(withStyles(styles))(VoiceOptions);
