import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import DraggableListItem from './DraggableListItem';
import List from '@material-ui/core/List';
import {DragDropContext, Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import GroupedDraggableListItem from "./GroupedDraggableListItem";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
});

class DraggableList extends Component {

    constructor(props) {

        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.reorder = this.reorder.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.updateOrderByIndex = this.updateOrderByIndex.bind(this);
        this.state = {}
    }

    renderItems() {

        if (this.state.items) {

            return this.state.items.map((item, index) => {
                return <GroupedDraggableListItem editCheckPoint={this.props.editCheckPoint} edit={this.props.edit} addItem={this.props.addItem} item={item} index={index} key={index}/>

            });

        }
    }




    componentDidMount() {

        if (this.props.items) {
            this.setState({items: this.props.items});
        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {

        if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {

            this.setState({items: this.props.items});
        }
    }

    onDragEnd = ({destination, source}: DropResult) => {
        // dropped outsidte the list
        if (!destination) return;

        const newItems = this.reorder(this.state.items, source.index, destination.index);
        const updatedItems = this.updateOrderByIndex(newItems);

        this.props.updatedItems(updatedItems);
        //this.setState({items: updatedItems});


    };


    updateOrderByIndex(list){

        list.map((item, i)=>{

            if(!Array.isArray(item)){
                item.order = i+1;
            }else{

                item.map((it)=>{
                    it.order = i+1;
                });

            }
        });

        return list;

    }


    reorder(list, startIndex, endIndex) {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    render() {
        const {classes} = this.props;
        return (<DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <List style={{padding: "0px"}} ref={provided.innerRef} {...provided.droppableProps}>
                            {this.renderItems()}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default withStyles(styles)(DraggableList);