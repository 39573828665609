import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class GetContextFromPage extends Component {


    constructor(props) {

        super(props);

        this.pass = this.pass.bind(this);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);

        this.handleChangeSelector = this.handleChangeSelector.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            workFlowRender: null,
            selector: "",
            name: ""
        };

    }
    handleChange(event){

        this.setState({name:event.target.value});

    }
    handleChangeSelector(event){

        this.setState({selector:event.target.value});

    }

    componentDidMount() {
        if(this.props.value){

            this.setState({selector: this.props.value.selector});
            this.setState({name: this.props.value.name});


        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {


            this.setState({selector: this.props.value.selector});
            this.setState({name: this.props.value.name});


        }

    }



    renderContextMenuItems() {
        if (this.state.contextValues) {
            return this.state.contextValues.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }


    pass(){

        let workflow = {type: "website.getContextFromPage", "selector": this.state.selector, "name": this.state.name};
        this.props.passData("website.getContextFromPage", workflow);


    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop:"10px"}}>

                    <TextField
                        value={this.state.name}
                        onChange={this.handleChange}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                        label="Save in Context Variable"
                        style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", width: "100%", marginTop: "-10px"}}
                    />

                    <TextField
                        label="Selector on Webpage"
                        value={this.state.selector}
                        onChange={this.handleChangeSelector}
                        margin="normal"
                        variant="outlined"
                    />

                    {this.props.value && this.props.value.selector && this.props.value.name ? <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"  onClick={this.pass}>Update</Button>: <Button  style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"    onClick={this.pass}>Add</Button>}

                </div>
            </div>

        )
    }
}

export default withStyles(styles)(GetContextFromPage);
