///////////////////////////////////////////////////////////////////
// "Checkpoint Count": 3, måste byta namn till utan mellanslag
//Behöver index för groupName för att kunna sortera data innan renderar.
///////////////////////////////////////////////////////////////////

//1 Skapa en array av enbart datarader från unik journey.

//2 Skapa en array som innehåller alla unika checkpointgroups
// ["First checkpoint ", "Closures"]

//4 Filtrera data för den specifika checkpointgroup - Nu har du bara data för en grupp som du måste summera.

//5 Gör en for loop där du loopar all data i en checkpointgroup

//6 I första foorloopen, Skapa en array med unika checkpointNames.

//5 Skapa en array som innehåller alla unika checkpointNames.
// ["Start over", "First checkpoint", "Satisfied"]
//5 för varje checkpointgrupp. summera "hits" i en variabel och "Score" i en variabel.
//6 Skapa ett checkpointObject : {checkpoint: CheckpointName, hits: hits, score, Score}
//7 pusha checkpointObject till checkpointArray
//8 Skapa checkpointGroupObject: {groupname: groupName, checkpoints: checkpointArray}
//9 Pusha checkpointGroupObject till dataArray.
//10 Skapa ett journeyObject. {journey: "JourneyName", data: dataArray}

const dataFormatter = (data, journey) => {
  //1. Filter out rows for only specific journey
  // const startTime = Date.now();
  // console.log(`start formatting ${startTime}`);
  const journeyData = [];
  data.forEach((row) => {
    if (row.JourneyName === journey) {
      journeyData.push(row);
    }
  });
  // console.log(journeyData);
  //Define variables

  const groupNames = [];
  const dataSummary = [];
  let finishedData = [];

  //2 Filter out unique GroupNames

  journeyData.forEach((rows) => {
    if (groupNames.includes(rows.groupName)) return;
    else groupNames.push(rows.groupName);
  });
  // console.log(groupNames);
  for (let i = 0; i < groupNames.length; i++) {
    //3Filter rows for from data containing only rows from correct groupname
    let groupDataObject = {};
    const groupCheckpointsDataArray = [];

    let rows = [];
    journeyData.forEach((e) => {
      // console.log(`${i} loop`);
      if (e.groupName === groupNames[i]) rows.push(e);
      else return;
    });

    //3. Nu har jag datarader med enbart samma groupname. Nu ska jag loopa i loopen för att få fram summering av alla checkpoint i en viss group.
    //Data är nu rows.
    //4. Sortera ut checkpoints i en egen array.
    let uniqueCheckpoint = [];
    rows.forEach((row) => {
      if (uniqueCheckpoint.includes(row.CheckpointName)) return;
      else uniqueCheckpoint.push(row.CheckpointName);
    });
    // console.log(`Detta är unika checkpoints i loop ${i} : ${uniqueCheckpoint}`);

    //5. I första loopen, filtrera nu rows så att du har data enbart för group (rows) och sedan enbart rader för rätt checkpoint. (rowsCheckpoint)
    //6. Summera Hits och Score i varsin variabel

    for (let j = 0; j < uniqueCheckpoint.length; j++) {
      //För alla checkpoints, filtrera ut rader från rows som är rätt checkpoint.
      // console.log(uniqueCheckpoint[j]);
      let checkpointdata = [];
      const CheckPointName = uniqueCheckpoint[j];
      rows.forEach((row) => {
        if (row.CheckpointName === uniqueCheckpoint[j]) {
          checkpointdata.push(row);
        }
      });

      // console.log(checkpointdata);
      let checkpointHits = 0;
      let checkpointScore = 0;

      checkpointdata.forEach((row) => {
        // console.log(row.CheckpointName);
        checkpointHits = checkpointHits + row.CheckpointCount;
        checkpointScore = checkpointScore + row.Score;
      });
      // console.log(`Hits för alla rader är: ${checkpointHits}`);
      // console.log(`Score för alla rader är: ${checkpointScore}`);

      groupCheckpointsDataArray.push({
        checkpoint: CheckPointName,
        hits: checkpointHits,
        score: checkpointScore,
      });
    }
    // console.log("Detta är slutresultat");
    // console.log(groupCheckpointsDataArray);
    // console.log(rows[0].GroupIndex);
    groupDataObject = {
      groupname: groupNames[i],
      groupIndex: rows[0].GroupIndex,
      checkpoints: groupCheckpointsDataArray,
    };
    // console.log("Detta är slutresultat");
    // console.log(groupDataObject);
    dataSummary.push(groupDataObject);

  }
  finishedData = { journey: journey, data: dataSummary };
   //console.log(finishedData);

  //SORT FINISHED DATA BY GroupIndex. Within finishedData.data

  finishedData.data.sort((a, b) => {
    return parseFloat(Number(a.groupIndex)) - parseFloat(Number(b.groupIndex));
  });

  // const endTime = Date.now();
  // console.log(startTime, endTime);
  // console.log(`End formatting ${endTime - startTime}`);
  return finishedData;
};

export default dataFormatter;
