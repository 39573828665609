import React, {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import MUIDataTable from "mui-datatables";
import Box from '@material-ui/core/Box';
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import moment from "moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ModelSelect from './ModelSelect'
import {StylesProvider} from "@material-ui/core/styles";
import "../index.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {DatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },


    formControl: {
        margin: theme.spacing(4),
        minWidth: 120
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    dateFormatInput: {},
    input: {

        height: "45px"
    },
    label: {fontSize: "40px"},


});


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class ShowIntent extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Intent hits',
            keyValue: [],
            confidence: '',
            chartData: [],
            chartData2: [],
            models: [],
            model: "",
            value: 0,
            confData: [],
            date: new Date(moment().subtract(6, "days").format("YYYY-MM-DD HH:mm")),
            toDate: new Date(moment().add(1, "days").format("YYYY-MM-DD HH:mm")),

        }
        this.handleSubmit = this.handleSubmit.bind(this);

        this.createChartData = this.createChartData.bind(this);
        this.createChartData2 = this.createChartData2.bind(this);
        this.queryIntent = this.queryIntent.bind(this);
        this.updateConfTable = this.updateConfTable.bind(this);
        this.cellClick = this.cellClick.bind(this);
        this.cellClickCreateIntent = this.cellClickCreateIntent.bind(this);

        this.getSavedModels = this.getSavedModels.bind(this);
        this.changeModel = this.changeModel.bind(this);

    }

    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    /*backgroundColor: "#FFF",
                    borderRightColor: "#CCC",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid"*/

                }
            },
            MUIDataTable: {
                responsiveScroll: {
                    maxHeight: "calc(100vh - 422px)!important",
                    minHeight: "calc(100vh - 422px)",
                    height: "calc(100vh - 422px)",
                }
            }

        }
    })

    cellClickCreateIntent(cellData, cellMetaData) {
        if (cellMetaData.colIndex === 1) {
            this.props.passData(this.state.confData[cellMetaData.rowIndex][3], cellData);
            this.props.history.push('/trainingdata');
        }
    }

    changeModel(event, value) {

        if (value && value.Model) {
            localStorage.setItem("model", value.Model);
            this.setState({modelValue: value});
            this.setState({model: value.Model});
        }
    }

    async getSavedModels() {


        let url = '/api/getSavedModels';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            const model = localStorage.getItem("model");
            data.map((d, i) => {

                if(model && d.Model === model) {

                    first = d;

                }else if (i === 0) {

                    first = d;
                }
            })

            this.setState({savedModels: data, modelValue: first, model: first.Model});


        }


    }

    cellClick(celldata) {

        //selectedIntents.map( intent => console.log(intent));

        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");
        let url = '/api/confidenceSpecific?limitName=' + celldata + '&fromDate=' + dateString + '&toDate=' + toDateString + '&model=' + this.state.model;

        fetch(url)
            .then(response => {
                response.json()
                    .then(data => {


                        let rows = [];
                        for (let row of data) {
                            const {CreatedAt, Query, Confidence, Name} = row;

                            rows.push([moment(CreatedAt).format("YYYY-MM-DD hh:mm"), Query, Math.round(Confidence), Name])

                        }

                        this.setState({confData: rows});


                        this.setState({value: 1});


                    })
            })


    };

    onChangeDate = (date) => {
        //console.log(date)
        this.setState({date: date})
    }

    onChangeToDate = (date) => {

        this.setState({toDate: date})
    }

    updateConfTable(data) {


    }


    queryIntent() {

        var dateString = moment(this.state.date.toString()).format("YYYY-MM-DD");
        var toDateString = moment(this.state.toDate.toString()).format("YYYY-MM-DD");

        fetch('/api/intenthit?date=' + dateString + '&toDate=' + toDateString + "&model=" + this.state.model)
            .then(response => {


                if (response.redirected) {
                    //console.log(response);
                    window.location.href = window.location.href;

                }


                response.json()
                    .then(data => {

                        if (data && data.length > 0 && data.name !== 'error') {

                            this.setState({keyValue: data});
                            this.createChartData();
                            this.createChartData2();
                        }
                    })
            })


    }

    handleSubmit(e) {
        e.preventDefault();
        this.queryIntent();
    }

    componentDidMount() {

        /*keycloak.init().success(function(authenticated) {
            alert(authenticated ? 'authenticated' : 'not authenticated');
        }).error(function() {
            alert('failed to initialize');
        });*/

        if (this.props.model) {

            //console.log(this.props.model)
            this.setState({model: this.props.model});


        }

        if (this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }

        this.getSavedModels();
        this.queryIntent();


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.model !== this.props.model) {

//            console.log(this.props.model)
            this.setState({model: this.props.model});


        }

        if (prevProps.pipeline !== this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }
    }


    createChartData() {


        let rows = [];

        if (this.state.keyValue !== []) {

            for (let row of this.state.keyValue) {
                const {counter, name, avgconf} = row

                rows.push([counter, name, Math.round(avgconf)])

            }

            this.setState({chartData: rows});

        }

    }


    createChartData2() {
        const columns = ['Intent', 'Hits'];

        let rows = [];

        for (let row of this.state.keyValue) {
            const {counter, name} = row

            rows.push([name, eval(counter)])

        }

        var array = [columns, ...rows];


        this.setState({chartData2: array});

    }


    handleChange = (event, value) => {
        this.setState({value});
    };
    setModel = (model) => {

        this.setState({model: model});
        this.queryIntent();
    }

    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        return (


            <div style={{paddingTop: "10px", height: "100%"}}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>


                            <Paper className={classes.paper}>

                                <div style={{display: "flex", flexDirection: "row", width: "100%", marginLeft: "20px", justifyContent: "flex-start", alignItems: "center"}}>

                                    <div style={{}}>
                                        <form onSubmit={this.handleSubmit} className={classes.paperForm}>
                                            {this.state.savedModels && this.state.savedModels.length > 1 &&

                                                < FormControl style={{borderColor: "black"}}
                                                              className={classes.formControl}
                                                              style={{
                                                                  minWidth: "250px",
                                                                  minHeight: "100px",
                                                                  paddingLeft: "0px",
                                                                  paddingRight: "10px",
                                                                  marginBottom: "0px",
                                                                  marginTop: "27px",
                                                              }}>
                                                    <Autocomplete
                                                        id="modelBox"
                                                        class="autocomplete"
                                                        options={this.state.savedModels}
                                                        onChange={this.changeModel}
                                                        value={this.state.modelValue}
                                                        getOptionLabel={option => option.Model}
                                                        style={{width: "100%"}}
                                                        renderInput={params => <TextField {...params}
                                                                                          variant="outlined"
                                                                                          label="Model"
                                                                                          style={{}}/>}/>
                                                </FormControl>}

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                < FormControl style={{borderColor: "black"}}
                                                              className={classes.formControl}
                                                              style={{
                                                                  minWidth: "200px",
                                                                  minHeight: "100px",
                                                                  paddingLeft: "0px",
                                                                  paddingRight: "20px",
                                                                  marginTop: "0px",
                                                                  marginBottom: "5px",
                                                                  padding: "10px"
                                                              }}>
                                                    <DatePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        inputVariant="outlined"
                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="From Date"
                                                        style={{}}
                                                        value={this.state.date}
                                                        onChange={this.onChangeDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </FormControl>
                                                < FormControl style={{borderColor: "black"}}
                                                              className={classes.formControl}
                                                              style={{
                                                                  minWidth: "200px",
                                                                  minHeight: "100px",
                                                                  paddingLeft: "0px",
                                                                  paddingRight: "10px",
                                                                  marginTop: "0px",
                                                                  marginBottom: "5px",
                                                                  padding: "10px"
                                                              }}>
                                                    <DatePicker
                                                        disableToolbar
                                                        autoOk
                                                        ampm={false}
                                                        variant="inline"
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="date-picker-inline2"
                                                        label="To Date"
                                                        style={{marginLeft: "20px"}}
                                                        value={this.state.toDate}
                                                        onChange={this.onChangeToDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </FormControl>
                                            </MuiPickersUtilsProvider>
                                            < FormControl style={{borderColor: "black"}}
                                                          className={classes.formControl}
                                                          style={{
                                                              minWidth: "200px",
                                                              minHeight: "30px",
                                                              paddingLeft: "0px",
                                                              paddingRight: "10px",
                                                              marginTop: "30px",
                                                              marginBottom: "5px",
                                                              padding: "10px"
                                                          }}>

                                                <Button variant="contained" color="primary" type="submit">Show Intent
                                                    Hits</Button></FormControl>
                                        </form>
                                    </div>
                                </div>
                            </Paper>

                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box style={{
                                    borderBottom: 1,
                                    borderColor: 'lightgrey',
                                    borderWidth: '1px',
                                    borderBottomStyle: "solid"
                                }}>
                                    <Tabs value={value} onChange={this.handleChange}>
                                        <Tab label="Intent"/>
                                        <Tab label="Confidence hits"/>

                                    </Tabs>
                                </Box>


                                {value === 1 && <TabContainer><Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <Paper style={{height: "calc(100vh - 50px)"}}>
                                            <MUIDataTable
                                                title={"Confidence Queries"}
                                                data={this.state.confData}
                                                columns={["CreatedAt", {
                                                    name: "Query",
                                                    options: {
                                                        filter: true,
                                                        setCellProps: (value) => {
                                                            return {
                                                                className: classnames(
                                                                    {
                                                                        [classes.NameCell]: value
                                                                    })
                                                            };
                                                        }
                                                    }
                                                }, "Confidence [%]", "Name"]}
                                                options={{
                                                    filter: true,
                                                    filterType: "checkbox",
                                                    responsive: "scroll",
                                                    selectableRows: "none",
                                                    onCellClick: (colData, cellMeta) => {
                                                        this.cellClickCreateIntent(colData, cellMeta);

                                                    }


                                                }}

                                            />
                                        </Paper>
                                    </Grid>
                                </Grid> </TabContainer>
                                }
                                {value === 0 && <TabContainer> <Grid container spacing={6}>

                                    <Grid item xs={6} style={{maxHeight: "640px"}}>
                                        <Paper style={{height: "calc(100vh - 305px)"}}>
                                            <MUIDataTable
                                                title={"Intent hits"}
                                                data={this.state.chartData}
                                                columns={["Number of hits",
                                                    {
                                                        name: "Intent Name",
                                                        options: {
                                                            filter: true,
                                                            setCellProps: (value) => {
                                                                return {
                                                                    className: classnames(
                                                                        {
                                                                            [classes.NameCell]: value
                                                                        })
                                                                };
                                                            }
                                                        }
                                                    }, "Average Intent Score [%]"]}
                                                options={{
                                                    filter: true,
                                                    filterType: "checkbox",
                                                    responsive: "scroll",
                                                    selectableRows: "none",
                                                    onCellClick: (colData, cellMeta) => {
                                                        this.cellClick(colData);

                                                    }


                                                }}
                                            />
                                        </Paper>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Paper style={{
                                            maxHeight: "calc(100vh - 305px)",
                                            minHeight: "calc(100vh - 305px)"
                                        }}>

                                            <div style={{
                                                width: "100%",
                                                paddingTop: "40px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <Chart
                                                    width={'90%'}
                                                    height={'440px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={this.state.chartData2}
                                                    options={{
                                                        title: 'Intent Hits ',
                                                        chartArea: {left: 40, top: 0, width: '100%', height: '100%'},
                                                    }}
                                                    rootProps={{'data-testid': '1'}}
                                                />
                                            </div>

                                        </Paper>
                                    </Grid>
                                </Grid> </TabContainer>
                                }

                            </Paper>
                        </Grid>


                    </Grid>

                </MuiThemeProvider>
            </div>

        );
    }
}

export default withStyles(styles)(ShowIntent);
