import { Badge, Box, Button, ButtonGroup, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import React, { useEffect, useState } from "react";

const Counter = (props) => {
  const [count, setCount] = useState(1);

  useEffect(() => {

     props.currentValue(count);
  
    
  }, [count]);

  return (
    <Box>
      <ButtonGroup size="small">
        <Button
          size="small"
          aria-label="reduce"
          onClick={() => {
            setCount((prevCount) => (prevCount - 1 < 1 ? 1 : prevCount - 1));
          }}
        >
          <RemoveIcon fontSize="small" />
        </Button>
        <Button size="small" aria-label="reduce">
          {count}
        </Button>
        <Button
          size="small"
          aria-label="increase"
          onClick={() => {
            setCount((prevCount) => prevCount + 1);
          }}
        >
          <AddIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default Counter;
