import React, { useCallback, useEffect, useRef, useState } from "react";
import { getBotConfig, updateConfiguration } from "./reused/reusedfunctions";
import { Controlled as CodeMirror } from "react-codemirror2";
import Paper from "@material-ui/core/Paper";
import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "./styles/botadmin-configuration.scss";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/show-hint.css"; // without this css hints won't show
import "codemirror/addon/search/match-highlighter";
import "codemirror/addon/search/matchesonscrollbar";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/fold/markdown-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldgutter.css";
import Button from "@material-ui/core/Button";
import Notifier from "./Notifier";
const ConfigurationAdmin = (props) => {
  const [notifierMessage, setNotifierMessage] = useState("");
  const [notifierOpen, setNotifierOpen] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const codeMirrorRef = useRef(null);
  //Function get all the configuration for this bot account
  const getConfig = async () => {
    const data = await getBotConfig();
    if (data) {
      setCodeValue(JSON.stringify(data, null, "\t"));
    }
  };
  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (props.environment) {
      setCodeValue("");
      setTimeout(getConfig, 1000);
    }
  }, [props.environment]);


  const changeState = () => {
    setNotifierOpen(false);
  };

  const updateConfigurationCode = async (event) => {
    const status = await updateConfiguration(codeValue, props.environment);
    if (status) {
      setNotifierOpen(true);
      setNotifierMessage("Bot Configuration Successfully Updated");
      getConfig();
    }
  };

  return (
    <React.Fragment>
      <Paper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CodeMirror
            value={codeValue}
            ref={codeMirrorRef}
            width="100px!important"
            options={{
              mode: "application/json",
              lineNumbers: true,
              lint: true,
              lineWrapping: true,
              foldGutter: true,
              gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
              theme: 'default widthConf readonly',
            }}
            onBeforeChange={(editor, data, value) => {
              setCodeValue(value);
            }}
            onChange={(editor, data, value) => {}}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                width: "300px",
              }}
              onClick={updateConfigurationCode}
            >
              Save
            </Button>
          </div>
        </div>
      </Paper>
      <Notifier
        changeState={changeState}
        open={notifierOpen}
        message={notifierMessage}
      />
    </React.Fragment>
  );
};
export default ConfigurationAdmin;
