import React, {useCallback, useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";


const VoiceTransfer = (props) => {
    const [queues, setQueues] = useState([{description: " "}]);
    const [queue, setQueue] = useState(null);
    const [queueInit, setQueueInit] = useState(null);
    const [init, setInit] = useState(true);
    const [transferMessage, setTransferMessage] = useState("");
    useEffect(() => {


        if(init){
            setInit(false);
            puzzelUser();
        }


    }, [init, setInit, setQueueInit]);
    useEffect(() => {
        if (props.value) {

            setQueueInit(props.value.queue);
            setTransferMessage(props.value.transfermessage);
        }
    },[props.value]);



    const changeQueue = (event, value) => {

        setQueue(value);
    }

    const puzzelUser = async function () {

        let url = '/api/getPuzzelUser';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();
            getVisualQueues();
        }
    }

    useEffect(() => {

        if(queues && queueInit){

            queues.map((q)=>{

                if(q.key === queueInit){

                    setQueue(q);

                }

            })

        }

    }, [queueInit,queues]);
    const getVisualQueues = async function () {
        let url = '/api/getVisualQueues';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();


            if (data) {
                const result = JSON.parse(data).result;

                //setQueues(result);


                let queueArray = [];

                result.map((o) => {
                    queueArray.push({"key": o.key, "description": o.description});

                });

                setQueues(queueArray)

            }
        }
    }
    const pass = function () {
        if (queue) {

            let workflow = {
                "type": "voice.transferToHuman",
                "queue": queue.key,
                "queueDesc": queue.description
            };
            if(transferMessage !== ""){

                workflow['transfermessage'] = transferMessage;
                workflow['sendRestResponse'] = true;
            }
            props.passData("voice.transferToHuman", workflow);
        }
    }

    const handleMessageChange = (event)=>{

        setTransferMessage(event.target.value)

    }

    return (

        <div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>

            <TextField label="(Optional) Transfer Message" style={{width: "100%", marginBottom: "10px", marginTop: "15px"}}
                       onChange={handleMessageChange} value={transferMessage} variant="outlined"/>
            <Autocomplete
                options={queues}
                onChange={changeQueue}
                value={queue||null}
                getOptionLabel={option => option.description}
                style={{width: "100%", minWidth: "150px"}}
                renderInput={params => <TextField {...params}
                                                  variant="outlined"
                                                  label="Transfer To Queue"
                />}/>

            <Button
                style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "20px"}}
                variant="contained" color="primary"
                onClick={pass}>Update</Button>
        </div>
    );
};
export default VoiceTransfer;
