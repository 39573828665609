import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class RemoveContext extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.getContext = this.getContext.bind(this);
        this.handleExpandChange = this.handleExpandChange.bind(this);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.handlePredefinedChange = this.handlePredefinedChange.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.renderEntityMenuItems = this.renderEntityMenuItems.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            commonWorkflows: [],
            setFlow: {"Id": ""},
            notSetFlow: {"Id": ""},
            equalValue: "",
            operator: " ",
            yourChips: [],
            expanded: "panel1",
            predefinedContext: "",
            entityContext: ""


        }
        //this.convRef = React.createRef();
    }

    handleEntityChange(e) {

        this.setState({entityContext: e.target.value});
    }

    renderEntityMenuItems() {
        if (this.state.entities) {
            return this.state.entities.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }

    }

    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            });
        }
    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;


        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );


        let data = await response.json();
        let entities;
        if (i === 0) {
            entities = [];
        } else {
            entities = this.state.entities ? this.state.entities : [];
        }


        this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]})
    }

    handlePredefinedChange(e) {

        this.setState({predefinedContext: e.target.value});

    }

    renderContextMenuItems() {
        if (this.state.contextValues) {
            return this.state.contextValues.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }
    }

    handleExpandChange(panelId) {
        this.setState({expanded: panelId});
    }

    async getContext() {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {

                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (this.props.value && this.props.value.key && object === this.props.value.key) {

                    this.setState({expanded: 'panel1'}, () => {
                        this.setState({predefinedContext: object});
                    });

                }

            })
        }


    }

    componentDidMount() {

        this.getSavedModels();

        this.getContext();

        if (this.props.model) {

            this.queryIntentResponse(this.props.model);
        }

        if (this.props.value) {
            this.setState({edit: true});


            this.setState({textFieldValue: this.props.value.key});
            this.setState({equalValue: this.props.value.value});
            if (this.props.value.values) {

                this.setState({yourChips: this.props.value.values});

            }

            if (this.props.value.contextType) {

                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.key});
                    this.setState({contextType: "predefinedContext", expanded: "panel1"});

                } else if (this.props.value.contextType === "entityContext") {
                    this.setState({entities: []});
                    this.setState({entityContext: this.props.value.key});
                    this.setState({contextType: "entityContext", expanded: "panel3"});


                } else {
                    this.setState({textFieldValue: this.props.value.key});
                    this.setState({contextType: "manual", expanded: "panel2"});
                }
            }else {
                this.setState({textFieldValue: this.props.value.key});
                this.setState({contextType: "manual", expanded: "panel2"});
            }

        }


    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.props.model) {

            this.queryIntentResponse(this.props.model);
        }
        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({edit: true});


            this.setState({textFieldValue: this.props.value.key});




            if (this.props.value.contextType) {

                if (this.props.value.contextType === "predefinedContext") {

                    this.setState({predefinedContext: this.props.value.key});
                    this.setState({contextType: "entitiyContext"});

                } else if (this.props.value.contextType === "entityContext") {
                    this.setState({entityContext: this.props.value.key});
                    this.setState({contextType: "entityContext"});

                } else {
                    this.setState({textFieldValue: this.props.value.key});
                    this.setState({contextType: "manual"});
                }
            }




        }




    }



    pass() {


        let textFieldValue = this.state.textFieldValue;

        let contextType = "manual";

        if (this.state.expanded === "panel1" && this.state.predefinedContext) {

            textFieldValue = this.state.predefinedContext;
            contextType = "predefinedContext";
        }

        if (this.state.expanded === "panel3" && this.state.entityContext) {

            textFieldValue = this.state.entityContext;
            contextType = "entityContext";
        }


        let workflow = {
            type: "case.removeContextValue",
            "key": textFieldValue,
            "contextType": contextType
        };


        this.props.passData("case.removeContextValue", workflow);


    }




    handleChange(e) {

        this.setState({textFieldValue: e.target.value});

    }

    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>

                <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                    this.handleExpandChange('panel1')
                }} style={{marginTop: "5px", marginBottom: "0px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Predefined Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                     className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                label="Predefined Context"
                                select
                                value={this.state.predefinedContext}
                                classes={{root: classes.select}}
                                onChange={this.handlePredefinedChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>


                                {this.renderContextMenuItems()}

                            </TextField>
                        </FormControl>

                    </AccordionDetails>
                </Accordion>


                <Accordion expanded={this.state.expanded === 'panel2'} onClick={event => {
                    this.handleExpandChange('panel2')
                }} style={{marginTop: "5px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}>

                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Manual Input Context Variable</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <TextField
                            value={this.state.textFieldValue}
                            onChange={this.handleChange}
                            margin="normal"
                            multiline={false}
                            variant="outlined"
                            label="Context Variable"
                            style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", width: "100%", marginTop: "-10px"}}
                        />

                    </AccordionDetails>
                </Accordion>


                {this.state.entities &&
                    <Accordion expanded={this.state.expanded === 'panel3'} onClick={event => {
                        this.handleExpandChange('panel3')
                    }} style={{marginTop: "5px"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row"
                            }}>

                                <div style={{marginLeft: "0px", fontWeight: "bold"}}>Entity Context Variable</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                            <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                         className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    label="Entity Context Variable"
                                    select
                                    value={this.state.entityContext}
                                    classes={{root: classes.select}}
                                    onChange={this.handleEntityChange}
                                >
                                    {this.renderEntityMenuItems()}


                                </TextField>
                            </FormControl>

                        </AccordionDetails>
                    </Accordion>}


                <div
                    style={{marginTop: this.state.edit && this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ? "10px" : "30px"}}>
                    {this.props.value && this.props.value.message ?
                        <Button
                            style={{
                                width: "100%",
                                backgroundColor: "green",
                                color: "white",
                                fontWeight: "bold"
                            }}
                            variant="contained"
                            onClick={this.pass}>Update</Button> :
                        <Button
                            style={{
                                width: "100%",
                                backgroundColor: "green",
                                color: "white",
                                fontWeight: "bold"
                            }}
                            variant="contained"
                            onClick={this.pass}>Add</Button>}

                </div>

            </div>


        )
    }

}

export default withStyles(styles)(RemoveContext);
