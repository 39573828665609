import React, {Component, createRef} from "react";
import {withStyles} from '@material-ui/core/styles';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import Notifier from "./Notifier";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import WorkflowComponents from "./WorkflowComponents";
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from "@material-ui/core/FormControl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import WorkflowOverview from "./WorkflowOverview";
import StarIcon from '@material-ui/icons/Star';
import PublicIcon from '@material-ui/icons/Public';
import Settings from "@material-ui/icons/Settings";
import Fab from "@material-ui/core/Fab";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChipInput from 'material-ui-chip-input'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Chip from '@material-ui/core/Chip';
import moment from "moment";
import FlowOverview from "./FlowOverview";
import { getDomains } from "./reused/reusedfunctions.js";

const options = {
    filter: true,
    filterType: "checkbox",
    responsive: "scroll",
    selectableRows: "none",
    onCellClick: (colData, cellMeta) => {
        this.cellClick(colData);
    }
};


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const drawerWidth = 340;


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    drawer: {
        marginLeft: "500px",
        flexShrink: 0,
    },
    paperBar: {
        width: `calc(100% - ${drawerWidth}px)`,

    },
    listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },
    filter: {

        background: "#fff",
        marginTop: "0px"
    },
    listItemText: {
        fontSize: '15px'
    },
    chips: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    toggleButton: {
        height: "25px"

    }


});

let flowPoints = [];
let allNames = [];
let multiLinks = [];
const dataSelect = [{"pipline": "se"}, {"pipline": "en"}];
let double = false;

class ResponseWorkflow extends Component {


    constructor(props) {
        super(props);

        this.divRef = React.createRef();
        this.filterRef = React.createRef();
        this.chipInputRef = React.createRef();
        this.state = {
            newCategory: '',
            overviewNew: false,
            savedPipelines: [],
            newModel: '',
            saveAsName: '',
            filter: '',
            model: '',
            notifierTrainOpen: false,
            open: true,
            pipeline: "se",
            loading: false,
            responseId: "",
            labelWidth: 0,
            response: JSON.stringify([{"type": "Start"}]),
            selectedWorkflow: "",
            confirmDeleteOpen: false,
            workflowCreate: true,
            newWorkflow: "",
            previousWorkflow: "",
            workflowsCreated: [],
            multipleLinks: [],
            openModal: false,
            openEditModal: false,
            openSaveAsModal: false,
            newWorkflowName: "",
            complete: [],
            linkedBy: [],
            overview: false,
            commonWorkflows: [],
            directLink: [],
            checked: true,
            removeChecked: true,
            yourChips: [],
            category: "",
            autoCategory: "",
            breadcrumbs: [],
            categoryArray: [],
            categoryArrayLevel2: [],
            showPreFilter: false,
            searchType: "&",
            listHeight: "65%",
            toggleButtonTop: "69px",
            voicebot: false

        }

        this.postToKv = this.postToKv.bind(this);
        this.resetNewTrainingData = this.resetNewTrainingData.bind(this);
        this.saveResponseToDb = this.saveResponseToDb.bind(this);
        this.changeState = this.changeState.bind(this);
        this.handleWorkflowChange = this.handleWorkflowChange.bind(this);
        this.saveModel = this.saveModel.bind(this);
        this.setModel = this.setModel.bind(this);
        this.setPipeline = this.setPipeline.bind(this);
        this.handleResponseIdChange = this.handleResponseIdChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.updateResponseFromWorkflow = this.updateResponseFromWorkflow.bind(this);
        this.listRow = this.listRow.bind(this);
        this.getWorkflowResponseFromDb = this.getWorkflowResponseFromDb.bind(this);
        this.updateResponseToDb = this.updateResponseToDb.bind(this);
        this.deleteResponseFromDb = this.deleteResponseFromDb.bind(this);
        this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this);
        this.deleteWorkflow = this.deleteWorkflow.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.renderList = this.renderList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.newWorkflow = this.newWorkflow.bind(this);
        this.setNewWorkflow = this.setNewWorkflow.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.createCompleteWorkflow = this.createCompleteWorkflow.bind(this);
        this.createComplete = this.createComplete.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.openPassedWorkflow = this.openPassedWorkflow.bind(this);
        this.newFromResponseOnIntent = this.newFromResponseOnIntent.bind(this);
        this.resetAdd = this.resetAdd.bind(this);
        this.editWorkflowName = this.editWorkflowName.bind(this);
        this.saveWorkflowAs = this.saveWorkflowAs.bind(this);
        this.changeWorkflowName = this.changeWorkflowName.bind(this);
        this.changeSaveAsName = this.changeSaveAsName.bind(this);
        this.createFlowComplete = this.createFlowComplete.bind(this);
        this.findObjectReturnDepth = this.findObjectReturnDepth.bind(this);
        this.setLevel = this.setLevel.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.addDirectLinks = this.addDirectLinks.bind(this);
        this.handleShowSettings = this.handleShowSettings.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.filterArray = this.filterArray.bind(this);
        this.getWorkflowsToUpdate = this.getWorkflowsToUpdate.bind(this);
        this.updateWorkflowValue = this.updateWorkflowValue.bind(this);
        this.getWorkflowsToUpdateFromOptions = this.getWorkflowsToUpdateFromOptions.bind(this);
        this.getWorkflowsToUpdateFromOptionsIntent = this.getWorkflowsToUpdateFromOptionsIntent.bind(this);
        this.getWorkflowsToUpdateIntent = this.getWorkflowsToUpdateIntent.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this);
        this.handleAutoCompCategoryChange = this.handleAutoCompCategoryChange.bind(this);
        this.addNewWorkflow = this.addNewWorkflow.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.addToBreadCrumbs = this.addToBreadCrumbs.bind(this);
        this.removeUnvantedChars = this.removeUnvantedChars.bind(this);
        this.getIndexOfWorkflow = this.getIndexOfWorkflow.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.getWorkflowsFromOptionsIntent = this.getWorkflowsFromOptionsIntent.bind(this);
        this.getWorkflowsFromWorkflow = this.getWorkflowsFromWorkflow.bind(this);
        this.getWorkflowsFromIntents = this.getWorkflowsFromIntents.bind(this);
        this.removeWorflowOccurences = this.removeWorflowOccurences.bind(this);
        this.getResponse = this.getResponse.bind(this);
        this.updateIntentResponseToDb = this.updateIntentResponseToDb.bind(this);
        this.handleRemoveCheckbox = this.handleRemoveCheckbox.bind(this);
        this.handleChipClick = this.handleChipClick.bind(this);
        this.newWorkflowFromCheckIfContextIsSet = this.newWorkflowFromCheckIfContextIsSet.bind(this);
        this.newFromCheckIfContextIsSet = this.newFromCheckIfContextIsSet.bind(this);
        this.renderChips = this.renderChips.bind(this);
        this.handlePrefilter = this.handlePrefilter.bind(this);
        this.handleSearchType = this.handleSearchType.bind(this);
        this.filterArraySome = this.filterArraySome.bind(this);
        this.updateListRender = this.updateListRender.bind(this);
        this.newWorkflowFromContextSwitch = this.newWorkflowFromContextSwitch.bind(this);
        this.newFromContextSwitch = this.newFromContextSwitch.bind(this);
        this.getR4Token = this.getR4Token.bind(this);
        this.newFromEntitySwitch = this.newFromEntitySwitch.bind(this);
        this.overViewClickHandler = this.overViewClickHandler.bind(this);
        this.createMapFromArray = this.createMapFromArray.bind(this);
        this.updateIntroMapping = this.updateIntroMapping.bind(this);
        this.entitySwitchesToUpdate = this.entitySwitchesToUpdate.bind(this);
        this.entitySwitchesIntentsToUpdate = this.entitySwitchesIntentsToUpdate.bind(this);
        this.contextSwitchesIntentsToUpdate = this.contextSwitchesIntentsToUpdate.bind(this);
        this.getConf = this.getConf.bind(this);
    }
    async getConf(){

        const domains = await getDomains();

        this.setState({domains: domains?domains: null});
    }
    //When changing the name of an intro flow change the mapping also
    updateIntroMapping(selectedWorkflow, newName) {

        let settings = this.state.settings;

        if (settings.CaseTypeMapping) {
            settings.CaseTypeMapping.map((mapping) => {
                if (mapping.flowName === selectedWorkflow) {
                    mapping.flowName = newName;
                }
            });
        }
        this.setState({settings: settings}, async () => {
            const update = await this.updateSettings();
            const sett = await this.getSettings();
            let mappedFlows = [];
            settings.CaseTypeMapping.map((object) => {

                mappedFlows.push(object.flowName);

            });
            this.setState({mappedFlows: mappedFlows});
        })

    }

    overViewClickHandler(event) {

        if (this.state.overviewNew) {

            this.setState({overviewNew: false});
        } else {
            this.setState({overviewNew: true});
        }

    }

    async getR4Token() {
        let url = '/api/getR4Token';

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.access_token) {

            this.setState({token: data.access_token});


        }
    }

    newWorkflowFromEntitySwitch() {
        this.setState({openModal: true});
    }

    newWorkflowFromContextSwitch() {

        this.setState({openModal: true});

    }

    updateListRender(initialLoad) {
        let timer = 0;
        if (initialLoad) {

            timer = 2000;
        }
        setTimeout(() => {

            if (this.chipInputRef) {
                const filterHeight = this.chipInputRef.clientHeight;
                this.setState({prefilterWidth: this.divRef.clientWidth});
                this.setState({"showPrefilter": true}, () => {
                    const evalHeight = eval(this.filterRef.clientHeight + 40 + filterHeight);
                    const toggleButtonTop = eval(3 + filterHeight) + "px";
                    let listHeight = "calc(100% - " + evalHeight + "px)";

                    this.setState({listHeight: listHeight, toggleButtonTop: toggleButtonTop});
                });
            }
        }, timer);
    }

    handleSearchType = (e, value) => {
        if (!value) {
            value = "&";
        }
        this.setState({searchType: value});
        localStorage.setItem("searchType", value);
    }

    handlePrefilter(e) {

        if (this.state.showPrefilter) {

            this.setState({"showPrefilter": false});
        } else {
            this.updateListRender();

        }

    }

    createMapFromArray(arr) {
        const map = new Map();

        for (let i = 0; i < arr.length; i++) {
            map.set(arr[i], i);
        }

        return map;
    }

    renderChips() {
        if (this.state.categoryArray) {
            let categoryArray = this.state.categoryArray;
            let categories = Object.keys(this.state.categoryArray);

            if (this.state.multiSite) {

                let categoriesLevel2 = Object.keys(this.state.categoryArrayLevel2);
                categories = [...categories.sort(), ...categoriesLevel2.sort()];

                let categoryArrayLevel2 = this.state.categoryArrayLevel2;
                categoryArray = {...categoryArray, ...categoryArrayLevel2};

            } else {
                categories.sort();
            }


            const uniqueCat = this.createMapFromArray(categories);

            categories = Array.from(uniqueCat.keys());

            return categories.map((cat) => {

                if (categoryArray[cat] > 3) {

                    return <Chip key={Math.random()} label={cat} variant="outlined"
                                 onClick={(event) => this.handleChipClick(event, cat)}/>

                }

            });
        }


    }

    handleChipClick = (e, name) => {


        let introFlows = [];
        if (this.state.mappedFlows) {
            this.state.mappedFlows.map((flow) => {

                introFlows.push(flow);

            });
        }

        let chips = this.state.yourChips ? this.state.yourChips : [];
        if (chips) {
            if (name === "Intro") {

                this.setState({introFlows: introFlows});
            }
            chips.push(name);
        }
        this.setState({commonWorkflows: this.state.commonWorkflows, yourChips: chips}, () => {
            this.updateListRender();
        });

        localStorage.setItem("savedFilters", JSON.stringify(chips));
        localStorage.setItem("searchType", this.state.searchType);


    }

    onVisibilityChange() {

        this.setState({"visible": document.visibilityState});
    }

    handleClick(e) {

        // console.info('You clicked a breadcrumb.');
    }

    handleAutoCompCategoryChange(event, value) {

        if (value && value.Id) {

            this.setState({autoCategory: value, category: value.Id});

        } else {

            this.setState({autoCategory: null, category: null});
        }

    }


    async getWorkflowsToUpdateFromOptions(workflow) {

        let url = '/api/getWorkflowsFromOptionsToUpdate?workflowNameToUpdate=' + workflow;
        const response = await fetch(url);

        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {
            let newData = [];
            data.map((dataObj) => {
                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);
                workflowArray.map((object) => {
                    if (object.type.indexOf("website.options") > -1) {
                        object.options.map((option) => {

                            if (option.onMatch && option.onMatch.type && option.onMatch.type.indexOf("vergicAi.responseOnIntent") > -1 && option.onMatch.initiateAiWithResponse === workflow) {

                                option.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        });


                    }

                });

                dataObj.Value = JSON.stringify(workflowArray);
                newData.push({"Id": workflowId, "Value": dataObj.Value})
            });

            newData.map((o) => {
                this.updateWorkflowValue(o.Value, o.Id);
            })
        }
    }

    async getWorkflowsToUpdateFromOptionsIntent(workflow) {

        let url = '/api/getWorkflowsToUpdateOptionsFromIntent?workflowNameToUpdate=' + workflow;
        const response = await fetch(url);

        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {
            let newData = [];
            data.map((dataObj) => {

                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);

                let meta = workflowArray.metaData.workflow;
                meta = JSON.parse(meta);

                meta.map((object) => {
                    if (object.type.indexOf("website.options") > -1) {
                        object.options.map((option) => {

                            if (option.onMatch && option.onMatch.type && option.onMatch.type.indexOf("vergicAi.responseOnIntent") > -1 && option.onMatch.initiateAiWithResponse === workflow) {

                                option.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        });


                    }

                });

                //dataObj.Value = meta;
                let workflowName = workflowId.split("_");

                if (workflowName.length === 3) {

                    let intentObj = {
                        "Id": workflowId,
                        "Value": {
                            "intent": workflowName[workflowName.length - 1],
                            "metaData": {"workflow": JSON.stringify(meta)}
                        }
                    };
                    newData.push(intentObj);


                }
            });


            newData.map((o) => {
                this.updateWorkflowValue(JSON.stringify(o.Value), o.Id);
            });
        }
    }

    async getWorkflowsToUpdateIntent(workflow) {


        let url = '/api/getWorkflowsToUpdateFromIntent?workflowNameToUpdate=' + workflow;
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let newData = [];
            data.map((dataObj) => {
                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);

                let meta = workflowArray.metaData.workflow;
                meta = JSON.parse(meta);
                meta.map((object) => {

                        if (object.type.indexOf("responseOnIntent") > -1 && object.initiateAiWithResponse === workflow) {
                            object.initiateAiWithResponse = this.state.newWorkflowName;
                        }
                    }
                );


                let workflowName = workflowId.split("_");

                if (workflowName.length === 3) {

                    let intentObj = {
                        "Id": workflowId,
                        "Value": {
                            "intent": workflowName[workflowName.length - 1],
                            "metaData": {"workflow": JSON.stringify(meta)}
                        }
                    };
                    newData.push(intentObj);

                }

            });

            newData.map((o) => {
                this.updateWorkflowValue(JSON.stringify(o.Value), o.Id);
            })


        }
    }

    async entitySwitchesIntentsToUpdate(workflow){

        let url = '/api/getIntentsFromEntitySwitch?account=' + this.props.account + '&workflow=' + workflow;
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0) {

            let intentName;
            data.map((dataObj) => {

                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);

                let meta = workflowArray.metaData.workflow;
                intentName = workflowArray?.intent;
                meta = JSON.parse(meta);

                meta.map((object) => {
                        if (object.type === "case.entitySwitch") {

                            object.switches.map((swi) => {
                                if (swi.onMatch.initiateAiWithResponse === workflow) {
                                    swi.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                                }
                            });

                            if (object.default && object.default.initiateAiWithResponse === workflow) {
                                object.default.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        }

                    }
                );

                workflowArray.metaData.workflow = JSON.stringify(meta);
                this.updateWorkflowValue(JSON.stringify(workflowArray), workflowId);

            });

        }
    }


    async contextSwitchesIntentsToUpdate(workflow){

        let url = '/api/getIntentsFromContextSwitch?account=' + this.props.account + '&workflow=' + workflow;
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0) {

            let intentName;
            data.map((dataObj) => {

                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);

                let meta = workflowArray.metaData.workflow;
                intentName = workflowArray?.intent;
                meta = JSON.parse(meta);

                meta.map((object) => {
                        if (object.type === "case.contextSwitch") {

                            object.switches.map((swi) => {
                                if (swi.onMatch.initiateAiWithResponse === workflow) {
                                    swi.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                                }
                            });

                            if (object.default && object.default.initiateAiWithResponse === workflow) {
                                object.default.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        }

                    }
                );

                workflowArray.metaData.workflow = JSON.stringify(meta);
                this.updateWorkflowValue(JSON.stringify(workflowArray), workflowId);

            });

        }
    }

    async entitySwitchesToUpdate(workflow) {

        let url = '/api/getWorkflowsFromEntitySwitch?account=' + this.props.account + '&workflow=' + workflow;
        const response = await fetch(url);

        const data = await response.json();
        if (data && data.length > 0) {
            let newData = [];
            data.map((dataObj) => {
                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);
                workflowArray.map((object) => {

                        if (object.type === "case.entitySwitch") {

                            object.switches.map((swi) => {
                                if (swi.onMatch.initiateAiWithResponse === workflow) {
                                    swi.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                                }
                            });

                            if (object.default && object.default.initiateAiWithResponse === workflow) {
                                object.default.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        }

                    }
                );
                dataObj.Value = JSON.stringify(workflowArray);
                newData.push({"Id": workflowId, "Value": dataObj.Value})

            });

            newData.map((o) => {
                this.updateWorkflowValue(o.Value, o.Id);
            })

        }
    }

    async contextSwitchesToUpdate(workflow) {

        let url = '/api/getWorkflowsFromContextSwitch?account=' + this.props.account + '&workflow=' + workflow;
        const response = await fetch(url);

        const data = await response.json();
        if (data && data.length > 0) {
            let newData = [];
            data.map((dataObj) => {
                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);
                workflowArray.map((object) => {

                        if (object.type === "case.contextSwitch") {

                            object.switches.map((swi) => {
                                if (swi.onMatch.initiateAiWithResponse === workflow) {
                                    swi.onMatch.initiateAiWithResponse = this.state.newWorkflowName;
                                }
                            });

                            if (object.default && object.default.initiateAiWithResponse === workflow) {
                                object.default.initiateAiWithResponse = this.state.newWorkflowName;
                            }
                        }

                    }
                );
                dataObj.Value = JSON.stringify(workflowArray);
                newData.push({"Id": workflowId, "Value": dataObj.Value})

            });

            newData.map((o) => {
                this.updateWorkflowValue(o.Value, o.Id);
            })

        }
    }

    async getWorkflowsToUpdate(workflow) {


        let url = '/api/getWorkflowsToUpdate?workflowNameToUpdate=' + workflow;
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let newData = [];
            data.map((dataObj) => {
                let workflowId = dataObj.Id;
                let workflowArray = JSON.parse(dataObj.Value);
                workflowArray.map((object) => {

                        if (object.type.indexOf("responseOnIntent") > -1 && object.initiateAiWithResponse === workflow) {
                            object.initiateAiWithResponse = this.state.newWorkflowName;
                        }
                    }
                );
                dataObj.Value = JSON.stringify(workflowArray);
                newData.push({"Id": workflowId, "Value": dataObj.Value})

            });

            newData.map((o) => {
                this.updateWorkflowValue(o.Value, o.Id);
            })


        }
    }

    async updateWorkflowValue(newValue, workflowId) {


        let url = '/api/updateWorkflowValue?workflowId=' + workflowId;


        const promises = [];
        promises.push(this.getR4Token());
        Promise.all(promises).then(() => {
            this.postToKv(workflowId, newValue);
        });

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: newValue
        });

        const data = await response.json();


    }


    handleAddChip = (chip) => {

        let chips = this.state.yourChips;
        chips.push(chip);

        this.setState({yourChips: chips}, () => {
            this.updateListRender();
        })

    }

    handleDeleteChip = (chip, index) => {


        let chips = this.state.yourChips;

        chips.splice(index, 1);


        this.setState({yourChips: chips}, () => {
            this.updateListRender();
        });

        localStorage.setItem("savedFilters", JSON.stringify(chips));
        localStorage.setItem("searchType", this.state.searchType);
    }

    handleCheckbox(e) {

        this.setState({checked: e.target.checked});

    }

    handleRemoveCheckbox(e) {

        this.setState({removeChecked: e.target.checked});

    }


    handleShowSettings(e) {

        if (this.state.showSettings) {
            this.setState({showSettings: false});
        } else {

            this.setState({showSettings: true});
        }

    }

    changeWorkflowName(e) {
    
        let editName = this.removeUnvantedChars(e.target.value);
        
        this.setState({newWorkflowName: editName});

    }

    changeSaveAsName(e) {

        this.setState({saveAsName: e.target.value});
    }

    resetAdd() {

        this.setState({backToAdd: false});

    }

    componentDidMount() {
        window.addEventListener("visibilitychange", this.onVisibilityChange);
        this.getR4Token();
        this.getConf();
        if (this.props.model) {
            
            this.setState({model: this.props.model})

        }

        if (this.props.openOverview) {


            this.setState({overviewNew: true, intentOverview: this.props.openOverview})

        }

        if (localStorage.getItem("savedFilters")) {
            //Read the saved filters from localStorage

            this.setState({yourChips: JSON.parse(localStorage.getItem("savedFilters"))});
            this.setState({searchType: localStorage.getItem("searchType")});

        }

        if (this.props.pipeline) {

            this.setState({pipeline: this.props.pipeline});

        }
        if (this.props.workflowToOpen) {

            this.addToBreadCrumbs(this.props.workflowToOpen)
        }
        setTimeout(this.getSavedModels, 1000);

        setTimeout(this.getAllCommonWorkflows, 1000);
        /* this.setState({
             labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
         });*/

        this.getSettings();


    }

    addToBreadCrumbs(workflowName, category) {
        let breadcrumbs = this.state.breadcrumbs;

        if (category && category !== "") {
            breadcrumbs.push(workflowName + ":" + category);
        } else {
            breadcrumbs.push(workflowName);

        }
        if (this.state.breadcrumbs.length > 6) {
            this.setState({breadcrumbs: breadcrumbs.slice(breadcrumbs.length - 6, breadcrumbs.length)});
        } else {

            this.setState({breadcrumbs: breadcrumbs});
        }


    }

    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );
        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let workflowArray = [];
            let categoryArray = {};
            let categoryArrayLevel2 = {};
            data.map((object) => {

                let arraySplit = object.Id.split("_");
                let name = arraySplit[arraySplit.length - 1];
                let nameSplit = name.split(" ");
                if (nameSplit.length > 1) {

                    if (nameSplit[0].length > 2) {

                        if (categoryArray[nameSplit[0]]) {
                            categoryArray[nameSplit[0]] += 1;
                        } else {
                            categoryArray[nameSplit[0]] = 1;
                        }
                    }

                    if (nameSplit.length >= 2 && this.state.multiSite && nameSplit[1].length > 2) {
                        if (categoryArrayLevel2[nameSplit[1]]) {
                            categoryArrayLevel2[nameSplit[1]] += 1;
                        } else {
                            categoryArrayLevel2[nameSplit[1]] = 1;
                        }
                    }

                } else {
                    nameSplit = name.split("-");
                    if (nameSplit.length > 1) {
                        if (nameSplit[0].length > 2) {
                            if (categoryArray[nameSplit[0]]) {
                                categoryArray[nameSplit[0]] += 1;
                            } else {
                                categoryArray[nameSplit[0]] = 1;
                            }
                        }

                        if (nameSplit.length >= 2 && this.state.multiSite && nameSplit[1].length > 2) {
                            if (categoryArrayLevel2[nameSplit[1]]) {
                                categoryArrayLevel2[nameSplit[1]] += 1;
                            } else {
                                categoryArrayLevel2[nameSplit[1]] = 1;
                            }
                        }
                    }
                }
                if (arraySplit[arraySplit.length - 1]) {
                    if (arraySplit.length > 3) {

                        workflowArray.push({
                            "Id": arraySplit[arraySplit.length - 1],
                            "Category": arraySplit[arraySplit.length - 2]
                        });
                    } else {
                        workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                    }

                }

            });


            this.setState({categoryArray: categoryArray, categoryArrayLevel2: categoryArrayLevel2});


            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })
            

            this.setState({commonWorkflows: workflowArray});
            if (this.props.workflowToOpen) {
                this.setState({selectedIndex: this.getIndexOfWorkflow(this.props.workflowToOpen)});
                
                let workflowToOpen = this.props.workflowToOpen;
                /* let workflowToOpenSplit = this.props.workflowToOpen.split(":");
                 if (workflowToOpenSplit.length > 1) {
                     workflowToOpen = {};
                     workflowToOpen.Id = workflowToOpenSplit[0].trim();
                     workflowToOpen.Category = workflowToOpenSplit[1].trim();
                     this.state.category = workflowToOpenSplit[1].trim();
                     this.setState({selectedWorkflow: workflowToOpenSplit[0].trim()});

                 } else {*/
                this.setState({selectedWorkflow: workflowToOpen});
                //}

                this.getWorkflowResponseFromDb(workflowToOpen, "standard");
            }
            this.getAllCategories();

        }


    }

    async updateSettings() {

        let url = '/api/updateSettings';

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.settings)
        });

        const data = await response.json();

        this.postToKv(this.props.account + '_account_settings', JSON.stringify(this.state.settings));

    }

    async getSettings() {


        let url = '/api/getSettings';

        const response = await fetch(url);

        if (response.status === 302) {

            window.location.reload();

        }


        const data = await response.json();

        if (data && data.length > 0) {
            try {
                if (data[0].Value) {
                    const sett = data[0].Value;

                    const settings = JSON.parse(sett);

                    this.setState({confidenceThreshold: settings.ConfidenceThreshold});

                    this.setState({fallbackFlow: settings.FallbackFlow});

                    if(settings.voicebot){

                        this.setState({voicebot: true});
                    }

                    this.setState({mappedCaseFlows: settings.CaseTypeMapping});
                    this.setState({multiSite: settings.MultiSite});
                    this.setState({filterVisibleByDefault: settings.FilterVisibleByDefault});
                    if (settings.FilterVisibleByDefault) {

                        this.updateListRender(true);
                    }


                    if (settings.CaseTypeMapping) {
                        let mappedFlows = [];
                        settings.CaseTypeMapping.map((object) => {

                            mappedFlows.push(object.flowName);

                        });

                        if (mappedFlows.length > 0) {

                            this.setState({"mappedFlows": mappedFlows});
                        }

                    }

                    this.setState({startFlow: settings.StartFlow, settings: settings});
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    openPassedWorkflow(workflowToOpen) {

        this.setState({selectedWorkflow: workflowToOpen});
        this.setState({backToAdd: true});

        this.setState({newWorkflow: "false"});
        this.state.commonWorkflows.map((object, i) => {

            if (object.Id === workflowToOpen) {
                this.setState({selectedIndex: i});
            }
        });

        this.setState({updatedWorkflow: true});
        this.getWorkflowResponseFromDb(workflowToOpen, "standard");

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (prevProps.drawerOpen !== this.props.drawerOpen && this.props.drawerOpen) {


        }
        //State that keeps track of tabs visibility
        if (prevState.visible !== this.state.visible && this.state.visible) {
            if (this.state.visible === "visible") {

                this.getSavedModels();
            }

        }

        if (prevProps.model !== this.props.model) {

            this.setState({response: [], breadcrumbs: []});

            this.setState({model: this.props.model});
            setTimeout(this.getSavedModels, 1000);
            setTimeout(this.getAllCommonWorkflows, 1000);
            /* this.setState({
                 labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
             });*/

            this.getSettings();

        }


        if (prevProps.pipeline !== this.props.pipeline) {


            this.setState({pipeline: this.props.pipeline});


        }
    }


    toggleDrawer = (side) => () => {

        if (this.state.left) {

            this.setState({
                [side]: false,
            });

        } else {
            this.setState({value: 1})
            this.setState({
                [side]: true,
            });

        }

    }

    handleConfirmDeleteClose(event) {

        if (event.target.innerText === "YES") {
            this.deleteResponseFromDb();
            this.setState({breadcrumbs: []});

            this.setState({backToAdd: true, selectedIndex: -1, yourChips: []});
        } else {


        }
        this.setState({confirmDeleteOpen: false});

    }

    getIndexOfWorkflow(workflowname) {
        let index = 0;
        this.state.commonWorkflows.map((object, i) => {


            if (object.Id === workflowname) {
                index = i;

            }
        });

        return index;

    }

    newWorkflow() {

        this.setState({category: "", autoCategory: {"Id": ""}});
        this.setState({response: JSON.stringify([])});
        this.setState({updatedResponse: JSON.stringify([])});
        this.setState({selectedWorkflow: ""});
        this.setState({newWorkflow: "true"});
        this.setState({openModal: true});
        this.setState({
            newNameResponseOnIntent: null,
            newFromResponseOnIntent: null,
            newFromCheckIfContextIsSet: null,
            newFromContextSwitch: null
        });

    }

    newWorkflowFromCheckIfContextIsSet() {

        this.setState({openModal: true});
    }

    newWorkflowFromResponseOnIntent() {

        this.setState({openModal: true});

    }

    deleteWorkflow() {

        this.setState({confirmDeleteOpen: true});
    }

    resetNewTrainingData() {

        this.setState({dataToUse: null});

    }


    async getResponse(intent, type, model) {

        let url = '/api/response?intent=' + intent + "&model=" + this.props.account + "_" + model + "_" + intent;

        const response = await fetch(url);
        const data = await response.json();

        if (data.length > 0) {
            let object = data[0];

            if (object.Response) {

                const resp = JSON.parse(object.Response);

                if (resp.workflow) {

                    const workflow = resp.workflow;

                    const workflowParsed = JSON.parse(workflow);
                    if (type == "WorkflowsFromOptionsIntent") {
                        this.removeWorflowOccurences(workflowParsed, type, this.state.workflowNameToDeleteOptions, intent, model)
                    } else {

                        this.removeWorflowOccurences(workflowParsed, type, this.state.workflowNameToDelete, intent, model)
                    }


                } else if (resp.responseText) {

                    let flow = [{"type": "conversation.message", "message": resp.responseText}];
                    this.setState({response: JSON.stringify(flow)});

                }
            }
        }
    }

    async getWorkflowResponseFromDb(workflow, type) {


        let workflowName = workflow;
        let category = "";
        //Includes category
        if (typeof workflow === 'object') {
            if (workflow.Category) {
                category = "_" + workflow.Category;
            }
            if (workflow.Id) {
                workflowName = workflow.Id;
            }

        }

        let url = '/api/getWorkflowResponseFromDb?responseId=' + this.props.account + '_common' + category + '_' + workflowName;


        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => console.log(error)
        );
        if (response.status === 302) {

            window.location.reload();

        }
        let data = await response.json();


        if (type === "complete") {
            if (data[0] && data[0].Value) {

                this.setState({completeResponse: data[0].Value});

            }

        } else if (type === "WorkflowToWorkflow") {


            this.removeWorflowOccurences(JSON.parse(data[0].Value), "WorkflowToWorkflow", this.state.workflowNameToDelete, workflowName)


        } else if (type === "WorkflowsFromOptionsWorkflow") {

            this.removeWorflowOccurences(JSON.parse(data[0].Value), "WorkflowsFromOptionsWorkflow", this.state.workflowNameToDeleteOptions, workflowName)

        } else {

            if (data[0] && data[0].Value) {
                this.setState({response: data[0].Value});

            } else {

                this.setState({response: []});
            }
        }


    }

    removeWorflowOccurences(workflow, type, name, intentOrWorkflowName, model) {


        let indexToRemove = -1;
        let actionIndexToRemove = [];


        workflow.map((action, i) => {

            if (type === "WorkflowToWorkflow" || type === "WorfklowsFromIntents") {

                if (action.type === "vergicAi.responseOnIntent") {

                    if (action.initiateAiWithResponse === name) {
                        indexToRemove = i;
                    }
                }
            }
            let optionIndex = -1;
            if (action.type === "website.options" && (type === "WorkflowsFromOptionsWorkflow" || type === "WorkflowsFromOptionsIntent")) {
                let optionIndexToRemove = [];
                action.options.map((option, ind) => {

                    if (option.onMatch.type === "vergicAi.responseOnIntent" && option.onMatch.initiateAiWithResponse === name) {


                        optionIndexToRemove.push(ind);

                    }

                });
                actionIndexToRemove.push({"actionIndex": i, "optionIndex": optionIndexToRemove});


            }

        });
        let saveUpdated = false;
        if (actionIndexToRemove.length > 0) {


            let optionsToRemove = [];
            for (let u = 0; u < actionIndexToRemove.length; u++) {

                let options = workflow[actionIndexToRemove[u].actionIndex].options;
                let optionIndexToRemove = actionIndexToRemove[u].optionIndex;

                for (let k = optionIndexToRemove.length - 1; k >= 0; k--) {

                    let rem = options.splice(optionIndexToRemove[k], 1);
                }


                saveUpdated = true;

                if (options.length === 0) {

                    optionsToRemove.push(actionIndexToRemove[u].actionIndex);

                }

            }

            optionsToRemove.map((index) => {

                workflow.splice(index, 1);

            });


        }


        if (indexToRemove > -1 || saveUpdated) {

            if (!saveUpdated) {

                let removed = workflow.splice(indexToRemove, 1);
            }


            if (type === "WorkflowToWorkflow" || type === "WorkflowsFromOptionsWorkflow") {


                this.setState({updatedResponse: JSON.stringify(workflow)}, () => {
                    this.updateResponseToDb(intentOrWorkflowName)
                });
            } else if (type === "WorfklowsFromIntents" || type === "WorkflowsFromOptionsIntent") {

                this.setState({updatedResponseIntent: JSON.stringify(workflow)}, () => {

                    this.updateIntentResponseToDb(intentOrWorkflowName, model);


                });
            }
        }

    }


    updateIntentResponseToDb(name, model) {

        let newModel = {"intent": name, "metaData": {"workflow": this.state.updatedResponseIntent}};
        let intentName = this.props.account + '_' + model + '_' + name;
        let url = '/api/updateResponse?intent=' + this.props.account + '_' + model + '_' + name;


        const promises = [];
        promises.push(this.getR4Token());
        Promise.all(promises).then(() => {
            this.postToKv(intentName, JSON.stringify(newModel));
        });


        fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newModel)
        }).then(response => {
            response.json()
                .then(data => {
                    if (data.length === 0) {
                        this.setState({notifierOpen: true});
                        this.setState({updatedResponse: " "});
                    }
                });
        });

    }

    async getWorkflowsFromWorkflow(workflowName, model) {

        let url = '/api/getWorkflowsFromWorkflow?account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        
        this.setState({getWorkflowsFromWorkflow: data});
        if (data && data.length > 0 && data[0].Id) {

            data.map((item) => {

                let idSplit = item.Id.split("_");
                if (idSplit.length > 0) {
                    this.setState({workflowNameToDelete: item.initiateAiWithResponse}, () => {
                        this.getWorkflowResponseFromDb(idSplit[idSplit.length - 1], "WorkflowToWorkflow");
                    });
                }
            })

        }


        if (data && data.length === 0) {

        }
    }

    async getWorkflowsFromIntents(workflowName, model) {

        let url = '/api/getWorkflowsFromIntents?model=' + this.props.model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
    
        if (data && data.length > 0 && data[0].Id) {

            data.map((item) => {
                let idSplit = item.Id.split("_");
                if (idSplit.length > 0) {

                    this.setState({workflowNameToDelete: item.initiateAiWithResponse}, () => {


                        this.getResponse(idSplit[idSplit.length - 1], "WorfklowsFromIntents", model);


                    });

                }
            });


        }

        if (this.state.savedModels.length > 0) {
            this.state.savedModels.map((model) => {

                const resp = this.getWorkflowsFromWorkflow(workflowName, model.Model);
            })


        }


        this.setState({workflowsFromIntent: data});

        if (data && data.length === 0) {

        }

    }

    async getWorkflowsFromOptionsWorkflow(workflowName, model) {

        let url = '/api/getWorkflowsFromOptionsWorkflow?account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        

        this.setState({workflowsFromOptionsIntent: data});

        if (data && data.length > 0 && data[0].Id) {

            data.map((item) => {

                let idSplit = item.Id.split("_");
                if (idSplit.length > 0) {
                    this.setState({workflowNameToDeleteOptions: item.initiateAiWithResponse}, () => {
                        this.getWorkflowResponseFromDb(idSplit[idSplit.length - 1], "WorkflowsFromOptionsWorkflow");
                    });
                }
            });
        }

        const resp = this.getWorkflowsFromIntents(workflowName, model);

    }


    async getWorkflowsFromOptionsIntent(workflowName, model) {
        let url = '/api/getWorkflowsFromOptionsIntent?model=' + model + '&account=' + this.props.account + '&workflow=' + workflowName;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        
        this.setState({workflowsFromOptionsIntent: data});

        if (data && data.length > 0 && data[0].Id) {

            data.map((item) => {

                let idSplit = item.Id.split("_");
                if (idSplit.length > 0) {
                    this.setState({workflowNameToDeleteOptions: item.initiateAiWithResponse}, () => {


                        this.getResponse(idSplit[idSplit.length - 1], "WorkflowsFromOptionsIntent", model);


                    });
                }
            })

        }

        const resp = this.getWorkflowsFromOptionsWorkflow(workflowName, model);

    }

    async deleteResponseFromDb() {


        let category = "";

        //Includes category
        if (this.state.category) {
            category = "_" + this.state.category;

        }

        let url = '/api/deleteStandAloneResponse?responseId=' + this.props.account + '_common' + category + '_' + encodeURI(this.state.selectedWorkflow);
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        if (data && data.length === 0) {

            if (this.state.removeChecked && this.state.savedModels.length > 0) {

                this.state.savedModels.map((model) => {
                    this.getWorkflowsFromOptionsIntent(this.state.selectedWorkflow, model.Model);

                })

            }

            this.setState({notifierDeleteOpen: true});
            this.getAllCommonWorkflows();
            this.setState({response: JSON.stringify([])});
            this.setState({updatedResponse: JSON.stringify([])});
            this.setState({selectedWorkflow: ""});


        }

    }

    async postToKv(name, postObject) {

        let url = "https://" + this.state.domains.restDomain.default + "/kv/" + name;
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.state.token);
        myHeaders.append("Content-Type", "application/json");

        let oToPost = this.state.updatedResponse;

        if (postObject) {

            oToPost = postObject;
        }

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: oToPost,
            redirect: 'follow'
        };

        const response = await fetch(url, requestOptions);

        const data = await response.text();
        if (this.state.updatedResponse && this.state.updatedResponse.length > 0)
            this.setState({updatedResponse: JSON.stringify([])});
    }


    async updateResponseToDb(name) {


        let url, toSaveName;
        if (name) {
            url = '/api/updateStandAloneResponse?responseId=' + this.props.account + '_common_' + name;
            toSaveName = this.props.account + '_common_' + name;
        } else {
            url = '/api/updateStandAloneResponse?responseId=' + this.props.account + '_common_' + this.state.selectedWorkflow;
            toSaveName = this.props.account + '_common_' + this.state.selectedWorkflow;
        }

        let nameArray = url.split("=");
        const updatedResponse = this.state.updatedResponse;
        let response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: updatedResponse
        }).catch(error => console.log(error)
        );



        //post to r4 Kv also
        if (nameArray.length > 1) {

            const promises = [];
            promises.push(this.getR4Token());
            Promise.all(promises).then(() => {
                this.postToKv(toSaveName, updatedResponse);
            });

        }

        let data = await response.json();

        if (data && data.length === 0) {
            if (!this.state.noNotifier) {
                this.setState({notifierOpen: true});
            }
            //this.props.resetSaveModel("new");

            if (this.state.newFromResponseOnIntent) {
                let res = this.state.response;

                if (res.constructor === String) {

                    res = JSON.parse(res);
                }

                if (res && res[0].type && res[0].type !== "LOGGER.track") {

                    this.setState({response: JSON.stringify([])});
                    this.setState({updatedResponse: JSON.stringify([])});
                    this.setState({newWorkflow: "true"});
                } else {
                    this.setState({newWorkflow: "false"});
                }

                this.setState({newNameResponseOnIntent: null});
                this.setState({newFromResponseOnIntent: null});

            }


            return new Promise(resolve => {
                resolve('resolved');
            });
        }


    }

    addNewWorkflow() {


        setTimeout(this.saveResponseToDb, 1000);
    }


    async saveResponseToDb(type) {


        if (this.state.category) {
            this.addToBreadCrumbs(this.state.responseId, this.state.category);
        }
        if (this.state.newFromResponseOnIntent) {
            let newName = this.state.responseId;

            if (this.state.category) {
                newName = this.state.responseId + ": " + this.state.category;
            }

            this.setState({newNameResponseOnIntent: newName, updatedResponse: JSON.stringify([])});

        }

        if (this.state.newFromCheckIfContextIsSet) {

            let newName = this.state.responseId;
            this.setState({newNameCheckIfContextIsSet: newName, updatedResponse: JSON.stringify([])});

        }

        if (this.state.newFromContextSwitch) {

            let newName = this.state.responseId;
            this.setState({newNameContextSwitch: newName, updatedResponse: JSON.stringify([])});

        }

        if (this.state.newFromEntitySwitch) {

            let newName = this.state.responseId;
            this.setState({newNameEntitySwitch: newName, updatedResponse: JSON.stringify([])});

        }

        this.setState({responseId: this.state.responseId.trim()});
        let id = this.state.responseId.trim();

        let category = "";

        if (this.state.category) {
            category = "_" + this.state.category;
        }
        let newName = this.props.account + '_common' + category + '_' + id;
        let url = '/api/saveStandAloneResponse?responseId=' + this.props.account + '_common' + category + '_' + id;
        //let ur = this.state.updatedResponse;
        let ur;


        if (this.state.newFromResponseOnIntent || this.state.newFromCheckIfContextIsSet || this.state.newFromContextSwitch || this.state.newFromEntitySwitch) {

            ur = [];

        } else if (this.state.updatedResponse) {

            ur = JSON.parse(this.state.updatedResponse);
        } else {

            ur = [];
        }

        if (this.state.checked && type !== "saveAs") {

            if (this.props.loggedInUserEmail) {

                let createdTimeStamp = moment(new Date()).format("YYYY-MM-DD HH:mm");
                let stats = {
                    "type": "LOGGER.track", "event": id, "data": {
                        "flow": id, "created": createdTimeStamp,
                        "createdBy": this.props.loggedInUserEmail,
                        "lastModified": createdTimeStamp,
                        "lastModifiedBy": this.props.loggedInUserEmail
                    }
                };

                ur.push(stats);
            }

            this.setState({response: JSON.stringify(ur)});
            this.setState({updatedWorkflow: true});
            this.setState({checked: true, openModal: false});


        }


        let response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(ur)
        }).catch(error => console.log(error)
        );


        let data = await response.json();

        const promises = [];
        promises.push(this.getR4Token());
        Promise.all(promises).then(() => {
            this.postToKv(newName, JSON.stringify(ur));
        });

        if (data && data.length === 0) {

            const workflows = await this.getAllCommonWorkflows();


            this.state.commonWorkflows.map((object, i) => {

                if (object.Id === this.state.responseId) {

                    this.setState({selectedIndex: i});

                }

            })

            this.setState({notifierOpen: true});
            this.setState({selectedWorkflow: this.state.responseId.trim()});

            let responseObject = {"Id": this.state.responseId.trim()};

            if (this.state.category) {
                responseObject.Category = this.state.category;

            }

            this.getWorkflowResponseFromDb(responseObject, "standard");
            this.setState({openModal: false});
            this.setState({responseId: ""});
            this.setState({newWorkflow: "false"});
            if (this.state.saveAsName !== "") {
                this.setState({updatedResponse: null, saveAsName: ""});
            }

        }


        this.setState({editOrAdd: true});

    }

    getAllCategories() {

        let categories = [];
        this.state.commonWorkflows.map((object) => {

            if (object.Category) {
                let found = false;
                categories.map((o) => {

                    if (o.Id === object.Category) {
                        found = true;
                    }

                });

                if (!found) {
                    categories.push({"Id": object.Category});
                }
            }
        });

        this.setState({categories: categories});

    }


    setModel = (model) => {

        this.setState({model: model});


    }

    setPipeline = (pipeline) => {

        this.setState({pipeline: pipeline});

    }


    saveModel() {

        //this.setState({saveModel: true});

    }


    handleWorkflowChange = (e, index, object, intro) => {

        this.setState({selectedWorkflow: object.Id});

        this.addToBreadCrumbs(object.Id, object.Category)

        if (object.Category) {
            this.setState({category: object.Category});
        } else {

            this.setState({category: null});
        }
        this.setState({newWorkflow: "false"});

        this.setState({selectedIndex: index, introSelected: intro});

        this.setState({updatedWorkflow: true});
        
        this.getWorkflowResponseFromDb(object, "standard");
        

    }

    async createComplete(e) {
        allNames = [];
        this.setState({runOnce: false});
        allNames.push(this.state.selectedWorkflow);
        this.setState({directLinks: []});
        const resp = await this.createCompleteWorkflow(this.state.selectedWorkflow, 0);


        setTimeout(this.setLevel, 3000);


    }

    findObjectReturnDepth(workflowName) {

        this.state.complete.map((object) => {

            if (object.name === workflowName) {
                return object.level;
            }


        });

    }

    setLevel() {

        let flowArray = this.state.secondFlowArray;

        let linkToFromLevel = [];
        let complete = this.state.complete;
        complete.map((object) => {

            if (!object.level) {
                if (flowArray.indexOf(object.name) > -1) {

                    object.level = this.state.level + 1;

                    object.linkTo.map((o) => {
                        linkToFromLevel.push(o);
                    });

                    flowArray.splice(flowArray.indexOf(object.name), 1);
                }
            }

        });

        if (flowArray && flowArray.length === 0) {
            let newLevel = this.state.level + 1;
            this.setState({level: newLevel});
            if (linkToFromLevel.length > 0) {
                this.setState({secondFlowArray: linkToFromLevel}, this.setLevel);
            } else {
                this.setState({complete: complete}, this.createFlowComplete)


            }

        }


    }

    async createFlowComplete() {
        flowPoints = [];
        let counterX = 0;
        let counterY = 0;
        let connectedFlows = [];
        let complete = this.state.complete;

        complete = await this.addDirectLinks(complete)
        complete.map((workflow, completeCounter) => {
            let startPoint = {"x": 0, "y": 0};
            let name = workflow.name;
            let level = workflow.level;
        
            let flows = [...workflow.flows];

            connectedFlows.push(flows);
            let addToXPos = 0;
            if (flows.length > 0) {
                addToXPos = 400;
            }

            let arrayPosition = 0;
            let found = false;


            connectedFlows.map((arr, counter) => {

                arr.map((object, i) => {

                    if (object.id === name) {

                        let linkedBy = object.linkedBy;

                        let posToUse = 0;
                        if (object.pos) {
                            posToUse = object.pos;
                        }



                        for (let j = connectedFlows.length - 1; j >= 0; j--) {
                            let a = connectedFlows[i];

                            if (a) {

                                a.map((connection) => {
                                    if (connection.id === linkedBy) {
                                        posToUse = connection.pos;
                                    }
                                });
                            }

                        }
                        found = true;

                        startPoint.x = level === 0 ? 350 : (level * 350);

                        if (counter > 1) {

                            startPoint.y = (150 * posToUse);

                        } else {

                            startPoint.y = (150 * i);
                        }

                    }

                })

            });

            counterX -= 400;
            counterY += 150;


            if (!found) {
                startPoint.x = 0;

            }

            let obj = {
                "key": name,
                "type": "vergicAi.responseOnIntent",
                "name": name,
                "content": "",
                "startPoint": startPoint,
                "outputs": []
            };
            
            let nbrLinked = flows.length;
            let outputs = [];

            
            flows.map((o) => {

                outputs.push(o.id);

            });
            obj.outputs = [...outputs];
            
            flowPoints.push(obj);
        });
        
        this.setState({flowpoints: flowPoints, complete: [], overview: true, secondFlowArray: []});
        flowPoints = [];
        allNames = [];
        multiLinks = [];

    }

    async addDirectLinks(complete) {

        let direct = this.state.directLink;
        let nameArray = [];
        direct.map((object) => {
            if (multiLinks.indexOf(object.name) === -1) {
                nameArray.push(object.name);
            } else {
                object.level = this.state.level + 1;

                complete.push(object);

            }


        });
        for (let i = 0; i < nameArray.length; i++) {
            const response = await this.getWorkflowResponseFromDb(nameArray[i], "complete");

            JSON.parse(this.state.completeResponse).map((object, index) => {
                if (object.type === "vergicAi.responseOnIntent") {

                    let newLinkedBy = [];
                    newLinkedBy.push();


                    let flow = {"id": object.initiateAiWithResponse, "pos": 0, linkedBy: nameArray[i]};
                    let flows = [];
                    flows.push(flow);
                    direct.map((o) => {
                        if (o.name === nameArray[i]) {

                            o.flows = flows;

                            complete.push(o);
                        }
                    });

                    let objectToPush = {
                        "name": object.initiateAiWithResponse,
                        "flows": [],
                        "linkTo": [],
                        "linkedBy": newLinkedBy,
                        "level": this.state.level + 2
                    };


                    complete.push(objectToPush);


                }
            });

        }

        this.setState({directLink: []});


        return complete;
    }

    async createCompleteWorkflow(workflowName, position) {
        let complete = this.state.complete;
        const response = await this.getWorkflowResponseFromDb(workflowName, "complete");
        let flow = {"name": workflowName};

        if (allNames.indexOf(workflowName) === -1) {

            allNames.push(workflowName);

        } else {

            if (workflowName === this.state.selectedWorkflow) {

                if (this.state.runOnce) {
                    return;
                } else {
                    this.setState({runOnce: true});
                }
            }

        }

        let flows = [];
        let linkTo = [];
        let linkedBy = [];
        JSON.parse(this.state.completeResponse).map((object, index) => {


            if (object.type === "website.options") {
                object.options.map((o, pos) => {

                    if (o.onMatch && o.onMatch.type === "vergicAi.responseOnIntent") {
                        let id = o.onMatch.initiateAiWithResponse;

                        if (id !== this.state.selectedWorkflow) {

                            flows.push({"id": id, "pos": pos, "linkedBy": workflowName});

                        }
                        if (id !== this.state.selectedWorkflow) {
                            linkTo.push(id);
                        }


                        linkedBy.push(workflowName);
                        this.createCompleteWorkflow(id, pos);
                    }
                });

            } else if (object.type && object.type === "vergicAi.responseOnIntent") {
                let id = object.initiateAiWithResponse;

                if (id !== this.state.selectedWorkflow) {
                    flows.push({"id": id, "pos": position, "linkedBy": workflowName});
                }

                let newLinkedBy = [];
                newLinkedBy.push(workflowName);


                let directLink = this.state.directLink;
                if (allNames.indexOf(id) === -1 && id !== this.state.selectedWorkflow) {


                    if (id !== this.state.selectedWorkflow) {
                        linkTo.push(id);
                    }


                    linkedBy.push(workflowName);
                    allNames.push(id)
                    this.createCompleteWorkflow(id, position);

                    /* directLink.push({"name": id, "flows": [], "linkTo": [], "linkedBy": newLinkedBy , "level": 0});


                     this.setState({directLink: directLink})*/

                } else {
                    multiLinks.push(id);

                }


            }


        });
        if (workflowName === this.state.selectedWorkflow) {
            flow.level = 0;
            this.setState({level: 0});
            this.setState({secondLevelFlows: linkTo});

        }
        flow.flows = flows;
        flow.linkTo = linkTo;
        if (workflowName === this.state.selectedWorkflow) {

            this.setState({secondFlowArray: linkTo});

        }
        flow.linkedBy = linkedBy;
        let level = 1;
        let addedLevel = false;
        let foundDuplicate = false;


        if (allNames.indexOf(flow.name) === -1) {

        }


        complete.push(flow);

        this.setState({complete: complete})


    }


    setNewWorkflow = (value) => {
        this.addToBreadCrumbs(value);
        let val = "";
        if (typeof value === 'object') {
            val = value.Id;
            this.setState({selectedWorkflow: value.Id + ": " + value.Category});
        } else {
            val = value;
            this.setState({selectedWorkflow: value});
        }


        if (this.state.overview) {

            this.setState({noNotifier: true});
        }
        this.setState({overview: false});
        this.setState({newWorkflow: "false"});

        // this.addToBreadCrumbs(val);

        //this.setState({previousWorkflow: this.state.selectedWorkflow});


        this.setState({updatedWorkflow: true});


        this.getWorkflowResponseFromDb(value, "standard");

        if (this.state.previousCategory) {

            this.setState({previousCategory: null});

        }

        if (this.state.category) {

            this.setState({previousCategory: this.state.category, category: null})

        }

        if (typeof value === 'object') {

            this.setSelected({"Id": value.Id, "Category": value.Category});
        } else {

            this.setSelected({"Id": value});
        }

        this.setState({backToAdd: true});

    }

    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyRow: {
                root: {
                    height: "65px"
                }
            },
        },

        typography: {
            // Tell Material-UI what's the font-size on the html element is.
            htmlFontSize: 10,
            useNextVariants: true,
        },

        palette: {
            primary: {
                light: '#757ce8',
                main: '#3f50b5',
                dark: '#002884',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },


    })


    removeUnvantedChars(str) {

        if (/([\%\?\&\/\_\#\\+])/.test(str)) {
            str = str.replace(/([\%\?\&\/\_\#\\+])/g, '');
        }

        return str;
    }

    handleResponseIdChange(e) {
        let str = e.target.value;

        /*if (/\s/.test(str)) {
            str = str.replace(/ /g, '');
        }*/

        str = this.removeUnvantedChars(str)


        this.setState({responseId: str});

    }

    handleCategoryChange(e) {

        let str = e.target.value;

        if (/\s/.test(str)) {
            str = str.replace(/ /g, '');
        }


        this.setState({newCategory: str});

    }


    changeState() {

        this.setState({notifierOpen: false});
        this.setState({notifierCreateOpen: false});
        this.setState({notifierTrainOpen: false});
        this.setState({notifierDeleteOpen: false});

    }


    updateResponseFromWorkflow = (value) => {


        if (this.state.selectedWorkflow !== "") {

            this.setState({updatedResponse: value}, () => {
                this.updateResponseToDb();
            });
        }


    }


    async getSavedModels() {


        let url = '/api/getSavedModels';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );


        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {

            this.setState({savedModels: data});

            let pipeLine = [];
            let reducedPipline = [];
            data.map((obj) => {

                if (!pipeLine.includes(obj.Pipeline)) {

                    pipeLine.push(obj.Pipeline);
                }

            });

            pipeLine.map((object) => {
                reducedPipline.push({"Pipeline": object});
            });

            this.setState({savedPipelines: reducedPipline});
        }


    }


    listRow() {

        if (this.state.commonWorkflows) {
            return this.state.commonWorkflows.map((object, i) => {
                return <MenuItem classes={{
                    root: this.props.classes.menuItemRoot
                }} key={i}
                                 value={object.Id}>{object.Category ? object.Id + ": " + object.Category : object.Id}</MenuItem>

            });
        }

    }

    filterArraySome(array, filters) {
        const filterKeys = filters;

        return array.filter(item => {
            // validates all filter criteria
            return filterKeys.some(key => {
                return item.Id.toLowerCase().indexOf(key.toLowerCase()) >= 0;
            });
        });
    }

    /*  filterArray(array, filters) {
          const filterKeys = filters;

          return array.filter(item => {
              // validates all filter criteria
              return filterKeys.every(key => {

                  //return item.Id.toLowerCase().indexOf(key.toLowerCase()) >= 0 && key.toLowerCase() !== "intro";
                  return item.Id.toLowerCase().indexOf(key.toLowerCase()) >= 0 || (item.Category ? item.Category.toLowerCase().indexOf(key.toLowerCase()) >= 0 : "");


              });
          });
      }*/

    filterArray(array, filters) {

        const filterKeys = filters;

        function filterArrayByKeywords(arr, keywords) {
            return arr.filter(item => {
                for (let i = 0; i < keywords.length; i++) {
                    const keyword = keywords[i];
                    if (keyword.startsWith('!')) {
                        const negatedKeyword = keyword.slice(1);
                        if (item.Id.toLowerCase().includes(negatedKeyword.toLowerCase())) {
                            return false;
                        }
                    } else if (!item.Id.toLowerCase().includes(keyword.toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }

        return filterArrayByKeywords(array, filters);

    }


   

    renderList() {
        if (this.state.commonWorkflows) {

            let common = this.state.commonWorkflows;
            let chips = [...this.state.yourChips];

            let introOnly = false;
            let includeIntro = false;
            if (chips.indexOf("Intro") > -1) {
                includeIntro = true;
                let index = chips.indexOf("Intro");
                chips.splice(index, 1);
                if (chips.length === 0) {
                    introOnly = true;
                }
            }

            let filteredArray;
            if (this.state.searchType === "|" && chips.length > 0) {
                filteredArray = this.filterArraySome(common, chips);

            } else {
                filteredArray = this.filterArray(common, chips);
            }
            let introObjects = [];

            if (this.state.introFlows && this.state.introFlows.length > 0 || (localStorage.getItem("savedFilters") && localStorage.getItem("savedFilters").indexOf("Intro") > -1)) {

                let intro = this.state.introFlows;
                if (!intro) {

                    intro = this.state.mappedFlows;

                }
                if (intro) {
                    intro.map((intro) => {
                        common.map((o) => {

                            if (o.Id === intro) {
                                introObjects.push(o);
                            }

                        });
                    });

                    if (introOnly) {
                        filteredArray = [...introObjects];
                    } else if (includeIntro) {
                        filteredArray = [...filteredArray, ...introObjects];
                    }

                }
            }

        
            return filteredArray.map((object, i) => {
                return <ListItem key={i}
                                 classes={{root: this.props.classes.listItemTextSelected}}
                                 onClick={event => this.handleWorkflowChange(event, i, object, this.state.mappedFlows && this.state.mappedFlows.indexOf(object.Id) > -1)}
                                 selected={this.state.selectedIndex === i}
                                 autoFocus={this.state.selectedIndex === i}
                                 button

                >

                    <ListItemText classes={{primary: this.props.classes.listItemText}}
                                  primary={object.Category ? object.Id + ": " + object.Category : object.Id}/>
                    {this.state.mappedFlows && this.state.mappedFlows.indexOf(object.Id) > -1 ? <ListItemIcon>
                        < StarIcon/>
                    </ListItemIcon> : <div></div>
                    }
                </ListItem>

            });
        }
    }

    handleChange(e) {

        this.setState({filter: e.target.value});

    }

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handlePrevious = (event, i, crumb) => {


        /*if(this.state.previousCategory){

            this.setState({category: this.state.previousCategory})
        }else{
            this.setState({previousCategory: null});
        }*/

        //this.setState({selectedWorkflow: ""});
        let cat = null;

        if (typeof crumb === 'object' && crumb !== null) {
            this.setState({selectedWorkflow: crumb.Id});
            this.getWorkflowResponseFromDb(crumb);

        } else {

            let crumbSplit = [];


            if (crumbSplit.length > 1) {
                cat = crumbSplit[1];
                crumb = crumbSplit[0];
            }
            this.setState({selectedWorkflow: crumb});

            this.addToBreadCrumbs(crumb, null)

            this.getWorkflowResponseFromDb({"Id": crumb, "Category": cat});
        }
        this.setState({newWorkflow: "false"});
        this.setState({previousWorkflow: ""});
        this.setSelected({"Id": crumb, "Category": cat});

    }

    setSelected(workflow) {

        let found = false;
        this.state.commonWorkflows.map((object, index) => {
            if (object.Id === workflow.Id) {

                if (workflow.Category && object.Category && object.Category === workflow.Category) {
                    found = true;
                    this.setState({selectedIndex: index});
                } else if (!found) {
                    this.setState({selectedIndex: index});
                }

            }
        });

    }

    handleClose = () => {
        this.setState({openModal: false});
    };

    clearInput() {

        this.setState({filter: ""});

    }

    newFromCheckIfContextIsSet = (type) => {

        this.setState({
            newFromCheckIfContextIsSet: true,
            newFromCheckIfContextIsSetType: type,
            updatedResponse: JSON.stringify([]),
            autoCategory: {"Id": ""}
        }, () => {
            this.newWorkflowFromCheckIfContextIsSet();
        });

    }

    newFromResponseOnIntent() {


        this.setState({
            newFromResponseOnIntent: true,
            updatedResponse: JSON.stringify([]),
            autoCategory: {"Id": ""}
        }, () => {
            this.newWorkflowFromResponseOnIntent()
        });

    }


    newFromEntitySwitch = (type) => {

        if (type === "default") {
            this.setState({default: true});
        } else {
            this.setState({default: false});
        }

        this.setState({
            newFromEntitySwitch: true,
            updatedResponse: JSON.stringify([])
        }, () => {
            this.newWorkflowFromEntitySwitch();
        });

    }
    newFromContextSwitch = (type) => {

        if (type === "default") {
            this.setState({default: true});
        } else {
            this.setState({default: false});
        }

        this.setState({
            newFromContextSwitch: true,
            updatedResponse: JSON.stringify([])
        }, () => {
            this.newWorkflowFromContextSwitch();
        });

    }

    async saveWorkflowAs() {


        let copyOfResponse = JSON.parse(this.state.response);
        copyOfResponse.map((object) => {

            if (object.type === "LOGGER.track") {

                object.data.flow = this.state.saveAsName;
                object.event = this.state.saveAsName;

            }

        });

        this.setState({updatedResponse: JSON.stringify(copyOfResponse), responseId: this.state.saveAsName}, () => {

            this.saveResponseToDb("saveAs");

            this.addToBreadCrumbs(this.state.saveAsName, null)
            this.setState({openSaveAsModal: false, yourChips: []});

        });


    }

    async editWorkflowName() {

        this.getWorkflowsToUpdate(this.state.selectedWorkflow);
        this.getWorkflowsToUpdateFromOptions(this.state.selectedWorkflow);
        this.getWorkflowsToUpdateFromOptionsIntent(this.state.selectedWorkflow);
        this.getWorkflowsToUpdateIntent(this.state.selectedWorkflow);
        this.entitySwitchesToUpdate(this.state.selectedWorkflow);
        this.contextSwitchesToUpdate(this.state.selectedWorkflow);
        this.entitySwitchesIntentsToUpdate(this.state.selectedWorkflow);
        this.contextSwitchesIntentsToUpdate(this.state.selectedWorkflow);

        if (this.state.introSelected) {
            this.updateIntroMapping(this.state.selectedWorkflow, this.state.newWorkflowName);
        }

        let category = "";
        //Includes category
        if (this.state.category) {
            category = "_" + this.state.category;

        }
        let newName = this.props.account + '_common' + category + '_' + this.state.newWorkflowName;
        newName = newName.trimEnd();


        const promises = [];
        promises.push(this.getR4Token());
        Promise.all(promises).then(() => {
            this.postToKv(newName, this.state.response);
        });


        let url = '/api/updateWorkflowName?newName=' + newName + '&currentName=' + this.props.account + '_common' + category + '_' + this.state.selectedWorkflow;
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        if (data && data.length === 0) {

            setTimeout(this.getAllCommonWorkflows, 1000);

            this.setState({
                selectedWorkflow: this.state.newWorkflowName.trimEnd(),
                openEditModal: false
            });
            this.setSelected({"Id": this.state.newWorkflowName.trimEnd()});

        }
    }


    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        const {data: stateData, cursor} = this.state;

        return (

            <div style={{marginTop: "10px"}}>
                
                {this.state.overviewNew &&
                    <React.Fragment><p>{this.state.overviewNew}</p> <FlowOverview intentOverview={this.state.intentOverview} openWorkflow={(name) => {
                        this.setState({yourChips: [], selectedWorkflow: name, overviewNew: false});
                        this.addToBreadCrumbs(name, null);
                        this.getWorkflowResponseFromDb({"Id": name}, "standard");
                        this.setState({selectedIndex: this.getIndexOfWorkflow(name)});
                    }
                    } flow={this.state.selectedWorkflow} model={this.props.model} account={this.props.account}
                                                   closeOverview={() => {
                                                       this.props.resetOpenOverview();
                                                       this.setState({overviewNew: false, intentOverview: null})
                                                   }}/> </React.Fragment>}


                {!this.state.overviewNew && <React.Fragment>
                    <div style={{
                        display: "flex",
                        margin: "0px",
                        padding: "0px",
                        flexDirection: "row",
                        marginRight: "255px"
                    }}>
                        {!this.state.overview ?
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "calc(100vh - 30px)",
                            }}>

                                <Paper
                                    style={{display: "flex", marginBottom: "10px", height: "100%", maxHeight: "120px"}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        marginTop: "0px",
                                        padding: "10px",
                                        flexWrap: "wrap",
                                        flexGrow: 1,
                                        width: "200px"

                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}>
                                            <Tooltip title="New Workflow">
                                                <IconButton style={{background: "#3E51B5", color: "white"}}
                                                            onClick={this.newWorkflow} aria-label="New Workflow"
                                                            component="span">
                                                    <AddIcon/>
                                                </IconButton>
                                            </Tooltip>

                                        </div>

                                        {this.state.selectedWorkflow !== "" ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginLeft: "10px"
                                            }}>


                                                <Tooltip title="Delete Workflow">
                                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                                onClick={this.deleteWorkflow} color="primary"
                                                                aria-label="Delete " component="span">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                            </div> : <div></div>}

                                        {/*this.state.selectedWorkflow !== "" ?
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                        marginRight: "10px"
                                    }}>
                                        <Tooltip title="Generate Overview">
                                            <IconButton style={{background: "#3E51B5", color: "white"}}
                                                        onClick={this.createComplete} aria-label="Generate Overview"
                                                        component="span">
                                                <TableChartIcon/>
                                            </IconButton>
                                        </Tooltip>

                                    </div> : <div></div>*/}


                                        {this.state.selectedWorkflow !== "" &&
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginLeft: "10px",
                                                marginRight: "10px"

                                            }}>

                                                <Tooltip title="Edit Workflow Name">
                                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                                onClick={() => {
                                                                    this.setState({newWorkflowName: this.state.selectedWorkflow});
                                                                    this.setState({openEditModal: true});

                                                                }} aria-label="Edit Workflow Name" component="span">
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {this.state.selectedWorkflow !== "" &&
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginTop: "5px"

                                            }}>
                                                <Tooltip title="Save Workflow As">
                                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                                onClick={() => {
                                                                    this.setState({openSaveAsModal: true});

                                                                }} aria-label="Save Workflow As" component="span">
                                                        <FileCopyIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Flow Overview">
                                                    <IconButton style={{
                                                        background: "#3E51B5",
                                                        color: "white",
                                                        marginLeft: "10px"
                                                    }}
                                                                onClick={this.overViewClickHandler}
                                                                aria-label="Flow Overview" component="span">
                                                        <PublicIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                            </div>}


                                        {this.state.updatedResponse && this.state.updatedResponse !== "[]" && this.state.selectedWorkflow !== "" ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginLeft: "10px"

                                            }}>
                                                <Tooltip title="Save Workflow">
                                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                                onClick={this.updateResponseToDb} color="primary"
                                                                aria-label="Save" component="span">
                                                        <SaveIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            : <div></div>}


                                    </div>
                                </Paper>

                                <Paper style={{
                                    display: "flex",
                                    height: this.state.selectedWorkflow !== "" ? "calc(100vh - 110px)" : "calc(100vh - 110px)",
                                    minHeight: this.state.selectedWorkflow !== "" ? "calc(100vh - 110px)" : "calc(100vh - 110px)"
                                }}>
                                    <div style={{
                                        position: "relative",
                                        width: "100%",

                                        height: "calc(100%-200px)",
                                        minWidth: "300px",
                                    }}>
                                        <div style={{
                                            background: "#FFF",
                                            width: "100%",
                                            marginRight: this.state.yourChips.length === 0 ? "-15px" : "0px"
                                        }}>

                                            {/* <TextField


                                        className={classes.filter}
                                        value={this.state.filter}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="filled"
                                        InputProps={{
                                            endAdornment: <InputAdornment onClick={this.clearInput}
                                                                          position="end">{this.state.filter !== "" ?
                                                <IconButton><Clear style={{fontSize: 16}}/></IconButton> :
                                                <div></div>}</InputAdornment>
                                        }}
                                    />*/}
                                            <div>
                                                <div ref={(ref) => {
                                                    this.chipInputRef = ref;
                                                }}>
                                                    <ChipInput

                                                        value={this.state.yourChips}
                                                        variant="filled"
                                                        label="Filter"
                                                        style={{width: "100%", maxWidth: "400px"}}
                                                        onAdd={(chip) => this.handleAddChip(chip)}
                                                        onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                    />
                                                </div>
                                                <div style={{
                                                    backgroundColor: "#E8E8E8",
                                                    position: "absolute",
                                                    paddingBottom: "5px",
                                                    paddingTop: "5px",
                                                    right: "10px",
                                                    width: "20px",
                                                    top: "25px"

                                                }}>
                                                    <IconButton aria-label="delete" size="small"
                                                                onClick={this.handlePrefilter}>
                                                        <FilterListIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </div>

                                            </div>
                                            <div style={{display: this.state.showPrefilter ? "block" : "none"}}>
                                                <div style={{
                                                    position: "absolute",
                                                    right: "1px",
                                                    top: this.state.toggleButtonTop
                                                }}>
                                                    <ToggleButtonGroup size="small" value={this.state.searchType}
                                                                       exclusive
                                                                       onChange={this.handleSearchType}>
                                                        <ToggleButton className={classes.toggleButton} value="&"
                                                                      aria-label="And">
                                                            {" & "}
                                                        </ToggleButton>
                                                        <ToggleButton className={classes.toggleButton} value="|"
                                                                      aria-label="Ors">
                                                            {" | "}
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                                <div style={{borderBottom: "1px #ddd solid"}}>
                                                    <div
                                                        ref={(ref) => {
                                                            this.filterRef = ref;
                                                        }}
                                                        className={classes.chips} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "left",
                                                        padding: "10px",
                                                        marginTop: "32px",
                                                        minWidth: "318px",
                                                        flexWrap: "wrap",
                                                        maxHeight: "250px",
                                                        overflow: "auto",
                                                        maxWidth: this.state.prefilterWidth
                                                    }}>


                                                        <Chip label="Intro" variant="outlined" icon={<StarIcon/>}
                                                              onClick={(event) => this.handleChipClick(event, "Intro")}/>
                                                        {this.renderChips()}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <List
                                            style={{

                                                maxHeight: this.state.showPrefilter ? this.state.listHeight : '88%',
                                                overflow: 'auto',
                                                width: "100%",
                                                maxWidth: "400px"
                                            }}
                                            ref={(ref) => {
                                                this.divRef = ref;
                                            }}
                                            component="nav"
                                            className={classes.root}
                                            id='thing'
                                        >
                                            {this.renderList()}
                                        </List>
                                    </div>
                                </Paper>
                            </div> : <div></div>
                        }
                        <Paper className={classes.paperBar} style={{
                            height: "calc(100vh - 28px)",
                            minHeight: "calc(100vh - 28px)",
                            marginLeft: "10px",
                            width: this.state.overview ? "97vw" : "calc(100vw - 705px)",
                            marginRight: this.state.overview ? "0px" : "000px",

                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "space-between",
                                overflow: 'auto',
                                height: 'calc(100vh - 30px)'
                            }}>
                                {this.state.selectedWorkflow !== "" && this.state.overview ?
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "35px",
                                            marginTop: "10px"
                                        }}>
                                            <Fab color="primary" onClick={() => {
                                                this.setState({overview: false});

                                            }}
                                                 aria-label="Back" size={"small"} style={{marginBottom: "10px"}}
                                                 className={classes.fab}>
                                                <ArrowBack/>
                                            </Fab>
                                            <div style={{
                                                marginLeft: "150px",
                                                fontSize: "1.3em",
                                                marginTop: "10px"
                                            }}>Overview from: {this.state.selectedWorkflow}</div>
                                        </div>
                                        <WorkflowOverview
                                            pipeline={this.props.pipeline}
                                            account={this.props.account}
                                            flowpoints={this.state.flowpoints}
                                            passWorkflowToOpen={this.setNewWorkflow}
                                            activeWorkflow={this.state.selectedWorkflow}
                                        />
                                    </div> : <div></div>}

                                {this.state.previousWorkflow !== "" && this.state.selectedWorkflow !== "" && !this.state.overview ?


                                    <div
                                        style={{
                                            textAlign: "right",
                                            width: "100c%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            paddingLeft: "10px"
                                        }}>
                                        <div style={{
                                            textAlign: "right",
                                            marginRight: "0px",
                                            fontSize: "1.2em",
                                            marginTop: "10px"
                                        }}>
                                            {this.state.breadcrumbs && this.state.breadcrumbs.map((crumb, i) => {
                                                return <Breadcrumbs key={i} aria-label="breadcrumb"><Link
                                                    color="inherit"
                                                    href="#"
                                                    onClick={event => this.handlePrevious(event, i, crumb)}>{crumb}</Link><Typography
                                                    color="textPrimary">{crumb}</Typography></Breadcrumbs>
                                            })}
                                        </div>
                                    </div> : <div></div>}
                                {this.state.selectedWorkflow !== "" && this.state.previousWorkflow === "" && !this.state.overview ?

                                    <div
                                        style={{
                                            textAlign: "right",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            paddingLeft: "10px"
                                        }}>
                                        <div style={{
                                            textAlign: "right",
                                            marginRight: "0px",
                                            fontSize: "1.2em",
                                            marginTop: "10px",
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <Breadcrumbs aria-label="breadcrumb">
                                                {this.state.breadcrumbs.map((crumb, i) => {
                                                    return i < this.state.breadcrumbs.length ?
                                                        <Link key={Math.random()} color="inherit" href="#"
                                                              onClick={event => this.handlePrevious(event, i, crumb)}>{crumb.Id ? crumb.Id + ": " + crumb.Category : crumb}</Link> : ""
                                                })}
                                            </Breadcrumbs>

                                            {/* return <Breadcrumbs key={i} aria-label="breadcrumb"><Breadcrumbs key={i} aria-label="breadcrumb"><Link color="inherit" href="#" onClick={event => this.handlePrevious(event, i, crumb)}>{crumb}</Link><Typography color="textPrimary">{crumb}</Typography></Breadcrumbs>
                                          {/*  <Link color="inherit" href="#" onClick={this.handlePrevious(crumb)}>
                                                {crumb}
                                            </Link>
                                            <Typography color="textPrimary">{crumb}</Typography>
                                        </Breadcrumbs>*/}


                                            {/*<Breadcrumbs aria-label="breadcrumb">
                                   <Typography color="textPrimary">{this.state.category?this.state.selectedWorkflow+": "+this.state.category:this.state.selectedWorkflow}</Typography>
                               </Breadcrumbs>*/}

                                            {/*Active: {this.state.category?this.state.selectedWorkflow+": "+this.state.category:this.state.selectedWorkflow}*/}
                                        </div>
                                    </div> : <div></div>}


                                {!this.state.overview ? <WorkflowComponents newWorkflow={this.state.newWorkflow}
                                                                            updatedWorkflow={this.state.updatedWorkflow}
                                                                            workflowCreate={true}
                                                                            voicebot={this.state.voicebot}
                                                                            roles={this.props.roles}
                                                                            account={this.props.account}
                                                                            accountName={this.props.accountName}
                                                                            forwardSetting={() => {
                                                                                this.setState({showSettings: false})
                                                                            }}
                                                                            loggedInUserEmail={this.props.loggedInUserEmail}
                                                                            model={this.props.model}
                                                                            pipeline={this.props.pipeline}
                                                                            passWorkflowToOpen={this.setNewWorkflow}
                                                                            response={this.state.response}
                                                                            updatedResponse={this.state.updatedResponse}
                                                                            passData={this.updateResponseFromWorkflow}                                                                        
                                                                            flowpoints={this.state.flowpoints}
                                                                            editOrAdd={this.state.editOrAdd}
                                                                            activeWorkflow={this.state.selectedWorkflow}
                                                                            newFromContextSwitch={this.newFromContextSwitch}
                                                                            newFromEntitySwitch={this.newFromEntitySwitch}
                                                                            newFromResponseOnIntent={this.newFromResponseOnIntent}
                                                                            newFromCheckIfContextIsSet={this.newFromCheckIfContextIsSet}
                                                                            newFromCheckIfContextIsSetType={this.state.newFromCheckIfContextIsSetType}
                                                                            newNameResponseOnIntent={this.state.newNameResponseOnIntent}
                                                                            newNameContextSwitch={this.state.newNameContextSwitch}
                                                                            newNameEntitySwitch={this.state.newNameEntitySwitch}
                                                                            default={this.state.default}
                                                                            newNameCheckIfContextIsSet={this.state.newNameCheckIfContextIsSet}
                                                                            backToAdd={this.state.backToAdd}
                                                                            resetAdd={this.resetAdd}
                                                                            showSettings={this.state.showSettings}

                                /> : <div></div>}

                                {this.state.selectedWorkflow && !this.state.overview ? <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginLeft: "20px",
                                    top: "-45px",
                                    marginTop: "-82px"
                                }}>

                                    <Fab color="primary" onClick={this.handleShowSettings}
                                         aria-label="Workflow Settings"
                                         size="small"> <Settings/></Fab>
                                </div> : <div></div>}
                            </div>
                        </Paper>

                    </div>

                    <Modal
                        aria-labelledby="Edit-Workflow-Name"
                        aria-describedby="Edit-Workflow-Name"
                        open={this.state.openEditModal}
                        onClose={this.handleClose}
                    >
                        <div style={getModalStyle()} className={classes.modal}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography variant="h6" id="modal-title">
                                    Edit Workflow Name
                                </Typography>
                                <Typography variant="subtitle1" id="simple-modal-description">
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <FormControl variant="outlined" style={{borderColor: "black"}}
                                                     className={classes.formControl}>
                                            <TextField
                                                label="Workflow Name"
                                                style={{marginLeft: "-10px", backgroundColor: "white"}}
                                                className={classes.textField}
                                                value={this.state.newWorkflowName}
                                                onChange={this.changeWorkflowName}
                                                margin="normal"
                                                variant="outlined"
                                            />


                                        </FormControl>


                                        <div style={{marginTop: "20px"}}>
                                            <Button style={{
                                                marginLeft: "0px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={this.editWorkflowName}>
                                                Update</Button>
                                            <Button style={{
                                                marginLeft: "20px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={() => {
                                                this.setState({openEditModal: false});
                                            }}>
                                                Cancel</Button>
                                        </div>

                                    </div>
                                </Typography></div>

                        </div>
                    </Modal>


                    <Modal
                        aria-labelledby="SaveAs-Workflow-Name"
                        aria-describedby="SaveAs-Workflow-Name"
                        open={this.state.openSaveAsModal}
                        onClose={this.handleClose}
                    >
                        <div style={getModalStyle()} className={classes.modal}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography variant="h6" id="modal-title">
                                    Save Workflow As
                                </Typography>
                                <Typography variant="subtitle1" id="simple-modal-description">
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <FormControl variant="outlined" style={{borderColor: "black"}}
                                                     className={classes.formControl}>
                                            <TextField
                                                label="Save Workflow As Name"
                                                style={{marginLeft: "-10px", backgroundColor: "white"}}
                                                className={classes.textField}
                                                value={this.state.saveAsName}
                                                onChange={this.changeSaveAsName}
                                                margin="normal"
                                                variant="outlined"
                                            />


                                        </FormControl>


                                        <div style={{marginTop: "20px"}}>
                                            <Button style={{
                                                marginLeft: "0px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={this.saveWorkflowAs}>
                                                Save As</Button>
                                            <Button style={{
                                                marginLeft: "20px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={() => {
                                                this.setState({openSaveAsModal: false});
                                            }}>
                                                Cancel</Button>
                                        </div>

                                    </div>
                                </Typography></div>

                        </div>
                    </Modal>

                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.openModal}
                        onClose={this.handleClose}
                    >
                        <div style={getModalStyle()} className={classes.modal}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography variant="h6" id="modal-title">
                                    New Workflow
                                </Typography>
                                <Typography variant="subtitle1" id="simple-modal-description">
                                    <div style={{display: "flex", flexDirection: "column"}}>

                                        <FormControl variant="outlined" style={{borderColor: "black"}}
                                                     className={classes.formControl}>
                                            <TextField
                                                label="Name"
                                                style={{marginLeft: "-10px", backgroundColor: "white"}}
                                                className={classes.textField}
                                                value={this.state.responseId}
                                                onChange={this.handleResponseIdChange}
                                                margin="normal"
                                                variant="outlined"
                                            />


                                        </FormControl>

                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.checked}
                                                                   onChange={this.handleCheckbox}
                                                                   name="Log Statistics"/>}
                                                label="Log statistics on this workflow"
                                            />
                                        </FormGroup>

                                        <div style={{marginTop: "20px"}}>
                                            <Button style={{
                                                marginLeft: "0px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={this.addNewWorkflow}>
                                                Add</Button>
                                            <Button style={{
                                                marginLeft: "20px",
                                                marginTop: "20px",
                                                padding: "10px"
                                            }}
                                                    variant="contained" color="primary" onClick={() => {
                                                this.setState({openModal: false});
                                            }}>
                                                Cancel</Button>
                                        </div>
                                    </div>
                                </Typography></div>

                        </div>
                    </Modal>

                    <Notifier changeState={this.changeState} open={this.state.notifierOpen}
                              message="Response Saved Successfully"/>

                    <Notifier changeState={this.changeState} open={this.state.notifierDeleteOpen}
                              message={"Workflow " + this.state.selectedWorkflow + " Deleted Successfully"}/>

                    <Dialog
                        open={this.state.confirmDeleteOpen}
                        onClose={this.handleConfirmDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete {this.state.selectedWorkflow}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.removeChecked}
                                                       onChange={this.handleRemoveCheckbox} name="Log Statistics"/>}
                                    label="Delete all links to this workflow"
                                />
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleConfirmDeleteClose} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleConfirmDeleteClose} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
                }
            </div>

        );
    }
}

export default withStyles(styles)(ResponseWorkflow);
