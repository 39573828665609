import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
    close: {
        padding: theme.spacing()/2,
    },
});

class Notifier extends React.Component {
    state = {
        open: false,
    };


    componentDidUpdate(prevProps, prevState, snapShot) {
        // only update chart if the data has changed

        if (prevProps.open !== this.props.open) {
           // console.log(this.props.open);
            this.setState({open: this.props.open});

        }
    }



    handleClose = (event, reason) => {

        this.setState({ open: false });
        this.props.changeState();

    };

    render() {
        const { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.props.message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }


}
Notifier.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifier);