import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class RandomNext extends Component {


    constructor(props) {

        super(props);

        this.pass = this.pass.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.changeSetFlow = this.changeSetFlow.bind(this);
        this.changeNotSetFlow = this.changeNotSetFlow.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);

        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            commonWorkflows: [],
            setFlow: {"Id": ""},
            notSetFlow: {"Id": ""},
            equalValue: "",
            operator: " "



        }
        //this.convRef = React.createRef();
    }



    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            workflowArray.push({"Id": "Workflow.GoNext"});
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length-1]) {


                    let found = false;
                    workflowArray.map((o) =>{

                        if(o.Id === arraySplit[arraySplit.length-1] ){

                            found = true;
                        }

                    });

                    if(!found) {

                        if (arraySplit.length > 3) {

                            workflowArray.push({
                                "Id": arraySplit[arraySplit.length - 1],
                                "Category": arraySplit[arraySplit.length - 2]
                            });
                        } else {
                            workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                        }
                    }

                }

            });

            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })

            this.setState({commonWorkflows: workflowArray});



        }


    }

    componentDidMount() {
        this.getAllCommonWorkflows();
        if (this.props.value) {
            this.setState({edit: true});

            if(this.props.value.firstFlow && this.props.value.firstFlow.type !== "vergicAi.responseOnIntent"){

                if(this.props.value.firstFlow.type === "workflow.goNext"){
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({setFlow: {"Id": this.props.value.firstFlow.type}});
                }

            }else{
                this.setState({setFlow: {"Id": this.props.value.firstFlow.initiateAiWithResponse}});
            }
            if(this.props.value.secondFlow && this.props.value.secondFlow.type !== "vergicAi.responseOnIntent"){
                if(this.props.value.secondFlow.type === "workflow.goNext"){
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({notSetFlow: {"Id": this.props.value.secondFlow.type}});
                }


            }else{
                this.setState({notSetFlow: {"Id": this.props.value.secondFlow.initiateAiWithResponse}});
            }

        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({edit: true});


            if(this.props.value.firstFlow && this.props.value.firstFlow.type !== "vergicAi.responseOnIntent"){
                if(this.props.value.firstFlow.type === "workflow.goNext"){
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({setFlow: {"Id": this.props.value.firstFlow.type}});
                }

            }else{
                this.setState({setFlow: {"Id": this.props.value.firstFlow.initiateAiWithResponse}});
            }
            if(this.props.value.secondFlow && this.props.value.secondFlow.type !== "vergicAi.responseOnIntent"){
                if(this.props.value.secondFlow.type === "workflow.goNext"){
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({notSetFlow: {"Id": this.props.value.secondFlow.type}});
                }

            }else{
                this.setState({notSetFlow: {"Id": this.props.value.secondFlow.initiateAiWithResponse}});
            }

        }

    }

    pass() {

        var firstFlow = {};
        var secondFlow = {};
        if (this.state.setFlow.Id === "Workflow.GoNext") {

            firstFlow = {"type": "workflow.goNext"};

        }else{

            firstFlow = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.setFlow.Id
            };
        }

        if (this.state.notSetFlow.Id === "Workflow.GoNext"){

            secondFlow = {"type": "workflow.goNext"};
        }else{
            secondFlow = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.notSetFlow.Id
            };


        }



        let workflow = {type: "case.randomNext", "firstFlow":
                firstFlow,
            "secondFlow":
                secondFlow};



        this.props.passData("case.randomNext", workflow);


    }

    openWorkflow = (event, flow) => {


        this.props.openWorkflow(flow.Id);

    }

    changeSetFlow(event, value) {

        if(value && value.Id){

            this.setState({setFlow: {"Id": value.Id}});
        }

    }

    changeNotSetFlow(event, value) {
        if(value && value.Id){
            this.setState({notSetFlow: {"Id": value.Id}});
        }

    }


    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent:"space-between"}}>

                <div style={{ marginBottom: "10px", marginTop: "-10px", display: "flex", flexDirection: "column",}}>

                <Autocomplete
                    id="combo-box5"
                    options={this.state.commonWorkflows}
                    onChange={this.changeSetFlow}
                    value={this.state.setFlow}
                    getOptionLabel={option => option.Id}
                    style={{ width: "100%", marginTop: "40px"}}
                    renderInput={params => <TextField {...params} variant="outlined" label="First Flow"  style={{minHeight: "100px"}} />}
                />
                {this.state.edit?<Button style={{width: "100%", marginBottom: "10px", marginTop: "-25px"}} variant="contained"
                        color="primary" onClick={event => this.openWorkflow(event, this.state.setFlow)}>Open Workflow</Button>: ""}
                </div>
                <div style={{ marginBottom: "0px", marginTop: "25px", display: "flex", flexDirection: "column", justifyContent:"space-around"}}>
                    <Autocomplete
                        id="combo-box4"
                        options={this.state.commonWorkflows}
                        onChange={this.changeNotSetFlow}
                        value={this.state.notSetFlow}
                        getOptionLabel={option => option.Id}
                        style={{ width: "100%", marginTop: "0px"}}
                        renderInput={params => <TextField {...params} variant="outlined" label="Second Flow"  style={{minHeight: "100px"}} />}
                    />
                    {this.state.edit?<Button style={{width: "100%", marginTop: "-25px"}} variant="contained"
                                             color="primary" onClick={event => this.openWorkflow(event, this.state.notSetFlow)}>Open Workflow</Button>: ""}
                </div>

                {!this.props.option ?
                    <div style={{marginTop: "50px"}}>
                        {this.props.value && this.props.value.message ?
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"
                                    onClick={this.pass}>Update</Button> :
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"
                                    onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}
            </div>


        )
    }

}

export default withStyles(styles)(RandomNext);
