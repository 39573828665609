import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import React, {useEffect, useState} from "react";
import './styles/intent-ranking.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddFromQueryLog from "./AddFromQueryLog";
import Notifier from "./Notifier";
import Tooltip from "@material-ui/core/Tooltip";

const IntentRanking = (props) => {

    const [copied, isCopied] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [addIntentQuery, setAddIntentQuery] = useState(false);
    const [notifierOpen, setNotifierOpen] = useState(false);


    useEffect(() => {
        renderList();
    }, []);

    useEffect(() => {
        if (copied) {
            const timeoutId = setTimeout(() => {
                isCopied(false);
                setShowButton(true);
            }, 3000)
            setShowButton(false);
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [copied]);

    const closeModal = () => {
        setAddIntentQuery(false);
    }

    const handleNotifier = () => {
        setTimeout(() => {
            setNotifierOpen(true);
        }, 2000);
    }

    const handleIntentClick = () => {
        setAddIntentQuery(true);
    };

    const renderList = () => {
        if (props.data) {
            return props.data.map((ranking) => (
                <tr className="ranking-results" key={Math.random()}>
                    <td>{ranking.name}</td>
                    <td className="intent-ranking">{Math.round(eval(ranking.confidence) * 100)}</td>
                </tr>
            ));
        }
    };

    return (
        <Grid item xs={4} className="intent-card">
            <Paper className="query-intents-container">
                <div className="test-icon-container">
                    <div style={{
                        width: '20px', height: '20px', marginTop: '13px',
                        backgroundColor: ` ${props.color}`,
                    }}></div>
                    <Tooltip title="Add to log" placement="bottom"
                             PopperProps={{style: {marginTop: -5, marginLeft: 20}}}>
                        <IconButton className="add-query-to-log-icon" onClick={handleIntentClick}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                    <CopyToClipboard text={props.bestMatch.query}
                                     onCopy={() => {
                                         props.updateTextValue(props.bestMatch.query);
                                         isCopied(true)
                                     }}>
                        <Tooltip title="Copy query" placement="bottom"
                                 PopperProps={{style: {marginTop: -5, marginLeft: 20}}}>
                            <IconButton className="copy-test-icon">
                                <ContentCopyIcon className="copy-query-icon"/>
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                    <Tooltip title="Delete query" placement="bottom"
                             PopperProps={{style: {marginTop: -5, marginLeft: 20}}}>
                        <IconButton className="delete-test-icon" onClick={props.onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                {showButton && (
                    <h3 className="query-title">{(props.bestMatch.query || "").replace(/^\w/, c => c.toUpperCase())}
                    </h3>)}
                {copied ? <h3 className="copied-query">Copied</h3> : null}
                <div>
                    <div className="line-break"><b>Best Intent Match:</b> {props.bestMatch.name}</div>
                    <div className="line-break-match">
                        <b>Confidence:</b> {Math.round(eval(props.bestMatch.confidence) * 100)}%
                    </div>
                </div>
                <div className="subtitle-info line-break"><b>Intents</b></div>
                <table className="confidence-info">
                    <thead>
                    <tr className="items-title-container">
                        <td className="name-title"><b>Name</b></td>
                        <td><b>Confidence [%]</b></td>
                    </tr>
                    </thead>

                    <tbody className="scrollable-tbody">
                    {renderList()}
                    </tbody>
                </table>
                <div>
                </div>
                <div className="show-entities-container">
                    <div className="subtitle-info line-break-entities"><b> Entities</b></div>
                    <table className="confidence-info">
                        <thead>
                        <tr className="items-title-container">
                            <td className="name-title"><b>Name</b></td>
                            <td><b>Value</b></td>
                            <td className="confidence-title"><b>Confidence [%]</b></td>
                        </tr>
                        </thead>
                        {props.entities && props.entities.length > 0 &&
                            <tbody className="entities-result-wrapper">
                            {props.entities.map((enti, i) => {
                                return <tr key={i} className="ranking-results">
                                    <td>{enti.entity}</td>
                                    <td className="entities-values">{enti.value}</td>
                                    <td className="entities-confidence">
                                        {Number.isFinite(enti.confidence_entity) ? Math.ceil(enti.confidence_entity * 100) : 0}
                                    </td>

                                </tr>
                            })}
                            </tbody>
                        }
                        {props.entities && props.entities.length === 0 &&
                            <tbody>
                            <tr className="no-entities-wrapper">
                                <td className="no-entities">N/A</td>
                            </tr>
                            </tbody>}
                    </table>
                </div>
            </Paper>
            {addIntentQuery &&
                <AddFromQueryLog
                    loggedInUserEmail={props.loggedInUser}
                    model={props.modelValue}
                    account={props.account}
                    closeModal={closeModal}
                    trainingSentence={props.bestMatch.query}
                    open={'true'}
                    handleNotifier={handleNotifier}
                    closeAdd={() => setAddIntentQuery(null)}
                />
            }
            <Notifier changeState={() => {
                setNotifierOpen(true)
            }}
                      open={notifierOpen}
                      message={"Query was added to " + props.model?.Model + "  successfully"}/>
        </Grid>
    )
}

export default IntentRanking;