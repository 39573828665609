import React, {Component} from "react";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {Controlled as CodeMirror} from 'react-codemirror2';
import '../App.css';
import classnames from 'classnames';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/show-hint.css'; // without this css hints won't show
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField";
import ChipInput from "material-ui-chip-input";
import MUIDataTable from "mui-datatables";
import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Notifier from "./Notifier";
import CircularProgress from "@material-ui/core/CircularProgress";
import prettier from "prettier";
import * as parserBabel from "prettier/parser-babel";
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    overrides: {
        MUIDataTableSelectCell: {
            checkboxRoot: {
                display: 'none'
            }
        }
    }
});


class Actions extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Actions',
            conversationId: "",
            caseId: "",
            errorChecked: false,
            actions: [],
            notifierOpen: false,
            notifierOpenConf: false,
            notifierOpenIntegration: false,
            tabValue: 0,
            actionTab: 0,
            limit: 100,
            yourChips: [],
            startDate: new Date(moment().subtract(1, "days").format("YYYY-MM-DD HH:mm")),
            endDate: new Date(moment().add(1, "days").format("YYYY-MM-DD HH:mm")),
        }

        this.getAllActions = this.getAllActions.bind(this);
        this.renderTree = this.renderTree.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.formatActionArray = this.formatActionArray.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.getAllIntegrations = this.getAllIntegrations.bind(this);
        this.getParameters = this.getParameters.bind(this);
        this.getActionsForThisAccount = this.getActionsForThisAccount.bind(this);
        this.getIntegrationsForThisAccount = this.getIntegrationsForThisAccount.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.updateActionInDb = this.updateActionInDb.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.filterArray = this.filterArray.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.filterActions = this.filterActions.bind(this);
        this.updateIntegrationInDb = this.updateIntegrationInDb.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.updateBotConfig = this.updateBotConfig.bind(this);
        this.getLog = this.getLog.bind(this);
        this.handleCaseId = this.handleCaseId.bind(this);
        this.createLogTableData = this.createLogTableData.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.getMuiTheme = this.getMuiTheme.bind(this);
        this.handleConversationId = this.handleConversationId.bind(this);
        this.handleCheckboxError = this.handleCheckboxError.bind(this);
        this.changeState = this.changeState.bind(this);
        this.getBotFlows = this.getBotFlows.bind(this);
        this.updateWorkflows = this.updateWorkflows.bind(this);
        this.handleLimitUpdate = this.handleLimitUpdate.bind(this);
        this.cellClick = this.cellClick.bind(this);
        this.formatCode = this.formatCode.bind(this);
    }

    formatCode(code){
        let formattedCode = prettier.format(code, {
            parser: "babel",
            plugins: [parserBabel],
            semi: true,
            singleQuote: true,
            trailingComma: "es5",
            tabWidth: 3
        });

        return formattedCode;

    }

    cellClick(cellData, cellMetaData, tableMeta) {
        if(cellData === "Open in Conversations"){
            this.props.openInConversations(this.state.chartData[cellMetaData.rowIndex][5]);
        }

    }
    handleLimitUpdate(event){

        this.setState({limit: event.target.value});
    }

    changeState() {

        this.setState({notifierOpen: false, notifierOpenConf: false, notifierOpenIntegration: false});


    }

    handleCheckboxError(event) {

        this.setState({errorChecked: event.target.checked});
    }


    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    /*backgroundColor: "#FFF",
                    borderRightColor: "#CCC",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid"*/
                    /*height: "11px",
                    cursor: "pointer"*/
                }
            },
            MUIDataTable: {
                responsiveScroll: {
                    maxHeight: "calc(100vh - 300px)",
                    minHeight: "calc(100vh - 300px)",
                }
            },


            MuiTableRow: {}
        }
    })

    handleConversationId(event) {

        this.setState({conversationId: event.target.value});

    }

    handleCaseId(event) {

        this.setState({caseId: event.target.value});

    }

    onChangeStartDate = (date) => {

        this.setState({startDate: date})
    }

    onChangeEndDate = (date) => {

        this.setState({endDate: date})
    }


    async getLog() {
        this.setState({loading: true});
        this.setState({jsonDetails: []});

        this.setState({chartData: []});

        var startDateString = moment(this.state.startDate.toString()).format("YYYY-MM-DD");
        var endDateString = moment(this.state.endDate.toString()).format("YYYY-MM-DD");
        var limit = Number(this.state.limit);
        let url;
        if (this.state.conversationId !== "") {
            url = '/api/getLogWithConv?startDate=' + startDateString + "&endDate=" + endDateString + "&conversationid=" + this.state.conversationId+"&limit="+limit;

        } else if (this.state.caseId !== "") {
            url = '/api/getLogWithCaseId?startDate=' + startDateString + "&endDate=" + endDateString + "&caseid=" + this.state.caseId+"&limit="+limit;

        } else if (this.state.errorChecked) {
            url = '/api/getLogWithError?startDate=' + startDateString + "&endDate=" + endDateString+"&limit="+limit;
        } else {
            url = '/api/getLog?startDate=' + startDateString + "&endDate=" + endDateString+"&limit="+limit;

        }

        let options = {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();

        this.createLogTableData(data);


    }

    createLogTableData(data) {


        let rows = [];
        let jsonDetails = [];
        if (data !== []) {

            for (let row of data) {

                const {createdAt, entity, description, botid, version, type, conversationid, caseid, json} = row

                rows.push([moment(createdAt).format("YYYY-MM-DD HH:mm:ss"), entity, description, type, conversationid, caseid,"Open in Conversations", JSON.stringify(json, null, 2)]);

                //jsonDetails.push(JSON.stringify(json, null, 2));

            }
            // this.setState({jsonDetails: jsonDetails});

            this.setState({chartData: rows, loading: false});

        }

    }


    updateConfig(event) {

        this.updateBotConfig();

    }

    handleFilter(event) {

        this.setState({filter: event.target.value});

    }


    async updateBotConfig() {
        let url = '/api/updateBotConfig';
        let config = {"development": JSON.parse(this.state.botConfig)};

        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(config)
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response;

        if (data && data.status === 200) {

            this.setState({notifierOpenConf: true});
        }

        //this.setState({botConfig: JSON.parse(data.development)});


    }

    async updateWorkflows() {
        let url = '/api/updateWorkflows';
        let config = {"workflows": JSON.parse(this.state.workflows)};

        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(config)
        };


        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response;

        if (data && data.status === 200) {

            this.setState({notifierOpenConf: true});
        }

        //this.setState({botConfig: JSON.parse(data.development)});


    }

    async getBotConfig() {
        let url = '/api/getBotConfig';
        let options = {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();
        let result = JSON.parse(data.development);
        this.setState({botConfig: JSON.stringify(result, null, "\t")});


    }

    async getBotFlows() {
        let url = '/api/getBotFlows';
        let options = {
            method: 'get',
            headers: {'Content-Type': 'application/json'}

        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();
        //let result = JSON.parse(data);

        this.setState({workflows: JSON.stringify(data.workflows, null, "\t")});


    }

    updateAction(event) {

        if (this.state.tabValue === 1) {
            this.updateIntegrationInDb();
        } else if (this.state.tabValue === 0) {

            this.updateActionInDb();
        }


    }

    async updateActionInDb() {
        let url = '/api/updateActionCode?id=' + this.state.id;
        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"actionCode": this.state.value.toString()})
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();

        if (data.length === 0) {
            this.setState({notifierOpen: true})

        }


        this.getActionsForThisAccount();

    }

    async updateIntegrationInDb() {
        
        

        let url = '/api/updateIntegrationCode?id=' + this.state.id;
        let options = {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"value": this.state.value.toString()})
        };

        let response = await fetch(url, options).catch(error => console.log(error)
        );
        let data = await response.json();
        if (data.length === 0) {

            this.setState({notifierOpenIntegration: true});

        }
        this.getIntegrationsForThisAccount();

    }

    async getActionsForThisAccount() {

        let url = '/api/getActionsForThisAccount';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );
        let data = await response.json();

        this.setState({actions: data}, () => {
            this.formatActionArray("actions")
        });

    }


    handleTabChange = (event, value) => {

        if (value === 1) {

            this.getIntegrationsForThisAccount();

        }
        this.setState({tabValue: value});
    };

    handleSelect = (event, nodeIds) => {

        if (this.state.tabValue === 0) {

            if (nodeIds.indexOf("library") === -1) {
                this.state.actions.map((action) => {

                    if (action.name === nodeIds.split("##")[0]) {

                        this.setState({value: this.formatCode(action.botscript)})
                        this.setState({id: action.id});
                        this.setState({actionName: action.name});
                        this.setState({valueJson: action.jsonconf})

                    }


                });
            }
        } else if (this.state.tabValue === 1) {
            if (nodeIds.indexOf("library") === -1) {

                this.state.integrations.map((integration) => {

                    if (integration.name === nodeIds.split("##")[0]) {
                        this.setState({id: integration.id});
                        this.setState({integrationName: integration.name});
                        this.setState({valueIntegration: integration.botscript, value: integration.botscript})
                        this.setState({valueJsonIntegration: integration.jsonconf})
                    }


                });
            }

        }

    }

    filterArray(array, filters) {
        const filterKeys = filters;

        if (filterKeys) {

            return array.filter(item => {

                // validates all filter criteria
                return filterKeys.every(key => {

                    return item.toLowerCase().indexOf(key.toLowerCase()) >= 0;


                });
            });
        }
    }

    filterActions() {
        let newLibArray = [];
        if (this.state.libraryArray && this.state.yourChips.length > 0) {
            let libraries = Object.keys(this.state.libraryArray);

            libraries.map((lib) => {

                this.state.libraryArray[lib].map((action) => {

                    let actionFound = false;

                    this.state.yourChips.map((chip) => {

                        if (action.name.indexOf(chip) > -1 && !actionFound) {

                            if (!newLibArray[lib]) {
                                newLibArray[lib] = [];
                            }

                            newLibArray[lib].push(action);
                            actionFound = true;
                        }

                    });


                })

            });

            return newLibArray;
        }
    }

    renderTree() {
        let current = "";
        let library = true;
        let filteredArray;

        if (this.state.tabValue === 0) {
            filteredArray = this.filterActions();
        }

        if (this.state.libraryArray) {

            let lib = this.state.libraryArray;
            if (filteredArray && Object.keys(filteredArray).length > 0) {

                lib = filteredArray;
            }

            if (this.state.tabValue === 1 && this.state.libraryArrayIntegrations) {
                lib = this.state.libraryArrayIntegrations;


            }
            let libraries = Object.keys(lib);
           /* if (this.state.tabValue === 1 && this.state.libraryArrayIntegrations) {
                libraries.splice(0,1);
                console.log(libraries)
            }*/

            //libraries = this.filterArray(libraries, this.state.yourChips);
            if (libraries && lib ) {
                return libraries.map((library, i) => {

                    return <TreeItem key={"Node" + (i + 1)} nodeId={"library" + library+"##"+Math.random()} label={library}>

                        {lib[library].map((a, u) => {

                            return <TreeItem key={"Treeitem" + (u * (i + 1))} nodeId={a.name+"##"+Math.random()}
                                           label={a.name}></TreeItem>;
                        })
                        }
                    </TreeItem>;

                });
            }
        }

    }


    async getIntegrationsForThisAccount() {

        let url = '/api/getIntegrationsForThisAccount';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        this.setState({integrations: data}, () => {
            this.formatActionArray("integrations")
        });


    }

    async getAllIntegrations() {

        let url = '/api/getAllIntegrations';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();
        this.setState({integrations: data}, () => {
            this.formatActionArray("integrations")
        });


    }

    async getParameters() {
        let url = '/api/getBotAccountParameters';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );
        let data = await response.json();

        this.setState({libraries:data.addOnLibraries});

    }

    async getAllActions() {

        let url = '/api/getAllActions';
        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'}
        }).catch(error => console.log(error)
        );

        let data = await response.json();

        this.setState({actions: data}, () => {
            this.formatActionArray("actions")
        });


    }

    formatActionArray(type) {
        let actions = this.state.actions;

        if (type === "integrations") {
            actions = this.state.integrations;

        }


        let libraryArray = [];
        let actionArray = [];
        let currentLibrary = "basic";
        actions.map((action, i) => {

            if (action.library !== currentLibrary) {


                libraryArray[currentLibrary] = actionArray;
                currentLibrary = action.library;
                actionArray = [];
                action.index = i;

                actionArray.push(action);
                if ((i === actions.length - 1)) {
                    libraryArray[currentLibrary] = actionArray;
                }

            } else if (i === actions.length - 1) {

                libraryArray[currentLibrary] = actionArray;

            } else {

                action.index = i;
                actionArray.push(action);


            }

        });

        if (type === "integrations") {

            this.setState({libraryArrayIntegrations: libraryArray});

        } else {
            this.setState({libraryArray: libraryArray});
        }
//        console.log(actions)

    }

    componentDidMount() {
        this.getBotConfig();
        this.getBotFlows();
        this.getParameters();

        if(this.props.convId){

            this.setState({tabValue: 3, conversationId: this.props.convId});
        }
        setTimeout(this.getActionsForThisAccount, 1000);
    }


    componentDidUpdate(prevProps, prevState, snapShot) {

    }

    handleAddChip = (chip) => {

        let chips = this.state.yourChips;
        chips.push(chip);

        this.setState({yourChips: chips})

    }

    handleDeleteChip = (chip, index) => {


        let chips = this.state.yourChips;

        chips.splice(index, 1);

        this.setState({yourChips: chips});
    }

    render() {

        const {classes} = this.props;


        return (
            <MuiThemeProvider
                theme={this.getMuiTheme()}>
                <div
                    style={{
                        marginTop: "-20px",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "calc(100vh - 20px)"
                    }}>
                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange}>
                        <Tab label="Actions"/>
                        <Tab label="Integrations"/>
                        <Tab label="Configuration"/>
                        <Tab label="Log"/>
                        <Tab label="Workflows"/>
                    </Tabs>
                    <div style={{display: "flex", flexDirection: "row"}}>


                        {this.state.tabValue === 0 &&

                            <Paper style={{
                                padding: "10px",
                                marginTop: "10px",
                                maxHeight: "calc(100vh - 70px)",
                                minHeight: "calc(100vh - 70px)",
                                overflow: "auto"
                            }}>

                                <Box style={{overflow: "auto", width: "max-content"}}>


                                    <ChipInput
                                        value={this.state.yourChips}
                                        variant="filled"
                                        label="Filter"
                                        style={{width: "100%", marginBottom: "20px"}}
                                        onAdd={(chip) => this.handleAddChip(chip)}
                                        onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                    />

                                    <TreeView
                                        aria-label="Action Navigator"
                                        defaultCollapseIcon={<ExpandMoreIcon/>}
                                        defaultExpandIcon={<ChevronRightIcon/>}
                                        onNodeSelect={this.handleSelect}
                                        sx={{
                                            height: 240,
                                            maxHeight: 500,
                                            flexGrow: 1,
                                            width: 300,
                                            maxWidth: 600,
                                            overflowY: 'auto',
                                            marginTop: "10px"
                                        }}
                                    >

                                        {this.renderTree()}

                                    </TreeView>
                                </Box>
                            </Paper>

                        }

                        {this.state.tabValue === 1 &&
                            <Paper style={{
                                padding: "10px",
                                marginTop: "10px",
                                maxHeight: "calc(100vh - 73px)",
                                minHeight: "calc(100vh - 73px)",
                                overflow: "auto",
                                paddingRight: "20px"
                            }}>

                                <Box style={{overflow: "auto", width: "max-content"}}>
                                    <TreeView
                                        aria-label="Integration Navigator"
                                        defaultCollapseIcon={<ExpandMoreIcon/>}
                                        defaultExpandIcon={<ChevronRightIcon/>}
                                        onNodeSelect={this.handleSelect}
                                        sx={{
                                            height: 240,
                                            maxHeight: 500,
                                            flexGrow: 1,
                                            width: 300,
                                            maxWidth: 600,
                                            overflowY: 'auto',
                                            marginTop: "10px"
                                        }}
                                    >

                                        {this.renderTree()}

                                    </TreeView>
                                </Box>
                            </Paper>
                        }


                        {this.state.tabValue === 4 && this.state.workflows &&
                            <Paper style={{padding: "10px", marginTop: "10px", maxHeight: "calc(100vh - 80px)", minHeight: "calc(100vh - 80px)"}}>

                                <Box style={{overflow: "auto", width: "1200", maxHeight: "calc(100vh - 150px)", minHeight: "calc(100vh - 150px)"}}>
                                    <CodeMirror
                                        value={this.state.workflows}
                                        options={{
                                            mode: 'application/json',
                                            lineNumbers: true,
                                            lint: true,
                                            lineWrapping: true,
                                            extraKeys: {
                                                "Ctrl-Q": function (cm) {
                                                    cm.foldCode(cm.getCursor());
                                                }
                                            },
                                            foldGutter: true,
                                            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                        }}

                                        onBeforeChange={(editor, data, value) => {

                                            this.setState({workflows: value});
                                        }}
                                        onChange={(editor, data, value) => {
                                        }}
                                    />
                                </Box>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginTop: "0px",
                                    paddingTop: "10px",
                                    borderTop: "1px solid #ddd",
                                    paddingRight: "20px"

                                }}>
                                    <Button style={{minWidth: "150pxm"}} color="primary" variant="contained"
                                            onClick={this.updateWorkflows}>Save</Button>
                                </div>
                            </Paper>}
                        {this.state.tabValue === 2 && this.state.botConfig &&
                            <Paper style={{padding: "10px", marginTop: "10px", maxHeight: "calc(100vh - 80px)", minHeight: "calc(100vh - 80px)"}}>

                                <Box style={{overflow: "auto", width: "1200", maxHeight: "calc(100vh - 150px)", minHeight: "calc(100vh - 150px)"}}>
                                    <CodeMirror
                                        value={this.state.botConfig}
                                        options={{
                                            mode: 'application/json',
                                            lineNumbers: true,
                                            lint: true,
                                            lineWrapping: true,
                                            extraKeys: {
                                                "Ctrl-Q": function (cm) {
                                                    cm.foldCode(cm.getCursor());
                                                }
                                            },
                                            foldGutter: true,
                                            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                        }}

                                        onBeforeChange={(editor, data, value) => {

                                            this.setState({botConfig: value});
                                        }}
                                        onChange={(editor, data, value) => {
                                        }}
                                    />
                                </Box>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginTop: "0px",
                                    paddingTop: "10px",
                                    borderTop: "1px solid #ddd",
                                    paddingRight: "20px"

                                }}>
                                    <Button style={{minWidth: "150pxm"}} color="primary" variant="contained"
                                            onClick={this.updateConfig}>Save</Button>
                                </div>
                            </Paper>}

                        {(this.state.tabValue === 0 || this.state.tabValue === 1) && (this.state.value) &&
                            <div style={{marginLeft: "20px"}}>
                                <Tabs value={this.state.actionTab} onChange={(event, value) => {
                                    this.setState({actionTab: value})
                                }}>
                                    <Tab label="Code"/>
                                    <Tab label="Manifest"/>
                                </Tabs>
                                {this.state.actionTab === 0 &&

                                    <div>

                                        <Paper style={{
                                            padding: "0px",
                                            marginTop: "10px",
                                            minHeight: "calc(100vh - 120px)",
                                            maxHeight: "calc(100vh - 120px)"
                                        }}>

                                            <Box style={{
                                                overflow: "auto",
                                                width: "calc(100%)",
                                                height: "calc(100vh - 180px)"
                                            }}>
                                                <CodeMirror
                                                    value={this.state.value}
                                                    options={{
                                                        mode: 'javascript',
                                                        lineNumbers: true,
                                                        lint: true,
                                                        lineWrapping: true,
                                                        extraKeys: {
                                                            "Ctrl-Q": function (cm) {
                                                                cm.foldCode(cm.getCursor());
                                                            }
                                                        },
                                                        foldGutter: true,
                                                        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                                                    }}

                                                    onBeforeChange={(editor, data, value) => {
                                                        this.setState({value});
                                                    }}
                                                    onChange={(editor, data, value) => {

                                                    }}
                                                />
                                            </Box>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                marginTop: "0px",
                                                paddingTop: "10px",
                                                borderTop: "1px solid #ddd",
                                                paddingRight: "20px"

                                            }}>
                                                <Button style={{minWidth: "150pxm", width: "200px"}} color="primary"
                                                        variant="contained"
                                                        onClick={this.updateAction}>Save</Button>
                                            </div>
                                        </Paper>
                                    </div>

                                }
                                {this.state.actionTab === 1 && <React.Fragment>
                                    {(this.state.tabValue === 1 || this.state.tabValue === 0) && (this.state.valueJson || this.state.valueJsonIntegration) &&
                                        <Paper style={{
                                            padding: "10px",
                                            marginTop: "10px",
                                            maxHeight: "calc(100vh - 120px)",
                                            overflow: "auto",
                                            width: "100%"
                                        }}>

                                            <Box style={{overflow: "auto", width: "100%", minWidth: "600px"}}>
                                                <CodeMirror
                                                    value={this.state.tabValue === 0 ? this.state.valueJson : this.state.valueJsonIntegration}
                                                    options={{
                                                        mode: 'javascript',
                                                        lineNumbers: true,
                                                        lint: true,
                                                        lineWrapping: true,
                                                        extraKeys: {
                                                            "Ctrl-Q": function (cm) {
                                                                cm.foldCode(cm.getCursor());
                                                            }
                                                        },
                                                        foldGutter: true,
                                                        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]


                                                    }}

                                                    onBeforeChange={(editor, data, value) => {

                                                        this.setState({value});
                                                    }}
                                                    onChange={(editor, data, value) => {
                                                    }}
                                                />

                                            </Box>
                                        </Paper>}</React.Fragment>}

                            </div>}


                        {this.state.tabValue === 3 &&
                            <Paper style={{
                                padding: "10px",
                                marginTop: "10px",
                                maxHeight: "calc(100vh - 40px)",
                                overflow: "auto"
                            }}>

                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        < FormControl style={{borderColor: "black"}}
                                                      className={classes.formControl}
                                                      style={{
                                                          minWidth: "200px",
                                                          minHeight: "100px",
                                                          paddingLeft: "0px",
                                                          paddingRight: "20px",
                                                          marginTop: "0px",
                                                          marginBottom: "5px",
                                                          padding: "10px"
                                                      }}>
                                            <DatePicker
                                                disableToolbar
                                                autoOk
                                                ampm={false}
                                                inputVariant="outlined"
                                                variant="inline"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Start Date"
                                                style={{}}
                                                value={this.state.startDate}
                                                onChange={this.onChangeStartDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </FormControl>
                                        < FormControl style={{borderColor: "black"}}
                                                      className={classes.formControl}
                                                      style={{
                                                          minWidth: "200px",
                                                          minHeight: "100px",
                                                          paddingLeft: "0px",
                                                          paddingRight: "20px",
                                                          marginTop: "0px",
                                                          marginBottom: "5px",
                                                          padding: "10px"
                                                      }}>
                                            <DatePicker
                                                disableToolbar
                                                autoOk
                                                ampm={false}
                                                variant="inline"
                                                inputVariant="outlined"
                                                margin="normal"
                                                id="date-picker-inline2"
                                                label="End Date"
                                                style={{marginLeft: "20px"}}
                                                value={this.state.endDate}
                                                onChange={this.onChangeEndDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </FormControl>
                                    </MuiPickersUtilsProvider>

                                    <TextField
                                        label="Conversation Id"
                                        style={{width: "100%", height: "60px", maxWidth: "155px", marginLeft: "20px"}}
                                        value={this.state.conversationId}
                                        onChange={this.handleConversationId}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="Case Id"
                                        style={{width: "100%", height: "60px", maxWidth: "155px", marginLeft: "20px"}}
                                        value={this.state.caseId}
                                        onChange={this.handleCaseId}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="Limit"
                                        style={{width: "100%", height: "60px", maxWidth: "155px", marginLeft: "20px"}}
                                        value={this.state.limit}
                                        onChange={this.handleLimitUpdate}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <FormGroup row
                                               style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px"}}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.errorChecked}
                                                               onChange={this.handleCheckboxError} name="Show Errors"/>}
                                            label="Show Errors"
                                        />
                                    </FormGroup>

                                    <span
                                        style={{marginLeft: "20px", marginTop: "10px"}}> <Button
                                        variant="contained" color="primary"
                                        onClick={this.getLog}>Show Log</Button></span>
                                </div>
                                {this.state.loading && <div style={{position: "absolute", left: "calc(50vw + 20px)", top: "500px"}}><CircularProgress /></div>}
                                <MUIDataTable

                                    title={"LOG"}
                                    data={this.state.chartData}
                                    columns={["createdAt", "entity", "description", "type", "conversationId",
                                        "caseId", {
                                            name: "Link",
                                            options: {
                                                filter: true,
                                                setCellProps: (value) => {
                                                    return {
                                                        className: classnames(
                                                            {
                                                                [classes.NameCell]: value
                                                            })
                                                    };
                                                }
                                            }
                                        },  {
                                        name: "json",
                                        options: {display: false}
                                    }]}
                                    options={{
                                        filter: true,
                                        filterType: "checkbox",
                                        responsive: "scroll",
                                        selectableRows: "none",
                                        rowsPerPage: 500,
                                        print: false,
                                        expandableRows: true,
                                        onCellClick: (colData, cellMeta, tableMeta) => {
                                            this.cellClick(colData, cellMeta);
                                        },
                                        renderExpandableRow: (rowData, rowMeta) => {

                                            return (
                                                <TableRow>
                                                    <TableCell colSpan={rowData.length}>

                                                        <table>

                                                            <tbody key={rowMeta.rowIndex}>
                                                            <tr>
                                                                <td>

                                                                    <TextareaAutosize style={{minWidth: "80vw"}}>
                                                                        {rowData[7]}
                                                                    </TextareaAutosize>

                                                                </td>

                                                            </tr>
                                                            </tbody>

                                                        </table>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }

                                    }}

                                />

                            </Paper>


                        }
                    </div>


                </div>
                <Notifier changeState={this.changeState} open={this.state.notifierOpen}
                          message={this.state.actionName + " updated successfully"}/>
                <Notifier changeState={this.changeState} open={this.state.notifierOpenIntegration}
                          message={this.state.integrationName + " updated successfully"}/>
                <Notifier changeState={this.changeState} open={this.state.notifierOpenConf}
                          message={"Configuration updated successfully"}/>
            </MuiThemeProvider>

        );
    }
}

export default withStyles(styles)(Actions);
