import React, { Component } from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button/Button";
import OptionsAction from "./OptionsAction";
import { Flowpoint, Flowspace } from "flowpoints";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Drawer from "@material-ui/core/Drawer";
import Tabs from "@material-ui/core/Tabs";
import SyncAltIcon from "@material-ui/icons/CompareArrows";
import Tab from "@material-ui/core/Tab";
import ConversationMessage from "./ConversationMessage";
import PhoneIcon from "@material-ui/icons/Phone";
import VoiceTransfer from "./VoiceTransfer";
import ImageInChat from "./ImageInChat";
import PresentUrl from "./PresentUrl";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Message from "@material-ui/icons/Message";
import Menu from "@material-ui/icons/Menu";
import Image from "@material-ui/icons/Image";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import ArrowBack from "@material-ui/icons/ArrowBack";
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import Link from "@material-ui/icons/Link";
import Highlight from "@material-ui/icons/Highlight";
import DeleteIcon from "@material-ui/icons/Delete";
import StopCircleIcon from "@material-ui/icons/Stop";
import IntegrationInstructionsIcon from "@material-ui/icons/SettingsInputAntenna";
import Save from "@material-ui/icons/Save";
import Receipt from "@material-ui/icons/Receipt";
import Bookmark from "@material-ui/icons/Bookmark";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import NavigateNext from "@material-ui/icons/NavigateNext";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckGroupStatusAndTransfer from "./CheckGroupStatusAndTransfer";
import AskForContext from "./AskForContext";
import VoiceUpdateCallDetails from "./VoiceUpdateCallDetails";
import WebsiteNavigate from "./WebsiteNavigate";
import Integrations from "./Integrations";
import VergicTemplate from "./VergicTemplate";
import HighlightElement from "./HighlightElement";
import Fab from "@material-ui/core/Fab";
import ResponseOnIntent from "./ResponseOnIntent";
import Tooltip from "@material-ui/core/Tooltip";
import CustomerJourneyAction from "./CustomerJourneyAction";
import CustomAction from "./CustomAction";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Settings from "@material-ui/icons/Settings";
import AgentAssist from "./AgentAssist";
import SaveIcon from "@material-ui/icons/Save";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { compose } from "redux";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import CheckIfContextIsSet from "./CheckIfContextIsSet";
import HelpIcon from "@material-ui/icons/Help";
import ExecuteOnTime from "./ExecuteOnTime";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckGroupStatus from "./CheckGroupStatus";
import GroupIcon from "@material-ui/icons/Group";
import PauseIcon from "@material-ui/icons/Pause";
import WaitForContext from "./WaitForContext";
import SetContextValue from "./SetContextValue";
import RandomNext from "./RandomNext";
import { SwapHorizontalCircle } from "@material-ui/icons";
import ExecuteOnDate from "./ExecuteOnDate";
import TodayIcon from "@material-ui/icons/Today";
import TimerIcon from "@material-ui/icons/Timer";
import CheckNumberInQueue from "./CheckNumberInQueue";
import Timer from "./Timer";
import RemoveContext from "./RemoveContext";
import VoiceAskForContext from "./VoiceAskForContext";
import ChatInput from "./ChatInput";
import InputIcon from "@material-ui/icons/Input";
import SetCaseType from "./SetCaseType";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SetIntentModel from "./SetIntentModel";
import ContextSwitch from "./ContextSwitch";
import moment from "moment";
import MinMaxUi from "./MinMaxUi";
import MinimizeIcon from "@material-ui/icons/Minimize";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import GetContextFromPage from "./GetContextFromPage";
import WebIcon from "@material-ui/icons/Web";
import ArrayMessage from "./ArrayMessage";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import EntitySwitch from "./EntitySwitch";
import VoiceOptions from "./VoiceOptions";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import InfoIcon from "@material-ui/icons/Info";
import AgentAssistIcon from "@material-ui/icons/Accessibility";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
let data = require("./config/Manifest.json");
let voicebotdata = require("./config/VoiceManifest.json");

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    background: "#DDDDDD",
    marginTop: "0px",
  },
  flowPoint: {
    backgroundColor: "#000",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  button: {
    padding: "0 10px",
  },
  listItemText: {
    fontSize: "15px",
    paddingLeft: "40px",
    selected: {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  fab: {
    margin: theme.spacing(),
  },

  paper: {
    padding: "10px",
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    marginTop: "0px",
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  paperForm: {
    display: "flex",
    flexDirection: "row",
  },
  drawerPaper: { marginTop: "0px" },
});
const counter = 0;
const renderArray = [
  "65",
  "65",
  "65",
  "65",
  "65",
  "155",
  "135",
  "135",
  "135",
  "135",
  "135",
  "135",
  "115",
  "115",
  "115",
  "115",
  "115",
  "115",
];
const renderArrayBegin = ["135"];
const SortableItem = sortableElement(({ value }) => (
  <div
    style={{
      padding: "10px",
      marginTop: "0px",
      color: "#fff",
      backgroundColor: "#C8315C",
      fontSize: "14px",
      borderRadius: "5px",
      borderBottom: "1px solid #ccc",
      zIndex: 9999999,
      cursor: "pointer",
    }}
    variant="contained"
    color="secondary"
  >
    <div style={{ display: "flex", flexDirection: "column", height: "42px" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <DragHandleIcon />
        <div style={{ marginLeft: "10px" }}>{value.readableName}</div>
      </div>
      <div style={{ marginLeft: "35px" }}>
        {value.message ? value.message.substring(0, 40) : ""}
      </div>
      <div style={{ marginLeft: "35px" }}>{value.key ? value.key : ""}</div>
      <div style={{ marginLeft: "35px" }}>
        {value.context ? value.context : ""}
      </div>
    </div>
  </div>
));

//{"name": "Transfer To Agent", "manifest": {"type": "case.transferToAgent"}},
class WorkflowComponents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      workFlowRender: null,
      textFieldValue: "",
      position: 0,
      flowpoints: [],
      right: true,
      response: [],
      message: "",
      website: "",
      data: [],
      showUpdatedResponseSave: false,
      groupId: "",
      context: "",
      createWorkflow: false,
      value: 0,
      errorMessage: "",
      viewPortHeight: "calc(100vh - 80px)",
      optionsType: "options",
      items: [],
      showSort: false,
      showJourneys: false,
      showIntegrations: false,
    };

    this.listRow = this.listRow.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.reactOnListClick = this.reactOnListClick.bind(this);
    this.renderWorkflowForm = this.renderWorkflowForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.makeWorkFlow = this.makeWorkFlow.bind(this);
    this.saveWorkflow = this.saveWorkflow.bind(this);
    this.renderFlowPoints = this.renderFlowPoints.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.convertWorkflowToFlowPoints =
      this.convertWorkflowToFlowPoints.bind(this);

    this.removeFlow = this.removeFlow.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.convertFlowToResponse = this.convertFlowToResponse.bind(this);
    this.handleWebsiteChange = this.handleWebsiteChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.onFlowClick = this.onFlowClick.bind(this);
    this.handleGroupIdChange = this.handleGroupIdChange.bind(this);
    this.handleContextChange = this.handleContextChange.bind(this);
    this.workflowNavigate = this.workflowNavigate.bind(this);
    this.passForwardParams = this.passForwardParams.bind(this);
    this.passDataToResponseWorkflow =
      this.passDataToResponseWorkflow.bind(this);
    this.changePosition = this.changePosition.bind(this);
    this.reorderArray = this.reorderArray.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.passWorkflowToOpen = this.passWorkflowToOpen.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.updateOptionType = this.updateOptionType.bind(this);
    this.openWorkflow = this.openWorkflow.bind(this);
    this.newWorkflow = this.newWorkflow.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.listOptions = this.listOptions.bind(this);
    this.handleShowSort = this.handleShowSort.bind(this);
    this.updateArray = this.updateArray.bind(this);
    this.updateDisableInput = this.updateDisableInput.bind(this);
    this.getAccountIntegrationActions =
      this.getAccountIntegrationActions.bind(this);
    this.getModels = this.getModels.bind(this);
    this.checkData = this.checkData.bind(this);
    this.updateLogInformation = this.updateLogInformation.bind(this);
    this.getCustomerJourneys = this.getCustomerJourneys.bind(this);
    this.checkForCustomerJourneys = this.checkForCustomerJourneys.bind(this);
    this.handleShowSettings = this.handleShowSettings.bind(this);
  }

  handleShowSettings(event) {
    if (this.state.showSettings) {
      this.setState({ showSettings: false });
    } else {
      this.setState({ showSettings: true });
    }
  }

  async getCustomerJourneys() {
    let url = "/api/getCustomerJourneys?accountId=" + this.props.account;

    const response = await fetch(url);
    const data = await response.json();

    let items = [];
    const groups = new Map();
    const journeys = new Map();

    if (data && data.length > 0) {
      data.map((journey) => {
        if (journey.Group) {
          let obj = {};
          obj.primary = journey.Checkpointname;
          obj.id = journey.Id.toString();
          obj.order = journey.Order;
          obj.secondary = journey.Score;
          obj.name = journey.Name;
          obj.group = journey.Group;
          items.push(obj);
        }

        journeys.set(journey.Name, 1);
      });
      this.setState({ showJourneys: true });
    }
  }

  checkForCustomerJourneys() {
    let customerJourneyIndex = -1;
    if (this.state.data) {
      let d = this.state.data;
      let anotherArray = [];
      d.map((entry, i) => {
        if (!this.state.showJourneys && entry.name === "Customer Journey") {
          customerJourneyIndex = i;
        } else {
          anotherArray.push(entry);
        }
      });

      if (anotherArray) {
        this.setState({ data: anotherArray });
      }
    }
  }

  checkData() {
    let index = -1;
    let d = data;

    if (this.props.voicebot) {
      d = voicebotdata;
    }

    d.map((entry, i) => {
      if (!this.state.showIntegrations && entry.name === "Integrations") {
        index = i;
      }
    });

    let dataCopy = [...d];

    if (index > 0) {
      dataCopy.splice(index, 1);

      this.setState({ data: dataCopy }, () => {
        setTimeout(this.checkForCustomerJourneys, 2000);
      });
    } else {
      setTimeout(this.checkForCustomerJourneys, 2000);
    }
  }

  async getModels(account) {
    fetch("/api/trainingDataForAccount?account=" + account).then((response) => {
      response.json().then((data) => {
        if (data && data.length > 0 && data.name !== "error") {
          let models = [];
          data.map((t) => {
            models.push(t.Id);
          });

          this.setState({ models: models });
          if (this.props.accountName) {
            this.setState({ firstModel: this.props.accountName }, () => {
              this.getAccountIntegrationActions();
            });
          }

          //this.setState( {model: this.state.newModel});
        }
      });
    });
  }

  async getAccountIntegrationActions() {
    if (this.state.firstModel) {
      let model = this.state.firstModel.replaceAll("-", "");

      let url = "/api/getAccountIntegrationActions?model=" + model;

      const response = await fetch(url);

      const data = await response.json();
      this.setState({ integrations: data });

      if (data && data.length > 0) {
        this.setState({ showIntegrations: true }, () => {
          this.checkData();
        });
      } else {
        this.setState({ showIntegrations: false }, () => {
          this.checkData();
        });
      }

      let actionNames = [];
      if (data) {
        data.map((inte) => {
          actionNames.push(inte.name);
        });
      }

      this.setState({ integrationsActionNames: actionNames });
    }

    /*data.map( (integration) =>{


        })*/
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }),
      this.updateArray
    );
  };

  updateDisableInput = (disable) => {
    this.setState({ disableInput: disable });
  };

  updateArray() {
    let newData = [];
    let items = this.state.items;

    let data = JSON.parse(this.state.response);

    this.setState({ response: JSON.stringify(items) }, () => {
      this.convertWorkflowToFlowPoints();
    });
    this.passDataToResponseWorkflow(JSON.stringify(items));
    //this.passDataToResponseWorkflow(JSON.stringify());
    //this.setState({data: newData});
  }

  handleShowSort(e) {
    this.setState({ showSort: true });
    this.listOptions();
  }

  listOptions() {
    let optionList = [];
    let resp = [];
    if (this.state.response.length > 0) {
      try {
        resp = JSON.parse(this.state.response);
        resp.map((object, i) => {
          object.readableName = "";
          let found = false;

          this.state.data.map((o) => {
            if (o.manifest.type === object.type) {
              object.readableName = o.name;
              found = true;
            }
          });

          if (!found) {
            object.readableName = "Custom.action: " + object.type;
          }
          optionList.push(object);
        });

        this.setState({ items: optionList });
      } catch (error) {}
    }
  }

  componentDidMount() {
    this.setState({ data: data });

    if (this.props.voicebot) {
      this.setState({ data: voicebotdata });
    }

    this.getAccountIntegrationActions();
    this.getCustomerJourneys();

    const json = (function (raw) {
      try {
        return JSON.parse(raw);
      } catch (err) {
        return false;
      }
    })(this.props.response);

    if (json) {
      if (
        (this.props.response && this.props.response === " ") ||
        this.props.response === "[]" ||
        JSON.parse(this.props.response)[0].type === "Start"
      ) {
        this.setState({ response: JSON.stringify([]) });
      } else {
        console.log(this.props.response);
        this.setState({ response: this.props.response }, this.listOptions);
      }
    }
    if (this.props.account) {
      this.getModels(this.props.account);
    }

    this.setState({ startPositionX: 50 });
    this.setState({ value: 1 });

    if (this.props.workflowCreate) {
      this.setState({ viewPortHeight: "calc(100vh - 20px)" });
      this.setState({ workflowCreate: true });
      this.setState({ right: true });
      this.setState({ startPositionX: 100 });
    }
  }

  clearInput() {
    this.setState({ filter: "" });
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.account !== this.props.account) {
      this.setState({ data: data });
      this.setState({ account: this.props.account }, () => {
        this.getModels(this.props.account).then(this.getCustomerJourneys());
      });
    }

    if (prevProps.voicebot !== this.props.voicebot) {
      if (this.props.voicebot) {
        this.setState({ data: voicebotdata });
      }
    }

    if (
      prevProps &&
      JSON.stringify(prevProps.flowpoints) !==
        JSON.stringify(this.props.flowpoints)
    ) {
      this.setState({ flowpoints: this.props.flowpoints });
    }

    if (prevProps && prevProps.showSettings !== this.props.showSettings) {
      if (this.props.showSettings) {
        this.setState({ showSettings: true }, this.listOptions);
      } else {
        this.setState({ showSettings: false });
      }
    }
    if (prevProps && prevProps.editOrAdd !== this.props.editOrAdd) {
      if (this.props.editOrAdd) {
        this.setState({ value: 1 });
      }
    }

    if (prevState && prevProps.updatedWorkflow !== this.props.updatedWorkflow) {
      this.setState({ updatedWorkflow: this.props.updatedWorkflow });
    }

    if (prevProps && prevProps.activeWorkflow !== this.props.activeWorkflow) {
      this.setState({ prevWorkflow: prevProps.activeWorkflow });
      this.setState({ activeWorkflow: this.props.activeWorkflow });
    }

    if (
      prevState &&
      JSON.stringify(prevState.response) !== JSON.stringify(this.state.response)
    ) {
      this.setState({ flowpoints: [], linkedWorkflows: [] }, () => {
        this.convertWorkflowToFlowPoints();
      });

      /*if (JSON.stringify(prevState.response) !== "[]" && this.state.prevWorkflow === this.state.activeWorkflow) {


                this.saveWorkflow();


            }*/
      //this.setState({showUpdatedResponseSave: true});
    }

    if (
      prevProps &&
      JSON.stringify(prevProps.response) !== JSON.stringify(this.props.response)
    ) {
      this.setState(
        { response: this.props.response, linkedWorkflows: [] },
        this.listOptions
      );

      this.setState({ renderType: "" });
      this.convertWorkflowToFlowPoints();
    }

    if (
      prevProps &&
      prevProps.newNameResponseOnIntent !== this.props.newNameResponseOnIntent
    ) {
      this.setState({ value: 1 });
      this.setState({ createWorkflow: false });
    }

    if (
      prevProps &&
      prevProps.newNameCheckIfContextIsSet !==
        this.props.newNameCheckIfContextIsSet
    ) {
      this.setState({ value: 1 });
      this.setState({ createWorkflow: false });
    }

    if (prevProps && prevProps.newWorkflow !== this.props.newWorkflow) {
      if (this.props.newWorkflow === "true") {
        this.setState({ flowpoints: [] });
        this.setState({ response: [] });

        this.setState({ value: 1 });
      }
    }
    if (prevProps && prevProps.backToAdd !== this.props.backToAdd) {
      if (this.props.backToAdd) {
        this.setState({ value: 1 });
        this.setState({ createWorkflow: false });
        this.props.resetAdd();
      }
    }
  }

  passForwardParams = (params) => {
    this.setState({ passForwardParams: params });
  };

  reactOnListClick = (event, i, type) => {
    let selected = 0;
    this.setState({ selectedWorkflow: event.target.innerText });

    this.state.data.map((item, i) => {
      if (item.manifest.type === type) {
        selected = i;
      }
    });

    this.setState({ selected: selected });
    this.setState({ workFlowRender: this.state.data[selected].manifest });
    this.setState({ createWorkflow: true });
  };

  handleChange(e) {
    this.setState({ textFieldValue: e.target.value });
  }

  workflowNavigate() {
    if (this.state.workflowNavigate) {
      this.setState({ createWorkflow: false });
    } else if (this.state.value === 1 && !this.state.workflowNavigate) {
      this.setState({ createWorkflow: false });
    } else {
      this.setState({ createWorkflow: true });
    }
  }

  toggleDrawer = (side) => () => {
    if (this.state.right) {
      this.setState({
        [side]: false,
      });
    } else {
      this.setState({ value: 1 });
      this.setState({
        [side]: true,
      });
    }
  };

  makeWorkFlow = (type, data, settings) => {
    let resp = [];

    if (
      data &&
      data.initiateAiWithResponse &&
      data.initiateAiWithResponse === "New Workflow"
    ) {
      this.props.newFromResponseOnIntent();
    } else {
      try {
        if (this.state.response.constructor === String) {
          resp = JSON.parse(this.state.response);
        }
      } catch (error) {
        resp = [];
      }

      let flow = "";
      if (data && this.state.value === 0) {
        let flow = "";

        if (type === "website.options") {
          flow = {
            type: type,
            options: data,
            disableInputField: this.state.disableInput,
            optionsType: this.state.optionType,
            forwardUnmatchedMessageTo: {
              type: "puzzelai.nlu",
              pipeline: this.props.pipeline,
              account: this.props.account,
            },
          };

          if (
            settings &&
            settings.defaultOption &&
            settings.chooseDefaultAfterSec
          ) {
            flow.defaultOption = settings.defaultOption;
            flow.chooseDefaultAfterSec = settings.chooseDefaultAfterSec;
          }
        } else {
          flow = data;
        }

        let object = JSON.parse(this.state.response);
        object[this.state.selected_point] = flow;

        this.setState({ response: JSON.stringify(object) });

        this.passDataToResponseWorkflow(JSON.stringify(object));

        this.setState({ createWorkflow: false });
      } else {
        if (
          this.state.workFlowRender &&
          this.state.workFlowRender.type === "conversation.message"
        ) {
          flow = data;
        } else if (
          this.state.workFlowRender &&
          this.state.workFlowRender.sendRestResponse &&
          this.state.workFlowRender.type === "puzzelai.nlu"
        ) {
          flow = data;
        } else if (type === "website.options") {
          flow = {
            type: type,
            options: data,
            optionsType: this.state.optionType,
            disableInputField: this.state.disableInput,
            forwardUnmatchedMessageTo: {
              type: "puzzelai.nlu",
            },
          };

          if (
            settings &&
            settings.defaultOption &&
            settings.chooseDefaultAfterSec
          ) {
            flow.defaultOption = settings.defaultOption;
            flow.chooseDefaultAfterSec = settings.chooseDefaultAfterSec;
          }
        } else if (this.state.workFlowRender.type === "website.navigate") {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.waitForContext") {
          flow = data;
        } else if (this.state.workFlowRender.type === "voice.transferToHuman") {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.setContextValue") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "case.removeContextValue"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.randomNext") {
          flow = data;
        } else if (this.state.workFlowRender.type === "website.imageInChat") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "website.getContextFromPage"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.setCaseType") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "vergicAi.setIntentModel"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "website.presentUrl") {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.transferToAgent") {
          flow = { type: this.state.workFlowRender.type };
        } else if (
          this.state.workFlowRender.type === "case.checkGroupStatusAndTransfer"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.checkGroupStatus") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "case.checkNumberInQueue"
        ) {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "conversation.askForContext"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "voice.askForContext") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "voice.updateCallDetails"
        ) {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "conversation.arrayMessage"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "temp.vergicTemplate") {
          flow = data;
        } else if (this.state.workFlowRender.type === "Integrations") {
          flow = data;
        } else if (
          this.state.workFlowRender.type ===
          "website.highLightElementLocatedByTargetPath"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "LOGGER.track") {
          flow = {
            type: "LOGGER.track",
            event: this.state.activeWorkflow,
            data: {
              flow: this.state.activeWorkflow,
            },
          };
        } else if (
          this.state.workFlowRender.type === "LOGGER.customerJourney"
        ) {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.closeAsSuccess") {
          flow = {
            type: "case.closeAsSuccess",
          };
        } else if (this.state.workFlowRender.type === "case.minMaxUi") {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.timer") {
          flow = data;
        } else if (this.state.workFlowRender.type === "case.chatInput") {
          flow = data;
        } else if (
          this.state.workFlowRender.type === "vergicAi.responseOnIntent"
        ) {
          flow = data;
        } else {
          flow = data;
        }

        if (!resp) {
          resp = [];
        }

        resp.push(flow);

        this.setState({ response: JSON.stringify(resp) });
        this.passDataToResponseWorkflow(JSON.stringify(resp));

        this.setState({ value: 1 });
        this.setState({ createWorkflow: false });
      }
    }
  };

  updateLogInformation(dataToPass) {
    if (dataToPass) {
      let workflow = [];
      try {
        workflow = JSON.parse(dataToPass);
      } catch (error) {}

      workflow.map((action) => {
        if (action.type === "LOGGER.track") {
          let lastModified = moment(new Date()).format("YYYY-MM-DD HH:mm");
          action.data.lastModified = lastModified;
          action.data.lastModifiedBy = this.props.loggedInUserEmail;
        }
      });

      return JSON.stringify(workflow);
    }
  }

  passDataToResponseWorkflow(dataToPass) {
    let data = this.updateLogInformation(dataToPass);
    this.setState({ response: data });
    this.props.passData(data);
  }

  saveWorkflow() {
    // if updated flow create the new flow and save it to the current response

    this.setState({ createWorkflow: false });
    this.props.passData(this.state.response);
  }

  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }

  updatePosition() {
    if (
      this.state.position <= JSON.parse(this.state.response).length &&
      this.state.positionEvent &&
      this.state.positionEvent.oldIndex !== this.state.positionEvent.newIndex
    ) {
      var newArray = this.reorderArray(
        this.state.positionEvent,
        JSON.parse(this.state.response)
      );

      this.setState({ response: JSON.stringify(newArray) }, () => {
        this.convertWorkflowToFlowPoints();
      });

      this.passDataToResponseWorkflow(JSON.stringify(newArray));
      this.setState({ oldPosition: this.state.position + 1 });
      this.setState({ value: 1 });
    } else if (!this.state.positionEvent) {
      this.setState({ errorMessage: "The same or wrong index" });
    } else if (this.state.position > JSON.parse(this.state.response).length) {
      this.setState({ errorMessage: "Wrong index" });
    }
  }

  updateOptionType = (optionType) => {
    this.setState({ optionType: optionType });
  };

  changePosition(e) {
    var ev = {};

    if (
      e.target.value !== "" &&
      e.target.value <= JSON.parse(this.state.response).length
    ) {
      ev.newIndex = e.target.value - 1;
      ev.oldIndex = this.state.selected_point;

      this.setState({ positionEvent: ev });
    }

    this.setState({ position: e.target.value });
  }

  handleContextChange(e) {
    this.setState({ context: e.target.value });
  }

  passWorkflowToOpen = (value) => {
    this.setState({ value: 1 });

    this.props.passWorkflowToOpen(value);
  };

  renderWorkflowForm() {
    if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "conversation.message"
    ) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <ConversationMessage
            roles={this.props.roles}
            passData={this.makeWorkFlow}
          />
        </div>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "puzzelai.nlu" &&
      this.state.workFlowRender.sendRestResponse
    ) {
      return (
        <AgentAssist
          passData={this.makeWorkFlow}
          model={this.props.model}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "website.options"
    ) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <OptionsAction
            saveWorkflow={this.makeWorkFlow}
            passForwardParams={this.passForwardParams}
            model={this.props.model}
            passWorkflowToOpen={this.passWorkflowToOpen}
            account={this.props.account}
            pipeline={this.props.pipeline}
            workflowCreate={this.props.workflowCreate}
            updateOptionsType={this.updateOptionType}
            openWorkflow={this.openWorkflow}
            newWorkflow={this.newWorkflow}
            updateDisableInput={this.updateDisableInput}
            newWorkflowResponseOnIntent={this.props.newFromResponseOnIntent}
            newNameResponseOnIntent={this.props.newNameResponseOnIntent}
            saveWorkflowResponseOnIntent={this.makeWorkFlow}
          />
        </div>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "voice.recognize"
    ) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <VoiceOptions
            saveWorkflow={this.makeWorkFlow}
            passForwardParams={this.passForwardParams}
            model={this.props.model}
            passWorkflowToOpen={this.passWorkflowToOpen}
            account={this.props.account}
            pipeline={this.props.pipeline}
            workflowCreate={false} // not showing new workflow for now
            updateOptionsType={this.updateOptionType}
            openWorkflow={this.openWorkflow}
            newWorkflow={this.newWorkflow}
            updateDisableInput={this.updateDisableInput}
            newWorkflowContextSwitch={this.props.newFromContextSwitch}
            newWorkflowResponseOnIntent={this.props.newFromResponseOnIntent}
            newNameResponseOnIntent={this.props.newNameResponseOnIntent}
            newNameContextSwitch={this.props.newNameContextSwitch}
            default={this.props.default}
            saveWorkflowResponseOnIntent={this.makeWorkFlow}
          />
        </div>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.contextSwitch"
    ) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <ContextSwitch
            saveWorkflow={this.makeWorkFlow}
            passForwardParams={this.passForwardParams}
            model={this.props.model}
            passWorkflowToOpen={this.passWorkflowToOpen}
            account={this.props.account}
            pipeline={this.props.pipeline}
            workflowCreate={this.props.workflowCreate}
            updateOptionsType={this.updateOptionType}
            openWorkflow={this.openWorkflow}
            newWorkflow={this.newWorkflow}
            updateDisableInput={this.updateDisableInput}
            newWorkflowContextSwitch={this.props.newFromContextSwitch}
            newWorkflowResponseOnIntent={this.props.newFromResponseOnIntent}
            newNameResponseOnIntent={this.props.newNameResponseOnIntent}
            newNameContextSwitch={this.props.newNameContextSwitch}
            default={this.props.default}
            saveWorkflowResponseOnIntent={this.makeWorkFlow}
          />
        </div>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.entitySwitch"
    ) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <EntitySwitch
            saveWorkflow={this.makeWorkFlow}
            passForwardParams={this.passForwardParams}
            model={this.props.model}
            passWorkflowToOpen={this.passWorkflowToOpen}
            account={this.props.account}
            pipeline={this.props.pipeline}
            workflowCreate={this.props.workflowCreate}
            updateOptionsType={this.updateOptionType}
            openWorkflow={this.openWorkflow}
            newWorkflow={this.newWorkflow}
            updateDisableInput={this.updateDisableInput}
            newWorkflowEntitySwitch={this.props.newFromEntitySwitch}
            newWorkflowResponseOnIntent={this.props.newFromResponseOnIntent}
            newNameResponseOnIntent={this.props.newNameResponseOnIntent}
            newNameEntitySwitch={this.props.newNameEntitySwitch}
            default={this.props.default}
            saveWorkflowResponseOnIntent={this.makeWorkFlow}
          ></EntitySwitch>
        </div>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "website.navigate"
    ) {
      return <WebsiteNavigate passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "website.imageInChat"
    ) {
      return <ImageInChat passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "website.getContextFromPage"
    ) {
      return <GetContextFromPage passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.setCaseType"
    ) {
      return <SetCaseType passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "vergicAi.setIntentModel"
    ) {
      return <SetIntentModel passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "website.presentUrl"
    ) {
      return <PresentUrl passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.transferToAgent"
    ) {
      return (
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          onClick={this.makeWorkFlow}
        >
          Add
        </Button>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.checkGroupStatusAndTransfer"
    ) {
      return <CheckGroupStatusAndTransfer passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.checkGroupStatus"
    ) {
      return (
        <CheckGroupStatus
          workflowCreate={this.state.workflowCreate}
          passData={this.makeWorkFlow}
          openWorkflow={this.openWorkflow}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.checkNumberInQueue"
    ) {
      return (
        <CheckNumberInQueue
          workflowCreate={this.state.workflowCreate}
          passData={this.makeWorkFlow}
          openWorkflow={this.openWorkflow}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "conversation.askForContext"
    ) {
      return (
        <AskForContext
          passData={this.makeWorkFlow}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "voice.askForContext"
    ) {
      return (
        <VoiceAskForContext
          passData={this.makeWorkFlow}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "voice.updateCallDetails"
    ) {
      return (
        <VoiceUpdateCallDetails
          passData={this.makeWorkFlow}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "conversation.arrayMessage"
    ) {
      return (
        <ArrayMessage
          passData={this.makeWorkFlow}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "temp.vergicTemplate"
    ) {
      return <VergicTemplate passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type ===
        "website.highLightElementLocatedByTargetPath"
    ) {
      return <HighlightElement passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "vergicAi.responseOnIntent"
    ) {
      return (
        <ResponseOnIntent
          passData={this.makeWorkFlow}
          model={this.props.model}
          pipeline={this.props.pipeline}
          account={this.props.account}
          workflowCreate={this.props.workflowCreate}
          openWorkflow={this.openWorkflow}
          newNameResponseOnIntent={this.props.newNameResponseOnIntent}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "LOGGER.track"
    ) {
      return (
        <Button
          style={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
            fontWeight: "bold",
          }}
          variant="contained"
          color="primary"
          onClick={this.makeWorkFlow}
        >
          Add
        </Button>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.timer"
    ) {
      return <Timer passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.chatInput"
    ) {
      return <ChatInput passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.minMaxUi"
    ) {
      return <MinMaxUi passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.closeAsSuccess"
    ) {
      return (
        <Button
          style={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
            fontWeight: "bold",
          }}
          variant="contained"
          color="primary"
          onClick={this.makeWorkFlow}
        >
          Add
        </Button>
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "LOGGER.customerJourney" &&
      this.state.showJourneys
    ) {
      return (
        <CustomerJourneyAction
          passData={this.makeWorkFlow}
          accountName={this.props.accountName}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "custom.action"
    ) {
      return <CustomAction passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "Integrations" &&
      this.state.showIntegrations
    ) {
      return (
        <Integrations
          passData={this.makeWorkFlow}
          accountName={this.props.accountName}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.checkIfContextIsSet"
    ) {
      return (
        <CheckIfContextIsSet
          passData={this.makeWorkFlow}
          model={this.props.model}
          pipeline={this.props.pipeline}
          account={this.props.account}
          newWorkflowCheckIfContextIsSet={this.props.newFromCheckIfContextIsSet}
          newNameCheckIfContextIsSet={this.props.newNameCheckIfContextIsSet}
          newFromCheckIfContextIsSetType={
            this.props.newFromCheckIfContextIsSetType
          }
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.waitForContext"
    ) {
      return <WaitForContext passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "voice.transferToHuman"
    ) {
      return <VoiceTransfer passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.setContextValue"
    ) {
      return <SetContextValue passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.removeContextValue"
    ) {
      return <RemoveContext passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.randomNext"
    ) {
      return <RandomNext passData={this.makeWorkFlow} />;
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.executeOnTime"
    ) {
      return (
        <ExecuteOnTime
          passData={this.makeWorkFlow}
          model={this.props.model}
          pipeline={this.props.pipeline}
          account={this.props.account}
        />
      );
    } else if (
      this.state.workFlowRender &&
      this.state.workFlowRender.type === "case.executeOnDate"
    ) {
      return (
        <ExecuteOnDate
          passData={this.makeWorkFlow}
          model={this.props.model}
          pipeline={this.props.pipeline}
          account={this.props.account}
        />
      );
    }
  }

  handleGroupIdChange(e) {
    this.setState({ groupId: e.target.value });
  }

  listRow() {
    if (this.state.data) {
      return this.state.data
        .filter((object) => {
          //remove names that doesnt match current filter text
          return (
            object.name
              .toLowerCase()
              .indexOf(this.state.filter.toLowerCase()) >= 0
          );
        })
        .map((object, i) => {
          return (
            <ListItem
              classes={{ root: this.props.classes.list }}
              key={i}
              selected={this.state.selected === i}
              button
            >
              <ListItemIcon>
                <div>
                  {object.manifest.type === "conversation.message" ? (
                    <Message />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "puzzelai.nlu" &&
                  object.manifest.sendRestResponse ? (
                    <AgentAssistIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "website.options" ? (
                    <Menu />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.contextSwitch" ? (
                    <SyncAltIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "voice.recognize" ? (
                    <PermPhoneMsgIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.entitySwitch" ? (
                    <SpellcheckIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "website.navigate" ? (
                    <NavigateNext />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.waitForContext" ? (
                    <PauseIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "voice.transferToHuman" ? (
                    <PhoneIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.setContextValue" ? (
                    <CheckBoxIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.removeContextValue" ? (
                    <DeleteIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.randomNext" ? (
                    <SwapHorizontalCircle />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "website.imageInChat" ? (
                    <Image />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "website.getContextFromPage" ? (
                    <WebIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.setCaseType" ? (
                    <SettingsInputComponentIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "vergicAi.setIntentModel" ? (
                    <ListAltIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "website.presentUrl" ? (
                    <Link />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type ===
                  "case.checkGroupStatusAndTransfer" ? (
                    <TransferWithinAStation />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.checkGroupStatus" && (
                    <GroupIcon />
                  )}
                  {object.manifest.type === "case.checkNumberInQueue" && (
                    <TimerIcon />
                  )}
                  {object.manifest.type === "conversation.askForContext" ? (
                    <Bookmark />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "voice.askForContext" ? (
                    <Bookmark />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "voice.updateCallDetails" ? (
                    <InfoIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "conversation.arrayMessage" ? (
                    <FormatListNumberedIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "temp.vergicTemplate" ? (
                    <Receipt />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type ===
                  "website.highLightElementLocatedByTargetPath" ? (
                    <Highlight />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "vergicAi.responseOnIntent" ? (
                    <Save />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "LOGGER.track" ? (
                    <TrendingUpIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.checkIfContextIsSet" ? (
                    <HelpIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "LOGGER.customerJourney" ? (
                    <FlightTakeoffIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "custom.action" ? (
                    <DeveloperMode />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.timer" ? (
                    <TimerIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.chatInput" ? (
                    <InputIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.minMaxUi" ? (
                    <MinimizeIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "case.closeAsSuccess" ? (
                    <StopCircleIcon />
                  ) : (
                    <div></div>
                  )}
                  {object.manifest.type === "Integrations" && (
                    <IntegrationInstructionsIcon />
                  )}
                  {object.manifest.type === "case.executeOnTime" && (
                    <AccessTimeIcon />
                  )}
                  {object.manifest.type === "case.executeOnDate" && (
                    <TodayIcon />
                  )}
                </div>
              </ListItemIcon>
              <ListItemText
                onClick={(event) =>
                  this.reactOnListClick(event, i, object.manifest.type)
                }
                id={i}
                classes={{ primary: this.props.classes.listItemText }}
                primary={object.name}
              />
            </ListItem>
          );
        });
    }
  }

  handleChangeFilter(e) {
    this.setState({ filter: e.target.value });
  }

  convertWorkflowToFlowPoints() {
    if (this.state.response !== " ") {
      let resp = null;

      try {
        resp = JSON.parse(this.state.response);
      } catch (error) {
        resp = this.state.response;
      }

      let output = -1;
      let flowpoints = [];
      let linked = [];
      if (resp && Array.isArray(resp)) {
        resp.map((val, index) => {
          let linkedWorkflows = [];
          let name = "";
          let found = false;

          let splitType = val.type.split(".");

          this.state.data.map((object) => {
            if (val && val.type && object.manifest.type === val.type) {
              found = true;
              name = object.name;
            }
          });
          if (
            this.state.firstModel &&
            splitType.length > -1 &&
            splitType[0] === this.state.firstModel
          ) {
            name = "Integrations";
          } else if (!found) {
            name = "Custom Action";
          }
          //const object = response[response.length - 1 - index];
          let content = "";
          if (val.message && val.type === "conversation.message") {
            content = val.message;
          } else if (val.message && val.type === "voice.transferToHuman") {
            content = val.queueDesc;
          } else if (val.type === "puzzelai.nlu" && val.sendRestResponse) {
            //No content agent assist action
            content = "";
          } else if (val.type === "voice.recognize") {
            let switches = val.options;

            let list = [];
            let indexOfLink = output + 1;
            switches.map((sw, i) => {
              if (sw.onMatch && sw.onMatch.initiateAiWithResponse) {
                list.push(sw.contains.toString());
                let option = {
                  type: "switch",
                  accepts: sw.contains.toString(),
                  flow: sw.onMatch.initiateAiWithResponse,
                  linkedOutput: indexOfLink,
                  optionIndex: i,
                };

                linkedWorkflows.push(option);
              }
            });

            content = list;
          } else if (val.type === "case.contextSwitch") {
            let switches = val.switches;

            let list = [];
            let indexOfLink = output + 1;
            switches.map((sw, i) => {
              if (sw.onMatch && sw.onMatch.initiateAiWithResponse) {
                list.push(sw.accepts.toString());
                let option = {
                  type: "switch",
                  accepts: sw.accepts.toString(),
                  flow: sw.onMatch.initiateAiWithResponse,
                  linkedOutput: indexOfLink,
                  optionIndex: i,
                };

                linkedWorkflows.push(option);
              }
            });

            let option = {
              type: "switch",
              accepts: "Default",
              flow: val.default.initiateAiWithResponse,
              linkedOutput: indexOfLink,
              optionIndex: list.length + 1,
            };
            linkedWorkflows.push(option);
            content = "Context Variable: " + val.contextKey;
          } else if (val.type === "case.entitySwitch") {
            let switches = val.switches;

            let list = [];
            let indexOfLink = output + 1;
            switches.map((sw, i) => {
              if (sw.onMatch && sw.onMatch.initiateAiWithResponse) {
                let option = {
                  type: "switch",
                  accepts: sw && sw.accepts ? sw.accepts.toString() : "",
                  flow: sw.onMatch.initiateAiWithResponse,
                  linkedOutput: indexOfLink,
                  optionIndex: i,
                };
                linkedWorkflows.push(option);
              }
            });

            let option = {
              type: "switch",
              accepts: "Default",
              flow: val.default.initiateAiWithResponse,
              linkedOutput: indexOfLink,
              optionIndex: list.length + 1,
            };
            linkedWorkflows.push(option);
            content = list;
          } else if (val.type === "website.options") {
            let options = val.options;
            let list = [];

            let indexOfLink = output + 1;

            for (var i = 0; i < options.length; i++) {
              list.push(options[i].text);
              if (
                options[i].onMatch &&
                options[i].onMatch.initiateAiWithResponse &&
                options[i].onMatch.initiateAiWithResponse
                  .toLowerCase()
                  .indexOf("gonext") === -1
              ) {
                let option = {
                  type: "options",
                  flow: options[i].onMatch.initiateAiWithResponse,
                  linkedOutput: indexOfLink,
                  optionIndex: i,
                };

                linkedWorkflows.push(option);
              }
            }
            content = list;
          } else if (val.type === "website.imageInChat") {
            content = val.path;
          } else if (val.type === "website.getContextFromPage") {
            content = val.selector;
          } else if (val.type === "LOGGER.customerJourney") {
            let list = [];
            list.push("stacked");
            list.push("Journey: " + val.data.journeyName);
            list.push("Group: " + val.data.groupName);
            list.push("Checkpoint: " + val.data.checkpointName);
            content = list;
          } else if (val.type === "case.setCaseType") {
            content = val.caseName;
          } else if (val.type === "vergicAi.setIntentModel") {
            content = val.model;
          } else if (val.type === "case.removeContextValue") {
            content = val.key;
          } else if (val.type === "case.chatInput") {
            content = val.trigger === "enable" ? "Enabled" : "Disabled";
          } else if (val.type === "case.minMaxUi") {
            content = val.trigger === "maximize" ? "Maximized" : "Minmized";
          } else if (val.type === "case.timer") {
            content = eval(val.timeout) / 1000 + " sec";
          } else if (val.type === "case.checkIfContextIsSet") {
            let method = val.method ? val.method : "";
            let key = val.key ? val.key : "";
            let value = val.value ? val.value : "";

            if (val.contextType && val.contextType === "caseTypeId") {
              key = "Case Type";
              value = val.caseName;
            }

            content = key + " " + method + " " + value;

            let onMatch = "Workflow Go Next#" + index;
            if (val.onMatch && val.onMatch.initiateAiWithResponse) {
              onMatch = val.onMatch.initiateAiWithResponse;
            }
            let onFailure = "Workflow Go Next#" + index;
            if (val.onFailure && val.onFailure.initiateAiWithResponse) {
              onFailure = val.onFailure.initiateAiWithResponse;
            }

            linkedWorkflows.push({ type: "onMatch", flow: onMatch });
            linkedWorkflows.push({ type: "onFailure", flow: onFailure });
          } else if (val.type === "case.checkGroupQueuesStatus") {
            let threshold = val.threshold ? val.threshold : "";

            let onMatch = "Workflow Go Next#" + index;
            if (val.onMatch && val.onMatch.initiateAiWithResponse) {
              onMatch = val.onMatch.initiateAiWithResponse;
            }
            let onFailure = "Workflow Go Next#" + index;
            if (val.onFailure && val.onFailure.initiateAiWithResponse) {
              onFailure = val.onFailure.initiateAiWithResponse;
            }

            linkedWorkflows.push({ type: "onMatch", flow: onMatch });
            linkedWorkflows.push({ type: "onFailure", flow: onFailure });
          } else if (
            val.type === "case.checkGroupStatus" ||
            val.type === "case.checkGroupStatusAndTransfer"
          ) {
            let onOpen = "";
            if (val.onOpen && val.onOpen.initiateAiWithResponse) {
              onOpen = val.onOpen.initiateAiWithResponse;
            } else if (val.transferFlows && val.groupId) {
              onOpen = false;
            }

            let onBusy = "";
            if (val.onBusy && val.onBusy.initiateAiWithResponse) {
              onBusy = val.onBusy.initiateAiWithResponse;
            } else if (val.transferFlows && val.transferFlows.busy) {
              onBusy = val.transferFlows.busy;
            }

            let onClosed = "";
            if (val.onClosed && val.onClosed.initiateAiWithResponse) {
              onClosed = val.onClosed.initiateAiWithResponse;
            } else if (val.transferFlows && val.transferFlows.closed) {
              onClosed = val.transferFlows.closed;
            }
            if (onOpen) {
              linkedWorkflows.push({ type: "onOpen", flow: onOpen });
            }

            linkedWorkflows.push({ type: "onBusy", flow: onBusy });
            linkedWorkflows.push({ type: "onClosed", flow: onClosed });
          } else if (val.key) {
            content = val.key;
          } else if (val.context) {
            content = val.context;
          } else if (val.initiateAiWithResponse) {
            linkedWorkflows.push({
              type: "Saved Workflow",
              flow: val.initiateAiWithResponse,
            });

            content = val.initiateAiWithResponse;
            if (val.category) {
              content += ": " + val.category;
            }
          } else if (val.url) {
            content = val.url;
          } else if (
            this.state.firstModel &&
            splitType.length > -1 &&
            splitType[0] === this.state.firstModel
          ) {
            let object = val;
            let newObject = {};
            newObject.type = "Integrations";
            //newObject.content = object;
            newObject.content = val.type;

            object.content = "Integrations " + JSON.stringify(newObject);
            val = newObject;
            content = newObject.content;
          } else if (!found) {
            let object = val;
            let newObject = {};
            newObject.type = "custom.action";
            //newObject.content = object;
            newObject.content = val.type;

            object.content = "Custom Action " + JSON.stringify(newObject);
            val = newObject;
            content = newObject.content;
          }

          let flowpointObj = {
            key: index,
            type: val.type,
            name: name,
            position: { y: 155 * index },
            outputs: [output],
            content: content,
            linkedWorkflows: linkedWorkflows,
          };

          flowpoints.push(flowpointObj);
          output = index;

          if (linkedWorkflows.length > 0) {
            linkedWorkflows.map((flow, i) => {
              let yPos = 0;
              if (index === 0) {
                yPos = 50 * 1 + (55 * i + 1);
              } else {
                yPos = 220 * index + (55 * i + 1);
              }

              let flowName = "";
              if (flow.flow) {
                flowName = flow.flow.replace(/\s/g, "");
              }

              if (
                !isNaN(flow.flow.charAt(flow.flow.length - 1)) &&
                (flow.flow.charAt(flow.flow.length - 2) === "#" ||
                  flow.flow.charAt(flow.flow.length - 3) === "#") &&
                (flow.type === "onMatch" || flow.type === "onFailure")
              ) {
                flow.flow = flow.flow.substr(0, flow.flow.length - 2);
              }

              let oIndex = "";

              if (flow.optionIndex || flow.optionIndex === 0) {
                oIndex = flow.optionIndex + 1 + ". ";
              }

              if (flow.type === "switch") {
                oIndex = flow.accepts + ": ";
              }

              if (flow.type === "onMatch") {
                oIndex = "True: ";
              }

              if (flow.type === "onFailure") {
                oIndex = "False: ";
              }

              if (flow.type === "onOpen") {
                oIndex = "Open: ";
              } else if (flow.type === "onBusy") {
                oIndex = "Busy: ";
              } else if (flow.type === "onClosed") {
                oIndex = "Closed: ";
              }
              let outputArr = [];
              outputArr.push(output);
              let Obj = {
                key: flowName + "##" + i,
                option: true,
                type: "Option Link",
                name: flow.flow,
                outputs: [...outputArr],
                content: oIndex + flow.flow,
                position: { x: 155, y: yPos },
              };

              flowpoints.push(Obj);
            });
          }

          output = index;

          linked = [...linked, ...linkedWorkflows];
        });
      }
      let onlyFlowName = [];
      linked.map((l) => {
        onlyFlowName.push(l.flow);
      });

      const toFindDuplicates = (onlyFlowName) =>
        onlyFlowName.filter(
          (item, index) => onlyFlowName.indexOf(item) !== index
        );
      const duplicateElementa = toFindDuplicates(onlyFlowName);

      if (duplicateElementa.length > 0) {
        flowpoints.map((point) => {
          if (point.type === "Option Link") {
            if (duplicateElementa.indexOf(point.name) > -1) {
              var r = Math.random().toString(36).substring(2);

              point.key = point.key + "#" + r;
            }
          }
        });
      }

      this.setState({ flowpoints: flowpoints });

      this.setState({ duplicates: duplicateElementa });
    }
  }

  onFlowClick = (event, key, name) => {
    this.setState({ linkedWorkflows: [] });

    if (isNaN(key)) {
      let keyArray = key.split("#");

      if (keyArray.length >= 2 && name.indexOf("Workflow Go Next") === -1) {
        this.openWorkflow(name);
      }
    } else {
      this.setState({ showSettings: false });
      this.setState({ agentAssist: false });

      this.setState({ flowpoints: this.state.flowpoints });

      //e.target.style.borderColor = "lightBlue";
      var selected_point = this.state.selected_point;

      selected_point = key;

      this.setState({ value: 0 });

      let renderType = JSON.parse(this.state.response)[key].type;

      let found = false;
      this.state.data.map((object) => {
        if (object.manifest && object.manifest.type === renderType) {
          found = true;
        }
      });

      if (
        this.state.integrationsActionNames &&
        this.state.integrationsActionNames.indexOf(renderType) > -1
      ) {
        renderType = "Integrations";
      } else if (!found) {
        renderType = "custom.action";
      }

      this.setState({ renderType: renderType });
      if (
        renderType === "puzzelai.nlu" &&
        JSON.parse(this.state.response)[key] &&
        JSON.parse(this.state.response)[key].sendRestResponse
      ) {
        this.setState({ agentAssist: true });
      }

      this.setState({ selected_point });
      this.setState({ position: selected_point + 1 });
      this.setState({ right: true });
      this.setState({ errorMessage: "" });
    }
  };

  renderFlowPoints() {
    let count = 0;
    let lastWebsiteOptions = -1;
    let lastWebsiteOptionsLength = -1;

    return this.state.flowpoints.map((point, key) => {
      if (point.option) {
        count++;
      }

      if (point.type && point.type === "website.options") {
        lastWebsiteOptions = key;
        lastWebsiteOptionsLength = point.content.length;
      }

      return (
        <Flowpoint
          onClick={(e) => this.onFlowClick(e, point.key, point.name)}
          startPosition={
            point.startPoint && point.startPoint !== ""
              ? {
                  x: this.state.startPositionX + point.startPoint.x,
                  y: point.startPoint.y,
                }
              : {
                  x: point.option
                    ? this.state.startPositionX + 400
                    : this.state.startPositionX,
                  y:
                    point.type === "website.options" && point.content.length > 2
                      ? key !== 0
                        ? 225 * key
                        : 0
                      : point.option
                      ? point.position.y
                      : key > 0 &&
                        lastWebsiteOptions !== -1 &&
                        this.state.flowpoints[lastWebsiteOptions].type ===
                          "website.options"
                      ? lastWebsiteOptionsLength !== -1 &&
                        lastWebsiteOptionsLength > 4
                        ? 255 * (key - count)
                        : 225 * (key - count)
                      : 220 * (key - count),
                }
          }
          outputs={point.outputs}
          key={point.key}
          style={{
            padding: "10px",
            minHeight:
              point.type === "website.options" && point.content.length > 2
                ? (point.content.length * 38.1).toString() + "px"
                : point.option
                ? "45px"
                : "100px",
          }}
          selected={this.state.selected_point === point.key}
          id={point.key}
          width={
            point.option
              ? point.content.length < 15
                ? point.content.length * 12
                : point.content.length * 9
              : 300
          }
          height={
            point.type === "website.options" && point.content.length > 2
              ? point.content.length * 38.1
              : point.option
              ? 45
              : 100
          }
          dragX={false}
          dragY={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "flex-end",
              width: "100%",
              justifyContent: "right",
            }}
          >
            {!point.option ? (
              "(" + eval(point.key + 1) + ")"
            ) : (
              <div>&nbsp;</div>
            )}
          </div>
          <div
            style={{
              wordBreak: "break-all",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              flexGrow: "1",
              marginTop: "-20px",
            }}
          >
            {point.type === "conversation.message" ? (
              <Message style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "puzzelai.nlu" && point.sendRestResponse ? (
              <AgentAssistIcon />
            ) : (
              <div></div>
            )}
            {point.type === "case.contextSwitch" ? (
              <SyncAltIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "voice.recognize" ? (
              <PermPhoneMsgIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.entitySwitch" ? (
              <SpellcheckIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.options" ? (
              <Menu style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.navigate" ? (
              <NavigateNext style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.waitForContext" ? (
              <PauseIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "voice.transferToHuman" ? (
              <PhoneIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.setContextValue" ? (
              <CheckBoxIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.removeContextValue" ? (
              <DeleteIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.randomNext" ? (
              <SwapHorizontalCircle style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.imageInChat" ? (
              <Image style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.getContextFromPage" ? (
              <WebIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.setCaseType" ? (
              <SettingsInputComponentIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "vergicAi.setIntentModel" ? (
              <ListAltIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.presentUrl" ? (
              <Link style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.checkGroupStatusAndTransfer" ? (
              <TransferWithinAStation style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.checkGroupStatus" && (
              <GroupIcon style={{ marginRight: "10px" }} />
            )}
            {point.type === "case.checkNumberInQueue" && (
              <TimerIcon style={{ marginRight: "10px" }} />
            )}
            {point.type === "conversation.askForContext" ? (
              <Bookmark style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "voice.askForContext" ? (
              <Bookmark style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "voice.updateCallDetails" ? (
              <InfoIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "conversation.arrayMessage" ? (
              <FormatListNumberedIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "temp.vergicTemplate" ? (
              <Receipt style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "website.highLightElementLocatedByTargetPath" ? (
              <Highlight style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "vergicAi.responseOnIntent" ? (
              <Save style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "LOGGER.track" ? (
              <TrendingUpIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "LOGGER.customerJourney" ? (
              <FlightTakeoffIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "custom.action" ? (
              <DeveloperMode style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "Integrations" && this.state.showIntegrations && (
              <IntegrationInstructionsIcon />
            )}
            {point.type === "case.closeAsSuccess" ? (
              <StopCircleIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.timer" ? (
              <TimerIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.chatInput" ? (
              <InputIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.minMaxUi" ? (
              <MinimizeIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}

            {point.type === "case.checkIfContextIsSet" ? (
              <HelpIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.executeOnTime" ? (
              <AccessTimeIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}
            {point.type === "case.executeOnDate" ? (
              <TodayIcon style={{ marginRight: "10px" }} />
            ) : (
              <div></div>
            )}

            {!point.option && point.name}
          </div>

          {point.type === "website.imageInChat" ? (
            <img style={{ maxHeight: "55px" }} src={point.content} />
          ) : (
            ""
          )}

          {point.content instanceof Object &&
          Array.isArray(point.content) &&
          point.content.length > 0 &&
          point.content[0] === "stacked" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "3px",
                margin: "5px",
                marginTop: "-2px",
              }}
            >
              {" "}
              {point.content.map((object, i) => {
                if (i > 0) {
                  return (
                    <div key={Math.random()} style={{ marginLeft: "10px" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "80px", fontSize: "12px" }}>
                          {object.split(":")[0]}
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          {object.split(":")[1]}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            " "
          )}
          {point.content instanceof Object &&
          Array.isArray(point.content) &&
          point.content.length > 0 &&
          point.content[0] !== "stacked" ? (
            <ol style={{ padding: "3px", margin: "5px", marginTop: "-2px" }}>
              {" "}
              {point.content.map((object) => {
                return (
                  <li key={Math.random()} style={{ marginLeft: "10px" }}>
                    {object}
                  </li>
                );
              })}
            </ol>
          ) : (
            " "
          )}

          {point.content instanceof Object ||
          point.type === "website.imageInChat" ? (
            " "
          ) : (
            <Tooltip title={point.content} aria-label={point.content}>
              <div style={{ wordBreak: "break-all" }}>
                {point.content && point.content.length > 130
                  ? point.content.substring(0, 100) + "..."
                  : point.content}
              </div>
            </Tooltip>
          )}
        </Flowpoint>
      );
    });
  }

  removeFlow() {
    this.setState({ renderType: "" });
    let arr = this.state.flowpoints.splice(this.state.selected_point, 1);

    this.setState({ flowpoints: this.state.flowpoints });

    if (this.state.flowpoints.length === 0) {
      this.setState({ value: 1 });
    }
    let response = JSON.parse(this.state.response);
    let arr2 = response.splice(this.state.selected_point, 1);
    this.setState({ response: JSON.stringify(response) }, this.saveWorkflow);
    if (this.state.workflowCreate) {
      this.passDataToResponseWorkflow(JSON.stringify(response));
    }

    this.setState({ value: 1 });
  }

  reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find(
      (item, index) => index === event.oldIndex
    );
    const remainingItems = originalArray.filter(
      (item, index) => index !== event.oldIndex
    );

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];

    return reorderedItems;
  };

  reOrderItems() {}

  convertFlowToResponse() {}

  handleChangeMessage(event) {
    this.state.flowpoints[this.state.selected_point].content =
      event.target.value;

    this.setState({ flowpoints: this.state.flowpoints });
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleMessageChange = (message) => {
    let newResp = JSON.parse(this.state.response);
    newResp[this.state.selected_point].message = message;
    this.setState({ response: JSON.stringify(newResp) });
    this.makeWorkFlow("conversation.message", message);
  };

  openWorkflow = (value) => {
    if (this.state.workflowCreate) {
      this.props.passWorkflowToOpen(value);
    } else {
      this.props.openWorkflow(value);
    }
  };

  newWorkflow() {
    this.props.newWorkflow();
  }

  render() {
    const SortableContainer = sortableContainer(({ children }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height:
              this.state.response.length > 1
                ? JSON.parse(this.state.response).length * 75
                : "400px",
            maxHeight: "800px",
            backgroundColor: "#DDD",
            padding: "8px",
            overflow: "auto",
            marginTop: "20px",
            marginBottom: "20px",

            borderTop: "1px solid #ccc",
            borderLeft: "1px solid #ccc",
            borderRight: "1px solid #ccc",
          }}
        >
          {children}
        </div>
      );
    });
    const { classes } = this.props;
    return (
      <div style={{ height: "100%" }}>
        {!this.state.workflowCreate ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "-8px",
              marginTop: "10px",
              marginRight: "10px",
              width: "100%",
            }}
          >
            {/*  <Tooltip title="Add Response">
                            <Fab color="primary" onClick={this.toggleDrawer('right')} aria-label="Add Response"
                                 className={classes.fab} size="small">
                                <AddIcon/>
                            </Fab>
                        </Tooltip>*/}
            {this.state.showUpdatedResponseSave ? (
              <Tooltip title="Save Response">
                <Fab
                  color="secondary"
                  onClick={this.saveWorkflow}
                  aria-label="Save Response"
                  className={classes.fab}
                  size="small"
                >
                  <SaveIcon />
                </Fab>
              </Tooltip>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div></div>
        )}

        <div style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
          <div
            style={{
              height: this.state.viewPortHeight,
              overflowY: "auto",
              overflowX: "hidden",
              marginTop: "20px",
            }}
          >
            <Drawer
              anchor="right"
              variant="persistent"
              open={this.state.right}
              onClose={this.toggleDrawer("right", false)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {this.state.showSettings ? (
                <div
                  style={{
                    width: "360px",
                    overflowX: "hidden",
                    wordBreak: "break-all",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginTop: "70px",
                  }}
                >
                  <Fab
                    color="primary"
                    onClick={() => {
                      this.setState({ showSettings: false });
                      //this.props.forwardSetting();
                    }}
                    aria-label="Back"
                    size={"small"}
                    style={{ marginBottom: "10px" }}
                    className={classes.fab}
                  >
                    <ArrowBack />
                  </Fab>

                  <h3>Rearrange Workflow Actions</h3>

                  <SortableContainer onSortEnd={this.onSortEnd}>
                    {this.state.items.map((value, index) => (
                      <SortableItem
                        helperClass="sortableHelper"
                        key={`item-${index}`}
                        index={index}
                        value={value}
                      />
                    ))}
                  </SortableContainer>
                </div>
              ) : (
                <div>
                  <Tabs
                    value={this.state.value}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onChange={this.handleTabChange}
                  >
                    <Tab label="Edit" />
                    <Tab label="Add" />
                  </Tabs>

                  {this.state.value === 0 && this.state.renderType && (
                    <div
                      style={{
                        width: "360px",
                        wordBreak: "break-all",
                        display: "flex",
                        overflowX: "hidden",
                        flexDirection: "column",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <h3>Workflow Item</h3>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Settings />
                            <div style={{ marginLeft: "10px" }}>Settings</div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{ dislay: "flex", flexDirection: "column" }}
                          >
                            {this.state.renderType !== "" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  value={this.state.position}
                                  onChange={this.changePosition}
                                  margin="normal"
                                  multiline={false}
                                  variant="filled"
                                  label="Position"
                                  style={{ width: "40%" }}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "10px",
                                    width: "100%",
                                  }}
                                  onClick={this.updatePosition}
                                >
                                  Update Position
                                </Button>
                              </div>
                            ) : (
                              <div></div>
                            )}

                            {this.state.renderType !== "" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "20px", width: "100%" }}
                                onClick={this.removeFlow}
                              >
                                Delete Selected
                              </Button>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <div style={{ marginTop: "20px" }}>
                        {this.state.renderType && this.state.agentAssist ? (
                          <AgentAssist
                            passData={this.makeWorkFlow}
                            value={
                              this.state.response &&
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        ) : (
                          this.state.renderType
                        )}
                      </div>

                      {this.state.errorMessage !== "" ? (
                        this.state.errorMessage
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "conversation.message" ? (
                        <div>
                          <ConversationMessage
                            passData={this.makeWorkFlow}
                            roles={this.props.roles}
                            value={
                              this.state.response &&
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType ===
                        "website.getContextFromPage" && (
                        <div>
                          <GetContextFromPage
                            passData={this.makeWorkFlow}
                            value={
                              this.state.response &&
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "website.options" ? (
                        <OptionsAction
                          saveWorkflow={this.makeWorkFlow}
                          data={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ].options
                          }
                          passForwardParams={this.passForwardParams}
                          model={this.props.model}
                          passWorkflowToOpen={this.passWorkflowToOpen}
                          pipeline={this.props.pipeline}
                          defaultOption={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ].defaultOption
                          }
                          chooseDefaultAfterSec={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ].chooseDefaultAfterSec
                          }
                          optionsType={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ].optionsType
                          }
                          disableInputField={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ].disableInputField
                          }
                          account={this.props.account}
                          workflowCreate={this.state.workflowCreate}
                          updateOptionsType={this.updateOptionType}
                          updateDisableInput={this.updateDisableInput}
                          openWorkflow={this.openWorkflow}
                          newWorkflow={this.newWorkflow}
                          newWorkflowResponseOnIntent={
                            this.props.newFromResponseOnIntent
                          }
                          newNameResponseOnIntent={
                            this.props.newNameResponseOnIntent
                          }
                          saveWorkflowResponseOnIntent={this.makeWorkFlow}
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.contextSwitch" ? (
                        <ContextSwitch
                          saveWorkflow={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                          passForwardParams={this.passForwardParams}
                          model={this.props.model}
                          passWorkflowToOpen={this.passWorkflowToOpen}
                          pipeline={this.props.pipeline}
                          account={this.props.account}
                          workflowCreate={this.state.workflowCreate}
                          openWorkflow={this.openWorkflow}
                          newWorkflow={this.newWorkflow}
                          default={this.props.default}
                          newWorkflowContextSwitch={
                            this.props.newFromContextSwitch
                          }
                          newWorkflowResponseOnIntent={
                            this.props.newFromResponseOnIntent
                          }
                          newNameContextSwitch={this.props.newNameContextSwitch}
                          saveWorkflowResponseOnIntent={this.makeWorkFlow}
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "voice.recognize" ? (
                        <VoiceOptions
                          saveWorkflow={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                          passForwardParams={this.passForwardParams}
                          model={this.props.model}
                          passWorkflowToOpen={this.passWorkflowToOpen}
                          pipeline={this.props.pipeline}
                          account={this.props.account}
                          workflowCreate={false} // not showing new workflow now
                          openWorkflow={this.openWorkflow}
                          newWorkflow={this.newWorkflow}
                          default={this.props.default}
                          newWorkflowEntitySwitch={
                            this.props.newFromEntitySwitch
                          }
                          newWorkflowResponseOnIntent={
                            this.props.newFromResponseOnIntent
                          }
                          newNameEntitySwitch={this.props.newNameEntitySwitch}
                          saveWorkflowResponseOnIntent={this.makeWorkFlow}
                        />
                      ) : (
                        <div></div>
                      )}
                      {this.state.renderType === "case.entitySwitch" ? (
                        <EntitySwitch
                          saveWorkflow={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                          passForwardParams={this.passForwardParams}
                          model={this.props.model}
                          passWorkflowToOpen={this.passWorkflowToOpen}
                          pipeline={this.props.pipeline}
                          account={this.props.account}
                          workflowCreate={this.state.workflowCreate}
                          openWorkflow={this.openWorkflow}
                          newWorkflow={this.newWorkflow}
                          default={this.props.default}
                          newWorkflowEntitySwitch={
                            this.props.newFromEntitySwitch
                          }
                          newWorkflowResponseOnIntent={
                            this.props.newFromResponseOnIntent
                          }
                          newNameEntitySwitch={this.props.newNameEntitySwitch}
                          saveWorkflowResponseOnIntent={this.makeWorkFlow}
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "website.presentUrl" ? (
                        <PresentUrl
                          passData={this.makeWorkFlow}
                          value={
                            this.state.response &&
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "website.imageInChat" ? (
                        <ImageInChat
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.setCaseType" ? (
                        <SetCaseType
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                      {this.state.renderType === "vergicAi.setIntentModel" ? (
                        <SetIntentModel
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                      {this.state.renderType ===
                      "case.checkGroupStatusAndTransfer" ? (
                        <div>
                          <CheckGroupStatusAndTransfer
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.renderType === "case.checkGroupStatus" && (
                        <div>
                          <CheckGroupStatus
                            workflowCreate={this.state.workflowCreate}
                            passData={this.makeWorkFlow}
                            openWorkflow={this.openWorkflow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.checkNumberInQueue" && (
                        <div>
                          <CheckNumberInQueue
                            workflowCreate={this.state.workflowCreate}
                            passData={this.makeWorkFlow}
                            openWorkflow={this.openWorkflow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType ===
                      "conversation.askForContext" ? (
                        <div>
                          <AskForContext
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "voice.askForContext" ? (
                        <div>
                          <VoiceAskForContext
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "voice.updateCallDetails" ? (
                        <div>
                          <VoiceUpdateCallDetails
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType ===
                        "conversation.arrayMessage" && (
                        <div>
                          <ArrayMessage
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.checkIfContextIsSet" ? (
                        <div>
                          <CheckIfContextIsSet
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                            openWorkflow={this.openWorkflow}
                            model={this.props.model}
                            newWorkflowCheckIfContextIsSet={
                              this.props.newFromCheckIfContextIsSet
                            }
                            newNameCheckIfContextIsSet={
                              this.props.newNameCheckIfContextIsSet
                            }
                            newFromCheckIfContextIsSetType={
                              this.props.newFromCheckIfContextIsSetType
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "website.navigate" ? (
                        <div>
                          <WebsiteNavigate
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.waitForContext" && (
                        <div>
                          <WaitForContext
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "voice.transferToHuman" && (
                        <div>
                          <VoiceTransfer
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.setContextValue" && (
                        <div>
                          <SetContextValue
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.removeContextValue" && (
                        <div>
                          <RemoveContext
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.randomNext" && (
                        <div>
                          <RandomNext
                            passData={this.makeWorkFlow}
                            openWorkflow={this.openWorkflow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      )}

                      {this.state.renderType === "temp.vergicTemplate" ? (
                        <div>
                          <VergicTemplate
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType ===
                      "website.highLightElementLocatedByTargetPath" ? (
                        <div>
                          <HighlightElement
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "vergicAi.responseOnIntent" ? (
                        <div>
                          <ResponseOnIntent
                            passData={this.makeWorkFlow}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                            model={this.props.model}
                            pipeline={this.props.pipeline}
                            account={this.props.account}
                            workflowCreate={this.state.workflowCreate}
                            openWorkflow={this.openWorkflow}
                            newNameResponseOnIntent={
                              this.props.newNameResponseOnIntent
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.renderType === "case.executeOnTime" && (
                        <div>
                          <ExecuteOnTime
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                            openWorkflow={this.openWorkflow}
                          />
                        </div>
                      )}

                      {this.state.renderType === "case.timer" ? (
                        <Timer
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.chatInput" ? (
                        <ChatInput
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.minMaxUi" ? (
                        <MinMaxUi
                          passData={this.makeWorkFlow}
                          value={
                            JSON.parse(this.state.response)[
                              this.state.selected_point
                            ]
                          }
                        />
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "case.executeOnDate" && (
                        <div>
                          <ExecuteOnDate
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                            openWorkflow={this.openWorkflow}
                          />
                        </div>
                      )}

                      {this.state.renderType === "LOGGER.customerJourney" && (
                        <div>
                          <CustomerJourneyAction
                            passData={this.makeWorkFlow}
                            account={this.props.account}
                            value={
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                            openWorkflow={this.openWorkflow}
                          />
                        </div>
                      )}

                      {this.state.renderType === "custom.action" ||
                      this.state.renderType === "LOGGER.track" ||
                      this.state.renderType === "case.closeAsSuccess" ? (
                        <div>
                          <CustomAction
                            passData={this.makeWorkFlow}
                            value={
                              this.state.response &&
                              JSON.parse(this.state.response)[
                                this.state.selected_point
                              ]
                            }
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.renderType === "Integrations" &&
                        this.state.showIntegrations && (
                          <div>
                            <Integrations
                              passData={this.makeWorkFlow}
                              account={this.props.account}
                              accountName={this.props.accountName}
                              value={
                                this.state.response &&
                                JSON.parse(this.state.response)[
                                  this.state.selected_point
                                ]
                              }
                            />
                          </div>
                        )}
                    </div>
                  )}
                  {this.state.value === 1 && (
                    <div
                      style={{
                        width: "360px",
                        wordBreak: "break-all",
                        display: "flex",
                        flexDirection: "column",
                        overflowX: "hidden",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      {!this.state.createWorkflow ? (
                        <div
                          style={{
                            fontSize: "1.2em",
                            paddingTop: "0" + "px",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "12px",
                              marginLeft: "-18px",
                              marginRight: "-2px",
                            }}
                          >
                            <TextField
                              label="Filter"
                              style={{
                                width: "100%",
                                maxWidth: "400px",
                                background: "#fff",
                              }}
                              className={classes.textField}
                              value={this.state.filter}
                              onChange={this.handleChangeFilter}
                              margin="normal"
                              variant="filled"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    onClick={this.clearInput}
                                    position="end"
                                  >
                                    {this.state.filter !== "" ? (
                                      <IconButton>
                                        <Clear style={{ fontSize: 16 }} />
                                      </IconButton>
                                    ) : (
                                      <div></div>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div style={{ overflowY: "auto" }}>
                            <div
                              id="chooseWorkflow"
                              style={{ overflowY: "auto" }}
                            >
                              <List
                                style={{ minWidth: "340px", width: "100%" }}
                                component="nav"
                                className={classes.root}
                                id="thing"
                              >
                                {this.listRow()}
                              </List>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.createWorkflow ? (
                        <div
                          id="workflow"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "0px",
                          }}
                        >
                          <Fab
                            color="primary"
                            onClick={this.workflowNavigate}
                            aria-label="Back"
                            size={"small"}
                            style={{ marginBottom: "10px" }}
                            className={classes.fab}
                          >
                            <ArrowBack />
                          </Fab>

                          <h3>{this.state.selectedWorkflow}</h3>
                          {this.renderWorkflowForm()}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Drawer>

            <Flowspace
              background="white"
              variant="filled"
              theme="pink"
              connectionSize="6"
              arrowStart={false}
              arrowEnd={false}
              style={{
                marginTop: "20px",
                marginRight: "-400px",
                maxWidth: "900px",
              }}
              selected={this.state.selected_point}
              onClick={(e) => {
                this.setState({ renderType: "" });
                this.setState({ selected_point: null });
                this.setState({ value: 1 });
                this.setState({ showSettings: false });
              }}
            >
              {this.renderFlowPoints()}
            </Flowspace>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: "20px",
            top: "-45px",
            marginTop: "-82px",
          }}
        >
          <Fab
            color="primary"
            onClick={this.handleShowSettings}
            aria-label="Workflow Settings"
            size="small"
          >
            {" "}
            <Settings />
          </Fab>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(WorkflowComponents);
