
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";
import InputBase from '@material-ui/core/InputBase';
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    notchedOutline: {},
    select: {
        selectedTextColor: 'black',
    },
    menuItem: {
        color: "white",

    }

});

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            //marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: "black",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '12px 26px 12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            background: "white",
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


class ModelSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model,
            name: '',
            labelWidth: 0,
            hiddenModel: false
        };
        this.listRow = this.listRow.bind(this);
        this.getPipeline = this.getPipeline.bind(this);

    }

    componentDidMount() {

        if (this.props.data && this.props.data.length === 1) {

            this.setState({model: this.props.data[0].Model})



        }else {


            this.setState({model: this.props.model})
        }



    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        // only update chart if the data has changed

        if (prevProps.model !== this.props.model) {


            this.setState({model: this.props.model});

        }
        if(prevProps.data !== this.props.data && this.props.data.length === 1 ){
            //console.log(this.props.data)
            this.setState({model: this.props.data[0].Model});
            this.props.setPipeline(this.props.data[0].Pipeline);

            this.setState({hiddenModel: true});
        }

    }

    getPipeline(modelName){

        let pipeline = "";
        this.props.data.map((object) => {

            if(object.Model === modelName){

                pipeline = object.Pipeline;


            }

        });

        return pipeline;

    }

    handleChange = event => {
        //console.log({ [event.target.name]: event.target.value });
        //this.setState({ [event.target.name]: event.target.value });

        let pipeline = this.getPipeline(event.target.value);

        this.props.setPipeline(pipeline);
        this.props.setModel(event.target.value);
    };

    listRow() {

        if (this.props.data) {
           return this.props.data.map((object, i) => {

                return  <MenuItem key={i} value={object.Model}>{object.Model}</MenuItem>

            });
        }

    }

    render() {
        const {classes} = this.props;
        return (
            <FormControl className={classes.formControl}>
                {!this.state.hiddenModel?
                <Select
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    value={this.state.model}
                    onChange={this.handleChange}
                    input={
                       <BootstrapInput/>
                    }
                >
                    {this.listRow()}
                </Select>:<div></div>}
            </FormControl>
        );
    }
}

ModelSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ModelSelect);
