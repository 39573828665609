import React, {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import MUIDataTable from "mui-datatables";
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddFromQueryLog from "./AddFromQueryLog";


const options = {
    filter: true,
    filterType: "checkbox",
    responsive: "stacked",
    selectableRows: "none",
    onCellClick: (colData, cellMeta) => {
        this.cellClick(colData);

    }


};
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row'

    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    overrides: {
        MUIDataTableSelectCell: {
            checkboxRoot: {
                display: 'none'
            }
        }
    }

});


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}


class Queries extends Component {


    constructor() {
        super();
        this.state = {
            title: 'Intent hits',
            keyValue: [],
            confidence: ' ',
            intentData: [],
            limit: "100",
            value: 0,
            confData: [],
            date: new Date('2019-01-17'),
            toDate: new Date(),
            model: "",
            pipeline: ""

        }

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitConf = this.handleSubmitConf.bind(this);
        this.createChartData = this.createChartData.bind(this);
        this.queryIntent = this.queryIntent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeConfidence = this.onChangeConfidence.bind(this);
        this.cellClickCreateIntent = this.cellClickCreateIntent.bind(this);
        this.setModel = this.setModel.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    modalClose(){
        console.log("here")
        this.setState({addIntentQuery: false});
    }
    changeModel(event, value) {

        if (value && value.Model) {
            localStorage.setItem("model", value.Model);
            this.setState({modelValue: value});
            this.setState({model: value.Model});
        }
    }

    async getSavedModels() {


        let url = '/api/getSavedModels';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {
            let first = false;
            const model = localStorage.getItem("model");
            data.map((d, i) => {

                if(model && d.Model === model) {

                    first = d;

                }else if (i === 0) {

                    first = d;
                }

            })

            this.setState({savedModels: data, modelValue: first, model: first.Model});


        }


    }

    async getSettings() {


        let url = '/api/getSettings';

        const response = await fetch(url);

        const data = await response.json();
        if (data.length > 0) {

            const sett = data[0].Value;

            const settings = JSON.parse(sett);

            this.setState({
                confidenceThreshold: settings.ConfidenceThreshold,
                confidence: settings.ConfidenceThreshold * 100
            });

            this.setState({mappedCaseFlows: settings.CaseTypeMapping});
            this.setState({mappedFallbackCategoryFlows: settings.StrictCategoryMapping});
            this.setState({mappedCategoryFlows: settings.CategoryMapping});
            this.setState({fallbackFlow: {"Id": settings.FallbackFlow}});
            this.setState({startFlow: {"Id": settings.StartFlow}});
            this.setState({inactiveFlow: {"Id": settings.InactiveFlow}});


        }
    }


    setModel = (model) => {

        this.setState({model: model});
        this.queryIntent();
    }

    cellClickCreateIntent(cellData, cellMetaData) {
        if (cellMetaData.colIndex === 1) {


            //this.props.passData(this.state.chartData[cellMetaData.rowIndex][2], cellData, this.state.model);
            this.setState({addIntentQuery: true, trainingSentence: cellData});
            //this.props.history.push('/trainingdata');
        }

    }


    queryConfidence() {
        var url = '/api/confidence?limit=' + eval(this.state.confidence) / 100 + '&model=' + this.state.model;


        fetch(url)
            .then(response => {
                response.json()
                    .then(data => {
                        this.setState({keyValue: data});
                        this.createChartData();

                    })
            })


    }


    async queryIntent() {


        let url = '/api/last100Queries?limit=' + this.state.limit + '&model=' + this.state.model;

        const response = await fetch(url);


        if (response.redirected) {

            window.location.reload();

        }

        const data = await response.json();


        if (data && data.length > 0 && data.name !== 'error') {
            let replaceArray = [{'&#229;':'å'},{ '&#246;': 'ö'}, {'&#228;' : 'ä'}, {'&#196;' :'Ä'}, {'&#214;': 'Ö'},{'&#197;': 'Å'}];
            data.map((row)=>{
                for(let i = 0; i < replaceArray.length; i++) {
                    row.Query = row.Query.replaceAll(Object.keys(replaceArray[i])[0], Object.values(replaceArray[i])[0]);
                }
            });

            this.setState({keyValue: data});
            this.createChartData();

        }
    }

    getKey() {
        return this.keyCount++;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.queryIntent();
    }

    handleSubmitConf(e) {
        e.preventDefault();
        this.queryConfidence();
    }

    componentDidMount() {


        /*if (this.props.model) {


            this.setState({model: this.props.model});


        }*/

        if (this.props.pipeline) {

            //console.log(this.props.pipeline)
            this.setState({pipeline: this.props.pipeline});


        }
        this.getSavedModels();
        this.getSettings();
        this.queryIntent();


    }


    componentDidUpdate(prevProps, prevState, snapShot) {




        if (prevProps.pipeline !== this.props.pipeline) {


            this.setState({pipeline: this.props.pipeline});


        }
    }


    createChartData() {


        let rows = [];
        let intentRow = [];
        if (this.state.keyValue !== []) {

            for (let row of this.state.keyValue) {
                const {CreatedAt, Query, Name, Confidence, IntentRanking} = row
                intentRow.push(IntentRanking);
                rows.push([moment(CreatedAt).format("YYYY-MM-DD HH:mm"), Query, Name, Math.round(Confidence)])

            }
            this.setState({intentRow: intentRow});
            this.setState({chartData: rows});

        }

    }

    onChangeConfidence(e) {

        this.setState({confidence: e.target.value});

    }


    handleChange(e) {

        this.setState({limit: e.target.value});
    }

    render() {

        const {classes} = this.props;
        const {date, toDate, value} = this.state
        const renderTable = this.state.confData;
        return (

            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Paper className={classes.paper}>


                            {/*                                  <ModelSelect model={this.state.model} setModel={this.setModel}
                                               data={this.state.savedModels}/>*/}
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <form onSubmit={this.handleSubmit} className={classes.paperForm}>


                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        {this.state.savedModels && this.state.savedModels.length > 1 &&


                                            <Autocomplete
                                                id="modelBox"
                                                options={this.state.savedModels}
                                                onChange={this.changeModel}
                                                value={this.state.modelValue || null}
                                                getOptionLabel={option => option.Model}
                                                style={{maxWidth: "300px", width: "100%", marginTop: "5px", minWidth: "150px"}}
                                                renderInput={params => <TextField {...params}
                                                                                  variant="outlined"
                                                                                  label="Model"
                                                                                  style={{}}/>}/>

                                        }

                                        <TextField label="Number of Queries"
                                                   margin="normal"
                                                   variant="outlined" onChange={this.handleChange}
                                                   value={this.state.limit}
                                                   style={{marginLeft: "20px", minWidth: "140px"}}/>


                                        <Button style={{marginLeft: "10px", height: "40px", minWidth: "200px",  marginTop: "20px"}}
                                                color="primary" variant="contained" type="submit">Get Last
                                            Queries</Button>

                                    </div>

                                </form>


                                <form onSubmit={this.handleSubmitConf}>

                                    <FormControl className={classes.formControl}>
                                        <div style={{display: "flex", flexDirection: "row", marginLeft: "20px"}}>
                                            <TextField
                                                value={this.state.confidence}
                                                onChange={this.onChangeConfidence}
                                                label="Highest Confidence [%]"
                                                margin="normal"
                                                variant="outlined"
                                                style={{ minWidth: "130px", maxWidth: "180px"}}/>

                                            <Button style={{marginLeft: "10px", height: "40px", marginTop: "30px"}}
                                                    color="primary" variant="contained" type="submit">Query</Button>
                                        </div>
                                    </FormControl>
                                </form>
                            </div>
                        </Paper>

                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <MUIDataTable
                                title={"Last BOT Queries"}
                                data={this.state.chartData}
                                columns={["CreatedAt", {
                                    name: "Query",
                                    options: {
                                        filter: true,
                                        setCellProps: (value) => {
                                            return {
                                                className: classnames(
                                                    {
                                                        [classes.NameCell]: value
                                                    })
                                            };
                                        }
                                    }
                                }, "Intent", "Confidence [%]"]}
                                options={{
                                    filter: false,
                                    filterType: "checkbox",
                                    responsive: "standard",
                                    selectableRows: "none",
                                    rowsPerPage: 100,
                                    expandableRows: true,
                                    onCellClick: (colData, cellMeta) => {
                                        this.cellClickCreateIntent(colData, cellMeta);

                                    },
                                    renderExpandableRow: (rowData, rowMeta) => {
                                        return (
                                            <TableRow>
                                                <TableCell colSpan={rowData.length}>
                                                    <h3> Confidence Ranking </h3>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Confidence [%]</th>
                                                        </tr>
                                                        </thead>
                                                        {this.state.intentRow[rowMeta.rowIndex].map((row, i) => {
                                                            return (<tbody key={this.getKey()}>
                                                            <tr>
                                                                <td>{row.name}</td>
                                                                <td>{Math.round(eval(row.confidence) * 100)}</td>
                                                            </tr>
                                                            </tbody>)
                                                        })}
                                                    </table>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }

                                }}

                            />
                        </Paper>
                    </Grid>

                </Grid>
                {this.state.addIntentQuery && <AddFromQueryLog loggedInUserEmail={this.props.loggedInUser} model={this.state.model}
                                                               account={this.props.account} closeModal={this.modalClose}
                                                               trainingSentence={this.state.trainingSentence} open={"true"}
                                                               closeAdd={()=>{this.setState({addIntentQuery:null});}} />}

            </Box>


        );
    }
}

export default withStyles(styles)(Queries);
