export const getSettings = async (props) => {
    try {
        let url = '/api/getSettings';
        const response = await fetch(url);
        const data = await response.json();

        if (data.length > 0) {
            const sett = data[0].Value;
            const settings = JSON.parse(sett);

            props.setConfidenceThreshold(settings.ConfidenceThreshold);
            props.setConfidence(settings.ConfidenceThreshold * 100);
            props.setMappedCaseFlows(settings.CaseTypeMapping);
            props.setMappedCategoryFlows(settings.CategoryMapping);
            props.setMappedFallbackCategoryFlows(settings.StrictCategoryMapping);
            props.setMappedCaseFlows(settings.CategoryMapping);
            props.setFallbackFlow({"Id": settings.FallbackFlow});
            props.setStartFlow({"Id": settings.StartFlow});
            props.setInactiveFlow({"Id": settings.InactiveFlow});

            // Return a resolved Promise with the settings data
            return settings;
        }
    } catch (error) {
        // Handle errors and reject the Promise if needed
        console.error('Error in getSettings:', error);
        throw error;
    }
};
