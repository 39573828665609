import TextField from '@material-ui/core/TextField';
import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WorkflowComponents from './WorkflowComponents'
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Box from '@material-ui/core/Box';
import '../App.css';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(80),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    NameCell: {
        fontWeight: 900,
        textDecoration: "underline",
        cursor: "pointer"
    },
    fab: {
        margin: theme.spacing(),
    },
    highlight: {

        fontSize: '16px',
        color: '#000'

    },
    textField: {
        fontSize: '15px'

    }


});

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            //marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: "black",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '12px 26px 12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            background: "white",
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function rand() {
    return Math.round(Math.random() * 20) - 10;
}


class TrainingdataBox extends Component {

    constructor(props) {

        super(props);
        this.updateTextField = this.updateTextField.bind(this);
        this.listRow = this.listRow.bind(this);
        this.addNewIntent = this.addNewIntent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deleteTraining = this.deleteTraining.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.selectRows = this.selectRows.bind(this);
        this.onChangeTraining = this.onChangeTraining.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.updateResponseFromWorkflow = this.updateResponseFromWorkflow.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.createSelectedArray = this.createSelectedArray.bind(this);
        this.handleDeleteEntity = this.handleDeleteEntity.bind(this);
        this.getAllEntities = this.getAllEntities.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.saveUpdatedTraining = this.saveUpdatedTraining.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.handleButtonTextChange = this.handleButtonTextChange.bind(this);
        this.addButtonText = this.addButtonText.bind(this);
        this.renderButtonRow = this.renderButtonRow.bind(this);
        this.handleDeleteButtonText = this.handleDeleteButtonText.bind(this);
        this.handleDeleteTrainingSentence = this.handleDeleteTrainingSentence.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.getSelectionText = this.getSelectionText.bind(this);
        this.getEntities = this.getEntities.bind(this);
        this.addNewEntity = this.addNewEntity.bind(this);
        this.handleContextChange = this.handleContextChange.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.handleCheckboxSynonym = this.handleCheckboxSynonym.bind(this);
        this.handleEntityValueChange = this.handleEntityValueChange.bind(this);
        this.handleExpandChange = this.handleExpandChange.bind(this);
        this.handleTrainingSentenceChange = this.handleTrainingSentenceChange.bind(this);
        this.renderTrainingSentencesRow = this.renderTrainingSentencesRow.bind(this);
        this.addTrainingSentence = this.addTrainingSentence.bind(this);
        this.handleMetaChange = this.handleMetaChange.bind(this);
        this.state = {
            trainingData: this.props.trainingData,
            open: false,
            addValue: '',
            filter: '',
            showSelect: false,
            enableEntitiesModal: false,
            openTraining: false,
            entityValueSaved: null,
            selected: [],
            buttonTexts: [],
            value: 0,
            showSave: false,
            metaValue: 0,
            startEntity: "",
            endEntity: "",
            selectedEntity: "",
            entityName: "",
            showHideRow: [],
            editTrainingRow: [],
            trainingSentence: "",
            highlightEntities: [],
            trainingSentences: [],
            entities: [],
            entityValues: [],
            errorMessage: "",
            metadata: [],
            buttonText: "",
            switchChecked: false,
            switchLabel: "Active",
            contextValue: "",
            entityContext: "",
            synonymChecked: false,
            expanded: "panel1"
        }

        this.myRef = React.createRef();
    }


    handleMetaChange = (event, value) => {

        this.setState({metaValue: value});

    };

    componentDidMount() {

        if (this.props.roles) {

            this.setState({roles: this.props.roles});
        }

        this.getSavedModels();
        if (this.props.trainingData) {
            this.setState({nbrSentences: this.props.trainingData.length});
        }

        if (this.props.metadata) {

            this.setState({metadata: this.props.metadata});

            if (this.props.metadata.buttonText) {

                this.setState({buttonTexts: this.props.metadata.buttonText});

            }
            if (this.props.metadata.trainingSentences) {

                this.setState({trainingSentences: this.props.metadata.trainingSentences});

            }

        }

        if (this.props.status) {


            if (this.props.status === "active") {
                this.setState({switchChecked: true, switchLabel: "Active"});
            } else if (this.props.status === "inactive") {
                this.setState({switchChecked: false, switchLabel: "Disabled"});
            }
        }

        if (this.props.selectResponse) {

            this.setState({value: 2});
            this.props.resetSelectResponse();
        }

        if (this.props.trainingData) {
            setTimeout(this.myRef.current.focus(), 1000)
        }
        //this.getAllEntities();
        this.createSelectedArray();

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (this.props.metadata && JSON.stringify(prevProps.metadata) !== JSON.stringify(this.props.metadata)) {

            if (this.props.metadata && this.props.metadata.buttonText) {

                this.setState({buttonTexts: this.props.metadata.buttonText});

            }

            if (this.props.metadata && this.props.metadata.trainingSentences) {

                this.setState({trainingSentences: this.props.metadata.trainingSentences});

            }
            this.setState({metadata: this.props.metadata});

        }
        if (this.props.updateTestSentences && JSON.stringify(prevProps.updateTestSentences) !== JSON.stringify(this.props.updateTestSentences)) {


            this.setState({updateTestSentences: this.props.updateTestSentences});
            if (this.state.metadata) {
                if (typeof this.state.metadata.trainingSentences === 'undefined') {

                    const training = [...this.props.updateTestSentences];
                    this.state.metadata.trainingSentences = training;
                    this.setState({trainingSentences:training});
                } else {
                    this.state.metadata.trainingSentences = [...this.state.metadata.trainingSentences, ...this.props.updateTestSentences];
                    this.setState({trainingSentences:this.state.metadata.trainingSentences});
                }


                this.setState({metadata: this.state.metadata}, () => {
                    this.props.updateResponseFromMetadata(this.state.metadata);
                    this.setState({trainingSentence: ""});
                });
            }

        }


        if (this.props.updateSentences && JSON.stringify(prevProps.updateSentences) !== JSON.stringify(this.props.updateSentences)) {


            this.setState({updateTestSentences: this.props.updateSentences});
            if(this.props.updateSentences){this.props.updateSentences.map((sentence) => {
                this.props.updateTrainingData(sentence);
            });}

        }

        if (this.props.trainingData && JSON.stringify(prevProps.trainingData) !== JSON.stringify(this.props.trainingData) ) {

            if (this.props.status) {
                if (this.props.status === "active") {
                    this.setState({switchChecked: true, switchLabel: "Active"});
                } else if (this.props.status === "inactive") {
                    this.setState({switchChecked: false, switchLabel: "Disabled"});
                }
            }
            this.setState({nbrSentences: this.props.trainingData.length});

            this.getAllEntities(true);
            if (this.state.value === 0) {
                setTimeout(this.myRef.current.focus(), 1000);
            }
        }

    }

    handleExpandChange(panelId) {
        this.setState({expanded: panelId});
    }

    handleEntityValueChange(event, value) {

        this.setState({entityValueSaved: value});
    }

    handleEntityChange(event, value) {

        this.setState({entityContext: value});

    }

    handleCheckboxSynonym(event) {

        this.setState({synonymChecked: event.target.checked, expanded: "panel1"});
    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;


        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );


        let data = await response.json();

        let entities, entityValues;
        if (i === 0) {
            entities = [];
            entityValues = [];
        } else {
            entityValues = this.state.entityValues ? this.state.entityValues : [];
            entities = this.state.entities ? this.state.entities : [];
        }

        if (data && data.rasa_nlu_data.entity_synonyms) {
            this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]})
        }

        if (data && data.rasa_nlu_data.entity_values) {
            this.setState({entityValues: [...entityValues, ...data.rasa_nlu_data.entity_values]})
        }

    }

    async getSavedModels() {

        this.setState({entities: [], entityValues: []});

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            });
        }
    }

    handleContextChange(event) {


        this.setState({contextValue: event.target.value});
    }

    addNewEntity(event) {

        let entityValue = this.state.entityValue;
        let endwithSpace = false;
        if (entityValue) {
            //Take away spaces.
            var endSpace = /\s$/;
            if (endSpace.test(entityValue)) {
                entityValue = entityValue.trim();
                endwithSpace = true;
            }
        }

        if (this.state.synonymChecked) {

            entityValue = "[" + entityValue + "]" + "{\"entity\": \"" + this.state.entityContext + "\", \"value\": \"" + this.state.entityValueSaved + "\"}";
            if (endwithSpace) {
                entityValue = entityValue + " ";
            }
        } else if (entityValue !== "") {
            let val = this.state.entityContext;
            if (this.state.expanded === 'panel2' && this.state.contextValue) {
                val = this.state.contextValue;
            }
            entityValue = "[" + entityValue + "]" + "(" + val + ")";
            if (endwithSpace) {
                entityValue = entityValue + " ";
            }
        }

        let newText = this.state.trainingdataText.replaceAll(this.state.entityValue, entityValue);

        this.props.trainingData[this.state.textIndex].text = newText;
        this.setState({
            enableEntitiesModal: false,
            expanded: "panel1",
            entityValue: "",
            entityContext: "",
            textIndex: "",
            trainingdataText: "",
            entityValueSaved: "",
            synonymChecked: false
        }, () => {
            this.saveUpdatedTraining();
        });

        setTimeout(this.getSavedModels, 4000);
    }

    getEntities = (event, text, i) => {

        let selection = this.getSelectionText();
        if (selection && selection !== "") {

            this.setState({enableEntities: true, entityValue: selection, trainingdataText: text, textIndex: i});

        } else {

            this.setState({enableEntities: false});
        }


    }

    handleSwitchChange(event) {


        this.setState({
            switchChecked: this.state.switchChecked ? false : true,
            switchLabel: this.state.switchLabel === "Active" ? "Disabled" : "Active"
        });
        this.setIntentStatus(event.currentTarget.checked)
    }

    handleDeleteButtonText = (e, i) => {

        this.state.buttonTexts.splice(i, 1)

        this.state.metadata.buttonText = this.state.buttonTexts;
        this.setState({
            buttonTexts: this.state.buttonTexts,
            metadata: this.state.metadata
        }, this.props.updateResponseFromMetadata(this.state.metadata));

    }

    handleDeleteTrainingSentence = (e, i) => {

        this.state.trainingSentences.splice(i, 1);

        this.state.metadata.trainingSentences = [...this.state.trainingSentences];
        this.setState({
            trainingSentences: this.state.trainingSentences,
            metadata: this.state.metadata
        }, this.props.updateResponseFromMetadata(this.state.metadata));

    }

    renderTrainingSentencesRow() {


        if (this.state.trainingSentences) {

            return this.state.trainingSentences.map((object, i) => {
                //console.log(object.entity)
                return <TableRow key={i}>
                    <TableCell component="th" scope="row">
                        {object}
                    </TableCell>

                    <TableCell align="right"> <InputAdornment
                        onClick={event => this.handleDeleteTrainingSentence(event, i)}
                        position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></TableCell>
                </TableRow>
            });
        }


    }

    renderButtonRow() {


        if (this.state.buttonTexts) {

            return this.state.buttonTexts.map((object, i) => {
                //console.log(object.entity)
                return <TableRow key={i}>
                    <TableCell component="th" scope="row">
                        {object}
                    </TableCell>

                    <TableCell align="right"> <InputAdornment onClick={event => this.handleDeleteButtonText(event, i)}
                                                              position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></TableCell>
                </TableRow>
            });
        }


    }

    addTrainingSentence(e) {

        let sentences = this.state.trainingSentences;
        sentences.push(this.state.trainingSentence);


        this.setState({trainingSentences: sentences});

        if (this.state.metadata) {
            this.state.metadata.trainingSentences = [...sentences];

            this.setState({metadata: this.state.metadata}, () => {
                this.props.updateResponseFromMetadata(this.state.metadata);
                this.setState({trainingSentence: ""});
            })


        }


    }

    addButtonText(e) {

        let meta = this.state.buttonTexts;
        meta.push(this.state.buttonText);
        this.setState({buttonTexts: meta});

        if (this.state.metadata && this.state.metadata.buttonText) {
            this.state.metadata.buttonText = meta;

            this.props.updateResponseFromMetadata(this.state.metadata);
        }


    }

    handleButtonTextChange(e) {

        this.setState({buttonText: e.target.value});

    }

    handleTrainingSentenceChange(e) {

        this.setState({trainingSentence: e.target.value});

    }

    openWorkflow = (value) => {

        this.props.openWorkflow(value);

    }

    saveUpdatedTraining(e) {


        if (this.getSelectionText() === "") {
            this.props.saveUpdatedTraining();
        }

    }

    createSelectedArray() {
        let selected = [];
        this.props.trainingData.map((object) => {

            selected.push(false);

        });
        this.setState({selected: selected});

    }

    clearInput() {

        this.setState({filter: ""});

    }

    updateTextField(e) {

        let addValue = e.target.value;

        this.setState({addValue: addValue});


    }

    onSelect(e) {

        let id = e.target.id.substring(2, e.target.id.length);
        let selected = this.state.selected;
        if (e.target.checked) {

            selected[Number(id)] = true;

        } else {
            selected[Number(id)] = false;
        }
        this.setState({selected: selected});
    }

    onChangeTraining(e) {


        function getHash(input) {
            var hash = 0, len = input.length;
            for (var i = 0; i < len; i++) {
                hash = ((hash << 5) - hash) + input.charCodeAt(i);
                hash |= 0; // to 32bit integer
            }
            return hash;
        }


        if (e.target.id.toString() !== getHash(e.target.value).toString()) {

            this.props.changeTraining(e.target.id, e.target.value)
        }


    }

    getSelectionText() {
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        return text;
    }

    handleDeleteEntity = (i, e) => {

        this.props.deleteEntity(i);

    }

    getAllEntities(changeTraining) {
        let entities = [];

        if (!changeTraining)
            entities = this.state.entities;
        let highlightEntities = this.state.highlightEntities ? this.state.highlightEntities : [];

        this.props.trainingData.map((object, i) => {

            if (object.entities) {

                for (let entity of object.entities) {

                    if (entity.entity) {
                        let found = 0;
                        for (let exEntity of entities) {

                            if (exEntity.entity === entity.entity) {
                                found = 1;
                                break;

                            }
                        }
                        if (highlightEntities[i]) {
                            let newArray = highlightEntities[i];
                            newArray.push(entity.value);
                            highlightEntities[i] = newArray;
                        } else {
                            let newArray = [];
                            newArray.push(entity.value);
                            highlightEntities[i] = newArray;

                        }

                        if (found === 0) {

                            entities.push({entity: entity.entity});

                        }

                    }

                }

            }

        });
        if (entities.length > 0) {
            this.setState({entities: entities});
        }


        this.setState({highlightEntities: highlightEntities});

    }

    listRow() {

        if (this.props.trainingData) {
            function getHash(input) {
                var hash = 0, len = input.length;
                for (var i = 0; i < len; i++) {
                    hash = ((hash << 5) - hash) + input.charCodeAt(i);
                    hash |= 0; // to 32bit integer
                }
                return hash;
            }

            let text = "";
            this.props.trainingData.map((object) => {
                text += this.props.intent + ";" + object.text + "\n";
            });

            return this.props.trainingData.filter(object => {
                //remove names that doesnt match current filter text
                return object.text.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 && object.text.toLowerCase().indexOf("##empty##") === -1
            }).map((object, i) => {
                return <div key={i} style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "5px"
                }}>
                    <div style={{width: "100%", marginTop: "10px", display: "flex", flexDirection: "row"}}>

                        {this.state.showSelect ? <div style={{
                            display: "inline-block",
                            width: "6%",
                            top: "50%",
                            textAlign: "center",
                            verticalAlign: "bottom"
                        }}><Checkbox style={{marginTop: "-7px"}} checked={this.state.selected[i]}
                                     id={"td" + i.toString()}
                                     onChange={this.onSelect}/>
                        </div> : <div></div>}
                        <div style={{marginTop: "6px", marginRight: "10px"}}>
                            {i + 1}.
                        </div>
                        <TextField variant="standard"
                                   id={object.id ? object.id.toString() : i}
                                   style={{width: "100%", height: '30px'}}
                                   InputProps={{
                                       classes: {
                                           input: this.props.classes.textField,
                                       },
                                   }} type="text" onChange={this.onChangeTraining} value={object.text}
                                   onBlur={this.saveUpdatedTraining}
                                   onMouseUp={(event) => {
                                       this.getEntities(event, object.text, i);
                                   }}/>
                    </div>


                </div>

            });
        }

    }

    handleClose = () => {
        this.setState({open: false});
    };

    setIntentStatus(status) {

        this.props.setIntentStatus(status);

    }

    addNewIntent() {

        this.setState({addValue: this.state.addValue.trim()});
        this.props.updateTrainingData(this.state.addValue.trim());
        this.setState({open: false});
        this.setState({addValue: ""});
        setTimeout(this.scrollToBottom, 500);
        setTimeout(this.props.saveUpdatedTraining, 600);

    }

    handleChangeFilter(e) {

        this.setState({filter: e.target.value});

    }

    selectRows() {
        if (!this.state.showSelect) {

            this.setState({filter: ""});

        }
        this.setState({showSelect: this.state.showSelect ? false : true})


    }

    deleteTraining() {

        this.props.deleteTrainingData(this.state.selected);
        this.createSelectedArray();
    }

    handleChange = (event, value) => {

        this.setState({value});
        this.props.tabChange(value);
    };

    scrollToBottom() {
        let el = document.getElementById("trainingList");
        if (el) {
            el.scrollTop = el.scrollHeight - el.clientHeight;
        }
    }

    updateResponseFromWorkflow = (value) => {
        this.setState({showSave: true});
        this.props.updateResponseFromWorkflow(value);


    }

    keyPress(e) {
        if (e.key === 'Enter' && this.state.addValue !== "") {
            this.addNewIntent();
        }

    }

    render() {
        const {classes} = this.props;

        return (
            <div style={{marginTop: "0px", height: "100%"}}>
                <Box
                    style={{borderBottom: 1, borderColor: 'lightgrey', borderWidth: '1px', borderBottomStyle: "solid"}}>
                    <Tabs value={this.state.value} onChange={this.handleChange}>
                        <Tab label="Training Data"/>
                        <Tab label="Metadata"/>
                        <Tab label="Response"/>
                    </Tabs>
                </Box>

                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "-5px"
                }}>

                    {this.state.value === 0 && <div style={{display: "flex", flexDirection: "column"}}>

                        <div id="trainingInput" style={{
                            display: "flex",
                            marginTop: "10px",
                            padding: "10px 0px",
                            paddingTop: '0px',
                            flexDirection: "column",
                            height: "calc(100vh - 100px)",
                            marginLeft: "20px",
                            marginRight: "20px"

                        }}>
                            <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>

                                        <TextField
                                            label="Filter"
                                            variant="filled"
                                            className={classes.textField}
                                            style={{marginTop: "18px", width: "30%", marginLeft: "0px"}}
                                            value={this.state.filter}
                                            onChange={this.handleChangeFilter}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment onClick={this.clearInput}
                                                                              position="end">{this.state.filter !== "" ?
                                                    <IconButton><Clear style={{fontSize: 16}}/></IconButton> :
                                                    <div></div>}</InputAdornment>,
                                            }}
                                        />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: "9px",
                                            marginLeft: "-1px",
                                            width: "100%",
                                            borderBottom: "1px solid #888"
                                        }}>
                                            <div style={{width: "100%", display: "flex"}}>
                                                {this.state.value === 0 &&
                                                    <Fab color="secondary" onClick={this.selectRows} aria-label="Check"
                                                         className={classes.fab} size="small"><CheckIcon/></Fab>}

                                                {this.state.showSelect ?
                                                    <Fab color="secondary" onClick={this.deleteTraining}
                                                         aria-label="Delete"
                                                         className={classes.fab}
                                                         size="small"><DeleteIcon/></Fab> : null}

                                                <FormControl component="fieldset"
                                                             style={{marginLeft: "20px", marginTop: "10px"}}>

                                                    <FormGroup>
                                                        <FormControlLabel

                                                            control={<Switch color="primary"
                                                                             checked={this.state.switchChecked}
                                                                             onChange={this.handleSwitchChange}
                                                                             name="active"/>}
                                                            label={this.state.switchLabel}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>

                                                    </FormGroup>
                                                </FormControl>

                                                {this.state.roles && (this.state.roles.indexOf("Admin") > -1 || this.state.roles.indexOf("chatgpt") > -1 )&&

                                                    <FormControl component="fieldset"
                                                                 style={{marginLeft: "20px", marginTop: "10px"}}>
                                                        <Button color={"primary"} onClick={() => {
                                                            this.props.aiAssisted();
                                                        }} variant="contained" size={"medium"}
                                                                style={{minWidth: "150px"}}>AI Assisted</Button>
                                                    </FormControl>}
                                                {this.state.enableEntities &&
                                                    <FormControl component="fieldset"
                                                                 style={{marginLeft: "10px", marginTop: "10px"}}>
                                                        <Button color={"primary"} onClick={() => {

                                                            if (this.state.entities && this.state.entities.length === 0) {
                                                                this.setState({expanded: "panel2"});
                                                            }
                                                            this.setState({enableEntitiesModal: true});

                                                        }} variant="contained" size={"medium"}
                                                                style={{marginBottom: "2px", minWidth: "150px"}}>Create
                                                            Entity</Button>
                                                    </FormControl>
                                                }

                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: "flex-end",

                                                width: "100%", justifyContent: "flex-end"
                                            }}>
                                                #{this.state.nbrSentences}
                                            </div>
                                        </div>

                                    </div>


                                    <TextField inputProps={{autoFocus: true}} inputRef={this.myRef}
                                               onKeyDown={this.keyPress} style={{width: "100%", marginBottom: "20px"}}
                                               onChange={this.updateTextField}
                                               label="Add Training Sentence" value={this.state.addValue}/>

                                    <div id="trainingList"
                                         style={{
                                             marginTop: '00px',
                                             paddingLeft: "0px",
                                             overflowY: 'auto',
                                             maxHeight: "calc(100vh - 260px)",
                                             width: "100%"
                                         }}>
                                        {this.listRow()}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {this.state.value === 1 && <div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignContent: "space-between",
                            height: "calc(100vh - 100px)",
                            marginRight: "600px",
                            marginLeft: "15px"
                        }}>
                            <Box
                                style={{
                                    borderBottom: 1,
                                    borderColor: 'lightgrey',
                                    borderWidth: '1px',
                                    borderBottomStyle: "solid",
                                    marginTop: "20px",
                                    marginLeft: "-12px"
                                }}>
                                <Tabs value={this.state.metaValue} onChange={this.handleMetaChange}>
                                    <Tab label="Test Sentences"/>
                                    <Tab label="Button Text"/>

                                </Tabs>
                            </Box>
                            {this.state.metaValue === 0 && <React.Fragment>
                                {/*Test Training Sentences*/}
                                <h3 style={{marginTop: "40px"}}>Test Sentences</h3>
                                <div>Senteneces to be used to test your intent</div>
                                {this.state.roles && (this.state.roles.indexOf("Admin") > -1 || this.state.roles.indexOf("chatgpt") > -1) &&

                                    <div style={{marginTop: "10px"}}>
                                        <Button color={"primary"} onClick={() => {
                                            this.props.aiAssisted("test");
                                        }} variant="contained" size={"medium"} style={{maxWidth: "150px"}}>AI
                                            Assisted</Button></div>
                                }
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <TextField
                                        label="Test Sentence"
                                        style={{width: "100%", height: "50px", maxWidth: "415px"}}
                                        value={this.state.trainingSentence}
                                        onChange={this.handleTrainingSentenceChange}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                this.addTrainingSentence();
                                            }
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    < Button variant="contained" color="primary" style={{
                                        width: "100px",
                                        height: "56px",
                                        marginLeft: "5px",
                                        marginTop: "13px"
                                    }}
                                             onClick={this.addTrainingSentence}>
                                        Add</Button>
                                </div>

                                <TableContainer style={{
                                    width: "520px",
                                    marginBottom: "50px",
                                    minHeight: "390px",
                                    marginTop: "10px"
                                }}
                                                component={Paper}>
                                    <Table className={classes.table} aria-label=" ">

                                        <TableBody>
                                            {this.renderTrainingSentencesRow()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>}
                            {this.state.metaValue === 1 && <React.Fragment>
                                <h3 style={{marginTop: "40px"}}>Button Text</h3>
                                <div>Text on Button when listing <i>did you mean?</i></div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <TextField
                                        label="Button Text"
                                        style={{width: "100%", height: "60px", maxWidth: "415px"}}
                                        value={this.state.buttonText}
                                        onChange={this.handleButtonTextChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    < Button variant="contained" color="primary" style={{
                                        width: "100px",
                                        height: "56px",
                                        marginLeft: "5px",
                                        marginTop: "2px"
                                    }}
                                             onClick={this.addButtonText}>
                                        Add</Button>
                                </div>

                                <TableContainer style={{width: "520px", marginBottom: "50px", marginTop: "10px"}}
                                                component={Paper}>
                                    <Table className={classes.table} aria-label=" ">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Button Text</TableCell>
                                                <TableCell align="right"> </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.renderButtonRow()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>}
                        </div>
                    </div>
                    }

                    {this.state.value === 2 && this.props.response !== null &&

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(100vw - 450px)",
                            alignContent: "space-between",
                            height: "calc(100vh - 100px)",
                            marginRight: "0px"
                        }}>

                            <WorkflowComponents account={this.props.account}
                                                model={this.props.model}
                                                voicebot={this.props.voicebot}
                                                pipeline={this.props.pipeline}
                                                response={this.props.response}
                                                passData={this.updateResponseFromWorkflow}
                                                activeWorkflow={this.props.intent}
                                                openWorkflow={this.openWorkflow}

                            />
                        </div>
                    }
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.enableEntitiesModal}
                        onClose={() => {
                            this.setState({enableEntitiesModal: false})
                        }}
                        keepMounted={true}
                    >
                        <div style={getModalStyle()} className={classes.paper}>
                            <Typography variant="h6" id="modal-title">
                                Create Entity
                            </Typography>
                            <div style={{display: "flex", flexDirection: "column"}}>

                                <div style={{display: "flex", marginTop: "10px", flexDirection: "row"}}>
                                    <Typography style={{marginRight: "10px"}} variant="subtitle1">
                                        Selected Entity Text:
                                    </Typography>
                                    <Typography variant="subtitle1" style={{backgroundColor: "yellow"}}>
                                        {this.state.entityValue}
                                    </Typography>
                                </div>

                                <FormGroup row style={{marginTop: "10px", marginBottom: "10px"}}>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.synonymChecked}
                                                           onChange={this.handleCheckboxSynonym}
                                                           name="Add Entity Synonym"/>}
                                        label="Add Entity Synonym"
                                    />
                                </FormGroup>

                                {this.state.synonymChecked &&

                                    <Card className={classes.root}>
                                        <CardContent>
                                            <Autocomplete
                                                defaultValue={this.state.entityValues[0]}
                                                options={this.state.entityValues}
                                                onChange={this.handleEntityValueChange}
                                                value={this.state.entityValueSaved || null}
                                                getOptionLabel={option => option}
                                                style={{
                                                    minWidth: "150px",
                                                    maxWidth: "200px",
                                                    marginTop: "10px",
                                                    marginBottom: "10px"
                                                }}
                                                renderInput={params => <TextField {...params} label="Entity Values"
                                                                                  variant="outlined"/>}
                                            />
                                        </CardContent>
                                    </Card>

                                }


                                <Accordion expanded={this.state.expanded === 'panel1'} onClick={event => {
                                    this.handleExpandChange('panel1')
                                }} style={{marginTop: "5px", marginBottom: "0px"}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>

                                            <div style={{marginLeft: "0px", fontWeight: "bold"}}>Predefined Entity
                                                Variable
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Autocomplete
                                            defaultValue={this.state.entities[0]}
                                            options={this.state.entities}
                                            onChange={this.handleEntityChange}
                                            value={this.state.entityContext || null}
                                            getOptionLabel={option => option}
                                            style={{minWidth: "150px"}}
                                            renderInput={params => <TextField {...params} label="Variable:"
                                                                              variant="outlined"/>}
                                        />

                                    </AccordionDetails>
                                </Accordion>
                                {!this.state.synonymChecked &&

                                    <Accordion expanded={this.state.expanded === 'panel2'} onClick={event => {
                                        this.handleExpandChange('panel2')
                                    }} style={{marginTop: "5px"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row"
                                            }}>

                                                <div style={{marginLeft: "0px", fontWeight: "bold"}}>Manual Input Entity
                                                    Variable
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <TextField variant="outlined"
                                                       style={{width: "calc(100% - 150px)", marginTop: "20px"}}
                                                       onChange={this.handleContextChange}
                                                       value={this.state.contextValue} label="New Entity Variable:"/>
                                        </AccordionDetails>
                                    </Accordion>}


                                {/*!this.state.synonymChecked && */}

                            </div>
                            <br/><br/>
                            <Button variant="contained" color="primary" onClick={this.addNewEntity}
                            >Add </Button>
                            <Button style={{marginLeft: "20px"}} variant="contained" color="primary" onClick={() => {
                                this.setState({enableEntitiesModal: false, enableEntities: false})
                            }}
                                    variant="contained">Cancel</Button>


                        </div>
                    </Modal>

                </div>
            </div>

        )

    }
}

TrainingdataBox.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TrainingdataBox);