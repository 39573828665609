import React, {Fragment, useEffect, useState} from "react";
import "./chart.css";
import {Box, Tab, Tabs} from "@material-ui/core/";
import {Chart} from "react-google-charts";
import dataFormatter from "./dataFormatter";
import {calcFinishedJourneys, calcLineData, calcTableData,} from "./dataCalculations";
import BasicTable from "./table/table";
import JourneyCard from "./journeyCard";
import KpiCard from "./kpi-finished";
import Selector from "./selector/selector";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button/Button";
import moment from "moment";

const JourneyChart = (props) => {
    //States:
    // filteredJourney: holds a complete customer journey dataobject
    // maxValue holds the value of the most hit checkpoint. This is used to visualise the barchart.
    // console.log("render");
    // HÄR FORMATTERAR JAG DATA TILL BRA FORMAT!
    const [date, setDate] = useState(0);
    const [toDate, setToDate] = useState(0);
    const [visualisation, setVisualisation] = useState(0);
    const [sumLineData, setSumLineData] = useState([]);
    const [sumTableData, setSumTableData] = useState([]);
    const [journeyNames, setJourneyNames] = useState([" "]);
    const [journeyRaw, setJourneyRaw] = useState([" "]);
    const [filteredJourney, setFilteredJourney] = useState([
        {
            journey: " ",
            data: [
                {
                    groupname: "No data",
                    checkpoints: [{checkpoint: "No data", hits: 0, score: 0}],
                },
            ],
        },
    ]);
    const [selectorLabel, setSelectorLabel] = useState("");

    // Calculate finished journeys. The total amount of hits at the journey.length -1 divided by the total amount of hits at journey[0]

    // GET DATA HERE!!
    useEffect(() => {
        //Sort all unique journey names in array.
        const fromDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        setDate(new Date(fromDate));
        const toDate = moment().add(1, "days").format("YYYY-MM-DD");
        setToDate(new Date(toDate));


        fetchData(fromDate, toDate);


    }, []);


    const fetchData = async (fromDate, toDate) => {
        try {
            const url = "/api/getCustomerJourneyStats?fromDate=" + fromDate + "&toDate=" + toDate + "&account=" + props.account;
            const response = await fetch(url);
            const data = await response.json();

            setJourneyRaw(data);

            let journeys = [];
            data.forEach((j) => {
                if (journeys.includes(j.JourneyName)) return;
                else journeys.push(j.JourneyName);
            });

            setJourneyNames(journeys);
            setSumTableData(calcTableData(data));
        } catch (error) {
            console.log("error", error);
        }
    };
    //filterJourneyHandler filters out the filteredJourney dataobject when journey is selected in the dropdown menu.
    //
    const filterJourneyHandler = (event) => {
        //Format and summarize data for journey.
        // console.log(event.target.value);
        const formattedData = []; //Use useMemo and push this to array in function instead.
        formattedData.push(dataFormatter(journeyRaw, event.target.value));

        const formattedLineData = calcLineData(journeyRaw, event.target.value);

        setFilteredJourney(formattedData);
        setSumLineData(formattedLineData);
        setSelectorLabel(event.target.value);
    };

    // console.log(filteredJourney[0].data);
    const finishedJourneyShare = calcFinishedJourneys(filteredJourney[0].data);
    //Filters out the maxValue

    const calcMaxHitsValue = () => {
        let maxValueArray = [];
        filteredJourney[0].data.map((group) => {
            return group.checkpoints.map((checkpoint) => {
                return maxValueArray.push(checkpoint.hits);
            });
        });
        return Math.max(...maxValueArray);
    };

    //Sets the initiating state of the visualisations (This should fetch data form database.)

    //Calculate max value set to maxValue

    const maxValue = calcMaxHitsValue();


    const handleChange = (event, newValue) => {
        setVisualisation(newValue);
        // console.log(newValue);
        // console.log(event);
    };

    const onChangeDate = (date) => {
        setDate(date);

    };
    const onChangeToDate = (date) => {
        setToDate(date);

    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const options = {
        chart: {
            title: "Customer journey outcomes",
        },
    };
    // console.log(sumLineData);
    return (

        <div className="journeys-chart-container">
            <div className="journeys-chart-mainchart">
                <div className="journeys-chart-mainchart-label" style={{display: "flex", flexDirection: "row"}}>
                    <div style={{marginTop: "5px"}}><Selector
                        value={selectorLabel}
                        onSelection={filterJourneyHandler}
                        journeyNames={journeyNames}

                    /></div>


                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        < FormControl
                            style={{
                                borderColor: "black",
                                minWidth: "200px",
                                minHeight: "100px",
                                paddingLeft: "0px",
                                paddingRight: "20px",
                                marginTop: "0px",
                                marginBottom: "5px",
                                padding: "10px", marginLeft: "10px"
                            }}>
                            <DatePicker
                                disableToolbar
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                variant="inline"
                                margin="normal"
                                id="date-picker-inline"
                                label="From Date"
                                style={{}}
                                value={date}
                                onChange={onChangeDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FormControl>
                        < FormControl style={{borderColor: "black"}}
                                      style={{
                                          minWidth: "200px",
                                          minHeight: "100px",
                                          paddingLeft: "0px",
                                          paddingRight: "20px",
                                          marginTop: "0px",
                                          marginBottom: "5px",
                                          padding: "10px"
                                      }}>
                            <DatePicker
                                disableToolbar
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                variant="inline"
                                margin="normal"
                                id="date-picker-inline"
                                label="To Date"
                                style={{}}
                                value={toDate}
                                onChange={onChangeToDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FormControl>
                    </MuiPickersUtilsProvider>
                    <Button variant="contained" style={{height: "56px", marginTop: "26px"}} color="primary" onClick={()=>{fetchData(moment(date).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD"));}}>Get Data</Button>

                </div>

                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    <Tabs
                        value={visualisation}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Over time" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <div className="journeys-chart-top">
                    {visualisation === 0 ? (
                        filteredJourney[0].data.map((group) => {
                            return (
                                <JourneyCard
                                    checkpoints={group.checkpoints}
                                    key={group.groupname}
                                    maxValue={maxValue}
                                    label={group.groupname}
                                />
                            );
                        })
                    ) : sumLineData && sumLineData.length > 0 ? (
                        <Chart
                            chartType="Line"
                            width="100%"
                            height="360px"
                            data={sumLineData}
                            options={options}
                        />
                    ) : (
                        <Fragment/>
                    )}
                </div>
                <div className="journeys-chart-bottom">
                    <BasicTable data={sumTableData}/>
                </div>
            </div>
            <div className="journeys-chart-kpis">
                <KpiCard
                    value={finishedJourneyShare.totalHits}
                    label={"Total checkpoint hits"}
                    unit={""}
                />
                <KpiCard
                    value={` ${finishedJourneyShare.finishedJourneys} / ${finishedJourneyShare.startedJourneys}`}
                    value2={
                        isNaN(finishedJourneyShare.finishedShare)
                            ? "-"
                            : `${Math.round(finishedJourneyShare.finishedShare)}%`
                    }
                    label={"Finished journeys"}
                />
            </div>
        </div>

    );
};
export default JourneyChart;
