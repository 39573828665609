import React from "react";
import "./chart.css";
import ChartBar from "./chartbar";
import TimeLine from "./timeline";

const JourneyCard = (props) => {
  return (
    <div className="journeychart-group">
      <div className="journey-visual-top">
        {props.checkpoints.map((checkpoint) => {
          return (
            <ChartBar
              key={checkpoint.checkpoint}
              hits={checkpoint.hits}
              score={checkpoint.score}
              maxValue={props.maxValue}
            />
          );
        })}
      </div>
      <TimeLine />

      <div className="journey-visual-bottom">
        <div className="journeychart-checkpont-labels-container">
          {props.checkpoints.map((checkpoint) => {
            return (
              <div
                className="journeychart-checkpont-labels"
                key={`${checkpoint.checkpoint}label`}
                title={checkpoint.checkpoint}
              >
                {checkpoint.checkpoint}
              </div>
            );
          })}
        </div>
        <span>{props.label}</span>
      </div>
    </div>
  );
};

export default JourneyCard;
