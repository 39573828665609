import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  getAdminLog,
  getAdminLogById,
  getAdminLogByDate,
} from "./reused/reusedfunctions";
import { Button, TextField } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function createData(
  id,
  createdAt,
  json,
  conversationid,
  caseid,
  entity,
  description
) {
  return {
    id,
    createdAt,
    conversationid,
    caseid,
    entity,
    description,
    details: json,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.createdAt}</TableCell>
        <TableCell align="left">{row.caseid}</TableCell>
        <TableCell align="left">{row.conversationid}</TableCell>
        <TableCell align="left">{row.entity}</TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <TextField
                multiline
                minRows={25}
                style={{ display: "flex", flexGrow: 1 }}
                value={row.details}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function LogAdmin(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = React.useState("desc");
  const [search, setSearch] = useState("");
  const [originalRows, setOriginalRows] = useState(null);
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const classes = useStyles();
  const [conversationId, setConversationId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(0, "days").format("YYYY-MM-DD HH:mm"))
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().add(1, "days").format("YYYY-MM-DD HH:mm"))
  );
  const [tabValue, setTabValue] = useState(0);

  const renderData = (data) => {
    let rowsParsed = [];

    data.map((r) => {
      rowsParsed.push(
        createData(
          r.id,
          r.createdAt,
          JSON.stringify(r.json, null, 4),
          r.conversationid,
          r.caseid,
          r.entity,
          r.description
        )
      );
    });

    setOriginalRows(rowsParsed);
    setRows(rowsParsed);
  };

  const getConfig = async () => {
    const data = await getAdminLog("12 hours");
    renderData(data);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (props.environment) {
      
      setTimeout(getConfig, 1000);
    }
  }, [props.environment]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { id: "id", numeric: true, disablePadding: true, label: "Id" },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
    { id: "caseId", numeric: false, disablePadding: false, label: "Case Id" },
    {
      id: "conversationId",
      numeric: false,
      disablePadding: false,
      label: "Conversation Id",
    },
    { id: "entity", numeric: false, disablePadding: false, label: "Entity" },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (search) {
      const filteredRows = originalRows.filter((item) => {
        return (
          (item &&
            (item?.id?.toString().includes(search) ||
              item?.createdAt?.includes(search) ||
              item?.description?.includes(search) ||
              item?.entity?.includes(search) ||
              item?.conversationid?.includes(search) ||
              item?.caseid?.includes(search))) ||
          (originalRows.length < 100 && item?.details?.includes(search))
        );
      });
      setRows(filteredRows);
    }
  }, [search]);

  const submitLogQuery = async (event) => {
    let data;
    
    if (tabValue === 0) {
      if (startId === "" && endId === "" && conversationId === "" && caseId === "") {
        data = await getAdminLog("12 hours");
      } else if (startId === "" && endId === "") {
        data = await getAdminLogById(null, null, conversationId, caseId);
      } else {
        data = await getAdminLogById(startId, endId, conversationId, caseId);
      }
    } else if (tabValue === 1) {
      const startDateString = moment(startDate.toString()).format("YYYY-MM-DD");
      const endDateString = moment(endDate.toString()).format("YYYY-MM-DD");
      data = await getAdminLogByDate(
        startDateString,
        endDateString,
        conversationId,
        caseId
      );
    }
    renderData(data);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <Paper style={{ padding: "20px", marginBottom: "10px" }}>
          <Tabs
            value={tabValue}
            onChange={(event, value) => {
              setTabValue(value);
            }}
          >
            <Tab label="Id" />
            <Tab label="Date" />
          </Tabs>
          {tabValue === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "23px",
              }}
            >
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                variant="outlined"
                label="Start Id"
                onChange={(event) => {
                  setStartId(event.target.value);
                }}
                value={startId}
              />
              <TextField
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                  marginLeft: "10px",
                }}
                variant="outlined"
                label="End Id"
                onChange={(event) => {
                  setEndId(event.target.value);
                }}
                value={endId}
              />
            </div>
          )}
          {tabValue === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  autoOk
                  ampm={false}
                  inputVariant="outlined"
                  variant="inline"
                  style={{ width: "100%" }}
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  autoOk
                  ampm={false}
                  style={{ width: "100%", marginLeft: "10px" }}
                  inputVariant="outlined"
                  variant="inline"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
          <TextField
            style={{ width: "100%", background: "#FFFFFF", marginTop: "10px" }}
            variant="outlined"
            label="Conversation Id"
            onChange={(event) => {
              setConversationId(event.target.value.trim());
            }}
            value={conversationId}
          />
          <TextField
            style={{ width: "100%", background: "#FFFFFF", marginTop: "10px" }}
            variant="outlined"
            label="Case Id"
            onChange={(event) => {
              setCaseId(event.target.value.trim());
            }}
            value={caseId}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              onClick={submitLogQuery}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </div>

      <TextField
        style={{ width: "100%", background: "#FFFFFF" }}
        variant="outlined"
        onChange={(event) => {
          setSearch(event.target.value);
          if (event.target.value === "") {
            setRows(originalRows);
          }
        }}
        value={search}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return <Row key={row.id} row={row} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 200, 500]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
