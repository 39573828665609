import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class SetIntentModel extends Component {


    constructor(props) {

        super(props);
        this.pass = this.pass.bind(this);
        this.renderModelItems = this.renderModelItems.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.state = {
            workFlowRender: null,
            model: "",
            allModels: []
        };

    }

    async getSavedModels(dataToUse) {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();
        let modelArray = [];
        data.map((model)=>{

            modelArray.push(model.Model);

        });
        this.setState({allModels: modelArray});
    }
    renderModelItems() {
        if (this.state.allModels) {
            return this.state.allModels.map((value) => {

                return <MenuItem key={Math.random()} value={value}>{value}</MenuItem>

            });
        }

    }


    componentDidMount() {

        this.getSavedModels();
        if(this.props.value){

            this.setState({"model": this.props.value.model});

        }

    }


    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({"model": this.props.value.model});

        }

    }



    pass(){

        let workflow = {type: "vergicAi.setIntentModel", "model": this.state.model};
        this.props.passData("vergicAi.setIntentModel", workflow);
    }

    handleModelChange(e) {

        this.setState({model: e.target.value});

    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column",  marginTop: "15px"}}>
                    <FormControl variant="outlined" style={{borderColor: "black", width: "100%"}}
                                 className={classes.formControl}>
                        <TextField
                            variant="outlined"
                            label="Model"
                            select
                            value={this.state.model}
                            classes={{root: classes.select}}
                            onChange={this.handleModelChange}
                        >
                            {this.renderModelItems()}


                        </TextField>
                    </FormControl>
                    {this.props.value && this.props.value.model ? <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "15px"}} variant="contained"  onClick={this.pass}>Update</Button>: <Button  style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold", marginTop: "15px"}} variant="contained"    onClick={this.pass}>Add</Button>}


                </div>
            </div>

        )
    }

}

export default withStyles(styles)(SetIntentModel);
