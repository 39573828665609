import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Button,
  Box,
  FormControl,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Paper,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getSites,
  getGroups,
  createThemeConfig,
  createGroup,
  createUser,
  createToken,
  createCase,
  getAllBanners,
  createSolution,
  getLastOpportunityPosition,
  createOpportunity,
  getCustomJsScript,
  createNewBot,
  getAccountIntentTemplate,
  getPuzzelQueues,
} from "./createBot/CreateBotQueries.js";
import moment from "moment";
import {
  saveIntentResponse,
  saveTrainingDataModel,
  postToKv,
} from "./reused/reusedfunctions.js";
import { SketchPicker } from "react-color";
import Notifier from "./Notifier.js";
import Context from "../BOTconfig/Context.json";
import Desktop from "../BOTconfig/Desktop.json";
import SystemWorkflows from "../BOTconfig/SystemWorkflows.json";
import Workflows from "../BOTconfig/Workflows.json";
import timeallocationJson from "../BOTconfig/TimeAllocations.json";
import { Autocomplete } from "@material-ui/lab";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Counter from "./Counter.js";
const timeAllocations = timeallocationJson.timeallocations;

const BotCreate = (props) => {
  const [log, setLog] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [vepToken, setVepToken] = useState("");
  const [domain, setDomain] = useState("https://domain.psplugin.com");
  const [model, setModel] = useState("");
  const [account, setAccount] = useState("");
  const [pipeline, setPipeline] = useState("en");
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [createTheme, setCreateTheme] = useState(false);
  const [notifierMessage, setNotifierMessage] = useState("");
  const [notifierOpen, setNotifierOpen] = useState(false);
  const [botGroupId, setBotGroupId] = useState([]);
  const [botUser, setBotUser] = useState(null);
  const [tokenUser, setTokenUser] = useState([]);
  const [botGroups, setBotGroups] = useState([]);
  const [banner, setBanner] = useState(null);
  const [botCase, setBotCase] = useState(null);
  const [position, setPosition] = useState(0);
  const [solCreated, setSolCreated] = useState(false);
  const [modelSet, setModelSet] = useState(false);
  const [customerKey, setCustomerKey] = useState(null);
  const [userName, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [usePuzzelIntegration, setUsePuzzelIntegration] = useState(false);
  const [groups, setGroups] = useState([]);
  const [engageGroup, setEngageGroup] = useState(null);
  const [puzzelQueues, setPuzzelQueues] = useState([]);
  const [puzzelQueue, setPuzzelQueue] = useState(null);
  const [queueMappings, setQueueMappings] = useState(new Map());
  const [botGroupsToCreate, setBotGroupsToCreate] = useState([]);
  const [numberOfBots, setNumberOfBots] = useState(0);
  const [defaultTransferQueue, setDefaultTransferQueue] = useState();
  const [puzzelApiPath, setPuzzelApiPath] = useState(
    "https://api.puzzel.com/ContactCentre5/"
  );
  const [puzzelTokenUrl, setPuzzelTokenUrl] = useState(
    "https://auth.puzzel.com/api/"
  );
  const loadConfig = async (event) => {
    if (domain !== "" && vepToken !== "") {
      const sites = await getSites(domain, vepToken);
      if (sites && sites.length > 0) {
        setSites(sites);
      }
    }
  };

  const createBotGroup = async () => {
    //get the dooreye banner
    getDooreyeBanner();

    //Only create one opportunity for all the bots created
    const pos = await getLastOpportunityPosition(domain, vepToken);
    setPosition(pos);

    let numberOfBotsToCreatePromise = [];

    let count = 1;

    while (count <= numberOfBots) {
      let groupObj = {
        dialogOptions: { startMessage: "", signature: "" },
        reservationBehaviour: 0,
        timeZone: "Europe/Stockholm",
        timeAllocations: timeAllocations,
        name: "BOT Group " + count,
        siteId: site,
      };

      numberOfBotsToCreatePromise.push(createDeGroup(groupObj));
      count++;
    }

    //Create All Bot Groups
    Promise.all(numberOfBotsToCreatePromise).then(() => {
      setLog((prevItems) => [
        ...prevItems,
        numberOfBotsToCreatePromise.length + " Bot Groups Were Created",
      ]);
    });
  };

  const createDeGroup = async (groupObj) => {
    const creGroup = await createGroup(groupObj, vepToken, domain);
    if (creGroup) {
      setBotGroups((prevValues) => [...prevValues, creGroup]);
    }
  };
  useEffect(() => {
    //If more than one bot to be created check that all groups been added before continuing
    if (botGroups && botGroups.length === numberOfBots) {
      let groupIds = [];
      let createBotUsersPromise = [];

      botGroups.map((group) => {
        groupIds.push(group.key);
        const nameSplit = group?.data?.name.split(" ");
        createBotUsersPromise.push(
          createBotUser(group.key, nameSplit[nameSplit.length - 1])
        );
      });
      Promise.all(createBotUsersPromise).then(() => {
        setBotGroupId(groupIds);
        caseCreate();
      });
    }
  }, [botGroups]);

  const renderSites = () => {
    return sites.map((site) => {
      return (
        <MenuItem key={Math.random} value={site.id}>
          {site.name}
        </MenuItem>
      );
    });
  };

  const createThemeConf = async (event) => {
    const themeSuccCreated = await createThemeConfig(
      domain,
      vepToken,
      backgroundColor,
      model
    );

    if (themeSuccCreated) {
      setNotifierMessage("Theme Successfully Created");
      setNotifierOpen(true);
    }
  };

  const createBotUser = async (groupId, botNum) => {
    const groupIds = [groupId];

    let role =
      "http://schemas.vergic.com/api/identity/claims/role#AccountAdministration";

    let userObject = {
      profile: {
        timeZoneId: "Europe/Stockholm",
        language: "en-GB",
        displayName: "BOT " + botNum,
        publicDisplayName: "BOT",
        preferredEmail: "andreas@" + model + botNum + ".vngage.com",
      },
      roleClaims: [role],
      groupIds: groupIds,
    };

    const creUser = await createUser(userObject, vepToken, domain);

    if (creUser && creUser.data) {
      const token = await createToken(creUser.data.id, vepToken, domain);
      if (token) {
        let userObj = {
          id: creUser.data.id,
          name: creUser.data.profile.displayName,
          token: token,
          group: groupId,
          email: creUser.data.profile.preferredEmail,
        };

        setTokenUser((prevData) => [...prevData, userObj]);
      }
    }
  };

  const caseCreate = async () => {
    const caseObj = {
      name: "BOT Intro Case",
      type: "customerCase",
      siteId: site,
      section: {
        caseTimeToLive: null,
        actionPanels: [],
        closures: [],
        forms: [],
        cannedResponses: [],
      },
    };

    const cc = await createCase(domain, vepToken, caseObj);
    setBotCase(cc);
  };

  /*When we have a botCase start creating the solution, only one solution and one opportunity will be created
  Additional needed needs to be added manually in DE
  */
  useEffect(() => {
    if (botCase && !solCreated) {
      setSolCreated(true);
      solutionCreate();
    }
  }, [botCase]);

  const getDooreyeBanner = async () => {
    const banners = await getAllBanners(domain, vepToken);
    banners.map((banner) => {
      if (
        banner?.data?.section?.template === "dooreye" &&
        banner?.data?.section?.description === "Default Dooreye"
      ) {
        setBanner(banner);
      }
    });
  };

  const solutionCreate = async () => {
    let solObj = {
      name: "BOT",
      type: "solution",
      siteId: site,
      section: {
        messageToCustomerCase: "BOT",
        bannerId: banner.key,
        caseTypeId: botCase.key,
        groupId: botGroupId[0],
      },
    };

    const cs = await createSolution(solObj, vepToken, domain);
    if (cs) {
      setLog((prevItems) => [
        ...prevItems,
        "Solution Created: " + cs.data.name,
      ]);
      console.log(log);
      const co = await opportunityCreate(cs.key);
    }
  };
  const opportunityCreate = async (solId) => {
    let pos = position + 1;

    let rule = "#bot";
    var oppObj = {
      name: "BOT",
      type: "opportunity",
      siteId: site,
      section: {
        position: pos,
        solutions: [solId],
        name: "",
        score: "",
        condition: {
          expression: {
            not: false,
            field: {
              type: "navigation",
              value: "currentPage",
            },
            operator: {
              type: "string",
              value: "contains",
            },
            value: {
              type: "static",
              value: rule,
            },
          },
          conditions: [],
        },
      },
    };

    const oppCreate = await createOpportunity(oppObj, domain, vepToken);

    if (oppCreate) {
      setLog((prevItems) => [
        ...prevItems,
        "Opportunity Created: " + oppCreate.data.name,
      ]);
      const custJsUp = await customJsUpdate();
    }
  };

  const customJsUpdate = async () => {
    const url =
      "https://content.psplugin.com/images/8733B8BB-49D3-4187-B9D4-719BCC78C769/bot/BOTLOADER.js";
    const response = await fetch(url);
    const data = await response.text();
    let botUsers = "";
    if (tokenUser) {
      tokenUser.map((user) => {
        botUsers += '"' + user.id + '"';
      });
    }

    const finalAddOnCustomJs =
      data +
      "\n// Needed to Identify which solutions are Bot solutions \n window.botGroups = [" +
      botUsers +
      "];";
    const updateCustom = await getCustomJsScript(
      domain,
      vepToken,
      finalAddOnCustomJs
    );

    if (updateCustom) {
      setLog((prevItems) => [...prevItems, "Custom Javascript Updated"]);
    }

    const adminConf = await createBOTAdminConfig();
  };

  const getGroupsOfAccount = async () => {
    const groups = await getGroups(domain, site, vepToken);

    if (groups) {
      setGroups(groups);
    }
  };

  const createBOTAdminConfig = async () => {
    let environments = [
      { env: "development", name: "BOT" },
      { env: "test", name: "BOT" },
      { env: "production", name: "BOT" },
    ];

    environments.map((obj) => {
      let e = obj.env;
      let dom = domain.replaceAll("https://", "");
      let environment = {};

      let token = "";
      if (obj.env === "development") {
        token = vepToken;
      }

      environment[e] = {
        account: account,
        accountTest: account,
        humanGroup: defaultTransferQueue.key,
        R4: true,
        maxConversationCount: 100,
        sendMessageDelaySec: 1,
        visitorResponseTimeoutSec: 360,
        allowTrainingAgent: true,
        addOnLibraries: [
          "browser",
          "basic",
          "puzzelai",
          "test",
          "webevents",
          "temp",
          "vergicAI",
        ],
        botUsers: [],
        REST: {
          idpHost: "https://idp.vergic.com",
          path: "https://" + dom,
          localPath: "/api/v1/",
          authType: "veptoken",
          SessionIdToken: tokenUser[0].token,
          availableFor: {
            groups: [tokenUser[0].group],
            caseTypes: [""],
          },
        },
        ws: {
          url: "wss://" + dom + "/ws",
          realm: "vngage",
        },
      };

      if (tokenUser) {
        let botUsers = [];

        tokenUser.map((user, i) => {
          let botUser = {
            SessionIdToken: user.token,
            DeUserId: user.id,
            email: user.email,
            availableFor: {
              groups: [user.group],
            },
            caseTypeMapping: {},
          };

          if (obj.env === "development" && usePuzzelIntegration) {
            botUser.puzzelUserName = userName[i];
            botUser.puzzelPassword = password[i];
          }

          botUsers.push(botUser);
        });

        environment[e].botUsers = [...botUsers];
      }

      if (obj.env === "development" && usePuzzelIntegration) {
        environment[e].puzzel = {
          authUrl: puzzelTokenUrl,
          urlBase: puzzelApiPath,
          customerKey: customerKey,
          userName: userName[0],
          password: password[0],
          queueMapping: [...queueMappings.values()],
        };
        environment[e].puzzelTriggerService = {
          path: "https://trigger.puzzel.com/v4/hubs/client",
        };

        let addOnL = [...environment[e].addOnLibraries];
        addOnL.push("puzzel");
        environment[e].addOnLibraries = [...addOnL];
        environment[e].maxConversationCount = 0;
      }
      SystemWorkflows.ON_INACTIVE.flow[0].account = account;
      SystemWorkflows.ON_INACTIVE.flow[0].model = model;
      SystemWorkflows.ON_FAILURE.flow[0].model = model;
      Workflows.welcome.flow[0].model = model;
      Workflows.welcome.flow[1].model = model;
      Workflows.welcome.flow[0].account = account;
      Workflows.welcome.flow[1].account = account;

      const cnb = createNewBot(
        Context,
        Desktop,
        SystemWorkflows,
        Workflows,
        environment,
        e,
        model,
        account
      );
    });
    setLog((prevItems) => [...prevItems, "Bot Admin Configuration Created"]);

    createInactiveAndFallback();
  };

  const createInactiveAndFallback = async () => {
    let id = "Inactive";
    let url =
      "/api/saveStandAloneResponse?responseId=" + account + "_common_" + id;
    let message = "Due to inactivity this dialog will now be closed.";
    let workflow = [
      { type: "LOGGER.track", event: id, data: { flow: id } },
      { type: "conversation.message", message: message },
      { type: "case.closeAsSuccess", readableName: "End Chat" },
    ];

    let response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(workflow),
    }).catch((error) => console.log(error));

    postToKv(account + "_common_" + id, workflow);

    const data = await response.json();
    if (data) {
      setLog((prevItems) => [...prevItems, "Inactive Flow Created"]);
    }
    id = "Fallback";
    url = "/api/saveStandAloneResponse?responseId=" + account + "_common_" + id;
    message =
      "Unfortunately I can not really understand you, can you formulate your question in other words?";
    workflow = [
      { type: "LOGGER.track", event: id, data: { flow: id } },
      { type: "conversation.message", message: message },
    ];

    response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(workflow),
    }).catch((error) => console.log(error));
    postToKv(account + "_common_" + id, workflow);
    const fallbackResp = await response.json();
    setLog((prevItems) => [...prevItems, "Fallback Flow Created"]);
    id = "Intro Bot";
    url = "/api/saveStandAloneResponse?responseId=" + account + "_common_" + id;
    message = "Welcome to " + model + " chatbot";
    workflow = [
      { type: "LOGGER.track", event: id, data: { flow: id } },
      { type: "conversation.message", message: message },
    ];
    response = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(workflow),
    }).catch((error) => console.log(error));
    postToKv(account + "_common_" + id, workflow);
    const introResp = await response.json();
    setLog((prevItems) => [...prevItems, "Default Intro Flow Created"]);
    const settings = await createSettings();
  };

  const createSettings = async () => {
    let url = "/api/saveSettings?account=" + account;
    let settings = {
      ConfidenceThreshold: 0.7,
      FallbackFlow: "Fallback",
      Account: account,
      whatDidYoumean: {
        activated: false,
        didYouMeanTexts: [],
        rangeValue: [25, 65],
      },
      CaseTypeMapping: [
        {
          caseId: botCase.key,
          caseName: "BOT Intro Case",
          flowName: "Intro Bot",
        },
      ],
      InactiveFlow: "Inactive",
      TrainingEpochs: "200",
      MultiSite: true,
      FilterVisibleByDefault: true,
      newTraining: true,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    });
    postToKv(account + "_account_settings", settings);
    const data = await response.json();

    if (data) {
      setLog((prevItems) => [...prevItems, "Bot Builder Settings Created"]);
    }
    const cii = await createInitialIntentModel();
  };

  const createInitialIntentModel = async (event) => {
    const getIntentModelTemplate = await getAccountIntentTemplate();
    if (getIntentModelTemplate && getIntentModelTemplate[0].Value) {
      const saveModel = await saveTrainingDataModel(
        pipeline,
        account,
        model,
        getIntentModelTemplate[0].Value
      );
      if (saveModel) {
        let rasaData = JSON.parse(getIntentModelTemplate[0].Value);

        let intentObject = {};
        rasaData.rasa_nlu_data.common_examples.map((intentObj) => {
          if (intentObj.intent) {
            intentObject[intentObj.intent] = true;
          }
        });
        const intentNameArray = Object.keys(intentObject);

        intentNameArray.map((intentName) => {
          createInitialIntentWorkflowResponse(intentName);
        });
      }
    }
  };

  const createInitialIntentWorkflowResponse = async (intentName) => {
    let workflow = [
      {
        type: "LOGGER.track",
        event: intentName,
        data: {
          flow: intentName,
          lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm"),
        },
      },
      {
        type: "conversation.message",
        message: intentName,
      },
    ];

    let requestObj = {
      intent: intentName,
      metaData: {
        workflow: JSON.stringify(workflow),
        model: model,
      },
    };

    const saveResponse = await saveIntentResponse(
      account,
      intentName,
      model,
      props.loggedInUserEmail,
      requestObj,
      moment(new Date()).format("YYYY-MM-DD HH:mm")
    );

    if (saveResponse) {
      setLog((prevItems) => [
        ...prevItems,
        "Initial Intent Model and Intent Response Created",
      ]);
    }
  };

  useEffect(() => {
    if (site) {
      getGroupsOfAccount();
    }
  }, [setSite, site]);

  const getPuzzelQ = async () => {
    const queues = await getPuzzelQueues(
      customerKey,
      userName[0],
      password[0],
      puzzelApiPath
    );
    if (queues) {
      let puzzelQueues = [];
      queues.result.map((q) => {
        puzzelQueues.push({ key: q.key, name: q.description });
      });
      setPuzzelQueues([...puzzelQueues]);
    }
  };

  const addMapping = (event) => {
    let queueMapping = new Map(queueMappings);
    let newObj = {};

    newObj[engageGroup.key.toString()] = puzzelQueue.key;
    queueMapping.set(engageGroup.key.toString(), newObj);
    setQueueMappings(queueMapping);
  };

  const renderQueueMappingRow = () => {
    let allQueueMappings = [...queueMappings.values()];

    return allQueueMappings.map((row) => {
      return (
        <TableRow key={Object.keys(row)[0]}>
          <TableCell component="th" scope="row">
            {Object.keys(row)[0]}
          </TableCell>
          <TableCell>{Object.values(row)[0]}</TableCell>
        </TableRow>
      );
    });
  };
  useEffect(() => {
    if (numberOfBots > 0) {
      let count = 1;
      let botsToCreate = [];
      while (count <= numberOfBots) {
        botsToCreate.push("Bot" + count);
        count++;
      }
      setBotGroupsToCreate([...botsToCreate]);
    }
  }, [numberOfBots]);

  const renderPuzzelUserRow = () => {
    return botGroupsToCreate.map((user, count) => {
      return (
        <Box style={{ marginTop: "14px" }} key={"puzzelUserRow" + count + 1}>
          <Typography variant="subtitle1">Puzzel User {count + 1}</Typography>
          <TextField
            style={{ backgroundColor: "white", width: "800px" }}
            label="Username"
            variant="outlined"
            value={userName[count]}
            onChange={(event) => {
              userName[count] = event.target.value;
              setUsername([...userName]);
            }}
            margin="normal"
          />
          <TextField
            style={{ backgroundColor: "white", width: "800px" }}
            label="Password"
            variant="outlined"
            value={password[count]}
            onBlur={getPuzzelQ}
            onChange={(event) => {
              password[count] = event.target.value;
              setPassword([...password]);
            }}
            margin="normal"
          />
        </Box>
      );
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ccc",
          padding: "20px",
          width: "840px",
          marginTop: "20px",
        }}
      >
        <Typography variant="h5">Bot Create Configuration</Typography>
        <TextField
          style={{ backgroundColor: "white", width: "800px" }}
          label="Account"
          variant="outlined"
          value={account}
          onChange={(event) => {
            setAccount(event.target.value.trim());
          }}
          margin="normal"
        />
        <TextField
          style={{ backgroundColor: "white", width: "800px" }}
          label="Token"
          variant="outlined"
          value={vepToken}
          onChange={(event) => {
            setVepToken(event.target.value);
          }}
          margin="normal"
        />

        <TextField
          style={{ backgroundColor: "white", width: "800px" }}
          label="Domain"
          variant="outlined"
          value={domain}
          onBlur={() => {
            if (domain && !modelSet && domain.indexOf("domain") === -1) {
              setModelSet(true);
              const domainSplit = domain.split(".");

              const secondSplit = domainSplit[0].split("//");
              setModel(secondSplit[1]);
            }
          }}
          onChange={(event) => {
            setDomain(event.target.value);
          }}
          margin="normal"
        />
        <div style={{ width: "800px", display: "flex", flexDirection: "row" }}>
          <TextField
            style={{
              backgroundColor: "white",
              width: "600px",
              marginTop: "20px",
            }}
            label="Intent Model Name"
            variant="outlined"
            value={model}
            onChange={(event) => {
              setModel(event.target.value);
            }}
            margin="normal"
          />
          <FormControl>
            <InputLabel
              style={{ marginLeft: "10px" }}
              id="demo-simple-select-label"
            >
              Language
            </InputLabel>
            <Select
              value={pipeline}
              label={"Language"}
              onChange={(event) => {
                setPipeline(event.target.value);
              }}
              inputProps={{
                name: "Pipeline",
                id: "Site-simple",
              }}
              variant="outlined"
              style={{ width: "200px", marginTop: "20px", marginLeft: "10px" }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="sv">Swedish</MenuItem>
              <MenuItem value="fi">Finish</MenuItem>
              <MenuItem value="nb">Norwegian Bokmål</MenuItem>
              <MenuItem value="da">Danish</MenuItem>
              <MenuItem value="fr">French</MenuItem>
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              <MenuItem value="nl">Dutch</MenuItem>
              <MenuItem value="bg">Bulgarian</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", width: "800px" }}
        >
          <Button
            style={{ marginTop: "20px", width: "800px" }}
            onClick={loadConfig}
            variant="outlined"
            color="primary"
          >
            Load Config
          </Button>
        </div>

        {sites && sites.length > 0 && (
          <Box
            style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}
          >
            <FormControl>
              <InputLabel
                style={{ marginLeft: "15px", marginTop: "-5px" }}
                id="demo-simple-select-label"
              >
                Sites
              </InputLabel>
              <Select
                value={site}
                onChange={(event) => {
                  setSite(event.target.value);
                }}
                inputProps={{
                  name: "Sites",
                  id: "Site-simple",
                }}
                label={"Sites"}
                variant="outlined"
                style={{ width: "300px" }}
              >
                {renderSites()}
              </Select>
            </FormControl>
            <FormGroup row style={{ marginTop: "0px", marginLeft: "60px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={usePuzzelIntegration}
                    onChange={(event) => {
                      setUsePuzzelIntegration(event.target.checked);
                    }}
                    name="Use Puzzel Integration"
                  />
                }
                label="Use Puzzel Integration"
              />
            </FormGroup>
          </Box>
        )}
        <Box style={{ marginTop: "20px" }}>
          <Typography style={{ marginBottom: "10px" }} variant="h6">
            Number of Bots
          </Typography>
          <Counter
            currentValue={(currentValue) => {
              setNumberOfBots(currentValue);
            }}
          />
        </Box>
        {groups && groups.length > 0 && (
          <Box style={{ marginTop: "20px" }}>
            <Typography style={{ marginBottom: "10px" }} variant="h6">
              Default Transfer Queue
            </Typography>
            <Autocomplete
              id="engageQueues"
              options={groups}
              onChange={(event, value) => {
                setDefaultTransferQueue(value);
              }}
              value={defaultTransferQueue ? defaultTransferQueue : null}
              getOptionLabel={(option) => option.data.name}
              style={{ width: "385px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Default Transfer Queue"
                  style={{}}
                />
              )}
            />
          </Box>
        )}
      </Box>

      {usePuzzelIntegration && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ccc",
            padding: "20px",
            width: "840px",
            marginTop: "20px",
          }}
        >
          <Typography variant="h6">Puzzel Integration Configuration</Typography>
          <TextField
            style={{ backgroundColor: "white", width: "800px" }}
            label="Customer Key"
            variant="outlined"
            value={customerKey}
            onChange={(event) => {
              setCustomerKey(event.target.value);
            }}
            margin="normal"
          />

          {renderPuzzelUserRow()}
          <Typography
            variant="h6"
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            Mapping Engage Queue - Puzzel Queues
          </Typography>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Autocomplete
              id="engageQueues"
              options={groups}
              onChange={(event, value) => {
                setEngageGroup(value);
              }}
              value={engageGroup ? engageGroup : null}
              getOptionLabel={(option) => option.data.name}
              style={{ width: "385px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Engage Queues"
                  style={{}}
                />
              )}
            />

            <Autocomplete
              id="puzzelQueues"
              options={puzzelQueues}
              onChange={(event, value) => {
                setPuzzelQueue(value);
              }}
              value={puzzelQueue ? puzzelQueue : null}
              getOptionLabel={(option) => option.name}
              style={{ marginLeft: "20px", width: "385px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="PuzzelQueues"
                  style={{}}
                />
              )}
            />
          </Box>
          <Button
            style={{ marginTop: "20px", width: "800px" }}
            onClick={addMapping}
            variant="outlined"
            color="primary"
          >
            Add Mapping
          </Button>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table style={{ width: "800px" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Engage Queue</TableCell>
                  <TableCell>Puzzel Queue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderQueueMappingRow()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {site && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ccc",
            padding: "20px",
            width: "840px",
            marginTop: "20px",
          }}
        >
          <Button
            style={{ marginTop: "20px", width: "800px" }}
            onClick={createBotGroup}
            variant="outlined"
            color="primary"
          >
            Create Bot
          </Button>
          <Button
            style={{ marginTop: "20px", width: "800px" }}
            onClick={(event) => {
              setCreateTheme(createTheme ? false : true);
            }}
            variant="outlined"
            color="primary"
          >
            Theme
          </Button>

          {createTheme && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SketchPicker
                onChangeComplete={(color) => {
                  setBackgroundColor(color.hex);
                }}
                color={backgroundColor}
              />

              <Button
                style={{ marginTop: "20px", width: "800px" }}
                onClick={createThemeConf}
                variant="outlined"
                color="primary"
              >
                Create Theme
              </Button>
            </div>
          )}
        </Box>
      )}
      {log && log.length > 0 && (
        <div
          style={{
            marginTop: "30px",
            border: "1px solid #ccc",
            padding: "20px",
            width: "840px",
          }}
        >
          <Typography style={{ marginLeft: "20px" }} variant="h5">
            Creation Log
          </Typography>

          <ol>
            {log.map((item, index) => {
              return (
                <li style={{ fontSize: "18px" }} key={index}>
                  {item}
                </li>
              );
            })}
          </ol>
          <Notifier
            changeState={(event) => {
              setNotifierOpen(false);
            }}
            open={notifierOpen}
            message={notifierMessage}
          />
        </div>
      )}
    </div>
  );
};
export default BotCreate;
