import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    buttonColor: {
        backgroundColor: "#000000"

    },
    ToggleButton : {
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.common.white,
        }
    },
    newClass : { backgroundColor:'#3E51B5!important', color: "white!important"},


});


class ExecuteOnTime extends Component {


    constructor(props) {

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.pass = this.pass.bind(this);
        this.getAllCommonWorkflows = this.getAllCommonWorkflows.bind(this);
        this.changeSetFlow = this.changeSetFlow.bind(this);
        this.changeNotSetFlow = this.changeNotSetFlow.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.handleChangeEqual = this.handleChangeEqual.bind(this);
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.state = {
            workFlowRender: null,
            textFieldValue: "",
            model: "",
            showPicker: false,
            optionAction: false,
            commonWorkflows: [],
            setFlow: {"Id": ""},
            notSetFlow: {"Id": ""},
            equalValue: "",
            operator: "",
            days: "",
            time: "00:00"



        }
        //this.convRef = React.createRef();
    }

    handleChangeTime(event){

        this.setState({time:event.target.value});

    }

    handleDayChange(event, newSelection){

        this.setState({days: newSelection});

    }

    handleOperatorChange(e){

        this.setState({operator: e.target.value});

    }


    async getAllCommonWorkflows() {


        let url = '/api/getAllCommonWorkflows';
        const response = await fetch(url);

        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let workflowArray = [];
            workflowArray.push({"Id": "Workflow.GoNext"});
            data.map((object) => {
                let arraySplit = object.Id.split("_");
                if (arraySplit[arraySplit.length-1]) {


                    let found = false;
                    workflowArray.map((o) =>{

                        if(o.Id === arraySplit[arraySplit.length-1] ){

                            found = true;
                        }

                    });

                    if(!found) {

                        if (arraySplit.length > 3) {

                            workflowArray.push({
                                "Id": arraySplit[arraySplit.length - 1],
                                "Category": arraySplit[arraySplit.length - 2]
                            });
                        } else {
                            workflowArray.push({"Id": arraySplit[arraySplit.length - 1]});
                        }
                    }

                }

            });

            //Sort the array in alphabetical order
            workflowArray.sort(function (a, b) {
                return a.Id.localeCompare(b.Id);
            })

            this.setState({commonWorkflows: workflowArray});



        }


    }

    componentDidMount() {
        this.getAllCommonWorkflows();
        if (this.props.value) {
            this.setState({edit: true});
            if(this.props.value.days && this.props.value.days.length > 0 ){

                let days = this.props.value.days;
                days.map((day,i)=>{

                    days[i] = day.toString();

                });

                this.setState({days: days});
            }


            this.setState({time: this.props.value.time});
            this.setState({operator: this.props.value.operator});
            if(this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent"){
                if(this.props.value.onMatch.type === "workflow.goNext"){
                    this.setState({setFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({setFlow: {"Id": this.props.value.onMatch.type}});
                }

            }else{
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if(this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent"){

                if(this.props.value.onFailure.type === "workflow.goNext"){
                    this.setState({notSetFlow: {"Id": "Workflow.GoNext"}});

                }else{
                    this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});
                }


            }else{
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }

        }


        if (this.props.optionAction) {

            this.setState({optionAction: this.props.optionAction});
        }

    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({edit: true});

            if(this.props.value.days && this.props.value.days.length > 0 ){

                let days = this.props.value.days;
                days.map((day,i)=>{

                    days[i] = day.toString();

                });

                this.setState({days: days});
            }


            this.setState({time: this.props.value.time});
            this.setState({operator: this.props.value.operator});

            if(this.props.value.onMatch && this.props.value.onMatch.type !== "vergicAi.responseOnIntent"){
                this.setState({setFlow: {"Id": this.props.value.onMatch.type}});

            }else{
                this.setState({setFlow: {"Id": this.props.value.onMatch.initiateAiWithResponse}});
            }
            if(this.props.value.onFailure && this.props.value.onFailure.type !== "vergicAi.responseOnIntent"){
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.type}});

            }else{
                this.setState({notSetFlow: {"Id": this.props.value.onFailure.initiateAiWithResponse}});
            }



        }

    }

    showEmoji(event){

        if(this.state.showPicker){

            this.setState({showPicker: false});
        }else{
            this.setState({showPicker: true});
        }

    }

    pass() {

        let days = this.state.days;
        if(days.length > 0){

            days.map((day,i)=>{

                days[i] = Number(day);


            });

        }


        let onMatch = {};
        let onFailure = {};


        if (this.state.setFlow.Id.toLowerCase() === "workflow.gonext") {

            onMatch = {"type": "workflow.goNext"};

        }else{

            onMatch =  {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.setFlow.Id,
                "replace": true,
                "account": this.props.account
            };
            if(this.state.setFlow.Id.indexOf("workflow.goNext")>-1){
                delete onMatch.replace;
            }
        }

        if (this.state.notSetFlow.Id.toLowerCase() === "workflow.gonext") {

            onFailure = {"type": "workflow.goNext"};

        }else {

            onFailure = {
                "type": "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.state.notSetFlow.Id,
                "replace": true,
                "account": this.props.account
            };

            if(this.state.notSetFlow.Id.indexOf("workflow.goNext")>-1){
                delete onFailure.replace;
            }
        }


        let workflow = {type: "case.executeOnTime", "time": this.state.time, "days": days, "operator": this.state.operator,  "onMatch":
                onMatch,
            "onFailure": onFailure
                };




        if(this.state.equalValue && this.state.equalValue !== "" && this.state.equalValue.split(",").length > 1) {
            workflow.value = this.state.equalValue;
            let valuesSplit = this.state.equalValue.split(",");
            let values = [];
            valuesSplit.map((o)=>{
                values.push(o);

            });
            workflow.values = values;


        }else if(this.state.equalValue && this.state.equalValue !== ""){

            workflow.value = this.state.equalValue;
        }




        this.props.passData("case.executeOnTime", workflow);


    }

    openWorkflow = (event, flow) => {


        this.props.openWorkflow(flow.Id);

    }

    changeSetFlow(event, value) {

        if(value && value.Id){
            this.setState({setFlow: {"Id": value.Id}});
        }

    }

    changeNotSetFlow(event, value) {
        if(value && value.Id){
            this.setState({notSetFlow: {"Id": value.Id}});
        }

    }

    handleChange(e) {

        this.setState({textFieldValue: e.target.value});

    }

    handleChangeEqual(e){

        this.setState({equalValue: e.target.value});
    }


    render() {
        const {classes} = this.props;


        return (

            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent:"space-around"}}>




                <FormControl  style={{borderColor: "black"}} className={classes.formControl}>
                    Time:
                    <TextField value={this.state.time} variant={"outlined"}  onChange={this.handleChangeTime} id="appt-time" type="time" placeholder="HH" />
                </FormControl>

                <FormControl variant="outlined" style={{borderColor: "black", marginTop: "20px", marginBottom: "20px"}} className={classes.formControl}>
                    Operator:
                    <TextField
                        variant="outlined"

                        select
                        value={this.state.operator}
                        classes={{root: classes.select}}
                        onChange={this.handleOperatorChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={"="}>{"="}</MenuItem>
                        <MenuItem value={">"}>{">"}</MenuItem>
                        <MenuItem value={"<"}>{"<"}</MenuItem>
                    </TextField>
                </FormControl>

                <FormControl  variant="outlined" style={{borderColor: "black", marginTop: this.props.value && this.props.value.time ? "0px": "25px"}} className={classes.formControl}>
                    Days of Week:
                    <ToggleButtonGroup  color="primary" value={this.state.days} size="small"  onChange={this.handleDayChange} aria-label="days of week">
                        <ToggleButton value="1" aria-label="MON"  classes={{
                            selected:classes.newClass}}>
                            MON
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="TUE"  classes={{
                            selected:classes.newClass}}>
                            TUE
                        </ToggleButton>
                        <ToggleButton value="3" aria-label="WED"  classes={{
                            selected:classes.newClass}}>
                            WED
                        </ToggleButton>
                        <ToggleButton value="4" aria-label="THU"  classes={{
                            selected:classes.newClass}}>
                            THU
                        </ToggleButton>
                        <ToggleButton value="5" aria-label="FRI"  classes={{
                            selected:classes.newClass}}>
                            FRI
                        </ToggleButton>
                        <ToggleButton value="6" aria-label="SAT"  classes={{
                            selected:classes.newClass}}>
                            SAT
                        </ToggleButton>
                        <ToggleButton value="0" aria-label="SUN"  classes={{
                            selected:classes.newClass}}>
                            SUN
                        </ToggleButton>
                    </ToggleButtonGroup>

                </FormControl>




                <Autocomplete
                    id="combo-box5"
                    options={this.state.commonWorkflows}
                    onChange={this.changeSetFlow}
                    value={this.state.setFlow}
                    getOptionLabel={option => option.Id}
                    style={{ width: "100%", marginTop: "40px"}}
                    renderInput={params => <TextField {...params} variant="outlined" label="True"  style={{minHeight: "100px"}} />}
                />
                {this.state.edit &&this.state.setFlow && this.state.setFlow.Id !== "Workflow.GoNext"?<Button style={{width: "100%", marginBottom: "10px", marginTop: "-20px"}} variant="contained"
                        color="primary" onClick={event => this.openWorkflow(event, this.state.setFlow)}>Open Workflow</Button>: ""}


                    <Autocomplete
                        id="combo-box4"
                        options={this.state.commonWorkflows}
                        onChange={this.changeNotSetFlow}
                        value={this.state.notSetFlow}
                        getOptionLabel={option => option.Id}
                        style={{ width: "100%", marginTop: "10px"}}
                        renderInput={params => <TextField {...params} variant="outlined" label="False"  style={{minHeight: "100px"}} />}
                    />
                    {this.state.edit &&this.state.notSetFlow && this.state.notSetFlow.Id !== "Workflow.GoNext" ?<Button style={{width: "100%", marginTop: "-20px"}} variant="contained"
                                             color="primary" onClick={event => this.openWorkflow(event, this.state.notSetFlow)}>Open Workflow</Button>: ""}


                {!this.props.option ?
                    <div style={{marginTop: "30px"}}>
                        {this.props.value && this.props.value.time ?
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"
                                    onClick={this.pass}>Update</Button> :
                            <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"
                                    onClick={this.pass}>Add</Button>}

                    </div> : <div></div>}
            </div>


        )
    }

}

export default withStyles(styles)(ExecuteOnTime);
