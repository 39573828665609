import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import List from '@material-ui/core/List';
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";



const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },


});


class VergicTemplate extends Component {


    constructor(props) {

        super(props);

        this.handleChangeLinkId = this.handleChangeLinkId.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.addParameter = this.addParameter.bind(this);
        this.pass = this.pass.bind(this);
        this.listRow = this.listRow.bind(this);
        this.removeParameter = this.removeParameter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.handleTemplatesChange = this.handleTemplatesChange.bind(this);
        this.setTemplate = this.setTemplate.bind(this);
        this.getTokenUser = this.getTokenUser.bind(this);

        this.state = {
            workFlowRender: null,
            linkId: "",
            text: "",
            paramaters: "",
            data: [""],
            keyData: [""],
            template: {"id": "", "name": ""},
            templates: []

        };

    }

    componentDidMount() {


        this.getTokenUser();


        if(this.props.value){
            this.setState({templateId: this.props.value.linkId})

            this.setState({text: this.props.value.text});

            this.setState({keyData: Object.keys(this.props.value.url)});
            this.setState({data: Object.values(this.props.value.url)});



        }


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {



            this.setState({templateId: this.props.value.linkId})

            this.state.templates.map((object) =>{

                if(object.id === this.props.value.linkId){

                    this.setState({template: object});

                }

            })

            this.setState({text: this.props.value.text});

            this.setState({keyData: Object.keys(this.props.value.url)});
            this.setState({data: Object.values(this.props.value.url)});

        }

    }

    handleTemplatesChange(event, value) {


            console.log(value)

            if(value && value.name){
                this.setState({template: value, templateId: value.id});

            }



    }

    async getTokenUser(){
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getTemplates();

        }
    }

    async getTemplates() {

        let url = '/api/getTemplates';

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {

            let templates = [...data, ...this.state.templates];


            this.setState({templates: templates});
            if(this.state.templateId){

                templates.map((o)=>{

                    if(o.id === this.state.templateId){
                        this.setState({template: {"id": o.id, "name": o.name}});
                    }

                })

            }


        }


    }

    setTemplate() {
        this.state.templates.map((object) => {

            if (this.props.value && this.props.value.linkId && object.id === this.props.value.linkId) {

                this.setState({template: object});

            }

        })
    }

    handleChangeLinkId(e) {

        this.setState({linkId: e.target.value})


    }

    handleChangeTitle(e) {

        this.setState({text: e.target.value})

    }


    pass(){

        let parameterObject = {};

        this.state.keyData.map( (object, i) => {
            if(this.state.keyData[i] !== ""){
                parameterObject[this.state.keyData[i]] = this.state.data[i];
            }
        });

        let workflow = {type: "temp.vergicTemplate", "linkId": this.state.templateId, "text": this.state.text, "url": parameterObject};

        this.props.passData("temp.vergicTemplate", workflow);


    }

    addParameter(){

        this.state.keyData.push("");
        this.state.data.push("");
        this.setState({render: true});

    }

    handleKeyChange = (event, i) => {


        let keyDataObject = this.state.keyData;
        keyDataObject[i] = event.target.value;

        this.setState({keyData: keyDataObject});

    }

    handleChange = (event, i) => {

        let newValue = this.state.data;
        newValue[i]= event.target.value;
        this.setState({data: newValue});

    }

    listRow() {


        return this.state.keyData.map((object, i) => {

            return <div key={i} style={{display: "flex", flexDirection: "column", minWidth: "100%", width: "100%"}}>

                <TextField
                    label="Key"
                    style={{width: "100%"}}
                    value={this.state.keyData[i]}
                    onChange={event => this.handleKeyChange(event, i)}
                    margin="normal"
                    variant="outlined"
                />

                <TextField
                    label="Value"
                    style={{width: "100%"}}
                    value={this.state.data[i]}
                    onChange={event => this.handleChange(event, i)}
                    margin="normal"
                    variant="outlined"
                />
            </div>
        });
    }

    removeParameter(){

        let arr = this.state.data.splice(this.state.data.length-1, 1);
        let arr2 = this.state.keyData.splice(this.state.keyData.length-1, 1);
        this.setState({data: this.state.data});
        this.setState({keyData: this.state.keyData});
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>

                    <TextField
                        label="Text"
                        value={this.state.text}
                        onChange={this.handleChangeTitle}
                        margin="normal"
                        variant="outlined"
                    />
                    {/*<TextField
                        label="Link Id"
                        value={this.state.linkId}
                        onChange={this.handleChangeLinkId}
                        margin="normal"
                        variant="outlined"
                    />*/}

                    <FormControl variant="outlined" style={{borderColor: "black", marginTop: "10px"}} className={classes.formControl}>


                        <Autocomplete
                            id="combo-box3"
                            options={this.state.templates}
                            onChange={this.handleTemplatesChange}
                            value={this.state.template}
                            getOptionLabel={option => option.name}
                            style={{ width: "100%"}}
                            renderInput={params => <TextField {...params}   variant="outlined" label="Vergic Template"  style={{minHeight: "100px"}} />}
                        />


                    </FormControl>

                    <h3>Input Paramaters</h3>
                    <div style={{marginTop: '0px', width: "100%", display: "flex", flexDirection: "column",  justifyContent: "space-evenly", height: "60px"}}>
                        <Button variant="contained"  color="primary" onClick={this.addParameter}>+
                            Parameters</Button>
                        {this.state.data.length-1 > 0?<Button variant="contained" style={{marginTop: "10px"}} color="primary" onClick={this.removeParameter}>Remove
                            Parameter</Button>:<div></div>}
                    </div>



                    <div style={{marginTop: '20px', width: "100%", overflow: "auto"}}>

                        <List
                            style={{minHeight: "250px", maxHeight: "250px", width: "100%"}}
                            component="nav"
                            className={classes.root}
                            id='thing'
                        >
                            {this.listRow()}
                        </List>

                    </div>
                    {this.props.value && this.props.value.url && this.props.value.text? <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"    onClick={this.pass}>Update</Button>: <Button style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}} variant="contained"   onClick={this.pass}>Add</Button>}
                </div>
            </div>

        )
    }

}

export default withStyles(styles)(VergicTemplate);
