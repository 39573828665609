import React, {Component} from "react";
import {ListItem, ListItemText, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button/Button";
import List from "@material-ui/core/List";
import Autocomplete from "@material-ui/lab/Autocomplete";


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {

        paddingLeft: '40px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(5),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    }, listItemTextSelected: {

        "&.Mui-selected": {
            backgroundColor: "#3E51B5",
            color: "white"
        },
        fontSize: "12px"

    },


});


class SetContextValue extends Component {


    constructor(props) {

        super(props);


        this.handleValueChange = this.handleValueChange.bind(this);
        this.getContext = this.getContext.bind(this);
        this.reactOnContextClick = this.reactOnContextClick.bind(this);
        this.pass = this.pass.bind(this);
        this.listRow = this.listRow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.passData = this.passData.bind(this);
        this.state = {
            workFlowRender: null,
            key: "",
            value: "",
            contextValues: [],
            context: ""


        };
        this.listRef = React.createRef();
    }

    passData(event){

        if(this.props.option){

            this.props.onChange(this.state.context+"##"+this.state.value);
        }

    }

    handleChange(event, value) {

        this.setState({context: value});
        if(this.props.option){
            this.props.onChange(value+"##"+this.state.value);
        }
    }

    async getContext() {

        let url = '/api/context';
        const response = await fetch(url);
        if (response) {

            const data = await response.json();

            if (data && data.name !== 'error') {
                this.setState({contextObject: data});
                this.setState({contextValues: Object.keys(data)});
            }


            Object.keys(data).map((object, i) => {

                if (object === this.state.context) {
                    this.setState({selectedIndex: i});

                }

            });


        }


    }

    reactOnContextClick(event, objectName, i) {
        this.setState({selectedIndex: i});
        this.setState({context: objectName});


    }

    componentDidMount() {
        this.getContext();
        if (this.props.value) {

            this.setState({value: this.props.value.value});
            this.setState({context: this.props.value.key});

        }

    }

    listRow() {


        return this.state.contextValues.map((object, i) => {

            return <ListItem onClick={event => this.reactOnContextClick(event, object, i)}
                             selected={this.state.selectedIndex === i}
                             classes={{root: this.props.classes.listItemTextSelected}} key={i}
                             button><ListItemText id={i} primary={object}/></ListItem>


        });


    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if ((prevProps && prevProps.value !== this.props.value)) {

            this.setState({key: this.props.value.key})

        }

    }


    handleValueChange(e) {

        this.setState({value: e.target.value})
        if(this.props.option){
            this.props.onChange(this.state.context+"##"+e.target.value);
        }
    }


    pass() {

        const num = Number(this.state.value);
        let isNumber = false;
        let value = this.state.value;

        if (Number.isInteger(num)) {
            isNumber = true;
            value = Number(this.state.value);
        }

        let workflow = {type: "case.setContextValue", "key": this.state.context, "value": value};


        this.props.passData("case.waitForContext", workflow);


    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "8px"}}>

                    {this.state.contextValues.length > 0 ?
                        <div style={{border: "0px solid #ccc", width: "100%"}}>

                            <Autocomplete
                                options={this.state.contextValues}
                                value={this.state.context?this.state.context:null}
                                onChange={this.handleChange}
                                getOptionLabel={option => option}
                                style={{width: "100%"}}
                                defaultValue={null}
                                renderInput={params => <TextField {...params} label="Context Key" variant="outlined"/>}
                            />

                            {/*<List
                                style={{ width: "99%"}}
                                component="nav"
                                className={classes.list}
                                id='thing'
                                ref={this.listRef}
                                value={this.state.context}
                            >
                                {this.listRow()}
                            </List>*/}
                        </div> : <div></div>}


                    <TextField
                        label="Value"
                        value={this.state.value}
                        onChange={this.handleValueChange}
                        onBlur={this.passData}
                        margin="normal"
                        multiline={false}
                        variant="outlined"
                    />
                    {!this.props.option && <React.Fragment>
                        {this.props.value && this.props.value.key ? <Button
                            style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                            variant="contained" onClick={this.pass}>Update</Button> : <Button
                            style={{width: "100%", backgroundColor: "green", color: "white", fontWeight: "bold"}}
                            variant="contained" onClick={this.pass}>Add</Button>}
                    </React.Fragment>}

                </div>
            </div>

        )
    }

}

export default withStyles(styles)(SetContextValue);
