import React, {Component} from "react";
import {ListItem, ListItemText, Select, withStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import Button from "@material-ui/core/Button/Button";
import ResponseOnIntent from "./ResponseOnIntent";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import {compose} from "redux";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";

const style = {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "20px"
}

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 200,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    button: {

        padding: '0 10px'
    },
    listItemText: {
        fontSize: '15px',
        paddingLeft: '10px',
        selected: {
            backgroundColor: '#000',
            color: '#fff'

        }
    },
    fab: {
        margin: theme.spacing(4),
    },

    paper: {
        padding: '10px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: '20px'

    },
    modal: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    paperForm: {
        display: 'flex',
        flexDirection: 'row'


    },
    chipRoot: {margin: theme.spacing(1), height: '100%', display: 'flex', flexDirection: 'column'},
    chipLabel: {overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip'}

});


class EntitySwitch extends Component {


    constructor(props) {

        super(props);
        this.listRow = this.listRow.bind(this);
        this.addOption = this.addOption.bind(this);
        this.saveWorkflow = this.saveWorkflow.bind(this);
        this.handleResponseChange = this.handleResponseChange.bind(this);
        this.handleRemoveOption = this.handleRemoveOption.bind(this);
        this.scrollOption = this.scrollOption.bind(this);
        this.openWorkflow = this.openWorkflow.bind(this);
        this.settingsChange = this.settingsChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.queryIntentResponse = this.queryIntentResponse.bind(this);
        this.getSavedModels = this.getSavedModels.bind(this);
        this.handleDefaultResponseChange = this.handleDefaultResponseChange.bind(this);

        this.state = {
            filter: '',
            expanded: "panel1",
            workFlowRender: [],
            data: [{"accepts": [], "onMatch": []}],
            count: 0,
            website: "",
            model: "",
            default: "",
            pipeline: "",
            selected: [],
            newOption: false,
            renderPreview: true,
            closureMessage: "",
            items: [],
            showSort: false,
            entityContext: '',
            predefinedContext: "",
            textFieldValue: "",
            typeSelect: "options",
            showType: false,
            showIcon: false,
            groups: [],
            group: {id: "", name: ""},
            checked: false,
            entities: [],
            switches: [" "]

        }

        this.listRef = React.createRef();

    }






    handleDefaultResponseChange(event) {

        this.setState({default: event, error: false});

        if (event.initiateAiWithResponse === "New Workflow") {
            this.props.newWorkflowEntitySwitch("default");
        }


    }



    async getSavedModels() {

        let url = '/api/getSavedModels';
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {

            data.map((o, i) => {

                this.queryIntentResponse(o.Model, i);
            });

        }

    }

    async queryIntentResponse(model, i) {


        let url = '/api/trainingDataJson?model=' + model;

        let response = await fetch(url, {
            method: 'get',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).catch(error => window.location.reload()
        );

        let data = await response.json();
        let entities;
        if (i === 0) {
            entities = [];
        } else {
            entities = this.state.entities ? this.state.entities : [];
        }

        if (data.rasa_nlu_data.entity_synonyms && data.rasa_nlu_data.entity_synonyms.length > 0 && typeof data.rasa_nlu_data.entity_synonyms[0] === "string") {

            // let entities = [...new Set(entities)];
            this.setState({entities: [...entities, ...data.rasa_nlu_data.entity_synonyms]});

        }


    }



    settingsChange = (key, flow) => {


        this.setState({flow: flow});
        let newData = this.state.data;

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            this.setState({data: newData}, this.setState({edit: true}));
            this.saveWorkflow();
        }


    }

    async getSites() {
        let url = '/api/getSites';

        const response = await fetch(url);

        if (response.status !== 500) {
            const data = await response.json();

            if (data && data.length > 0 && data.name !== 'error') {

                data.map((site) => {

                    this.getGroups(site);

                });

            }
        }
    }


    async getTokenUser() {
        let url = '/api/getTokenUser';

        const response = await fetch(url);
        const data = await response.json();


        if (data && data.length === 0 && data.name !== 'error') {

            this.getSites();

        }
    }

    async getGroups(site) {

        let url = '/api/getGroups?site=' + site;

        const response = await fetch(url);


        const data = await response.json();

        if (data && data.length > 0 && data.name !== 'error') {


            let groups = [...data, ...this.state.groups];


            this.setState({groups: groups});

        }

    }

    openWorkflow = (value) => {

        if (this.props.workflowCreate) {

            this.props.passWorkflowToOpen(value);
        } else {
            this.props.openWorkflow(value);
        }


    }


    handleOpenWorkFlow = (value) => {

        this.props.passWorkflowToOpen(value);

    }

    handleRemoveOption = (event, i) => {

        let arr = this.state.data;
        let removed = arr.splice(i, 1);
        this.setState({data: arr}, this.saveWorkflow);

    }

    componentDidMount() {

        this.getSavedModels();
        if (this.props.data) {

            this.setState({edit: true});
            this.setState({items: this.props.data});

        }


        if (this.props.optionsType) {

            this.setState({typeSelect: this.props.optionsType});
        }

        if (this.props.disableInputField) {

            this.setState({checked: this.props.disableInputField})

        }

        if (this.props.model && this.props.pipeline) {

            this.setState({model: this.props.model});
            this.setState({pipeline: this.props.pipeline});

        }

        if (this.props.forwardParams) {

            this.setState({aiModel: this.props.forwardParams.model});
            this.setState({aiPipeline: this.props.forwardParams.pipeline});

        }


        if (this.props.value) {
            console.log(this.props.value)
            let switches = [];
            this.props.value.switches.map((swi)=>{

                switches.push(swi.accepts);

            })


            this.setState({selectedEntity: switches, data: this.props.value.switches, switches: switches});



            if (this.props.value.default) {

                this.setState({default: this.props.value.default, edit: true});
            }

        }

        this.getTokenUser();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {


        if (JSON.stringify(prevState.listOptions) !== JSON.stringify(this.state.listOptions)) {

        }

        if (prevProps.optionsType !== this.props.optionsType) {

            this.setState({typeSelect: this.props.optionsType});
        }

        if (prevProps.disableInputField !== this.props.disableInputField) {

            this.setState({checked: this.props.disableInputField});
        }

        if (JSON.stringify(prevState.items) !== JSON.stringify(this.state.items)) {

        }

        if ((prevProps && prevProps.newNameEntitySwitch !== this.props.newNameEntitySwitch)) {

            this.setState({selectedWorkflow: {"Id": this.props.newNameEntitySwitch}});

            let workflow = {
                type: "vergicAi.responseOnIntent",
                "initiateAiWithResponse": this.props.newNameEntitySwitch,
                "account": this.props.account,

            };

            if (this.props.default) {

                this.handleDefaultResponseChange(workflow);

            } else {

                if (workflow.initiateAiWithResponse === "New Workflow") {

                    workflow.initiateAiWithResponse = " ";
                }
                this.handleResponseChange(this.state.updatedPosition, workflow);

            }
            if (this.state.textFieldValue === "") {
                this.setState({textFieldValue: "default"}, () => {
                    this.saveWorkflow();
                });
            } else {
                this.saveWorkflow();
            }


        }
    }


    handleResponseChange = (key, flow) => {

        console.log(key, flow)
        if (flow.initiateAiWithResponse === "New Workflow") {
            this.setState({updatedPosition: key});
            this.props.newWorkflowEntitySwitch("switch");

        } //else {

        this.setState({flow: flow});
        let newData = this.state.data ? this.state.data : {};

        if (newData && newData[key].onMatch) {

            newData[key].onMatch = flow;

            //newData[key].accepts = this.state.switches[key];

            this.setState({data: newData, edit: true});

        }

    }
    handleEntityChange = (key, entity) => {

        this.state.switches[key] = entity.target.innerHTML;
        let newData = this.state.data ? this.state.data : {};

        if (newData && newData[key].onMatch) {


            newData[key].accepts = this.state.switches[key];

            this.setState({data: newData, edit: true});

        }
    }

    listRow() {


        return this.state.data.map((object, i) => {

            return <div key={i} style={{display: "flex", flexDirection: "column", minWidth: "100%", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <div style={{width: "80%"}}><h3>Check: {i + 1}</h3></div>
                    <div style={{width: "20%"}}><InputAdornment onClick={event => this.handleRemoveOption(event, i)}
                                                                position="end"><IconButton><Delete
                        style={{fontSize: 24}}/></IconButton></InputAdornment></div>
                </div>

                <Autocomplete
                    options={this.state.entities}
                    defaultValue={this.state.entities[0]}
                    onChange={this.handleEntityChange.bind(this, i)}
                    value={typeof this.state.data[i].accepts === "object"? "":  this.state.data[i].accepts }
                    getOptionLabel={option => option}
                    style={{width: "100%"}}
                    renderInput={params => <TextField {...params} label="Entity" variant="outlined"/>}
                />

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch &&
                (this.state.data[i].onMatch.initiateAiWithResponse ||
                    this.state.data[i].onMatch.initiateAiWithResponse === "")
                && this.state.edit ?
                    <div style={{padding: "0px", width: "100%"}}>


                        <ResponseOnIntent option={true}
                                          onChange={this.handleResponseChange.bind(this, i)}
                                          value={this.state.data[i].onMatch}
                                          settingsChange={this.settingsChange}
                                          workflowCreate={true}
                                          dontShowSettings={true}
                                          id={i.toString()}
                                          workflowCreate={true}
                                          model={this.props.model}
                                          pipeline={this.props.pipeline}
                                          openWorkflow={this.openWorkflow}
                                          handleOpenWorkFlow={this.handleOpenWorkFlow}
                                          replace={this.state.data[i].replace}
                                          account={this.props.account}/>
                    </div> : <div></div>}

                {this.state.data && this.state.data[i] && this.state.data[i].onMatch && !this.state.data[i].onMatch.initiateAiWithResponse &&

                    <div style={{
                        width: "100%",
                        wordBreak: "break-all",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px"
                    }}>
                        <p style={{fontSize: "16px", fontWeight: "bold"}}>On Match</p>

                        <div style={{overflow: "auto", marginTop: "-10px"}}>
                            <div>
                                <ResponseOnIntent option={true}
                                                  onChange={this.handleResponseChange.bind(this, i)}
                                                  model={this.props.model}
                                                  pipeline={this.props.pipeline}
                                                  account={this.props.account}
                                                  dontShowSettings={true}
                                                  contextSwitch={true}
                                                  workflowCreate={true}
                                                  openWorkflow={this.openWorkflow}
                                />
                            </div>
                        </div>
                    </div>
                }


            </div>
        });

    }

    addOption() {

        this.state.data.push({"accepts": [], "onMatch": []});
        this.setState({render: true});
        this.setState({showSort: false});
        //this.listRef.current.scrollIntoView({block: "end", inline: "nearest"});


        setTimeout(this.scrollOption, 500);

    }

    scrollOption() {
        let curr = this.listRef.current;

        if (curr) {
            curr.scrollTop = (curr.scrollHeight - (curr.clientHeight + 20));
        }

    }


    saveWorkflow() {

        let workflow = this.state.data;

        if (this.state.default === "") {

            this.setState({error: true, errorInfo: "Please choose a default workflow before adding this action"})


        } else {

            let action = {
                "type": "case.entitySwitch",
                "switches": workflow,
                "default": this.state.default
            };
            this.props.saveWorkflow("case.entitySwitch", action);

        }

    }


    render() {
        const {classes} = this.props;
        return (
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>

                <Paper style={{
                    padding: "10px",
                    marginTop: "10px",
                    overflow: "auto"
                }}>


                    <div style={{marginBottom: "0px"}}>


                        <div style={{display: "flex", flexDirection: "column"}}>

                            <div style={{width: "80%"}}><h3>Default</h3></div>
                            <div style={{marginTop: "-15px"}}>
                                <ResponseOnIntent passData={this.makeWorkFlow} model={this.props.model}
                                                  option={true}
                                                  value={this.state.default}
                                                  onChange={this.handleDefaultResponseChange}
                                                  dontShowSettings={true}
                                                  pipeline={this.props.pipeline}
                                                  account={this.props.account}
                                                  workflowCreate={true}
                                                  openWorkflow={this.openWorkflow}/>
                            </div>

                            <div style={{marginTop: "-40px"}}>
                                <Tooltip title="Add Switch">
                                    <IconButton style={{background: "#3E51B5", color: "white"}}
                                                onClick={this.newWorkflow} aria-label="Add Switch"
                                                onClick={this.addOption}
                                                component="span">
                                        <AddIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{
                            marginTop: '1   ' +
                                '0px',
                            width: "100%",
                            overflowY: "auto",
                            paddingBottom: "10px",
                            marginBottom: "0px",
                            maxHeight: "83%"
                        }} ref={this.listRef}>

                            <List
                                style={{minHeight: "180px", maxHeight: "330px", width: "100%"}}
                                component="nav"
                                className={classes.root}
                                id='thingList'

                            >
                                {this.listRow()}
                            </List>

                        </div>

                    </div>
                    <div style={{

                        marginTop: "-30px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"

                    }}>
                        {this.state.error &&
                            <p style={{marginTop: "-20px", width: "280px", color: "red", fontWeight: "bold"}}>
                                {this.state.errorInfo}
                            </p>}

                        {this.state.edit ? <Button variant="contained"
                                                   style={{
                                                       backgroundColor: "green",
                                                       color: "white",
                                                       fontWeight: "bold"
                                                   }}
                                                   onClick={this.saveWorkflow}>Update</Button> :
                            <Button variant="contained"
                                    style={{backgroundColor: "green", color: "white", fontWeight: "bold"}}
                                    onClick={this.saveWorkflow}>Add</Button>}
                    </div>
                </Paper>
            </div>

        )
    }

}

export default compose(
    withStyles(styles)
)(EntitySwitch);
